import {
	FreespinsParams,
	getFreespinsKey
} from 'casino/modules/freespinsModule/freespinsModule';
import {
	GamesParams,
	getGamesKey,
	getMyGamesKey,
	MyGamesParams
} from 'casino/modules/gamesModule';
import {
	getRolloverBonusesKey,
	RolloverBonusesParams
} from 'casino/modules/rolloverBonusesModule';
import { CasinoState, RootState } from 'casino/modules/rootModule';
import { getSectionsKey, SectionsParams } from 'casino/modules/sectionsModule';
import { getStudiosKey, StudiosParams } from 'casino/modules/studiosModule';
import { WinnerType } from 'casino/modules/winnersModule';
import { createSelector } from 'reselect';

const metaSelector = (state: RootState) => state.meta;
export const getGameList = (params: GamesParams) => (state: CasinoState) =>
	metaSelector(state).gameLists[getGamesKey(params)] || [];
export const getGamesCount = (params: GamesParams) => (state: CasinoState) =>
	metaSelector(state).gameCounts[getGamesKey(params)];
export const getSections = (params: SectionsParams) => (
	state: CasinoState
): string[] | undefined => metaSelector(state).sections[getSectionsKey(params)];
export const getStudios = (params: StudiosParams) => (
	state: CasinoState
): string[] | undefined => metaSelector(state).studios[getStudiosKey(params)];
export const getFreespins = (params: FreespinsParams) => (
	state: CasinoState
): string[] | undefined =>
	metaSelector(state).freespins[getFreespinsKey(params)];
export const getRolloverBonuses = (params: RolloverBonusesParams) => (
	state: CasinoState
): string[] | undefined =>
	metaSelector(state).rolloverBonuses[getRolloverBonusesKey(params)];
export const getMyGamesCount = (params: MyGamesParams) => (
	state: CasinoState
) => metaSelector(state).gameCounts[getMyGamesKey(params)];
export const getWinningAmount = (type: WinnerType) => (
	state: CasinoState
): number | undefined => metaSelector(state).winningAmount[type];
const fullGameListSelector = createSelector(
	[
		(state: CasinoState) => metaSelector(state).gameLists,
		(_: CasinoState, props: GamesParams) => props
	],
	(gameList, params) => {
		const { page } = params;
		const fullGames: string[] = [];

		for (let i = 1; i <= page; i++) {
			fullGames.push(
				...(gameList[getGamesKey({ ...params, page: i })] || [])
			);
		}
		return fullGames;
	}
);
const myGameListSelector = createSelector(
	[
		(state: CasinoState) => metaSelector(state).gameLists,
		(_: CasinoState, props: MyGamesParams) => props
	],
	(gameList, params) => {
		const { page } = params;
		const fullGames: string[] = [];

		for (let i = 1; i <= page; i++) {
			fullGames.push(
				...(gameList[getMyGamesKey({ ...params, page: i })] || [])
			);
		}
		return fullGames;
	}
);
/** @description note that this will return all available gameIds from page 1 to @param params.page if available */
export const getFullGameList = (params: GamesParams) => (state: CasinoState) =>
	fullGameListSelector(state, params);
export const getMyGameList = (params: MyGamesParams) => (state: CasinoState) =>
	myGameListSelector(state, params);
