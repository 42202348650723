import { schema } from 'normalizr';
import { Match } from 'sports/api/livesport365/api';
import {
	Category,
	CompetitionForSport,
	EventResponse,
	Market,
	Player,
	Selection,
	Sport,
	Submarket,
	TopCouponsListMeta,
	Stream
} from 'sports/api/sports';

import { EventMetadata } from 'sports/api/sports';
import {
	BetsHistoryResponse,
	EventPromotionResponse
} from './api/sportsbetting/api';
import {
	EventPromotionIdentifier,
	getEventPromotionId
} from './utils/eventPromotionsUtils/eventPromotionsUtils';

export const sports = new schema.Entity(
	'sports',
	{},
	{ idAttribute: (value: SportEntity) => value.key }
);
export const categories = new schema.Entity(
	'categories',
	{},
	{ idAttribute: (value: CategoryEntity) => value.key }
);
export const competitions = new schema.Entity(
	'competitions',
	{},
	{ idAttribute: (value: CompetitionEntity) => value.key }
);
export const topCoupons = new schema.Entity(
	'topCoupons',
	{},
	{ idAttribute: (value: TopCouponsEntity) => value.key }
);
export const outrights = new schema.Entity('outrights');
export const events = new schema.Entity('events');
export const competitors = new schema.Entity('competitors');
export const markets = new schema.Entity('markets');
export const submarkets = new schema.Entity('submarkets');
export const eventPromotions = new schema.Entity(
	'eventPromotions',
	{},
	{
		idAttribute: getEventPromotionId
	}
);
export const betsHistoryResponses = new schema.Entity('betsHistoryResponses');

sports.define({
	categories: [categories],
	competitions: [competitions]
});
categories.define({
	competitions: [competitions]
});
competitions.define({
	events: [events],
	category: categories,
	outrights: [events],
	sport: sports
});
events.define({
	competition: competitions,
	sport: sports
});
outrights.define({
	competition: competitions,
	sport: sports
});
markets.define({
	submarkets: [submarkets]
});
topCoupons.define({
	sport: sports
});

// Note the used types to derive the entitytpe might not be sufficient, as I did not look that closely into each response,
// however types can overwriten/extended as needed during development
// TODO: would be good to Extract The Entity Typing in another file.
export declare type SportEntityBase = Sport;
export declare type SportEntity = Omit<Sport, 'key'> & {
	categories?: string[];
	competitions?: string[];
	key: string;
	// add it here first, not updating api schema yet
	outrightCount?: number;
};
export declare type CategoryEntityBase = Category;
export declare type CategoryEntity = Omit<Category, 'key' | 'competitions'> & {
	key: string;
	competitions: string[];
};

export declare type StreamEntity = Stream;
export declare type CompetitionEntityBase = CompetitionForSport;
export declare type CompetitionEntity = Omit<CompetitionEntityBase, 'key'> & {
	events?: number[];
	outrights?: number[];
	category?: string;
	sport: string;
	key: string;
	streams?: StreamEntity[];
};
export declare type EventEntityMetadata = EventMetadata;

export declare type EventEntityBase = EventResponse;
export declare type EventEntity = Omit<
	EventEntityBase,
	| 'competition'
	| 'sport'
	| 'status'
	| 'markets'
	| 'players'
	| 'tags'
	| 'metadata'
> & {
	competition?: string;
	/** @obsolete Will only be set when querying full (main) event, get sport from route instead */
	sport?: string;
	status?: string;
	markets?: { [key: string]: MarketEntity };
	players?: { [key: string]: PlayerEntity };
	tags?: string[];
	metadata?: EventEntityMetadata;
	promotions?: string[];
};
// OutrightEntity will be as same as event
export declare type OutrightEntity = EventEntity;
export declare type TopCouponsEntity = Omit<TopCouponsListMeta, 'key'> & {
	key: string;
};
export declare type SelectionEntityBase = Selection;
export declare type SelectionEntity = Omit<
	SelectionEntityBase,
	'side' | 'status' | 'sequence'
> & {
	side?: string;
	status?: 'SELECTION_ENABLED' | 'SELECTION_DISABLED';
	sequence?: string;
};
export declare type SubmarketEntityBase = Submarket;
export declare type SubmarketEntity = Omit<
	SubmarketEntityBase,
	'selections' | 'sequence'
> & {
	selections?: Array<SelectionEntity>;
	sequence?: string;
	blank?: boolean;
};
export declare type MarketEntityBase = Market;
export declare type MarketEntity = Omit<MarketEntityBase, 'submarkets'> & {
	submarkets?: { [key: string]: SubmarketEntity } & { blank?: boolean };
	liability?: number;
};
export declare type PlayerEntityBase = Player;
export declare type PlayerEntity = Omit<Player, 'team'> & {
	team?: string;
};
export type Livesport365MatchEntity = Match;

export type EventPromotionEntity = EventPromotionResponse &
	EventPromotionIdentifier;
export declare interface Entities {
	sports?: { [key: string]: SportEntity };
	categories?: { [key: string]: CategoryEntity };
	competitions?: { [key: string]: CompetitionEntity };
	outrights?: { [key: string]: OutrightEntity };
	events?: { [key: string]: EventEntity };
	markets?: { [key: string]: MarketEntity };
	submarkets?: { [key: string]: SubmarketEntity };
	betsHistoryResponses?: { [key: string]: BetsHistoryResponse };
	topCoupons?: { [key: string]: TopCouponsEntity };
	eventPromotions?: { [key: string]: EventPromotionEntity };
}
