/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EventMetadataSet
 */
export interface EventMetadataSet {
	/**
	 *
	 * @type {Array<number>}
	 * @memberof EventMetadataSet
	 */
	score?: Array<number>;
}

export function EventMetadataSetFromJSON(json: any): EventMetadataSet {
	return EventMetadataSetFromJSONTyped(json, false);
}

export function EventMetadataSetFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): EventMetadataSet {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		score: !exists(json, 'score') ? undefined : json['score']
	};
}

export function EventMetadataSetToJSON(value?: EventMetadataSet | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		score: value.score
	};
}
