import { useScrollTrigger } from '@material-ui/core';
import * as React from 'react';

import { CustomSlide, CustomSlideProps } from '../CustomSlide';

export const HideOnScroll = ({
	children,
	sizeRef
}: Pick<CustomSlideProps, 'sizeRef' | 'children'>) => {
	const threshold =
		(sizeRef?.current &&
			parseInt(
				window.getComputedStyle(sizeRef?.current as HTMLElement).height
			)) ||
		undefined;
	const trigger = useScrollTrigger({ threshold });

	return (
		<CustomSlide
			appear={false}
			direction="down"
			in={!trigger}
			sizeRef={sizeRef}
		>
			{children}
		</CustomSlide>
	);
};
