import { Translate } from '@gaming-shell/i18n';
import {
	usePlayerEmail,
	usePlayerLoggedIn,
	usePlayerNickname
} from '@materialbet-core/player';
import { CustomerSupportIcon } from 'materialbet-icons';
import * as React from 'react';
import { ZendeskWidget } from 'materialbet-common';

import { AccountMenuItem } from '../AccountMenuItem';

export interface LiveChatMenuItemProps {
	onClick?: () => void;
}

export const LiveChatMenuItem: React.FC<LiveChatMenuItemProps> = ({
	onClick,
	...rest
}) => {
	const playerName = usePlayerNickname();
	const playerEmail = usePlayerEmail();
	const playerLoggedIn = usePlayerLoggedIn();

	// Will not render anything if apiKey is falsy
	return (
		<ZendeskWidget
			apiKey={ZENDESK_KEY}
			userName={playerName}
			userEmail={playerEmail}
			reset={!playerLoggedIn}
			onOpen={onClick}
		>
			{({ openWidget }) => (
				<AccountMenuItem {...rest} onClick={openWidget}>
					<CustomerSupportIcon />
					<Translate label="descriptions.liveChat" ns="common" />
				</AccountMenuItem>
			)}
		</ZendeskWidget>
	);
};
