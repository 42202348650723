export interface MoonPayCountry {
	alpha2: string;
	alpha3: string;
	isAllowed: boolean;
	isLightKycAllowed: boolean;
	name: string;
	supportedDocuments: string[];
}

/**
 * Makes a call to MoonPay API which returns all MoonPay's supported countries.
 * Returns array of strings contains alpha2 codes for each supported country ex. ['HK', 'PL', 'UK' ...]
 */
export const getMoonPaySupportedCountries = async (): Promise<string[]> => {
	const moonpaySupportedCountires: MoonPayCountry[] = await (
		await fetch(MOONPAY_SUPPORTED_COUNTRIES_API_URL)
	).json();
	const supportedCountries = moonpaySupportedCountires
		?.filter(({ alpha2 }) => !MOONPAY_RESTRICTED_COUNTRIES.includes(alpha2))
		.map(({ alpha2 }) => alpha2);
	return supportedCountries;
};
