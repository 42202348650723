import { GetFreespinsResponse } from 'casino/api/casino';
import { FreespinEntity, freespins } from 'casino/casinoSchemas';
import { createCasinoFetchEntityEpic } from 'casino/util/epicsUtil';
import { createFreespinsMeta } from 'casino/util/metaUtils';
import { createEntityReducer } from 'casino/util/reducerUtils';
import { finishLoading } from 'materialbet-common';
import { createAction } from 'typesafe-actions';
import { combineEpics } from 'redux-observable';

export const freespinsReducer = createEntityReducer<FreespinEntity>(
	freespins.key
);
export interface FreespinsParams {
	playerUuid: string;
}

export const getFreespinsKey = (params: FreespinsParams) =>
	`${params.playerUuid}`;
export const loads = {
	freespins: (params: FreespinsParams) =>
		`freespins/freespins_${getFreespinsKey(params)}`
};

export const actions = {
	fetchFreespins: createAction(
		'freespins/fetchFreespins',
		(resolve) => (payload: FreespinsParams) => resolve(payload)
	)
};
export const getFreespinsEpic = createCasinoFetchEntityEpic<
	FreespinsParams,
	GetFreespinsResponse
>({
	actionCreator: actions.fetchFreespins,
	fetch: (payload, { api }) =>
		api.freespins.apiV1FreespinsOffersGet(payload.playerUuid),
	resultSelector: (result) => result.freespins_offers || [],
	resultSchema: [freespins],
	createMeta: ({ payload, result }) => {
		const freespinsKey = getFreespinsKey(payload);
		const loading = finishLoading(loads.freespins(payload));
		const casino = createFreespinsMeta(result, freespinsKey);
		return { ...loading, ...casino };
	}
});

export const freespinsEpic = combineEpics(getFreespinsEpic);
