import * as React from 'react';
import { usePrevious } from 'sports/utils/hooks';
import { useFormatOdds } from 'sports/utils/odds';

import { LockIcon } from '../icons/common/LockIcon';
import { LinearIndicator, PriceChange } from './LinearIndicator';

export interface BetPriceProps {
	odds: number;
	side: 'lay' | 'back';
	hideSelection: boolean;
}

export interface BetPriceIndicatorProps {
	odds: number;
	side: 'lay' | 'back';
}

let BetPrice: React.FunctionComponent<BetPriceProps> = (props) => {
	const formatOdds = useFormatOdds();

	if (!props.odds || props.hideSelection) {
		return (
			<LockIcon color="disabled" fontSize="small" aria-label="locked" />
		);
	}
	return <>{formatOdds(props.odds)}</>;
};

let BetPriceIndicator: React.FunctionComponent<BetPriceIndicatorProps> = (
	props
) => {
	const [change, setChange] = React.useState('unchanged' as PriceChange);
	const { odds } = props;
	const previousOdds = usePrevious(odds);

	React.useEffect(() => {
		if (!previousOdds) {
			return;
		}
		if (previousOdds > odds) {
			setChange('decreased');
			return;
		}
		if (previousOdds < odds) {
			setChange('increased');
			return;
		}
	}, [previousOdds, odds]);

	if (!odds) {
		return null;
	}

	return <LinearIndicator change={change} odds={odds} />;
};

BetPrice = React.memo(BetPrice);
BetPriceIndicator = React.memo(BetPriceIndicator);
export { BetPrice, BetPriceIndicator };
