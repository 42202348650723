import { isMobile } from '@gaming-shell/device';
import { Theme } from '@gaming-shell/theming';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import BigNumber from 'bignumber.js';
import * as React from 'react';
import { CurrencyVariantConfig } from 'sports/config/currency';
import { getVariantCurrencyValue } from 'sports/utils/currency';

import { useTrailing } from '../../../utils/stake';
import {
	getMultiplesMaxStake,
	getMultiplesTotalPrice,
	getMultiplesTotalStake,
	getSelectionCount,
	getSelectionPrices
} from '../betslipSelectors/betslipSelectors';
import { BetslipStakeFormHeader } from '../BetslipStakeFormHeader';
import { useBetslipSelector } from '../hooks';
import { QuickBetslipStakeControl } from '../QuickBetslip/QuickBetslipStakeControl';
import { QuickBetslipStakeField } from '../QuickBetslip/QuickBetslipStakeField';
import { BigBetslipSinglesSummary } from './BigBetslipSinglesSummary';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
		background: isMobile()
			? theme.palette.background.paper
			: theme.palette.background.top.main
	},
	header: { paddingBottom: theme.spacing(1) }
}));
const noop = () => void undefined;

export interface UiBigBetslipAllStakesFormProps {
	isSubmitting: boolean;
	calculateTotalStake: (stake: number) => number;
	calculateTotalReturn: (stake: number) => number;
	currencyConfig: CurrencyVariantConfig;
	onConfirmClick: (stake: number) => void;
	onCloseEditStake: () => void;
	onMaxStakeClick?: () => void;
	title: React.ReactNode;
	maxStake?: number;
	initialStake?: number;
}

let UiBigBetslipAllStakesForm: React.FunctionComponent<UiBigBetslipAllStakesFormProps> = (
	props
) => {
	const {
		calculateTotalStake,
		calculateTotalReturn,
		currencyConfig,
		onConfirmClick,
		onMaxStakeClick,
		onCloseEditStake,
		isSubmitting,
		title,
		maxStake = 0,
		initialStake = 0
	} = props;
	const [stake, setStake] = React.useState(initialStake);
	const [trailing, setTrailing] = useTrailing(stake);
	const [showDialpad, setShowDialpad] = React.useState(false);
	const totalStake = React.useMemo(() => calculateTotalStake(stake), [
		calculateTotalStake,
		stake
	]);
	const totalReturn = React.useMemo(() => calculateTotalReturn(stake), [
		stake,
		calculateTotalReturn
	]);
	const totalStakeVariant = getVariantCurrencyValue(
		currencyConfig,
		totalStake
	);
	const totalReturnVariant = getVariantCurrencyValue(
		currencyConfig,
		totalReturn
	);
	const classes = useStyles();
	const handleConfirmClick = () => {
		onConfirmClick(stake);
	};
	const onStakeDelete = () => {
		setStake(0);
		setTrailing({ trail: '', forStake: 0 });
	};
	return (
		<div className={classes.root}>
			<BetslipStakeFormHeader
				disabled={false}
				close={onCloseEditStake}
				className={classes.header}
			>
				<Typography variant="h6">{title}</Typography>
			</BetslipStakeFormHeader>
			<BigBetslipSinglesSummary
				totalStake={totalStakeVariant.toString()}
				totalReturn={totalReturnVariant.toString()}
				currencyLabel={currencyConfig.label}
			/>
			<QuickBetslipStakeField
				toggleInputMethod={() => setShowDialpad(!showDialpad)}
				stake={stake}
				trailing={trailing.trail}
				setTrailing={setTrailing}
				showDialpad={showDialpad}
				onChange={setStake}
				currencyConfig={currencyConfig}
			/>
			<QuickBetslipStakeControl
				stake={stake}
				trailing={trailing.trail}
				setTrailing={setTrailing}
				showDialpad={showDialpad}
				minStake={0}
				maxStake={maxStake}
				onMaxStakeClick={onMaxStakeClick}
				onStakeChange={setStake}
				onStakeDelete={onStakeDelete}
				onConfirmClick={handleConfirmClick}
				onSecondaryActionClick={noop}
				variant="editAllStakes"
				isSubmitting={isSubmitting}
			/>
		</div>
	);
};
UiBigBetslipAllStakesForm = React.memo(UiBigBetslipAllStakesForm);

type BigBetslipAllStakesFormProps = Omit<
	UiBigBetslipAllStakesFormProps,
	'calculateTotalStake' | 'calculateTotalReturn'
>;

let BigBetslipAllStakesForm: React.FunctionComponent<BigBetslipAllStakesFormProps> = (
	props
) => {
	const count = useBetslipSelector(getSelectionCount);
	const prices = useBetslipSelector(getSelectionPrices);
	const calculateTotalStake = React.useCallback(
		(stake: number) => new BigNumber(stake).multipliedBy(count).toNumber(),
		[count]
	);
	const calculateTotalReturn = React.useCallback(
		(stake) =>
			prices
				.reduce(
					(acc, p) => acc.plus(new BigNumber(p).multipliedBy(stake)),
					new BigNumber(0)
				)
				.toNumber(),
		[prices]
	);
	return (
		<UiBigBetslipAllStakesForm
			calculateTotalStake={calculateTotalStake}
			calculateTotalReturn={calculateTotalReturn}
			{...props}
		/>
	);
};
let BigBetslipMultipleForm: React.FunctionComponent<BigBetslipAllStakesFormProps> = (
	props
) => {
	const price = useBetslipSelector(getMultiplesTotalPrice);
	const totalStake = useBetslipSelector(getMultiplesTotalStake);
	const maxAvailabeStake = useBetslipSelector(getMultiplesMaxStake);
	const calculateTotalStake = React.useCallback((stake: number) => stake, []);
	const calculateTotalReturn = React.useCallback(
		(stake) => new BigNumber(price).multipliedBy(stake).toNumber(),
		[price]
	);
	return (
		<UiBigBetslipAllStakesForm
			calculateTotalStake={calculateTotalStake}
			calculateTotalReturn={calculateTotalReturn}
			maxStake={maxAvailabeStake}
			initialStake={totalStake}
			{...props}
		/>
	);
};
BigBetslipAllStakesForm = React.memo(BigBetslipAllStakesForm);
BigBetslipMultipleForm = React.memo(BigBetslipMultipleForm);
export {
	UiBigBetslipAllStakesForm,
	BigBetslipAllStakesForm,
	BigBetslipMultipleForm
};
