"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIframeAreaStyles = void 0;
var styles_1 = require("@material-ui/core/styles");
exports.useIframeAreaStyles = styles_1.makeStyles(function (theme) { return ({
    background: function (_a) {
        var backgroundImgUrl = _a.backgroundImgUrl;
        return ({
            background: theme.palette.background.paper + "CC",
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: backgroundImgUrl
                ? "url(" + backgroundImgUrl + ")"
                : undefined
        });
    },
    iframeWrapper: {
        position: 'relative',
        top: 0,
        width: '100%',
        height: '100%',
        transition: 'width 0.1s ease-in, height 0.1s ease-in' // transition when change screen size
    },
    iframe: {
        height: '100%',
        width: '100%',
        border: 0
    },
    iframeArea: {
        position: 'relative',
        transition: 'width 0.1s ease-in, height 0.1s ease-in',
        width: '100%',
        height: '100%',
        border: 0,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    cinemaIframeArea: {
        paddingTop: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    // Override with important as the class is created before the ratio
    fullscreenIframeArea: {
        paddingTop: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh !important',
        width: '100vw !important'
    },
    fullscreenContent: {
        top: 'initial !important'
    },
    loadingWrapper: {
        position: 'absolute',
        height: '100%',
        width: '100%'
    },
    loading: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        transform: 'none',
        top: 'initial',
        left: 'initial',
        height: '100%',
        width: '100%',
        background: theme.palette.background.paper + '4D' // 30% opacity, just to make sure the text looks better
    }
}); });
