"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCompetitionRouteParams = exports.createCompetitionRouteParams = void 0;
/** Creates a sports route */
var logging_1 = require("@gaming-shell/logging");
/** @description removes the sport key from the competitionkey, so those values can be used for building nice looking routes */
exports.createCompetitionRouteParams = function (params) {
    if (typeof params.competitionKey === 'number') {
        return params;
    }
    var competionKeyWithoutSport = params.competitionKey.replace(params.sportKey + '-', '');
    if (params.competitionKey === competionKeyWithoutSport) {
        logging_1.error("sportKey '" + params.sportKey + "' not part of competitionKey: '" + params.competitionKey + "'");
    }
    return __assign(__assign({}, params), { competitionKey: competionKeyWithoutSport });
};
/** @description Takes sport key and competition key as they are in the route, and adds the sportkey to the competition key*/
exports.getCompetitionRouteParams = function (params) {
    var competitionKeyWithSport = params.sportKey + "-" + params.competitionKey;
    return __assign(__assign({}, params), { competitionKey: competitionKeyWithSport });
};
