import { Translate } from '@gaming-shell/i18n';
import { Button, Grid, makeStyles, Theme } from '@material-ui/core';
import BigNumber from 'bignumber.js';
import * as React from 'react';
import {
	getBaseCurrencyValue,
	getVariantCurrencyValue,
	usePlayerCurrencyVariantConfig
} from 'sports/utils/currency';
import { truncate } from 'sports/utils/number';
import { trackOnBetslipPreset } from 'sports/utils/trackingUtils';

import { useDialPadStyles } from './QuickBetslipStakeDialPad';

interface StakePresetInputFormProps {
	stake: number;
	onChange: (stake: number) => void;
	buttonValues: string[];
	maxStake: number;
	onMaxStakeClick?: () => void;
	disabled?: boolean;
}

const useContainerStyles = makeStyles((theme: Theme) => ({
	root: {
		marginBottom: theme.spacing(1)
	}
}));

let StakePresetInputForm: React.FunctionComponent<StakePresetInputFormProps> = (
	props
) => {
	const {
		buttonValues,
		stake,
		onChange,
		onMaxStakeClick,
		disabled,
		maxStake
	} = props;
	const numberPadClasses = useDialPadStyles();
	const containerStyleClasses = useContainerStyles();
	const currencyConfig = usePlayerCurrencyVariantConfig();
	const handleChange = (buttonValue: string) => {
		if (buttonValue === 'max') {
			if (onMaxStakeClick) {
				onMaxStakeClick();
				return;
			}
			// The maxStake is in the format of original one without any variant factor
			const variantNumber = getVariantCurrencyValue(
				currencyConfig,
				maxStake
			);
			const trimedMaxStake = getBaseCurrencyValue(
				currencyConfig,
				parseFloat(truncate(variantNumber, BETSLIP_MAX_INPUT_DP))
			);
			onChange(trimedMaxStake);

			return;
		}
		const buttonNumber = getBaseCurrencyValue(
			currencyConfig,
			parseFloat(buttonValue)
		);
		const stakeSum = new BigNumber(stake).plus(buttonNumber).toNumber();

		onChange(stakeSum);
		trackOnBetslipPreset({
			value: buttonNumber,
			currency: currencyConfig.label,
			type: stake ? 'add' : 'set'
		});
	};

	const buttonsToRender = buttonValues.map((buttonValue) => {
		const buttonText =
			buttonValue === 'max' ? (
				<Translate label="descriptions.max" />
			) : (
				<>{buttonValue}</>
			);
		return (
			<Grid item xs={3} key={buttonValue}>
				<Button
					fullWidth
					classes={numberPadClasses}
					key={buttonValue}
					variant="contained"
					onClick={() => handleChange(buttonValue)}
					disabled={disabled}
				>
					{stake !== 0 && buttonValue !== 'max' ? '+' : ''}
					{buttonText}
				</Button>
			</Grid>
		);
	});
	return (
		<Grid container spacing={1} className={containerStyleClasses.root}>
			{buttonsToRender}
		</Grid>
	);
};
StakePresetInputForm = React.memo(StakePresetInputForm);
export { StakePresetInputForm };
