import {
	ActionBarButtonProps,
	useIsBonusAvailable
} from '@gaming-shell/layout';
import { makeStyles, Theme } from '@material-ui/core/';
import {
	usePlayerCurrency,
	usePlayerCurrencyVariant,
	usePlayerLoading,
	usePlayerWallet
} from '@materialbet-core/player';
import BigNumber from 'bignumber.js';
import { formatCurrency } from 'materialbet-common';
import * as React from 'react';

import { AccountBalanceBonusAmount } from './BonusAmount';
import { AccountBalanceFundsAmount } from './FundsAmount';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		minWidth: 0,
		margin: theme.spacing(0.25),
		whiteSpace: 'nowrap'
	}
}));

const AccountBalanceButtons: Record<string, React.FC<ActionBarButtonProps>> = {
	['true']: AccountBalanceBonusAmount,
	['false']: AccountBalanceFundsAmount
};

export interface AccountBalancePureButtonProps {
	onClick?: ActionBarButtonProps['onClick'];
	href?: ActionBarButtonProps['href'];
	endIcon?: ActionBarButtonProps['endIcon'];
	classes?: ActionBarButtonProps['classes'];
	className?: string;
}

export const AccountBalancePureButton: React.FC<AccountBalancePureButtonProps> = ({
	className,
	...otherActionBarButtonProps
}) => {
	const { amount, bonusAmount } = usePlayerWallet();
	const classes = useStyles();

	const currency = usePlayerCurrency();
	const variant = usePlayerCurrencyVariant();
	const loading = usePlayerLoading();
	const isBonusAvailable = useIsBonusAvailable();
	const amountToDisplay = isBonusAvailable
		? new BigNumber(amount).plus(bonusAmount).toNumber()
		: amount;
	const AccountBalanceButton =
		AccountBalanceButtons[(isBonusAvailable && amount === 0).toString()];

	return (
		<AccountBalanceButton
			className={clsx(classes.root, className)}
			skeleton={loading}
			aria-label="Account balance"
			{...otherActionBarButtonProps}
		>
			{formatCurrency(amountToDisplay, currency, {
				variant,
				keepTrailingZeros: true,
				maxDecimalPlaces: ACCOUNT_MENU_MAX_DECIMAL_PLACES
			})}
		</AccountBalanceButton>
	);
};
