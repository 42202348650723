import { isMobile } from '@gaming-shell/device';
import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

const allMarkets = {
	popular: [
		{
			marketGroup: [
				{
					marketType: MarketType.baseball_moneyline,
					disableAltLines: true
				},
				{
					marketType: MarketType.baseball_run_line,
					view: MarketView.Handicap,
					disableAltLines: true
				},
				{
					marketType: MarketType.baseball_totals,
					view: MarketView.Goals,
					disableAltLines: true
				}
			],
			view: MarketView.GameLine
		},
		{
			marketType: MarketType.baseball_run_line,
			view: MarketView.Handicap,
			disableMainLine: true,
			collapsedByDefault: isMobile()
		},
		{
			marketType: MarketType.baseball_totals,
			view: MarketView.Goals,
			disableMainLine: true,
			collapsedByDefault: isMobile()
		},
		{
			marketType: MarketType.baseball_team_totals,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.baseball_moneyline_innings_1_to_5
		},
		{
			marketType: MarketType.baseball_handicap_innings_1_to_5,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.baseball_totals_innings_1_to_5,
			view: MarketView.Goals
		},
		{
			marketType:
				MarketType.baseball_which_team_wins_the_rest_of_the_match
		},
		{
			marketType: MarketType.baseball_race_to_runs
		},
		{
			marketType: MarketType.baseball_with_extra_inning
		},
		{
			marketType: MarketType.baseball_total_scoreless_innings
		},
		{
			marketType: MarketType.baseball_max_consecutive_runs
		},
		{
			marketType: MarketType.baseball_inning_match_decision
		},
		{
			marketType: MarketType.baseball_odd_even
		},
		{
			marketType: MarketType.baseball_batter_runs
		}
	],
	specials: [
		{
			marketType: MarketType.baseball_freetext
		}
	]
};

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: allMarkets.popular
	},
	{
		label: 'markets.specials',
		markets: allMarkets.specials
	},
	{
		label: 'descriptions.all',
		markets: [...allMarkets.popular, ...allMarkets.specials]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.baseball_moneyline
			},
			{
				marketType: MarketType.baseball_totals
			},
			{
				marketType: MarketType.baseball_run_line
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.baseball_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
