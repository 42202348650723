import { FC } from 'react';

import {
	useLoadBetslipOnMount,
	usePersistBetslipOnUnload
} from '../persistBetslip';
import { PlaceBetCallback } from '../placeBet/useBetslipPlaceBet';

export interface BetslipPersisterProps {
	onBetPlaced: PlaceBetCallback;
}
export const BetslipPersister: FC<BetslipPersisterProps> = ENABLE_PERSIST_BETSLIP
	? ({ onBetPlaced }) => {
			usePersistBetslipOnUnload();
			useLoadBetslipOnMount(onBetPlaced);
			return null;
	  }
	: () => null;
