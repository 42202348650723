import { isMobile } from '@gaming-shell/device';
import { Translate } from '@gaming-shell/i18n';
import { Drawer } from '@material-ui/core';
import * as React from 'react';

import { betslipActions } from '../betslipReducer';
import { BigBetslipAllStakesForm } from './BigBetslipAllStakesForm';
import { BigBetslipEditSelectionStakeForm } from './BigBetslipEditSelectionStakeForm';
import { BigBetslipSinglesBigButton } from './BigBetslipSinglesBigButton';
import { BigBetslipSinglesPanelContent } from './BigBetslipSinglesPanelContent';
import { BigBetslipActionPanelProps } from './types';

export const BigBetslipSinglesActionPanel: React.FunctionComponent<BigBetslipActionPanelProps> = (
	props
) => {
	const {
		currentEditStakeView,
		setCurrentEditStakeView,
		dispatch,
		currencyConfig,
		onBetPlaced,
		isSubmitting
	} = props;

	const handleSetAllStakes = React.useCallback(
		(stake: number) => {
			dispatch(betslipActions.updateAllSelectionStakes(stake));
			setCurrentEditStakeView();
		},
		[dispatch, setCurrentEditStakeView]
	);

	const handleSetSelectionStake = React.useCallback(
		(stake: number) => {
			if (currentEditStakeView && currentEditStakeView.straight) {
				dispatch(
					betslipActions.updateSelectionStake({
						selectionIndex:
							currentEditStakeView.straight.selectionIndex,
						stake
					})
				);
			}
			setCurrentEditStakeView();
		},
		[currentEditStakeView, dispatch, setCurrentEditStakeView]
	);

	const handleSetAllStakesMax = React.useCallback(() => {
		dispatch(betslipActions.setAllSelectionsMaxStake());
		setCurrentEditStakeView();
	}, [dispatch, setCurrentEditStakeView]);

	const startEditingStakeForAll = () =>
		setCurrentEditStakeView({ allSelections: true });

	const form =
		currentEditStakeView && currentEditStakeView.straight ? (
			<BigBetslipEditSelectionStakeForm
				selectionIndex={currentEditStakeView.straight.selectionIndex}
				currencyConfig={currencyConfig}
				onCloseEditStake={setCurrentEditStakeView}
				onSetAllStakes={handleSetAllStakes}
				onSetSelectionStake={handleSetSelectionStake}
			/>
		) : (
			<BigBetslipAllStakesForm
				onConfirmClick={handleSetAllStakes}
				currencyConfig={currencyConfig}
				onCloseEditStake={setCurrentEditStakeView}
				onMaxStakeClick={handleSetAllStakesMax}
				isSubmitting={isSubmitting}
				title={<Translate label="betting.allSelections" ns="sports" />}
			/>
		);
	const showForm = !!(
		currentEditStakeView &&
		(currentEditStakeView.straight || currentEditStakeView.allSelections)
	);
	const wrappedForm = isMobile() ? (
		<Drawer open={showForm} anchor="bottom">
			{form}
		</Drawer>
	) : (
		showForm && form
	);
	const bigButton = (
		<BigBetslipSinglesBigButton
			onEditStakeForAll={startEditingStakeForAll}
			onBetPlaced={onBetPlaced}
		/>
	);
	const showPanelContent = !showForm || isMobile();
	return (
		<>
			{showPanelContent && (
				<BigBetslipSinglesPanelContent
					currencyConfig={currencyConfig}
					button={bigButton}
				/>
			)}
			{wrappedForm}
		</>
	);
};
