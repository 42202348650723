import { isMobile } from '@gaming-shell/device';
import { CasinoRoutes } from 'materialbet-common';
import { useLocation } from 'react-router';

import { useDispatchSections } from '../util/sectionUtils';

export const FetchSections = () => {
	const location = useLocation();
	// on desktop we dont need the sections to be fetched in the sports area - on mobile we should always have them
	const condition =
		isMobile() || CasinoRoutes.root.matches(location.pathname);
	useDispatchSections({ live: true, condition });
	useDispatchSections({ live: false, condition });
	return null;
};
