import * as React from 'react';
import { useLocation } from 'react-router';

export const ScrollToTop: React.FunctionComponent<unknown> = () => {
	const location = useLocation();
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return null;
};
