import produce from 'immer';
import { ActionType, createAction } from 'typesafe-actions';

import {
	BetslipMode,
	BetslipPlaceBetChanges,
	BetslipReducerBetslip,
	BetslipReducerSelection,
	BetslipState,
	BetslipUpdateSelectionPayload,
	BetslipValidatePayload,
	StakeLocation,
	UpdateMultipleStakePayload
} from '../types';

const action = <T extends unknown>(type: string) =>
	createAction(type, (resolve) => (payload: T) => resolve(payload));

export const betslipActions = {
	addSelection: action<{
		selection: BetslipReducerSelection;
	}>('addSelection'),
	/** @description Updates a selection, if the selection is not in the array, no changes will be*/
	updateSelection: action<BetslipUpdateSelectionPayload>('updateSelection'),
	/** @description Updates the stake of the selection directly via selectionIndex. As its faster and stake updates have to be responsive. Could potentially run minor validation */
	updateSelectionStake: action<{ selectionIndex: number; stake: number }>(
		'updateSelectionStake'
	),
	resetSelectionStake: action<number>('resetSelectionStake'),
	/** @description Updates the stake of all selections, and validates it against the selection*/
	updateAllSelectionStakes: action<number>('updateAllSelectionStakes'),
	/** @description Sets stake of all selections to each individuals max stake*/
	setAllSelectionsMaxStake: createAction(
		'setAllSelectionsMaxStake',
		(resolve) => () => resolve()
	),
	/**  @description Removes selection via selection index */
	removeSelection: action<number>('removeSelection'),
	updateMultipleStake: action<UpdateMultipleStakePayload>(
		'updateMultipleStake'
	),
	setPlaceBetChanges: action<BetslipPlaceBetChanges>('setPlaceBetChanges'),
	/** @description Updates or sets a selection change. If there is no change at all a new changed betslip will be created */
	changeSelection: action<BetslipUpdateSelectionPayload>('changeSelection'),
	/** @description Validates the betslip for multiple and singles, it will be up to the UI todecide wether or not to show specific error status */
	validate: action<BetslipValidatePayload>('validate'),
	submit: action<{ betslipId: string }>('submit'),
	/** @descriptions resets the betslip selections and stakes. It keeps acceptBetterOdds setting as is.*/
	clear: createAction('clear', (resolve) => () => resolve()),
	setAcceptBetterOdds: action<boolean>('setAcceptBetterOdds'),
	/** @description Resets the betslip to the given betslip data */
	setBetslip: action<BetslipReducerBetslip>('setBetslip'),
	/** @description Resets the state to the given state data - used for loading persisted betslip*/
	setState: action<BetslipState>('setState'),
	/** @description overwrites the current betslip with the change */
	applyChanges: createAction('applyChanges', (resolve) => () => resolve()),
	/** @description Applies min or max stake if above min or max stake */
	correctStake: action<StakeLocation>('correctStake'),
	/** @description Removes all selection with non correctable status */
	removeInvalidSelections: createAction(
		'removeInvalidSelections',
		(resolve) => () => resolve()
	),
	setMode: action<BetslipMode>('setMode')
};

type ActionsObject = typeof betslipActions;

export const createProducer = <T extends keyof ActionsObject>(
	producer: (
		draft: BetslipState,
		action: ReturnType<typeof betslipActions[T]>
	) => void
) => produce(producer);

export type BetslipActions = ActionType<typeof betslipActions>;
