import { api } from '@materialbet-core/api';
import {
	Player,
	PreferredOddsFormat,
	usePlayerAuthToken,
	useUpdatePlayer
} from '@materialbet-core/player';
import i18next from 'i18next';
import { useSnackbar } from 'materialbet-common';
import * as React from 'react';

export interface UpdatePreferencesArgs {
	timeZone?: string;
	preferredOddsFormat?: string;
}

export interface Submitting {
	timeZone: boolean;
	preferredOddsFormat: boolean;
}

export type UseUpdatePreferencesOutput = [
	Submitting,
	(args: UpdatePreferencesArgs) => void
];

const initialSubmittingState: Submitting = {
	timeZone: false,
	preferredOddsFormat: false
};

const trans = i18next.t.bind(i18next);
const onChange = (
	setSubmitting: React.Dispatch<React.SetStateAction<Submitting>>,
	updatePlayer: React.Dispatch<React.SetStateAction<Player>>,
	updateSnackbar: (text: string) => void,
	csrfToken: string
) => ({ timeZone, preferredOddsFormat }: UpdatePreferencesArgs) => {
	if (timeZone || preferredOddsFormat) {
		setSubmitting({
			timeZone: !!timeZone,
			preferredOddsFormat: !!preferredOddsFormat
		});
		api.putUpdatePreferences(
			{
				// eslint-disable-next-line @typescript-eslint/camelcase
				...(timeZone && { time_zone: timeZone }),
				...(preferredOddsFormat && {
					// eslint-disable-next-line @typescript-eslint/camelcase
					preferred_odds_format: preferredOddsFormat
				})
			},
			csrfToken
		)
			.then((response) => {
				if (response.result === 'OK') {
					updateSnackbar(
						timeZone
							? trans('player:preferences.timeZoneSuccess', {
									timeZone,
									interpolation: { escapeValue: false }
							  })
							: trans(
									'player:preferences.preferredOddsFormatSuccess',
									{
										odds: trans(
											`player:preferences.oddsFormats.${preferredOddsFormat}`
										)
									}
							  )
					);
					timeZone &&
						updatePlayer((player: Player) => ({
							...player,
							timeZone
						}));
					preferredOddsFormat &&
						updatePlayer((player: Player) => ({
							...player,
							preferredOddsFormat: preferredOddsFormat as PreferredOddsFormat
						}));
				}
			})
			.catch(() => {
				updateSnackbar(trans('player:descriptions.failedRequest'));
			})
			.finally(() => setSubmitting(initialSubmittingState));
	}
};

const useUpdatePreferences = (): UseUpdatePreferencesOutput => {
	const [submitting, setSubmitting] = React.useState(initialSubmittingState);
	const authToken = usePlayerAuthToken();
	const updatePlayer = useUpdatePlayer();
	const { enqueueSnackbar } = useSnackbar();

	return [
		submitting,
		onChange(setSubmitting, updatePlayer, enqueueSnackbar, authToken)
	];
};

export default useUpdatePreferences;
