"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNetworkStatus = void 0;
var React = __importStar(require("react"));
var useNetworkStatus = function (initialEffectiveConnectionType) {
    var _a;
    var effectiveType = (_a = navigator === null || navigator === void 0 ? void 0 : navigator.connection) === null || _a === void 0 ? void 0 : _a.effectiveType;
    var unsupported = effectiveType === undefined;
    var initialNetworkStatus = {
        unsupported: unsupported,
        effectiveConnectionType: unsupported
            ? initialEffectiveConnectionType
            : effectiveType
    };
    var _b = React.useState(initialNetworkStatus), networkStatus = _b[0], setNetworkStatus = _b[1];
    React.useEffect(function () {
        if (unsupported) {
            return;
        }
        var navigatorConnection = navigator.connection;
        var updateECTStatus = function () {
            setNetworkStatus({
                effectiveConnectionType: navigatorConnection && navigatorConnection.effectiveType
            });
        };
        navigatorConnection &&
            navigatorConnection.addEventListener('change', updateECTStatus);
        return function () {
            navigatorConnection &&
                navigatorConnection.removeEventListener('change', updateECTStatus);
        };
    }, []);
    return __assign(__assign({}, networkStatus), { setNetworkStatus: setNetworkStatus });
};
exports.useNetworkStatus = useNetworkStatus;
