import { Configuration } from './configuration';

export interface Sport {
	id: string;
	name: string;
}

export interface Tournament {
	id: string;
	name: string;
}

export interface Attributes {
	startAt: string;
	finishAt: string;
	sport: Sport;
	tournament: Tournament;
	round?: unknown;
	title: string;
	opponent: string;
	team: string;
	status: string;
	safely: string;
	state: string;
}

export interface Sources {
	betradar_id: string;
}

export interface Video {
	iframe: string;
	embeded: string;
}

export interface Content {
	video: Video;
}
export interface Match {
	match_id: string;
	attributes: Attributes;
	sources: Sources;
	content: Content;
	timezone: string;
}

export interface LiveResponse200 {
	[betradarId: number]: Match;
}

export default (configuration: Configuration) => {
	const baseOptions = configuration.baseOptions || {};
	const basePath = configuration.basePath || '';
	const api = {
		getLiveMatches: (): Promise<LiveResponse200> => {
			return fetch(basePath + '/data/live.json', baseOptions).then(
				(response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				}
			);
		}
	};

	return api;
};
