import { Dispatch } from 'redux';
import sportsApi from 'sports/api/sports';

import { BetslipActions, betslipActions } from '../betslipReducer';
import { BetslipReducerSelection } from '../types';
import { createSelectionUpdate } from './createSelectionUpdate';
import { getEventSelectionByBetslipSelection } from './getEventSelectionByBetslipSelection';
import { getSelectionsMarkets } from './getSelectionsMarkets';
import { groupSelectionsByEventId } from './groupSelectionsByEventId';

export const refetchBetslipData = (
	selections: BetslipReducerSelection[],
	dispatch: Dispatch<BetslipActions>
) => {
	const groups = groupSelectionsByEventId(selections);
	const promises = Object.keys(groups).map((eventId) => {
		const group = groups[parseFloat(eventId)];
		const markets = getSelectionsMarkets(group);
		sportsApi
			.getMainEvent(parseFloat(eventId), markets)
			.then((event) => {
				group.forEach((s) => {
					const eventSelection = getEventSelectionByBetslipSelection(
						event,
						s
					);
					const selectionUpdate = createSelectionUpdate(
						eventSelection,
						s
					);
					dispatch(betslipActions.changeSelection(selectionUpdate));
				});
			})
			.catch((e: Response) => {
				if (e && e.status && e.status > 300 && e.status < 400) {
					// network error can try again here
					// TODO build something to check network status - dont blindly retry if we know we are offline
				}
			});
	});
	return Promise.all(promises);
};
