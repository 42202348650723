import { CasinoState } from 'casino/modules/rootModule';

export type FreespinId = string | undefined;
const freespinsSelector = (state: CasinoState) => state.freespins;
export const getFreespin = (id: FreespinId) => (state: CasinoState) =>
	id === undefined ? undefined : freespinsSelector(state)[id];

const forFreespin = <T>(
	selector: (
		freespin: NonNullable<ReturnType<ReturnType<typeof getFreespin>>>
	) => T
) => (id: FreespinId) => (state: CasinoState) => {
	const f = getFreespin(id)(state);
	return f ? selector(f) : f;
};
export const getFreespinCasinoCurrency = forFreespin((f) => f.casino_currency);
export const getFreespinGameId = forFreespin((f) => f.game);
export const getFreespinExpiresAt = forFreespin((f) => f.expires_at);
export const getFreespinSpinsAwarded = forFreespin((f) => f.spins_awarded);
