import { useUpdateAnnouncements } from '@gaming-shell/layout';
import { usePusher } from '@gaming-shell/pusher';
import { usePlayerChannel, useUpdatePlayer } from '@materialbet-core/player';
import { parsePusherAnnouncement } from 'core/utils/announcementsParser';
import { parseWallets } from 'core/utils/parseWallet';
import { PusherAnnouncement } from 'materialbet-common';
import * as React from 'react';
import { reassembleFrames } from 'sports/utils/pusherparser';

export const PusherHandlers: React.FC = () => {
	const updatePlayer = useUpdatePlayer();
	const channel = usePlayerChannel();
	const setAnnouncements = useUpdateAnnouncements();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	usePusher('balance-updated', channel, (data: any) => {
		updatePlayer((player) => ({
			...player,
			wallets: {
				...player.wallets,
				...parseWallets(data.wallets)
			}
		}));
	});
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	usePusher('forced-sms-verification-updated', channel, (data: any) => {
		updatePlayer((player) => ({
			...player,
			forcedRoutes: {
				...player.forcedRoutes,
				sms: data.forced_sms_verification
			}
		}));
	});
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	usePusher('restriction-tier-updated', channel, (data: any) => {
		updatePlayer((player) => ({
			...player,
			forcedRoutes: {
				...player.forcedRoutes,
				countryRestrictionTier: data.restrictionTier
			}
		}));
	});
	usePusher(
		'update',
		'site_notifications',
		reassembleFrames((data: PusherAnnouncement) => {
			const announcement = parsePusherAnnouncement(data);
			setAnnouncements((prevAnnouncements) => {
				return [
					...prevAnnouncements.filter(
						(prevAnnouncement) =>
							prevAnnouncement.id !== announcement.id
					),
					announcement
				];
			});
		})
	);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	usePusher('currencies-available-updated', channel, (data: any) => {
		updatePlayer((player) => ({
			...player,
			currenciesAvailable: data.currencies_available
		}));
	});

	return null;
};
