import React from 'react';

export const useBeforeUnload = (callbackBeforeUnload: () => void) => {
	React.useEffect(() => {
		const handleBeforeUnload = () => {
			callbackBeforeUnload();
			return null; /* required */
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [callbackBeforeUnload]);
};
