import { isMobile } from '@gaming-shell/device';
import { Translate } from '@gaming-shell/i18n';
import { Theme } from '@gaming-shell/theming';
import {
	Box,
	Chip,
	makeStyles,
	Tab,
	Tabs,
	Typography
} from '@material-ui/core';
import * as React from 'react';
import { CurrencyVariantConfig } from 'sports/config/currency';
import { useFunds } from 'sports/utils/hooks';

import { BetslipErrorSlip } from '../BetslipErrorSlip';
import { betslipActions } from '../betslipReducer';
import { modeSelector } from '../betslipSelectors';
import { BetslipStakeFormHeader } from '../BetslipStakeFormHeader';
import { createOnSelectionUpdate } from '../createOnSelectionUpdate';
import { useBetslipSelector } from '../hooks';
import { useBetslipDispatch } from '../hooks/useBetslipDispatch';
import { PlaceBetCallback } from '../placeBet/useBetslipPlaceBet';
import { BetslipMode, BetslipReducerStatus, StakeLocation } from '../types';
import { BigBetslipActionPanel } from './BigBetslipActionPanel';
import { BigBetslipCountButton } from './BigBetslipCountButton';
import { BigBetslipOptions } from './BigBetslipOptions';
import { BigBetslipParlay } from './BigBetslipParlay';
import { BigBetslipSingles } from './BigBetslipSingles';
import { BigBetslipWrapper } from './BigBetslipWrapper';
import {
	BigBetslipActionPanelVariant,
	BigBetslipTabProps,
	CurrentEditStakeView
} from './types';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		borderRadius: 0,
		maxHeight: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	title: {
		flexGrow: 1
	},
	header: {
		background: theme.palette.background.top.main
	},
	padding: {
		padding: theme.spacing(2)
	},
	chip: {
		marginLeft: theme.spacing(1)
	},
	tabs: {
		background: 'transparent'
	}
}));
const useSingleTabsStyles = makeStyles({
	wrapper: {
		flexDirection: 'row'
	}
});

const tabComponents: Record<
	BetslipMode,
	React.ElementType<BigBetslipTabProps>
> = {
	singles: BigBetslipSingles,
	multiples: BigBetslipParlay
};
const panelVariants: Record<BetslipMode, BigBetslipActionPanelVariant> = {
	singles: 'singles',
	multiples: 'parlay'
};
const indexToTab: ['singles', 'multiples'] = ['singles', 'multiples'];
const tabToIndex: Record<BetslipMode, 0 | 1> = {
	singles: 0,
	multiples: 1
};
const stakeLocation: Record<BetslipMode, StakeLocation> = {
	singles: { allSelections: true },
	multiples: { multiple: { system: 0 }, allSelections: true }
};
export interface BigBetslipProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	selectionCount: number;
	showBetslipButton: boolean;
	isSubmitting: boolean;
	errors: BetslipReducerStatus;
	onBetPlaced: PlaceBetCallback;
	currencyConfig: CurrencyVariantConfig;
	disableMultiples: boolean;
}
let BigBetslip: React.FunctionComponent<BigBetslipProps> = (props) => {
	const {
		isSubmitting,
		selectionCount,
		setOpen,
		open,
		showBetslipButton,
		onBetPlaced,
		currencyConfig,
		errors,
		disableMultiples
	} = props;
	const [currentEditStakeView, setCurrentEditStakeView] = React.useState<
		CurrentEditStakeView | undefined
	>();
	const dispatch = useBetslipDispatch();
	const mode = useBetslipSelector(modeSelector);
	const classes = useStyles();
	const TabComponent = tabComponents[mode];
	const singleTabClasses = useSingleTabsStyles();
	const panelVariant = panelVariants[mode];
	const disabled = isSubmitting;
	const onOpen = React.useCallback(() => setOpen(true), [setOpen]);
	const onClose = React.useCallback(() => !isSubmitting && setOpen(false), [
		setOpen,
		isSubmitting
	]);
	const funds = useFunds();
	const onSelectionUpdate = React.useMemo(
		() => createOnSelectionUpdate(dispatch),
		[dispatch]
	);
	const onTabChange = (_: unknown, value: number) => {
		const tabType = indexToTab[value];
		dispatch(betslipActions.setMode(tabType));
		dispatch(
			betslipActions.validate({
				funds
			})
		);
		setCurrentEditStakeView(undefined);
	};
	const disableTabs = isSubmitting;
	return (
		<>
			<BigBetslipWrapper open={open} onClose={onClose}>
				<div className={classes.header}>
					<BetslipStakeFormHeader
						close={onClose}
						disabled={disabled}
						className={classes.padding}
					>
						<Typography variant="h6" className={classes.title}>
							<Translate label="betting.betslip" ns="sports" />
						</Typography>
						<BigBetslipOptions />
					</BetslipStakeFormHeader>
					<Tabs
						value={tabToIndex[mode]}
						variant="fullWidth"
						onChange={onTabChange}
						className={classes.tabs}
						disabled={disableTabs}
					>
						<Tab
							classes={singleTabClasses}
							label={
								<>
									<Translate
										label="betting.systems.single"
										ns="sports"
									/>
									<Chip
										className={classes.chip}
										label={selectionCount}
										size="small"
									/>
								</>
							}
							disabled={disableTabs}
						/>
						<Tab
							label={
								<Translate
									label="betting.systems.parlay"
									ns="sports"
								/>
							}
							disabled={disableTabs || disableMultiples}
						/>
					</Tabs>
				</div>
				<Box
					flexGrow="1"
					overflow="auto"
					height="100%"
					bgcolor={
						isMobile() ? 'background.default' : 'background.paper'
					}
				>
					<TabComponent
						onSelectionUpdate={onSelectionUpdate}
						currencyConfig={currencyConfig}
						setCurrentEditStakeView={setCurrentEditStakeView}
					/>
				</Box>
				<BetslipErrorSlip
					minStake={0}
					maxStake={0}
					errors={errors}
					stakeLocation={stakeLocation[mode]}
					summary={true}
				/>
				<BigBetslipActionPanel
					variant={panelVariant}
					currentEditStakeView={currentEditStakeView}
					setCurrentEditStakeView={setCurrentEditStakeView}
					dispatch={dispatch}
					currencyConfig={currencyConfig}
					onBetPlaced={onBetPlaced}
					isSubmitting={isSubmitting}
				/>
			</BigBetslipWrapper>

			<BigBetslipCountButton
				onClick={onOpen}
				count={selectionCount}
				hidden={!showBetslipButton}
				float={true}
			/>
		</>
	);
};
BigBetslip = React.memo(BigBetslip);
export { BigBetslip };
