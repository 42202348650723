import { useLanguage } from '@gaming-shell/i18n';
import { usePlayerTimeZone, usePlayerWallet } from '@materialbet-core/player';
import * as React from 'react';
import { CSSProperties } from 'react';
import { DefaultRootState, shallowEqual, useSelector } from 'react-redux';
import { EventData, MarketType } from 'sports-sdk';
import { Outcome as OutcomeType } from 'sports-sdk/sdk-api-types';
import { getOutcome } from 'sports-sdk/sports-core';
import { useSelectionIsInBetslip } from 'sports/components/betterBetslip';
import { RootState } from 'sports/modules/root';
import {
	getCompetitorAwayName,
	getCompetitorHomeName,
	getPlayers
} from 'sports/selectors/eventSelectors';

import {
	makeGetFirstSubmarket,
	makeGetSelection,
	SelectionIdentity
} from 'sports/selectors/selectionSelectors';

import { getIpCountry } from '../selectors/metaSelectors';
import { hashCode, intToHex } from './color';
import { getTimeZone } from './date';
import { getSdkLocale } from './locale';
import { useStakeLimitLabel } from './stake';
import { useOutcomeStyles } from './styles';

export const useMakeSelector = <
	TState extends DefaultRootState,
	TResult,
	TProps
>(
	makeSelector: () => (s: TState, p?: TProps) => TResult,
	props: TProps,
	equalityFn?: (left: TResult, right: TResult) => boolean
) => {
	const { current: selector } = React.useRef(makeSelector());
	return useSelector<TState, TResult>(
		(state) => selector(state, props),
		equalityFn
	);
};

export const useToggleState = (
	defaultValue: boolean
): [boolean, () => void] => {
	const [state, setState] = React.useState(defaultValue);
	const toggle = React.useCallback(() => setState((s) => !s), []);
	return [state, toggle];
};
export const usePrevious = <T extends unknown>(value: T) => {
	const ref = React.useRef<T | undefined>(undefined);
	React.useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};
export const useTimeZone = () => {
	const playerTimeZone = usePlayerTimeZone();
	if (!playerTimeZone || playerTimeZone.length === 0) {
		return getTimeZone();
	}
	return playerTimeZone;
};
export const useFunds = () => {
	const wallet = usePlayerWallet();
	// How come Core still has not fixed this?, they type it as number but its a string property...
	const funds =
		parseFloat(wallet.amount.toString()) +
		parseFloat(wallet.bonusAmount.toString());
	return funds;
};

export interface OutcomeVariablesType {
	outcome: string;
	eventId: number;
	marketKey: MarketType;
	submarketKey: string;
	outcomeStringType?: string;
	params: string;
}
export const getOutcomeHighlightStyles = (submarketObject: {
	provider?: string | undefined;
}) => {
	if (!ENABLE_PROVIDER_COLORCODING) {
		return undefined;
	}

	const provider = (submarketObject as { provider?: string | undefined })
		?.provider;
	if (!provider) {
		return undefined;
	}
	const style: CSSProperties = {
		backgroundColor: `#${intToHex(hashCode(provider))}`
	};
	return style;
};

/**@description Used to get Outcome related variables to pass into <OutcomeButton/> and <OutcomeDisplayText/> in market outcomes */
export const useOutcomeVariables = ({
	/**@todo outcome type needs to be updated to string[]. But this will impact all other market views */
	outcome,
	eventId,
	marketKey,
	params,
	submarketKey
}: OutcomeVariablesType) => {
	const language = useLanguage();
	const getFirstSubmarket = makeGetFirstSubmarket();
	const submarketObject = useSelector((state: RootState) =>
		getFirstSubmarket(state, {
			eventId,
			marketType: marketKey,
			outcome: outcome || ''
		})
	);

	const selectionId = {
		eventId,
		params,
		outcome,
		marketType: marketKey,
		submarketKey
	};

	const fullSelection = useMakeSelector(
		() => {
			const getSelection = makeGetSelection();
			return (state: RootState, props: SelectionIdentity) => {
				const selection = getSelection(state, props);
				if (!selection) {
					return;
				}
				return {
					minStake: selection.minStake,
					maxStake: selection.maxStake,
					status: selection.status,
					price: selection.price
				};
			};
		},
		selectionId,
		shallowEqual
	);

	/**@description getOutcome from SDK only uses these properties from eventData. Will need to update sdk */
	const homeName = useSelector(getCompetitorHomeName(eventId));
	const awayName = useSelector(getCompetitorAwayName(eventId));
	const players = useSelector(getPlayers(eventId));

	/**@description temporary narrowed down EventData obejct for getOutcome sdk function */
	const slicedEvent = {
		home: { name: homeName },
		away: { name: awayName },
		players: players
	};

	const selection = { params, outcome };
	const outcomeClasses = useOutcomeStyles();
	const outcomeDetails = getOutcome(
		getSdkLocale(language),
		slicedEvent as EventData,
		marketKey as MarketType,
		selection
	);

	const hideSelection = fullSelection?.status === 'SELECTION_DISABLED';
	const disabled = !fullSelection?.price || hideSelection;

	const outcomeName = outcomeDetails?.[0]?.name || '';

	const outcomeObject = {
		back: {
			minStake: fullSelection?.minStake || 0,
			maxStake: fullSelection?.maxStake || 0
		},
		name: outcomeName
	} as OutcomeType;
	const stakeLabel = useStakeLimitLabel(outcomeObject);
	const betslipSelection = {
		price: 0, // selection is only used for to check
		eventId,
		marketKey,
		...selection
	};
	const isInBetslip = useSelectionIsInBetslip(betslipSelection);
	const styles = getOutcomeHighlightStyles(
		submarketObject as { provider?: string | undefined }
	);

	return {
		isInBetslip,
		stakeLabel,
		outcomeClasses,
		disabled,
		outcomeDetails,
		styles,
		outcomeName
	};
};
/** @description Similar to use effect, but runs again whenever ipCountry has changed */
export const useCountryEffect = (
	callback: React.EffectCallback,
	dependencyList: React.DependencyList = []
) => {
	const country = useSelector(getIpCountry);
	const prevCountry = usePrevious(country);
	const callbackRef = React.useRef(callback);
	callbackRef.current = callback;
	const callbackedAlready = React.useRef(false);
	callbackedAlready.current = false;
	React.useEffect(() => {
		if (country && prevCountry && prevCountry !== country) {
			callbackedAlready.current = true;
			return callbackRef.current();
		}
	}, [country, prevCountry]);

	React.useEffect(() => {
		if (callbackedAlready.current) {
			// the same render also included a country change, thus callback has already been called
			// we dont want to call it again
			return;
		}
		return callbackRef.current();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencyList);
};
