"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var platform_1 = require("./platform");
Object.defineProperty(exports, "isMobile", { enumerable: true, get: function () { return platform_1.isMobile; } });
Object.defineProperty(exports, "isDesktop", { enumerable: true, get: function () { return platform_1.isDesktop; } });
Object.defineProperty(exports, "getPlatform", { enumerable: true, get: function () { return platform_1.getPlatform; } });
Object.defineProperty(exports, "isSafari", { enumerable: true, get: function () { return platform_1.isSafari; } });
Object.defineProperty(exports, "isIOS", { enumerable: true, get: function () { return platform_1.isIOS; } });
var performance_1 = require("./performance");
Object.defineProperty(exports, "getMemoryStatus", { enumerable: true, get: function () { return performance_1.getMemoryStatus; } });
Object.defineProperty(exports, "getHardwareConcurrencyInfo", { enumerable: true, get: function () { return performance_1.getHardwareConcurrencyInfo; } });
Object.defineProperty(exports, "useNetworkStatus", { enumerable: true, get: function () { return performance_1.useNetworkStatus; } });
Object.defineProperty(exports, "getSaveData", { enumerable: true, get: function () { return performance_1.getSaveData; } });
