"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBaseRoute = void 0;
var react_router_1 = require("react-router");
var routes_1 = require("../../routes");
/** @description Hook to get base route for current route */
exports.useBaseRoute = function () {
    var pathname = react_router_1.useLocation().pathname;
    if (routes_1.CasinoRoutes.root.matches(pathname)) {
        return routes_1.CasinoRoutes.root.create();
    }
    if (ENABLE_ESPORTS_SECTION && routes_1.EsportsRoutes.root.matches(pathname)) {
        return routes_1.EsportsRoutes.root.create();
    }
    return routes_1.SportsRoutes.root.create();
};
