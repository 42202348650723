import { sportInfo, SportType, TabConfiguration } from 'sports/config/sport';

export const getEventMarketConfiguration = (
	sportKey: SportType
): TabConfiguration[] => sportInfo[sportKey]?.eventMarketConfig || [];

export const getCompetitionMarketConfiguration = (
	sportKey: SportType
): TabConfiguration =>
	sportInfo[sportKey]?.competitionMarketConfig?.[0] || {
		markets: [],
		label: ''
	};
export const getOutrightMarketConfiguration = (
	sportKey: SportType
): TabConfiguration =>
	sportInfo[sportKey]?.outrightMarketConfig || {
		markets: [],
		label: ''
	};
