export enum CommonSportType {
	'american-football' = 'american-football',
	'aussie-rules' = 'aussie-rules',
	'badminton' = 'badminton',
	'bandy' = 'bandy',
	'baseball' = 'baseball',
	'basketball' = 'basketball',
	'beach-soccer' = 'beach-soccer',
	'beach-volleyball' = 'beach-volleyball',
	'bowls' = 'bowls',
	'boxing' = 'boxing',
	'cricket' = 'cricket',
	'chess' = 'chess',
	'cycling' = 'cycling',
	'darts' = 'darts',
	'field-hockey' = 'field-hockey',
	'floorball' = 'floorball',
	'futsal' = 'futsal',
	'golf' = 'golf',
	'handball' = 'handball',
	'ice-hockey' = 'ice-hockey',
	'mma' = 'mma',
	'motorsport' = 'motorsport',
	'pesapallo' = 'pesapallo',
	'rugby' = 'rugby',
	'rugby-union' = 'rugby-union',
	'rugby-league' = 'rugby-league',
	'snooker' = 'snooker',
	'soccer' = 'soccer',
	'sailing' = 'sailing',
	'table-tennis' = 'table-tennis',
	'tennis' = 'tennis',
	'volleyball' = 'volleyball',
	'waterpolo' = 'waterpolo',
	'world-lottery' = 'world-lottery',
	'kabaddi' = 'kabaddi',
	'greyhound' = 'greyhound'
}
export enum EsportType {
	'call-of-duty' = 'call-of-duty',
	'counter-strike' = 'counter-strike',
	'overwatch' = 'overwatch',
	'dota-2' = 'dota-2',
	'league-of-legends' = 'league-of-legends',
	'starcraft' = 'starcraft',
	'esport-nba2k' = 'esport-nba2k',
	'rainbow-six' = 'rainbow-six',
	'hearthstone' = 'hearthstone',
	'rocket-league' = 'rocket-league',
	'esport-arena-of-valor' = 'esport-arena-of-valor',
	'esport-fifa' = 'esport-fifa',
	'esport-king-of-glory' = 'esport-king-of-glory'
}

// It will be the sport where they will only have outrights
export enum OutrightSportType {
	'politics' = 'politics'
}

export type SportType = CommonSportType | EsportType | OutrightSportType;
export const SportType = {
	...CommonSportType,
	...EsportType,
	...OutrightSportType
};
// Interface could handle separately for this one when we have more config like scores or market
export type SportTab =
	| 'inPlay'
	| 'competitions'
	| 'highlights'
	| 'today'
	| 'tomorrow'
	| 'virtuals'
	| 'outrights';

export interface SportBaseInfo {
	/** @description scores display */
	setScores?: boolean;
	hideScores?: boolean;
	/** @description tabs for the sport in the single sport page */
	tabs: SportTab[];
	/** @description default access tab for the sport in the single sport page */
	defaultTab: SportTab;
}
