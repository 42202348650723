"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.initialState = void 0;
var actions_1 = require("./actions");
var typesafe_actions_1 = require("typesafe-actions");
var utils_1 = require("../utils");
exports.initialState = {
    fetched: false,
    messages: []
};
var messageById = function (messageId) { return function (message) {
    return message.id === messageId;
}; };
exports.reducer = typesafe_actions_1.createReducer(exports.initialState)
    .handleAction(actions_1.actions.fetchNotifications.success, function (state, _a) {
    var messages = _a.payload;
    return (__assign(__assign({}, state), { fetched: true, messages: messages }));
})
    .handleAction(actions_1.actions.fetchMessages.request, function (state) { return (__assign(__assign({}, state), { fetched: false })); })
    .handleAction(actions_1.actions.fetchMessages.success, function (state, _a) {
    var messages = _a.payload;
    return (__assign(__assign({}, state), { fetched: true, messages: messages }));
})
    .handleAction([actions_1.actions.fetchNotifications.failure, actions_1.actions.fetchMessages.failure], function (state) { return (__assign(__assign({}, state), { fetched: true })); })
    .handleAction(actions_1.actions.dismissNotification, function (state, _a) {
    var messageId = _a.payload;
    var messageIndex = state.messages.findIndex(messageById(messageId));
    var message = state.messages[messageIndex];
    var updatedMessage = message && __assign(__assign({}, message), { dismissed: true });
    var updatedMessages = updatedMessage
        ? utils_1.replace(state.messages, updatedMessage, messageIndex)
        : state.messages;
    return __assign(__assign({}, state), { messages: updatedMessages });
})
    .handleAction(actions_1.actions.readMessage, function (state, _a) {
    var messageId = _a.payload;
    var messageIndex = state.messages.findIndex(messageById(messageId));
    var message = state.messages[messageIndex];
    var updatedMessage = message && __assign(__assign({}, message), { unread: false });
    var updatedMessages = updatedMessage
        ? utils_1.replace(state.messages, updatedMessage, messageIndex)
        : state.messages;
    return __assign(__assign({}, state), { messages: updatedMessages });
});
