import { Theme } from '@gaming-shell/theming';
import { Collapse, makeStyles } from '@material-ui/core';
import { withHook } from 'materialbet-common';
import * as React from 'react';
import { mapActiveBits } from 'sports/utils/bitmaskUtils';

import { BetslipError } from './BetslipError';
import {
	BetslipStatusFlags,
	maxBits
} from './betslipReducer/BetslipStatusFlags';
import { useBetslipIsSubmitting } from './hooks/useBetslipIsSubmitting';
import { BetslipReducerStatus, StakeLocation } from './types';

interface BetslipErrorSlipProps {
	errors: BetslipReducerStatus;
	changedPrice?: number;
	className?: string;
	summary?: boolean;
	stakeLocation?: StakeLocation;
	minStake: number;
	maxStake: number;
	/** @description Will not show any errors if true */
	disabled?: boolean;
}

const useBetslipErrorSlipStyles = makeStyles((theme: Theme) => ({
	error: {
		background:
			theme.palette.type === 'dark'
				? theme.palette.common.white
				: theme.palette.background.top.main,
		padding: theme.spacing(0, 2),
		color: theme.palette.text.primary,
		bottom: '100%',
		flexShrink: 0
	}
}));
export const BetslipErrorSlipUi: React.FunctionComponent<BetslipErrorSlipProps> = ({
	errors,
	summary,
	className,
	stakeLocation,
	changedPrice,
	minStake,
	maxStake,
	disabled
}) => {
	const classes = useBetslipErrorSlipStyles();
	const collapseIn = !disabled && errors !== BetslipStatusFlags.Default;
	const betslipErrors = mapActiveBits(errors, maxBits, (e) => (
		<BetslipError
			key={e}
			error={e}
			changedPrice={changedPrice}
			className={className}
			summary={summary}
			stakeLocation={stakeLocation}
			minStake={minStake}
			maxStake={maxStake}
		/>
	));
	return (
		<Collapse in={collapseIn} className={classes.error}>
			{betslipErrors}
		</Collapse>
	);
};
export const BetslipErrorSlip = withHook(() => {
	const disabled = useBetslipIsSubmitting();
	return { disabled };
})(BetslipErrorSlipUi);
