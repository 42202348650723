import { isDesktop } from '@gaming-shell/device';
import { Drawer, makeStyles, Paper, Slide, Theme } from '@material-ui/core';
import { DrawerProps } from '@material-ui/core/Drawer';
import * as React from 'react';

interface BigBetslipWrapperProps {
	open: boolean;
	onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	float: {
		position: 'fixed',
		display: 'flex',
		flexDirection: 'column',
		right: theme.spacing(2),
		bottom: 0,
		borderRadius: 0,
		maxHeight: 'calc(100% - 72px)',
		overflow: 'hidden',
		width: 376
	}
}));

export const BigBetslipWrapper: React.FunctionComponent<BigBetslipWrapperProps &
	DrawerProps> = ({ children, open, onClose }) => {
	const classes = useStyles();
	const WidgeStyle = {
		zIndex: 3
	};
	if (isDesktop()) {
		return (
			<Slide direction="up" in={open} timeout={500} style={WidgeStyle}>
				<Paper className={classes.float} elevation={24}>
					{children}
				</Paper>
			</Slide>
		);
	}
	return (
		<Drawer anchor={'bottom'} open={open} onClose={onClose}>
			{children}
		</Drawer>
	);
};
