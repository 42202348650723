"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMemoizedLink = exports.createLink = void 0;
var React = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
exports.createLink = function (Component) {
    var CustomLinkComponent = function (_a, ref) {
        /* Be careful, this will be global for all Link components created via this hoc */
        var children = _a.children, href = _a.href, onClick = _a.onClick, replace = _a.replace, rest = __rest(_a, ["children", "href", "onClick", "replace"]);
        /**
         * Fixes issue where user have history polluted because of redirects to same site he/she actually currently is.
         * ex. user is on /casino and clicks on link to /casino, history is being polluted with /casino routes and back arrow stops behaving correctly
         */
        var _b = react_router_dom_1.useLocation(), pathname = _b.pathname, search = _b.search;
        var fullPath = pathname + search;
        var handleClick = React.useCallback(function (ev) {
            if (href === fullPath ||
                (typeof href === 'function' && href() === fullPath)) {
                ev.preventDefault();
            }
            onClick && onClick(ev);
        }, [href, fullPath, onClick]);
        return (
        // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
        React.createElement(Component, __assign({ component: react_router_dom_1.Link, to: href, href: href }, rest, { onClick: handleClick, innerRef: ref, replace: replace }), children));
    };
    CustomLinkComponent.displayName = "createLink(" + Component.displayName + ")";
    // requires ref https://github.com/mui-org/material-ui/issues/15903
    return React.forwardRef(CustomLinkComponent);
};
exports.createMemoizedLink = function (Component) {
    var Link = exports.createLink(Component);
    return React.memo(Link);
};
