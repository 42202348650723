import * as React from 'react';
import { Helmet } from 'react-helmet';

export interface HeaderProps {
	title: string;
	description?: string;
}
export type WithHeaderProps = () => HeaderProps;
/** @description adds title and meta description tag
 * @params useHeaderProps hook that will be run to get the title and description. Allows to access context of whatever is surrounding the page.
 */
export const withHeader = (useHeaderProps: WithHeaderProps) => <T extends {}>(
	Component: React.ComponentType<T>
) => (props: T) => {
	const { title, description } = useHeaderProps();
	const titleElement = title && <title>{title}</title>;
	const metaDescriptionElement = description && (
		<meta name="description" content={description} />
	);
	const helmet = (
		<Helmet encodeSpecialCharacters={false} title={title}>
			{titleElement}
			{metaDescriptionElement}
		</Helmet>
	);
	return (
		<>
			{helmet}
			<Component {...props} />
		</>
	);
};
