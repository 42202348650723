import { Translate } from '@gaming-shell/i18n';
import { Box, Typography, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { resultPrecision } from 'sports/config/app';
import { formatPrecision } from 'sports/utils/number';

export interface BigBetslipSinglesSummaryProps {
	totalStake: string;
	totalReturn: string;
	currencyLabel: string;
}
const useStyles = makeStyles({
	label: {
		flex: 1
	},
	result: {
		whiteSpace: 'nowrap',
		lineHeight: 1,
		width: '30%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	}
});
export const BigBetslipSinglesSummary: React.FunctionComponent<BigBetslipSinglesSummaryProps> = (
	props
) => {
	const { totalStake, totalReturn, currencyLabel } = props;
	const classes = useStyles();
	const totalReturnValue = parseFloat(totalReturn);
	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography color="textSecondary" className={classes.label}>
					<Translate label="betting.totalStake" ns="sports" />
				</Typography>
				<Typography color="textSecondary" className={classes.result}>
					<span>{currencyLabel}</span> <span>{totalStake}</span>
				</Typography>
			</Box>
			<Box display="flex" justifyContent="space-between">
				<Typography className={classes.label}>
					<Translate label="betting.potentialTotalWin" ns="sports" />
				</Typography>
				<Typography color="textPrimary" className={classes.result}>
					<span>{currencyLabel}</span>
					<span>
						{formatPrecision(totalReturnValue, resultPrecision)}
					</span>
				</Typography>
			</Box>
		</>
	);
};
