import { Box, Checkbox, Typography, TypographyProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

import { ConsentBoxContext } from '../ConsentBox';

const useStyles = makeStyles({
	typography: {
		flex: 8
	}
});

export const ConsentBoxDescription: React.FunctionComponent<TypographyProps> = ({
	children,
	...rest
}) => {
	const classes = useStyles();
	const onChange = React.useContext(ConsentBoxContext);

	return (
		<Box display="flex" flex={1}>
			<Box flex={1}>
				<Checkbox onChange={onChange} color="secondary" />
			</Box>
			<Box flex={8}>
				<Typography
					className={classes.typography}
					variant="body2"
					role="consent-box-description"
					{...rest}
				>
					{children}
				</Typography>
			</Box>
		</Box>
	);
};
