import { useLanguage } from '@gaming-shell/i18n';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'sports/modules/root';
import { actions, loads } from 'sports/modules/sports';
import { loadedOnce } from 'sports/selectors/loading';
import { useSports } from 'sports/selectors/sports';

/** @description a hook to return the sport list from store and return loading state */
export const useSportsList = () => {
	const language = useLanguage();
	const dispatch = useDispatch();
	const name = loads.sportsList;
	const loaded = useSelector(
		(state: RootState) => !!loadedOnce(state, { name })
	);
	React.useEffect(() => {
		if (language) {
			dispatch(actions.fetchSportsList({ locale: language }));
		}
	}, [language, dispatch]);
	const sports = useSports();
	return { loaded, sports };
};
