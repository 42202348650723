"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOnPathChange = void 0;
var react_router_1 = require("react-router");
var react_1 = require("react");
var usePrevious_1 = require("../usePrevious");
/** @description Hook to that triggers callback on path change */
exports.useOnPathChange = function (callback) {
    var pathname = react_router_1.useLocation().pathname;
    var prevPathname = usePrevious_1.usePrevious(pathname);
    react_1.useEffect(function () {
        if (pathname && prevPathname && pathname !== prevPathname) {
            callback();
        }
    }, [callback, pathname, prevPathname]);
};
