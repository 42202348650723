import { debug, SportsTopics, warning } from '@gaming-shell/logging';
import { PusherProxy } from '@gaming-shell/pusher/dist/internal/types';
import sportsbettingApi from 'sports/api/sportsbetting';
import {
	PlaceBetV2RequestParams,
	PlaceBetV2Response
} from 'sports/api/sportsbetting/api';
import {
	betslipPollingInterval,
	pendingAcceptenceTimeout
} from 'sports/config/sportsbetting';

export type PlaceBetV2Args = PlaceBetV2RequestParams;
type PlaceBetHandler = (
	response: PlaceBetV2Response,
	playerChannel: string,
	pusher: PusherProxy,
	resolve: (value: PlaceBetV2Response) => void,
	reject: (value?: PlaceBetV2Response) => void,
	source: 'pusher' | 'sync' | 'poll'
) => void;
export const handlePlaceBetResponse: PlaceBetHandler = (
	response,
	playerChannel,
	pusher,
	resolve,
	reject,
	source
) => {
	debug(SportsTopics.PlaceBetResponse, {
		referenceId: response.referenceId,
		status: response.status,
		source,
		data: response
	});
	switch (response.status) {
		case 'PENDING_ACCEPTANCE': {
			// Circular dep
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			handlePendingAcceptance(
				response,
				playerChannel,
				pusher,
				resolve,
				reject,
				source
			);
			return;
		}
		case 'ACCEPTED': {
			resolve(response);
			return;
		}
		default: {
			reject(response);
		}
	}
};
/** @private */
export const pollResponse: PlaceBetHandler = (
	{ referenceId },
	playerChannel,
	pusher,
	resolve,
	reject
) => {
	let intervalId: NodeJS.Timer | null = null;
	const clear = () => intervalId && clearInterval(intervalId);
	intervalId = setInterval(() => {
		sportsbettingApi
			.getBetResponseV2({ referenceId })
			.then((res) => {
				if (res.status !== 'PENDING_ACCEPTANCE') {
					return;
				}
				handlePlaceBetResponse(
					res,
					playerChannel,
					pusher,
					resolve,
					reject,
					'poll'
				);
			})
			.catch((reason: unknown) => {
				warning('fetching betslip failed', [reason]);
				clear();
			});
	}, betslipPollingInterval);
	return clear;
};
const handlePendingAcceptance: PlaceBetHandler = (
	response,
	playerChannel,
	pusher,
	resolve,
	reject
) => {
	const event = response.referenceId;
	/** @todo check if pusher is already bound to this event - if so do nothing - this is only necessary for the freak case that pusher returns pending acceptance*/

	let timeOut: NodeJS.Timeout | null = null;
	const startPolling = () => {
		timeOut && clearTimeout(timeOut);
		// once we start polling for whatever reason we dont want rely on pusher anymore for this particular betslip
		pusher.unbind(event, playerChannel);
		pollResponse(response, playerChannel, pusher, resolve, reject, 'poll');
	};
	timeOut = setTimeout(startPolling, pendingAcceptenceTimeout);

	pusher.bind(
		event,
		playerChannel,
		(res: PlaceBetV2Response) => {
			timeOut && clearTimeout(timeOut);
			pusher.unbind(event, playerChannel);
			handlePlaceBetResponse(
				res,
				playerChannel,
				pusher,
				resolve,
				reject,
				'pusher'
			);
		},
		startPolling
	);
};

export const placeBetV2 = (
	args: PlaceBetV2Args,
	csrfToken: string,
	playerChannel: string,
	pusher: PusherProxy
): Promise<PlaceBetV2Response> => {
	const promise = new Promise<PlaceBetV2Response>((resolve, reject) => {
		sportsbettingApi
			.postPlaceBetV2(args, csrfToken)
			.then((response) =>
				handlePlaceBetResponse(
					response,
					playerChannel,
					pusher,
					resolve,
					reject,
					'sync'
				)
			)
			.catch((err: unknown) => {
				const response = err as PlaceBetV2Response;
				if (response.referenceId) {
					handlePlaceBetResponse(
						response,
						playerChannel,
						pusher,
						resolve,
						reject,
						'sync'
					);
				} else {
					reject(err);
				}
			});
	});
	debug(SportsTopics.PlaceBetRequest, {
		referenceId: args.referenceId,
		data: args
	});
	return promise;
};
