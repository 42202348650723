import { Translate } from '@gaming-shell/i18n';
import { makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import * as React from 'react';
import { isIOS } from '@gaming-shell/device';

import { MoonPayApplePayButton } from '../MoonPayApplePayButton';

export interface MoonPayWidgetProps {
	apiUrl: string;
	apiKey: string;
	externalCustomerId?: number | string;
	currencyCode?: string;
	walletAddress?: string;
	colorCode?: string;
	showWalletAddressForm?: boolean;
	className?: string;
	style?: React.CSSProperties;
	onSignatureCreation?: (encodedMoonPayUrl: string) => Promise<string>;
	onLoad?: () => void;
	withApplePay?: boolean;
}

const useStyles = makeStyles({
	hidden: {
		visibility: 'hidden'
	}
});

export const MoonPayWidget: React.FC<MoonPayWidgetProps> = ({
	apiUrl,
	apiKey,
	showWalletAddressForm = true,
	externalCustomerId = '',
	currencyCode = '',
	walletAddress = '',
	colorCode,
	onSignatureCreation,
	onLoad,
	withApplePay,
	style,
	className
}) => {
	const [isLoading, setIsLoading] = React.useState(true);
	const [signature, setSignature] = React.useState<string>();
	const classes = useStyles();
	const defaultColorCode = useTheme().palette.primary.main;

	const params = {
		apiKey,
		externalCustomerId: externalCustomerId.toString(),
		showWalletAddressForm: showWalletAddressForm.toString(),
		colorCode: colorCode || defaultColorCode,
		signature,
		currencyCode,
		enabledPaymentMethods: MOONPAY_ENABLED_PAYMENT_METHODS,
		walletAddress
	};
	/* this is required because moonpay crashes when we pass them params with empty values */
	const queryString = new URLSearchParams(
		omitBy(params, isUndefined)
	).toString();
	const moonpayUrl = `https://${apiUrl}?${queryString}`; /* https required by moonpay! */

	React.useEffect(() => {
		const handleSignatureCreationCall = async () => {
			/* wait until signature is ready */
			/* needs to be encoded twice because there's an error on backend which decodes passed url in between */
			const encodedMoonPayUrl = encodeURIComponent(moonpayUrl);

			const signature = await onSignatureCreation!(encodedMoonPayUrl);

			setSignature(decodeURIComponent(signature));
			setIsLoading(false);
		};

		if (onSignatureCreation && isLoading && !signature) {
			handleSignatureCreationCall();
		} else {
			setIsLoading(false);
		}
	}, [
		currencyCode,
		walletAddress,
		onSignatureCreation,
		setIsLoading,
		isLoading,
		signature,
		moonpayUrl
	]);

	const handleOnLoad = React.useCallback(() => {
		setIsLoading(false);
		onLoad && onLoad();
	}, [onLoad, setIsLoading]);

	return !isLoading ? (
		<>
			<iframe
				frameBorder="0"
				src={moonpayUrl}
				className={clsx(className, isLoading && classes.hidden)}
				style={style}
				onLoad={handleOnLoad}
			>
				<Translate
					label="descriptions.iframesNotSupported"
					ns="player"
				/>
			</iframe>
			{isIOS() && withApplePay && (
				<MoonPayApplePayButton url={moonpayUrl} />
			)}
		</>
	) : null;
};
