import { FiatCurrency } from 'materialbet-common';

const currencyToShortSymbolMapping: Record<FiatCurrency, string> = {
	USD: '$',
	EUR: '€',
	JPY: '¥',
	INR: '₹',
	BRL: 'R$'
};

const fiatCurrenciesObj: Record<FiatCurrency, FiatCurrency> = {
	USD: 'USD',
	EUR: 'EUR',
	JPY: 'JPY',
	INR: 'INR',
	BRL: 'BRL'
};

const fiatCurrencies: FiatCurrency[] = Object.values(fiatCurrenciesObj);

export default {
	currencyToShortSymbolMapping,
	fiatCurrencies,
	providerListSkeletons: 2
};
