import { makeStyles } from '@material-ui/core/styles';
import { promotionColor } from 'sports/config/colors';

export const useStyles = makeStyles((theme) => ({
	title: {
		marginBottom: theme.spacing(1)
	},
	percentage: ({ value }: { value: number }) => ({
		transition: 'all 0.4s linear',
		width: `${value}%`,
		display: 'block',
		paddingBottom: theme.spacing(0.5)
	}),
	progressWrapper: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0.5, 0),
		position: 'relative' as 'relative' // Typescript
	},
	iconPositionWrapper: ({ value }: { value: number }) => ({
		transition: 'all 0.4s linear',
		width: `${value}%`,
		display: 'flex',
		position: 'absolute' as 'absolute' // Typescript
	}),
	lockIconWrapper: {
		display: 'inline-flex',
		backgroundColor: theme.palette.grey[600],
		padding: theme.spacing(0.75),
		borderRadius: '100%',
		marginLeft: 'auto'
	},
	lockedIcon: {
		fontSize: theme.typography.h6.fontSize
	}
}));

export const useProgressStyles = makeStyles((theme) => ({
	root: {
		height: 8, // From design
		flex: 1
	},
	bar: {
		backgroundColor: promotionColor
	},
	colorPrimary: {
		backgroundColor:
			theme.palette.grey[theme.palette.type === 'dark' ? 800 : 100]
	}
}));

export const useSecondaryProgressStyles = makeStyles((theme) => ({
	root: {
		height: 8, // From design
		flex: 1
	},
	bar: {
		backgroundColor: theme.palette.secondary.main
	},
	colorPrimary: {
		backgroundColor:
			theme.palette.grey[theme.palette.type === 'dark' ? 800 : 100]
	}
}));

export const useLockedProgressStyles = makeStyles((theme) => ({
	root: {
		height: 8, // From design
		width: '100%'
	},
	bar: ({ value }: { value: number }) => ({
		backgroundColor: theme.palette.grey[600],
		borderRadius:
			value === 0
				? `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`
				: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0` // Round it up if locked as there is rounded icon
	}),
	colorPrimary: ({ value }: { value: number }) => ({
		backgroundColor:
			theme.palette.grey[theme.palette.type === 'dark' ? 800 : 100],
		borderRadius:
			value >= 100
				? `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0` // Round it up if locked as there is rounded icon
				: undefined // We keep it not radius until it reaches 100%
	})
}));
