"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignUp = void 0;
var device_1 = require("@gaming-shell/device");
var i18n_1 = require("@gaming-shell/i18n");
var layout_1 = require("@gaming-shell/layout");
var api_1 = require("@materialbet-core/api");
var player_1 = require("@materialbet-core/player");
var __1 = require("../..");
var BasicSignUp_1 = require("./BasicSignUp");
var SsoSignUp_1 = require("./SsoSignUp");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
exports.SignUp = function (props) {
    var _a = React.useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var trans = i18n_1.useTranslation();
    var authToken = player_1.usePlayerAuthToken();
    var enqueueSnackbar = materialbet_common_1.useSnackbar().enqueueSnackbar;
    var updatePlayer = player_1.useUpdatePlayer();
    var refreshPlayerContext = __1.useRefreshPlayerContext();
    var _b = props.location.state || {}, _c = _b.ssoToken, ssoToken = _c === void 0 ? '' : _c, ssoData = __rest(_b, ["ssoToken"]);
    var signUp = function (email, password, locale, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    retry) {
        setIsSubmitting(true);
        var userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return api_1.api
            .postSignUp(__assign(__assign(__assign(__assign({ email: email.trim() }, (password && { password: password })), { locale: locale }), (ssoToken && { sso_token: ssoToken })), { 
            // eslint-disable-next-line @typescript-eslint/camelcase
            time_zone: userTimeZone }), {
            'X-Csrf-Token': authToken,
            'X-Player-Timezone': userTimeZone
        })
            .then(function (response) {
            if (response.result === 'INVALID_AUTHENTICITY_TOKEN') {
                var authToken_1 = response.authenticity_token;
                updatePlayer(function (state) { return (__assign(__assign({}, state), { authToken: authToken_1 })); });
                // setTimeout ensures that the next sign in placed in an event queue which is called after this method returned (js version of defer)
                setTimeout(function () { return retry(email, password, locale); });
                return;
            }
            if (response.result === 'OK' &&
                response.player &&
                !ssoToken) {
                props.history.push(materialbet_common_1.AuthRoutes.signUpConfirmation.create(), {
                    email: email
                });
            }
            else if (response.result === 'OK' &&
                response.player &&
                !!ssoToken) {
                refreshPlayerContext().then(function (response) {
                    if (response.result === 'OK') {
                        props.history.push(materialbet_common_1.BaseRoutes.root.create());
                    }
                    else if (response.result ===
                        'NOT_SIGNED_IN') {
                        props.history.push(materialbet_common_1.AuthRoutes.signIn.create());
                    }
                });
            }
            else if (response.result === 'VALIDATION_ERROR' &&
                response) {
                if (response.params['player[date_of_birth]']) {
                    enqueueSnackbar(trans('player:signUp.ageTest'));
                }
            }
            else if (response.result === 'RESTRICTED_COUNTRY' ||
                response.result === 'COUNTRY_BLOCKED') {
                enqueueSnackbar(trans('player:signIn.restrictedCountry'));
            }
        })
            .catch(function () {
            enqueueSnackbar(trans('player:descriptions.failedRequest'));
        })
            .finally(function () {
            setIsSubmitting(false);
        });
    };
    var retryableSignUp = __1.retryable(signUp, function () {
        enqueueSnackbar(trans('player:descriptions.failedRequest'));
        return Promise.resolve();
    });
    var signUpComponent = SSO_ENABLED ? (React.createElement(__1.SsoAuthWrapp, { flexDirection: "column" },
        React.createElement(SsoSignUp_1.SsoSignUp, { nicknameLabel: React.createElement(i18n_1.Translate, { label: "profile.nickname", ns: "player" }), emailLabel: React.createElement(i18n_1.Translate, { label: "profile.email", ns: "player" }), birthDateLabel: React.createElement(i18n_1.Translate, { label: "profile.birthDate", ns: "player" }), onSubmitClick: retryableSignUp, passwordLabel: React.createElement(i18n_1.Translate, { label: "profile.password", ns: "player" }), submitButtonText: React.createElement(i18n_1.Translate, { label: "actions.join", ns: "common" }), extraText: React.createElement(i18n_1.Translate, { label: "signUp.haveAcc", ns: "player" }), extraButtonText: React.createElement(i18n_1.Translate, { label: "actions.signIn", ns: "common" }), extraButtonRoute: materialbet_common_1.AuthRoutes.signIn.create(), validations: __1.authValidators, isSubmitting: isSubmitting, sso: !!ssoToken, initialValues: ssoData }))) : (React.createElement(BasicSignUp_1.BasicSignUp, { nicknameLabel: React.createElement(i18n_1.Translate, { label: "profile.nickname", ns: "player" }), emailLabel: React.createElement(i18n_1.Translate, { label: "profile.email", ns: "player" }), birthDateLabel: React.createElement(i18n_1.Translate, { label: "profile.birthDate", ns: "player" }), onSubmitClick: retryableSignUp, passwordLabel: React.createElement(i18n_1.Translate, { label: "profile.password", ns: "player" }), submitButtonText: React.createElement(i18n_1.Translate, { label: "actions.join", ns: "common" }), extraText: React.createElement(i18n_1.Translate, { label: "signUp.haveAcc", ns: "player" }), extraButtonText: React.createElement(i18n_1.Translate, { label: "actions.signIn", ns: "common" }), extraButtonRoute: materialbet_common_1.AuthRoutes.signIn.create(), validations: __1.authValidators, isSubmitting: isSubmitting, sso: !!ssoToken, initialValues: ssoData }));
    return (React.createElement(React.Fragment, null,
        React.createElement(layout_1.DefaultAppBar, { type: device_1.isDesktop() ? 'home' : 'window', title: trans('common:actions.join'), defaultBackRoute: materialbet_common_1.BaseRoutes.root.create() }),
        React.createElement(__1.AuthWrapp, { fullScreen: true },
            React.createElement(__1.SingleColumnLayout, { title: React.createElement(i18n_1.Translate, { label: "actions.join", ns: "common" }) }, signUpComponent))));
};
