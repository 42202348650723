import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export const sportPreviewCardDimensions: Record<
	Breakpoint,
	{ height: number; width: number }
> = {
	xs: { width: 236, height: 192 }, // xs is the lowest, default
	sm: { width: 236, height: 192 },
	md: { width: 236, height: 192 },
	lg: { width: 276, height: 192 },
	xl: { width: 308, height: 192 }
};
