import { usePlayerPreferredOddsFormat } from '@materialbet-core/player';
import BigNumber from 'bignumber.js';

const formatSign = (x: number) => ({
	sign: x >= 0 ? '+' : '-',
	value: Math.abs(x)
});

export const formatDecimal = (odds: number) => {
	const number = new BigNumber(odds);
	if (odds < 10) {
		return number.toFixed(2, 1);
	}
	if (odds < 100) {
		return number.toFixed(1, 1);
	}
	return number.toFixed(0, 1);
};

export const decimalToFractional = (x: number) => {
	const number = new BigNumber(x - 1);
	const fractionals = number.toFraction(10);
	return `${fractionals[0]}/${fractionals[1]}`;
};

export const decimalToAmerican = (x: number) => {
	const americanOdds =
		x >= 2 ? Math.floor((x - 1) * 100) : Math.floor(-100 / (x - 1));
	return formatSign(americanOdds);
};

export const decimalToHK = (x: number) => {
	return Math.round((x - 1) * 100) / 100;
};

export const decimalToMalaysian = (x: number) => {
	let malayOdds = x > 2 ? 1 / (1 - x) : x - 1;
	malayOdds = Math.floor(malayOdds * 1000) / 1000;
	return formatSign(malayOdds);
};

export const formatOdds = (
	odds: number,
	format:
		| 'decimal'
		| 'american'
		| 'fractional'
		| 'hongkong'
		| 'malaysian' = 'decimal'
) => {
	switch (format) {
		case 'decimal': {
			return formatDecimal(odds);
		}
		case 'american': {
			const american = decimalToAmerican(odds);
			return american.sign + american.value;
		}
		case 'fractional': {
			return decimalToFractional(odds);
		}
		case 'hongkong': {
			return decimalToHK(odds).toString();
		}
		case 'malaysian': {
			const malaysian = decimalToMalaysian(odds);
			return malaysian.sign + malaysian.value;
		}
	}
	return formatDecimal(odds);
};
/** @description Parses odds for calculation with rounded down values */
export const parseOdds = (odds: number) => {
	const factor = odds >= 10 ? 10 : 100;
	return (
		Math.floor(new BigNumber(odds).multipliedBy(factor).toNumber()) / factor
	);
};
export const useFormatOdds = () => {
	const format = usePlayerPreferredOddsFormat();
	return (odds: number) => formatOdds(odds, format);
};

export const parseBackersOdds = (backersOdds: number) => {
	const factor = 100;
	return Math.ceil(backersOdds * factor) / factor;
};

export const calculateMultiplesTotalPrice = (selections: { price: number }[]) =>
	parseOdds(
		selections
			.reduce(
				(product, { price }) => product.multipliedBy(price),
				new BigNumber(1)
			)
			.toNumber()
	);
