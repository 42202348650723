import { Translate } from '@gaming-shell/i18n';
import { SideMenuItem } from '@gaming-shell/layout';
import PhonelinkLock from '@material-ui/icons/PhonelinkLock';
import {
	PreferencesIcon,
	ResponsibleGamblingIcon,
	SmsIcon
} from 'materialbet-icons';
import { PlayerRoutes, hasAuthEnabled } from 'materialbet-common';
import * as React from 'react';
import { useLocation } from 'react-router';

/** @description a component that contain only the main item for navigation: Security, Preferences, SMS, Responsible Gambling */
let CoreNavigationList: React.FC = () => {
	const authEnabled = hasAuthEnabled();
	const preferencesRoute = PlayerRoutes.preferences.create();
	const verificationRoute = PlayerRoutes.verification.create();
	const responsibleGamblingRoute = PlayerRoutes.responsibleGambling.create();
	const { pathname } = useLocation();
	const securityRoute = PlayerRoutes.security.create();
	const isSecurityRoute = authEnabled
		? false
		: PlayerRoutes.security.matches(pathname);
	const isPreferencesRoute = PlayerRoutes.preferences.matches(pathname);
	const isVerificationRoute = PlayerRoutes.verification.matchesExactly(
		pathname
	);
	const isResponsibleGamblingRoute = PlayerRoutes.responsibleGambling.matches(
		pathname
	);
	const handleClick = (e: React.MouseEvent<unknown>, path: string) => {
		window.open(path, '_blank');
		e.stopPropagation();
		e.preventDefault();
	};
	return (
		<React.Fragment>
			<SideMenuItem
				icon={<PhonelinkLock />}
				label={<Translate label="security.security" ns="player" />}
				href={securityRoute}
				active={isSecurityRoute}
				variant="subtitle2"
				onClick={
					authEnabled
						? (e: React.MouseEvent<unknown>) =>
								handleClick(e, KEYCLOAK_ACCOUNT_PAGE)
						: undefined
				}
			/>
			<SideMenuItem
				icon={<PreferencesIcon />}
				label={
					<Translate label="preferences.preferences" ns="player" />
				}
				href={preferencesRoute}
				active={isPreferencesRoute}
				variant="subtitle2"
			/>
			<SideMenuItem
				icon={<SmsIcon />}
				label={
					PLAYER_KYC_ENABLED ? (
						<Translate
							label="verification.verification"
							ns="player"
						/>
					) : (
						<Translate label="content.sms" ns="player" />
					)
				}
				href={verificationRoute}
				active={isVerificationRoute}
				variant="subtitle2"
			/>
			<SideMenuItem
				icon={<ResponsibleGamblingIcon />}
				label={<Translate label="gambling.pageTitle" ns="player" />}
				href={responsibleGamblingRoute}
				active={isResponsibleGamblingRoute}
				variant="subtitle2"
			/>
		</React.Fragment>
	);
};

CoreNavigationList = React.memo(CoreNavigationList);
export { CoreNavigationList };
