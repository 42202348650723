"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPasswordLayout = void 0;
var device_1 = require("@gaming-shell/device");
var styles_1 = require("@material-ui/core/styles");
var __1 = require("../..");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var styles = function (theme) {
    return styles_1.createStyles({
        container: {
            width: '100%'
        },
        button: {
            margin: theme.spacing(2) + "px 0"
        },
        wrapper: {
            display: 'flex',
            justifyContent: 'center'
        },
        input: {
            '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
                WebkitBoxShadow: "0 0 0 100px " + (device_1.isDesktop()
                    ? theme.palette.background.paper
                    : theme.palette.background.default) + " inset !important",
                '-webkit-text-fill-color': theme.palette.text.primary
            }
        }
    });
};
var ForgotPasswordLayout = function (props) {
    var _a = React.useState(true), disabled = _a[0], setDisabled = _a[1];
    var _b = React.useState({
        email: { value: '', error: '' }
    }), inputValues = _b[0], setInputValues = _b[1];
    var updateValue = function (value) {
        setInputValues({
            email: {
                error: '',
                value: value
            }
        });
    };
    var onSubmit = function (event) {
        event.preventDefault();
        var validationValues = [
            {
                key: 'email',
                value: inputValues.email.value,
                validator: 'email'
            }
        ];
        var valuesWithError = __1.validateForm(validationValues, props.validations);
        if (Object.keys(valuesWithError).length === 0) {
            props.onSubmitClick(inputValues.email.value);
        }
        else {
            setInputValues(function (prevState) { return (__assign(__assign({}, prevState), valuesWithError)); });
        }
    };
    React.useEffect(function () {
        setDisabled(__1.isFormDisabled(inputValues));
    }, [inputValues, setDisabled]);
    return (React.createElement("form", { onSubmit: onSubmit, className: props.classes.container },
        React.createElement(materialbet_common_1.TextField, { id: "email", label: props.emailLabel, type: "email", value: inputValues.email.value, onChange: updateValue, variant: "outlined", fullWidth: true, margin: "normal", helperText: inputValues.email.error, error: !!inputValues.email.error, InputProps: {
                classes: {
                    input: props.classes.input
                }
            } }),
        React.createElement(materialbet_common_1.ProgressButton, { submitting: props.isSubmitting, ProgressWrapperProps: {
                className: props.classes.wrapper
            }, className: props.classes.button, variant: "contained", color: "primary", fullWidth: true, disabled: disabled, type: "submit" }, props.submitButtonText),
        React.createElement(__1.FormExtraAction, { buttonText: props.extraButtonText, buttonRoute: props.extraButtonRoute, justifyContent: "center" }, props.extraText)));
};
var StyledForgotPasswordLayout = styles_1.withStyles(styles)(ForgotPasswordLayout);
exports.ForgotPasswordLayout = StyledForgotPasswordLayout;
