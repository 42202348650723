import {
	Box,
	IconButton,
	Theme,
	Typography,
	makeStyles,
	useMediaQuery,
	useTheme
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';

export interface PromoHeaderTitleProps {
	title?: React.ReactNode;
	subtitle?: React.ReactNode;
	onArrowClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	expandBtn: {
		position: 'relative',
		marginLeft: 'auto',
		left: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(1)
		}
	}
}));

export const PromoHeaderTitle: React.FunctionComponent<PromoHeaderTitleProps> = ({
	title,
	subtitle,
	onArrowClick
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('xs')); // Todo in future replace with device.isMobile()
	const classes = useStyles();

	return (
		<Box padding={2} flex={isMobile ? 2 : '1 1 100%'}>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<Typography variant="h6">{title}</Typography>
				{onArrowClick && (
					<IconButton
						onClick={onArrowClick}
						className={classes.expandBtn}
						aria-label="expand"
						size={isMobile ? 'small' : 'medium'}
					>
						<ExpandMoreIcon />
					</IconButton>
				)}
			</Box>
			<Typography variant="body2">{subtitle}</Typography>
		</Box>
	);
};
