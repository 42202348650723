import { GetRolloverBonusesResponse } from 'casino/api/casino';
import { RolloverBonusEntity, rolloverBonuses } from 'casino/casinoSchemas';
import { createCasinoFetchEntityEpic } from 'casino/util/epicsUtil';
import { createRolloverBonusesMeta } from 'casino/util/metaUtils';
import { createEntityReducer } from 'casino/util/reducerUtils';
import { finishLoading } from 'materialbet-common';
import { combineEpics } from 'redux-observable';
import { createAction } from 'typesafe-actions';

export const rolloverBonusesReducer = createEntityReducer<RolloverBonusEntity>(
	rolloverBonuses.key
);
export interface RolloverBonusesParams {
	playerUuid: string;
	currency?: string;
}

export const getRolloverBonusesKey = (params: RolloverBonusesParams) =>
	`${params.playerUuid}_${params.currency}`;
export const loads = {
	rolloverBonuses: (params: RolloverBonusesParams) =>
		`rolloverBonuses/rolloverBonuses_${getRolloverBonusesKey(params)}`
};

export const actions = {
	fetchRolloverBonuses: createAction(
		'rolloverBonuses/fetchRolloverBonuses',
		(resolve) => (payload: RolloverBonusesParams) => resolve(payload)
	)
};
export const getRolloverBonusesEpic = createCasinoFetchEntityEpic<
	RolloverBonusesParams,
	GetRolloverBonusesResponse
>({
	actionCreator: actions.fetchRolloverBonuses,
	fetch: (payload, { api }) =>
		api.rolloverBonuses.apiV1RolloverBonusesGet(
			payload.playerUuid,
			payload.currency
		),
	resultSelector: (result) => result.rollover_bonuses || [],
	resultSchema: [rolloverBonuses],
	createMeta: ({ payload, result }) => {
		const rolloverBonusKey = getRolloverBonusesKey(payload);
		const loading = finishLoading(loads.rolloverBonuses(payload));
		const casino = createRolloverBonusesMeta(result, rolloverBonusKey);
		return { ...loading, ...casino };
	}
});

export const rolloverBonusesEpic = combineEpics(getRolloverBonusesEpic);
