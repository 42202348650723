"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetContentState = exports.useContentState = exports.ContentStateProvider = void 0;
var React = __importStar(require("react"));
var initContentState = {
    hasPadding: true
};
var initSetContentState = function () { };
var ContentStateContext = React.createContext(initContentState);
var SetContentStateContext = React.createContext(initSetContentState);
exports.ContentStateProvider = function (_a) {
    var children = _a.children;
    var _b = React.useState(initContentState), contentState = _b[0], setContentState = _b[1];
    return (React.createElement(ContentStateContext.Provider, { value: contentState },
        React.createElement(SetContentStateContext.Provider, { value: setContentState }, children)));
};
exports.useContentState = function () {
    var context = React.useContext(ContentStateContext);
    return context;
};
exports.useSetContentState = function () {
    var context = React.useContext(SetContentStateContext);
    return context;
};
