import { PlaceBetV2Bet } from 'sports/api/sportsbetting/api';

import { BetslipReducerBetslip, BetslipReducerSelection } from '../types';
import { createPlaceBetSelection } from './createPlaceBetSelection';
import { PlaceBetPayload } from './types';

const createBet = (
	selection: BetslipReducerSelection,
	index: number
): PlaceBetV2Bet => ({
	stake: selection.stake.toString(),
	straight: { selectionIndex: index }
});
export const createStraightPlaceBetFromBetslip = (
	betslip: BetslipReducerBetslip
) => {
	return betslip.selections.reduce<PlaceBetPayload>(
		(acc, selection, index) => {
			acc.selections[index] = createPlaceBetSelection(selection);
			acc.bets[index] = createBet(selection, index);
			return acc;
		},
		{
			bets: new Array(betslip.selections.length),
			selections: new Array(betslip.selections.length)
		}
	);
};
