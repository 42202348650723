"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBonusEligibleForPath = void 0;
var routes_1 = require("../routes");
/* todo fix routes to use prefixed routes */
var routeMap = {
    sports: routes_1.SportsRoutes,
    casino: routes_1.CasinoRoutes
};
exports.isBonusEligibleForPath = function (bonusEligibility, pathname) {
    var eligiblePaths = bonusEligibility.map(function (b) { return b.replace('Bet', '').toLowerCase(); });
    return (eligiblePaths.some(function (path) { return routeMap[path].root.matches(pathname); }) ||
        routes_1.PlayerRoutes.root.matches(pathname) ||
        routes_1.AuthRoutes.root.matches(pathname));
};
