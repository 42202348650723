import { isDesktop } from '@gaming-shell/device';
import {
	DefaultSideMenu,
	EsportsSideMenuPortal,
	SideMenuItemList,
	SideMenuPortal
} from '@gaming-shell/layout';
import * as React from 'react';
import { Provider } from 'react-redux';
import {
	EsportsList,
	SportsNavigationList,
	SportsSideMenuFooter,
	useSportsList,
	VirtualSportsItem
} from 'sports/components/sideMenu';

import { registerSharedComponents } from './sharedComponents';
import store from './store';

// Just put in the same file to make it closer to it wrapper
const UnprovidedEsportsSideMenu: React.FC = () => {
	useSportsList(); // This will fetch the sports
	return (
		<SideMenuItemList>
			<SportsNavigationList />
			<EsportsList disableCollapsible />
			{ENABLE_VIRTUAL_SPORTS_PAGE &&
				(isDesktop() || ENABLE_VIRTUAL_SPORTS_MOBILE) && (
					<VirtualSportsItem />
				)}
			<SportsSideMenuFooter />
		</SideMenuItemList>
	);
};

registerSharedComponents();
/** @description this component will return basic side menu for the esports */
let EsportsSideMenu: React.FunctionComponent = () => {
	return (
		<>
			<EsportsSideMenuPortal>
				<Provider store={store}>
					<UnprovidedEsportsSideMenu />
				</Provider>
			</EsportsSideMenuPortal>
			<SideMenuPortal>
				<DefaultSideMenu />
			</SideMenuPortal>
		</>
	);
};

EsportsSideMenu = React.memo(EsportsSideMenu);
export { EsportsSideMenu };
