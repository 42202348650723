"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHardwareConcurrencyInfo = void 0;
exports.getHardwareConcurrencyInfo = function () {
    var hardwareConcurrency = navigator === null || navigator === void 0 ? void 0 : navigator.hardwareConcurrency;
    var unsupported = hardwareConcurrency === undefined;
    return {
        unsupported: unsupported,
        numberOfLogicalProcessors: hardwareConcurrency
    };
};
