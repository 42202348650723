import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

const JackpotSlots: React.FunctionComponent<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<path
				fill={props.fill}
				fillRule="nonzero"
				d="M16.32 10.63c2.998 0 5.535 1.23 5.674 3.062l.006.158v4.93c0 1.924-2.587 3.22-5.68 3.22-3.009 0-5.545-1.225-5.684-3.061l-.006-.159v-4.93c0-.049.005-.097.014-.143.126-1.84 2.67-3.077 5.676-3.077zm-4.19 7.905v.245c0 .812 1.818 1.72 4.19 1.72 2.289 0 4.055-.845 4.174-1.636l.006-.084v-.242c-1.046.628-2.545.992-4.18.992-1.638 0-3.141-.365-4.19-.995zm0-2.467l-.001.239.001.013c0 .774 1.704 1.647 3.948 1.707l.242.003c2.28 0 4.054-.846 4.174-1.627l.006-.083v-.246c-1.046.63-2.544.996-4.18.996-1.64 0-3.144-.368-4.19-1.002zm4.19-3.938c-2.362 0-4.18.911-4.18 1.72 0 .809 1.818 1.72 4.18 1.72 2.362 0 4.18-.911 4.18-1.72 0-.809-1.818-1.72-4.18-1.72zM19.988 2A1.98 1.98 0 0 1 22 4v6.17a.75.75 0 1 1-1.5 0V3.988A.48.48 0 0 0 20 3.5H4a.48.48 0 0 0-.48.5v7.38c0 .269.217.488.48.49h4.3a.75.75 0 1 1 0 1.5H3.994a1.99 1.99 0 0 1-1.974-1.99V4.012A1.98 1.98 0 0 1 4 2zM6.79 4.47c.74 0 1.342.586 1.369 1.318.008.08.01.162.006.242l-.012.121-.04.163-1.54 4.1a.75.75 0 0 1-1.434-.43l.029-.098 1.47-3.916H5.22a.75.75 0 0 1-.102-1.493l.102-.007h1.57zm5.55 0c.74 0 1.342.586 1.369 1.318.008.08.01.162.006.242l-.012.121-.04.163-1.54 4.1a.75.75 0 0 1-1.434-.43l.029-.098 1.47-3.916H10.77a.75.75 0 0 1-.102-1.493l.102-.007h1.57zm3.98 0h1.57c.74 0 1.342.586 1.369 1.318.008.08.01.162.006.242l-.012.121-.043.17-1.11 2.87a.75.75 0 0 1-1.43-.444l.03-.098 1.037-2.679H16.32a.75.75 0 0 1-.102-1.493l.102-.007h1.57z"
			/>
		</SvgIcon>
	);
};
export default JackpotSlots;
