import { useLanguage } from '@gaming-shell/i18n';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { LiveEventListWidgetHeader } from 'sports/components/displays/LiveEventListWidgetHeader';
import { SportCard } from 'sports/components/displays/SportCard';
import { useEventTree } from 'sports/components/lists/SmartEventList';
import { LiveEventList } from 'sports/components/liveEventList';
import { CustomPaper } from 'sports/components/materialWrapper/CustomPaper';
import {
	SportType,
	supportedCommonSports,
	supportedEsports,
	supportedSports
} from 'sports/config/sport';
import { EventTreeMeta } from 'sports/modules/sports/sportsMeta';
import { getSdkLocale } from 'sports/utils/locale';
import { getSportDefaultMarket } from 'sports/utils/market';
import { sortSports } from 'sports/utils/sports';

import { LiveEventNavigationWidgetSkeleton } from './LiveEventNavigationWidget.skeleton';

export const useStyles = makeStyles({
	root: {
		width: 324,
		overflow: 'auto'
	}
});

export interface LiveEventNavigationWidgetProps {
	className?: string;
}

let LiveEventNavigationWidget: React.FunctionComponent<LiveEventNavigationWidgetProps> = (
	props
) => {
	const { className } = props;
	const classes = useStyles();
	const language = useLanguage();
	const locale = getSdkLocale(language);

	const { loaded, eventTree } = useEventTree({
		live: true,
		endpoint: 'events',
		sports: supportedSports
	});
	const sportIds = (eventTree || []).map(
		(sport: EventTreeMeta) => sport.id
	) as SportType[];
	const commonSportIds = sortSports(
		sportIds.filter((sport) => supportedCommonSports.includes(sport)),
		locale
	);
	const eSportIds = sortSports(
		sportIds.filter((sport) => supportedEsports.includes(sport)),
		locale
	);
	if (!loaded) {
		return (
			<LiveEventNavigationWidgetSkeleton
				className={clsx(classes.root, className)}
			/>
		);
	}
	if (!eventTree || !eventTree.length) {
		return null;
	}
	const eventTreeDict = eventTree.reduce((dict, sport) => {
		dict[sport.id] = sport;
		return dict;
	}, {});

	const allSports = [...commonSportIds, ...eSportIds];
	return (
		<CustomPaper className={clsx(classes.root, className)}>
			<LiveEventListWidgetHeader hideAllLiveEventsButton />
			{loaded &&
				allSports.map((sportKey: SportType, index) => {
					const defaultMarketKey = getSportDefaultMarket(sportKey);
					const sport = eventTreeDict[sportKey];
					return (
						<SportCard
							paperVariant="flat"
							paperTitleVariant={
								index === allSports.length - 1
									? 'text-small'
									: 'outline-small'
							}
							sportKey={sportKey}
							key={sportKey}
						>
							<LiveEventList
								sport={sport}
								sportKey={sportKey}
								marketKey={defaultMarketKey}
								variant="narrow"
								paperVariant="flat"
								paperTitleVariant="contained-small"
								competitionTitleVariant="secondary"
								disableCompetitionIcon
								disableMarketHeader
								disablePriceList
								hideNavigateAllButton
							/>
						</SportCard>
					);
				})}
		</CustomPaper>
	);
};

LiveEventNavigationWidget = React.memo(LiveEventNavigationWidget);

export { LiveEventNavigationWidget };
