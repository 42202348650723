import { isMobile } from '@gaming-shell/device';
import {
	InputAdornment,
	TextField,
	Theme,
	makeStyles
} from '@material-ui/core';
import BigNumber from 'bignumber.js';
import * as React from 'react';
import { NumPadIcon } from 'sports/components/icons/betslip/NumPadIcon';
import { QuickInputIcon } from 'sports/components/icons/betslip/QuickInputIcon';
import { stakeInputMaxDecimals } from 'sports/config/app';
import { CurrencyVariantConfig } from 'sports/config/currency';
import { getVariantCurrencyValue } from 'sports/utils/currency';
import { StakeTrailingInfo, handleStakeInputChange } from 'sports/utils/stake';

export interface QuickBetslipStakeFieldProps {
	stake: number;
	trailing: string;
	setTrailing: (trail: StakeTrailingInfo) => void;
	toggleInputMethod: () => void;
	showDialpad: boolean;
	onChange: (stake: number) => void;
	currencyConfig: CurrencyVariantConfig;
}

const useTextFieldStyles = makeStyles((theme: Theme) => ({
	root: {
		margin: theme.spacing(1, 0)
	},
	input: {
		textAlign: 'right',
		fontSize: theme.typography.h6.fontSize
	}
}));

export const QuickBetslipStakeField: React.FunctionComponent<QuickBetslipStakeFieldProps> = (
	props
) => {
	const textFieldClasses = useTextFieldStyles();
	const {
		toggleInputMethod,
		stake,
		showDialpad,
		onChange,
		trailing,
		setTrailing,
		currencyConfig
	} = props;
	const switchIcon = showDialpad ? <QuickInputIcon /> : <NumPadIcon />;
	const variantStake = getVariantCurrencyValue(currencyConfig, stake || 0);
	const stakeString = `${currencyConfig.label} ${new BigNumber(
		variantStake
	).toFixed()}${trailing}`;

	const handleChange = handleStakeInputChange(
		currencyConfig,
		onChange,
		setTrailing,
		stakeInputMaxDecimals
	);
	return (
		<TextField
			InputProps={{
				startAdornment: isMobile() && (
					<InputAdornment position="start">
						{switchIcon}
					</InputAdornment>
				),
				classes: textFieldClasses
			}}
			onClick={isMobile() ? toggleInputMethod : undefined}
			fullWidth
			variant="outlined"
			disabled={isMobile()}
			autoFocus={true}
			value={stakeString}
			className={textFieldClasses.root}
			onChange={handleChange}
		/>
	);
};
