import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

import { ProgressWrapper, ProgressWrapperProps } from '..';

export interface ProgressButtonProps extends ButtonProps {
	submitting: ProgressWrapperProps['submitting'];
	ProgressWrapperProps?: Partial<Omit<ProgressWrapperProps, 'submitting'>>;
	children: React.ReactNode;
}

const useStyles = makeStyles({
	fullWidth: {
		width: '100%'
	}
});

export const ProgressButton: React.FC<ProgressButtonProps> = ({
	submitting,
	fullWidth,
	ProgressWrapperProps,
	disabled,
	...buttonProps
}) => {
	const classes = useStyles();

	return (
		<ProgressWrapper
			{...ProgressWrapperProps}
			submitting={submitting}
			className={clsx(
				ProgressWrapperProps?.className,
				fullWidth && classes.fullWidth
			)}
		>
			<Button
				{...buttonProps}
				fullWidth={fullWidth}
				disabled={disabled || submitting}
			/>
		</ProgressWrapper>
	);
};
