/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
	CompetitionWithEvent,
	CompetitionWithEventFromJSON,
	CompetitionWithEventFromJSONTyped,
	CompetitionWithEventToJSON
} from './';

/**
 * SportWithCompetition is used for the /events endpoint
 * Please keep index compatibility with private.SportWithCompetition
 * @export
 * @interface SportWithCompetition
 */
export interface SportWithCompetition {
	/**
	 *
	 * @type {boolean}
	 * @memberof SportWithCompetition
	 */
	blank?: boolean;
	/**
	 *
	 * @type {Array<CompetitionWithEvent>}
	 * @memberof SportWithCompetition
	 */
	competitions?: Array<CompetitionWithEvent>;
	/**
	 *
	 * @type {string}
	 * @memberof SportWithCompetition
	 */
	key?: string;
	/**
	 *
	 * @type {string}
	 * @memberof SportWithCompetition
	 */
	name?: string;
}

export function SportWithCompetitionFromJSON(json: any): SportWithCompetition {
	return SportWithCompetitionFromJSONTyped(json, false);
}

export function SportWithCompetitionFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): SportWithCompetition {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		blank: !exists(json, 'blank') ? undefined : json['blank'],
		competitions: !exists(json, 'competitions')
			? undefined
			: (json['competitions'] as Array<any>).map(
					CompetitionWithEventFromJSON
			  ),
		key: !exists(json, 'key') ? undefined : json['key'],
		name: !exists(json, 'name') ? undefined : json['name']
	};
}

export function SportWithCompetitionToJSON(
	value?: SportWithCompetition | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		blank: value.blank,
		competitions:
			value.competitions === undefined
				? undefined
				: (value.competitions as Array<any>).map(
						CompetitionWithEventToJSON
				  ),
		key: value.key,
		name: value.name
	};
}
