import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
	activeEventPromotionPollInterval,
	eventPromotionPollInterval
} from 'sports/config/zeromargin';
import { actions } from 'sports/modules/eventPromotionsModule';
import { startPolling } from 'sports/utils/scheduleUtils';

import { useIsZeroMarginPromotionActive } from '../zeroMarginUtils/zeroMarginUtils';

const promotion = 'zero_margin';
export const usePollZeroMarginEventPromotion = !ENABLE_ZERO_MARGIN
	? () => {}
	: (eventId?: number) => {
			const dispatch = useDispatch();
			const isActive = useIsZeroMarginPromotionActive(eventId || 0);
			React.useEffect(() => {
				if (!eventId) {
					return;
				}
				return startPolling({
					onPoll: () =>
						dispatch(actions.fetchByEvent({ eventId, promotion })),
					interval: isActive
						? activeEventPromotionPollInterval
						: eventPromotionPollInterval
				});
			}, [isActive, eventId, dispatch]);
	  };
