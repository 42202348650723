import { Platform } from '@gaming-shell/device';
import { GetStudiosResponse } from 'casino/api/casino';
import { StudioEntity, studios } from 'casino/casinoSchemas';
import { createCasinoFetchEntityEpic } from 'casino/util/epicsUtil';
import { createStudiosMeta } from 'casino/util/metaUtils';
import { createEntityReducer } from 'casino/util/reducerUtils';
import { finishLoading } from 'materialbet-common';
import { createAction } from 'typesafe-actions';

import { FilterList, GameType } from '../types/casinoApiTypes';

export default createEntityReducer<StudioEntity>(studios.key);
export interface StudiosParams {
	playerUuid: string;
	category: 'all' | 'recommendation';
	tags?: string[];
	locale: string;
	type?: GameType[];
	platform: Platform;
	country?: string;
	currency: string;
	themes?: string[];
	list?: FilterList;
	live?: boolean;
	/** @deprecated */
	sectionId?: string;
}

export const getStudiosKey = (params: StudiosParams) =>
	`${params.playerUuid}_${params.category}_${params.locale}_${
		params.currency
	}_${params.platform}_${params.country}_[${params.themes?.join(',')}]_${
		params.list
	}_[${params.tags?.join(',')}]_[${params.type?.join(',')}]_${params.live}_${
		params.sectionId
	}`;
export const loads = {
	studios: (params: StudiosParams) =>
		`studios/studios_${getStudiosKey(params)}`
};

export const actions = {
	fetchStudios: createAction(
		'studios/fetchStudios',
		(resolve) => (payload: StudiosParams) => resolve(payload)
	)
};
export const getStudiosEpic = createCasinoFetchEntityEpic<
	StudiosParams,
	GetStudiosResponse
>({
	actionCreator: actions.fetchStudios,
	fetch: (payload, { api }) =>
		api.games.v1PlayersPlayerUuidStudiosGet(
			payload.playerUuid,
			payload.category,
			BRAND_NAME as 'materialbet',
			undefined,
			payload.currency,
			undefined, // filter id
			payload.live,
			payload.platform,
			payload.sectionId, //section id
			undefined, //studio
			payload.tags,
			payload.themes,
			undefined, // tile type
			payload.type, // filter type
			payload.list,
			payload.locale
		),
	resultSelector: (result) => result.list || [],
	resultSchema: [studios],
	createMeta: ({ payload, result }) => {
		const studiosKey = getStudiosKey(payload);
		const loading = finishLoading(loads.studios(payload));
		const casino = createStudiosMeta(result, studiosKey);
		return { ...loading, ...casino };
	}
});

export const studiosEpic = getStudiosEpic;
