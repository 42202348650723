import { gtag } from '@gaming-shell/gtm';

export const SportsTrackingEvents = {
	onOpenQuickBetslip: 'onOpenQuickBetslip',
	onAddToBigBetslip: 'onAddToBigBetslip',
	onCopyBetslip: 'onCopyBetslip',
	onBetslipClose: 'onBetslipClose',
	onBetslipSubmit: 'onBetslipSubmit',
	onBetPlaced: 'onBetPlaced',
	onBetslipPreset: 'onBetslipPreset',
	onBetslipMobileKeyboardOpen: 'onBetslipMobileKeyboardOpen',
	onBetslipManualInput: 'onBetslipManualInput'
};
export const SportsTrackingVariables = {
	currentBetslipType: 'currentBetslipType',
	placedBetCurrency: 'placedBetCurrency',
	placedBetValue: 'placedBetValue',
	betslipPreset: 'betslipPreset',
	betslipSource: 'betslipSource'
};
export const CurrentBetslipType = {
	big: 'big',
	quick: 'quick',
	none: 'undefined'
};
export const trackOpenQuickBetslip = () =>
	gtag({
		event: SportsTrackingEvents.onOpenQuickBetslip,
		[SportsTrackingVariables.currentBetslipType]: CurrentBetslipType.quick
	});

export const trackAddToBigBetslip = () =>
	gtag({
		event: SportsTrackingEvents.onAddToBigBetslip,
		[SportsTrackingVariables.currentBetslipType]: CurrentBetslipType.big
	});

export const trackCopyToBigBetslip = (source: string) =>
	gtag({
		event: SportsTrackingEvents.onCopyBetslip,
		[SportsTrackingVariables.currentBetslipType]: CurrentBetslipType.big,
		[SportsTrackingVariables.betslipSource]: source
	});

export const trackOnBetslipClose = () =>
	gtag({
		event: SportsTrackingEvents.onBetslipClose,
		[SportsTrackingVariables.currentBetslipType]: CurrentBetslipType.none,
		[SportsTrackingVariables.betslipSource]: 'undefined'
	});

export const trackOnBetslipSubmit = () =>
	gtag({ event: SportsTrackingEvents.onBetslipSubmit });

export const trackOnBetPlaced = (betPlaced: {
	value: number;
	currency: string;
}) =>
	gtag({
		event: SportsTrackingEvents.onBetPlaced,
		[SportsTrackingVariables.placedBetCurrency]: betPlaced.currency,
		[SportsTrackingVariables.placedBetValue]: betPlaced.value
	});
export const trackOnBetslipPreset = (preset: {
	value: number;
	currency: string;
	type: 'set' | 'add';
}) =>
	gtag({
		event: SportsTrackingEvents.onBetslipPreset,
		[SportsTrackingVariables.betslipPreset]: preset
	});
export const trackBetslipMobileKeyboardOpen = () =>
	gtag({ event: SportsTrackingEvents.onBetslipMobileKeyboardOpen });
