import * as React from 'react';
import { useQuery } from 'urql';
import { getCurrentPlayer } from './query';
import { Player, defaultPlayer } from '../../types';

/**@description to fetch all required fields related to player */
export const useGetCurrentPlayer = (): Player => {
	const [returnValue, setReturnValue] = React.useState<Player>(defaultPlayer);
	const [result] = useQuery({
		query: getCurrentPlayer
	});
	const { data, fetching, error } = result;

	React.useEffect(() => {
		if (!fetching && !error && data.playerMe) {
			const { playerMe } = data;
			const { __typename: _, ...rest } = playerMe;
			setReturnValue({ ...rest, authToken: '', isLoading: false });
		}
	}, [setReturnValue, fetching, data, error]);
	return returnValue;
};
