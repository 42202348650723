import * as React from 'react';

export const useInterval = (callback: () => void, delay: number | null) => {
	const interval = React.useRef<ReturnType<typeof setTimeout>>();
	// Set up the interval.
	React.useEffect(() => {
		if (delay !== null) {
			interval.current = setInterval(callback, delay);
		}
		return () => {
			if (interval.current) {
				clearInterval(interval.current);
				interval.current = undefined;
			}
		};
	}, [callback, delay]);
};

export const useCountdown = (syncTime: number, interval = 1000) => {
	const [time, setTime] = React.useState<number>(syncTime);
	React.useLayoutEffect(() => {
		// whenever time is updated from outside, we sync that back here
		setTime(syncTime);
	}, [syncTime]);
	React.useEffect(() => {
		const intervalId = setInterval(
			() => setTime((t) => t - interval),
			interval
		);
		return () => clearInterval(intervalId);
	}, []);
	return time;
};
