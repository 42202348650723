"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TIER3_LOCATION_RESTRICTION_ENABLED = exports.LEFT_NAVBAR_FOOTER_ENABLED = exports.QUICK_DEPOSIT_ENABLED = exports.ASIAN_VIEW_ENABLED = exports.SPORTS_ONBOARDING_MODULE_ENABLED = exports.isStorageEnabled = void 0;
var storage = window.localStorage;
exports.isStorageEnabled = function (key, opts) {
    if (opts === void 0) { opts = {}; }
    return (ENABLE_LOCALSTORAGE_FLAGS || opts.includeProd) &&
        storage &&
        storage.getItem(key) === 'true';
};
exports.SPORTS_ONBOARDING_MODULE_ENABLED = exports.isStorageEnabled('sportsOnboardingEnabled') || SPORTS_ONBOARDING_ENABLED;
exports.ASIAN_VIEW_ENABLED = exports.isStorageEnabled('asianView') || ENABLE_ASIAN_VIEW;
exports.QUICK_DEPOSIT_ENABLED = exports.isStorageEnabled('quickDeposit') || ENABLE_QUICK_DEPOSIT;
exports.LEFT_NAVBAR_FOOTER_ENABLED = exports.isStorageEnabled('navbarFooter') || ENABLE_NAVBAR_IMPROVEMENT;
exports.TIER3_LOCATION_RESTRICTION_ENABLED = exports.isStorageEnabled('tier3LocationRestrictionEnabled') ||
    TIER3_LOCATION_RESTRICTED_ENABLED;
