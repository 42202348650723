"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBackgroundStyle = void 0;
var core_1 = require("@material-ui/core");
var useStyles = core_1.makeStyles({
    background: function (_a) {
        var imageUrl = _a.imageUrl;
        return ({
            backgroundImage: "url(" + imageUrl + ")"
        });
    }
});
/** @description Handy hook which returns className with background-image with passed imageUrl */
exports.useBackgroundStyle = function (imageUrl) {
    if (imageUrl) {
        var backgroundClassName = useStyles({ imageUrl: imageUrl }).background;
        return backgroundClassName;
    }
    return undefined;
};
