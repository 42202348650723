import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: [
			{
				marketType: MarketType.starcraft_winner
			},
			{
				marketType: MarketType.starcraft_map_handicap,
				view: MarketView.Handicap
			},
			{
				marketType: MarketType.starcraft_map_winner
			},
			{
				marketType: MarketType.starcraft_total_maps,
				view: MarketView.Goals
			}
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.starcraft_winner
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.starcraft_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
