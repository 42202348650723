import { Theme, makeStyles } from '@material-ui/core';
import clx from 'classnames';
import React from 'react';
export type PriceChange = 'unchanged' | 'increased' | 'decreased';

interface LinearIndicatorIconProps {
	change: PriceChange;
	odds: number;
}

const useLinearDivStyles = makeStyles((theme: Theme) => ({
	indicatorContainer: {
		overflowY: 'hidden'
	},
	indicatorBody: {
		height: 'inherit',
		position: 'absolute',
		width: '3px',
		right: '0px',
		animationFillMode: 'backwards',
		animationIterationCount: 3,
		animationDuration: '1s'
	},
	increased: {
		backgroundColor: theme.palette.secondary.light,
		animationName: '$increase'
	},
	decreased: {
		backgroundColor: 'red',
		animationName: '$decrease'
	},
	'@keyframes increase': {
		'0%': { bottom: '-5%', height: '10%' },
		'100%': { bottom: '105%', height: '100%' }
	},
	'@keyframes decrease': {
		'0%': { top: '-5%', height: '10%' },
		'100%': { top: '105%', height: '100%' }
	}
}));

export const LinearIndicator: React.FC<LinearIndicatorIconProps> = (props) => {
	const indicatorClasses = useLinearDivStyles();
	const { change, odds } = props;

	if (change === 'unchanged') {
		return null;
	}
	return (
		<div className={indicatorClasses.indicatorContainer}>
			<div
				key={change + odds}
				className={clx(
					indicatorClasses.indicatorBody,
					indicatorClasses[change]
				)}
			/>
		</div>
	);
};
