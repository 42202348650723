"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDontHaveAnAccount = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var __1 = require("../..");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
exports.IDontHaveAnAccount = function () {
    if (!ENABLE_SIGNUP) {
        return null;
    }
    return (React.createElement(__1.FormExtraAction, { buttonText: React.createElement(i18n_1.Translate, { label: "actions.join", ns: "common" }), buttonRoute: materialbet_common_1.AuthRoutes.signUp.create(), justifyContent: "center" },
        React.createElement(i18n_1.Translate, { label: "signIn.dontHaveAccount", ns: "player" })));
};
