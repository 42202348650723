/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Stream
 */
export interface Stream {
	/**
	 *
	 * @type {string}
	 * @memberof Stream
	 */
	language?: string;
	/**
	 *
	 * @type {string}
	 * @memberof Stream
	 */
	provider?: string;
	/**
	 *
	 * @type {string}
	 * @memberof Stream
	 */
	url?: string;
}

export function StreamFromJSON(json: any): Stream {
	return StreamFromJSONTyped(json, false);
}

export function StreamFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): Stream {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		language: !exists(json, 'language') ? undefined : json['language'],
		provider: !exists(json, 'provider') ? undefined : json['provider'],
		url: !exists(json, 'url') ? undefined : json['url']
	};
}

export function StreamToJSON(value?: Stream | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		language: value.language,
		provider: value.provider,
		url: value.url
	};
}
