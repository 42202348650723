import livesport365 from 'sports/config/livesport365';

import createLivesportApi from './livesport365/api';

const configuration = {
	basePath: livesport365.url,
	baseOptions: {
		mode: 'cors',
		headers: {
			accept: 'application/json',
			'Content-Type': 'application/json'
		}
	}
};
const api = createLivesportApi(configuration);
export default api;
