import { isMobile } from '@gaming-shell/device';
import { makeStyles } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'sports/modules/root';
import {
	getCompetitorAwayName,
	getCompetitorHomeName,
	getEventName
} from 'sports/selectors/eventSelectors';

import { PrimaryText } from './Text';

export interface TeamNameProps {
	name: string;
	className?: string;
	textVariant?: TypographyProps['variant'];
}
const useEventNameStyle = makeStyles({
	root: {
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	}
});
export const EventName: React.FunctionComponent<TeamNameProps> = (props) => {
	const { name, textVariant, className } = props;
	const classes = useEventNameStyle();
	const customTextVariant = textVariant
		? textVariant
		: isMobile()
		? 'caption'
		: 'body2';
	return (
		<div className={className}>
			<PrimaryText variant={customTextVariant} className={classes.root}>
				{name}
			</PrimaryText>
		</div>
	);
};

const mapStateToProps = () => {
	return (
		state: RootState,
		props: {
			eventId: number | string;
		}
	) => {
		const { eventId } = props;
		const eventName = getEventName(eventId)(state);
		const homeName = getCompetitorHomeName(eventId)(state);
		const awayName = getCompetitorAwayName(eventId)(state);
		const name =
			homeName && awayName ? `${homeName} v ${awayName}` : eventName;
		return { name };
	};
};

/** @description pass in event id and render name get from state */
export const ConnectedEventName = connect(mapStateToProps)(EventName);
