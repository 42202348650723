import { combineEpics } from 'redux-observable';
import { GetSportEntitiesEnum } from 'sports/api/sports';
import * as schemas from 'sports/schema';
import { setSportKeyInCategoryCompetitions } from 'sports/utils/entity';
import { createFetchEntityEpic } from 'sports/utils/epic';
import { getSportsApiLocale } from 'sports/utils/locale';
import { finishLoading } from 'sports/utils/meta';
import { createEntityReducer } from 'sports/utils/reducer';
import { createAction } from 'typesafe-actions';

////////////////
/// Actions ///
//////////////
export const actions = {
	fetchSportsList: createAction(
		'sports/fetchSportsList',
		(resolve) => (payload: { locale: string }) => resolve(payload)
	),
	fetchSport: createAction(
		'sports/fetchSport',
		(resolve) => (payload: {
			key: string;
			locale: string;
			entities?: GetSportEntitiesEnum;
		}) => resolve(payload)
	)
};
export const loads = {
	sportsList: 'sports/sportsList',
	sport: (sportKey: string) => `sports/sport${sportKey}`
};
export default createEntityReducer<schemas.SportEntity>(schemas.sports.key);

//////////////
/// EPICS ///
////////////
const getSport = createFetchEntityEpic(
	actions.fetchSport,
	({ key, entities, locale }, { api }) =>
		api.sports.getSport(key, entities, getSportsApiLocale(locale)),
	[schemas.sports],
	(result) => {
		setSportKeyInCategoryCompetitions(result);
		return [result];
	},
	(params) => {
		const loadingMeta = finishLoading(loads.sport(params.payload.key));
		return { ...loadingMeta };
	}
);

const getSportsList = createFetchEntityEpic(
	actions.fetchSportsList,
	({ locale }, { api }) => api.sports.getSports(getSportsApiLocale(locale)),
	[schemas.sports],
	(result) => {
		if (!result.sports) {
			return [];
		}
		return result.sports;
	},
	() => {
		const loadingMeta = finishLoading(loads.sportsList);
		return { ...loadingMeta };
	}
);

export const sportsEpic = combineEpics(getSportsList, getSport);
