import i18next from 'i18next';
import { Language } from '@materialbet/translations';
import { warning } from '@gaming-shell/logging';
import { api } from '@materialbet-core/api';

let languageChangeListener: (locale: Language) => void;
const languageChangeSubscriber = (authToken: string) => (locale: Language) => {
	/* don't show & handle the error, just let it fail and let it be logged in rollbar */

	/* map locale from pt_br to pt-BR format (it's required by the API) */
	let apiFormattedLocale = locale;
	const parts = locale.split('_');
	if (parts.length === 2) {
		apiFormattedLocale = `${
			parts[0]
		}-${parts[1].toUpperCase()}` as Language;
	}
	api.putUpdatePreferences(
		{
			locale: apiFormattedLocale
		},
		authToken
	).catch(() =>
		warning(
			'Something went wrong during saving language preference on server.',
			apiFormattedLocale
		)
	);
};

export const registerLanguageChange = (authToken: string) => {
	languageChangeListener = languageChangeSubscriber(authToken);
	i18next.on('languageChanged', languageChangeListener);
};

export const unregisterLanguageChange = () => {
	/**
	 * Important!
	 * Make sure that you will not pass `undefined` as second arg.
	 * If `undefined` will be passed, at the time of execution this function, all existing listeners will be deleted.
	 * This means some of `useLocation` hooks will not trigger re-render even if they should.
	 */
	if (languageChangeListener) {
		i18next.off('languageChanged', languageChangeListener);
	}
};
