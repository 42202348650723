import * as React from 'react';
import { SportsRoutes } from 'materialbet-common';
import { SideMenuItem } from '@gaming-shell/layout';
import { useLocation } from 'react-router';
import { Translate } from '@gaming-shell/i18n';
import { VirtualsIcon } from 'materialbet-icons';
import { SideMenuItemChip } from './SideMenuItemChip';

const virtualsRoot = SportsRoutes.virtualSports.create();

/** @descriπption Brings you to viruals page*/
let VirtualSportsItem: React.FC = () => {
	const { pathname } = useLocation();
	const isVirtuals = SportsRoutes.virtualSports.matchesExactly(pathname);
	return (
		<SideMenuItem
			icon={<VirtualsIcon />}
			label={
				<>
					<Translate label="sports.content.virtuals" />
				</>
			}
			href={virtualsRoot}
			active={isVirtuals}
			variant="subtitle2"
			additionalInfo={
				ENABLE_VIRTUAL_SPORT_SIDEMENU_NEW_LABEL ? (
					<SideMenuItemChip
						label={<Translate label="descriptions.new" />}
					/>
				) : (
					undefined
				)
			}
		/>
	);
};

VirtualSportsItem = React.memo(VirtualSportsItem);
export { VirtualSportsItem };
