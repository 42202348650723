import {
	CompetitionResponse,
	EventListResponse,
	SportWithCategoryResponse,
	SportWithCompetition,
	TopCouponsResponse
} from 'sports/api/sports';
import { CompetitionEntity, EventEntity, OutrightEntity } from 'sports/schema';

export const createSubmarketId = (
	marketId: string | number,
	submarketName: string
) => `${marketId}.${submarketName}`;

export const setSportKeyInCategoryCompetitions = (
	sport: SportWithCategoryResponse
) => {
	if (!sport || !sport.categories) {
		return sport || {};
	}
	sport.categories &&
		sport.categories.forEach((category) => {
			category.competitions &&
				category.competitions.forEach(
					(comp) =>
						((comp as CompetitionEntity).sport = sport.key || '')
				);
		});
	return sport;
};

export const setSportKeyInCompetitions = (sports: SportWithCompetition[]) => {
	if (!sports) {
		return [];
	}
	sports.forEach((sport) => {
		sport.competitions &&
			sport.competitions.forEach(
				(comp) => ((comp as CompetitionEntity).sport = sport.key || '')
			);
	});
	return sports;
};

export const setCompetitionKeyInCompetitionEvent = (
	competition: CompetitionResponse
) => {
	const events = competition.events;
	if (!events) {
		return;
	}
	events.forEach((event) => {
		const eventEntity = event as EventEntity;
		eventEntity.competition = competition.key;
	});
};

/* Event consuming apis often require the competition to be present in the event however eventlists do not include the competition in the event
 as the event is included in the competition */

export const setCompetitionKeyInEvent = (response: EventListResponse) => {
	const sports = response.sports;
	if (!sports) {
		return;
	}
	sports.forEach((sport) => {
		const competitions = sport.competitions;
		if (!competitions) {
			return;
		}
		competitions.forEach((competition) => {
			setCompetitionKeyInCompetitionEvent(competition);
		});
	});
};

export const setCompetitionKeyInCompetitionOutright = (
	competition: CompetitionResponse
) => {
	const outrights = competition.outrights;
	if (!outrights) {
		return;
	}
	outrights.forEach((outright) => {
		const outrightEntity = outright as OutrightEntity;
		outrightEntity.competition = competition.key;
	});
};

/* Event consuming apis often require the competition to be present in the event however eventlists do not include the competition in the event
 as the event is included in the competition */

export const setCompetitionKeyInOutright = (response: EventListResponse) => {
	const sports = response.sports;
	if (!sports) {
		return;
	}
	sports.forEach((sport) => {
		const competitions = sport.competitions;
		if (!competitions) {
			return;
		}
		competitions.forEach((competition) => {
			setCompetitionKeyInCompetitionOutright(competition);
		});
	});
};

export const setSingleSportCompetitionKeyInEvent = (
	response: TopCouponsResponse
) => {
	const sport = response.sport;
	if (!sport) {
		return;
	}
	const competitions = sport.competitions;
	if (!competitions) {
		return;
	}
	competitions.forEach((competition) => {
		setCompetitionKeyInCompetitionEvent(competition);
	});
};

export const setSingleSportKeyInCompetitions = (
	sport: SportWithCompetition
) => {
	if (!sport) {
		return;
	}
	sport.competitions &&
		sport.competitions.forEach(
			(comp) => ((comp as CompetitionEntity).sport = sport.key || '')
		);
	return sport;
};

export const getIsEventLive = (eventStatus: string) => {
	return (
		eventStatus === 'TRADING_LIVE' ||
		eventStatus === 'INTERRUPTED' ||
		eventStatus === 'POST_TRADING'
	);
};

export const getIsEventAwaitingResults = (eventStatus: string) => {
	return eventStatus === 'AWAITING_RESULTS';
};
