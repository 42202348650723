import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

import { fillArray } from '../../utils';

export interface DotsProps {
	amount: number;
	color?: string;
	hoverColor?: string;
}

const useStyles = makeStyles<Theme, Pick<DotsProps, 'color' | 'hoverColor'>>(
	(theme: Theme) => ({
		dots: ({ hoverColor }) => ({
			'&:hover': {
				'& > div': {
					backgroundColor: hoverColor || theme.palette.text.hint
				}
			}
		}),
		dot: ({ color }: Partial<DotsProps>) => ({
			display: 'inline-block',
			height: theme.spacing(0.5),
			width: theme.spacing(0.5),
			margin: theme.spacing(0.25),
			backgroundColor: color || theme.palette.text.hint,
			borderRadius: '50%'
		})
	})
);

export const Dots: React.FC<DotsProps> = ({ amount, color, hoverColor }) => {
	const classes = useStyles({ color, hoverColor });

	return (
		<div className={classes.dots} aria-label="dots">
			{fillArray(amount, (i) => (
				<div key={`dot-${i}`} className={classes.dot} />
			))}
		</div>
	);
};
