"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIframeWidgetControls = exports.getIframeWidgetDimensions = exports.getIframeDimensions = void 0;
var React = __importStar(require("react"));
var ratio_1 = require("../configs/ratio");
var dimensionsUtils_1 = require("./dimensionsUtils");
var ratioUtils_1 = require("./ratioUtils");
exports.getIframeDimensions = function (_a) {
    var availableHeight = _a.availableHeight, availableWidth = _a.availableWidth, _b = _a.ratio, ratio = _b === void 0 ? ratio_1.defaultIframeRatio : _b;
    // Ratio
    var aspectRatio = ratioUtils_1.getRatioFromString(ratio);
    var _c = dimensionsUtils_1.getDefaultDimensions(ratio), defaultWidth = _c.defaultWidth, defaultHeight = _c.defaultHeight;
    var iframeDimensions = dimensionsUtils_1.fillAvailableSpaceWhileKeepingRatio({
        width: availableWidth,
        height: availableHeight,
        ratio: aspectRatio
    });
    // Height and width for game
    var height = iframeDimensions.height > 0
        ? iframeDimensions.height + "px"
        : defaultHeight;
    var width = iframeDimensions.width > 0
        ? iframeDimensions.width + "px"
        : defaultWidth;
    return {
        height: height,
        width: width
    };
};
exports.getIframeWidgetDimensions = function (_a) {
    var iframeWidth = _a.iframeWidth, headerHeight = _a.headerHeight, availableHeight = _a.availableHeight, actionBarHeight = _a.actionBarHeight, cinema = _a.cinema;
    // Height and Width of widget
    var widgetHeight = availableHeight > 0
        ? headerHeight + availableHeight + actionBarHeight + "px"
        : '100%';
    var widgetWidth = iframeWidth;
    return {
        height: cinema ? '100vh' : widgetHeight,
        width: cinema ? '100vw' : widgetWidth
    };
};
/** @description controls for iframe widget that contain a iframe area and other components */
exports.useIframeWidgetControls = function (params) {
    var _a = params || {}, _b = _a.defaultFullscreen, defaultFullscreen = _b === void 0 ? false : _b, _c = _a.defaultCinema, defaultCinema = _c === void 0 ? false : _c, _d = _a.defaultShowSidemenu, defaultShowSidemenu = _d === void 0 ? false : _d;
    var _e = React.useState(defaultFullscreen), fullscreen = _e[0], setFullscreen = _e[1];
    var _f = React.useState(defaultCinema), cinema = _f[0], setCinema = _f[1];
    var _g = React.useState(defaultShowSidemenu), showSidemenu = _g[0], setShowSidemenu = _g[1];
    return {
        fullscreen: fullscreen,
        setFullscreen: setFullscreen,
        cinema: cinema,
        setCinema: setCinema,
        showSidemenu: showSidemenu,
        setShowSidemenu: setShowSidemenu
    };
};
