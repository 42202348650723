import { Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import * as React from 'react';
// Use Primary Text Color, body1 by default
export const PrimaryText: React.FunctionComponent<TypographyProps> = ({
	children,
	...props
}) => (
	<Typography variant="body1" color="textPrimary" {...props}>
		{children}
	</Typography>
);

// Use Secondary Text Color, body2 by default
export const SecondaryText: React.FunctionComponent<TypographyProps> = ({
	children,
	...props
}) => (
	<Typography variant="body2" color="textSecondary" {...props}>
		{children}
	</Typography>
);
