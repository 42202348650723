export interface EventPromotionIdentifier {
	eventId: number;
	promotion: string;
}
export const getEventPromotionId = ({
	eventId,
	promotion
}: EventPromotionIdentifier) => `${eventId}_${promotion}`;
export const getZeroMarginPromotionId = (eventId: number) =>
	getEventPromotionId({ eventId, promotion: 'zero_margin' });
