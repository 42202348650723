import { Trans, useTranslation } from '@gaming-shell/i18n';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Link from '@material-ui/core/Link';
import { withHook } from 'materialbet-common';
import * as React from 'react';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import { PromotionCounter } from 'sports/components/PromotionCounter';
import {
	getEventPromotionSlotsAvailable,
	getEventPromotionSlotsTaken
} from 'sports/selectors/eventPromotionsSelectors';
import { getIsZeroMarginPromoExpired } from 'sports/selectors/eventSelectors';
import { getEventPromotionId } from 'sports/utils/eventPromotionsUtils';
import { useIsZeroMarginPromotionActive } from 'sports/utils/zeroMarginUtils';

export interface EventPromotionPlayerCountProps {
	eventId: number;
	currentCount?: number;
	totalCount?: number;
	/** @description is promotion active */
	active?: boolean;
	/** @description indicate if event expired */
	expired?: boolean;
	hideFooter?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	message: {
		display: 'block',
		marginTop: theme.spacing(1.5)
	},
	link: {
		color: theme.palette.primary.main
	}
}));
let EventPromotionPlayerCountUi: React.FunctionComponent<EventPromotionPlayerCountProps> = (
	props
) => {
	const { currentCount, totalCount, active, expired, hideFooter } = props;
	const trans = useTranslation();
	const classes = useStyles();
	// TODO: string for title and message would need to passed from outside to be more generic
	const title = active ? (
		<CountUp
			end={currentCount || 0}
			prefix={trans('descriptions.participatingPlayers') + ': '}
			preserveValue
		/>
	) : expired ? (
		trans('player:promotions.descriptions.zeroMarginClosed')
	) : (
		trans('player:promotions.descriptions.timeWindowOpeningSoon', {
			number: totalCount
		}) + '...'
	);
	const socialMediaLinks = [
		<Link
			href={TWITTER_URL}
			key="twitter_url"
			className={classes.link}
			target="_blank"
		>
			Twitter
		</Link>,
		<Link
			href={DISCORD_URL}
			key="discord_url"
			className={classes.link}
			target="_blank"
		>
			Discord
		</Link>
	];
	return (
		<div>
			<PromotionCounter
				title={title}
				current={currentCount}
				total={totalCount}
				isLocked={!active}
			/>
			{/* TODO: may be extract to component */}
			{/* Status Message for the player count */}
			<Collapse in={!hideFooter}>
				{active && (
					<Typography
						variant="caption"
						color="textSecondary"
						className={classes.message}
					>
						{trans(
							'player:promotions.descriptions.maximumParticipantsPerGame',
							{
								number: totalCount
							}
						)}
					</Typography>
				)}
				{/* Using zero margin translation here  */}
				{!active && (
					<Typography
						variant="caption"
						color="textSecondary"
						className={classes.message}
					>
						{expired ? (
							<Trans
								ns="player"
								label="player:promotions.descriptions.maximumZeroMarginSlotsReached"
								values={{ number: totalCount }}
								components={socialMediaLinks}
							/>
						) : (
							<Trans
								ns="player"
								label="player:promotions.descriptions.promotitionAnnouncement"
								values={{
									promotion: trans(
										'player:promotions.zeroMarginOdds'
									)
								}}
								components={socialMediaLinks}
							/>
						)}
					</Typography>
				)}
			</Collapse>
		</div>
	);
};
EventPromotionPlayerCountUi = React.memo(EventPromotionPlayerCountUi);
export { EventPromotionPlayerCountUi };
const useEventPromotionPlayerCountProps = ({
	eventId,
	promotion,
	hideFooter
}: {
	eventId: number;
	promotion: string;
	hideFooter?: boolean;
}): EventPromotionPlayerCountProps => {
	const eventPromotionId = getEventPromotionId({
		eventId,
		promotion: promotion.toLowerCase()
	});
	const expired = useSelector(getIsZeroMarginPromoExpired(eventId));
	const currentCount = useSelector(
		getEventPromotionSlotsTaken(eventPromotionId)
	);
	const totalCount = useSelector(
		getEventPromotionSlotsAvailable(eventPromotionId)
	);
	// TODO this should be switched back to a more generic selector allowing to pass in the promotion string - once the big promo is active
	const active = useIsZeroMarginPromotionActive(eventId);
	return {
		eventId,
		currentCount: expired ? totalCount : currentCount,
		totalCount,
		active,
		expired,
		hideFooter
	};
};
export const EventPromotionPlayerCount = withHook(
	useEventPromotionPlayerCountProps
)(EventPromotionPlayerCountUi);
