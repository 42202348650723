import * as React from 'react';
import { useBetslipSelector } from 'sports/components/betterBetslip/hooks';

import { getBetslipSelectionsIdentityString } from '../../betslipSelectors/betslipSelectors';
import { SelectionsIdentitySeparator } from '../../betslipSelectors/betslipSelectorsUtils';
import { BigBetslipSelection } from '../BigBetslipSelection';
import { BigBetslipTabProps } from '../types';

export const BigBetslipSingles: React.FunctionComponent<BigBetslipTabProps> = (
	props
) => {
	const {
		onSelectionUpdate,
		setCurrentEditStakeView,
		currencyConfig
	} = props;
	const selectionsIdentity = useBetslipSelector(
		getBetslipSelectionsIdentityString
	);
	const selectionList =
		selectionsIdentity &&
		selectionsIdentity
			.split(SelectionsIdentitySeparator)
			.map((identity, selectionIndex) => {
				return (
					<BigBetslipSelection
						key={identity}
						selectionIndex={selectionIndex}
						onSelectionUpdate={onSelectionUpdate}
						setCurrentEditStakeView={setCurrentEditStakeView}
						currencyConfig={currencyConfig}
					/>
				);
			});
	return <>{selectionList}</>;
};
