import * as React from 'react';
import { usePlayerIpCountry } from '@materialbet-core/player';
import { Configure } from 'react-instantsearch-dom';
import { SearchConfigProps } from 'sports/types/sharedComponents';

export const SearchConfig: React.FC<SearchConfigProps> = ({ filters }) => {
	const ipCountry = usePlayerIpCountry();
	const from = Date.now() / 1000;
	const filterQuery = filters?.length
		? `AND (${filters
				.map(({ value }) => `sportId = ${value}`)
				.join(' OR ')})`
		: 'AND sportId > 100';

	return (
		<Configure
			filters={`startsAt > ${from} ${filterQuery}`}
			facetFilters={`${ipCountry}`}
			hitsPerPage={SEARCH_SPORTS_RESULTS_PER_PAGE}
		/>
	);
};
