import {
	Typography as MuiTypography,
	Theme,
	makeStyles
} from '@material-ui/core';
import {
	TypographyProps as MuiTypographyProps,
	TypographyTypeMap
} from '@material-ui/core/Typography';
import clsx from 'clsx';
import { TextTransformProperty } from 'csstype';
import * as React from 'react';

export type TypographyProps<
	D extends React.ElementType = TypographyTypeMap['defaultComponent'],
	P = {}
> = MuiTypographyProps<D, P> & {
	textTransform?: TextTransformProperty;
};

const useStyles = makeStyles<
	Theme,
	{ textTransform?: TypographyProps['textTransform'] }
>({
	root: {
		textTransform: ({ textTransform }) => textTransform
	}
});

export const Typography = <
	D extends React.ElementType = TypographyTypeMap['defaultComponent'],
	P = {}
>({
	className,
	textTransform,
	...otherTypographyProps
}: TypographyProps<D, P>) => {
	const classes = useStyles({ textTransform });

	return (
		<MuiTypography
			{...otherTypographyProps}
			className={clsx(className, classes.root)}
		/>
	);
};
