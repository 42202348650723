"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileHomeAppBar = void 0;
var React = __importStar(require("react"));
var betterAppBar_1 = require("../../../..");
var hooks_1 = require("../../../../../SideMenuDrawer/hooks");
var shared_components_1 = require("shared-components");
exports.MobileHomeAppBar = function (_a) {
    var prevType = _a.prevType;
    var toggleSideMenu = hooks_1.useToggleSideMenuDrawer();
    return (React.createElement(React.Fragment, null,
        React.createElement(betterAppBar_1.MainNavButton, { type: "menu", fromType: prevType, onClick: toggleSideMenu }),
        React.createElement(betterAppBar_1.BrandLogo, null),
        React.createElement(betterAppBar_1.MobileActionBarSwitch, null,
            React.createElement(betterAppBar_1.MobileNotSignedInActionBar, null,
                React.createElement(betterAppBar_1.Search, null),
                React.createElement(betterAppBar_1.SignInButton, null)),
            React.createElement(betterAppBar_1.MobileSignedInActionBar, null,
                React.createElement(betterAppBar_1.Search, null),
                React.createElement(shared_components_1.SharedComponent, { id: shared_components_1.SharedComponentIds.AccountBalanceButton }),
                React.createElement(shared_components_1.SharedComponent, { id: shared_components_1.SharedComponentIds.AccountButton })))));
};
