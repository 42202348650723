import { useInterval } from 'materialbet-common';
import * as React from 'react';
import { secondsToMinutes, timeStringToSeconds } from 'sports/utils/number';

interface TimerProps {
	initialTime: string;
	disabled?: boolean;
}

export const Timer: React.FunctionComponent<TimerProps> = ({
	initialTime,
	disabled
}) => {
	const time = timeStringToSeconds(initialTime);
	const [seconds, setSeconds] = React.useState(time);
	const incrementSeconds = React.useCallback(() => {
		if (!disabled) {
			setSeconds((s) => s + 1);
		}
	}, [disabled]);
	useInterval(incrementSeconds, disabled ? null : 1000);
	return <>{secondsToMinutes(seconds)}</>;
};

export default Timer;
