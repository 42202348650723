import { Box, BoxProps, Collapse, CollapseProps } from '@material-ui/core';
import * as React from 'react';

import { ExpandableCardContext } from '../../ExpandableCard';

export interface ExpandableCardCollapsedContentProps extends CollapseProps {
	boxProps?: BoxProps;
}

export const ExpandableCardCollapsedContent: React.FC<ExpandableCardCollapsedContentProps> = ({
	boxProps,
	children
}) => {
	const [collapsed] = React.useContext(ExpandableCardContext);

	return (
		<Collapse in={collapsed} mountOnEnter unmountOnExit>
			<Box padding={2} {...boxProps}>
				{children}
			</Box>
		</Collapse>
	);
};
