import {
	usePlayerCurrency,
	usePlayerCurrencyVariant
} from '@materialbet-core/player';
import BigNumber from 'bignumber.js';
import {
	CurrencyVariantConfig,
	getCurrencyConfig,
	getCurrencyVariantConfig
} from 'sports/config/currency';

import { truncate } from './number';

export type CurrencyPosition = 'left' | 'right' | 'none';
/** @param currencyPosition: left, right or none */
/** @param variant: optional variant of given currency */
export interface FormatCurrencyOptions {
	keepTrailingZeros?: boolean;
	currencyPosition?: CurrencyPosition;
	maxDecimalPlaces?: number;
	variant?: string;
}
const defaultCurrencyOptions: FormatCurrencyOptions = {
	currencyPosition: 'none'
};

export const formatCurrency = (
	amount: number,
	currency: string,
	options: FormatCurrencyOptions = defaultCurrencyOptions
) => {
	const { maxDecimalPlaces } = options;
	const { decimalPlaces, label, factor } = getCurrencyVariantConfig(
		currency,
		options.variant || ''
	);
	const formatDecimalPlaces = maxDecimalPlaces
		? Math.min(maxDecimalPlaces, decimalPlaces)
		: decimalPlaces;
	const number = new BigNumber(amount).times(factor).toNumber();
	const truncated = truncate(number, formatDecimalPlaces); // TODO should localize number string after truncating
	switch (options.currencyPosition) {
		case 'right':
			return `${truncated} ${label || currency}`;
		case 'left':
			return `${label || currency} ${truncated}`;
		default:
			return truncated;
	}
};

export const formatCurrencyValue = (
	amount: number,
	currency: string,
	options?: FormatCurrencyOptions
) => formatCurrency(amount, currency, options);

export const formatBetInput = (amount: string, currency: string) => {
	const { decimalPlaces } = getCurrencyConfig(currency);
	const decimalPoint = amount.indexOf('.'); // TODO needs to be localized
	const stakeNumber = parseFloat(amount);
	if (stakeNumber === 0 && decimalPoint < 1) {
		return '0';
	} else if (decimalPoint < 1) {
		return amount;
	}
	return amount.substring(0, decimalPoint + decimalPlaces + 1);
};

export const useCurrency = () => {
	return usePlayerCurrency();
};
export const useCurrencyConfig = () => {
	const currency = useCurrency();
	return getCurrencyConfig(currency);
};
export const useCurrencyVariantConfig = (variant: string) => {
	const currency = useCurrency();
	return getCurrencyVariantConfig(currency, variant);
};
export const getMinStake = (
	currency: string,
	variant?: string,
	stakeMaxDecimals?: number
) => {
	const { decimalPlaces } = getCurrencyVariantConfig(currency, variant || ''); // The decimal place should already calculated by factor
	const currencyMinStake = new BigNumber(10)
		.dividedBy(Math.pow(10, decimalPlaces))
		.toNumber();
	if (!stakeMaxDecimals) {
		return currencyMinStake;
	}
	// Handle when the input allow input is larger than the default minimum of currency, probably the
	const inputMinStake = new BigNumber(1)
		.dividedBy(Math.pow(10, stakeMaxDecimals))
		.toNumber();
	return Math.max(inputMinStake, currencyMinStake);
};
export const defaultCurrencyVariant: Record<string, string> = {
	BTC: 'mBTC',
	BCH: 'mBCH',
	ETH: 'mETH'
};

export const usePlayerCurrencyVariantConfig = () => {
	const currency = useCurrency();
	const variant = usePlayerCurrencyVariant();
	return getCurrencyVariantConfig(currency, variant);
};

export const getBaseCurrencyValue = (
	variantConfig: CurrencyVariantConfig,
	variantValue: number
) => new BigNumber(variantValue).dividedBy(variantConfig.factor).toNumber();
export const getVariantCurrencyValue = (
	variantConfig: CurrencyVariantConfig,
	baseValue: number
) => new BigNumber(baseValue).multipliedBy(variantConfig.factor).toNumber();
