/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Stream, StreamFromJSON, StreamFromJSONTyped, StreamToJSON } from './';

/**
 * CompetitionForSport is used for the /sports/{key} endpoint
 * Please keep index compatibility with Competition
 * @export
 * @interface CompetitionForSport
 */
export interface CompetitionForSport {
	/**
	 *
	 * @type {boolean}
	 * @memberof CompetitionForSport
	 */
	blank?: boolean;
	/**
	 *
	 * @type {number}
	 * @memberof CompetitionForSport
	 */
	eventCount?: number;
	/**
	 *
	 * @type {string}
	 * @memberof CompetitionForSport
	 */
	key?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompetitionForSport
	 */
	name?: string;
	/**
	 *
	 * @type {number}
	 * @memberof CompetitionForSport
	 */
	outrightCount?: number;
	/**
	 *
	 * @type {Array<Stream>}
	 * @memberof CompetitionForSport
	 */
	streams?: Array<Stream>;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof CompetitionForSport
	 */
	tags?: Array<string>;
}

export function CompetitionForSportFromJSON(json: any): CompetitionForSport {
	return CompetitionForSportFromJSONTyped(json, false);
}

export function CompetitionForSportFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompetitionForSport {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		blank: !exists(json, 'blank') ? undefined : json['blank'],
		eventCount: !exists(json, 'eventCount')
			? undefined
			: json['eventCount'],
		key: !exists(json, 'key') ? undefined : json['key'],
		name: !exists(json, 'name') ? undefined : json['name'],
		outrightCount: !exists(json, 'outrightCount')
			? undefined
			: json['outrightCount'],
		streams: !exists(json, 'streams')
			? undefined
			: (json['streams'] as Array<any>).map(StreamFromJSON),
		tags: !exists(json, 'tags') ? undefined : json['tags']
	};
}

export function CompetitionForSportToJSON(
	value?: CompetitionForSport | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		blank: value.blank,
		eventCount: value.eventCount,
		key: value.key,
		name: value.name,
		outrightCount: value.outrightCount,
		streams:
			value.streams === undefined
				? undefined
				: (value.streams as Array<any>).map(StreamToJSON),
		tags: value.tags
	};
}
