import { useLanguage } from '@gaming-shell/i18n';
import { withHook } from 'materialbet-common';
import * as React from 'react';
import { Provider, useSelector } from 'react-redux';
import { zeroMarginCompetitionKey } from 'sports/config/zeromargin';
import { SportsState } from 'sports/modules/root';
import { getZeroMarginEventIdsByEventsIds } from 'sports/selectors/eventSelectors';
import { getCompetitionEvents } from 'sports/selectors/metaSelectors';
import store from 'sports/store';
import { getCompetitionEventsMetaParams } from 'sports/utils/competitionUtils';

import { ZeroMarginEventWidget } from '../ZeroMarginEventWidget';

const competitionKey = zeroMarginCompetitionKey;
export const ZeroMarginWidget = withHook(
	({ imageSize = 'small' }: { imageSize?: 'small' | 'normal' }) => {
		const locale = useLanguage();
		const params = getCompetitionEventsMetaParams({
			competitionKey,
			locale
		});
		const competitionEventIds = useSelector((state: SportsState) =>
			getCompetitionEvents(state, params)
		);
		const eventId = useSelector(
			getZeroMarginEventIdsByEventsIds(competitionEventIds || [])
		)[0]; // there should only be one event so we only care about the first one find then
		return {
			eventId,
			competitionKey,
			imageSize
		};
	}
)(ZeroMarginEventWidget);

export const ProvidedZeroMarginWidget: React.FC<{
	imageSize?: 'small' | 'normal';
}> = (props) => {
	return (
		<Provider store={store}>
			<ZeroMarginWidget {...props} />
		</Provider>
	);
};
