import { BetslipReducerSelection } from '../types';

export type SelectionEventGroups = Record<number, BetslipReducerSelection[]>;

export const groupSelectionsByEventId = (
	selections: BetslipReducerSelection[]
) =>
	selections.reduceRight((groups, s) => {
		const group = groups[s.eventId] || [];
		group.push(s);
		groups[s.eventId] = group;
		return groups;
	}, {} as SelectionEventGroups);
