export interface LanguageConfig {
	displayKey: string;
	name: string;
}

export const languageConfig: Record<string, LanguageConfig> = {
	en: {
		displayKey: 'gb',
		name: 'English'
	},
	es: {
		displayKey: 'es',
		name: 'Español'
	},
	de: {
		displayKey: 'de',
		name: 'Deutsch'
	},
	it: {
		displayKey: 'it',
		name: 'Italiano'
	},
	//eslint-disable-next-line
	zh_tw: {
		displayKey: 'zh_tw',
		name: '繁體中文'
	},
	el: {
		displayKey: 'el',
		name: 'Ελληνικά'
	},
	hi: {
		displayKey: 'hi',
		name: 'हिन्दी'
	},
	hu: {
		displayKey: 'hu',
		name: 'Magyar'
	},
	id: {
		displayKey: 'id',
		name: 'Bahasa Indonesia'
	},
	lv: {
		displayKey: 'lv',
		name: 'Latviešu'
	},
	mt: {
		displayKey: 'mt',
		name: 'Malti'
	},
	pl: {
		displayKey: 'pl',
		name: 'Polski'
	},
	pt: {
		displayKey: 'pt',
		name: 'Português'
	},
	//eslint-disable-next-line
	pt_br: {
		displayKey: 'pt_br',
		name: 'Português (Brasil)'
	},
	ru: {
		displayKey: 'ru',
		name: 'Русский'
	},
	//eslint-disable-next-line
	zh_cn: {
		displayKey: 'zh_cn',
		name: '简体中文'
	},
	nl: {
		displayKey: 'nl',
		name: 'Nederlands'
	},
	ja: {
		displayKey: 'ja',
		name: '日本語'
	},
	ko: {
		displayKey: 'ko',
		name: '한국어'
	},
	sv: {
		displayKey: 'sv',
		name: 'Svenska'
	},
	tr: {
		displayKey: 'tr',
		name: 'Türkçe'
	},
	fr: {
		displayKey: 'fr',
		name: 'Français'
	},
	th: {
		displayKey: 'th',
		name: 'ภาษาไทย'
	}
};

export const supportedLanguages = SUPPORTED_LANGUAGES;
