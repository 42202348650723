import { pathParam, queryParam, route } from '@gaming-shell/routing';

const rootRoute = route(['player']);
const accountRoute = rootRoute.add(['account']);
const cashierRoute = rootRoute.add(['cashier']);
const settlementsRoute = rootRoute.add(['settlements']);
const settingsRoute = accountRoute.add(['settings']);

export const PlayerRoutes = {
	// Root routes
	root: rootRoute,
	onboarding: rootRoute.add(['onboarding']),
	bonus: rootRoute.add(['bonus']),
	// Account routes
	account: accountRoute,
	transaction: accountRoute.add(['transaction', pathParam('id')]),
	moonpay: accountRoute.add(['moonpay']),
	inbox: accountRoute.add(['inbox']),
	inboxMessage: accountRoute.add(['inbox', queryParam('message_id')]),
	// Settings routes
	settings: settingsRoute.add([pathParam('page')]),
	disable2fa: settingsRoute.add(['disable-2fa']),
	enable2fa: settingsRoute.add(['enable-2fa']),
	preferences: settingsRoute.add(['preferences']),
	responsibleGambling: settingsRoute.add(['responsible-gambling']),
	security: settingsRoute.add(['security']),
	securityTab: settingsRoute.add(['security', queryParam('tab')]),
	accessLog: settingsRoute.add(['access-log']),
	verification: settingsRoute.add(['verification']),
	verificationTab: settingsRoute.add(['verification', queryParam('tab')]),
	sms: settingsRoute.add(['sms']),
	personalVerification: settingsRoute.add(['personal_verification']),
	// Cashier routes
	cashier: cashierRoute.add([pathParam('page'), pathParam('currency')]),
	cashierDeposit: cashierRoute.add(['deposit', pathParam('currency')]),
	cashierWithdraw: cashierRoute.add(['withdraw', pathParam('currency')]),
	cashierHistory: cashierRoute.add(['history', pathParam('currency')]),
	cashierHistoryPendingTransaction: cashierRoute.add([
		'history',
		pathParam('currency'),
		queryParam('pending_id')
	]),
	// Settlement routes
	settlements: settlementsRoute.add([pathParam('currency')])
};
