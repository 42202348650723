"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var styles_1 = require("@material-ui/core/styles");
exports.useStyles = styles_1.makeStyles(function (theme) { return ({
    iframeWidget: {
        alignItems: 'stretch',
        transition: 'left 1s linear, top 1s linear',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'inline-flex',
        flexDirection: 'column'
    },
    background: function (_a) {
        var backgroundImgUrl = _a.backgroundImgUrl;
        return ({
            background: theme.palette.background.paper,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: backgroundImgUrl
                ? "url(" + backgroundImgUrl + ")"
                : undefined
        });
    },
    cinema: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: theme.zIndex.drawer,
        width: '100vw',
        height: '100vh'
    }
}); });
