"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePusherProxy = exports.usePusher = void 0;
var react_1 = require("react");
var PusherProvider_1 = require("./PusherProvider");
exports.usePusher = function (event, channel, callback, interruptionHandler) {
    var pusher = react_1.useContext(PusherProvider_1.context).pusher;
    var callbackRef = react_1.useRef(callback);
    var deregister = function () {
        if (channel) {
            pusher.unsubscribe(channel);
        }
        pusher.unbind(event, channel, callback);
    };
    react_1.useEffect(function () {
        callbackRef.current = callback;
    }, [callback]);
    react_1.useEffect(function () {
        if (channel) {
            pusher.subscribe(channel);
        }
        pusher.bind(event, channel, function (data) {
            callbackRef.current(data);
        }, interruptionHandler);
        window.addEventListener('unload', deregister);
        return function () {
            deregister();
            window.removeEventListener('unload', deregister);
        };
    }, [event, channel, pusher]);
};
exports.usePusherProxy = function () {
    var pusher = react_1.useContext(PusherProvider_1.context).pusher;
    return pusher;
};
