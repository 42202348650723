import * as React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Translate } from '@gaming-shell/i18n';
import CategoryIcon from 'casino/components/CategoryIcon/CategoryIcon';
import { camelize } from 'casino/util/camelcase';

export interface SearchResultsHeaderProps {
	category: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex'
	},
	icon: {
		marginRight: theme.spacing(1),
		width: theme.spacing(2),
		height: theme.spacing(2)
	},
	text: {
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: theme.typography.caption.fontSize
	}
}));

export const SearchResultsHeader: React.FC<SearchResultsHeaderProps> = ({
	category
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CategoryIcon category={category} className={classes.icon} />
			<Typography color="primary" className={classes.text}>
				<Translate
					label={`casino.games.categories.${camelize(category)}`}
					ns="common"
				/>
			</Typography>
		</div>
	);
};
