import { useTranslation } from '@gaming-shell/i18n';
import { Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Zoom from '@material-ui/core/Zoom';
import { LockIcon } from 'materialbet-icons';
import * as React from 'react';
import CountUp from 'react-countup';

import {
	useLockedProgressStyles,
	useProgressStyles,
	useSecondaryProgressStyles,
	useStyles
} from './PromotionCounter.styles';

export interface PromotionCounterProps {
	/** @desctiption title to be shown above the counter progress bar */
	title?: React.ReactNode;
	/** @desctiption number of the current count, default: 0 */
	current?: number;
	/** @desctiption number of the total of the counter ends, default: 100 */
	total?: number;
	/** @desctiption indicate the progress is locked or not */
	isLocked?: boolean;
	/** @desctiption indicate show percentage or not */
	showPercentage?: boolean;
	/** @desctiption indicate the color of the progress */
	color?: 'primary' | 'secondary';
}
/** @description Promotion Counter that show given header and the progress bar */
let PromotionCounter: React.FC<PromotionCounterProps> = (props) => {
	const {
		title,
		current = 0,
		total = 100,
		isLocked,
		showPercentage = false,
		color = 'primary'
	} = props;
	const roundedValue = Math.round((current / total) * 100); // Calculated percentage and round up
	const value = Math.min(roundedValue, 100);
	const classes = useStyles({ value }); // Current only has locked icon
	const primaryProgressClasses = useProgressStyles();
	const secondaryProgressClasses = useSecondaryProgressStyles();
	const lockedProgressClasses = useLockedProgressStyles({
		value
	});
	const progressClasses =
		color === 'primary' ? primaryProgressClasses : secondaryProgressClasses;
	const trans = useTranslation();
	return (
		<div>
			{title && (
				<Typography variant="h6" className={classes.title}>
					{title}
				</Typography>
			)}
			{showPercentage && (
				<Typography
					variant="caption"
					color="textSecondary"
					className={classes.percentage}
					align="right"
					aria-label={`${roundedValue}%`}
				>
					<CountUp end={roundedValue} suffix="%" preserveValue />
				</Typography>
			)}
			<div className={classes.progressWrapper}>
				<LinearProgress
					classes={isLocked ? lockedProgressClasses : progressClasses}
					value={value}
					variant="determinate"
					aria-label={trans('common:descriptions.status')}
				/>
				<div className={classes.iconPositionWrapper}>
					<Zoom
						in={isLocked}
						aria-label={trans('player:promotions.offerFinished')}
					>
						<div className={classes.lockIconWrapper}>
							<LockIcon
								fontSize="small"
								className={classes.lockedIcon}
							/>
						</div>
					</Zoom>
				</div>
			</div>
		</div>
	);
};
PromotionCounter = React.memo(PromotionCounter);
export { PromotionCounter };
