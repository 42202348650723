import { PusherProvider as PusherProviderDep } from '@gaming-shell/pusher';
import { usePlayerAuthToken } from '@materialbet-core/player';
import * as React from 'react';

export const PusherProvider: React.FunctionComponent<unknown> = React.memo(
	({ children }) => {
		const authenticationToken = usePlayerAuthToken();
		const pusherKey = PUSHER_KEY;
		const cluster = PUSHER_CLUSTER;
		const authEndpoint = PUSHER_AUTH_URL;
		const basicAuth = BASE_API_BASIC_AUTH;
		return (
			<PusherProviderDep
				authenticationToken={authenticationToken}
				pusherKey={pusherKey}
				cluster={cluster}
				authEndpoint={authEndpoint}
				basicAuth={basicAuth}
			>
				{children}
			</PusherProviderDep>
		);
	}
);
