import { useTranslation } from '@gaming-shell/i18n';
import { usePlayerCurrencyVariant } from '@materialbet-core/player';
import BigNumber from 'bignumber.js';
import * as React from 'react';
import { Outcome } from 'sports-sdk/sdk-api-types';
import { CurrencyVariantConfig } from 'sports/config/currency';
import { useStakeFactor } from 'sports/selectors/sportsplayer';
import { formatCurrency } from 'sports/utils/currency';

import { usePlayerCurrencyExchangeRate } from 'materialbet-exchange-rates';
import {
	FormatCurrencyOptions,
	formatCurrencyValue,
	getBaseCurrencyValue,
	getMinStake,
	useCurrency,
	usePlayerCurrencyVariantConfig
} from './currency';
import { getDecimalCount, truncate } from './number';

export interface StakeTrailingInfo {
	trail: string;
	forStake: number;
}
// This is the format to check if the stake input is valid
// This should wllow pattern like:
// 0.
// 0.00
// 0.0001
// 1.0001
// 2.00
export const validStakeFormat = /^$|([0-9]+(\.([0-9]*))?)/;

export const trailingStakeInput = /^\d+(\.[0]*$)|\.\d*?([0]+)$/;

export const getTrailingStakeInput = (stake: string) => {
	const match = stake.match(trailingStakeInput);
	if (!match) {
		return '';
	}
	return match[2] || match[1] || '';
};

export const getTrailingDecimals = (trail: string) => {
	const hasDot = trail.startsWith('.') || trail.indexOf('.');
	const trailDecimals = (hasDot ? trail.split('.')[1] : trail) || '';
	return trailDecimals.length;
};

export const useTrailing = (stake: number) => {
	const state = React.useState<StakeTrailingInfo>({
		trail: '',
		forStake: stake
	});
	const [trailing, setTrailing] = state;
	React.useLayoutEffect(() => {
		if (stake !== trailing.forStake) {
			setTrailing({ trail: '', forStake: stake });
		}
	}, [stake, trailing, setTrailing]);
	return state;
};
interface EventWithTarget {
	target: { value: string };
}
const limitDecimals = (numberString: string, limit: number) => {
	const dotIndex = numberString.indexOf('.');
	if (dotIndex < 0) {
		return numberString;
	}
	return numberString.substring(0, dotIndex + limit + 1);
};
export const handleStakeInputChange = (
	currencyConfig: CurrencyVariantConfig,
	onChange: (s: number) => void,
	setTrailing: (trailing: { trail: string; forStake: number }) => void,
	decimalPlaceLimit?: number
) => (event: EventWithTarget) => {
	const value = event.target.value;
	const match = value.match(/\d+\.?\d*/);
	const numberString = match ? match[0] : '0';
	const variantStake = parseFloat(numberString);
	const currencyMaxDecimals = currencyConfig.decimalPlaces - 1; // the currency config is max decimals for displaying, for placing we have to remove one
	const maxDecimals =
		decimalPlaceLimit !== undefined
			? Math.min(decimalPlaceLimit, currencyMaxDecimals)
			: currencyMaxDecimals;
	if (isNaN(variantStake)) {
		return;
	}
	// Restrict the input from inputting more than the given decimal places
	// const inputDecimalCount = getDecimalCount(variantStake);
	const baseValue = getBaseCurrencyValue(currencyConfig, variantStake);
	const truncatedBaseValue = parseFloat(
		truncate(
			baseValue,
			maxDecimals + Math.log10(currencyConfig.factor),
			true
		)
	);

	const limitedVariantStake = limitDecimals(numberString, maxDecimals);
	const variantDecimalCount = getDecimalCount(
		parseFloat(limitedVariantStake)
	);
	// we lose the dot at the end when parsing the number, so we need to add it back
	let trail = getTrailingStakeInput(limitedVariantStake);
	if (trail) {
		const hasDot = trail.startsWith('.');
		let trailDecimals = (hasDot ? trail.split('.')[1] : trail) || '';
		trailDecimals = trailDecimals.substring(
			0,
			maxDecimals - variantDecimalCount
		);
		trail = (hasDot ? '.' : '') + trailDecimals;
	}

	onChange(truncatedBaseValue);
	setTrailing({ trail, forStake: truncatedBaseValue });
};

export const handleStakeInputChangeDeprecated = (
	currencyConfig: CurrencyVariantConfig,
	onChange: (s: string) => void,
	setTrailing: (t: string) => void
) => (event: React.ChangeEvent<HTMLInputElement>) => {
	const value = event.target.value;
	const match = value.match(/\d+\.?\d*/);
	const numberString = match ? match[0] : '0';
	const variantStake = parseFloat(numberString);
	if (isNaN(variantStake)) {
		return;
	}
	const baseValue = getBaseCurrencyValue(
		currencyConfig,
		variantStake
	).toString();
	// we lose the dot at the end when parsing the number, so we need to add it back
	const trailing = getTrailingStakeInput(numberString);
	setTrailing(trailing);
	onChange(baseValue);
};
export const toReturn = (stake: number, odds: number, currency: string) => {
	const bigStake = new BigNumber(stake);
	const product = bigStake.multipliedBy(odds);
	return formatCurrencyValue(product.toNumber(), currency);
};

export const calculateStake = (
	stakeInEuro: number,
	exchangeRate: string,
	stakeFactor: number,
	currency: string,
	options?: FormatCurrencyOptions
) =>
	parseFloat(
		formatCurrencyValue(
			new BigNumber(stakeInEuro)
				.multipliedBy(exchangeRate)
				.multipliedBy(stakeFactor || 1)
				.toNumber(),
			currency,
			options
		)
	);

export const useCalculatedMaxStake = (
	stakeInEuro: number,
	maxDecimalPlaces?: number
) => {
	const currency = useCurrency();
	const stakeFactor = useStakeFactor();
	const exchangeRates = usePlayerCurrencyExchangeRate();

	return calculateStake(
		stakeInEuro,
		exchangeRates || '0',
		stakeFactor,
		currency,
		{
			maxDecimalPlaces
		}
	);
};
export interface CalulcatedMinStakeParams {
	stake: number;
	availableRates?: string;
	factor: number;
	currency: string;
	maxDecimalPlaces?: number;
	currencyVariant: string;
}
export const calculatedMinStake = ({
	stake,
	availableRates,
	factor,
	currency,
	maxDecimalPlaces,
	currencyVariant
}: CalulcatedMinStakeParams) => {
	const currencyMinStake = getMinStake(
		currency,
		currencyVariant,
		maxDecimalPlaces
	);
	const calculatedMinStake = calculateStake(
		stake,
		availableRates || '0',
		factor,
		currency,
		{
			maxDecimalPlaces
		}
	);
	if (calculatedMinStake < currencyMinStake) {
		return currencyMinStake / factor;
	}
	return calculatedMinStake / factor;
};
/** @deprecated */
export const useCalculatedMinStake = (
	stake: number,
	maxDecimalPlaces?: number
) => {
	const currency = useCurrency();
	const variant = usePlayerCurrencyVariant();
	const { factor = 1 } = usePlayerCurrencyVariantConfig();
	const availableRates = usePlayerCurrencyExchangeRate();

	return calculatedMinStake({
		currency,
		currencyVariant: variant,
		factor,
		availableRates,
		stake,
		maxDecimalPlaces
	});
};

export const useStakeLimitLabel = (outcome: Outcome): string => {
	const selection = outcome.back;
	const minStake = (selection && selection.minStake) || 0;
	const maxStake = (selection && selection.maxStake) || 0;
	const currency = useCurrency();
	const currencyConfig = usePlayerCurrencyVariantConfig();
	const { variant, decimalPlaces } = currencyConfig;
	const t = useTranslation();
	const labels = {
		minStake: t('common:descriptions.minStake'),
		maxStake: t('common:descriptions.maxStake')
	};
	const currencyOptions = {
		variant,
		maxDecimalPlaces: Math.min(decimalPlaces, BETSLIP_MAX_INPUT_DP)
	};
	const minStakeInCurrency = useCalculatedMinStake(
		minStake,
		BETSLIP_MAX_INPUT_DP
	);
	const formattedMinStake = formatCurrency(
		minStakeInCurrency,
		currency,
		currencyOptions
	);
	const maxStakeInCurrency = useCalculatedMaxStake(maxStake);
	const formattedMaxStake = parseFloat(
		formatCurrency(maxStakeInCurrency, currency, {
			variant
		})
	);
	const trimmedFormatMaxStake = parseFloat(
		formatCurrency(maxStakeInCurrency, currency, currencyOptions)
	);
	return `${outcome.name}\n${labels.maxStake}: ${
		trimmedFormatMaxStake || formattedMaxStake
	}\n${labels.minStake}: ${formattedMinStake}`;
};
