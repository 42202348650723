import { pathParam, route } from '@gaming-shell/routing';

export const CasinoRoutes = {
	root: route(['casino']) /* also serves as rng lobby */,
	myCasino: route(['casino', 'my']),
	recommendations: route(['casino', 'recommendations']),
	liveCasino: route(['casino', 'live']),
	/** @obsolete Use legacyGame and legacyFreePlayGame, this route has been deprecated in favor of using the same routing as on legacy */
	game: route(['casino', 'game', pathParam('gameId')]),
	legacyFreeplayGame: route([
		'casino',
		'free',
		pathParam('category'),
		pathParam('gameKey'),
		pathParam('gameId')
	]),
	legacyGame: route([
		'casino',
		pathParam('category'),
		pathParam('gameKey'),
		pathParam('gameId')
	]),
	rngGames: route(['casino', pathParam('gameType')]), // todo need to rename gameTyoe to section
	rngStudioGames: route([
		'casino',
		pathParam('gameType'),
		'studio',
		pathParam('studioKey')
	]),
	liveGames: route(['casino', 'live', pathParam('gameType')]), // todo need to rename gameTyoe to section
	liveStudioGames: route([
		'casino',
		'live',
		pathParam('gameType'),
		'studio',
		pathParam('studioKey')
	]),
	legacyLiveCasino: route(['live_casino']),
	permanentLiveCasino: route(['casino', 'live']),
	studioGames: route(['casino', 'studios', pathParam('studioKey')]),
	allProviders: route(['casino', 'providers']),
	allThemes: route(['casino', 'themes']),
	gamesWeLove: route(['casino', 'games-we-love']),
	theme: route(['casino', 'theme', pathParam('themeKey')])
};
