import { CasinoEntitiesKeys } from 'casino/casinoSchemas';
import { actions } from 'casino/modules/commonModule';
import {
	CreateFetchEntityEpic,
	createFetchEntityEpic as genericCreateFetchEntitiesEpic
} from 'materialbet-common';

import { CasinoEpicDependencies } from '../store';
import { CasinoMetaPayload } from './metaUtils';

type GenericCasinoCreateFetchEntityEpic<
	TArgs,
	TPromiseResult
> = CreateFetchEntityEpic<
	CasinoEpicDependencies,
	TArgs,
	TPromiseResult,
	CasinoMetaPayload,
	CasinoEntitiesKeys
>;
type CasinoCreateFetchEntityEpic = <TArgs, TPromiseResult>(
	params: Omit<
		Parameters<
			GenericCasinoCreateFetchEntityEpic<TArgs, TPromiseResult>
		>[0],
		'updateEntitiesAction'
	>
) => ReturnType<GenericCasinoCreateFetchEntityEpic<TArgs, TPromiseResult>>;

export const createCasinoFetchEntityEpic: CasinoCreateFetchEntityEpic = (
	params
) =>
	genericCreateFetchEntitiesEpic({
		...params,
		updateEntitiesAction: actions.updateEntities
	});
