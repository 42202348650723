/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
	Identifier,
	IdentifierFromJSON,
	IdentifierFromJSONTyped,
	IdentifierToJSON
} from './';

/**
 *
 * @export
 * @interface Player
 */
export interface Player {
	/**
	 *
	 * @type {string}
	 * @memberof Player
	 */
	name?: string;
	/**
	 *
	 * @type {Identifier}
	 * @memberof Player
	 */
	position?: Identifier;
	/**
	 *
	 * @type {string}
	 * @memberof Player
	 */
	team?: string;
}

export function PlayerFromJSON(json: any): Player {
	return PlayerFromJSONTyped(json, false);
}

export function PlayerFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): Player {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		name: !exists(json, 'name') ? undefined : json['name'],
		position: !exists(json, 'position')
			? undefined
			: IdentifierFromJSON(json['position']),
		team: !exists(json, 'team') ? undefined : json['team']
	};
}

export function PlayerToJSON(value?: Player | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		name: value.name,
		position: IdentifierToJSON(value.position),
		team: value.team
	};
}
