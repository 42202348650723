/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
	TopCouponsListMeta,
	TopCouponsListMetaFromJSON,
	TopCouponsListMetaFromJSONTyped,
	TopCouponsListMetaToJSON
} from './';

/**
 * TopCouponsListResponse presents a list of top coupons
 * @export
 * @interface TopCouponsListResponse
 */
export interface TopCouponsListResponse {
	/**
	 *
	 * @type {Array<TopCouponsListMeta>}
	 * @memberof TopCouponsListResponse
	 */
	topCouponsListMetas?: Array<TopCouponsListMeta>;
}

export function TopCouponsListResponseFromJSON(
	json: any
): TopCouponsListResponse {
	return TopCouponsListResponseFromJSONTyped(json, false);
}

export function TopCouponsListResponseFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): TopCouponsListResponse {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		topCouponsListMetas: !exists(json, 'topCouponsListMetas')
			? undefined
			: (json['topCouponsListMetas'] as Array<any>).map(
					TopCouponsListMetaFromJSON
			  )
	};
}

export function TopCouponsListResponseToJSON(
	value?: TopCouponsListResponse | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		topCouponsListMetas:
			value.topCouponsListMetas === undefined
				? undefined
				: (value.topCouponsListMetas as Array<any>).map(
						TopCouponsListMetaToJSON
				  )
	};
}
