import * as React from 'react';
import { useFormatOdds } from 'sports/utils/odds';

interface FormattedOddsProps {
	odds: number;
}

export const FormattedOdds: React.FunctionComponent<FormattedOddsProps> = ({
	odds
}) => {
	const formatOdds = useFormatOdds();
	return <>{formatOdds(odds)}</>;
};
