import { makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clx from 'classnames';
import { fillArray } from 'materialbet-common';
import * as React from 'react';
import { LiveEventListWidgetHeader } from 'sports/components/displays/LiveEventListWidgetHeader';
import { EventListSkeleton } from 'sports/components/eventList/EventList';
import { CustomPaper } from 'sports/components/materialWrapper/CustomPaper';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: 324,
		overflow: 'auto'
	},
	sportContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '48px',
		padding: theme.spacing(0, 1)
	},
	sportIcon: {
		marginRight: theme.spacing(1)
	},
	sportName: {
		width: '40%'
	},
	competition: {
		width: '100%',
		padding: theme.spacing(2)
	}
}));

export interface LiveEventNavigationWidgetSkeletonProps {
	className?: string;
}

let LiveEventNavigationWidgetSkeleton: React.FunctionComponent<LiveEventNavigationWidgetSkeletonProps> = (
	props
) => {
	const { className } = props;
	const classes = useStyles();
	const sports = fillArray(3, (index) => (
		<div key={index}>
			<div className={classes.sportContainer} key={index}>
				<Skeleton
					className={classes.sportIcon}
					height={20}
					width={20}
					variant="circle"
				/>
				<Skeleton className={classes.sportName} />
			</div>
			<Skeleton className={classes.competition} variant="rect" />
			<EventListSkeleton items={2} variant="narrow" />
		</div>
	));
	return (
		<CustomPaper className={clx(classes.root, className)}>
			<LiveEventListWidgetHeader hideAllLiveEventsButton />
			{sports}
		</CustomPaper>
	);
};

LiveEventNavigationWidgetSkeleton = React.memo(
	LiveEventNavigationWidgetSkeleton
);

export { LiveEventNavigationWidgetSkeleton };
