"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMockProxy = exports.MockPusherEvents = void 0;
var MockPusherEvents;
(function (MockPusherEvents) {
    MockPusherEvents["SubscriptionError"] = "SubscriptionError";
    MockPusherEvents["Connected"] = "Connected";
})(MockPusherEvents = exports.MockPusherEvents || (exports.MockPusherEvents = {}));
var getBindId = function (event, channel) { return event + "_" + channel; };
exports.createMockProxy = function () {
    var boundEvents = {};
    var interruptionHandlers = {};
    var reconnectHandlers = {};
    var fireEvent = function (channel, event, data) {
        var bindId = getBindId(event, channel);
        if (channel === 'pusher') {
            if (event === MockPusherEvents.SubscriptionError) {
                var interruptionHandler = interruptionHandlers[bindId];
                if (!interruptionHandler) {
                    return;
                }
                var reconnectHandler = interruptionHandler('CONNECTION_NOT_ESTABLISHED');
                if (reconnectHandler) {
                    reconnectHandlers[bindId] = reconnectHandler;
                }
            }
            else if (event === MockPusherEvents.Connected) {
                var reconnectHandler = reconnectHandlers[bindId];
                if (!reconnectHandler) {
                    return;
                }
                reconnectHandler();
            }
            return;
        }
        var callback = boundEvents[bindId];
        if (!callback) {
            console.warn('Attempting to fire event for which not callback has been registered');
            return;
        }
        callback(data);
    };
    return {
        bind: function (event, channel, callback, interruptionHandler) {
            boundEvents[getBindId(event, channel)] = callback;
            if (interruptionHandler) {
                interruptionHandlers[getBindId(event, channel)] = interruptionHandler;
            }
        },
        unbind: function (event, channel, _) {
            var bindId = getBindId(event, channel);
            delete interruptionHandlers[bindId];
            delete reconnectHandlers[bindId];
        },
        subscribe: function (_) { },
        unsubscribe: function (_) { },
        fireEvent: fireEvent,
        disconnect: function () { }
    };
};
