import { Translate } from '@gaming-shell/i18n';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { LinkButtonBase, SportsRoutes } from 'materialbet-common';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		padding: theme.spacing(1, 2),
		alignItems: 'center',
		display: 'flex'
	},
	live: {
		marginRight: theme.spacing(1),
		borderBottom: `2px ${theme.palette.secondary.main} solid`
	},
	allLiveEventsButton: {
		marginLeft: 'auto'
	}
}));

interface LiveEventListWidgetHeaderProps {
	hideAllLiveEventsButton?: boolean;
}

export const LiveEventListWidgetHeader: React.FunctionComponent<LiveEventListWidgetHeaderProps> = (
	props
) => {
	const { hideAllLiveEventsButton } = props;
	const classes = useStyles();
	return (
		<div className={classes.header}>
			<Typography variant="h6" className={classes.live}>
				<Translate label="sports.content.inPlay" />
			</Typography>

			{!hideAllLiveEventsButton && (
				<LinkButtonBase
					to={() => SportsRoutes.live.create()}
					className={classes.allLiveEventsButton}
				>
					<Typography variant="caption" color="secondary">
						<Translate label="event.seeAllLiveEvents" ns="sports" />
					</Typography>
				</LinkButtonBase>
			)}
		</div>
	);
};
