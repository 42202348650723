import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: [
			{
				marketType: MarketType.rugby_league_moneyline
			},
			{
				marketType: MarketType.rugby_league_handicap,
				view: MarketView.Handicap
			},
			{
				marketType: MarketType.rugby_league_totals,
				view: MarketView.Goals
			},
			{
				marketType: MarketType.rugby_league_team_totals,
				view: MarketView.Goals
			},
			{
				marketType: MarketType.rugby_league_draw_no_bet
			},
			{
				marketType: MarketType.rugby_league_halftime_fulltime_result
			},
			{
				marketType: MarketType.rugby_league_odd_even
			}
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.rugby_league_moneyline
			},
			{
				marketType: MarketType.rugby_league_handicap
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.rugby_league_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
