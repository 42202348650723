import { useTranslation } from '@gaming-shell/i18n';
import { Typography } from '@material-ui/core';
import { filterMap, Preview, withHook } from 'materialbet-common';
import * as React from 'react';
import { Provider } from 'react-redux';
import { useSportsList } from 'sports/components/sideMenu';
import {
	SportPreviewCard,
	SportPreviewCardSkeleton
} from 'sports/components/SportPreviewCard';
import store from 'sports/store';
import { useGetSportPreviewCardWidth } from 'sports/utils/sportPreviewCardUtils';
import { useEsports } from 'sports/utils/sports';

// Esports for now, we not sure we have other later
const useEsportsPreviewProps = () => {
	const trans = useTranslation();
	const { sports, loaded } = useSportsList();
	const esports = useEsports();
	const width = useGetSportPreviewCardWidth();
	const title = (
		<Typography variant="h6">
			{trans('sports:betting.betOnEsports')}
		</Typography>
	);
	const filteredSliderElements = filterMap(
		esports,
		(key) => {
			const sport = sports[key];
			const hasCount = !!sport?.eventCount || !!sport?.outrightCount;
			return hasCount;
		},
		(key) => <SportPreviewCard sportKey={key} key={key} />
	);
	return {
		sliderElements: filteredSliderElements,
		title,
		loaded,
		slideSkeleton: <SportPreviewCardSkeleton />,
		slideWidth: width,
		skeletonItems: 4
	};
};

export const EsportsPreview = withHook(useEsportsPreviewProps)(Preview);

export const EsportsExternalPreview: React.FC = () => (
	<Provider store={store}>
		<EsportsPreview />
	</Provider>
);
