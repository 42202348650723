import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { registerServiceWorker, unregister } from './registerServiceWorker';
import Shell from './Shell';
import { startup } from './startup';

const root = document.getElementById('root');
const swHandler = {}; // create reference to be called by service worker register, and to be filled in by app
if (root) {
	ReactDOM.render(
		<Shell swHandler={swHandler} />,
		document.getElementById('root') as HTMLElement
	);
}
if (ENABLE_SERVICEWORKER) {
	registerServiceWorker(swHandler);
} else {
	unregister();
}

startup();
