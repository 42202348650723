import { mapTagsToCategory } from 'materialbet-common';
import { Hit } from 'react-instantsearch-core';

import { SearchResultItem } from '../Content';

export const groupByTags = (
	hits: Hit<SearchResultItem>[]
): { [key: string]: Hit<SearchResultItem>[] } =>
	hits.reduce((objectsByKeyValue, obj) => {
		/* loop through all tags and first match is game category - same behavior as on legacy */
		const category = mapTagsToCategory(obj?._tags);
		if (category) {
			objectsByKeyValue[category] = (
				objectsByKeyValue[category] || []
			).concat(obj);
		}
		return objectsByKeyValue;
	}, {});
