import hoistNonReactStatic from 'hoist-non-react-statics';
import * as React from 'react';

// types are copied from @types/react-redux
/** @descriptions Run a hook and inject its return value into the component */
export const withMappedProps = <TInputProps = {}, TResultProps = {}>(
	map: (props: TInputProps) => TResultProps
) => <C extends React.ComponentType<TResultProps>>(Component: C) =>
	hoistNonReactStatic((props: TInputProps) => {
		const mappedProps = map(props);
		return React.createElement(Component, mappedProps);
	}, Component);
