import { Configuration } from './configuration';

export interface SportsPlayer {
	stakeFactor: number;
}

export interface SportsPlayersApi {
	getMe: () => Promise<SportsPlayer>;
}

export const createSportsplayersApi = (configuration: Configuration) => {
	const { basePath, baseOptions } = configuration;
	const createOptions = (method: string, body?: string, headers?: object) => {
		const options = { ...baseOptions, method, body };
		options['headers'] = { ...baseOptions?.['headers'], ...headers };
		return options;
	};

	return {
		getMe: () => {
			const url = basePath + 'me';
			const options = createOptions('GET');
			return fetch(url, options).then((response) => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				} else {
					throw response;
				}
			});
		}
	} as SportsPlayersApi;
};
