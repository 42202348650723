import { Collapse } from '@material-ui/core';
import { CardContentProps } from '@material-ui/core/CardContent';
import * as React from 'react';

import { BaseCard, BaseCardProps } from '../BaseCard';

export interface PromoExpandableCardProps extends BaseCardProps {
	collapsed?: boolean;
	collapsedContent?: React.ReactNode;
	collapsedContentProps?: CardContentProps;
}

export const PromoExpandableCard: React.FunctionComponent<PromoExpandableCardProps> = ({
	children,
	collapsed,
	collapsedContent,
	collapsedContentProps,
	...rest
}) => {
	return (
		<BaseCard
			collapseElement={
				<Collapse in={collapsed} mountOnEnter unmountOnExit>
					{collapsedContent}
				</Collapse>
			}
			{...rest}
		>
			{children}
		</BaseCard>
	);
};
