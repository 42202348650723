import {
	Wallet as WalletApi,
	Wallets as WalletsApi
} from '@materialbet-core/api';
import { Wallet, Wallets } from '@materialbet-core/player';
import {
	Wallet as WalletPusher,
	Wallets as WalletsPusher
} from 'materialbet-common';

export const parseWallet = (obj: WalletPusher | WalletApi): Wallet => {
	const bonusAmount =
		(obj as WalletPusher).bonusAmount ||
		(obj as WalletApi).bonus_amount ||
		'0';

	return {
		amount: Number(obj.amount || '0'),
		bonusAmount: Number(bonusAmount)
	};
};

export const parseWallets = (wallets: WalletsApi | WalletsPusher): Wallets => {
	return Object.keys(wallets).reduce((acc, currency) => {
		acc[currency] = parseWallet(wallets[currency]);

		return acc;
	}, {});
};
