import { useTranslation } from '@gaming-shell/i18n';
import { useSnackbar } from 'materialbet-common';
import * as React from 'react';

const useGeneralErrorHandler = () => {
	const trans = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const showGenericError = React.useCallback(() => {
		enqueueSnackbar(trans('common:application.descriptions.generalError'));
	}, [enqueueSnackbar, trans]);

	return showGenericError;
};

export default useGeneralErrorHandler;
