import { isSameDay } from 'date-fns';
import { mapFilter } from 'materialbet-common';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Identifier } from 'sports/api/sports';
import { RootState } from 'sports/modules/root';
import { loads } from 'sports/modules/sports';
import * as schema from 'sports/schema';
import { CompetitionEntity } from 'sports/schema';
import {
	EventTreesMetaParams,
	buildEventTreesMetaKey
} from 'sports/utils/meta';
import { SportType } from '../config/sport';
import { loadedOnce } from './loading';
import { getEventTreesMeta } from './meta';
import { hasLiveStream } from './eventSelectors';
import {
	IdProp,
	idPropSelector,
	keyPropSelector,
	idsPropSelector,
	keysPropSelector
} from './propSelectors';
import { _DeepReadonlyObject } from 'utility-types/dist/mapped-types';
/**
 * @file Centralized memoized access to the store
 *
 * NOTE that most selectors have the potential to be optimized if the need arises. (Dont chain looping instructions, cache internal id selectors etc)
 */
// State selectors (access data via state.something)
const sportsSelector = (state: RootState) => state.sports;
const eventsSelector = (state: RootState) => state.events;
const topCouponsSelector = (state: RootState) => state.topCoupons;
const categoriesSelector = (state: RootState) => state.categories;
const competitionsSelector = (state: RootState) => state.competitions;
// const outrightsSelector = (state: RootState) => state.outrights;
const livesport365Selector = (state: RootState) => state.livesport365;

const eventTreeParamSelector = (
	_: unknown,
	{ params }: { params: EventTreesMetaParams }
) => params;
// Direct Slice Selectors
export const getEvent = (state: RootState, { id }: IdProp) =>
	eventsSelector(state)[id];
export const getCategory = (state: RootState, { id }: IdProp) =>
	categoriesSelector(state)[id];
// export const getOutright = (state: RootState, { id }: IdProp) =>
// 	outrightsSelector(state)[id];
export const getCompetition = (state: RootState, { id }: IdProp) =>
	competitionsSelector(state)[id];
export const getTopCouponName = (state: RootState, { id }: IdProp) =>
	(topCouponsSelector(state)[id] || { name: '' }).name;
export const getSport = (state: RootState, { id }: IdProp) =>
	sportsSelector(state)[id];
export const getLivesport365 = (state: RootState, { id }: IdProp) =>
	livesport365Selector(state)[id];
export interface Competitior extends Identifier {
	sport: number;
	type: number;
}
export type FullEvent = Pick<
	schema.EventEntity,
	Exclude<keyof schema.EventEntity, 'competition'>
> & {
	competition: schema.CompetitionEntity;
};

// TODO fix with event data
export const makeEventWCompetitor = () =>
	createSelector([getEvent, competitionsSelector], (event, competitions) => {
		if (!event || !event.id || !event.competition) {
			return;
		}
		const compId = (event.competition as unknown) as string | undefined;
		if (!compId) {
			return;
		}
		const comp = competitions[compId] as CompetitionEntity;
		if (!comp || comp.blank) {
			return;
		}
		const fullEvent: FullEvent = {
			...(event as schema.EventEntity),
			id: event.id as number,
			competition: comp
		};
		return fullEvent;
	});

export const makeSportByKey = () =>
	createSelector([sportsSelector, keyPropSelector], (sports, key) => {
		if (!Object.keys(sports).length) {
			return null;
		}
		const sportId = Object.keys(sports).find(
			(id) => sports[id].key === key
		);
		if (sportId) {
			return sports[sportId];
		}
		return null;
	});
export const makeSportByEvent = () =>
	createSelector(
		[sportsSelector, competitionsSelector, getEvent],
		(sports, competitions, event) => {
			if (!event) {
				return null;
			}
			const eventId = event.id;
			if (!eventId) {
				return null;
			}
			const competitionKey = event.competition;
			if (!competitionKey) {
				return null;
			}
			const sportId = competitions[competitionKey].sport;
			const sport = sports[sportId];
			if (!sport) {
				return null;
			}
			return sport;
		}
	);

export const tradingEventsSelector = createSelector(
	eventsSelector,
	(events) => {
		const eventArray = Object.keys(events)
			.map((key) => events[key])
			.filter(
				(event) =>
					event.status === 'TRADING' ||
					event.status === 'TRADING_LIVE'
			);
		return eventArray;
	}
);

export const getEventTree = createSelector(
	[getEventTreesMeta, eventTreeParamSelector],
	(eventTrees, params) => {
		if (!eventTrees) {
			return undefined;
		}
		const key = buildEventTreesMetaKey(params);
		return eventTrees[key];
	}
);
export const makeEventListDetailsSelector = () => {
	const getEvent = makeEventWCompetitor(); // This one is returning full event
	const getSportByEvent = makeSportByEvent();
	return createSelector([getEvent, getSportByEvent], (event, sport) => {
		return {
			homeTeam: event?.home?.name || '',
			awayTeam: event?.away?.name || '',
			name: event?.name || '',
			startTime: event?.startTime || '',
			sportKey: sport?.key,
			competitionKey: event?.competition?.key || '',
			status: event ? event.status : '',
			players: (event && event.players) || undefined,
			id: event?.id,
			metadata: event?.metadata || undefined,
			media: event?.media
		};
	});
};
export const useEventListDetailsSelector = (props: IdProp) => {
	const getEventListDetails = makeEventListDetailsSelector();
	const details = useSelector((state: RootState) =>
		getEventListDetails(state, props)
	);
	const isLiveStream = useSelector(hasLiveStream(props.id));
	return {
		...details,
		isLiveStream
	};
};
/**
 * @description Creates a selector which returns for a given sport id groups of competition ids with category id as a group key, uses the sportTree meta to decide what to include.
 */
const createGroupedCompetitionsSelector = (
	competitionFilter?: (
		competition: _DeepReadonlyObject<schema.CompetitionEntity>
	) => boolean
) =>
	createSelector(
		[getSport, categoriesSelector, competitionsSelector],
		(sport, categories, competitions) => {
			if (!sport || !sport.categories || !categories) {
				return [];
			}
			return sport.categories?.reduce((groups, category) => {
				const competitionsCount =
					categories[category].competitions.length;
				const items = categories[category].competitions.filter(
					(key: string) =>
						competitions[key].sport === sport.key &&
						!(competitions[key] || { blank: true }).blank &&
						(competitionFilter
							? competitionFilter(competitions[key])
							: true) // Dont do extra filter if no filter is passed
				);
				const sportsKey = sport.key;
				if (!competitionsCount || !items.length) {
					return groups;
				}
				groups.push({
					id: category as string,
					items,
					sportsKey: sportsKey as SportType
				});
				return groups;
			}, [] as { id: string; items: string[]; sportsKey: SportType }[]);
		}
	);

/**
 * @description Creates a selector which returns for a given sport id groups of event competition ids with category id as a group key, uses the sportTree meta to decide what to include.
 */
export const getCompetitionGroups = createGroupedCompetitionsSelector(
	(competition) => !!competition.eventCount
);
/**
 * @description Creates a selector which returns for a given sport id groups of outright competition ids with category id as a group key, uses the sportTree meta to decide what to include.
 */
export const getOutrightCompetitionGroups = createGroupedCompetitionsSelector(
	(competition) =>
		!!competition.outrightCount && !!competition?.outrights?.length
);
/**
 * @description Creates a selector which returns the sport for a given competition id
 */
export const makeSportByCompetition = () =>
	createSelector([sportsSelector, getCompetition], (sports, competition) => {
		if (!competition) {
			return null;
		}
		return sports[competition.sport as string];
	});
export const makeCompetitionByEvent = () =>
	createSelector([competitionsSelector, getEvent], (competitions, event) => {
		if (!event) {
			// it seems the event is not loaded yet, or does not exist
			return null;
		}
		const eventId = event.id;
		if (!eventId) {
			throw Error('Store Integrity: Stored event is missing property id');
		}
		if (
			typeof event.competition === 'string' &&
			competitions[event.competition]
		) {
			return competitions[event.competition];
		}
		throw Error(
			'Store Integrity: Could not find competition for event id ' +
				eventId
		);
	});

export const makeEventsByCompetition = () =>
	createSelector([getCompetition, eventsSelector], (competition, events) => {
		if (!competition || !competition.events || competition.blank) {
			return [];
		}
		// TODO should be fixed after normilzr
		return [...competition.events]
			.filter((id) => !events[id].blank)
			.sort((idA: number, idB: number) => {
				const eventA = events[idA];
				const eventB = events[idB];
				if (eventA.status === eventB.status) {
					// order by startTime
					return (eventA.startTime || '0') < (eventB.startTime || '0')
						? -1
						: 1;
				}
				return eventA.status === 'TRADING_LIVE' ? -1 : 1;
			}) as number[];
	});
export const makeOutrightsByCompetition = () =>
	createSelector([getCompetition, eventsSelector], (competition, events) => {
		if (!competition || !competition.outrights || competition.blank) {
			return [];
		}
		// TODO should be fixed after normilzr
		return [...competition.outrights].filter(
			(id) => !events[id].blank
		) as number[];
	});
export const getSportKey = (state: RootState, idProp: IdProp) =>
	(sportsSelector(state)[idProp.id] || {}).key;
export const getEventSportKey = (id: number) => (state: RootState) => {
	const competitionKey = state.events[id]?.competition;
	if (!competitionKey) {
		return;
	}
	const sportKey: string | undefined =
		state.competitions[competitionKey]?.sport;
	return sportKey;
};
export const makeLiveStreamUrl = () =>
	createSelector([getEvent, livesport365Selector], (event, matches) => {
		if (!event) {
			return null;
		}
		if (!event.betradarId) {
			return null;
		}
		const match = matches[event.betradarId];
		if (!match) {
			return null;
		}
		try {
			return match.content.video.embeded; // cannot trust this reducer state as the api is not from us
		} catch (e) {
			return null;
		}
	});
export const makeCompetitionTagsByEvent = () =>
	createSelector(
		[getEvent, competitionsSelector, idPropSelector],
		(event, competitions, eventId) => {
			if (event && event.competition) {
				const competition =
					competitions[(event.competition as unknown) as string];
				if (!competition) {
					return [];
				}
				return competition.tags || [];
			}
			const eventIdNumber = parseInt(eventId + '', 10);
			const key = Object.keys(competitions).find((k) => {
				const comp = competitions[k];
				const events = comp.events as number[];
				if (!events) {
					return false;
				}

				return events.includes(eventIdNumber);
			});
			if (!key) {
				return [];
			}
			return competitions[key].tags || [];
		}
	);
export const makeGetEvents = () =>
	createSelector([eventsSelector, idsPropSelector], (events, ids) =>
		ids.map((id) => events[id])
	);
export const makeStartTimes = () => {
	const getEvents = makeGetEvents();
	return createSelector([getEvents], (events) => {
		return mapFilter(
			events,
			(e) =>
				e && {
					startTime: e.startTime || e.cutoffTime,
					id: e.id,
					blank: e.blank
				},
			(s) => !!(s && s.startTime && s.id && !s.blank)
		) as { startTime: string; id: number }[];
	});
};
export const makeEventGroups = () => {
	const getEvents = makeGetEvents();
	return createSelector([getEvents], (events) => {
		return events.reduce((groups, event) => {
			if (!event || !event.id) {
				return groups;
			}
			const eventId = event.id;
			const fullStartTime = event.startTime;
			if (!eventId || !fullStartTime || event.blank) {
				return groups;
			}
			const startDate = new Date(fullStartTime);
			let current = groups[groups.length - 1];
			if (current && isSameDay(current.date, startDate)) {
				current.events.push(eventId);
				return groups;
			} else {
				current = {
					events: [eventId] as number[],
					date: startDate
				};
				groups.push(current);
				return groups;
			}
		}, [] as { events: number[]; date: Date }[]);
	});
};

export const makeGetCompetitions = () =>
	createSelector(
		[competitionsSelector, keysPropSelector],
		(competitions, keys) =>
			mapFilter(
				keys,
				(key) => competitions[key],
				(c) => !!c
			)
	);

export const makeCompetitionList = () => {
	const getCompetitions = makeGetCompetitions();
	return createSelector([getCompetitions], (competitions) => competitions);
};

export const useSports = () => {
	const sports = useSelector(sportsSelector);
	return sports;
};

export const useTopCoupons = () => {
	const topCoupons = useSelector(topCouponsSelector);
	return topCoupons;
};

export const useCompetitionEventCount = (competitionKey: string) =>
	useSelector((state: RootState) => {
		const comp = getCompetition(state, { id: competitionKey });
		return comp && comp.eventCount;
	});
export const useEventGroupsSelector = (eventIds: number[]) => {
	const getEventGroups = useMemo(() => makeEventGroups(), []);
	return useSelector((state: RootState) =>
		getEventGroups(state, { ids: eventIds })
	);
};

export const useCompetitionListSelector = (competitionKeys: string[]) => {
	const getCompetitionList = useMemo(() => makeCompetitionList(), []);
	return useSelector((state: RootState) =>
		getCompetitionList(state, { keys: competitionKeys })
	);
};

export const useStartTimesSelector = (eventIds: number[]) => {
	const getStartTimes = useMemo(() => makeStartTimes(), []);
	return useSelector((state: RootState) =>
		getStartTimes(state, { ids: eventIds })
	);
};

export const useSportLoaded = (sportKey: SportType) =>
	useSelector((s: RootState) =>
		loadedOnce(s, { name: loads.sport(sportKey) })
	);
