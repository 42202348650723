import { Configuration } from './configuration';

export interface BetShareRequestParams {
	uuid: string;
}
export interface BetShareResponse {
	shareUrl: string;
}

export interface SportssharingApi {
	getBetShareUrl(params: BetShareRequestParams): Promise<BetShareResponse>;
}

export default (configuration: Configuration) => {
	const baseOptions = configuration.baseOptions || {};
	const basePath = configuration.basePath;
	const createOptions = (method: string, body?: string, headers?: object) => {
		const options = { ...baseOptions, method, body };
		options['headers'] = { ...baseOptions['headers'], ...headers };
		return options;
	};
	const api: SportssharingApi = {
		getBetShareUrl: (params: BetShareRequestParams) => {
			const url = basePath + '/bets';
			const options = createOptions('POST', JSON.stringify(params));
			return fetch(url, options).then((response) => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				} else {
					if (response.json) {
						throw response.json();
					}
					throw response;
				}
			});
		}
	};
	return api;
};
