import { getStaticMedia } from 'materialbet-common';
import ZeroMarginImage from 'sports/assets/promotions/zeroMargin.png';
import ZeroMarginBlogImage from 'sports/assets/promotions/zeroMarginBlog.png';
import ZeroMarginMobileHeroImage from 'sports/assets/promotions/zeroMarginMobileHero.png';
import ZeroMarginSmallImage from 'sports/assets/promotions/zeroMarginSmall.png';
import ZeroMarginWideImage from 'sports/assets/promotions/zeroMarginWide.png';
import ZeroMarginWithTitleSmallImage from 'sports/assets/promotions/zeroMarginWithTitleSmall.png';
import ZeroMarginWithTitleWideImage from 'sports/assets/promotions/zeroMarginWithTitleWide.png';

export const zeroMarginCompetitionKey = 'soccer-england-premier-league';
export const zeroMarginSportKey = 'soccer';
// TODO  this is just a placeholder image until we get the actual image, we might just upload it with our assets
export const zeroMarginMobileHeroImageUrl = getStaticMedia(
	ZeroMarginMobileHeroImage
);
export const zeroMarginBlogImageUrl = getStaticMedia(ZeroMarginBlogImage);
export const zeroMarginImageUrl = getStaticMedia(ZeroMarginImage);
export const zeroMarginSmallImageUrl = getStaticMedia(ZeroMarginSmallImage);
export const zeroMarginWithTitleSmallImageUrl = getStaticMedia(
	ZeroMarginWithTitleSmallImage
);
export const zeroMarginWideImageUrl = getStaticMedia(ZeroMarginWideImage);
export const zeroMarginWithTitleWideImageUrl = getStaticMedia(
	ZeroMarginWithTitleWideImage
);
export const competitionPollInterval = 120000;
export const eventPromotionPollInterval = 60000;
export const activeEventPromotionPollInterval = 10000;
