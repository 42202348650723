"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.retryable = void 0;
// TODO we could probably remove its usage - need to test and confirm
exports.retryable = function (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
func, fail, times) {
    if (times === void 0) { times = 3; }
    var count = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var retry = function () {
        var props = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            props[_i] = arguments[_i];
        }
        if (count >= times) {
            fail();
            return Promise.resolve();
        }
        count++;
        return func.apply(void 0, __spreadArrays(props, [retry]));
    };
    return retry;
};
