import { BetslipStatusFlags } from './betslipReducer/BetslipStatusFlags';
import { BetslipReducerStatus } from './types';

export const betslipStatusTranslation: Record<BetslipReducerStatus, string> = {
	[BetslipStatusFlags.InternalServerError]: 'betting.result.unknownError',
	[BetslipStatusFlags.DuplicateRequest]: 'betting.result.duplicateRequest',
	[BetslipStatusFlags.MalformedRequest]: 'betting.result.malformedRequest',
	[BetslipStatusFlags.PriceAboveMarket]: 'betting.result.priceAboveMarket',
	[BetslipStatusFlags.InsufficientFund]:
		ACCOUNT_FINANCE === 'cashier'
			? 'betting.result.insufficientFunds'
			: 'betting.result.insufficientFundsContactAgent',
	[BetslipStatusFlags.StakeAboveMax]: 'betting.result.stakeAboveMax',
	[BetslipStatusFlags.StakeBelowMin]: 'betting.result.stakeBelowMin',
	[BetslipStatusFlags.LiabilityLimitExceeded]:
		'betting.result.liabilityLimitExceeded', // we dont show a message currently so this does not yet need to be translated
	[BetslipStatusFlags.IncompleteRequest]: 'betting.result.incompleteRequest',
	[BetslipStatusFlags.MarketSuspended]: 'betting.result.marketSuspended',
	[BetslipStatusFlags.ParlayRestriction]: 'betting.result.parlayRestricted',
	[BetslipStatusFlags.Restricted]: 'betting.result.marketRestricted',
	[BetslipStatusFlags.ActiveBonusCorrelation]:
		'betting.result.activeBonusUseOnceOnEachEvent',
	[BetslipStatusFlags.ActiveBonusMinPrice]:
		'betting.result.activeBonusMinOdds',
	[BetslipStatusFlags.ActiveBonusPendingPayout]:
		'betting.result.activeBonusPendingPayout',
	[BetslipStatusFlags.ActiveBonusRestriced]:
		'betting.result.activeBonusUseOnUpcomingEvents',
	[BetslipStatusFlags.ReofferAvailable]:
		'betting.result.exhaustedZeroMargins',
	[BetslipStatusFlags.CorrelatedSelections]:
		'betting.result.correlatedSelections',
	[BetslipStatusFlags.VerificationRequired]:
		'betting.result.phoneVerificationRequiredForZeroMargin'
};
export const summaryTranslation: Partial<Record<
	BetslipReducerStatus,
	string
>> = {
	[BetslipStatusFlags.ParlayRestriction]:
		'betting.resultSummary.parlayRestricted',
	[BetslipStatusFlags.StakeAboveMax]: 'betting.resultSummary.stakeAboveMax',
	[BetslipStatusFlags.StakeBelowMin]: 'betting.resultSummary.stakeBelowMin',
	[BetslipStatusFlags.Restricted]: 'betting.resultSummary.marketRestricted',
	[BetslipStatusFlags.MarketSuspended]:
		'betting.resultSummary.marketSuspended',
	[BetslipStatusFlags.PriceAboveMarket]:
		'betting.resultSummary.priceAboveMarket'
};
export const getSummaryTranslation = (status: BetslipReducerStatus) =>
	summaryTranslation[status] ||
	betslipStatusTranslation[status] ||
	'betting.result.unknownError';
