"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BetCountRotatedIcon = exports.BetCountIcon = void 0;
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
var path = (React.createElement("path", { d: "M25.1,6.5c-0.7,0-1.4,0.5-1.6,1.2C23,9.1,21.6,10,20.1,10c-1.5,0-2.9-0.9-3.4-2.4c-0.3-0.7-0.9-1.2-1.6-1.2\nl-2.6,0c-1.7,0-3,1.3-3,3L9.5,33.9c0,1.7,1.3,3,3,3l2,0c0.8,0,1.5-0.5,1.6-1.3c0.4-1.6,1.8-2.7,3.5-2.7c1.6,0,3.1,1.1,3.5,2.7\nc0.2,0.8,0.9,1.3,1.6,1.3l2.9,0c1.7,0,3-1.3,3-3l0-24.4c0-1.7-1.3-3-3-3L25.1,6.5z" }));
exports.BetCountIcon = function (props) {
    return (React.createElement(core_1.SvgIcon, __assign({}, props),
        React.createElement("g", { transform: "scale(0.65) translate(-2,-3) " }, path)));
};
exports.BetCountRotatedIcon = function (props) {
    return (React.createElement(core_1.SvgIcon, __assign({}, props),
        React.createElement("g", { transform: "scale(1) translate(38, -8) rotate(90)" }, path)));
};
