import * as React from 'react';

import { BigBetslipParlayActionPanel } from './BigBetslipParlayActionPanel';
import { BigBetslipSinglesActionPanel } from './BigBetslipSinglesActionPanel';
import {
	BigBetslipActionPanelProps,
	BigBetslipActionPanelVariant
} from './types';

const panels: Record<
	BigBetslipActionPanelVariant,
	React.ElementType<BigBetslipActionPanelProps>
> = {
	singles: BigBetslipSinglesActionPanel,
	parlay: BigBetslipParlayActionPanel
};
export const BigBetslipActionPanel: React.FunctionComponent<
	BigBetslipActionPanelProps
> = (props) => {
	const { variant } = props;
	const Panel = panels[variant];
	return <Panel {...props} />;
};
