"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Filters = exports.getActiveFilters = exports.AlgoliaFiltersTypes = void 0;
var React = __importStar(require("react"));
var i18n_1 = require("@gaming-shell/i18n");
var shared_components_1 = require("shared-components");
var Filter_1 = require("../Filter/Filter");
var AlgoliaFiltersTypes;
(function (AlgoliaFiltersTypes) {
    AlgoliaFiltersTypes[AlgoliaFiltersTypes["Casino"] = 0] = "Casino";
    AlgoliaFiltersTypes[AlgoliaFiltersTypes["Sports"] = 1] = "Sports";
})(AlgoliaFiltersTypes = exports.AlgoliaFiltersTypes || (exports.AlgoliaFiltersTypes = {}));
exports.getActiveFilters = function (filters, type) { return filters === null || filters === void 0 ? void 0 : filters.filter(function (filter) { return filter.type === type; }); };
exports.Filters = function (_a) {
    var onFiltersChange = _a.onFiltersChange, className = _a.className;
    var _b = React.useState(), active = _b[0], setActive = _b[1];
    var _c = React.useState([]), casinoStoredFilters = _c[0], storeCasinoFilters = _c[1];
    var _d = React.useState([]), sportsStoredFilters = _d[0], storeSportsFilters = _d[1];
    var handleCreateFilters = React.useCallback(function (type) { return function (newFilter) {
        return setActive(function (oldFilters) {
            if (oldFilters === void 0) { oldFilters = []; }
            var newFilters = oldFilters;
            var existingFilterIndex = oldFilters.findIndex(function (filter) { return filter.name === newFilter.name; });
            if (existingFilterIndex > -1) {
                newFilters = oldFilters.filter(function (_, index) { return index !== existingFilterIndex; });
            }
            else {
                newFilters = __spreadArrays(oldFilters, [__assign(__assign({}, newFilter), { type: type })]);
            }
            onFiltersChange(newFilters);
            return newFilters;
        });
    }; }, [onFiltersChange]);
    var handleClearFilter = React.useCallback(function () {
        setActive(undefined);
        onFiltersChange(undefined);
        storeCasinoFilters([]);
        storeSportsFilters([]);
    }, [onFiltersChange]);
    return (React.createElement("div", { className: className },
        React.createElement(Filter_1.Filter, { active: !active || (active === null || active === void 0 ? void 0 : active.length) === 0, onClick: handleClearFilter },
            React.createElement(i18n_1.Translate, { label: "descriptions.all", ns: "common" })),
        React.createElement(shared_components_1.SharedComponent, { id: shared_components_1.SharedComponentIds.CasinoSearchFilters, activeFilters: active, component: Filter_1.Filter, onClick: handleCreateFilters(AlgoliaFiltersTypes.Casino), storedFilters: casinoStoredFilters, storeFilters: storeCasinoFilters }),
        React.createElement(shared_components_1.SharedComponent, { id: shared_components_1.SharedComponentIds.SportsSearchFilters, activeFilters: active, component: Filter_1.Filter, onClick: handleCreateFilters(AlgoliaFiltersTypes.Sports), storedFilters: sportsStoredFilters, storeFilters: storeSportsFilters })));
};
