import { Translate } from '@gaming-shell/i18n';
import { Box, Typography, makeStyles } from '@material-ui/core';
import clx from 'classnames';
import * as React from 'react';
import { resultPrecision } from 'sports/config/app';
import { formatPrecision } from 'sports/utils/number';
import { parseOdds } from 'sports/utils/odds';
import { toReturn } from 'sports/utils/stake';

import { CurrencyVariantConfig } from '../../config/currency';
import { formatCurrency } from '../../utils/currency';

interface BetslipSelectionResultProps {
	price: number;
	stake: number; // TODO this should be taken from selection once we update stake correctly
	label: string;
	currencyConfig: CurrencyVariantConfig;
	variant?: 'horizontal' | 'vertical';
}
const useStyles = makeStyles({
	result: {
		whiteSpace: 'nowrap',
		lineHeight: 1,
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between'
	}
});

export const BetslipSelectionResult: React.FunctionComponent<BetslipSelectionResultProps> = ({
	price,
	stake,
	label,
	currencyConfig,
	variant = 'horizontal'
}) => {
	const parsedPrice = parseOdds(price);
	const classes = useStyles();
	const toReturnBase = parseFloat(
		toReturn(stake, parsedPrice, currencyConfig.currency)
	);
	const toReturnVariant = parseFloat(
		formatCurrency(toReturnBase, currencyConfig.currency, {
			variant: currencyConfig.variant
		})
	);
	const currencyLabel = currencyConfig.label;
	// TODO: it should not be toReturn in new design
	return (
		<Box
			display="flex"
			flexDirection={variant === 'horizontal' ? 'row' : 'column'}
			justifyContent="flex-end"
			alignItems={variant === 'horizontal' ? 'center' : 'flex-start'}
			mb={1}
		>
			<Typography variant="caption" color="textSecondary">
				<Translate label={label} />
			</Typography>
			{variant === 'horizontal' ? <>&nbsp;</> : null}
			<Typography
				variant="body1"
				align="right"
				color="textPrimary"
				className={clx({ [classes.result]: variant !== 'horizontal' })}
			>
				<span>{currencyLabel} </span>
				<span>{formatPrecision(toReturnVariant, resultPrecision)}</span>
			</Typography>
		</Box>
	);
};
