"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthRoutes = exports.LegacyAuthRoutes = exports.authRoute = exports.hasAuthEnabled = void 0;
var routing_1 = require("@gaming-shell/routing");
exports.hasAuthEnabled = function () {
    return ENABLE_AUTH ||
        (ENABLE_AUTH_OVERRIDE &&
            localStorage.getItem('ENABLE_AUTH') === BRAND_NAME);
};
/** Creates an auth route */
exports.authRoute = function (path) { return "/auth/" + path; };
exports.LegacyAuthRoutes = {
    root: routing_1.route(['auth']),
    check2fa: routing_1.route(['auth', 'check_2fa']) /* two factor auth */,
    reset2fa: routing_1.route(['auth', 'reset_2fa']),
    forgotPassword: routing_1.route(['auth', 'forgot_password']),
    resetPassword: routing_1.route(['auth', 'reset_password']),
    signIn: routing_1.route(['auth', 'sign_in']),
    signUpConfirmation: routing_1.route(['auth', 'sign_up_confirmation']),
    signUp: routing_1.route(['auth', 'sign_up']),
    sso: routing_1.route(['auth', 'sso']),
    locationRestricted: routing_1.route(['auth', 'location_restricted']),
    logout: routing_1.route(['auth', 'logout']),
    authCode: routing_1.route(['auth-code'])
};
var NewAuthRoutes = {
    root: routing_1.route(['auth']),
    check2fa: routing_1.route(['auth', 'check_2fa']) /* two factor auth */,
    reset2fa: routing_1.route(['auth', 'reset_2fa']),
    forgotPassword: routing_1.route(['auth', 'forgot_password']),
    resetPassword: routing_1.route(['auth', 'reset_password']),
    signIn: routing_1.route(['sign-in']),
    signUpConfirmation: routing_1.route(['auth', 'sign_up_confirmation']),
    signUp: routing_1.route(['sign-up']),
    sso: routing_1.route(['auth', 'sso']),
    locationRestricted: routing_1.route(['location-restricted']),
    logout: routing_1.route(['sign-out']),
    authCode: routing_1.route(['auth-code'])
};
exports.AuthRoutes = exports.hasAuthEnabled() ? NewAuthRoutes : exports.LegacyAuthRoutes;
