import merge from 'deepmerge';
import { createAction, getType } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';

import { EntitiesType, UpdateEntitiesAction } from './actions';

const arrayMerge = (
	destination: Array<string | number>,
	origin: Array<string | number>
) => {
	const hash = {};
	const originHash = {}; // if a value is twice in origin we want it merged twice into the target
	const arr = [];
	if (origin[0] && typeof origin[0] === 'object') {
		return origin; // we overwrite if array consists of object
	}
	for (const e of destination) {
		if (hash[e] !== true) {
			hash[e] = true;
			arr[arr.length] = e;
		}
	}
	for (const e of origin) {
		if (hash[e] !== true || originHash[e] === true) {
			hash[e] = true;
			originHash[e] = true;
			arr[arr.length] = e;
		}
	}
	return arr;
};
const overwrite = <T extends {}>(destination: T, origin: T) => origin;
const defaultMerge = <T extends {}>(destination: T, origin: T): T =>
	merge(destination, origin, { arrayMerge, customMerge });
const customMerge = (key: string) => {
	if (key === 'metadata') {
		return overwrite;
	}
	return defaultMerge;
};
export const createEntityReducer = <TEntitiyKeys extends string>(
	updateEntitiesAction: UpdateEntitiesAction<
		EntitiesType<TEntitiyKeys>,
		unknown
	>,
	clearEntitiesAction: ReturnType<typeof createAction>
) => <TEntity>(entityKey: string) => (
	state: DeepReadonly<
		Record<string, TEntity>
	> = ({} as unknown) as DeepReadonly<Record<string, TEntity>>,
	action: { type: string; payload?: object }
) => {
	if (action.type === getType(clearEntitiesAction)) {
		return {} as typeof state;
	}

	if (
		!action.payload ||
		action.type !== getType(updateEntitiesAction) ||
		!action.payload[entityKey]
	) {
		return state;
	}
	return defaultMerge(
		state,
		action.payload[entityKey] as DeepReadonly<{
			[key: string]: TEntity;
		}>
	);
};
