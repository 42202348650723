import { PusherAnnouncement, Announcement } from 'materialbet-common';
import { ApiAnnouncement } from '@materialbet-core/api';

export const parsePusherAnnouncement = (
	announcement: PusherAnnouncement
): Announcement => {
	const {
		importance,
		validFrom,
		validTo,
		sitePath,
		country,
		language
	} = announcement;
	return {
		id: +announcement.id,
		title: announcement.title[0].value,
		description: announcement.description[0].value,
		importance,
		validFrom,
		validTo,
		sitePath,
		country,
		language
	};
};

export const parseApiAnnouncement = (
	announcement: ApiAnnouncement
): Announcement => {
	// eslint-disable-next-line @typescript-eslint/camelcase
	const {
		valid_from: validFrom,
		valid_to: validTo,
		site_path: sitePath,
		...rest
	} = announcement;
	return {
		...rest,
		validFrom,
		validTo,
		sitePath
	};
};
