"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
var FifaIcon = function (props) {
    return (React.createElement(core_1.SvgIcon, __assign({}, props),
        React.createElement("g", { stroke: "none", strokeWidth: "1", fill: props.fill, fillRule: "evenodd" },
            React.createElement("path", { d: "M5.19 0c2.866 0 5.19 2.324 5.19 5.197-.01.918-.264 1.815-.748 2.616l-.13.215c-.06.134-.135.262-.222.381-1.79 3.257-1.725 7.242.212 10.454.302.5-.058 1.137-.642 1.137l-7.192.001c-.184.042-.383.016-.557-.09-.216-.13-.342-.353-.36-.587-.014-.152.017-.313.107-.461C2.904 15.453 2.85 11.17.69 7.786.234 6.996-.005 6.099 0 5.19 0 2.324 2.324 0 5.19 0zM3.78 5.184c-.055-.038-.13-.029-.174.022-.043.05-.041.126.007.177 2.185 2.297 2.493 5.798.746 8.435-.338.521-.566 1.105-.67 1.725l-.088.435c-.19.87-.476 1.717-.851 2.523l4.853-.001c-1.365-2.935-1.477-6.286-.348-9.276-.779-.133-1.443-.708-1.647-1.51-.29-1.029-.935-1.922-1.829-2.53zM5.19 1.5C3.152 1.5 1.5 3.152 1.5 5.194c-.004.646.165 1.281.472 1.813.928 1.454 1.513 3.06 1.754 4.705.592-1.815.168-3.857-1.197-5.293l-.102-.117c-.48-.605-.472-1.475.04-2.072.546-.637 1.487-.756 2.165-.28.663.451 1.226 1.026 1.66 1.691l.132-.104c.228-.164.494-.284.786-.345.596-.123 1.189.025 1.646.366.015-.122.023-.245.024-.368 0-2.038-1.652-3.69-3.69-3.69zm2.42 5.15l-.096.011c-.289.06-.482.334-.441.626.005.037.007.073.007.11l.041.083c.127.21.38.321.63.258.111-.028.207-.088.28-.167.04-.076.084-.151.128-.226l-.128.226c.126-.135.183-.327.136-.51-.079-.285-.364-.46-.653-.4z", id: "Path", fill: props.fill, fillRule: "nonzero", transform: "translate(7 2)" }))));
};
exports.default = FifaIcon;
