import { isMobile } from '@gaming-shell/device';
import { makeStyles, Theme } from '@material-ui/core';
import { IconButton, useOpenCloseMenu } from 'materialbet-common';
import { MyAccountIcon } from 'materialbet-icons';
import { NotificationsBadge } from 'materialbet-inbox';
import * as React from 'react';

import { AccountMenu } from './AccountMenu';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		[theme.breakpoints.down(321)]: {
			paddingRight: QUICK_CURRENCY_SWITCH_ENABLED ? 0 : undefined
		}
	}
}));

const allowNotificationsBadge = INBOX_ENABLED && isMobile();
const MyAccountEnhancedIcon: React.FC = () => {
	if (allowNotificationsBadge) {
		return (
			<NotificationsBadge>
				<MyAccountIcon />
			</NotificationsBadge>
		);
	}

	return <MyAccountIcon />;
};

let AccountButton: React.FC = () => {
	const [handleOpenMenu, handleCloseMenu, anchorEl] = useOpenCloseMenu();
	const classes = useStyles();
	return (
		<>
			<IconButton
				size={0.8}
				color="inherit"
				onClick={handleOpenMenu}
				aria-label="My account"
				className={classes.root}
			>
				<MyAccountEnhancedIcon />
			</IconButton>
			<AccountMenu anchorEl={anchorEl} onClose={handleCloseMenu} />
		</>
	);
};

AccountButton = React.memo(AccountButton);
export { AccountButton };
