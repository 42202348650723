import { getPlatform } from '@gaming-shell/device';
import { useLanguage } from '@gaming-shell/i18n';
import {
	usePlayerCurrency,
	usePlayerLoading,
	usePlayerLoggedIn,
	usePlayerUuid
} from '@materialbet-core/player';
import { actions, loads, SectionsParams } from 'casino/modules/sectionsModule';
import { getLoadedOnce } from 'casino/selectors/loadingSelectors';
import { getSections } from 'casino/selectors/metaSelectors/metaSelectors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export type SectionHookParams = Omit<
	SectionsParams,
	'playerUuid' | 'signedIn' | 'platform' | 'currency' | 'locale'
>;
/** Most of the section params depend on player and other context stored data - so it makes sense to just use a hook here */
export const useSectionsParams = (params: SectionHookParams) => {
	const playerUuid = usePlayerUuid();
	const platform = getPlatform();
	const currency = usePlayerCurrency();
	const signedIn = usePlayerLoggedIn();
	const locale = useLanguage(); // probably need to format to casino language format here
	const allParams: SectionsParams = {
		...params,
		playerUuid,
		platform,
		currency,
		locale,
		signedIn
	};
	return allParams;
};
export const useSectionsLoaded = (params: SectionHookParams) =>
	useSelector(getLoadedOnce(loads.sections(useSectionsParams(params))));
export const useSectionIds = (params: SectionHookParams) =>
	useSelector(getSections(useSectionsParams(params))) || [];

export const useDispatchSections = ({
	live,
	condition = true
}: {
	live: boolean;
	condition: boolean;
}) => {
	const dispatch = useDispatch();
	const playerIsLoading = usePlayerLoading();
	const {
		playerUuid,
		signedIn,
		platform,
		currency,
		locale
	} = useSectionsParams({ live });
	React.useEffect(() => {
		if (playerIsLoading || !condition) {
			return;
		}
		dispatch(
			actions.fetchSections({
				playerUuid,
				signedIn,
				live,
				platform,
				currency,
				locale
			})
		);
	}, [
		playerIsLoading,
		playerUuid,
		signedIn,
		live,
		platform,
		currency,
		locale,
		condition,
		dispatch
	]);
};
