import * as React from 'react';
import { getAuthReturnKey } from 'auth/utils/url';
import { AuthSideEffects } from './AuthSideEffects';
import { hasAuthEnabled } from 'materialbet-common';

export const AuthSideEffectsSwitch: React.FunctionComponent<unknown> = () => {
	const authReturnKey = getAuthReturnKey();
	return hasAuthEnabled() && !!authReturnKey ? (
		<AuthSideEffects authReturnKey={authReturnKey} />
	) : null;
};
