import { useLanguage } from '@gaming-shell/i18n';
import {
	getCloudinaryImageUrl,
	getStaticMedia,
	SimplePreviewCard,
	SimplePreviewCardProps,
	useMediaWidth,
	withHook
} from 'materialbet-common';
import { getSportsName } from 'sports-sdk/sports-core';
import { defaultSportInfo, sportInfo, SportType } from 'sports/config/sport';
import { getSdkLocale } from 'sports/utils/locale';
import { sportRoute } from 'sports/utils/route';
import { sportImage } from 'sports/utils/sportMedia';

import { sportPreviewCardDimensions } from './SportPreviewCard.config';
import { useSportPreviewCardStyles } from './SportPreviewCard.styles';

const useSportPreviewCardProps = ({
	sportKey
}: {
	sportKey: SportType;
}): SimplePreviewCardProps => {
	const language = useLanguage();
	const locale = getSdkLocale(language);
	const singleSportInfo = sportInfo[sportKey] || defaultSportInfo;
	const tab = singleSportInfo.defaultTab;
	const mediaWidth = useMediaWidth(); // xs, sm , md, lg, xl
	const width = Math.ceil(sportPreviewCardDimensions[mediaWidth].width);
	const height = Math.ceil(sportPreviewCardDimensions[mediaWidth].height);
	const image = getStaticMedia(sportImage[sportKey]);
	const imageUrl = getCloudinaryImageUrl({
		imageUrl: image,
		width,
		height: height * 0.75
	});
	const link = sportRoute(sportKey, tab);
	const title = getSportsName(sportKey, locale);
	const classes = useSportPreviewCardStyles();
	return {
		title,
		imageUrl,
		link,
		classes
	};
};
export const SportPreviewCard = withHook(useSportPreviewCardProps)(
	SimplePreviewCard
);
