"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var core_1 = require("@material-ui/core");
var __1 = require("..");
var i18n_1 = require("@gaming-shell/i18n");
var useStyles = core_1.makeStyles(function (theme) { return ({
    wrapper: {
        padding: "0 " + theme.spacing(2) + "px " + theme.spacing(4) + "px"
    },
    expandIcon: {
        top: 0
    },
    root: {
        width: '100%'
    },
    select: __assign(__assign({}, theme.typography.subtitle1), { width: '100%', '&::before': {
            borderBottomColor: theme.palette.text.hint
        }, '&::after': {
            borderBottom: 'none'
        } }),
    input: {
        '& > div': {
            alignItems: 'center'
        },
        height: theme.typography.subtitle1.lineHeight + "em",
        paddingTop: 0
    },
    flag: {
        marginRight: theme.spacing(3)
    }
}); });
var FooterLanguageSwitch = function (_a) {
    var native = _a.native;
    var classes = useStyles();
    return (React.createElement("div", { className: classes.wrapper },
        React.createElement(core_1.Typography, { variant: "overline", color: "textSecondary" },
            React.createElement(i18n_1.Translate, { label: "descriptions.language", ns: "common" })),
        React.createElement(__1.LanguageSwitch, { classes: {
                root: classes.root,
                select: classes.select,
                input: classes.input,
                flag: classes.flag,
                expandIcon: classes.expandIcon
            }, flagSize: 32, native: !!native })));
};
exports.default = React.memo(FooterLanguageSwitch);
