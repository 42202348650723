import * as React from 'react';
import { CurrencyVariantConfig } from 'sports/config/currency';
import { getVariantCurrencyValue } from 'sports/utils/currency';

import { formatCurrency } from '../../../utils/currency';
import {
	getStraightsTotalReturn,
	getStraightsTotalStake
} from '../betslipSelectors';
import { useBetslipSelector } from '../hooks';
import { BigBetslipPanelContent } from './BigBetslipPanelContent';
import { BigBetslipSinglesSummary } from './BigBetslipSinglesSummary';

export interface UiBigBetslipSinglesPanelContentProps {
	currencyConfig: CurrencyVariantConfig;
	totalStake: number;
	totalReturn: number;
	button: JSX.Element;
}
let UiBigBetslipSinglesPanelContent: React.FunctionComponent<
	UiBigBetslipSinglesPanelContentProps
> = (props) => {
	const { currencyConfig, totalStake, totalReturn, button } = props;
	const totalStakeVariant = getVariantCurrencyValue(
		currencyConfig,
		totalStake
	);
	const totalReturnVariant = formatCurrency(
		totalReturn,
		currencyConfig.currency,
		{ currencyPosition: 'none', variant: currencyConfig.label }
	);
	return (
		<BigBetslipPanelContent>
			<BigBetslipSinglesSummary
				totalStake={totalStakeVariant.toString()}
				totalReturn={totalReturnVariant.toString()}
				currencyLabel={currencyConfig.label}
			/>
			{button}
		</BigBetslipPanelContent>
	);
};
UiBigBetslipSinglesPanelContent = React.memo(UiBigBetslipSinglesPanelContent);
export type BigBetslipSinglesPanelContentProps = Omit<
	UiBigBetslipSinglesPanelContentProps,
	'totalStake' | 'totalReturn'
>;

let BigBetslipSinglesPanelContent: React.FunctionComponent<
	BigBetslipSinglesPanelContentProps
> = (props) => {
	const totalStake = useBetslipSelector(getStraightsTotalStake);
	const totalReturn = useBetslipSelector(getStraightsTotalReturn);
	return (
		<UiBigBetslipSinglesPanelContent
			totalStake={totalStake}
			totalReturn={totalReturn}
			{...props}
		/>
	);
};
BigBetslipSinglesPanelContent = React.memo(BigBetslipSinglesPanelContent);
export { UiBigBetslipSinglesPanelContent, BigBetslipSinglesPanelContent };
