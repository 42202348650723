import { FreespinsWidget } from 'casino/components/FreespinsWidget';
import { RolloverBonusesWidget } from 'casino/components/RolloverBonusesWidget';
import { casinoStore } from 'casino/store';
import * as React from 'react';
import { Provider } from 'react-redux';

export const CasinoAccountPromotions: React.FC = () => (
	<Provider store={casinoStore}>
		<RolloverBonusesWidget />
		<FreespinsWidget />
	</Provider>
);
