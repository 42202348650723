import { Card, CardProps } from '@material-ui/core';
import * as React from 'react';

export interface ExpandableCardProps extends CardProps {
	collapsed?: boolean;
}

export const ExpandableCardContext = React.createContext<[boolean, () => void]>(
	[false, () => {}]
);

export const ExpandableCard: React.FunctionComponent<ExpandableCardProps> = ({
	children,
	collapsed: parentCollapsed,
	...rest
}) => {
	// it's collapsed by default when parent don't set it
	const [collapsed, setCollapsed] = React.useState(!!parentCollapsed);

	// Allow control of collapsed state by parent
	React.useEffect(() => setCollapsed(!!parentCollapsed), [setCollapsed]);
	const toggleCollapsed = React.useCallback(
		() => setCollapsed((prev) => !prev),
		[setCollapsed]
	);

	return (
		<ExpandableCardContext.Provider value={[collapsed, toggleCollapsed]}>
			<Card {...rest}>{children}</Card>
		</ExpandableCardContext.Provider>
	);
};
