import { useTranslation } from '@gaming-shell/i18n';
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ButtonLink } from 'materialbet-common/src';
import * as React from 'react';

import { useSimplePreviewCardStyles } from './SimplePreviewCard.styles';

export interface SimplePreviewCardClasses {
	card?: string;
	cardContent?: string;
	media?: string;
}

export interface SimplePreviewCardProps {
	title: string;
	imageUrl?: string;
	link?: string;
	/** @description classes for the external styling, card, media, card */
	classes?: SimplePreviewCardClasses;
}

/** @description simple based component for preview card where its shows image and title */
let SimplePreviewCard: React.FC<SimplePreviewCardProps> = (props) => {
	const { title, imageUrl, link, classes: externalClasses } = props;
	const classes = useSimplePreviewCardStyles();
	const trans = useTranslation();
	return (
		<ButtonLink
			href={link || '#'}
			className={classes.link}
			aria-label={`${trans('actions.seeAll')} ${title}`}
		>
			<Card
				className={clsx(
					classes.simplePreviewCard,
					externalClasses?.card
				)}
			>
				<CardMedia
					component="img"
					src={imageUrl}
					className={clsx(classes.media, externalClasses?.media)}
				/>
				<CardContent
					className={clsx(
						classes.cardContent,
						externalClasses?.cardContent
					)}
				>
					<div className={classes.row}>
						<Typography
							className={clsx(
								classes.title,
								classes.ellipsisTextOverflow
							)}
							variant="subtitle1"
						>
							{title}
						</Typography>
					</div>
				</CardContent>
			</Card>
		</ButtonLink>
	);
};

SimplePreviewCard = React.memo(SimplePreviewCard);
export { SimplePreviewCard };
