import * as React from 'react';
import { useTranslation } from '@gaming-shell/i18n';
import { InfoPageLayout } from '@materialbet-core/auth';

export const CanceledPageContainer: React.FC = ({ children }) => {
	const trans = useTranslation();

	return (
		<InfoPageLayout
			hideBrandLogo
			imgSrc="/img/transaction_result/canceled.png"
			imgAlt={trans('player:cashier.fiatTransactionCanceled')}
		>
			{children}
		</InfoPageLayout>
	);
};
