import { SportBaseInfo, SportInfo, SportMarketInfo } from './types';

export const defaultBaseInfo: SportBaseInfo = {
	tabs: [
		'inPlay',
		'today',
		'tomorrow',
		'competitions',
		'highlights',
		'outrights'
	],
	defaultTab: 'competitions'
};
export const defaultMarketInfo: SportMarketInfo = {
	// Fallback for default config
	eventMarketConfig: [],
	competitionMarketConfig: [
		{
			markets: [],
			label: ''
		}
	]
};
export const defaultSportInfo: SportInfo = {
	...defaultBaseInfo,
	...defaultMarketInfo
};
