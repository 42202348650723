export interface Player {
	id: string;
	isLoading: boolean;
	username: string;
	selfExcludedTill: string;
	dateOfBirth: string;
	timeZone: string;
	locale: string;
	applyCountryRestriction: boolean;
	vipLevel: string;
	email: string;
	regIpAddress: string;
	authToken: string;
}

//Default values will be filled accordingly with intigration of usage of each value
export const defaultPlayer = {
	id: '',
	isLoading: true,
	username: '',
	selfExcludedTill: '0',
	dateOfBirth: '',
	timeZone: '',
	locale: DEFAULT_LANGUAGE,
	applyCountryRestriction: false,
	vipLevel: 'NONE',
	email: '',
	regIpAddress: '',
	authToken: ''
};
