import { RootState } from 'sports/modules/root';

interface NameProp {
	name: string;
}
const loadingSelector = (state: RootState) => state.loading;
const namePropSelector = ({ name }: NameProp) => name;

export const isLoading = (state: RootState, props: NameProp) => {
	const loading = loadingSelector(state)[namePropSelector(props)];
	return loading && loading.isLoading;
};
export const loadedOnce = (state: RootState, props: NameProp) => {
	const loading = loadingSelector(state)[namePropSelector(props)];
	return loading && loading.receivedAt;
};
