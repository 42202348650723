import * as React from 'react';

export const useOpenCloseMenu = () => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const handleOpen = React.useCallback(
		(event: React.MouseEvent<any>) => {
			setAnchorEl(event.currentTarget);
		},
		[setAnchorEl]
	);
	const handleClose = React.useCallback(() => {
		setAnchorEl(null);
	}, [setAnchorEl]);

	/* Please note anchorElement is often used as isOpen */
	return [handleOpen, handleClose, anchorEl] as const;
};
