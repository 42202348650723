import { RootState } from 'sports/modules/root';
import {
	EventTreeMeta,
	SportsMetaKeys
} from 'sports/modules/sports/sportsMeta';

const sportsSelector = (state: RootState) => state.sportsMeta;
interface SportsKeyProp {
	key: SportsMetaKeys;
}

export const getSportsMeta = (state: RootState, { key }: SportsKeyProp) =>
	sportsSelector(state)[key];
export const getEventTreesMeta = (state: RootState) =>
	(getSportsMeta(state, { key: 'eventTrees' }) || []) as Record<
		string,
		EventTreeMeta[]
	>; // TODO better typesafety
