import { isMobile } from '@gaming-shell/device';
import { Translate } from '@gaming-shell/i18n';
import { Button, Theme, makeStyles } from '@material-ui/core';
import { SnackbarOrigin } from '@material-ui/core/Snackbar';
import {
	OptionsObject,
	SnackbarProvider as Snackbar,
	useSnackbar
} from 'notistack';
import * as React from 'react';

export interface SnackbarProviderProps {
	children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
	close: {
		padding: theme.spacing(0.5)
	}
}));

const CloseButton: React.FunctionComponent<{ id: OptionsObject['key'] }> = ({
	id
}) => {
	const styles = useStyles();
	const { closeSnackbar } = useSnackbar();

	return (
		<Button
			aria-label="Close"
			color="primary"
			className={styles.close}
			onClick={() => closeSnackbar(id)}
		>
			<Translate label="actions.close" ns="common" />
		</Button>
	);
};

const maxNumberOfSnackbars = isMobile()
	? MAX_NUMBER_OF_SNACKBARS_MOBILE
	: MAX_NUMBER_OF_SNACKBARS_DESKTOP;
const ANCHOR_ORIGIN: SnackbarOrigin = {
	vertical: 'bottom',
	horizontal: 'left'
};

export const SnackbarProvider: React.FunctionComponent<SnackbarProviderProps> = React.memo(
	({ children }) => {
		return (
			<Snackbar
				maxSnack={maxNumberOfSnackbars}
				anchorOrigin={ANCHOR_ORIGIN}
				autoHideDuration={6000}
				dense={isMobile()}
				preventDuplicate
				action={(key) => <CloseButton id={key} />}
			>
				{children}
			</Snackbar>
		);
	}
);
