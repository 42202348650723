import { createSvgIcon } from 'sports/components/util/Icon';

export default ENABLE_NEW_ICON
	? createSvgIcon(
			'M12 8.02a2.6 2.6 0 0 1 .751 5.09l-.001 7.36a.75.75 0 0 1-1.493.102l-.007-.102v-7.36A2.601 2.601 0 0 1 12 8.02zM6.554 3.065a.75.75 0 0 1-.049 1.06 9 9 0 0 0-.936.993 8.49 8.49 0 0 0 .92 11.973.75.75 0 1 1-.977 1.138A9.99 9.99 0 0 1 4.417 4.158c.33-.407.69-.79 1.078-1.142a.75.75 0 0 1 1.06.049zm11.94-.059c.412.361.794.754 1.139 1.17 3.554 4.2 3.041 10.485-1.146 14.055a.75.75 0 0 1-.974-1.142c3.56-3.034 3.995-8.374.97-11.95a8.89 8.89 0 0 0-.978-1.005.75.75 0 0 1 .99-1.128zM16.078 5.8c.255.217.494.453.724.714a6.31 6.31 0 0 1-.726 8.908.75.75 0 1 1-.97-1.144 4.81 4.81 0 0 0 .562-6.782 5.59 5.59 0 0 0-.564-.556.75.75 0 1 1 .974-1.14zm-8.154 0a.75.75 0 1 1 .974 1.14c-.201.172-.39.358-.556.546a4.81 4.81 0 0 0 .554 6.792.75.75 0 0 1-.97 1.144 6.31 6.31 0 0 1-.718-8.918 7.09 7.09 0 0 1 .716-.704zM12 9.52a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2z',
			'in-play'
	  )
	: createSvgIcon(
			'M8.5,9A6.5,6.5 0 0,0 2,15.5A6.5,6.5 0 0,0 8.5,22A6.5,6.5 0 0,0 15,15.5V13.91L22,12V9H11V11H9V9H8.5M11,2V7H9V2H11M6.35,7.28C5.68,7.44 5.04,7.68 4.43,8L2.14,4.88L3.76,3.7L6.35,7.28M17.86,4.88L16.32,7H13.85L16.24,3.7L17.86,4.88Z',
			'in-play'
	  );
