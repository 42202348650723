"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SsoSignUp = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var core_1 = require("@material-ui/core");
var __1 = require("../..");
var SignUpForm_1 = require("./SignUpForm");
var React = __importStar(require("react"));
var useStyles = core_1.makeStyles(function (theme) { return ({
    orDivider: {
        marginBottom: theme.spacing(1)
    }
}); });
exports.SsoSignUp = function (props) {
    var styles = useStyles();
    return (React.createElement(__1.SsoAuthWrapp, { flexDirection: "column" },
        React.createElement(__1.SsoProviders, null),
        React.createElement(__1.DividerWithText, { className: styles.orDivider },
            React.createElement(i18n_1.Translate, { label: "descriptions.or", ns: "common" })),
        React.createElement(SignUpForm_1.SignUpForm, { nicknameLabel: props.nicknameLabel, emailLabel: props.emailLabel, birthDateLabel: props.birthDateLabel, onSubmitClick: props.onSubmitClick, passwordLabel: props.passwordLabel, submitButtonText: props.submitButtonText, validations: props.validations, isSubmitting: props.isSubmitting, sso: props.sso, initialValues: props.initialValues },
            React.createElement(__1.FormExtraAction, { buttonText: props.extraButtonText, buttonRoute: props.extraButtonRoute, justifyContent: "center" }, props.extraText))));
};
