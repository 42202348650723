import * as React from 'react';

const ForcedRedirectionSwitch: React.FunctionComponent = (props) => {
	const matched = React.Children.toArray(props.children).find(
		(child) =>
			React.isValidElement(child) &&
			(child.props as { redirectCondition: boolean }).redirectCondition
	);

	return matched ? (matched as React.ReactElement) : null;
};

export default ForcedRedirectionSwitch;
