import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import * as React from 'react';
import { Row } from '../..';
import { Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export interface CheckboxFieldProps
	extends Omit<CheckboxProps, 'onChange' | 'value'> {
	onChange(value: boolean): void;
}

const useStyles = makeStyles((theme: Theme) => ({
	rightMargin: {
		marginRight: theme.spacing(1)
	}
}));

export const CheckboxField: React.FunctionComponent<CheckboxFieldProps> = ({
	onChange,
	children,
	className,
	size = 'small',
	color = 'primary',
	checked: checkedProp = false,
	...checkboxProps
}) => {
	const classes = useStyles();
	const [checked, setChecked] = React.useState<boolean>(checkedProp);

	React.useEffect(() => {
		setChecked(checkedProp);
	}, [setChecked, checkedProp]);

	const toggleCheckbox = React.useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			setChecked((checked) => {
				onChange(!checked);
				return !checked;
			});
		},
		[onChange, setChecked]
	);

	return (
		<Row
			aria-label="labelled checkbox container"
			className={className}
			alignItems="center"
			onClick={toggleCheckbox}
		>
			<Checkbox
				size={size}
				color={color}
				value={checked}
				checked={checked}
				onClick={toggleCheckbox}
				className={clsx(children && classes.rightMargin)}
				{...checkboxProps}
			/>
			{children}
		</Row>
	);
};
