"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateTFunction = void 0;
var decorateNamespace = function (key, ns, nsSeparator) {
    var nsPrefix = ns;
    var decorateKey = key;
    if (key.indexOf(nsSeparator) > -1) {
        var splitKeyName = key.split(nsSeparator);
        nsPrefix = splitKeyName[0];
        decorateKey = splitKeyName[1];
    }
    return {
        decorateKey: decorateKey,
        nsPrefix: nsPrefix
    };
};
var decorateKeyName = function (key, config, ns, nsSeparator) {
    var prefix = config.prefix ? config.prefix : '[[__';
    var suffix = config.suffix ? config.suffix : '__]]';
    var _a = decorateNamespace(key, ns, nsSeparator), decorateKey = _a.decorateKey, nsPrefix = _a.nsPrefix;
    var result = prefix + "phrase_" + nsPrefix + "." + decorateKey + suffix;
    return result;
};
var getDefaultConfig = function (i18n) {
    var defaultNS = (i18n && i18n.options && i18n.options.defaultNS) || 'common';
    var nsSeparator = (i18n && i18n.options && i18n.options.nsSeparator) || ':';
    return {
        defaultNS: defaultNS,
        nsSeparator: nsSeparator
    };
};
exports.generateTFunction = function (t, config) {
    var tFunction1 = function (key, options) {
        if (typeof options === 'string') {
            return 'Currently not supporting string options to the translate function for phraseapp marshalling';
        }
        var ns = options && options.ns;
        var _a = getDefaultConfig(options && options.i18n), defaultNS = _a.defaultNS, nsSeparator = _a.nsSeparator;
        var nsPrefix = (typeof ns === 'string' && ns) || defaultNS;
        var marshalled;
        if (Array.isArray(key)) {
            marshalled = key.map(function (k) {
                return decorateKeyName(k, config, nsPrefix, nsSeparator);
            });
        }
        else {
            marshalled = decorateKeyName(key, config, nsPrefix, nsSeparator);
        }
        return t(marshalled, options);
    };
    return tFunction1;
};
