import { isMobile } from '@gaming-shell/device';
import { Theme, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { createDynamicStyle, makeDynamicStyles } from 'materialbet-common';
import * as React from 'react';
import { EventListFormatProps } from 'sports/components/eventList/types';
import { CustomPaper } from 'sports/components/materialWrapper/CustomPaper';
import { PaperVariant } from 'sports/components/materialWrapper/types';
import { useTranslation } from '@gaming-shell/i18n';
import { EventListSkeleton } from '../EventList';

const useStyles = makeStyles((theme: Theme) => ({
	sportIcon: {
		marginRight: theme.spacing(1)
	},
	title: {
		display: 'flex',
		alignItems: 'center'
	},
	titleContainer: {
		padding: theme.spacing(2, 1)
	}
}));

const useVariantStyles = makeDynamicStyles({
	card: createDynamicStyle(
		(theme) => ({
			background: isMobile()
				? 'transparent'
				: theme.palette.background.paper
		}),
		{
			// is flat
			true: (theme) => ({
				marginBottom: theme.spacing(1)
			}),
			false: {
				marginBottom: 0
			}
		},
		({ paperVariant }: { paperVariant?: PaperVariant }) =>
			`${paperVariant !== 'flat' ? true : false}`
	)
});

export const EventListCompetitionSkeleton: React.FC<EventListFormatProps> = (
	props
) => {
	const {
		disableCompetitionHeader,
		disableCompetitionIcon,
		paperVariant,
		variant = 'narrow'
	} = props;
	const customPaperVariant =
		paperVariant || (variant === 'narrow' ? 'flat' : undefined);
	const squarePaper = customPaperVariant === 'flat';
	const { sportIcon, title, titleContainer } = useStyles();
	const { card } = useVariantStyles({ paperVariant: customPaperVariant });
	const t = useTranslation();
	const competitionHeader = (
		<div className={titleContainer}>
			<div className={title}>
				{!disableCompetitionIcon && (
					<Skeleton
						aria-label={t('common:descriptions.loading')}
						className={sportIcon}
						height={24}
						width={24}
						variant="circle"
					/>
				)}
				<Skeleton width="30%" />
			</div>
		</div>
	);
	return (
		<CustomPaper
			variant={customPaperVariant}
			className={card}
			square={squarePaper}
		>
			{!disableCompetitionHeader && competitionHeader}
			<EventListSkeleton variant={variant} />
		</CustomPaper>
	);
};
