import React from 'react';

/**
 * Hook for making use of ref instance instead of useMemo as this might be flushed without any notice by react's internals.
 * @param compute function that returns computed value
 * @param dependencies array of dependencies for compute function
 *
 * Copied from sports package, original author @HoYinChung
 */
export const useComputedReference = <T>(
	compute: () => T,
	dependencies: unknown[]
) => {
	const valueReference = React.useRef<T>();
	const depReference = React.useRef<unknown[]>();
	const updated =
		depReference.current === undefined ||
		dependencies.some((d, i) => {
			return depReference.current && depReference.current[i] !== d;
		});
	if (updated) {
		depReference.current = dependencies;
		valueReference.current = compute();
	}
	return valueReference.current as T;
};
