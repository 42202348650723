"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.route = void 0;
var react_router_1 = require("react-router");
var _1 = require(".");
var create_1 = require("./utils/create");
var _a = window.location, protocol = _a.protocol, host = _a.host;
exports.route = function (routePathElements) {
    var routePathStrings = _1.createRoutePathStrings(routePathElements);
    var routePathPart = _1.filterParams(routePathStrings, _1.ParamIndicators.QUERY);
    var routeQueryPart = _1.createQueryParams(routePathStrings);
    var routePathWithoutLocale = "" + routePathPart.join('/') + routeQueryPart.join('&');
    var routePath = _1.withLocaleParam("" + routePathPart.join('/') + routeQueryPart.join('&'));
    var template = "/" + routePath;
    var templateWithoutLocale = "/" + routePathWithoutLocale;
    var relativePath = routePath;
    var routePathWithoutParams = _1.removeParams(routePath);
    var fullPath = protocol + "//" + host + template;
    var createRoutePath = function (prefix) {
        if (prefix === void 0) { prefix = ''; }
        return create_1.create(prefix, routePath);
    };
    var matchRoutePath = function (path, _a) {
        if (path === void 0) { path = window.location.pathname; }
        var exact = (_a === void 0 ? { exact: false } : _a).exact;
        return !!react_router_1.matchPath(path, {
            path: template,
            exact: exact
        });
    };
    var setCurrentLocaleParam = function (path) { return function () { return _1.setLocaleParam(path); }; };
    return {
        template: template,
        templateWithoutLocale: templateWithoutLocale,
        templateWithoutParams: "/" + _1.withLocaleParam(_1.removeParams(routePathWithoutLocale)),
        fullPath: setCurrentLocaleParam(fullPath),
        relativePath: setCurrentLocaleParam(relativePath),
        pathWithoutParams: setCurrentLocaleParam("/" + routePathWithoutParams),
        relativePathWithoutParams: setCurrentLocaleParam(routePathWithoutParams),
        create: createRoutePath('/'),
        createRelative: createRoutePath(),
        matches: matchRoutePath,
        matchesExactly: function (path) {
            return matchRoutePath(path, { exact: true });
        },
        add: function (addedRoutePathElements) {
            return exports.route(__spreadArrays(routePathElements, addedRoutePathElements));
        },
        getPathParams: function (path) {
            var _a;
            return (((_a = react_router_1.matchPath(path, { path: template })) === null || _a === void 0 ? void 0 : _a.params) ||
                {});
        },
        useParams: function () {
            return react_router_1.useParams();
        }
    };
};
