import { usePlayerUuid } from '@materialbet-core/player';
import { RolloverBonusesParams } from 'casino/modules/rolloverBonusesModule';

export const useRolloverBonusesParams = ({
	currency
}: {
	currency?: string;
}): RolloverBonusesParams => {
	return { playerUuid: usePlayerUuid(), currency };
};
