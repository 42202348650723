import { combineEpics } from 'redux-observable';
import {
	BetsHistoryRequestParams,
	BetsHistoryResponse,
	BetsHistoryState
} from 'sports/api/sportsbetting/api';
import * as schemas from 'sports/schema';
import { betsHistoryResponseId } from 'sports/utils/betsHistory';
import { createFetchEntityEpic } from 'sports/utils/epic';
import { betsHistoryCountMeta, finishLoading } from 'sports/utils/meta';
import { createEntityReducer } from 'sports/utils/reducer';
import { createAction } from 'typesafe-actions';

////////////////
/// Actions ///
//////////////
export const actions = {
	fetchHistory: createAction(
		'bets/history',
		(resolve) => (params: BetsHistoryRequestParams) => resolve(params)
	)
};
export default createEntityReducer<BetsHistoryResponse>(
	schemas.betsHistoryResponses.key
);
export const loads = {
	fetchBetHistory: (params: BetsHistoryRequestParams) =>
		`bets/history_${params.from}_${params.to}_${params.state}`,
	fetchBetHistoryTotals: (state: BetsHistoryState) =>
		`bets/history_totals_${state}`
};
//////////////
/// EPICS ///
////////////

const getBetHistory = createFetchEntityEpic(
	actions.fetchHistory,
	(params: BetsHistoryRequestParams, { api }) =>
		api.sportsbetting.getBetsHistory(params),
	schemas.betsHistoryResponses,
	(result, payload) => ({
		...result,
		id: betsHistoryResponseId(payload)
	}),
	({ payload, result }) => {
		const loadmeta = finishLoading(
			loads.fetchBetHistory(payload),
			loads.fetchBetHistoryTotals(payload.state)
		);
		let sportsMeta = {};
		if (result) {
			sportsMeta = betsHistoryCountMeta(payload.state, result.totalCount);
		}
		return { ...loadmeta, ...sportsMeta };
	}
);
export const betsHistoryResponsesEpic = combineEpics(getBetHistory);
