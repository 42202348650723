import { filterMap } from '../utils/array';

export interface CloudinaryProps {
	imageUrl: string;
	height?: number | string; // Can be string like iw
	width?: number | string; // Can be string like iw
	opacity?: number;
	/** @desctiption hex code for colour */
	background?: string;
	gravity?: string;
	crop?: string;
	/** @description some quality settings of image we dont usually need to change */
	quality?: string;
	fetchFormat?: string;
	devicePixelRatio?: string;
}
const cloudinaryOptions: Record<
	keyof Omit<CloudinaryProps, 'imageUrl'>,
	string
> = {
	// Dimensions
	width: 'w_{{value}}',
	height: 'h_{{value}}',
	// Position and cropping
	gravity: 'g_{{value}}',
	crop: 'c_{{value}}',
	// Styling
	opacity: 'o_{{value}}',
	background: 'b_rgb:{{value}}',
	// Image Quality
	quality: 'q_{{value}}',
	fetchFormat: 'f_{{value}}',
	devicePixelRatio: 'dpr_{{value}}'
};

const defaultOptions: Pick<
	CloudinaryProps,
	'crop' | 'gravity' | 'quality' | 'fetchFormat' | 'devicePixelRatio'
> = {
	crop: 'fill',
	gravity: 'center',
	quality: 'auto:best',
	fetchFormat: 'auto',
	devicePixelRatio: 'auto'
};
/** @description image url helper to generate image url with cloudinary params prefix */
export const getCloudinaryImageUrl = (props: CloudinaryProps) => {
	const { imageUrl, background, ...restProps } = props;
	const backgroundColorCode = background?.substring(1);
	const params = {
		...defaultOptions,
		...restProps,
		background: backgroundColorCode
	};
	// Base Url
	let url = CLOUDINARY_URL + '/image/fetch/';
	// Cloudinary Params
	let options: string[] = filterMap(
		Object.keys(cloudinaryOptions),
		/* filter out the ones we can't translate https://bitgamelabs.slack.com/archives/CST11DK46/p1589199487071000 */
		(key) => params[key] !== undefined,
		(key) => {
			const param = params[key];
			const option = cloudinaryOptions[key];
			return option.replace('{{value}}', param);
		}
	);
	url += options.join(',');
	// Actual Image
	url += `/${imageUrl}`;
	return url;
};

/** @description image url helper to generate a string to point to local resources of a given static path*/
export const getStaticMedia = (path: string) => {
	const auth = `https://${BASE_SITE_BASIC_AUTH}`;
	const host = BASE_HOST || window.location.hostname;
	return auth + host + path;
};
