"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createWhatsAppShareUrl = exports.createTelegramShareUrl = exports.createTwitterShareUrl = exports.createFacebookShareUrl = void 0;
exports.createFacebookShareUrl = function (url) {
    return "https://www.facebook.com/sharer.php?u=" + url + "&display=page";
};
exports.createTwitterShareUrl = function (url) {
    return "https://twitter.com/intent/tweet?url=" + url;
};
exports.createTelegramShareUrl = function (url) {
    return "https://t.me/share/url?url=" + url;
};
exports.createWhatsAppShareUrl = function (url) {
    return "https://api.whatsapp.com/send?phone=&text=" + encodeURIComponent(url);
};
