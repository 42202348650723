"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIframeWidgetStyles = void 0;
var styles_1 = require("@material-ui/core/styles");
var React = __importStar(require("react"));
var ratio_1 = require("../../configs/ratio");
var dimensionsUtils_1 = require("../../utils/dimensionsUtils");
var iframeUtils_1 = require("../../utils/iframeUtils");
// Because of classes creation order, we will need to duplicate one here
var useIframeWidgetDimensionsStyles = styles_1.makeStyles({
    root: function (_a) {
        var height = _a.height, width = _a.width;
        return ({
            width: width,
            height: height
        });
    }
});
var getIframeWidgetElements = function (_a) {
    var _b, _c;
    var ref = _a.ref;
    // Define
    var widget = ref === null || ref === void 0 ? void 0 : ref.current;
    var parent = widget === null || widget === void 0 ? void 0 : widget.parentElement;
    var header = (_b = widget === null || widget === void 0 ? void 0 : widget.getElementsByTagName('header')) === null || _b === void 0 ? void 0 : _b[0];
    var actionBar = (_c = widget === null || widget === void 0 ? void 0 : widget.getElementsByTagName('footer')) === null || _c === void 0 ? void 0 : _c[0];
    return {
        parent: parent,
        widget: widget,
        header: header,
        actionBar: actionBar
    };
};
exports.useIframeWidgetStyles = function (_a) {
    var ref = _a.ref, // The widget ref
    _b = _a.ratio, // The widget ref
    ratio = _b === void 0 ? ratio_1.defaultIframeRatio : _b, fullscreen = _a.fullscreen, cinema = _a.cinema, showSidemenu = _a.showSidemenu;
    // Get Elements
    var _c = getIframeWidgetElements({
        ref: ref
    }), parent = _c.parent, widget = _c.widget, header = _c.header, actionBar = _c.actionBar;
    // Window
    var _d = window.innerHeight, windowHeight = _d === void 0 ? 0 : _d, _e = window.innerWidth, windowWidth = _e === void 0 ? 0 : _e;
    var parentWidth = dimensionsUtils_1.useElementDimensionsResize(parent).width;
    var headerHeight = (header === null || header === void 0 ? void 0 : header.clientHeight) || 0;
    var actionBarHeight = (actionBar === null || actionBar === void 0 ? void 0 : actionBar.clientHeight) || 0;
    var widgetTop = (widget === null || widget === void 0 ? void 0 : widget.offsetTop) || 0;
    var positionTop = cinema ? 0 : widgetTop;
    dimensionsUtils_1.useWindowDimensionsResize();
    // Its being used to trigger the rerender and calclation when the mode its switch because of reference of components
    var _f = React.useState('normal'), triggerChangeMode = _f[1];
    // Show info will push the area to the left, the iframe wrapper need to be re calculated
    var _g = React.useState(false), setShowSidemnu = _g[1];
    // Hooks For setting cinema
    React.useEffect(function () {
        triggerChangeMode(fullscreen ? 'fullscreen' : cinema ? 'cinema' : 'normal');
        setShowSidemnu(!!showSidemenu);
        // Switch the Cinema Mode, the widget should cover the screen.
        // It should avoid the screen from scrolling on the main body.
        document.body.style.overflow = cinema ? 'hidden' : 'auto';
        return function () {
            document.body.style.removeProperty('overflow');
        };
    }, [cinema, showSidemenu, fullscreen]);
    var _h = React.useMemo(function () {
        // Getting the available space we have for the area to place the game
        var _a = dimensionsUtils_1.getAvailableDimensions({
            windowHeight: windowHeight,
            windowWidth: windowWidth,
            parentWidth: parentWidth,
            headerHeight: headerHeight,
            actionBarHeight: actionBarHeight,
            positionTop: positionTop,
            fullscreen: fullscreen,
            cinema: cinema,
            showSidemenu: showSidemenu,
            ratio: ratio
        }), availableHeight = _a.height, availableWidth = _a.width;
        // Getting the dimensions of game based on the spaces and ratio
        var iframeWidth = iframeUtils_1.getIframeDimensions({
            availableHeight: availableHeight,
            availableWidth: availableWidth,
            ratio: ratio
        }).width;
        // Calculated the size of widget, widget shouldnt have wider width of the given game.
        var _b = iframeUtils_1.getIframeWidgetDimensions({
            iframeWidth: iframeWidth,
            headerHeight: headerHeight,
            availableHeight: availableHeight,
            actionBarHeight: actionBarHeight,
            cinema: cinema
        }), height = _b.height, width = _b.width;
        return {
            height: height,
            width: width
        };
    }, [
        positionTop,
        windowHeight,
        windowWidth,
        headerHeight,
        actionBarHeight,
        fullscreen,
        cinema,
        showSidemenu,
        ratio,
        parentWidth
    ]), height = _h.height, width = _h.width;
    // Create Classes
    return useIframeWidgetDimensionsStyles({
        height: height,
        width: width
    });
};
