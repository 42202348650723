import { SectionTypeEnum } from 'casino/api/casino';
import { CasinoState } from 'casino/modules/rootModule';
import { SectionsParams } from 'casino/modules/sectionsModule';
import { filterMap } from 'materialbet-common';
import { createSelector } from 'reselect';

import { GameId, getGameStudio } from '../gamesSelectors';
import { getSections } from '../metaSelectors';
import { getStudioName } from '../studiosSelectors';

/** @file Selectors across multiple store slices */
export const getSectionIdByCategorySlug = (
	sectionParams: SectionsParams,
	slug: string
) => (state: CasinoState) =>
	getSections(sectionParams)(state)?.find(
		(id) => state.sections[id]?.category_slug === slug
	);
const sideMenuCategorySlugSelector = createSelector(
	[
		(state: CasinoState, props: SectionsParams) =>
			getSections(props)(state),
		(state: CasinoState) => state.sections
	],
	(sectionIds, sections) =>
		(sectionIds &&
			filterMap(
				sectionIds,
				(id) =>
					!!(
						sections[id] &&
						sections[id].category_slug &&
						sections[id].type === SectionTypeEnum.GameRow &&
						!sections[id].recommendations_only
					),
				(id) => sections[id].category_slug || ''
			)) ||
		[]
);
export const getSideMenuCatgorySlugs = (params: SectionsParams) => (
	state: CasinoState
) => sideMenuCategorySlugSelector(state, params);
export const getGameStudioName = (gameId: GameId) => (state: CasinoState) => {
	const studioKey = getGameStudio(gameId)(state);
	const studioName = getStudioName(studioKey)(state);
	return studioName;
};
