import { MarketType } from 'sports-sdk';

import { TabConfiguration } from '../types';

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: [
			{ marketType: MarketType.world_lottery_standard_bet },
			{
				marketType:
					MarketType.world_lottery_standard_bet_incl_bonus_ball_in_same_drum
			},
			{
				marketType:
					MarketType.world_lottery_standard_bet_incl_bonus_ball_in_additional_drum
			},
			{
				marketType: MarketType.world_lottery_draw_sum_total
			},
			{
				marketType:
					MarketType.world_lottery_draw_sum_total_incl_bonus_ball
			},
			{
				marketType: MarketType.world_lottery_draw_sum_range
			},
			{
				marketType: MarketType.world_lottery_bonus_ball_total
			},
			{
				marketType: MarketType.world_lottery_bonus_ball_sum_total
			},
			{
				marketType: MarketType.world_lottery_bonus_ball_single_digit
			},
			{
				marketType: MarketType.world_lottery_bonus_ball_range
			},
			{
				marketType:
					MarketType.world_lottery_bonus_ball_equals_any_regular_ball
			},
			{
				marketType: MarketType.world_lottery_draw_sum_odd_even
			},
			{
				marketType: MarketType.world_lottery_bonus_ball_sum_odd_even
			},
			{
				marketType: MarketType.world_lottery_bonus_ball_odd_even
			}
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.world_lottery_standard_bet
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.world_lottery_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
