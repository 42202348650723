import { route } from '@gaming-shell/routing';

export const BaseRoutes = {
	root: route([]),
	license: route(['about-us', 'license']),
	privacyPolicy: route(['security', 'privacy-policy']),
	termsAndConditions: route(['help', 'terms']),
	rules: route(['help', 'rules']),
	providerTransactionSuccess: route(['fiat_transaction_provider', 'success']),
	providerTransactionCanceled: route(['fiat_transaction_provider', 'cancel'])
};
