"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toKey = exports.useStaticContent = void 0;
var React = __importStar(require("react"));
/* currently only 'en' language is supported thus setting different one will result in bad request*/
/** @description a helper to fetch data json for a given path*/
exports.useStaticContent = function (url, language) {
    if (language === void 0) { language = 'en'; }
    var _a = React.useState(), content = _a[0], setContent = _a[1];
    var _b = React.useState(true), isLoading = _b[0], setLoading = _b[1];
    var key = "/" + language + "/" + url;
    React.useEffect(function () {
        var localizedUrl = "https://" + window.location.hostname + key + ".json";
        fetch(localizedUrl, {
            headers: { Authorization: BASE_API_BASIC_AUTH }
        })
            .then(function (response) { return response.json(); })
            .then(function (response) {
            if (ENABLE_STATIC_CONTENT_DRAFT || !response.draft) {
                setContent(response);
                setLoading(false);
            }
            else {
                throw response;
            }
        })
            .catch(function (_) {
            // page just stays empty for now
            // TODO should display error
            if (language !== 'en') {
                fetch(localizedUrl, {
                    headers: { Authorization: BASE_API_BASIC_AUTH }
                })
                    .then(function (response) { return response.json(); })
                    .then(function (response) {
                    if (ENABLE_STATIC_CONTENT_DRAFT ||
                        !response.draft) {
                        setContent(response);
                        setLoading(false);
                    }
                    else {
                        throw response;
                    }
                })
                    .catch(function (_) {
                    setLoading(false);
                });
            }
            else {
                setLoading(false);
            }
        });
    }, [key]);
    return { content: content, isLoading: isLoading };
};
exports.toKey = function (value) { return value.toLowerCase().replace(/\s/g, '_'); };
