import { Translate } from '@gaming-shell/i18n';
import { SideMenuItem } from '@gaming-shell/layout';
import { usePlayerLoading, usePlayerLoggedIn } from '@materialbet-core/player';
import { AuthRoutes, CasinoRoutes } from 'materialbet-common';
import { MyCasinoIcon } from 'materialbet-icons';
import * as React from 'react';
import { useLocation } from 'react-router';

/** @description a component for single my casino item in side menu */
let CasinoSideMenuMyCasino: React.FC = () => {
	const { pathname } = useLocation();
	const playerIsLoading = usePlayerLoading();
	const playerIsLoggedIn = usePlayerLoggedIn();
	const loggedOut = !playerIsLoading && !playerIsLoggedIn;
	const isMyCasinoRoute = CasinoRoutes.myCasino.matches(pathname);
	return (
		<SideMenuItem
			icon={<MyCasinoIcon />}
			label={<Translate label="casino.content.myCasino" />}
			href={
				loggedOut
					? AuthRoutes.signIn.create()
					: CasinoRoutes.myCasino.create()
			}
			active={isMyCasinoRoute}
			variant="subtitle2"
		/>
	);
};

CasinoSideMenuMyCasino = React.memo(CasinoSideMenuMyCasino);
export { CasinoSideMenuMyCasino };
