import { isPrimaryMarket } from 'sports-sdk';
import { parseOdds } from 'sports/utils/odds';
import {
	useAddToBigBetslip,
	useBigBetslipIsActive
} from '../BigBetslip/BigBetslipProvider';
import { useOpenQuickbetslip } from '../QuickBetslip/QuickBetslipProvider';
import { BetslipReducerSelection } from '../types';
import { getIsZeroMarginPromoActive } from 'sports/selectors/eventSelectors';
import { useStore } from 'react-redux';
import { useLanguage } from '@gaming-shell/i18n';

export const useAddToBetslip = () => {
	const openQuickBetslip = useOpenQuickbetslip();
	const bigBetslipIsActive = useBigBetslipIsActive();
	const addToBigBetslip = useAddToBigBetslip();
	const store = useStore();
	const locale = useLanguage();
	return (selection: BetslipReducerSelection, anchorEl?: Element | null) => {
		const state = store.getState();
		const isPrimary = isPrimaryMarket(selection.marketKey);
		const isZeroMarginActive = getIsZeroMarginPromoActive(
			selection.eventId,
			locale
		)(state);
		if (!bigBetslipIsActive || (isZeroMarginActive && isPrimary)) {
			openQuickBetslip(selection, anchorEl || null);
			return;
		}
		const parsedSelection = {
			...selection,
			price: parseOdds(selection.price)
		};
		addToBigBetslip(parsedSelection);
	};
};
