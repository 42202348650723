import { CasinoSideMenuPortal } from '@gaming-shell/layout';
import { CasinoSideMenu } from 'casino/components/CasinoSideMenu/CasinoSideMenu';
import { registerSharedComponents } from 'casino/sharedComponents';
import { casinoStore } from 'casino/store/store';
import * as React from 'react';
import { Provider } from 'react-redux';

import { FetchSections } from './FetchSections';

registerSharedComponents();
export const SideEffects: React.FunctionComponent<unknown> = () => {
	// useSetTitleBarCasinoItem();

	return (
		<CasinoSideMenuPortal>
			<Provider store={casinoStore}>
				{!ENABLE_SECTIONLESS_SECTION_PAGES && <FetchSections />}
				<CasinoSideMenu />
			</Provider>
		</CasinoSideMenuPortal>
	);
};
