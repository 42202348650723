import { Translate } from '@gaming-shell/i18n';
import { SideMenuItem } from '@gaming-shell/layout';
import { usePlayerCurrency } from '@materialbet-core/player';
import { PlayerRoutes } from 'materialbet-common';
import { CashierIcon } from 'materialbet-icons';
import * as React from 'react';
import { useLocation } from 'react-router';

/** @description a component for single Cashier item in side menu */
let Cashier: React.FC = () => {
	const currency = usePlayerCurrency();
	const { pathname } = useLocation();
	const isCashierRoute = PlayerRoutes.cashier.matches(pathname);
	if (ACCOUNT_FINANCE === 'settlements' || !currency) {
		return null;
	}
	const cashierRoute = PlayerRoutes.cashierDeposit.create({ currency });
	return (
		<SideMenuItem
			icon={<CashierIcon />}
			label={<Translate label="cashier.cashier" ns="player" />}
			href={cashierRoute}
			active={isCashierRoute}
			variant="subtitle2"
		/>
	);
};

Cashier = React.memo(Cashier);
export { Cashier };
