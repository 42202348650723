import { RolloverBonusCard } from 'casino/components/RolloverBonusCard';
import { actions, loads } from 'casino/modules/rolloverBonusesModule';
import { getLoadedOnce } from 'casino/selectors/loadingSelectors';
import { getRolloverBonuses } from 'casino/selectors/metaSelectors';
import { useRolloverBonusesParams } from 'casino/util/rolloverBonusesUtils';
import { withHook } from 'materialbet-common';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export interface RolloverBonusesWidgetUiProps {
	rolloverBonusesIds: string[];
	loaded: boolean;
}
const RolloverBonusesWidgetUi: React.FC<RolloverBonusesWidgetUiProps> = ({
	rolloverBonusesIds,
	loaded
}) => {
	if (!loaded || !rolloverBonusesIds.length) {
		return null;
	}
	const rolloverBonuses = rolloverBonusesIds.map((id) => (
		<RolloverBonusCard key={id} id={id} />
	));
	return <>{rolloverBonuses}</>;
};
const useRolloverBonusesWidgetUiProps = (): RolloverBonusesWidgetUiProps => {
	const params = useRolloverBonusesParams({}); // get for all currencies
	const rolloverBonusesIds = useSelector(getRolloverBonuses(params)) || [];
	const loaded = useSelector(getLoadedOnce(loads.rolloverBonuses(params)));
	const dispatch = useDispatch();
	const { playerUuid, currency } = params;
	React.useEffect(() => {
		dispatch(actions.fetchRolloverBonuses({ playerUuid, currency }));
	}, [dispatch, playerUuid, currency]);
	return { rolloverBonusesIds, loaded };
};

export const RolloverBonusesWidget = withHook(useRolloverBonusesWidgetUiProps)(
	RolloverBonusesWidgetUi
);
