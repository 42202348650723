import { ClientOptions } from 'urql';

type CustomClientOptions = ClientOptions & {
	fetchOptions: {
		removeCSRF: boolean;
	};
};

export const urqlClientConfig: CustomClientOptions = {
	url: PLAYER_API_URL,
	fetchOptions: {
		removeCSRF: true,
		credentials: 'include'
	}
};
