import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

export const createSvgIcon = (
	path: string,
	displayName: string,
	opacity?: string,
	viewBox?: string,
	transform?: string
) => {
	const Icon: React.FunctionComponent<SvgIconProps> = (props) => {
		return (
			<SvgIcon transform={transform} viewBox={viewBox} {...props}>
				<path fill={props.fill} d={path} opacity={opacity} />
			</SvgIcon>
		);
	};
	Icon.displayName = ''.concat(displayName, 'Icon');
	return Icon;
};
