import * as React from 'react';
import { fetchTokenCookie } from 'auth/api/token';
import { useSetAuth } from 'auth/components/AuthProvider/AuthContext';
import {
	getTokenCookieError,
	TokenCookieError
} from 'auth/utils/errorHandlers';

export const useSetCookie = (authReturnKey: string): TokenCookieError => {
	const code: string = new URLSearchParams(window.location.search).get(
		'code'
	) as string;
	const [error, setError] = React.useState<TokenCookieError>({
		hasError: false,
		messageKey: ''
	});
	const setAuth = useSetAuth();

	React.useEffect(() => {
		fetchTokenCookie(code, authReturnKey).then((res) => {
			if (res.status === 200) {
				setAuth(true);
			} else {
				setAuth(false);
				setError(getTokenCookieError(res));
			}
		});
	}, [code, setAuth, setError, authReturnKey]);
	return error;
};
