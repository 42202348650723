import { Box, Theme, makeStyles } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		['& > .MuiBox-root:first-child']: {
			borderTop: `1px solid ${theme.palette.divider}`,
			marginTop: 0,
			padding: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px 0`,
			[theme.breakpoints.down('xs')]: {
				marginTop: theme.spacing(2)
			}
		},
		['& > .MuiBox-root']: {
			borderTop: `1px solid ${theme.palette.divider}`,
			marginTop: theme.spacing(1),
			padding: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px 0`
		},
		['& > .MuiBox-root:last-child']: {
			borderBottom: `1px solid ${theme.palette.divider}`,
			padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`
		}
	}
}));

export const PromoDetailsSection: React.FC = ({ children }) => {
	const classes = useStyles();

	return <Box className={classes.root}>{children}</Box>;
};
