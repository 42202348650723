import casinoConfig from '../config';
import * as casinoApis from './casino/api';
import { Configuration } from './casino/configuration';

const config: Configuration = {
	basePath: casinoConfig.baseApiUrl,
	baseOptions: {
		credentials: 'include',
		headers: {
			accept: 'application/json',
			'Content-Type': 'application/json',
			origin: casinoConfig.baseApiUrl
		}
	}
};

const api = {
	studios: casinoApis.StudiosApiFactory(config, fetch, config.basePath),
	games: casinoApis.GamesApiFactory(config, fetch, config.basePath),
	recommendation: casinoApis.RecommendationApiFactory(
		config,
		fetch,
		config.basePath
	),
	favorites: casinoApis.FavouritesApiFactory(config, fetch, config.basePath),
	freespins: casinoApis.FreespinsoffersApiFactory(
		config,
		fetch,
		config.basePath
	),
	rolloverBonuses: casinoApis.RolloverbonusesApiFactory(
		config,
		fetch,
		config.basePath
	),
	// healthcheck: new casinoApis.HealthcheckApi(config, config.basePath, fetch),
	default: casinoApis.DefaultApiFactory(config, fetch, config.basePath)
};
export default api;
export type CasinoApi = typeof api;
// The produced InlineResponse names are not stable, so we map them here such that we only have to update them here
export type GetRolloverBonusesResponse = casinoApis.InlineResponse2001;
export type GetGamesResponse = casinoApis.InlineResponse2004;
export type GetMyGamesResponse = casinoApis.InlineResponse2006;
export type GetStudiosResponse = casinoApis.InlineResponse2005;
export type GetFreespinsResponse = casinoApis.InlineResponse200;
export type GetSectionsResponse = casinoApis.InlineResponse2007;
export const SectionTypeEnum = casinoApis.InlineResponse2007Sections.TypeEnum;
export type CasinoApiSection = casinoApis.InlineResponse2007Sections;
