import { CasinoRoutes } from 'materialbet-common';

import { GameEntity } from '../casinoSchemas';

export const createGameLaunchUrl = (
	game: Partial<GameEntity> & Pick<GameEntity, 'id'>,
	isLoggedIn?: boolean,
	category?: string
) => {
	let launchUrl;
	if (game.tags?.[0] && game.game_key && game.id) {
		if (isLoggedIn) {
			launchUrl = CasinoRoutes.legacyGame.create({
				gameId: game.id,
				category: category || game.tags[0],
				gameKey: game.game_key
			});
		} else {
			// freeplay url
			launchUrl = CasinoRoutes.legacyFreeplayGame.create({
				gameId: game.id,
				category: category || game.tags[0],
				gameKey: game.game_key
			});
		}
	} else {
		/* eslint-disable-next-line no-console */
		console.warn(
			`Missing params for legacy game launch url. GameId: ${game.id}`
		);
		launchUrl = CasinoRoutes.game.create({ gameId: game.id });
	}

	return launchUrl;
};

export interface LaunchUrlParams {
	gameId: string;
	isLoggedIn: boolean;
	categorySlug: string;
	gameKey: string;
}
export const createGameLaunchUrl20 = ({
	gameId,
	isLoggedIn,
	categorySlug,
	gameKey
}: LaunchUrlParams) => {
	const route = isLoggedIn
		? CasinoRoutes.legacyGame
		: CasinoRoutes.legacyFreeplayGame;
	return route.create({ gameId, category: categorySlug, gameKey });
};
