import {
	CompetitionEventsParams,
	getCompetitionEventsKey
} from 'sports/modules/eventsModule';
import { RootState } from 'sports/modules/root';

export const getCompetitionEvents = (
	state: RootState,
	params: CompetitionEventsParams
) => {
	const key = getCompetitionEventsKey(params);
	return state.sportsMeta.competitionEvents?.[key];
};
export const getIpCountry = (state: RootState) => state.sportsMeta.ipCountry;
