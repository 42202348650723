import { isMobile } from '@gaming-shell/device';
import { MarketType } from 'sports-sdk';

import { MarketConfig, MarketView, TabConfiguration } from '../types';

const allMarkets: Record<string, MarketConfig[]> = {
	popular: [
		{
			marketGroup: [
				{
					marketType: MarketType.basketball_handicap,
					view: MarketView.Handicap,
					disableAltLines: true
				},
				{
					marketType: MarketType.basketball_totals,
					view: MarketView.Goals,
					disableAltLines: true
				},
				{
					marketType: MarketType.basketball_moneyline,
					disableAltLines: true
				}
			],
			view: MarketView.GameLine
		},
		{
			marketType: MarketType.basketball_handicap,
			view: MarketView.Handicap,
			disableMainLine: true,
			collapsedByDefault: isMobile()
		},
		{
			marketType: MarketType.basketball_totals,
			view: MarketView.Goals,
			disableMainLine: true,
			collapsedByDefault: isMobile()
		},
		{
			marketType: MarketType.basketball_1x2
		},
		{
			marketType: MarketType.basketball_race_to_points
		},
		{
			marketType: MarketType.basketball_team_to_lead_by_points
		},
		{
			marketType: MarketType.basketball_any_team_to_lead_by_points
		},
		{
			marketType:
				MarketType.basketball_team_total_maximum_consecutive_points,
			view: MarketView.Goals
		},
		{
			marketType:
				MarketType.basketball_any_team_total_maximum_consecutive_points,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.basketball_winning_margin
		},
		{
			marketType: MarketType.basketball_scoring_type_pointnr
		}
	],
	firstHalf: [
		{
			marketType: MarketType.basketball_1st_half_draw_no_bet
		},
		{
			marketType: MarketType.basketball_handicap_period_first_half,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.basketball_total_period_first_half,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.basketball_1st_half_1x2
		},
		{
			marketType: MarketType.basketball_1st_half_winning_margin
		},
		{
			marketType: MarketType.basketball_1st_half_team_total,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.basketball_halftime_fulltime
		},
		{
			marketType: MarketType.basketball_odd_even_period_first_half
		}
	],
	secondHalf: [
		{
			marketType: MarketType.basketball_2nd_half_1x2_incl_ot
		},
		{
			marketType: MarketType.basketball_2nd_half_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.basketball_handicap_period_second_half,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.basketball_2nd_half_total,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.basketball_total_period_second_half
		},
		{
			marketType: MarketType.basketball_2nd_half_1x2
		}
	],
	points: [
		{
			marketType: MarketType.basketball_team_totals,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.basketball_race_to_points_incl_ot
		},
		{
			marketType: MarketType.basketball_point_range
		},
		{
			marketType: MarketType.basketball_odd_even
		}
	],
	quarters: [
		{
			marketType: MarketType.basketball_quarter_1x2
		},
		{
			marketType: MarketType.basketball_quarter_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.basketball_quarter_totals,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.basketball_highest_scoring_quarter
		},
		{
			marketType: MarketType.basketball_quarter_winning_margin
		}
	],
	specials: [
		{
			marketType: MarketType.basketball_freetext
		}
	]
};

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: allMarkets.popular
	},
	{
		label: 'markets.firstHalf',
		markets: allMarkets.firstHalf
	},
	{
		label: 'markets.secondHalf',
		markets: allMarkets.secondHalf
	},
	{
		label: 'markets.points',
		markets: allMarkets.points
	},
	{
		label: 'markets.quarters',
		markets: allMarkets.quarters
	},
	{
		label: 'markets.specials',
		markets: allMarkets.specials
	},
	{
		label: 'descriptions.all',
		markets: [
			...allMarkets.popular,
			...allMarkets.firstHalf,
			...allMarkets.secondHalf,
			...allMarkets.points,
			...allMarkets.quarters,
			...allMarkets.specials
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.basketball_handicap
			},
			{
				marketType: MarketType.basketball_moneyline
			},
			{
				marketType: MarketType.basketball_totals
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.basketball_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
