import * as React from 'react';

import { KeyCodes } from './keyCodes';

export const useOnEscapePress = (callback: () => void) => {
	React.useEffect(() => {
		const keydownListener = (event: KeyboardEvent) => {
			if (event.keyCode === KeyCodes.Escape) {
				callback();
			}
		};
		document.addEventListener('keydown', keydownListener, false);
		return () => {
			document.removeEventListener('keydown', keydownListener, false);
		};
	});
};
