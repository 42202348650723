import { Theme } from '@gaming-shell/theming';
import { makeStyles, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import * as React from 'react';
import { MarketType, possibleOutcomeCount } from 'sports-sdk';
import { RelativeDayDisplay } from 'sports/components/displays/Date';
import { useTimeZone } from 'sports/utils/hooks';
import { useMarketHeader } from 'sports/utils/market';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		alignItems: 'center',
		justifyContent: 'space-between',
		background: theme.palette.background.top.main,
		display: 'flex',
		height: '24px'
	},
	dates: {
		marginLeft: theme.spacing(2)
	},
	selection: {
		marginRight: theme.spacing(1),
		textTransform: 'capitalize',
		fontWeight: 'normal',
		opacity: 0.6
	},
	selections: {
		marginLeft: 'auto',
		justifyContent: 'space-around',
		display: 'flex',
		padding: theme.spacing(0, 1)
	},
	trailingSpace: {
		maxWidth: 'max-content'
	},
	trailingIcon: {
		visibility: 'hidden'
	},
	halfWidth: {
		flexBasis: '50%'
	},
	dateColumn: {
		flexBasis: '50%',
		overflow: 'hidden',
		alignItems: 'center',
		display: 'flex'
	}
}));

export interface EventListHeadRowProps {
	marketKey?: MarketType;
	date: Date;
	disableEventTime?: boolean;
	disableTrailingSpace?: boolean;
}
let EventListHeadRow: React.FunctionComponent<EventListHeadRowProps> = (
	props
) => {
	const { marketKey, date, disableEventTime, disableTrailingSpace } = props;
	const classes = useStyles();
	const timeZone = useTimeZone();

	const [outcomesCount] = marketKey
		? possibleOutcomeCount(marketKey)
		: [0, undefined];

	const marketHeaders = useMarketHeader(outcomesCount, marketKey);
	const marketColumns = marketHeaders.map((market: string) => {
		return (
			<Typography
				className={classes.selection}
				variant="body2"
				key={market}
			>
				{market}
			</Typography>
		);
	});
	return (
		<div className={classes.container}>
			<div className={classes.dateColumn}>
				{!disableEventTime && (
					<Typography className={classes.dates} variant="body2">
						<RelativeDayDisplay
							date={date}
							dateFormat={'EEE MMM dd'}
							timeZone={timeZone}
							disableTime
						/>
					</Typography>
				)}
			</div>
			<div className={classes.halfWidth}>
				<Typography className={classes.selections} variant="caption">
					{marketColumns}
				</Typography>
			</div>
			{!disableTrailingSpace && (
				<div className={classes.trailingSpace}>
					<NavigateNextIcon className={classes.trailingIcon} />
				</div>
			)}
		</div>
	);
};
EventListHeadRow = React.memo(EventListHeadRow);
export { EventListHeadRow };
