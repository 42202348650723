"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAvailableMenus = exports.SideMenuProvider = void 0;
var device_1 = require("@gaming-shell/device");
var React = __importStar(require("react"));
var Context_1 = require("./Context");
var menuReducer = function (state, _a) {
    var _b;
    var menus = _a.menus, namespace = _a.namespace;
    if (menus === state[namespace]) {
        return state;
    }
    return __assign(__assign({}, state), (_b = {}, _b[namespace] = menus, _b));
};
var fixedMenuReducer = function (state, _a) {
    var _b;
    var fixedMenus = _a.fixedMenus, namespace = _a.namespace;
    if (fixedMenus === state[namespace]) {
        return state;
    }
    return __assign(__assign({}, state), (_b = {}, _b[namespace] = fixedMenus, _b));
};
exports.SideMenuProvider = function (_a) {
    var children = _a.children;
    var _b = React.useReducer(menuReducer, {
        casino: [],
        player: [],
        sports: []
    }), menus = _b[0], setMenus = _b[1];
    var _c = React.useState(device_1.isDesktop()), isOpen = _c[0], setOpen = _c[1];
    var _d = React.useState(), header = _d[0], setHeader = _d[1];
    var _e = React.useState(undefined), renderHeader = _e[0], setRenderHeader = _e[1];
    var _f = React.useState(), footer = _f[0], setFooter = _f[1];
    var _g = React.useState(), namespace = _g[0], setNamespace = _g[1];
    var setHeaderOrRenderHeader = function (header) {
        if (typeof header === 'function') {
            setHeader(undefined);
            setRenderHeader(header);
        }
        else {
            setHeader(header);
            setRenderHeader(undefined);
        }
    };
    var _h = React.useReducer(fixedMenuReducer, {
        casino: [],
        player: [],
        sports: []
    }), fixedMenus = _h[0], setFixedMenus = _h[1];
    var contextValue = React.useRef({
        namespace: namespace,
        menus: menus,
        fixedMenus: fixedMenus,
        header: header,
        renderHeader: renderHeader,
        isOpen: isOpen,
        footer: footer
    });
    var current = contextValue.current;
    if (namespace !== current.namespace ||
        menus !== current.menus ||
        header !== current.header ||
        fixedMenus !== current.fixedMenus ||
        isOpen !== current.isOpen ||
        footer !== current.footer) {
        contextValue.current = {
            namespace: namespace,
            menus: menus,
            header: header,
            fixedMenus: fixedMenus,
            isOpen: isOpen,
            footer: footer
        };
    }
    var controlValue = React.useRef({
        setNamespace: setNamespace,
        setMenus: setMenus,
        setHeader: setHeaderOrRenderHeader,
        setFixedMenus: setFixedMenus,
        setOpen: setOpen,
        setFooter: setFooter
    });
    return (React.createElement(Context_1.SideMenuControlContext.Provider, { value: controlValue.current },
        React.createElement(Context_1.SideMenuContext.Provider, { value: contextValue.current }, children)));
};
exports.getAvailableMenus = function (menus, gaming) {
    if (gaming) {
        return __spreadArrays(menus.sports, menus.casino);
    }
    return menus.player;
};
