import * as React from 'react';
import { useHistory } from 'react-router';
import { Typography, CircularProgress } from '@material-ui/core';
import { Translate } from '@gaming-shell/i18n';
import { useSignOut } from 'auth/utils/useSignOut';
import { AuthRoutes } from 'materialbet-common';

export const SignOut: React.FC = () => {
	const history = useHistory();
	const hasError = useSignOut(() =>
		history.replace(AuthRoutes.signIn.create())
	);
	return hasError ? (
		<Typography component={'span'}>
			<h2>
				<Translate label="common:application.descriptions.generalError" />
			</h2>
		</Typography>
	) : (
		<CircularProgress />
	);
};
