"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickDeposit = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var core_1 = require("@material-ui/core");
var framer_motion_1 = require("framer-motion");
var materialbet_icons_1 = require("materialbet-icons");
var React = __importStar(require("react"));
var __1 = require("..");
var __2 = require("../..");
var routes_1 = require("../../routes");
var useStyles = core_1.makeStyles(function (theme) { return ({
    address: {
        wordBreak: 'break-word'
    },
    paper: {
        width: 712,
        maxWidth: 'none'
    },
    column: {
        flex: 1
    },
    content: {
        marginTop: theme.spacing(4)
    },
    contentSpacing: {
        marginTop: theme.spacing(3),
        minHeight: 205
    },
    copyButton: {
        textTransform: 'none',
        flex: 1
    },
    qrImageWrapper: {
        marginRight: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    },
    minDeposit: {
        margin: theme.spacing(2) + "px 0"
    },
    currencyIcon: {
        marginRight: theme.spacing(1.5)
    },
    qrCodeButton: {
        marginLeft: theme.spacing(1)
    },
    depositNote: {
        marginTop: theme.spacing(1)
    }
}); });
var qrImageMotionVariants = {
    hidden: {
        opacity: 0,
        x: -300
    },
    visible: {
        opacity: 1,
        x: 0
    }
};
exports.QuickDeposit = function (_a) {
    var _b;
    var currentWalletDetails = _a.currentWalletDetails, currencies = _a.currencies, onCurrencyChange = _a.onCurrencyChange, onCopyClick = _a.onCopyClick, _c = _a.open, open = _c === void 0 ? false : _c, onClose = _a.onClose;
    var classes = useStyles();
    var standardTransitionDuration = core_1.useTheme().transitions.duration.standard;
    var trans = i18n_1.useTranslation();
    var _d = React.useState(false), showQrCode = _d[0], setShowQrCode = _d[1];
    var toggleQrCodeVisibility = React.useCallback(function (event) {
        event.stopPropagation();
        setShowQrCode(function (show) { return !show; });
    }, [setShowQrCode]);
    var handleClose = React.useCallback(function () {
        setShowQrCode(false);
        onClose();
    }, [onClose, setShowQrCode]);
    var currentCurrencyLabel = ((_b = __2.getCurrencyConfig(currentWalletDetails.currency)) === null || _b === void 0 ? void 0 : _b.label) ||
        currentWalletDetails.currency;
    return (React.createElement(__1.Dialog, { dialogTitle: React.createElement(i18n_1.Translate, { label: "actions.deposit", ns: "common" }), onClose: handleClose, open: open, classes: {
            paper: classes.paper
        }, buttons: React.createElement(React.Fragment, null,
            React.createElement(__1.ButtonLink, { color: "primary", variant: "text", onClick: handleClose, href: routes_1.PlayerRoutes.cashierDeposit.create({
                    currency: currentWalletDetails.currency
                }), "aria-label": "cashier link" },
                React.createElement(i18n_1.Translate, { label: "cashier.cashier", ns: "player" })),
            React.createElement(core_1.Button, { onClick: handleClose, "aria-label": "close" },
                React.createElement(i18n_1.Translate, { label: "actions.close", ns: "common" }))) },
        React.createElement(core_1.Divider, null),
        React.createElement(__1.Row, { className: classes.content },
            React.createElement(__1.Row, { justify: "flex-start", alignItems: "center", className: classes.column },
                React.createElement(__1.CurrencyIcon, { currency: currentWalletDetails.currency, className: classes.currencyIcon }),
                React.createElement(__1.Typography, { variant: "h6" }, currentWalletDetails.formattedCurrency)),
            React.createElement(__2.Select, { className: classes.column, value: currentWalletDetails.currency, onChange: onCurrencyChange, name: "currency", variant: "outlined", color: "primary", options: currencies.map(function (currency) {
                    var _a;
                    return ({
                        value: currency,
                        name: ((_a = __2.getCurrencyConfig(currency)) === null || _a === void 0 ? void 0 : _a.label) || currency
                    });
                }) })),
        React.createElement(__1.Row, { className: classes.contentSpacing, justify: "space-between", alignItems: "flex-start" },
            showQrCode && (React.createElement(framer_motion_1.motion.div, { variants: qrImageMotionVariants, initial: "hidden", animate: "visible", className: classes.qrImageWrapper, transition: {
                    ease: 'easeOut',
                    duration: standardTransitionDuration / 1000
                } },
                React.createElement(__1.QrImage, { src: QR_SERVICE_URL + "?data=" + currentWalletDetails.depositAddress, alt: trans('player:wallet.qrAddress') }))),
            React.createElement(__1.Column, { className: classes.column },
                React.createElement(__1.Row, { alignItems: "center" },
                    React.createElement(__1.CopyButton, { onClick: onCopyClick, valueToCopy: currentWalletDetails.depositAddress, className: classes.copyButton, "aria-label": "deposit address" },
                        React.createElement(core_1.Box, { flex: 1, textAlign: "left" },
                            React.createElement(__1.Typography, { color: "textPrimary" },
                                React.createElement(i18n_1.Translate, { label: "wallet.depositAddress", ns: "player" })),
                            React.createElement(__1.Typography, { className: classes.address, variant: "body2", color: "textSecondary" }, currentWalletDetails.depositAddress)),
                        React.createElement(core_1.Box, { display: "flex", alignItems: "center", justifyContent: "flex-end", paddingLeft: 2 },
                            React.createElement(materialbet_icons_1.CopyIcon, { color: "primary" }))),
                    React.createElement(core_1.IconButton, { className: classes.qrCodeButton, onClick: toggleQrCodeVisibility, "aria-label": "toggle qr visibility" },
                        React.createElement(materialbet_icons_1.QrCodeIcon, { color: "primary" }))),
                React.createElement(__1.Typography, { className: classes.minDeposit, component: "div", variant: "caption", color: "textSecondary" }, trans('player:cashier.minimumDeposit', {
                    value: currentCurrencyLabel + " " + currentWalletDetails.minDepositAmount
                })),
                currentWalletDetails.depositNote && (React.createElement(__1.Typography, { component: "div", className: classes.depositNote, variant: "body2", color: "textPrimary", "aria-label": "deposit note" }, currentWalletDetails.depositNote))))));
};
