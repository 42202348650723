import BigNumber from 'bignumber.js';

import {
	getCurrencyConfig,
	getCurrencyVariantConfig
} from '../configs/currency';
import { truncate } from './number';

export type CurrencyPosition = 'left' | 'right' | 'none';
export interface FormatCurrencyOptions {
	keepTrailingZeros?: boolean;
	currencyPosition?: CurrencyPosition;
	variant?: string;
	/** @descriptions overwrite the configured currency decimal places */
	maxDecimalPlaces?: number;
}
const defaultCurrencyOptions: FormatCurrencyOptions = {
	currencyPosition: 'left'
};

export const formatCurrency = (
	amount: number | string,
	currency: string,
	options: FormatCurrencyOptions = {}
) => {
	options = { ...defaultCurrencyOptions, ...options };
	const {
		variant,
		keepTrailingZeros,
		currencyPosition,
		maxDecimalPlaces
	} = options;
	const { factor, decimalPlaces, label } = getCurrencyVariantConfig(
		currency,
		variant
	);
	const formatDecimalPlaces = maxDecimalPlaces
		? Math.min(maxDecimalPlaces, decimalPlaces)
		: decimalPlaces;
	const number = new BigNumber(amount).times(factor).toNumber();
	const truncated = truncate(number, formatDecimalPlaces, keepTrailingZeros);
	const currencyLabel = label;
	switch (currencyPosition) {
		case 'right':
			return `${truncated} ${currencyLabel}`;
		case 'left':
			return `${currencyLabel} ${truncated}`;
		default:
			return truncated;
	}
};
export const formatCurrencyValue = (amount: number, currency: string) =>
	formatCurrency(amount, currency, { currencyPosition: 'none' });
export interface GetDigitDisplayingCurrencyVariantParams {
	currency: string;
	variant: string;
	decimalPlaces: number;
	value: number;
}
export const getDigitDisplayingCurrencyVariant = ({
	currency,
	decimalPlaces,
	value,
	variant
}: GetDigitDisplayingCurrencyVariantParams) => {
	const currentVariantConfig = getCurrencyVariantConfig(currency, variant);
	if (value === 0 || !currentVariantConfig) {
		return variant;
	}
	const minValue = +(0.1 ** decimalPlaces).toFixed(decimalPlaces);
	if (currentVariantConfig.factor * value >= minValue) {
		return variant;
	}
	const currentCurrencyConfig = getCurrencyConfig(currency);
	if (!currentCurrencyConfig) {
		return variant;
	}
	// this gets the next best variant that can display the value
	// the implementation is fine for now - however in case we will have currencies with many variants
	// we should opt to find the one closest to the given variant (minimizing diff of factor)
	const result =
		Object.keys(currentCurrencyConfig.variants).find((key) => {
			const config = currentCurrencyConfig.variants[key];
			return config.factor * value >= minValue;
		}) || variant;
	return result;
};
