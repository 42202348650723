import React from 'react';

export type AuthControlContextValue = (auth: boolean) => void;

export const AuthContext = React.createContext<boolean>(false);
export const AuthControlContext = React.createContext<
	React.Dispatch<React.SetStateAction<boolean>>
>(() => void 0);

export const useSetAuth = () => React.useContext(AuthControlContext);
export const useAuth = () => React.useContext(AuthContext);
