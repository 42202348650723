import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';
import { createSvgIcon } from 'sports/components/util/Icon';

// TODO: remove old and rename when remove the flag
export const NewBackspaceIcon: React.FunctionComponent<SvgIconProps> = (
	props
) => {
	return (
		<SvgIcon {...props} viewBox={'0 0 28 22'}>
			<g
				id="Icon-suite"
				stroke="none"
				strokeWidth="1"
				fillRule="evenodd"
				fillOpacity="0.87"
			>
				<g
					id="Navigation-icons"
					transform="translate(-1723.000000, -1034.000000)"
				>
					<g
						id="backspace-2"
						transform="translate(1723.000000, 1034.000000)"
					>
						<g id="backspace">
							<g
								id="delete.1-2"
								transform="translate(0.000000, 0.916667)"
							>
								<g id="delete.1" fillRule="nonzero">
									<path
										d="M9.11011884,0.0374021564 L9.22712903,0.0381222432 L24.2104636,0.0381222432 C26.0565574,0.0381222432 27.5536384,1.67585381 27.5536384,3.69785759 L27.5536384,3.69785759 L27.5536384,16.5069313 C27.5536384,18.5289351 26.0565574,20.1666667 24.2104636,20.1666667 L24.2104636,20.1666667 L9.167012,20.1666667 L9.167012,20.148368 C8.95326257,20.148368 8.73951313,20.0568746 8.57586122,19.8830372 L8.57586122,19.8830372 L1.07292198,10.7885948 C0.896745686,10.5964587 0.820764441,10.3402773 0.833288822,10.0840958 C0.820764441,9.83706364 0.896745686,9.58088217 1.07292198,9.38874606 L1.07292198,9.38874606 L8.57586122,0.294303718 C8.75454239,0.102167612 8.99334059,0.0198235665 9.22712903,0.0381222432 Z M19.7952018,6.69884058 C19.4662281,6.34201638 18.9335244,6.34201638 18.6037157,6.69884058 L18.6037157,6.69884058 L16.6833106,8.80318841 L14.813838,6.75373661 C14.4882041,6.39691241 13.9588402,6.39691241 13.6323713,6.75373661 C13.3059025,7.11056081 13.3059025,7.69611846 13.6323713,8.05294266 L13.6323713,8.05294266 L15.501844,10.0932451 L13.6081575,12.1701449 C13.2791838,12.5269691 13.2791838,13.1125268 13.6081575,13.4785003 C13.9371313,13.8353245 14.4706699,13.8353245 14.7996437,13.4785003 L14.7996437,13.4785003 L16.6933301,11.4016005 L18.5628027,13.4510523 C18.8892716,13.8078765 19.4186354,13.8078765 19.7442693,13.4510523 C20.0715731,13.0942281 20.0715731,12.5086704 19.7442693,12.1518463 L19.7442693,12.1518463 L17.8747967,10.1115438 L19.7952018,8.00719597 C20.1250105,7.64122243 20.1250105,7.06481411 19.7952018,6.69884058 Z"
										id="Combined-Shape"
										fill={props.fill}
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};

const oldBackspaceIcon = createSvgIcon(
	'M22.565 13.86a1.119 1.119 0 0 1 0 1.48.9.9 0 0 1-1.35 0l-2.137-2.337-2.164 2.37a.912.912 0 0 1-1.362 0c-.376-.418-.376-1.086 0-1.493l2.164-2.369-2.136-2.327a1.12 1.12 0 0 1 0-1.481.9.9 0 0 1 1.35 0l2.137 2.337 2.194-2.4a.912.912 0 0 1 1.362 0 1.126 1.126 0 0 1 0 1.492l-2.195 2.4 2.137 2.327zM27.669.042H10.545a.91.91 0 0 0-.744.293L1.226 10.708c-.201.219-.288.511-.274.793-.014.292.073.584.274.803l8.575 10.373a.932.932 0 0 0 .676.302V23h17.192c2.11 0 3.82-1.868 3.82-4.174V4.217c0-2.306-1.71-4.174-3.82-4.174z',
	'delete',
	'0.6',
	'0 0 32 24',
	'scale(1.2)'
);

export const BackspaceIcon = ENABLE_NEW_ICON
	? NewBackspaceIcon
	: oldBackspaceIcon;
