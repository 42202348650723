"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseWallets = exports.parseWallet = void 0;
exports.parseWallet = function (obj) {
    var bonusAmount = obj.bonusAmount ||
        obj.bonus_amount ||
        '0';
    return {
        amount: Number(obj.amount || '0'),
        bonusAmount: Number(bonusAmount)
    };
};
exports.parseWallets = function (wallets) {
    return Object.keys(wallets).reduce(function (acc, currency) {
        acc[currency] = exports.parseWallet(wallets[currency]);
        return acc;
    }, {});
};
