"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedComponentIds = void 0;
/* populate id for each component added */
/* populate id for each component added */
var SharedComponentIds;
(function (SharedComponentIds) {
    SharedComponentIds[SharedComponentIds["CasinoSearchResults"] = 0] = "CasinoSearchResults";
    SharedComponentIds[SharedComponentIds["SportsSearchResults"] = 1] = "SportsSearchResults";
    SharedComponentIds[SharedComponentIds["AccountBalanceButton"] = 2] = "AccountBalanceButton";
    SharedComponentIds[SharedComponentIds["AccountButton"] = 3] = "AccountButton";
    SharedComponentIds[SharedComponentIds["SportsSearchConfigure"] = 4] = "SportsSearchConfigure";
    SharedComponentIds[SharedComponentIds["CasinoSearchConfigure"] = 5] = "CasinoSearchConfigure";
    SharedComponentIds[SharedComponentIds["CasinoSearchFilters"] = 6] = "CasinoSearchFilters";
    SharedComponentIds[SharedComponentIds["SportsSearchFilters"] = 7] = "SportsSearchFilters";
    SharedComponentIds[SharedComponentIds["PlayerCurrentTime"] = 8] = "PlayerCurrentTime";
    SharedComponentIds[SharedComponentIds["SportsZeroMarginWidget"] = 9] = "SportsZeroMarginWidget";
    SharedComponentIds[SharedComponentIds["EsportsPreview"] = 10] = "EsportsPreview";
    SharedComponentIds[SharedComponentIds["CasinoAccountPromotions"] = 11] = "CasinoAccountPromotions";
    SharedComponentIds[SharedComponentIds["NotificationsButton"] = 12] = "NotificationsButton";
})(SharedComponentIds = exports.SharedComponentIds || (exports.SharedComponentIds = {}));
