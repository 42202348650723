/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Selection is used as a part of Submarket
 * Used to build a list of all selections for a given Submarket
 * Note: Same as private selection at present, but separated to prevent
 * sensitive updates in the future from creeping in
 * @export
 * @interface Selection
 */
export interface Selection {
	/**
	 *
	 * @type {number}
	 * @memberof Selection
	 */
	maxStake?: number;
	/**
	 *
	 * @type {number}
	 * @memberof Selection
	 */
	minStake?: number;
	/**
	 *
	 * @type {string}
	 * @memberof Selection
	 */
	outcome?: string;
	/**
	 *
	 * @type {string}
	 * @memberof Selection
	 */
	params?: string;
	/**
	 *
	 * @type {number}
	 * @memberof Selection
	 */
	price?: number;
	/**
	 *
	 * @type {number}
	 * @memberof Selection
	 */
	probability?: number;
	/**
	 *
	 * @type {string}
	 * @memberof Selection
	 */
	side?: string;
	/**
	 *
	 * @type {string}
	 * @memberof Selection
	 */
	status?: string;
}

export function SelectionFromJSON(json: any): Selection {
	return SelectionFromJSONTyped(json, false);
}

export function SelectionFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): Selection {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		maxStake: !exists(json, 'maxStake') ? undefined : json['maxStake'],
		minStake: !exists(json, 'minStake') ? undefined : json['minStake'],
		outcome: !exists(json, 'outcome') ? undefined : json['outcome'],
		params: !exists(json, 'params') ? undefined : json['params'],
		price: !exists(json, 'price') ? undefined : json['price'],
		probability: !exists(json, 'probability')
			? undefined
			: json['probability'],
		side: !exists(json, 'side') ? undefined : json['side'],
		status: !exists(json, 'status') ? undefined : json['status']
	};
}

export function SelectionToJSON(value?: Selection | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		maxStake: value.maxStake,
		minStake: value.minStake,
		outcome: value.outcome,
		params: value.params,
		price: value.price,
		probability: value.probability,
		side: value.side,
		status: value.status
	};
}
