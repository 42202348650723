"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toLocalLanguage = exports.toISO639 = exports.toISO3166 = void 0;
exports.toISO3166 = function (language) {
    var _a = language.split('_'), lang = _a[0], opt = _a[1];
    if (!lang || !opt) {
        return language;
    }
    return lang + '-' + opt.toUpperCase();
};
exports.toISO639 = function (language) {
    var _a = language.split('_'), lang = _a[0], opt = _a[1];
    if (!lang || !opt) {
        return language;
    }
    return language.replace('_', '-').toLowerCase();
};
exports.toLocalLanguage = function (language) {
    return language.replace('-', '_').toLowerCase();
};
