"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsAnyNewNotification = void 0;
var useNotifications_1 = require("../useNotifications");
var notificationsSeenTimestamp_1 = require("../../utils/notificationsSeenTimestamp");
exports.useIsAnyNewNotification = function () {
    var notifications = useNotifications_1.useNotifications();
    var timestamp = notificationsSeenTimestamp_1.getNotificationsSeenTimestamp();
    return (INBOX_ENABLED && (notifications === null || notifications === void 0 ? void 0 : notifications.some(function (notification) { return notification.creationTimestamp > timestamp; })));
};
