"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Select = void 0;
var device_1 = require("@gaming-shell/device");
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
exports.Select = function (_a) {
    var onChange = _a.onChange, onChangeOption = _a.onChangeOption, options = _a.options, _b = _a.value, value = _b === void 0 ? '' : _b, _c = _a.native, native = _c === void 0 ? device_1.isMobile() : _c, selectProps = __rest(_a, ["onChange", "onChangeOption", "options", "value", "native"]);
    var _d = React.useState(value), selected = _d[0], setSelected = _d[1];
    React.useEffect(function () {
        setSelected(value);
    }, [value]);
    var handleChange = React.useCallback(function (_a) {
        var value = _a.target.value;
        var selectedOption = options.find(function (option) { return option.value === value; });
        setSelected(value);
        onChange && onChange(value);
        onChangeOption && onChangeOption(selectedOption);
    }, [onChange, setSelected]);
    var OptionComponent = native ? 'option' : core_1.MenuItem;
    return (React.createElement(core_1.Select, __assign({ native: native, value: selected, onChange: handleChange }, selectProps), options.map(function (_a) {
        var value = _a.value, name = _a.name, option = __rest(_a, ["value", "name"]);
        return (React.createElement(OptionComponent, __assign({ key: value, value: value }, option), name));
    })));
};
