"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Positions = exports.Variants = void 0;
/** @deprecated use string value instead */
exports.Variants = {
    AUTO: 'auto',
    DENSE: 'dense',
    PROMINENT: 'prominent'
};
var Positions;
(function (Positions) {
    Positions["ABSOLUTE"] = "absolute";
    Positions["FIXED"] = "fixed";
    Positions["RELATIVE"] = "relative";
    Positions["STATIC"] = "static";
    Positions["STICKY"] = "sticky";
})(Positions = exports.Positions || (exports.Positions = {}));
