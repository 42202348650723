import {
	CircularProgress,
	Dialog,
	DialogProps,
	Theme,
	makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

import { MoonPayWidget, MoonPayWidgetProps } from '../MoonPayWidget';

type CommonMoonPayDialogProps = DialogProps & MoonPayWidgetProps;
export interface MoonPayDialogProps extends CommonMoonPayDialogProps {
	widgetClassName?: string;
	widgetStyle?: React.CSSProperties;
	onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		minHeight: 'calc(50vh + 10px)',
		minWidth: 475,
		backgroundColor: '#fff',
		justifyContent: 'center',
		alignItems: 'center',
		padding: theme.spacing(1.5),
		[theme.breakpoints.down('xs')]: {
			padding: 0,
			margin: theme.spacing(3),
			minWidth: 'auto',
			maxHeight: '80vh',
			width: '100%',
			backgroundColor: 'transparent'
		}
	},
	loader: {
		position: 'absolute'
	},
	widget: {
		height: '50vh',
		width: 450,
		[theme.breakpoints.down('xs')]: {
			height: '100vh',
			width: '100%',
			borderRadius: theme.shape.borderRadius
		}
	}
}));

export const MoonPayDialog: React.FC<MoonPayDialogProps> = ({
	apiUrl,
	apiKey,
	onSignatureCreation,
	showWalletAddressForm = true,
	externalCustomerId = '',
	currencyCode = '',
	walletAddress = '',
	colorCode,
	widgetClassName,
	widgetStyle,
	onClose,
	withApplePay,
	...rest
}) => {
	const classes = useStyles();
	const dialogClasses = { paper: classes.paper };
	const [widgetReady, setWidgetReady] = React.useState(false);
	const onWidgetReady = React.useCallback(() => {
		setWidgetReady(true);
	}, [setWidgetReady]);
	const handleClose = React.useCallback(() => {
		setWidgetReady(false);
		onClose && onClose();
	}, [onClose, setWidgetReady]);

	return (
		<Dialog classes={dialogClasses} onClose={handleClose} {...rest}>
			{!widgetReady && <CircularProgress className={classes.loader} />}
			<MoonPayWidget
				apiUrl={apiUrl}
				apiKey={apiKey}
				onLoad={onWidgetReady}
				showWalletAddressForm={showWalletAddressForm}
				externalCustomerId={externalCustomerId}
				currencyCode={currencyCode}
				walletAddress={walletAddress}
				onSignatureCreation={onSignatureCreation}
				colorCode={colorCode}
				className={clsx(widgetClassName, classes.widget)}
				style={widgetStyle}
				withApplePay={withApplePay}
			/>
		</Dialog>
	);
};
