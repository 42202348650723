"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToggle = void 0;
var react_1 = require("react");
exports.useToggle = function (initial) {
    if (initial === void 0) { initial = false; }
    var _a = react_1.useState(initial), value = _a[0], setToggle = _a[1];
    var onToggle = react_1.useCallback(function () {
        setToggle(function (value) { return !value; });
    }, [setToggle]);
    return [value, onToggle, setToggle];
};
