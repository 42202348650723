"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var core_1 = require("@material-ui/core");
exports.useStyles = core_1.makeStyles(function (theme) { return ({
    icon: {
        color: theme.palette.primary.main
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(2, 0, 1, 0)
    },
    button: __assign(__assign({}, theme.typography.h6), { textTransform: 'none' }),
    seeAll: {
        flexGrow: 1,
        textAlign: 'right'
    },
    leftArrowButton: {
        marginLeft: theme.spacing(0.5)
    }
}); });
