import { toLocalLanguage, useChangeLanguage } from '@gaming-shell/i18n';
import {
	useJoinMessageSnackbar,
	useUpdateAnnouncements
} from '@gaming-shell/layout';
import { setLanguageInPath } from '@gaming-shell/routing';
import {
	api,
	ApiAnnouncement,
	GetAnnouncementsResponse200
} from '@materialbet-core/api';
import { useRefreshPlayerContext } from '@materialbet-core/auth';
import {
	usePlayerAuthToken,
	usePlayerForcedRoutes,
	usePlayerLoading,
	usePlayerLocale,
	usePlayerLoggedIn,
	useUpdatePlayer
} from '@materialbet-core/player';
import csrfTokenInterceptor from 'core/api/player/csrfTokenInterceptor';
import ForcedRedirections from 'core/components/Util/ForcedRedirections';
import Notify from 'core/components/Util/Notify';
import SessionTracker from 'core/components/Util/SessionTracker';
import {
	getPersistedCurrencyVariant,
	getPersistedSportsView
} from 'core/utils/cookies';
import { usePrevious } from 'materialbet-common';
import * as React from 'react';
import { useLocation } from 'react-router';
import { useLoadMinDepositAmountsEffect } from './api/hooks/useLoadMinDepositAmountsEffect';
import { registerSharedComponents } from './sharedComponents';
import {
	registerLanguageChange,
	unregisterLanguageChange
} from './subscribers';
import { parseApiAnnouncement } from './utils/announcementsParser';

import { PusherHandlers } from './components/SideEffects/PusherHandlers';
import noop from 'lodash/noop';
import { useLoadNotificationsEffect } from 'materialbet-inbox';
import { useFetchKycDetails } from './pages/Account/Verification/PersonalVerificationPage/useFetchKycDetails';

const unregister = csrfTokenInterceptor();
window.addEventListener('onbeforeunload', () => {
	unregister();
});

registerSharedComponents();
export const SideEffects: React.FunctionComponent = () => {
	const updatePlayer = useUpdatePlayer();
	const isLoggedIn = usePlayerLoggedIn();
	const { pathname } = useLocation();
	const setAnnouncements = useUpdateAnnouncements();
	const refreshPlayerContext = useRefreshPlayerContext();
	const { fetchKycDetails } = useFetchKycDetails();
	const authToken = usePlayerAuthToken();
	const isPlayerLoading = usePlayerLoading();
	const playerLocale = usePlayerLocale();
	const changeLanguage = useChangeLanguage();

	const forcedRoutes = usePlayerForcedRoutes();
	const prevLoggedIn = usePrevious(isLoggedIn);

	/* this subscribes on language change and updates it on db side */
	React.useEffect(() => {
		if (isLoggedIn && forcedRoutes.onboardingStatuses.signUp && authToken) {
			registerLanguageChange(authToken);
		}
	}, [isLoggedIn, authToken, forcedRoutes.onboardingStatuses.signUp]);
	/* this unsubscribes when user logs out from the app */
	React.useEffect(() => {
		if (!isLoggedIn) {
			unregisterLanguageChange();
		}
	}, [isLoggedIn]);

	/* this will set current language according to user settings */
	React.useEffect(() => {
		if (!isPlayerLoading && prevLoggedIn !== isLoggedIn && isLoggedIn) {
			const playerLanguage = toLocalLanguage(playerLocale);
			changeLanguage(playerLanguage);
			setLanguageInPath(playerLanguage);
		}
	}, [
		isPlayerLoading,
		changeLanguage,
		playerLocale,
		prevLoggedIn,
		isLoggedIn
	]);

	useJoinMessageSnackbar(pathname);
	React.useEffect(() => {
		updatePlayer((player) => ({
			...player,
			currencyVariant: getPersistedCurrencyVariant(),
			preferredSportsView: getPersistedSportsView() || 'european'
		}));
	}, [updatePlayer]);

	React.useEffect(() => {
		refreshPlayerContext();
		// we call it only on initial render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		PLAYER_KYC_ENABLED && isLoggedIn && fetchKycDetails();
	}, [isLoggedIn, fetchKycDetails]);

	useLoadNotificationsEffect();

	React.useEffect(() => {
		api.getAnnouncements()
			.then((response) => {
				const notifications = (response as GetAnnouncementsResponse200)
					.notifications;
				if (notifications.length) {
					const announcements = notifications.map(
						(announcement: ApiAnnouncement) =>
							parseApiAnnouncement(announcement)
					);
					setAnnouncements(announcements);
				}
			})
			.catch(noop);
	}, [setAnnouncements]);

	useLoadMinDepositAmountsEffect();

	return (
		<>
			<SessionTracker />
			<Notify />
			<ForcedRedirections />
			<PusherHandlers />
		</>
	);
};
