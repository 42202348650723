import { isMobile } from '@gaming-shell/device';
import { Box, Divider, makeStyles, Theme } from '@material-ui/core';
import clx from 'classnames';
import * as React from 'react';
import { BetPriceIndicator } from 'sports/components/displays/BetPrice';
import { SelectionAdditionalInfo } from 'sports/components/SelectionAdditionalInfo';
import { CurrencyVariantConfig } from 'sports/config/currency';
import { bitsIncludeSome, mapActiveBits } from 'sports/utils/bitmaskUtils';
import {
	BetslipSelectionPusherArgs,
	useSelectionLevelPusherUpdate
} from 'sports/utils/pusherUtils';

import { BetslipError } from '../BetslipError';
import {
	BetslipStatusFlags,
	maxBits
} from '../betslipReducer/BetslipStatusFlags';
import { BetslipSelectionResult } from '../BetslipSelectionResult';
import {
	getChangedSelectionPrice,
	getIsSubmitting,
	getSelection,
	getTotalSelectionStatus
} from '../betslipSelectors/betslipSelectors';
import { useBetslipSelector } from '../hooks';
import { BetslipReducerStatus, StakeLocation } from '../types';
import { BigBetslipRemoveSelection } from './BigBetslipRemoveSelection';
import { BigBetslipSelectionInfo } from './BigBetslipSelectionInfo';
import { BigBetslipSelectionStakeInput } from './BigBetslipSelectionStakeInput';
import { CurrentEditStakeView } from './types';

// TODO: add props for editing and delete betslip
export interface UiBigBetslipSelectionProps {
	errors: BetslipReducerStatus;
	disableStakeInput?: boolean;
	price: number;
	changedPrice?: number;
	selectionIndex: number;
	stake: number;
	outcomeName: string;
	eventName: string;
	marketName: string;
	isSubmitting: boolean;
	currencyConfig: CurrencyVariantConfig;
	minStake: number;
	maxStake: number;
	setCurrentEditStakeView: (view?: CurrentEditStakeView) => void;
}
export type BigBetslipSelectionProps = Omit<
	UiBigBetslipSelectionProps,
	| 'errors'
	| 'price'
	| 'marketName'
	| 'outcomeName'
	| 'eventName'
	| 'isSubmitting'
	| 'stake'
	| 'changedPrice'
	| 'maxStake'
	| 'minStake'
> & { onSelectionUpdate: BetslipSelectionPusherArgs['onSelectionUpdate'] };
interface StyleProps {
	highlight: boolean;
	showStakeInput: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2, 2, 2, 0),
		gridTemplateColumns: `[option-start] 32px [info-start] auto [info-end] ${theme.spacing(
			2
		)}px [stake-start] 100px [stake-end]`
	},
	highlight: {
		backgroundColor: theme.palette.background.default,
		borderRight: `solid 1px ${theme.palette.error.main}`,
		margin: '1px 0'
	},
	content: {
		paddingLeft: theme.spacing(1)
	},
	removeIcon: ({ highlight }: StyleProps) => ({
		alignSelf: 'flex-start',
		color: highlight
			? theme.palette.primary.main
			: theme.palette.text.secondary
	}),
	info: ({ showStakeInput }: StyleProps) => {
		return {
			overflow: 'hidden',
			gridColumnStart: 'info-start',
			gridColumnEnd: `${showStakeInput ? 'info-end' : 'stake-end'}`
		};
	},
	divider: {
		marginLeft: `calc(24px + ${theme.spacing(1)}px)`
	},
	error: {
		gridColumn: 'option-start / stake-end',
		paddingLeft: theme.spacing(1)
	}
}));

let UiBigBetslipSelection: React.FunctionComponent<UiBigBetslipSelectionProps> = (
	props
) => {
	const {
		disableStakeInput,
		errors,
		selectionIndex,
		eventName,
		outcomeName,
		marketName,
		isSubmitting,
		price,
		stake,
		currencyConfig,
		setCurrentEditStakeView,
		changedPrice,
		minStake,
		maxStake
	} = props;
	const stakeLocation = React.useMemo<StakeLocation>(
		() => ({
			straight: { selectionIndex }
		}),
		[selectionIndex]
	);
	const highlight = bitsIncludeSome(
		BetslipStatusFlags.HighlightErrors,
		errors
	);
	const showStakeInput = !disableStakeInput && !highlight;
	const [showAddStake, setShowAddStake] = React.useState(
		!stake && !disableStakeInput
	);
	const classes = useStyles({ showStakeInput, highlight });
	const onAddStakeClick = () => {
		if (isMobile()) {
			setCurrentEditStakeView({ straight: { selectionIndex } });
		}
		setShowAddStake(false);
	};
	React.useEffect(() => {
		if (showAddStake && stake) {
			setShowAddStake(false);
		}
	}, [stake, showAddStake]);
	return (
		<>
			<Box
				display="grid"
				alignItems="center"
				position="relative"
				overflow="hidden"
				className={clx(classes.root, {
					[classes.highlight]: highlight
				})}
			>
				<BigBetslipRemoveSelection
					selectionIndex={selectionIndex}
					className={classes.removeIcon}
					color={highlight ? 'primary' : 'default'}
				/>
				<Box className={classes.info}>
					<BigBetslipSelectionInfo
						price={price}
						changedPrice={changedPrice}
						outcomeName={outcomeName}
						eventName={eventName}
						marketName={marketName}
					/>
					<SelectionAdditionalInfo selectionIndex={selectionIndex} />
				</Box>
				<Box />
				<Box>
					{showStakeInput && (
						<>
							<BigBetslipSelectionStakeInput
								stake={stake}
								selectionIndex={selectionIndex}
								disabled={isSubmitting}
								currencyConfig={currencyConfig}
								onAddStakeClick={onAddStakeClick}
								showAddStake={showAddStake}
								setCurrentEditStakeView={
									setCurrentEditStakeView
								}
							/>
							{!showAddStake && (
								<BetslipSelectionResult
									price={price}
									stake={stake}
									variant="vertical"
									label="descriptions.win"
									currencyConfig={currencyConfig}
								/>
							)}
						</>
					)}
				</Box>
				{mapActiveBits(errors, maxBits, (e) => (
					<BetslipError
						error={e}
						minStake={minStake}
						maxStake={maxStake}
						className={classes.error}
						key={e}
						stakeLocation={stakeLocation}
						changedPrice={changedPrice}
					/>
				))}
				<BetPriceIndicator odds={changedPrice || price} side="back" />
			</Box>
			<Divider className={classes.divider} />
		</>
	);
};
UiBigBetslipSelection = React.memo(UiBigBetslipSelection);

let BigBetslipSelection: React.FunctionComponent<BigBetslipSelectionProps> = (
	props
) => {
	const { selectionIndex, onSelectionUpdate } = props;
	const selection = useBetslipSelector((state) =>
		getSelection(state, { selectionIndex })
	);
	const status = useBetslipSelector((state) => {
		return getTotalSelectionStatus(state, { selectionIndex });
	});
	const isSubmitting = useBetslipSelector(getIsSubmitting);
	useSelectionLevelPusherUpdate({
		params: (selection && selection.params) || '',
		outcome: (selection && selection.outcome) || '',
		eventId: (selection && selection.eventId) || 0,
		marketKey: (selection && selection.marketKey) || '',
		submarketKey: (selection && selection.submarketKey) || '',
		onSelectionUpdate
	});
	const changedPrice = useBetslipSelector((state) =>
		getChangedSelectionPrice(state, { selectionIndex })
	);
	if (!selection) {
		return null;
	}
	const {
		price,
		marketName,
		outcomeName,
		eventName,
		stake,
		minStake,
		maxStake
	} = selection;
	return (
		<UiBigBetslipSelection
			stake={stake}
			minStake={minStake}
			maxStake={maxStake}
			price={price}
			errors={status}
			marketName={marketName}
			outcomeName={outcomeName}
			eventName={eventName}
			isSubmitting={isSubmitting}
			changedPrice={changedPrice}
			{...props}
		/>
	);
};
BigBetslipSelection = React.memo(BigBetslipSelection);
export { BigBetslipSelection, UiBigBetslipSelection };
