import { Theme } from '@gaming-shell/theming';
import { ButtonBase, makeStyles } from '@material-ui/core';
import { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import classNames from 'classnames';
import * as React from 'react';

const useButtonBaseStyles = makeStyles((theme: Theme) => ({
	root: {
		flexDirection: 'row', // previously column
		alignItems: 'left',
		backgroundColor: theme.palette.background.top.main, // todo this should be top
		color: theme.palette.text.primary,
		padding: theme.spacing(1),
		justifyContent: 'left',
		height: '100%',
		borderRadius: '4px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		width: '100%',
		'&:hover': {
			backgroundColor:
				// Dark themes - the hover goes lighter.
				// Light themes the hover goes darker
				theme.palette.type === 'dark'
					? theme.palette.background.top.light
					: theme.palette.background.top.dark,
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
				backgroundColor: theme.palette.background.top.main
			}
		}
	},
	active: {
		background: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
				backgroundColor: theme.palette.primary.main
			}
		}
	}
}));
export interface TopButtonProps {
	active?: boolean;
}

export const TopButton: React.FunctionComponent<ButtonBaseProps &
	TopButtonProps> = (props) => {
	const classes = useButtonBaseStyles();
	const { active, ...rest } = props;
	return (
		<ButtonBase
			{...rest}
			className={classNames(classes.root, props.className, {
				[classes.active]: active
			})}
		>
			{props.children}
		</ButtonBase>
	);
};
