import { Switch, SwitchProps as MuiSwitchProps } from '@material-ui/core';
import * as React from 'react';

import { Row, Typography, TypographyProps } from '../../';

export interface ToggleProps {
	/** Style applied to root element */
	className?: string;
	LeftLabelProps?: TypographyProps;
	RightLabelProps?: TypographyProps;
	SwitchProps?: SwitchProps;
	leftLabel?: React.ReactNode;
	rightLabel?: React.ReactNode;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	checked?: boolean;
	disabled?: boolean;
}

export type SwitchProps = Omit<MuiSwitchProps, 'checked' | 'onChange'>;

export const Toggle: React.FC<ToggleProps> = ({
	className,
	LeftLabelProps,
	RightLabelProps,
	SwitchProps,
	leftLabel,
	rightLabel,
	onChange,
	checked: checkedProp = false
}) => {
	const [checked, setChecked] = React.useState<boolean>(checkedProp);

	React.useEffect(() => {
		setChecked(checkedProp);
	}, [checkedProp]);

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			onChange(event);
			setChecked(event.target.checked);
		},
		[onChange, setChecked]
	);

	return (
		<Row alignItems="center" className={className}>
			{leftLabel && (
				<Typography
					color="textSecondary"
					variant="body2"
					{...LeftLabelProps}
				>
					{leftLabel}
				</Typography>
			)}
			<Switch
				checked={checked}
				onChange={handleChange}
				{...SwitchProps}
			/>
			{rightLabel && (
				<Typography
					color="textSecondary"
					variant="body2"
					{...RightLabelProps}
				>
					{rightLabel}
				</Typography>
			)}
		</Row>
	);
};
