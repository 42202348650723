import { useLanguage } from '@gaming-shell/i18n';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import * as React from 'react';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { DayDisplay } from 'sports/components/displays/Date';
import { useTimeZone } from 'sports/utils/hooks';
import { SportsRoutes } from 'materialbet-common';
import { SearchResultItem } from 'sports/types/sharedComponents';

export interface SportSearchResultItemProps extends SearchResultItem {
	skeleton?: boolean;
	imageUrl?: string;
}

export interface SearchResultSkeletonItemProps {
	skeleton: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flex: `1 1 100%`,
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'nowrap',
		paddingBottom: theme.spacing(2.5),
		padding: theme.spacing(0, 0.5),
		textDecoration: 'none'
	},
	title: {
		paddingTop: theme.spacing(0.5)
	},
	icon: {
		color: theme.palette.text.secondary,
		fontSize: theme.typography.caption.fontSize
	}
}));

export const SportSearchResultItem = <T extends {}>({
	id,
	startsAt,
	sportKey,
	name,
	competitions,
	skeleton,
	type
}: T extends SearchResultSkeletonItemProps
	? T
	: SportSearchResultItemProps) => {
	const classes = useStyles();
	const language = useLanguage();
	const timeZone = useTimeZone();
	/* adjust unix timestamp(seconds) to js timestamp (milliseconds) */
	const startTime = startsAt && new Date(startsAt * 1000);
	const competition = competitions?.[0];
	const competitionKey = competition?.key;
	const competitionName = competition?.name?.[language] ?? '';
	const isOutright = type === 'OUTRIGHT';
	const urlTemplate = isOutright
		? SportsRoutes.outright
		: SportsRoutes.eventWithKey;
	if (skeleton) {
		return (
			<div className={classes.root}>
				<div>
					<Skeleton
						variant="text"
						className={classes.title}
						width={250}
					/>
					<Skeleton variant="text" width={150} />
				</div>
			</div>
		);
	}

	return (
		<Link
			className={classes.root}
			to={urlTemplate.create({
				eventId: id,
				sportKey,
				competitionKey,
				eventKey: '-'
			})}
		>
			<div>
				<Typography
					className={classes.title}
					variant="body2"
					color="textPrimary"
				>
					{/* Take translated version if exist */}
					{name?.[language] || name?.[FALLBACK_LANGUAGE]}
				</Typography>
				{startTime && (
					<Typography variant="caption" color="textSecondary">
						{competitionName && `${competitionName} - `}
						<DayDisplay
							date={startTime}
							timeZone={timeZone}
							dateFormat="MMMM dd"
						/>
					</Typography>
				)}
			</div>
			<div>
				{/* todo add stakes rates here */}
				<ArrowForwardIosIcon className={classes.icon} />
			</div>
		</Link>
	);
};
