import { Platform } from '@gaming-shell/device';
import { GetGamesResponse, GetMyGamesResponse } from 'casino/api/casino';
import { GameEntity, games } from 'casino/casinoSchemas';
import { GameTagType } from 'casino/config/gameTags/types';
import { CasinoEpicDependencies } from 'casino/store/';
import { FilterList, GameType } from 'casino/types/casinoApiTypes';
import { createCasinoFetchEntityEpic } from 'casino/util/epicsUtil';
import { isFavorite } from 'casino/util/gamesUtils/isFavorite';
import { createGamesMeta } from 'casino/util/metaUtils';
import { createEntityReducer } from 'casino/util/reducerUtils';
import { finishLoading } from 'materialbet-common';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, interval, NEVER, of } from 'rxjs';
import { debounce, map, mergeMap } from 'rxjs/operators';
import { ActionType, createAction, getType } from 'typesafe-actions';

export interface GameParams {
	playerUuid: string;
	gameId: string;
	currency: string;
	platform: Platform;
	language: string;
}
export interface GamesParams {
	playerUuid: string;
	category: 'all' | 'recommendation';
	platform: Platform;
	currency: string;
	live?: boolean;
	language: string;
	page: number;
	tags?: string[];
	pageSize: number;
	sectionId?: string;
	list?: FilterList;
	studioKey?: string;
	themeKeys?: string[];
	type?: GameType[];
	sort?: Array<
		| 'name'
		| '-name'
		| 'recommendation'
		| '-recommendation'
		| 'popularity'
		| '-popularity'
		| 'biggest_possible_win'
		| '-biggest_possible_win'
		| 'id'
		| '-id'
		| 'featured_order'
		| '-featured_order'
	>;
	lobby?: boolean;
}
export interface MyGamesParams {
	playerUuid: string;
	platform: Platform;
	currency: string;
	language: string;
	page: number;
	pageSize: number;
	tab?: 'favourites' | 'recommended'; // Not required by api
}
export interface SetFavoriteParams {
	gameId: string;
	isFavorite: boolean;
	playerUuid: string;
}
export const getGameKey = (params: GameParams) =>
	`${params.playerUuid}_${params.gameId}_${params.currency}_${params.platform}_${params.language}`;
export const getGamesKey = (params: GamesParams) =>
	`${params.playerUuid}_${params.category}_${params.platform}_${
		params.currency
	}_${params.live}_${params.language}_${params.page}_${
		params.pageSize
	}_[${params.tags?.join(',')}]_[${params.type?.join(',')}]_${
		params.sectionId
	}_${params.list}_${params.studioKey}_[${params.sort?.join(',')}]_${
		params.lobby
	}_[${params.themeKeys?.join(',')}]`;
export const getMyGamesKey = (params: MyGamesParams) =>
	`${params.playerUuid}_${params.platform}_${params.currency}_${params.language}_${params.page}_${params.pageSize}_${params.tab}`;
export const loads = {
	game: (params: GameParams) => `games/game_${getGameKey(params)}`,
	games: (params: GamesParams) => `games/games_${getGamesKey(params)}`,
	myGames: (params: MyGamesParams) => `games/myGames_${getMyGamesKey(params)}`
};
export const actions = {
	fetchGame: createAction(
		'games/fetchGame',
		(resolve) => (payload: GameParams) => resolve(payload)
	),
	fetchGames: createAction(
		'games/fetchGames',
		(resolve) => (payload: GamesParams) => resolve(payload)
	),
	fetchMyGames: createAction(
		'games/fetchMyGames',
		(resolve) => (payload: MyGamesParams) => resolve(payload)
	),
	setFavorite: createAction(
		'games/setFavorite',
		(resolve) => (payload: SetFavoriteParams) => resolve(payload)
	),
	postFavorite: createAction(
		'games/postFavorite',
		(resolve) => (payload: SetFavoriteParams) => resolve(payload)
	)
};

const gamesEntityReducer = createEntityReducer<GameEntity>(games.key);
type GamesState = Parameters<typeof gamesEntityReducer>[0];
const setFavorite = (
	state: GamesState = {},
	action: ActionType<typeof actions.setFavorite | typeof actions.postFavorite>
) => {
	const game = state[action.payload.gameId];
	if (!game) {
		return state;
	}
	const gameIsFavorite = isFavorite(game);
	if (gameIsFavorite === action.payload.isFavorite) {
		return state;
	}
	let newGame = game;
	if (action.payload.isFavorite) {
		newGame = {
			...game,
			tags: [...(game.tags || []), GameTagType.favorite]
		};
	} else {
		newGame = {
			...game,
			tags: game.tags?.filter((t) => t !== GameTagType.favorite)
		};
	}
	return { ...state, [action.payload.gameId]: newGame };
};

export const gamesReducer: typeof gamesEntityReducer = (
	state: GamesState = {},
	action: ActionType<typeof actions>
): ReturnType<typeof gamesEntityReducer> => {
	switch (action.type) {
		case getType(actions.setFavorite):
		case getType(actions.postFavorite):
			return setFavorite(state, action);
		default:
			return gamesEntityReducer(state, action);
	}
};

/** @description Fetches a single game */
export const getGameEpic = createCasinoFetchEntityEpic({
	actionCreator: actions.fetchGame,
	fetch: (payload, { api }) => {
		const promise = api.games.v1PlayersPlayerUuidGamesGameUuidGet(
			payload.playerUuid,
			payload.gameId,
			undefined, // country
			payload.currency,
			payload.platform,
			payload.language
		);
		return promise;
	},
	resultSchema: games,
	createMeta: ({ payload }) => finishLoading(loads.game(payload))
});

export const getGamesEpic = createCasinoFetchEntityEpic<
	GamesParams,
	GetGamesResponse
>({
	actionCreator: actions.fetchGames,
	fetch: (payload, { api }) => {
		const promise = api.games.v1PlayersPlayerUuidGamesGet(
			payload.playerUuid,
			payload.category,
			BRAND_NAME as 'materialbet',
			undefined, // country
			payload.currency,
			undefined,
			payload.live,
			payload.platform,
			!ENABLE_SECTIONLESS_SECTION_PAGES && !payload.tags?.length
				? payload.sectionId
				: undefined,
			payload.studioKey,
			payload.tags,
			payload.themeKeys,
			undefined,
			payload.type,
			payload.list,
			payload.language,
			payload.sort,
			payload.page,
			payload.pageSize,
			payload.lobby
		);
		return promise;
	},
	resultSchema: [games],
	resultSelector: (result) => result.list,
	createMeta: ({ payload, result }) => {
		const loading = finishLoading(loads.games(payload));
		const casino = createGamesMeta(result, getGamesKey(payload));
		return { ...loading, ...casino };
	}
});
export const getMyGamesEpic = createCasinoFetchEntityEpic<
	MyGamesParams,
	GetMyGamesResponse
>({
	actionCreator: actions.fetchMyGames,
	fetch: (payload, { api }) => {
		const promise = api.games.v1PlayersPlayerUuidMyCasinoGamesGet(
			payload.playerUuid,
			undefined, // country
			payload.currency,
			payload.platform,
			payload.language,
			payload.pageSize,
			payload.page,
			payload.tab
		);
		return promise;
	},
	resultSchema: [games],
	resultSelector: (result) => result.list,
	createMeta: ({ payload, result }) => {
		const loading = finishLoading(loads.myGames(payload));
		const casino = createGamesMeta(result, getMyGamesKey(payload));
		return { ...loading, ...casino };
	}
});

/** @descriptions handles postFavorite. It will debounce any input action for 1 second, just in case someone goes apeshit on the favorite button.
 * This prevents a) spamming the api, b) having the button go full disco when the api responses come in (we dont keep track on api response and request or anything atm,
 * although we could do that). In synchronous rendering the value on the screen always corresponds to the action (assuming we just dispatch the action with the opposite boolean):
 * E.g. if game is favorited then the action would be correctly have isFavorite: false - as the intention is to toggle it.

 */
export const setFavoriteEpic: Epic<
	ActionType<typeof actions.postFavorite | typeof actions.setFavorite>,
	ActionType<typeof actions.setFavorite>,
	unknown,
	CasinoEpicDependencies
> = (action$, _, { api }) =>
	action$.pipe(
		debounce(() => interval(1000)),
		mergeMap((a) =>
			of(a).pipe(
				ofType(getType(actions.postFavorite)),
				mergeMap(({ payload }) => {
					const apiAction = payload.isFavorite
						? api.favorites
								.v1PlayersPlayerUuidFavouritesGamesGameUuidPut
						: api.favorites
								.v1PlayersPlayerUuidFavouritesGamesGameUuidDelete;
					apiAction(payload.playerUuid, payload.gameId).catch(() => ({
						...payload,
						isFavorite: !payload.isFavorite
					}));
					return from(NEVER); // this epic will never emit another action
				}),
				map(actions.setFavorite)
			)
		)
	);

export const gamesEpic = combineEpics(
	getGamesEpic,
	getGameEpic,
	getMyGamesEpic,
	setFavoriteEpic
);
