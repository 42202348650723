/** Creates a sports route */
import { error } from '@gaming-shell/logging';

/** @description removes the sport key from the competitionkey, so those values can be used for building nice looking routes */
export const createCompetitionRouteParams = (params: {
	sportKey: string | number;
	competitionKey: string | number;
}) => {
	if (typeof params.competitionKey === 'number') {
		return params;
	}
	const competionKeyWithoutSport = params.competitionKey.replace(
		params.sportKey + '-',
		''
	);
	if (params.competitionKey === competionKeyWithoutSport) {
		error(
			`sportKey '${params.sportKey}' not part of competitionKey: '${params.competitionKey}'`
		);
	}
	return { ...params, competitionKey: competionKeyWithoutSport };
};
/** @description Takes sport key and competition key as they are in the route, and adds the sportkey to the competition key*/
export const getCompetitionRouteParams = (params: {
	sportKey: string;
	competitionKey: string;
}) => {
	const competitionKeyWithSport = `${params.sportKey}-${params.competitionKey}`;
	return { ...params, competitionKey: competitionKeyWithSport };
};
