import { Action } from 'redux';
import { Epic, combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { delay, map, mergeMap, retryWhen, take } from 'rxjs/operators';
import { SportsPlayer } from 'sports/api/sportsplayers/api';
import { attempts, timeBetweenAttempts } from 'sports/config/sportsplayers';
import { FinishLoadingMeta } from 'sports/modules/loading';
import { EpicDependencies } from 'sports/store';
import { ActionType, createAction, getType } from 'typesafe-actions';

export const loads = {
	update: 'sportsPlayer/update'
};

export const actions = {
	fetch: createAction('sportsPlayer/fetch', (resolve) => () => {
		return resolve();
	}),
	update: createAction(
		'sportsPlayer/update',
		(resolve) => (payload: SportsPlayer) =>
			resolve(payload, {
				load: {
					name: loads.update,
					receivedAt: new Date().getTime()
				} as FinishLoadingMeta
			})
	)
};
const defaultSportsPlayer: SportsPlayer = {
	stakeFactor: 1
};
export type SportsPlayerActions = ActionType<typeof actions>;
export default (
	state: SportsPlayer = defaultSportsPlayer,
	action: SportsPlayerActions
) => {
	switch (action.type) {
		case getType(actions.update): {
			return action.payload;
		}
		default:
			return state;
	}
};

const getPlayerDetails: Epic<
	Action<string>,
	Action<string>,
	object,
	EpicDependencies
> = (action$, _, dependencies) => {
	return action$.pipe(
		mergeMap((a) =>
			of(a).pipe(
				ofType(getType(actions.fetch)),
				mergeMap(() => from(dependencies.api.sportsplayers.getMe())),
				map((response) => {
					if (!response) {
						throw Error('Sports player response was empty');
					}
					return actions.update(response);
				}),
				retryWhen((errors) =>
					errors.pipe(delay(timeBetweenAttempts), take(attempts))
				)
			)
		)
	);
};

export const sportsPlayerEpic = combineEpics(getPlayerDetails);
