"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckMfa = void 0;
var device_1 = require("@gaming-shell/device");
var i18n_1 = require("@gaming-shell/i18n");
var routing_1 = require("@gaming-shell/routing");
var api_1 = require("@materialbet-core/api");
var player_1 = require("@materialbet-core/player");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var react_router_1 = require("react-router");
var __1 = require("../..");
var CheckMfaLayout_1 = require("./CheckMfaLayout");
var layout_1 = require("@gaming-shell/layout");
var validations = function (trans) { return ({
    code: [
        {
            isValid: function (value) {
                return value ? value.toString().length === 6 : true;
            },
            message: trans('common:descriptions.valueDigitLength', { value: 6 })
        }
    ]
}); };
exports.CheckMfa = function () {
    var _a = React.useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var trans = i18n_1.useTranslation();
    var locationState = react_router_1.useLocation().state;
    var enqueueSnackbar = materialbet_common_1.useSnackbar().enqueueSnackbar;
    var authToken = player_1.usePlayerAuthToken();
    var isLoggedIn = player_1.usePlayerLoggedIn();
    var updatePlayer = player_1.useUpdatePlayer();
    var refreshPlayerContext = __1.useRefreshPlayerContext();
    var checkMfa = function (csrfToken) { return function (code) {
        setIsSubmitting(true);
        // We're confident that locationState isn't undefined when this code is executed.
        // When locationState is undefined then we're redirecting to Sign In page -> user can't see Mfa form so he won't trigger this function
        api_1.api.postCheckMfa(code, (locationState === null || locationState === void 0 ? void 0 : locationState.ref) || '', csrfToken)
            .then(function (response) {
            var token = response
                .authenticity_token;
            if (response.result === 'INVALID_AUTHENTICITY_TOKEN') {
                updatePlayer(function (player) { return (__assign(__assign({}, player), { authToken: token })); });
                // setTimeout ensures that the next sign in placed in an event queue which is called after this method returned (js version of defer)
                setTimeout(function () { return checkMfa(token)(code); });
                return;
            }
            var backRoute = locationState && locationState.backRoute;
            if (response.result === 'OK') {
                refreshPlayerContext(response);
                enqueueSnackbar(trans('player:signIn.success'));
                routing_1.history.push(backRoute || materialbet_common_1.BaseRoutes.root.create());
            }
            else if (response.result === '2FA_INVALID') {
                enqueueSnackbar(trans('player:withdrawal.invalidMfa'));
                var mfaRef = response['2fa_ref'];
                routing_1.history.replace(location.pathname, {
                    backRoute: backRoute,
                    ref: mfaRef
                });
            }
            else if (response.result === 'NOT_ALLOWED') {
                routing_1.history.push(materialbet_common_1.AuthRoutes.signIn.create(), {
                    backRoute: backRoute
                });
                enqueueSnackbar(trans('player:mfa.mfaExpired'));
            }
        })
            .catch(function () {
            enqueueSnackbar(trans('player:descriptions.failedRequest'));
        })
            .finally(function () { return setIsSubmitting(false); });
    }; };
    return (React.createElement(React.Fragment, null,
        React.createElement(layout_1.DefaultAppBar, { type: device_1.isDesktop() ? 'home' : 'window', title: trans('common:actions.signIn') }),
        isLoggedIn && React.createElement(react_router_1.Redirect, { to: materialbet_common_1.BaseRoutes.root.create() }),
        !locationState && React.createElement(react_router_1.Redirect, { to: materialbet_common_1.AuthRoutes.signIn.create() }),
        React.createElement(__1.AuthWrapp, { fullScreen: true },
            React.createElement(__1.SingleColumnLayout, { title: React.createElement(i18n_1.Translate, { label: "actions.signIn", ns: "common" }) },
                React.createElement(CheckMfaLayout_1.CheckMfaLayout, { loginInputType: "email", mfaLabel: React.createElement(i18n_1.Translate, { label: "withdrawal.mfaCode", ns: "player" }), extraText: React.createElement(i18n_1.Translate, { label: "mfa.lostMfa", ns: "player" }), extraButtonText: React.createElement(i18n_1.Translate, { label: "mfa.resetMfaSubmit", ns: "player" }), extraButtonRoute: materialbet_common_1.AuthRoutes.reset2fa.create(), onSubmitClick: checkMfa(authToken), submitButtonText: React.createElement(i18n_1.Translate, { label: "actions.signIn", ns: "common" }), validations: validations(trans), isSubmitting: isSubmitting })))));
};
