/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
	SportWithCompetition,
	SportWithCompetitionFromJSON,
	SportWithCompetitionFromJSONTyped,
	SportWithCompetitionToJSON
} from './';

/**
 * TopCouponsResponse presents a single top coupon
 * @export
 * @interface TopCouponsResponse
 */
export interface TopCouponsResponse {
	/**
	 *
	 * @type {string}
	 * @memberof TopCouponsResponse
	 */
	key?: string;
	/**
	 *
	 * @type {string}
	 * @memberof TopCouponsResponse
	 */
	name?: string;
	/**
	 *
	 * @type {SportWithCompetition}
	 * @memberof TopCouponsResponse
	 */
	sport?: SportWithCompetition;
}

export function TopCouponsResponseFromJSON(json: any): TopCouponsResponse {
	return TopCouponsResponseFromJSONTyped(json, false);
}

export function TopCouponsResponseFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): TopCouponsResponse {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		key: !exists(json, 'key') ? undefined : json['key'],
		name: !exists(json, 'name') ? undefined : json['name'],
		sport: !exists(json, 'sport')
			? undefined
			: SportWithCompetitionFromJSON(json['sport'])
	};
}

export function TopCouponsResponseToJSON(
	value?: TopCouponsResponse | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		key: value.key,
		name: value.name,
		sport: SportWithCompetitionToJSON(value.sport)
	};
}
