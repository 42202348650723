export interface OnPollPayload {
	attempt: number;
	stopPolling: () => void;
}
export type OnPoll = (payload: OnPollPayload) => void;
export type OnTimeOut = () => void;
export interface StartPollingPayload {
	onPoll: OnPoll;
	interval: number;
	timeout?: number;
	onTimeout?: OnTimeOut;
}

export const startPolling = ({
	onPoll,
	interval,
	timeout,
	onTimeout
}: StartPollingPayload) => {
	let attempt = 1;
	let intervalId: NodeJS.Timeout | undefined = undefined;
	let timeoutId: NodeJS.Timeout | undefined = undefined;
	const stopPolling = () => {
		if (intervalId) {
			clearInterval(intervalId);
		}
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
	};
	intervalId = setInterval(() => {
		attempt++;
		onPoll({ attempt, stopPolling });
	}, interval);

	if (timeout) {
		timeoutId = setTimeout(() => {
			stopPolling();
			if (onTimeout) {
				onTimeout();
			}
		}, timeout);
	}

	onPoll({ attempt, stopPolling });
	return stopPolling;
};
