"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SsoProviders = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var core_1 = require("@material-ui/core");
var styles_1 = require("@material-ui/core/styles");
var __1 = require("..");
var materialbet_icons_1 = require("materialbet-icons");
var React = __importStar(require("react"));
var materialbet_common_1 = require("materialbet-common");
var __2 = require("../..");
var FACEBOOK_COLOR = '#3b5998';
var FACEBOOK_COLOR_HOVER = '#2f477a';
var useStyles = core_1.makeStyles(function (theme) { return ({
    icon: {
        marginRight: theme.spacing(1)
    },
    contentWrapper: {
        marginBottom: theme.spacing(3)
    },
    facebookButton: {
        color: theme.palette.common.white,
        backgroundColor: FACEBOOK_COLOR,
        '&:hover': {
            backgroundColor: FACEBOOK_COLOR_HOVER,
            '@media (hover: none)': {
                backgroundColor: FACEBOOK_COLOR_HOVER
            }
        }
    },
    googleButton: {
        backgroundColor: theme.palette.common.white,
        color: styles_1.fade(theme.palette.common.black, 0.7),
        border: 'none'
    },
    googleButtonDisabled: {
        backgroundColor: styles_1.fade(theme.palette.common.white, 0.84),
        color: styles_1.fade(theme.palette.common.black, 0.44) + " !important"
    }
}); });
var SsoProviders = function (_a) {
    var _b = _a.googleHref, googleHref = _b === void 0 ? __2.createSsoUrl('google') : _b, _c = _a.facebookHref, facebookHref = _c === void 0 ? __2.createSsoUrl('facebook') : _c;
    var styles = useStyles();
    return (React.createElement(materialbet_common_1.Row, { className: styles.contentWrapper, justify: "space-between" },
        GOOGLE_SSO_ENABLED && (React.createElement(__1.SsoProviderButton, { icon: React.createElement(materialbet_icons_1.GoogleIcon, { className: styles.icon }), href: googleHref, classes: {
                root: styles.googleButton,
                disabled: styles.googleButtonDisabled
            } },
            React.createElement(i18n_1.Translate, { label: "subjects.providers.google", ns: "common" }))),
        FACEBOOK_SSO_ENABLED && (React.createElement(__1.SsoProviderButton, { variant: "contained", icon: React.createElement(materialbet_icons_1.FacebookIcon, { fillColor: "#fff", className: styles.icon }), classes: {
                root: styles.facebookButton
            }, href: facebookHref },
            React.createElement(i18n_1.Translate, { label: "subjects.providers.facebook", ns: "common" })))));
};
var MemoizedSsoProviders = React.memo(SsoProviders);
exports.SsoProviders = MemoizedSsoProviders;
