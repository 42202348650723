/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
	EventForCompetition,
	EventForCompetitionFromJSON,
	EventForCompetitionFromJSONTyped,
	EventForCompetitionToJSON,
	Identifier,
	IdentifierFromJSON,
	IdentifierFromJSONTyped,
	IdentifierToJSON,
	OutrightForCompetition,
	OutrightForCompetitionFromJSON,
	OutrightForCompetitionFromJSONTyped,
	OutrightForCompetitionToJSON,
	Stream,
	StreamFromJSON,
	StreamFromJSONTyped,
	StreamToJSON
} from './';

/**
 * CompetitionResponse presents competition with list of events
 * @export
 * @interface CompetitionResponse
 */
export interface CompetitionResponse {
	/**
	 *
	 * @type {boolean}
	 * @memberof CompetitionResponse
	 */
	blank?: boolean;
	/**
	 *
	 * @type {Identifier}
	 * @memberof CompetitionResponse
	 */
	category?: Identifier;
	/**
	 *
	 * @type {Array<EventForCompetition>}
	 * @memberof CompetitionResponse
	 */
	events?: Array<EventForCompetition>;
	/**
	 *
	 * @type {string}
	 * @memberof CompetitionResponse
	 */
	key?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompetitionResponse
	 */
	name?: string;
	/**
	 *
	 * @type {Array<OutrightForCompetition>}
	 * @memberof CompetitionResponse
	 */
	outrights?: Array<OutrightForCompetition>;
	/**
	 *
	 * @type {Identifier}
	 * @memberof CompetitionResponse
	 */
	sport?: Identifier;
	/**
	 *
	 * @type {Array<Stream>}
	 * @memberof CompetitionResponse
	 */
	streams?: Array<Stream>;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof CompetitionResponse
	 */
	tags?: Array<string>;
}

export function CompetitionResponseFromJSON(json: any): CompetitionResponse {
	return CompetitionResponseFromJSONTyped(json, false);
}

export function CompetitionResponseFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompetitionResponse {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		blank: !exists(json, 'blank') ? undefined : json['blank'],
		category: !exists(json, 'category')
			? undefined
			: IdentifierFromJSON(json['category']),
		events: !exists(json, 'events')
			? undefined
			: (json['events'] as Array<any>).map(EventForCompetitionFromJSON),
		key: !exists(json, 'key') ? undefined : json['key'],
		name: !exists(json, 'name') ? undefined : json['name'],
		outrights: !exists(json, 'outrights')
			? undefined
			: (json['outrights'] as Array<any>).map(
					OutrightForCompetitionFromJSON
			  ),
		sport: !exists(json, 'sport')
			? undefined
			: IdentifierFromJSON(json['sport']),
		streams: !exists(json, 'streams')
			? undefined
			: (json['streams'] as Array<any>).map(StreamFromJSON),
		tags: !exists(json, 'tags') ? undefined : json['tags']
	};
}

export function CompetitionResponseToJSON(
	value?: CompetitionResponse | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		blank: value.blank,
		category: IdentifierToJSON(value.category),
		events:
			value.events === undefined
				? undefined
				: (value.events as Array<any>).map(EventForCompetitionToJSON),
		key: value.key,
		name: value.name,
		outrights:
			value.outrights === undefined
				? undefined
				: (value.outrights as Array<any>).map(
						OutrightForCompetitionToJSON
				  ),
		sport: IdentifierToJSON(value.sport),
		streams:
			value.streams === undefined
				? undefined
				: (value.streams as Array<any>).map(StreamToJSON),
		tags: value.tags
	};
}
