import {
	Card,
	CardContent,
	makeStyles,
	Theme,
	useTheme
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import * as React from 'react';

import { PromoCardVariants } from '../PromoCard';

interface SimplePromoCardSkeletonProps {
	variant?: PromoCardVariants;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	horizontal: {
		display: 'flex'
	},
	vertical: {
		display: 'block'
	},
	description: {
		paddingBottom: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	text: {
		marginBottom: 6 // From material ui demo
	}
}));

const useMediaStyles = makeStyles({
	container: {
		overflow: 'hidden',
		position: 'relative'
	},
	vertical: {
		height: 0,
		paddingTop: '56.25%'
	},
	horizontal: {
		width: 0,
		paddingLeft: '40%'
	},
	media: {
		top: 0,
		left: 0,
		position: 'absolute'
	}
});

export const useContentStyles = makeStyles({
	horizontal: {
		width: '60%'
	},
	vertical: {
		width: '100%'
	}
});

export const SimplePromoCardSkeleton: React.FC<SimplePromoCardSkeletonProps> = (
	props
) => {
	const { className, variant = 'vertical' } = props;
	const theme = useTheme();
	const classes = useStyles();
	const mediaClasses = useMediaStyles();
	const contentClasses = useContentStyles();
	return (
		<Card className={clsx(className, classes[variant])}>
			<div
				className={clsx(mediaClasses.container, mediaClasses[variant])}
			>
				<Skeleton
					variant="rect"
					className={mediaClasses.media}
					component="div"
					height="100%"
					width="100%"
				/>
			</div>
			<CardContent className={contentClasses[variant]}>
				<Skeleton height={theme.typography.h4.fontSize} />
				<div className={classes.description}>
					<Skeleton height={10} classes={{ text: classes.text }} />
					<Skeleton height={10} classes={{ text: classes.text }} />
					<Skeleton height={10} width="80%" />
				</div>
				<Skeleton height={theme.typography.body2.fontSize} width={80} />
			</CardContent>
		</Card>
	);
};
