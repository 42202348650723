"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.finishLoading = exports.startLoading = exports.createLoadedOnceSelector = exports.createIsLoadingSelector = exports.loadingReducer = exports.LoadingMetaKey = void 0;
exports.LoadingMetaKey = 'loading';
exports.loadingReducer = function (state, action) {
    if (state === void 0) { state = {}; }
    if (action.meta && Array.isArray(action.meta[exports.LoadingMetaKey])) {
        var newLoads = action.meta[exports.LoadingMetaKey];
        var newLoading = newLoads.reduce(function (loading, load) {
            var name = load.name, newLoad = __rest(load, ["name"]);
            if (!name) {
                return loading;
            }
            var isLoading = !!newLoad.initiatedAt;
            loading[name] = __assign(__assign(__assign({}, state[name]), newLoad), { isLoading: isLoading });
            return loading;
        }, {});
        return __assign(__assign({}, state), newLoading);
    }
    return state;
};
exports.createIsLoadingSelector = function (loadingSelector) { return function (name) { return function (state) {
    var loading = loadingSelector(state)[name];
    return loading && loading.isLoading;
}; }; };
exports.createLoadedOnceSelector = function (loadingSelector) { return function (name) { return function (state) {
    var loading = loadingSelector(state)[name];
    return loading && loading.receivedAt;
}; }; };
exports.startLoading = function () {
    var _a;
    var names = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        names[_i] = arguments[_i];
    }
    return (_a = {},
        _a[exports.LoadingMetaKey] = names.map(function (name) { return ({
            name: name,
            initiatedAt: new Date().getTime()
        }); }),
        _a);
};
exports.finishLoading = function () {
    var _a;
    var names = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        names[_i] = arguments[_i];
    }
    return (_a = {},
        _a[exports.LoadingMetaKey] = names.map(function (name) { return ({
            name: name,
            receivedAt: new Date().getTime()
        }); }),
        _a);
};
