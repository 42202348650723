"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAccountButton = exports.useSetAccountButton = exports.AccountButtonProvider = void 0;
var React = __importStar(require("react"));
var AccountButtonContext = React.createContext({});
var AccountButtonControlContext = React.createContext(function () { return void 0; });
exports.AccountButtonProvider = function (_a) {
    var children = _a.children;
    var _b = React.useState({}), accountButtonData = _b[0], setAccountButton = _b[1];
    return (React.createElement(AccountButtonControlContext.Provider, { value: setAccountButton },
        React.createElement(AccountButtonContext.Provider, { value: accountButtonData }, children)));
};
exports.useSetAccountButton = function () {
    return React.useContext(AccountButtonControlContext);
};
exports.useAccountButton = function () { return React.useContext(AccountButtonContext); };
