"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.providersList = void 0;
exports.providersList = {
    ainsworth: 'Ainsworth',
    ag: 'Asia Gaming',
    bsg: 'Betsoft',
    blueprint: 'Blueprint',
    booming: 'Booming Games',
    booongo: 'Booongo',
    concept: 'Concept Gaming',
    ebet: 'eBET',
    elk: 'ELK Studios',
    espresso: 'Espresso Games',
    evo: 'Evolution Gaming',
    evoplay: 'Evoplay Entertainment',
    ez: 'Ezugi',
    fantasma: 'Fantasma Games',
    firebird: 'Firebird Gaming',
    gameart: 'GameArt',
    gamevy: 'Gamevy',
    gamomat: 'Gamomat',
    ganapati: 'Ganapati',
    givme: 'Givme Games',
    goldenhero: 'Golden Hero',
    habanero: 'Habanero',
    irondog: 'IronDog',
    kalamba: 'Kalamba Games',
    kiron: 'Kiron Interactive',
    leander: 'Leander Games',
    leap: 'Leap Gaming',
    lottopop: 'LottoPOP',
    lotus: 'Lotus Gaming',
    mga: 'MGA',
    mgs: 'Microgaming',
    mikado: 'Mikado Games',
    multislot: 'MultiSlot',
    netent: 'NetEnt',
    nolimit: 'Nolimit City',
    omigaming: 'OMI Gaming',
    onetouch: 'OneTouch',
    onextwo: '1X2gaming',
    oryx: 'Oryx',
    pariplay: 'Pariplay',
    pgsoft: 'PG Soft',
    piratesgold: 'Pirates Gold Studios',
    platipus: 'Platipus',
    png: 'Play’n Go',
    playson: 'Playson',
    pragmaticplay: 'Pragmatic Play',
    push: 'Push Gaming',
    quickspin: 'Quickspin',
    rakki: 'Rakki',
    redtiger: 'Red Tiger',
    relax: 'Relax Gaming',
    revolver: 'Revolver Gaming',
    rtgasia: 'RTG',
    sagaming: 'SA Gaming',
    skillzz: 'Skillzzgaming',
    slingo: 'Slingo Originals',
    spike: 'Spike Games',
    spml: 'Spinomenal',
    thunderkick: 'Thunderkick',
    th: 'Tom Horn Gaming',
    triplepg: 'Triple Profits Games',
    vi: 'Vivo Gaming',
    wazdan: 'Wazdan',
    yggdrasil: 'Yggdrasil'
};
