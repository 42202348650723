import { CardActions, CardActionsProps, IconButton } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import * as React from 'react';

import { ExpandableCardContext } from '../../ExpandableCard';

const useStyles = makeStyles((theme: Theme) => ({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	}
}));

export const ExpandableCardActions: React.FC<CardActionsProps> = ({
	children
}) => {
	const [expanded, toggleExpanded] = React.useContext(ExpandableCardContext);
	const classes = useStyles();

	return (
		<CardActions disableSpacing>
			{children}
			<IconButton
				className={clsx(classes.expand, {
					[classes.expandOpen]: expanded
				})}
				onClick={toggleExpanded}
				aria-expanded={expanded}
				aria-label="show more"
			>
				<ExpandMoreIcon />
			</IconButton>
		</CardActions>
	);
};
