/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Sport, SportFromJSON, SportFromJSONTyped, SportToJSON } from './';

/**
 * SportsResponse presents all sports with their competition count
 * @export
 * @interface SportsResponse
 */
export interface SportsResponse {
	/**
	 *
	 * @type {Array<Sport>}
	 * @memberof SportsResponse
	 */
	sports?: Array<Sport>;
}

export function SportsResponseFromJSON(json: any): SportsResponse {
	return SportsResponseFromJSONTyped(json, false);
}

export function SportsResponseFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): SportsResponse {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		sports: !exists(json, 'sports')
			? undefined
			: (json['sports'] as Array<any>).map(SportFromJSON)
	};
}

export function SportsResponseToJSON(value?: SportsResponse | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		sports:
			value.sports === undefined
				? undefined
				: (value.sports as Array<any>).map(SportToJSON)
	};
}
