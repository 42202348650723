import { isMobile } from '@gaming-shell/device';
import {
	MenuItem,
	Select as MuiSelect,
	SelectProps as MuiSelectProps
} from '@material-ui/core';
import * as React from 'react';

export interface Option {
	name: string;
	value: string;
	selected?: boolean;
	disabled?: boolean;
}

export interface SelectProps extends Omit<MuiSelectProps, 'onChange'> {
	onChangeOption?: (selectedOption: Option) => void;
	onChange?: (value: string) => void;
	options: Option[];
}

export const Select: React.FC<SelectProps> = ({
	onChange,
	onChangeOption,
	options,
	value = '',
	native = isMobile(),
	...selectProps
}) => {
	const [selected, setSelected] = React.useState(value);

	React.useEffect(() => {
		setSelected(value);
	}, [value]);

	const handleChange = React.useCallback(
		({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			const selectedOption = options.find(
				(option) => option.value === value
			)!;

			setSelected(value);
			onChange && onChange(value);
			onChangeOption && onChangeOption(selectedOption);
		},
		[onChange, setSelected]
	);

	const OptionComponent = native ? 'option' : MenuItem;

	return (
		<MuiSelect
			native={native}
			value={selected}
			onChange={handleChange}
			{...selectProps}
		>
			{options.map(({ value, name, ...option }) => (
				<OptionComponent key={value} value={value} {...option}>
					{name}
				</OptionComponent>
			))}
		</MuiSelect>
	);
};
