"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebugTopics = exports.PusherTopics = exports.CasinoTopics = exports.SportsTopics = void 0;
var SportsTopics;
(function (SportsTopics) {
    SportsTopics["PusherUpdatedEvent"] = "sports.events.pusherUpdatedEvent";
    SportsTopics["PusherUpdatedSubmarket"] = "sports.event.pusherUpdatedSubmarket";
    SportsTopics["PusherUpdatedMarket"] = "sports.event.pusherUpdatedMarkett";
    SportsTopics["PusherUpdatedEventMetadata"] = "sports.events.pusherUpdatedEventMetadata";
    SportsTopics["PusherUpdatedEventStatus"] = "sports.events.pusherUpdatedEventStatus";
    SportsTopics["PlaceBetRequest"] = "sports.bet.placeRequest";
    SportsTopics["PlaceBetResponse"] = "sports.bet.placeResponse";
})(SportsTopics = exports.SportsTopics || (exports.SportsTopics = {}));
var CasinoTopics;
(function (CasinoTopics) {
    CasinoTopics["PusherUpdatedRecentWinners"] = "casino.winners.pusherUpdatedRecentWinners";
})(CasinoTopics = exports.CasinoTopics || (exports.CasinoTopics = {}));
var PusherTopics;
(function (PusherTopics) {
    PusherTopics["SubscribedChannel"] = "pusher.channel.subscribed";
    PusherTopics["SubscribedEvent"] = "pusher.event.subscribed";
    PusherTopics["EventReceived"] = "pusher.event.received";
    PusherTopics["UnsubscribedChannel"] = "pusher.channel.unsubscribed";
})(PusherTopics = exports.PusherTopics || (exports.PusherTopics = {}));
exports.DebugTopics = {
    sports: SportsTopics,
    pusher: PusherTopics
};
