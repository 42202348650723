import { SideMenuFooter, SideMenuItemList } from '@gaming-shell/layout';
import { Divider } from '@material-ui/core';
import {
	Cashier,
	CoreNavigationList,
	Logout,
	MyAccount,
	PlayerGreeting
} from 'core/components/SideMenu';
import * as React from 'react';
import { usePlayerForcedRoutes } from '@materialbet-core/player';
import { Inbox } from '../Inbox';

/** @description this component will return basic side menu for the core */

let PlayerSideMenuContent: React.FunctionComponent = () => {
	const { onboardingStatuses } = usePlayerForcedRoutes();

	return (
		<SideMenuItemList>
			{onboardingStatuses.signUp && (
				<>
					<PlayerGreeting />
					<Divider />
				</>
			)}
			<MyAccount />
			<Cashier />
			{INBOX_ENABLED && <Inbox />}
			<CoreNavigationList />
			<Logout />
			<Divider />
			<SideMenuFooter showPlayerSettings />
		</SideMenuItemList>
	);
};

PlayerSideMenuContent = React.memo(PlayerSideMenuContent);
export { PlayerSideMenuContent };
