import { fillArray } from 'materialbet-common';
import * as React from 'react';
import { HitsProvided } from 'react-instantsearch-core';
import { SearchResultItem } from 'sports/types/sharedComponents';

import { SportSearchResultItem } from '../SportSearchResultItem';

export type AlgoliaHitsProvided = HitsProvided<SearchResultItem>;

export interface SearchResultsContentProps {
	skeleton: boolean;
}

export const SearchResultsContent: React.FC<SearchResultsContentProps &
	AlgoliaHitsProvided> = ({ hits, skeleton }) => {
	return (
		<div>
			{!skeleton &&
				hits.map((item) => (
					<SportSearchResultItem key={item.id} {...item} />
				))}
			{/* Skeletons */}
			{skeleton &&
				fillArray(SEARCH_SPORTS_RESULTS_PER_PAGE, (i) => (
					<SportSearchResultItem key={`skeleton-${i}`} skeleton />
				))}
		</div>
	);
};
