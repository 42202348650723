import { Theme, makeStyles, MenuItem } from '@material-ui/core';
import * as React from 'react';
import { LinkCommonProps, MenuItemLink } from 'materialbet-common';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		minHeight: theme.spacing(5),
		'& > :first-child': {
			marginRight: theme.spacing(2)
		}
	}
}));

export const AccountMenuItem: React.FC<Partial<LinkCommonProps>> = ({
	children,
	href,
	...rest
}) => {
	const classes = useStyles();

	if (href) {
		return (
			<MenuItemLink href={href} className={classes.root} {...rest}>
				{children}
			</MenuItemLink>
		);
	}

	return (
		<MenuItem {...rest} className={classes.root}>
			{children}
		</MenuItem>
	);
};
