import * as React from 'react';

import {
	getCanValidate,
	getHasChanges,
	getStraightsTotalStake
} from '../betslipSelectors/betslipSelectors';
import { useBetslipSelector } from '../hooks/useBetslipSelector';
import { PlaceBetCallback } from '../placeBet/useBetslipPlaceBet';
import { BigBetSlipAcceptChangesButton } from './BigBetslipAcceptChangesButton';
import { BigBetslipAddStakeToAllButton } from './BigBetslipAddStakeToAllButton';
import { BigBetslipPlaceStraightsBetButton } from './BigBetslipPlaceStraightsBetButton';

export interface UiBigBetslipSinglesBigButtonProps {
	hasChanges: boolean;
	canValidate: boolean;
	onEditStakeForAll: () => void;
	onBetPlaced: PlaceBetCallback;
}
export type BigBetslipSinglesBigButtonProps = Omit<
	UiBigBetslipSinglesBigButtonProps,
	'hasChanges' | 'canValidate'
>;
let UiBigBetslipSinglesBigButton: React.FunctionComponent<UiBigBetslipSinglesBigButtonProps> = (
	props
) => {
	const { hasChanges, canValidate, onEditStakeForAll, onBetPlaced } = props;
	const bigButton = hasChanges ? (
		<BigBetSlipAcceptChangesButton />
	) : canValidate ? (
		<BigBetslipPlaceStraightsBetButton onBetPlaced={onBetPlaced} />
	) : (
		<BigBetslipAddStakeToAllButton onClick={onEditStakeForAll} />
	);
	return bigButton;
};

UiBigBetslipSinglesBigButton = React.memo(UiBigBetslipSinglesBigButton);
let BigBetslipSinglesBigButton: React.FunctionComponent<BigBetslipSinglesBigButtonProps> = (
	props
) => {
	const hasChanges = useBetslipSelector(getHasChanges);
	const canValidate = useBetslipSelector(getCanValidate);
	const totalStake = useBetslipSelector(getStraightsTotalStake);
	return (
		<UiBigBetslipSinglesBigButton
			hasChanges={hasChanges}
			canValidate={canValidate && totalStake > 0}
			{...props}
		/>
	);
};
BigBetslipSinglesBigButton = React.memo(BigBetslipSinglesBigButton);
export { UiBigBetslipSinglesBigButton, BigBetslipSinglesBigButton };
