"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHandleFieldChange = void 0;
var React = __importStar(require("react"));
/* Handles extracting value from event */
exports.useHandleFieldChange = function (initial) {
    if (initial === void 0) { initial = ''; }
    var _a = React.useState(initial), value = _a[0], setValue = _a[1];
    var onChange = React.useCallback(function (ev) {
        var _a;
        var value = (_a = ev === null || ev === void 0 ? void 0 : ev.target) === null || _a === void 0 ? void 0 : _a.value;
        // Undefined is essential here as we want to pass '' (empty string) to state
        if (value !== undefined) {
            setValue(value);
        }
    }, [setValue]);
    var clear = React.useCallback(function () { return setValue(''); }, [setValue]);
    return [value, onChange, clear];
};
