interface RedirectToExternalProps {
	path: string;
}

export const RedirectToExternal: React.FunctionComponent<RedirectToExternalProps> = (
	props
) => {
	window.location.replace(props.path);
	return null;
};
