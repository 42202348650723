"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSlidesToScroll = exports.calcSlidesToScroll = void 0;
var core_1 = require("@material-ui/core");
/** @description it is a function to return slides to scroll based on given slider width,slide width, slide spacing */
exports.calcSlidesToScroll = function (props) {
    var sliderWidth = props.sliderWidth, slideWidth = props.slideWidth, _a = props.slideSpacing, slideSpacing = _a === void 0 ? 0 : _a;
    if (!sliderWidth) {
        return 1;
    }
    return Math.floor(sliderWidth / (slideWidth + slideSpacing));
};
/** @description it is a function to return slides to scroll based on given slider width,slide width using the theme spacing */
exports.useSlidesToScroll = function (props) {
    var theme = core_1.useTheme();
    var sliderWidth = props.sliderWidth, slideWidth = props.slideWidth, _a = props.slideSpacing, slideSpacing = _a === void 0 ? 1 : _a;
    return exports.calcSlidesToScroll({
        sliderWidth: sliderWidth,
        slideWidth: slideWidth,
        slideSpacing: theme.spacing(slideSpacing)
    });
};
