"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPassword = void 0;
var device_1 = require("@gaming-shell/device");
var i18n_1 = require("@gaming-shell/i18n");
var api_1 = require("@materialbet-core/api");
var __1 = require("../..");
var player_1 = require("@materialbet-core/player");
var ForgotPasswordLayout_1 = require("./ForgotPasswordLayout");
var materialbet_common_1 = require("materialbet-common");
var materialbet_common_2 = require("materialbet-common");
var React = __importStar(require("react"));
var routing_1 = require("@gaming-shell/routing");
var layout_1 = require("@gaming-shell/layout");
exports.ForgotPassword = function () {
    var _a = React.useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var authToken = player_1.usePlayerAuthToken();
    var updatePlayer = player_1.useUpdatePlayer();
    var enqueueSnackbar = materialbet_common_1.useSnackbar().enqueueSnackbar;
    var trans = i18n_1.useTranslation();
    var resetPassword = function (email, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    retry) {
        setIsSubmitting(true);
        return api_1.api
            .postResetPassword({
            email: email.trim()
        }, authToken)
            .then(function (response) {
            if (response.result === 'INVALID_AUTHENTICITY_TOKEN') {
                var token_1 = response
                    .authenticity_token;
                updatePlayer(function (player) { return (__assign(__assign({}, player), { authToken: token_1 })); });
                authToken = token_1;
                // setTimeout ensures that the next sign in placed in an event queue which is called after this method returned (js version of defer)
                setTimeout(function () { return retry(email); });
                return;
            }
            if (response.result === 'OK') {
                enqueueSnackbar(trans('player:signIn.forgotPasswordEmailSent'));
                routing_1.history.push(materialbet_common_2.BaseRoutes.root.create());
            }
            else if (response.result === 'VALIDATION_ERROR') {
                enqueueSnackbar(trans('player:signIn.invalidEmail'));
            }
        })
            .catch(function () {
            enqueueSnackbar(trans('player:descriptions.failedRequest'));
        })
            .finally(function () {
            setIsSubmitting(false);
        });
    };
    var retryableResetPassword = __1.retryable(resetPassword, function () {
        enqueueSnackbar(trans('player:descriptions.failedRequest'));
        return Promise.resolve();
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(layout_1.DefaultAppBar, { type: device_1.isDesktop() ? 'home' : 'window', title: trans('player:signIn.forgotPassword') }),
        React.createElement(__1.AuthWrapp, null,
            React.createElement(__1.SingleColumnLayout, { title: React.createElement(i18n_1.Translate, { label: "signIn.forgotPassword", ns: "player" }) },
                React.createElement(ForgotPasswordLayout_1.ForgotPasswordLayout, { emailLabel: React.createElement(i18n_1.Translate, { label: "profile.email", ns: "player" }), extraButtonText: React.createElement(i18n_1.Translate, { label: "actions.signIn", ns: "common" }), extraText: React.createElement(i18n_1.Translate, { label: "security.rememberPassword", ns: "player" }), extraButtonRoute: materialbet_common_2.AuthRoutes.signIn.create(), onSubmitClick: retryableResetPassword, submitButtonText: React.createElement(i18n_1.Translate, { label: "security.sendEmail", ns: "player" }), validations: { email: __1.authValidators.email }, isSubmitting: isSubmitting })))));
};
