"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrophyIcon = void 0;
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
exports.TrophyIcon = function (props) {
    return (React.createElement(core_1.SvgIcon, __assign({}, props),
        React.createElement("g", { fill: props.fill, fillRule: "evenodd" },
            React.createElement("path", { d: "M18.282 2.041H5.718c-.778-.018-1.439.578-1.467 1.354.076 5.677 1.124 8.425 3.16 9.654.158.096.32.182.494.263l.269.119c.117.049.234.095.389.153l.264.1c.814.308 1.002.485 1.021 1.263l.002.153c.07 1.484-1.17 2.962-2.843 3.468-.565.167-.967.675-.983 1.267v1.42c0 .413.335.75.75.75H17.27c.414 0 .75-.337.75-.75v-1.4c-.014-.612-.418-1.125-.992-1.293-1.612-.489-2.845-1.876-2.882-3.255l.002-.172c.001-.889.141-1.1.878-1.395l.71-.273.089-.036c.28-.117.525-.238.764-.382 2.034-1.23 3.083-3.977 3.16-9.616-.03-.814-.69-1.41-1.468-1.392zM5.751 3.54h12.497l.003-.09c-.07 5.009-.896 7.295-2.326 8.245l-.11.07c-.3.181-.373.211-1.175.516-1.334.507-1.937 1.146-1.987 2.613l-.007.413c.016 2.003 1.62 3.89 3.734 4.62l.14.046v.531H7.523v-.528l-.086.029c2.217-.67 3.905-2.618 3.917-4.73l-.005-.211c.001-1.51-.544-2.193-1.812-2.713l-.882-.344c-.201-.084-.298-.138-.47-.242l-.11-.07c-1.356-.9-2.17-3.003-2.308-7.518L5.75 3.54z" }),
            React.createElement("path", { d: "M4.615 4.019c.414 0 .75.335.75.75 0 .38-.282.693-.648.743l-.102.007H2.843c-.139 0-.25.112-.25.25l.003.039.009.038 1.148 3.53c.129.393-.087.816-.48.945-.362.117-.747-.054-.908-.387l-.038-.094-1.149-3.53c-.056-.175-.085-.357-.085-.541 0-.919.707-1.672 1.606-1.745l.144-.005h1.772zm17 0l.144.005c.899.073 1.606.826 1.606 1.745 0 .184-.029.366-.086.541l-1.148 3.53-.038.094c-.161.333-.547.504-.908.387-.394-.129-.609-.552-.48-.946l1.148-3.529.009-.038.003-.04c0-.137-.112-.25-.25-.25h-1.772l-.102-.006c-.366-.05-.648-.364-.648-.743 0-.415.335-.75.75-.75h1.772z" }))));
};
