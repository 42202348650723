/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
	CompetitionForSport,
	CompetitionForSportFromJSON,
	CompetitionForSportFromJSONTyped,
	CompetitionForSportToJSON
} from './';

/**
 * Category is used for the /sports/{key} endpoint
 * @export
 * @interface Category
 */
export interface Category {
	/**
	 *
	 * @type {Array<CompetitionForSport>}
	 * @memberof Category
	 */
	competitions?: Array<CompetitionForSport>;
	/**
	 *
	 * @type {string}
	 * @memberof Category
	 */
	key?: string;
	/**
	 *
	 * @type {string}
	 * @memberof Category
	 */
	name?: string;
}

export function CategoryFromJSON(json: any): Category {
	return CategoryFromJSONTyped(json, false);
}

export function CategoryFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): Category {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		competitions: !exists(json, 'competitions')
			? undefined
			: (json['competitions'] as Array<any>).map(
					CompetitionForSportFromJSON
			  ),
		key: !exists(json, 'key') ? undefined : json['key'],
		name: !exists(json, 'name') ? undefined : json['name']
	};
}

export function CategoryToJSON(value?: Category | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		competitions:
			value.competitions === undefined
				? undefined
				: (value.competitions as Array<any>).map(
						CompetitionForSportToJSON
				  ),
		key: value.key,
		name: value.name
	};
}
