import { LinearProgress, Theme, makeStyles } from '@material-ui/core';
import * as React from 'react';

import { Column, Row } from '../Layout';
import { Typography } from '../Typography';

export interface PlaythroughProgressProps {
	currency: string;
	/** Text displayed in the first row - above current value and the target */
	label: React.ReactNode;
	target: number;
	value: number;
	progress: number;
	/** Allows to modify how the currency and its amount is displayed to the user */
	formatCurrency?: (currency: string, amount: number) => string;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	linearProgress: {
		backgroundColor:
			theme.palette.type === 'dark'
				? theme.palette.grey[700]
				: theme.palette.grey[100],
		marginTop: theme.spacing(1)
	},
	label: {
		paddingBottom: theme.spacing(1.5)
	}
}));

export const PlaythroughProgress: React.FunctionComponent<PlaythroughProgressProps> = ({
	currency,
	target,
	value,
	progress,
	label,
	formatCurrency,
	className
}) => {
	const classes = useStyles();

	return (
		<Column className={className}>
			<Typography
				variant="body2"
				color="textSecondary"
				className={classes.label}
			>
				{label}
			</Typography>
			<Row justify="space-between" alignItems="baseline">
				<Typography variant="h6">
					{formatCurrency
						? formatCurrency(currency, value)
						: `${currency} ${value}`}
				</Typography>
				<Typography variant="body2">
					{formatCurrency
						? formatCurrency(currency, target)
						: `${currency} ${target}`}
				</Typography>
			</Row>
			<LinearProgress
				color="secondary"
				variant="determinate"
				value={progress}
				className={classes.linearProgress}
			/>
		</Column>
	);
};
