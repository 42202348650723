import casinoConfig from 'casino/config';
import { CasinoRoutes, querystring } from 'materialbet-common';
import { matchPath, useLocation } from 'react-router';

/** @obsolete use getSectionRoute instead*/
export const getGameRoute = (gameType: string, isLive: boolean) => {
	const gameRoute = isLive ? CasinoRoutes.liveGames : CasinoRoutes.rngGames;
	return gameRoute.create({ gameType });
};

export const getThemeRoute = (themeKey: string) =>
	CasinoRoutes.theme.create({ themeKey });
export const getStudioRoute = (studioKey: string) =>
	CasinoRoutes.studioGames.create({ studioKey });
export const getSectionRoute = getGameRoute;
export const isLiveCasino = (currentRoute: string) =>
	!!matchPath(currentRoute, CasinoRoutes.liveCasino.create());
export const useRouteCategorySlug = () => {
	const { pathname } = useLocation();
	const live = isLiveCasino(pathname);
	const result = matchPath(pathname, {
		path: live
			? CasinoRoutes.liveGames.template
			: CasinoRoutes.rngGames.template,
		exact: false,
		strict: true
	});
	const categorySlug: string = result?.params['gameType'];
	return categorySlug;
};
export const useIsLiveRoute = () => isLiveCasino(useLocation().pathname);
export const getLobbyRoute = (live: boolean) =>
	live ? CasinoRoutes.liveCasino : CasinoRoutes.root;
export const searchPreviewParam = 'preview';
export const getPreviewGameId = (search: string): string | undefined => {
	const params = querystring.parse(search);
	const gameId = params[searchPreviewParam];
	if (Array.isArray(gameId)) {
		return gameId[0];
	}
	return gameId;
};
export const isFreePlay = (pathname: string) =>
	!!CasinoRoutes.legacyFreeplayGame.matches(pathname);
export const useIsFreePlay = () => isFreePlay(useLocation().pathname);
export const getFullLaunchUrl = (launchPath: string) =>
	`${casinoConfig.launchBase}${launchPath}`;
export const useRouteGameId = () => {
	const { gameId } = CasinoRoutes.legacyGame.useParams();
	return gameId as string;
};
export const useRouteStudioKey = () => {
	const { studioKey } = CasinoRoutes.studioGames.useParams();
	return studioKey as string;
};
export const useAllProviders = () => CasinoRoutes.allProviders;
