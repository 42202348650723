import { useTheme } from '@material-ui/core';

export type SlidesToScrollProps = {
	slideWidth: number;
	sliderWidth?: number;
	slideSpacing?: number;
};

/** @description it is a function to return slides to scroll based on given slider width,slide width, slide spacing */
export const calcSlidesToScroll = (props: SlidesToScrollProps) => {
	const { sliderWidth, slideWidth, slideSpacing = 0 } = props;
	if (!sliderWidth) {
		return 1;
	}
	return Math.floor(sliderWidth / (slideWidth + slideSpacing));
};

/** @description it is a function to return slides to scroll based on given slider width,slide width using the theme spacing */
export const useSlidesToScroll = (props: SlidesToScrollProps) => {
	const theme = useTheme();
	const { sliderWidth, slideWidth, slideSpacing = 1 } = props;
	return calcSlidesToScroll({
		sliderWidth,
		slideWidth,
		slideSpacing: theme.spacing(slideSpacing)
	});
};
