"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.actions = void 0;
var typesafe_actions_1 = require("typesafe-actions");
exports.actions = {
    fetchNotifications: typesafe_actions_1.createAsyncAction('playerMessaging/fetchNotificationsRequest', 'playerMessaging/fetchNotificationsSuccess', 'playerMessaging/fetchNotificationsFailure')(),
    fetchMessages: typesafe_actions_1.createAsyncAction('playerMessaging/fetchMessagesRequest', 'playerMessaging/fetchMessagesSuccess', 'playerMessaging/fetchMessagesFailure')(),
    dismissNotification: typesafe_actions_1.createAction('playerMessaging/dismissNotification', function (resolve) { return function (notificationId) { return resolve(notificationId); }; }),
    readMessage: typesafe_actions_1.createAction('playerMessaging/readMessage', function (resolve) { return function (messageId) { return resolve(messageId); }; }),
    seeNewNotifications: typesafe_actions_1.createAction('playerMessaging/seeNewNotifications'),
    seeNewMessages: typesafe_actions_1.createAction('playerMessaging/seeNewMessages')
};
