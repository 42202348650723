import { useLanguage } from '@gaming-shell/i18n';
import { addDays, differenceInDays, format, isAfter } from 'date-fns';
import { format as formatToTimeZone, utcToZonedTime } from 'date-fns-tz';

export const getTimeZoneOffset = () => {
	const currentDate = new Date();
	const timezoneOffset = currentDate.getTimezoneOffset() / 60;
	const offset = timezoneOffset === 0 ? 'UTC+0' : `UTC${timezoneOffset}`;
	return offset;
};

export const getTimeZone = () => {
	if (Intl) {
		return Intl.DateTimeFormat().resolvedOptions().timeZone; // Default return the browser timeZone
	}
	return getTimeZoneOffset();
};

export const getUnixTime = (date: Date) => {
	const timestamp = Math.round(date.getTime() / 1000);
	return timestamp;
};

export const getFormatDate = (timestamp?: number) => {
	if (!timestamp) {
		return undefined;
	}
	return format(timestamp, 'MMMM dd, yyyy');
};

export const isSameYear = (
	firstDate: Date,
	secondDate: Date,
	timeZone?: string
) => {
	const dateFormat = 'yyyy';
	const firstDateYear = timeZone
		? formatToTimeZone(utcToZonedTime(firstDate, timeZone), dateFormat, {
				timeZone
		  })
		: format(firstDate, dateFormat);
	const secondDateYear = timeZone
		? formatToTimeZone(utcToZonedTime(secondDate, timeZone), dateFormat, {
				timeZone
		  })
		: format(secondDate, dateFormat);
	return firstDateYear === secondDateYear;
};

export const isSameDay = (
	firstDate: Date,
	secondDate: Date,
	timeZone?: string
) => {
	const dateFormat = 'dd';
	const firstDateDay = timeZone
		? formatToTimeZone(utcToZonedTime(firstDate, timeZone), dateFormat, {
				timeZone
		  })
		: format(firstDate, dateFormat);
	const secondDateDay = timeZone
		? formatToTimeZone(utcToZonedTime(secondDate, timeZone), dateFormat, {
				timeZone
		  })
		: format(secondDate, dateFormat);
	return firstDateDay === secondDateDay;
};

export const dateIsWithinLastXDays = (date: Date, days: number) => {
	const cutoffDate = addDays(new Date(), -days);
	if (days < 0) {
		return true; // always return true if -1 is passed
	}
	if (days === 0) {
		return isSameDay(date, cutoffDate); // check if thats the same day if days is 0
	}
	return (
		isAfter(date, cutoffDate) && differenceInDays(date, cutoffDate) <= days
	);
};

/**@description This function return 23:59 of the same day of a given time, it will consider the timeZone passed in  */
export const getEndOfDay = (date: Date, timeZone: string) => {
	const dateFormat = 'yyyy-MM-dd HH:mm XXX';
	const zonedTime = utcToZonedTime(date, timeZone);
	const formattedDate = formatToTimeZone(zonedTime, dateFormat, { timeZone });
	const [dateString, , offset] = formattedDate.split(' ');
	const newDate = `${dateString}T23:59${offset}`;
	return new Date(newDate);
};

export const useTimeFormat = () => {
	const language = useLanguage();
	// Only return 12 hours format for en
	if (language === 'en') {
		return 'h:mm a'; // This is 0...12:00...59 AM/PM
	}
	return 'HH:mm'; // This is 00...23:00...59
};
