import {
	IconButton as MuiIconButton,
	IconButtonProps as MuiIconButtonProps,
	makeStyles,
	Theme
} from '@material-ui/core';
import * as React from 'react';
import clsx from 'clsx';

export interface IconButtonProps extends Omit<MuiIconButtonProps, 'size'> {
	size?: 'small' | 'medium' | number;
}

const useStyles = makeStyles((theme: Theme) => ({
	// Please note '{}' is just for ts purposes, it will not be applied as class
	size: ({ size }: IconButtonProps) =>
		typeof size === 'number' ? { padding: theme.spacing(size) } : {}
}));

// Extends mui's IconButton that accepts numbers as size
const UnforwardedIconButton: React.ForwardRefRenderFunction<
	HTMLButtonElement,
	IconButtonProps
> = ({ size, className, ...rest }, ref) => {
	const classes = useStyles({ size });

	return (
		<MuiIconButton
			ref={ref}
			className={clsx(
				typeof size === 'number' && classes.size, // only applies class if custom size passed
				className
			)}
			size={typeof size === 'string' ? size : undefined}
			{...rest}
		/>
	);
};

export const IconButton = React.forwardRef(UnforwardedIconButton);
