"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromotionsRoutes = void 0;
/** Creates a promote route */
var routing_1 = require("@gaming-shell/routing");
var promotionsRoute = routing_1.route(['promotions']); // we cant use promotions as it is targeted to acquisition page
exports.PromotionsRoutes = {
    root: promotionsRoute,
    promotion: promotionsRoute.add([
        routing_1.pathParam('categoryKey'),
        routing_1.pathParam('promotionKey')
    ])
};
