// note that the common module is not a module in duck sense, as it does not export a reducer
// we need seperate file for common actions, as we cannot import an action from root in any other
// module due to circular dependencies
import { createUpdateEntitiesAction } from 'materialbet-common';
import { ActionType, createAction } from 'typesafe-actions';

export interface ErrorPayload {
	action: string;
	message?: string;
	error: unknown;
}

export const commonActions = {
	updateEntities: createUpdateEntitiesAction('root/updateEntities'),
	loggedout: createAction('root/loggedout')
};

export type CommonActions = ActionType<typeof commonActions>;
