import { useTranslation } from '@gaming-shell/i18n';
import { PromotionsRoutes, SportsRoutes, withHook } from 'materialbet-common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { EventTeams } from 'sports/components/displays/EventTeams';
import { ConnectedEventTime } from 'sports/components/displays/EventTime';
import { ZeroMarginEventWidgetPart } from 'sports/components/ZeroMarginEventWidgetPart';
import { ZeroMarginOpeningSoonCounter } from 'sports/components/ZeroMarginOpeningSoonCounter';
import { ZeroMarginSlotsCounter } from 'sports/components/ZeroMarginSlotsCounter';
import { SportType } from 'sports/config/sport';
import {
	getEventCompetitionKey,
	getEventKey
} from 'sports/selectors/eventSelectors';

export const ZeroMarginEventWidgetPromoPart = withHook(
	({ eventId, sportKey }: { eventId?: number; sportKey: SportType }) => {
		const t = useTranslation();
		const title = eventId ? (
			<EventTeams eventId={eventId} sportKey={sportKey} />
		) : (
			t('player:promotions.zeroMarginOdds')
		);
		const eventKey = useSelector(getEventKey(eventId)) || '';
		const competitionKey =
			useSelector(getEventCompetitionKey(eventId)) || '';
		const titleLink =
			eventId &&
			SportsRoutes.eventWithKey.create({
				eventId,
				eventKey,
				competitionKey,
				sportKey
			});
		const caption = eventId && (
			<ConnectedEventTime eventId={eventId} sportKey={sportKey} />
		);
		const linkTitle = t('actions.seePromo');
		// This is EPL specific at the moment, hardcoded for now
		const linkTo = PromotionsRoutes.promotion.create({
			categoryKey: 'general',
			promotionKey: 'epl-zero-margin'
		});
		const counter = eventId ? (
			<ZeroMarginSlotsCounter eventId={eventId} />
		) : (
			<ZeroMarginOpeningSoonCounter />
		);
		return {
			title,
			titleLink,
			caption,
			linkTo,
			linkTitle,
			counter
		};
	}
)(ZeroMarginEventWidgetPart);
