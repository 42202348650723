"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getImageUrl = exports.mapOptionsToCloudinaryParams = void 0;
var defaultOptions = {
    width: 320,
    height: 320,
    fill: true,
    dpr: true,
    quality: 'auto',
    format: 'auto'
};
var optionsToCloudinaryOptions = {
    width: 'w_{{value}}',
    height: 'h_{{value}}',
    fill: 'c_fill',
    dpr: 'dpr_1',
    quality: 'q_{{value}}',
    format: 'f_{{value}}'
};
function mapOptionsToCloudinaryParams(options) {
    return Object.entries(options).map(function (_a) {
        var key = _a[0], value = _a[1];
        var cloudinaryParam = optionsToCloudinaryOptions[key];
        // if placeholder exist replace it with passed value
        if (cloudinaryParam.includes('{{value}}')) {
            return cloudinaryParam.replace('{{value}}', value);
        }
        // else return cloudinary param or exact value of passed property
        return cloudinaryParam || value;
    });
}
exports.mapOptionsToCloudinaryParams = mapOptionsToCloudinaryParams;
/* gets image url from CDN through cloudinary */
function getImageUrl(cdnImagePath, options) {
    if (options === void 0) { options = defaultOptions; }
    var mappedOptions = mapOptionsToCloudinaryParams(__assign(__assign({}, defaultOptions), options));
    return (cdnImagePath &&
        CLOUDINARY_URL + "/image/fetch/" + mappedOptions.join(',') + "/" + CDN_URL + "/" + cdnImagePath.replace(/^\//, ''));
}
exports.getImageUrl = getImageUrl;
