"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerRoutes = void 0;
var routing_1 = require("@gaming-shell/routing");
var rootRoute = routing_1.route(['player']);
var accountRoute = rootRoute.add(['account']);
var cashierRoute = rootRoute.add(['cashier']);
var settlementsRoute = rootRoute.add(['settlements']);
var settingsRoute = accountRoute.add(['settings']);
exports.PlayerRoutes = {
    // Root routes
    root: rootRoute,
    onboarding: rootRoute.add(['onboarding']),
    bonus: rootRoute.add(['bonus']),
    // Account routes
    account: accountRoute,
    transaction: accountRoute.add(['transaction', routing_1.pathParam('id')]),
    moonpay: accountRoute.add(['moonpay']),
    inbox: accountRoute.add(['inbox']),
    inboxMessage: accountRoute.add(['inbox', routing_1.queryParam('message_id')]),
    // Settings routes
    settings: settingsRoute.add([routing_1.pathParam('page')]),
    disable2fa: settingsRoute.add(['disable-2fa']),
    enable2fa: settingsRoute.add(['enable-2fa']),
    preferences: settingsRoute.add(['preferences']),
    responsibleGambling: settingsRoute.add(['responsible-gambling']),
    security: settingsRoute.add(['security']),
    securityTab: settingsRoute.add(['security', routing_1.queryParam('tab')]),
    accessLog: settingsRoute.add(['access-log']),
    verification: settingsRoute.add(['verification']),
    verificationTab: settingsRoute.add(['verification', routing_1.queryParam('tab')]),
    sms: settingsRoute.add(['sms']),
    personalVerification: settingsRoute.add(['personal_verification']),
    // Cashier routes
    cashier: cashierRoute.add([routing_1.pathParam('page'), routing_1.pathParam('currency')]),
    cashierDeposit: cashierRoute.add(['deposit', routing_1.pathParam('currency')]),
    cashierWithdraw: cashierRoute.add(['withdraw', routing_1.pathParam('currency')]),
    cashierHistory: cashierRoute.add(['history', routing_1.pathParam('currency')]),
    cashierHistoryPendingTransaction: cashierRoute.add([
        'history',
        routing_1.pathParam('currency'),
        routing_1.queryParam('pending_id')
    ]),
    // Settlement routes
    settlements: settlementsRoute.add([routing_1.pathParam('currency')])
};
