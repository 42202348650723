/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
	EventMetadataSet,
	EventMetadataSetFromJSON,
	EventMetadataSetFromJSONTyped,
	EventMetadataSetToJSON
} from './';

/**
 *
 * @export
 * @interface EventMetadata
 */
export interface EventMetadata {
	/**
	 *
	 * @type {object}
	 * @memberof EventMetadata
	 */
	metadata?: object;
	/**
	 *
	 * @type {Array<number>}
	 * @memberof EventMetadata
	 */
	clearedScore?: Array<number>;
	/**
	 *
	 * @type {string}
	 * @memberof EventMetadata
	 */
	cutoffTime?: string;
	/**
	 *
	 * @type {string}
	 * @memberof EventMetadata
	 */
	eventStatus?: string;
	/**
	 *
	 * @type {string}
	 * @memberof EventMetadata
	 */
	eventTime?: string;
	/**
	 *
	 * @type {string}
	 * @memberof EventMetadata
	 */
	eventTimeExtended?: string;
	/**
	 *
	 * @type {number}
	 * @memberof EventMetadata
	 */
	lastModified?: number;
	/**
	 *
	 * @type {number}
	 * @memberof EventMetadata
	 */
	matchDay?: number;
	/**
	 *
	 * @type {Array<number>}
	 * @memberof EventMetadata
	 */
	score?: Array<number>;
	/**
	 *
	 * @type {number}
	 * @memberof EventMetadata
	 */
	sequence?: number;
	/**
	 *
	 * @type {Array<EventMetadataSet>}
	 * @memberof EventMetadata
	 */
	setScores?: Array<EventMetadataSet>;
}

export function EventMetadataFromJSON(json: any): EventMetadata {
	return EventMetadataFromJSONTyped(json, false);
}

export function EventMetadataFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): EventMetadata {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		metadata: !exists(json, 'Metadata') ? undefined : json['Metadata'],
		clearedScore: !exists(json, 'clearedScore')
			? undefined
			: json['clearedScore'],
		cutoffTime: !exists(json, 'cutoffTime')
			? undefined
			: json['cutoffTime'],
		eventStatus: !exists(json, 'eventStatus')
			? undefined
			: json['eventStatus'],
		eventTime: !exists(json, 'eventTime') ? undefined : json['eventTime'],
		eventTimeExtended: !exists(json, 'eventTimeExtended')
			? undefined
			: json['eventTimeExtended'],
		lastModified: !exists(json, 'lastModified')
			? undefined
			: json['lastModified'],
		matchDay: !exists(json, 'matchDay') ? undefined : json['matchDay'],
		score: !exists(json, 'score') ? undefined : json['score'],
		sequence: !exists(json, 'sequence') ? undefined : json['sequence'],
		setScores: !exists(json, 'setScores')
			? undefined
			: (json['setScores'] as Array<any>).map(EventMetadataSetFromJSON)
	};
}

export function EventMetadataToJSON(value?: EventMetadata | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		Metadata: value.metadata,
		clearedScore: value.clearedScore,
		cutoffTime: value.cutoffTime,
		eventStatus: value.eventStatus,
		eventTime: value.eventTime,
		eventTimeExtended: value.eventTimeExtended,
		lastModified: value.lastModified,
		matchDay: value.matchDay,
		score: value.score,
		sequence: value.sequence,
		setScores:
			value.setScores === undefined
				? undefined
				: (value.setScores as Array<any>).map(EventMetadataSetToJSON)
	};
}
