/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * TeamIdentifier is used for home/away team details in events
 * @export
 * @interface TeamIdentifier
 */
export interface TeamIdentifier {
	/**
	 *
	 * @type {string}
	 * @memberof TeamIdentifier
	 */
	abbreviation?: string;
	/**
	 *
	 * @type {string}
	 * @memberof TeamIdentifier
	 */
	key?: string;
	/**
	 *
	 * @type {string}
	 * @memberof TeamIdentifier
	 */
	name?: string;
}

export function TeamIdentifierFromJSON(json: any): TeamIdentifier {
	return TeamIdentifierFromJSONTyped(json, false);
}

export function TeamIdentifierFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): TeamIdentifier {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		abbreviation: !exists(json, 'abbreviation')
			? undefined
			: json['abbreviation'],
		key: !exists(json, 'key') ? undefined : json['key'],
		name: !exists(json, 'name') ? undefined : json['name']
	};
}

export function TeamIdentifierToJSON(value?: TeamIdentifier | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		abbreviation: value.abbreviation,
		key: value.key,
		name: value.name
	};
}
