import { register, SharedComponentIds } from 'shared-components';

import {
	AccountButton,
	AccountBalanceButton,
	NotificationsButton
} from 'core/components/AppBar';
import { PlayerCurrentTime } from 'core/components/SideMenu';

export const registerSharedComponents = () => {
	/* unfortunately casting is required due to algolia wrapper */
	register(SharedComponentIds.AccountBalanceButton, AccountBalanceButton);
	register(SharedComponentIds.NotificationsButton, NotificationsButton);
	register(SharedComponentIds.AccountButton, AccountButton);

	PLAYER_CURRENT_TIME_ENABLED &&
		register(SharedComponentIds.PlayerCurrentTime, PlayerCurrentTime);
};
