import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const NoTrailingSlashRedirect: React.FC = () => {
	return (
		<Route exact strict path="/:url*(/+)">
			<Redirect
				exact
				from="/:url*(/+)"
				to={{
					pathname: window.location.pathname.slice(0, -1),
					search: window.location.search
				}}
			/>
		</Route>
	);
};
