"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseRatio = exports.getRatioFromString = void 0;
/** @description a function to return ratio width/height from string */
exports.getRatioFromString = function (ratio) {
    var parts = ratio.split(':');
    var _a = parts.map(function (x) { return parseInt(x); }), _b = _a[0], width = _b === void 0 ? 0 : _b, _c = _a[1], height = _c === void 0 ? 0 : _c;
    if (height === 0) {
        return 1; // Everything divide by zero will be infinity, avoid the edge case
    }
    return width / height || 1;
};
/** @description a function to return x/y in a percentage ratio, 4:3 => x:y => 3/4 => 75% */
exports.parseRatio = function (ratio) {
    var deaultValue = (3 / 4) * 100;
    if (ratio) {
        var parsedRatio = ratio.split(':');
        if (parsedRatio.length === 2) {
            var parsedX = parseInt(parsedRatio[0], 10);
            var parsedY = parseInt(parsedRatio[1], 10);
            if (isNaN(parsedX) || isNaN(parsedY)) {
                return deaultValue;
            }
            return (parsedY / parsedX) * 100;
        }
    }
    return deaultValue;
};
