import { SvgIconProps, makeStyles } from '@material-ui/core';
import { ErrorIcon, HelpIcon, WarningIcon } from 'materialbet-icons';
import * as React from 'react';
import { Banner, BannerProps } from '../Banner';
import { NoticeAlertIcon } from './NoticeAlertIcon';

export type AlertType = 'critical' | 'warning' | 'info' | 'notice';

export interface AlertBannerProps extends Omit<BannerProps, 'icon'> {
	type: AlertType;
	iconSize?: number;
}

const useStyles = makeStyles({
	icon: ({ iconSize }: { iconSize: number }) => ({
		width: iconSize,
		height: iconSize
	})
});

const iconMapping: {
	[key in AlertType]: React.FunctionComponent<SvgIconProps>;
} = {
	critical: ErrorIcon,
	warning: WarningIcon,
	notice: NoticeAlertIcon,
	info: HelpIcon
};

export const AlertBanner: React.FunctionComponent<AlertBannerProps> = ({
	type,
	iconSize = 37,
	...bannerProps
}) => {
	const classes = useStyles({ iconSize });
	const Icon = iconMapping[type];

	return <Banner icon={<Icon className={classes.icon} />} {...bannerProps} />;
};
