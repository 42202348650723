"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.authValidators = exports.birthDateValidator = exports.emailValidator = exports.passwordValidator = exports.nicknameValidator = exports.loginValidator = exports.isBirthDate = exports.isDateValid = exports.isPassword = exports.isNickname = exports.isEmail = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var React = __importStar(require("react"));
exports.isEmail = function (value) {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    return regex.test(value);
};
var MIN_NICKNAME_LENGTH = 3;
var MAX_NICKNAME_LENGTH = 15;
exports.isNickname = function (value) {
    var regex = new RegExp("^[-_a-zA-Z0-9]{" + MIN_NICKNAME_LENGTH + "," + MAX_NICKNAME_LENGTH + "}$");
    return regex.test(value);
};
var MIN_PASSWORD_LENGTH = 8;
exports.isPassword = function (value) {
    return value.length >= MIN_PASSWORD_LENGTH;
};
var YOU_MUST_BE_DEAD = 1900;
var isDate18orMoreYearsOld = function (dateIncome) {
    var date = '';
    var regExp = /\d{2}(?:\.|\/|-)\d{2}(?:\.|\/|-)\d{4}/g;
    if (regExp.test(dateIncome)) {
        var divider = dateIncome.substring(2, 3);
        var formatDate = dateIncome.split(divider);
        var birthDate_1 = formatDate[0];
        var birthMounth = formatDate[1];
        var birthYear_1 = formatDate[2];
        date = birthYear_1 + "-" + birthMounth + "-" + birthDate_1;
    }
    else {
        date = dateIncome;
    }
    var d = new Date();
    var maxBirthDate = new Date(d.setUTCFullYear(d.getUTCFullYear() - 18));
    var birthDate = new Date(date);
    var birthYear = birthDate.getFullYear();
    if (maxBirthDate.getFullYear() > birthYear &&
        birthYear > YOU_MUST_BE_DEAD) {
        return true;
    }
    if (maxBirthDate.getFullYear() === birthYear) {
        if (birthDate.getUTCMonth() < maxBirthDate.getUTCMonth()) {
            return true;
        }
        if (maxBirthDate.getUTCMonth() === birthDate.getUTCMonth()) {
            if (birthDate.getUTCDate() <= maxBirthDate.getUTCDate()) {
                return true;
            }
        }
    }
    return false;
};
// Date constructor accepts any day value and overflows it into the next month
// e.g. February 30th 2020 - but parses it to March 1st 2020.
// This function validates date correctness by checking the input is equal to the output
exports.isDateValid = function (day, monthIndex, year) {
    var date = new Date(year, monthIndex, day);
    return (date.getFullYear() === year &&
        date.getMonth() === monthIndex &&
        date.getDate() === day);
};
exports.isBirthDate = function (value) {
    return isDate18orMoreYearsOld(value);
};
exports.loginValidator = [
    {
        isValid: function (value) {
            return value ? exports.isEmail(value) || exports.isNickname(value) : true;
        },
        message: React.createElement(i18n_1.Translate, { label: "signIn.invalidNicknameEmail", ns: "player" })
    }
];
exports.nicknameValidator = [
    {
        isValid: function (value) { return (value ? exports.isNickname(value) : true); },
        message: React.createElement(i18n_1.Translate, { label: "signUp.nicknameInvalid", ns: "player" })
    }
];
exports.passwordValidator = [
    {
        isValid: function (value) { return (value ? exports.isPassword(value) : true); },
        message: React.createElement(i18n_1.Translate, { label: "security.passwordMustHave", ns: "player" })
    }
];
exports.emailValidator = [
    {
        isValid: function (value) { return (value ? exports.isEmail(value) : true); },
        message: React.createElement(i18n_1.Translate, { label: "signIn.invalidEmail", ns: "player" })
    }
];
exports.birthDateValidator = [
    {
        isValid: function (value) { return (value ? exports.isBirthDate(value) : true); },
        message: React.createElement(i18n_1.Translate, { label: "security.youAreTooYoung", ns: "player" })
    }
];
exports.authValidators = {
    login: exports.loginValidator,
    nickname: exports.nicknameValidator,
    password: exports.passwordValidator,
    email: exports.emailValidator,
    birthDate: exports.birthDateValidator
};
