import { Translate } from '@gaming-shell/i18n';
import {
	ButtonBase,
	Card,
	CardContent,
	CardMedia,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { PromoCardVariants } from '../PromoCard';

export interface SimplePromoCardProps {
	title: string;
	description: string;
	imageUrl?: string;
	href?: string;
	variant?: PromoCardVariants;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	card: {
		display: 'flex'
	},
	linkedCard: {
		'&:hover': {
			boxShadow: theme.shadows[6]
		}
	},
	horizontal: {
		flexDirection: 'row'
	},
	vertical: {
		flexDirection: 'column'
	},
	content: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column'
	},
	description: {
		paddingBottom: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	button: {
		alignItems: 'center',
		marginTop: 'auto',
		justifyContent: 'left'
	},
	icon: {
		color: theme.palette.primary.main
	}
}));

const useMediaStyles = makeStyles({
	horizontal: {
		width: '40%'
	},
	vertical: {
		maxHeight: '56.25%' // dont use fix heigh allow it be smaller to avoid overflow the card
	}
});

export const SimplePromoCard: React.FunctionComponent<SimplePromoCardProps> = ({
	title,
	description,
	variant = 'vertical',
	href,
	imageUrl,
	className
}) => {
	const classes = useStyles();
	const mediaClasses = useMediaStyles();
	const card = (
		<Card
			className={clsx(className, classes.card, classes[variant], {
				[classes.linkedCard]: href
			})}
		>
			{imageUrl && (
				<CardMedia
					component="img"
					alt={imageUrl}
					image={imageUrl}
					title={title}
					className={mediaClasses[variant]}
				/>
			)}
			<CardContent className={classes.content}>
				<Typography gutterBottom variant="h6">
					{title}
				</Typography>
				<Typography
					variant="body2"
					color="textSecondary"
					component="p"
					className={classes.description}
				>
					{description}
				</Typography>
				{href && (
					<ButtonBase
						className={classes.button}
						to={href}
						component={Link}
					>
						<Typography variant="body2" color="primary">
							<Translate label="actions.seeDetails" />
						</Typography>
						<NavigateNextIcon
							className={classes.icon}
							fontSize="small"
						/>
					</ButtonBase>
				)}
			</CardContent>
		</Card>
	);
	return href ? (
		<ButtonBase
			to={href}
			component={Link}
			className={clsx(className, classes[variant])}
		>
			{card}
		</ButtonBase>
	) : (
		card
	);
};
