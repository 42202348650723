import { Translate } from '@gaming-shell/i18n';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { PlayerRoutes } from 'materialbet-common';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useCurrency } from 'sports/utils/currency';

import { bitsIncludeSome } from '../../utils/bitmaskUtils';
import { betslipActions } from './betslipReducer';
import { BetslipStatusFlags } from './betslipReducer/BetslipStatusFlags';
import { getSelectionCount } from './betslipSelectors/betslipSelectors';
import { useBetslipDispatch } from './hooks/useBetslipDispatch';
import { useBetslipSelector } from './hooks/useBetslipSelector';
import { BetslipReducerStatus, StakeLocation } from './types';

export interface BetslipErrorActionButtonProps {
	onClick: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		fontSize: '0.75rem',
		margin: theme.spacing(-1, 0),
		alignSelf: 'center'
	}
}));
export const BetslipErrorActionButton: React.FunctionComponent<BetslipErrorActionButtonProps> = (
	props
) => {
	const { root } = useStyles();
	return (
		<Button
			variant="text"
			color="inherit"
			size="small"
			className={root}
			{...props}
		/>
	);
};

export const useCreateErrorActionButton = (
	error?: BetslipReducerStatus,
	stakeLocation?: StakeLocation
) => {
	const dispatch = useBetslipDispatch();
	const selectionCount = useBetslipSelector(getSelectionCount);
	const isSingleSelectionBetslip = selectionCount === 1;
	const history = useHistory();
	const currency = useCurrency();
	if (!error || !stakeLocation) {
		return null;
	}

	if (bitsIncludeSome(BetslipStatusFlags.StakeValidationStatus, error)) {
		const onClick = () =>
			dispatch(betslipActions.correctStake(stakeLocation));
		return (
			<BetslipErrorActionButton onClick={onClick}>
				<Translate label="actions.update" />
			</BetslipErrorActionButton>
		);
	}
	if (bitsIncludeSome(BetslipStatusFlags.CanBeRemoved, error)) {
		if (
			isSingleSelectionBetslip ||
			(bitsIncludeSome(BetslipStatusFlags.ParlayRestriction, error) &&
				!stakeLocation.straight)
		) {
			return null;
		}
		const onClick = () =>
			dispatch(
				stakeLocation.straight
					? betslipActions.removeSelection(
							stakeLocation.straight.selectionIndex
					  )
					: betslipActions.removeInvalidSelections()
			);
		return (
			<BetslipErrorActionButton onClick={onClick}>
				<Translate label="actions.remove" />
			</BetslipErrorActionButton>
		);
	}
	if (bitsIncludeSome(BetslipStatusFlags.InsufficientFund, error)) {
		if (ACCOUNT_FINANCE !== 'cashier') {
			return null;
		}
		const route = PlayerRoutes.cashierDeposit.create({ currency });
		const onClick = () => history.push(route);
		return (
			<BetslipErrorActionButton onClick={onClick}>
				<Translate label="actions.deposit" />
			</BetslipErrorActionButton>
		);
	}
	return null;
};
