import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

const Studios: React.FunctionComponent<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<path
				fill={props.fill}
				fillRule="nonzero"
				d="M12 2a.75.75 0 0 1 .743.648l.007.102v2.971l6.368 3.215a.763.763 0 0 1 .183.13l.04.042.018.02a.728.728 0 0 1 .131.234l.005.016a.53.53 0 0 1 .018.065l.006.036a.616.616 0 0 1 .01.097l.001.015v7.796l2.085 1.164a.75.75 0 0 1 .334.928l-.044.092a.75.75 0 0 1-1.02.29l-2.123-1.185-6.423 3.254-.013.007-.05.022-.042.015a.753.753 0 0 1-.276.037h-.011l-.047-.006-.044-.007a.68.68 0 0 1-.086-.023l-.054-.02-.01-.004.008.003-.014-.006.004.002-.006-.002-.024-.012-.013-.006-6.424-3.254-2.122 1.185a.75.75 0 0 1-.964-.205l-.056-.085a.75.75 0 0 1 .204-.965l.086-.055 2.085-1.165v-7.78l.001-.018-.001.002v-.002l.001-.028v.016l.002-.039.008-.06.007-.033a.59.59 0 0 1 .017-.066l.006-.016a.688.688 0 0 1 .072-.153l.018-.027a.559.559 0 0 1 .04-.053l.018-.021a.642.642 0 0 1 .114-.105l.043-.03.025-.014.025-.015.016-.008 6.368-3.215V2.75a.75.75 0 0 1 .648-.743L12 2zm-6.03 8.826v6.54l5.28 2.675V13.5l-5.28-2.674zm12.06 0L12.75 13.5v6.54l5.28-2.673v-6.541zM12 7.023L6.882 9.607 12 12.198l5.117-2.592L12 7.023zM4.838 8.96l-.02.012.007-.004.013-.008z"
			/>
		</SvgIcon>
	);
};
export default Studios;
