export interface TokenCookieError {
	hasError: boolean;
	messageKey: string;
}

export const getTokenCookieError = (res: Response): TokenCookieError => {
	switch (res.status) {
		case 451:
			return {
				hasError: true,
				messageKey: 'player:descriptions.locationRestricted'
			};
		default:
			return {
				hasError: true,
				messageKey: 'common:application.descriptions.generalError'
			};
	}
};
