import {
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	Card as MuiCard
} from '@material-ui/core';
import {
	CardClassKey as MuiCardClassKey,
	CardProps as MuiCardProps
} from '@material-ui/core/Card';
import { CardActionsProps } from '@material-ui/core/CardActions';
import { CardContentProps } from '@material-ui/core/CardContent';
import { CardHeaderProps } from '@material-ui/core/CardHeader';
import { CardMediaProps } from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import * as React from 'react';

export type CardClassKey = MuiCardClassKey | 'media' | 'content' | 'actions';

export interface BaseCardProps extends MuiCardProps {
	imageUrl?: string;
	cardTitle?: string;
	cardContentProps?: CardContentProps;
	cardHeaderProps?: CardHeaderProps;
	cardMediaProps?: CardMediaProps;
	cardActionsProps?: CardActionsProps;
	cardHeader?: React.ReactNode;
	cardMedia?: React.ReactNode;
	cardHeaderContent?: React.ReactNode;
	cardActions?: React.ReactNode;
	cardActionItems?: React.ReactNode;
	collapseElement?: React.ReactNode;
	classes?: Partial<Record<CardClassKey, string>>;
	skipCardContentWrapper?: boolean;
}

const useStyles = makeStyles({
	media: {
		height: 0,
		paddingTop: '56.25%' // 16:9
	}
});

export const BaseCard: React.FunctionComponent<BaseCardProps> = ({
	imageUrl,
	cardTitle,
	cardActions,
	cardActionItems,
	cardActionsProps,
	cardHeader,
	cardHeaderProps,
	cardHeaderContent,
	cardMedia,
	cardMediaProps,
	cardContentProps,
	collapseElement,
	children,
	skipCardContentWrapper,
	classes: {
		media: mediaClass,
		actions: actionsClass,
		content: contentClass,
		...classes
	} = {},
	...otherProps
}) => {
	const innerClasses = useStyles();

	return (
		<MuiCard classes={classes} {...otherProps}>
			{cardHeader ||
				(cardHeaderContent && (
					<CardHeader {...cardHeaderProps}>
						{cardHeaderContent}
					</CardHeader>
				))}
			{cardMedia ||
				(imageUrl && (
					<CardMedia
						image={imageUrl}
						title={cardTitle}
						className={clsx(innerClasses.media, mediaClass)}
						{...cardMediaProps}
					/>
				))}
			{skipCardContentWrapper ? (
				children
			) : (
				<CardContent className={contentClass} {...cardContentProps}>
					{children}
				</CardContent>
			)}
			{cardActions ||
				(cardActionItems && (
					<CardActions className={actionsClass} {...cardActionsProps}>
						{cardActionItems}
					</CardActions>
				))}
			{collapseElement}
		</MuiCard>
	);
};
