import * as React from 'react';
import { Provider } from 'react-redux';

import { BRWidgetSideEffect } from './components/displays/BRWidgetSideEffect';
import store from './store';

const UnprovidedSideEffects: React.FunctionComponent<unknown> = () => {
	return <BRWidgetSideEffect />;
};

export const SportsSideEffects: React.FunctionComponent = () => {
	return (
		<Provider store={store}>
			<UnprovidedSideEffects />
		</Provider>
	);
};
