import { betslipStateVersion } from '../betslipReducer/betslipReducer';
import { BetslipState, PersistedBetslip } from '../types';

export const persistBetslip = (betslip: BetslipState) => {
	const peristedBetslip: PersistedBetslip = {
		version: betslipStateVersion,
		betslip
	};
	const stringified = JSON.stringify(peristedBetslip);
	document.cookie = `betslip=${stringified}; path=/; max-age=${24 *
		60 *
		60};`;
};
