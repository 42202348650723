"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLocaleParam = exports.getCurrentLang = exports.withLocaleParam = exports.localePathParam = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var indicators_1 = require("./indicators");
exports.localePathParam = indicators_1.ParamIndicators.PATH + "locale";
exports.withLocaleParam = function (path) {
    return ENABLE_LOCALIZED_ROUTES ? exports.localePathParam + "/" + path : path;
};
exports.getCurrentLang = function () { return i18n_1.getDefaultLanguage(); };
exports.setLocaleParam = function (path) {
    return path.replace(exports.localePathParam, exports.getCurrentLang());
};
