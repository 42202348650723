import { Translate } from '@gaming-shell/i18n';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { fillArray } from 'materialbet-common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SportType } from 'sports/config/sport';
import { RootState } from 'sports/modules/root';
import { loads } from 'sports/modules/sports';
import { loadedOnce } from 'sports/selectors/loading';
import { useFeaturedSports } from 'sports/utils/sports';

import {
	SportSideMenuItem,
	SportSideMenuItemSkeleton
} from '../SportSideMenuItem';

/** @description a component that contain only the main item for featured sports*/
let FeaturedSportsList: React.FC = () => {
	const sports = useFeaturedSports();
	// We dont want to fetch again we just need the loading state here
	const name = loads.sportsList;
	const loaded = useSelector(
		(state: RootState) => !!loadedOnce(state, { name })
	);
	const sportsList = React.useMemo(
		() =>
			sports.map((sportKey: SportType) => (
				<SportSideMenuItem key={sportKey} sportKey={sportKey} />
			)),
		[sports]
	);
	const popular = (
		<ListItem dense>
			<Typography variant="caption" color="textSecondary">
				<Translate label="descriptions.popular" />
			</Typography>
		</ListItem>
	);
	if (!loaded) {
		return (
			<React.Fragment>
				{popular}
				{fillArray(3, (i) => (
					<SportSideMenuItemSkeleton key={i} />
				))}
			</React.Fragment>
		);
	}
	if (!sportsList.length) {
		return null;
	}
	return (
		<React.Fragment>
			{popular}
			{sportsList}
		</React.Fragment>
	);
};

FeaturedSportsList = React.memo(FeaturedSportsList);
export { FeaturedSportsList };
