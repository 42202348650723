"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Trans = void 0;
var React = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var phraseApp_1 = require("./phraseApp");
var config_1 = require("./phraseApp/config");
var tFunction_1 = require("./phraseApp/tFunction");
exports.Trans = function (props) {
    var ns = props.ns, label = props.label, components = props.components, values = props.values;
    var t = phraseApp_1.useTranslation(ns).t;
    var config = config_1.getConfig();
    if (!config) {
        return (React.createElement(react_i18next_1.Trans, { defaults: t(label, values), ns: ns, components: components }));
    }
    var marshalledT = tFunction_1.generateTFunction(t, config);
    var defaults = marshalledT(label, values);
    return React.createElement(react_i18next_1.Trans, { defaults: defaults, ns: ns, components: components });
};
