import { Translate } from '@gaming-shell/i18n';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import { PlayerRoutes } from 'materialbet-common';
import * as React from 'react';

import { PlayerNickname } from '../PlayerNickname';

const playerRoute = PlayerRoutes.account.create();
const isPlayerRoute = PlayerRoutes.account.matchesExactly();
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2.5, 2),
		opacity: '1 !important', // Override the disabled opacity
		display: 'block',
		background: 'unset !important' // Override Hover state
	}
}));

let PlayerGreeting: React.FC = () => {
	const { root } = useStyles();
	return (
		<ListItem
			button
			component="a"
			href={playerRoute}
			className={root}
			disabled={isPlayerRoute}
			disableRipple
		>
			<Typography variant="h6" noWrap>
				<Translate label="content.welcome" ns="player" />
			</Typography>
			<Typography variant="body2" color="textSecondary" noWrap>
				<PlayerNickname />
			</Typography>
		</ListItem>
	);
};

PlayerGreeting = React.memo(PlayerGreeting);
export { PlayerGreeting };
