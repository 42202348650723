"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeParams = exports.filterParams = exports.createQueryParams = exports.createRoutePathStrings = exports.createParam = void 0;
var __1 = require("..");
exports.createParam = function (_a) {
    var param = _a.param, indicator = _a.indicator;
    return "" + indicator + param;
};
exports.createRoutePathStrings = function (routePathElements) {
    return routePathElements.map(function (routePathElement) {
        if (typeof routePathElement === 'string')
            return routePathElement;
        else {
            var pathParam = routePathElement.pathParam;
            var queryParam = routePathElement.queryParam;
            var param = {
                param: pathParam || queryParam,
                indicator: pathParam
                    ? __1.ParamIndicators.PATH
                    : __1.ParamIndicators.QUERY
            };
            return exports.createParam(param);
        }
    });
};
exports.createQueryParams = function (routePathStrings) {
    return routePathStrings
        .filter(function (path) { return path.startsWith(__1.ParamIndicators.QUERY); })
        .map(function (param, i) {
        var paramWithoutIndicator = param.replace(__1.ParamIndicators.QUERY, '');
        if (i === 0) {
            return "\\" + __1.ParamIndicators.QUERY + paramWithoutIndicator + "=:" + paramWithoutIndicator;
        }
        return paramWithoutIndicator + "=:" + paramWithoutIndicator;
    });
};
exports.filterParams = function (routePathStrings, indicator) { return routePathStrings.filter(function (path) { return !path.startsWith(indicator); }); };
var removeParamsRegex = /(\/:\w+\?)|(\/:\w+)|(\\\?.*)/g;
exports.removeParams = function (routePathWithParams) {
    return routePathWithParams.replace(removeParamsRegex, '');
};
