"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FullscreenButton = void 0;
var core_1 = require("@material-ui/core");
var materialbet_icons_1 = require("materialbet-icons");
var React = __importStar(require("react"));
var FullscreenButton = function (props) {
    var active = props.active, onClick = props.onClick, className = props.className, fontSize = props.fontSize, color = props.color;
    var Icon = active ? materialbet_icons_1.SmallscreenIcon : materialbet_icons_1.FullscreenIcon;
    return (React.createElement(core_1.IconButton, { size: "small", onClick: onClick, className: className, "data-testid": "FullscreenButton" },
        React.createElement(Icon, { color: color, fontSize: fontSize })));
};
exports.FullscreenButton = FullscreenButton;
exports.FullscreenButton = FullscreenButton = React.memo(FullscreenButton);
