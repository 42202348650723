"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSaveData = void 0;
exports.getSaveData = function (initialSaveDataStatus) {
    var _a;
    if (initialSaveDataStatus === void 0) { initialSaveDataStatus = null; }
    var saveData = (_a = navigator === null || navigator === void 0 ? void 0 : navigator.connection) === null || _a === void 0 ? void 0 : _a.saveData;
    var unsupported = saveData === undefined;
    return {
        unsupported: unsupported,
        saveData: unsupported ? initialSaveDataStatus : saveData
    };
};
