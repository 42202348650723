import { Translate } from '@gaming-shell/i18n';
import * as React from 'react';

import { betslipActions } from '../betslipReducer';
import { useBetslipDispatch } from '../hooks/useBetslipDispatch';
import { BigBetslipActionPanelButton } from './BigBetslipActionPanelButton';

export const BigBetSlipAcceptChangesButton: React.FunctionComponent<unknown> = () => {
	const dispatch = useBetslipDispatch();
	const onClick = () => dispatch(betslipActions.applyChanges());

	return (
		<BigBetslipActionPanelButton color="primary" onClick={onClick}>
			<Translate label="betting.acceptAllChanges" ns="sports" />
		</BigBetslipActionPanelButton>
	);
};
