import { makeStyles, Theme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

const facebookColor = '#3b5998';
const facebookColorHover = '#2f477a';

const twitterColor = '#55acee';
const twitterColorHover = '#007dbb';

const telegramColor = '#0088cc';
const telegramColorHover = '#005b9b';

const whatsappColor = '#25D366';
const whatsappColorHover = '#00a038';

export const useStyles = makeStyles((theme: Theme) => ({
	socialMediaButtonWrapper: {
		display: 'inline-flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center'
	},
	socialMediaLabel: {
		marginTop: theme.spacing(1)
	}
}));
export const useFacebookStyles = makeStyles((theme: Theme) => ({
	root: {
		color: theme.palette.common.white,
		backgroundColor: facebookColor,
		fillColor: '#fff',
		'&:hover': {
			backgroundColor: facebookColorHover,
			'@media (hover: none)': {
				backgroundColor: facebookColorHover
			}
		}
	}
}));

export const useGoogleStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: theme.palette.common.white,
		color: fade(theme.palette.common.black, 0.7),
		border: 'none'
	},
	disabled: {
		backgroundColor: fade(theme.palette.common.white, 0.84),
		color: `${fade(theme.palette.common.black, 0.44)} !important`
	}
}));

export const useTwitterStyles = makeStyles((theme: Theme) => ({
	root: {
		color: theme.palette.common.white,
		backgroundColor: twitterColor,
		fillColor: '#fff',
		'&:hover': {
			backgroundColor: twitterColorHover,
			'@media (hover: none)': {
				backgroundColor: twitterColorHover
			}
		}
	}
}));
export const useTelegramStyles = makeStyles((theme: Theme) => ({
	root: {
		color: theme.palette.common.white,
		backgroundColor: telegramColor,
		fillColor: '#fff',
		'&:hover': {
			backgroundColor: telegramColorHover,
			'@media (hover: none)': {
				backgroundColor: telegramColorHover
			}
		}
	}
}));
export const useWhatsAppStyles = makeStyles((theme: Theme) => ({
	root: {
		color: theme.palette.common.white,
		backgroundColor: whatsappColor,
		fillColor: '#fff',
		'&:hover': {
			backgroundColor: whatsappColorHover,
			'@media (hover: none)': {
				backgroundColor: whatsappColorHover
			}
		}
	}
}));
