"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyCodes = void 0;
var KeyCodes;
(function (KeyCodes) {
    KeyCodes[KeyCodes["Backspace"] = 8] = "Backspace";
    KeyCodes[KeyCodes["Tab"] = 9] = "Tab";
    KeyCodes[KeyCodes["Enter"] = 13] = "Enter";
    KeyCodes[KeyCodes["Shift"] = 16] = "Shift";
    KeyCodes[KeyCodes["Ctrl"] = 17] = "Ctrl";
    KeyCodes[KeyCodes["Alt"] = 18] = "Alt";
    KeyCodes[KeyCodes["Pause"] = 19] = "Pause";
    KeyCodes[KeyCodes["CapsLock"] = 20] = "CapsLock";
    KeyCodes[KeyCodes["Escape"] = 27] = "Escape";
    KeyCodes[KeyCodes["Space"] = 32] = "Space";
    KeyCodes[KeyCodes["PageUp"] = 33] = "PageUp";
    KeyCodes[KeyCodes["PageDown"] = 34] = "PageDown";
    KeyCodes[KeyCodes["End"] = 35] = "End";
    KeyCodes[KeyCodes["Home"] = 36] = "Home";
    KeyCodes[KeyCodes["LeftArrow"] = 37] = "LeftArrow";
    KeyCodes[KeyCodes["UpArrow"] = 38] = "UpArrow";
    KeyCodes[KeyCodes["RightArrow"] = 39] = "RightArrow";
    KeyCodes[KeyCodes["DownArrow"] = 40] = "DownArrow";
    KeyCodes[KeyCodes["Insert"] = 45] = "Insert";
    KeyCodes[KeyCodes["Delete"] = 46] = "Delete";
    KeyCodes[KeyCodes["Key_0"] = 48] = "Key_0";
    KeyCodes[KeyCodes["Key_1"] = 49] = "Key_1";
    KeyCodes[KeyCodes["Key_2"] = 50] = "Key_2";
    KeyCodes[KeyCodes["Key_3"] = 51] = "Key_3";
    KeyCodes[KeyCodes["Key_4"] = 52] = "Key_4";
    KeyCodes[KeyCodes["Key_5"] = 53] = "Key_5";
    KeyCodes[KeyCodes["Key_6"] = 54] = "Key_6";
    KeyCodes[KeyCodes["Key_7"] = 55] = "Key_7";
    KeyCodes[KeyCodes["Key_8"] = 56] = "Key_8";
    KeyCodes[KeyCodes["Key_9"] = 57] = "Key_9";
    KeyCodes[KeyCodes["Key_A"] = 65] = "Key_A";
    KeyCodes[KeyCodes["Key_B"] = 66] = "Key_B";
    KeyCodes[KeyCodes["Key_C"] = 67] = "Key_C";
    KeyCodes[KeyCodes["Key_D"] = 68] = "Key_D";
    KeyCodes[KeyCodes["Key_E"] = 69] = "Key_E";
    KeyCodes[KeyCodes["Key_F"] = 70] = "Key_F";
    KeyCodes[KeyCodes["Key_G"] = 71] = "Key_G";
    KeyCodes[KeyCodes["Key_H"] = 72] = "Key_H";
    KeyCodes[KeyCodes["Key_I"] = 73] = "Key_I";
    KeyCodes[KeyCodes["Key_J"] = 74] = "Key_J";
    KeyCodes[KeyCodes["Key_K"] = 75] = "Key_K";
    KeyCodes[KeyCodes["Key_L"] = 76] = "Key_L";
    KeyCodes[KeyCodes["Key_M"] = 77] = "Key_M";
    KeyCodes[KeyCodes["Key_N"] = 78] = "Key_N";
    KeyCodes[KeyCodes["Key_O"] = 79] = "Key_O";
    KeyCodes[KeyCodes["Key_P"] = 80] = "Key_P";
    KeyCodes[KeyCodes["Key_Q"] = 81] = "Key_Q";
    KeyCodes[KeyCodes["Key_R"] = 82] = "Key_R";
    KeyCodes[KeyCodes["Key_S"] = 83] = "Key_S";
    KeyCodes[KeyCodes["Key_T"] = 84] = "Key_T";
    KeyCodes[KeyCodes["Key_U"] = 85] = "Key_U";
    KeyCodes[KeyCodes["Key_V"] = 86] = "Key_V";
    KeyCodes[KeyCodes["Key_W"] = 87] = "Key_W";
    KeyCodes[KeyCodes["Key_X"] = 88] = "Key_X";
    KeyCodes[KeyCodes["Key_Y"] = 89] = "Key_Y";
    KeyCodes[KeyCodes["Key_Z"] = 90] = "Key_Z";
    KeyCodes[KeyCodes["LeftMeta"] = 91] = "LeftMeta";
    KeyCodes[KeyCodes["RightMeta"] = 92] = "RightMeta";
    KeyCodes[KeyCodes["Select"] = 93] = "Select";
    KeyCodes[KeyCodes["Numpad_0"] = 96] = "Numpad_0";
    KeyCodes[KeyCodes["Numpad_1"] = 97] = "Numpad_1";
    KeyCodes[KeyCodes["Numpad_2"] = 98] = "Numpad_2";
    KeyCodes[KeyCodes["Numpad_3"] = 99] = "Numpad_3";
    KeyCodes[KeyCodes["Numpad_4"] = 100] = "Numpad_4";
    KeyCodes[KeyCodes["Numpad_5"] = 101] = "Numpad_5";
    KeyCodes[KeyCodes["Numpad_6"] = 102] = "Numpad_6";
    KeyCodes[KeyCodes["Numpad_7"] = 103] = "Numpad_7";
    KeyCodes[KeyCodes["Numpad_8"] = 104] = "Numpad_8";
    KeyCodes[KeyCodes["Numpad_9"] = 105] = "Numpad_9";
    KeyCodes[KeyCodes["Multiply"] = 106] = "Multiply";
    KeyCodes[KeyCodes["Add"] = 107] = "Add";
    KeyCodes[KeyCodes["Subtract"] = 109] = "Subtract";
    KeyCodes[KeyCodes["Decimal"] = 110] = "Decimal";
    KeyCodes[KeyCodes["Divide"] = 111] = "Divide";
    KeyCodes[KeyCodes["F1"] = 112] = "F1";
    KeyCodes[KeyCodes["F2"] = 113] = "F2";
    KeyCodes[KeyCodes["F3"] = 114] = "F3";
    KeyCodes[KeyCodes["F4"] = 115] = "F4";
    KeyCodes[KeyCodes["F5"] = 116] = "F5";
    KeyCodes[KeyCodes["F6"] = 117] = "F6";
    KeyCodes[KeyCodes["F7"] = 118] = "F7";
    KeyCodes[KeyCodes["F8"] = 119] = "F8";
    KeyCodes[KeyCodes["F9"] = 120] = "F9";
    KeyCodes[KeyCodes["F10"] = 121] = "F10";
    KeyCodes[KeyCodes["F11"] = 122] = "F11";
    KeyCodes[KeyCodes["F12"] = 123] = "F12";
    KeyCodes[KeyCodes["NumLock"] = 144] = "NumLock";
    KeyCodes[KeyCodes["ScrollLock"] = 145] = "ScrollLock";
    KeyCodes[KeyCodes["Semicolon"] = 186] = "Semicolon";
    KeyCodes[KeyCodes["Equals"] = 187] = "Equals";
    KeyCodes[KeyCodes["Comma"] = 188] = "Comma";
    KeyCodes[KeyCodes["Dash"] = 189] = "Dash";
    KeyCodes[KeyCodes["Period"] = 190] = "Period";
    KeyCodes[KeyCodes["ForwardSlash"] = 191] = "ForwardSlash";
    KeyCodes[KeyCodes["GraveAccent"] = 192] = "GraveAccent";
    KeyCodes[KeyCodes["OpenBracket"] = 219] = "OpenBracket";
    KeyCodes[KeyCodes["BackSlash"] = 220] = "BackSlash";
    KeyCodes[KeyCodes["CloseBracket"] = 221] = "CloseBracket";
    KeyCodes[KeyCodes["SingleQuote"] = 222] = "SingleQuote";
})(KeyCodes = exports.KeyCodes || (exports.KeyCodes = {}));
