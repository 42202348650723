"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useActivePageApp = exports.useActivePageBase = void 0;
var materialbet_common_1 = require("materialbet-common");
var react_router_1 = require("react-router");
exports.useActivePageBase = function () {
    var pathname = react_router_1.useLocation().pathname;
    if (materialbet_common_1.CasinoRoutes.root.matches(pathname)) {
        return 'casino';
    }
    if (materialbet_common_1.SportsRoutes.root.matches(pathname)) {
        return 'sports';
    }
    if (ENABLE_ESPORTS_SECTION && materialbet_common_1.EsportsRoutes.root.matches(pathname)) {
        return 'esports';
    }
    if (materialbet_common_1.PlayerRoutes.root.matches(pathname) ||
        materialbet_common_1.AuthRoutes.logout.matches(pathname)) {
        return 'player';
    }
    return 'sports';
};
exports.useActivePageApp = function () {
    var pathname = react_router_1.useLocation().pathname;
    if (materialbet_common_1.CasinoRoutes.root.matches(pathname)) {
        return 'casino';
    }
    if (materialbet_common_1.SportsRoutes.root.matches(pathname)) {
        return 'sports';
    }
    if (ENABLE_ESPORTS_SECTION && materialbet_common_1.EsportsRoutes.root.matches(pathname)) {
        return 'esports';
    }
    return SUPPORTED_APPS[0];
};
