import { isDesktop, isMobile, isSafari } from '@gaming-shell/device';
import { Theme, makeStyles } from '@material-ui/core';
import MuiTextField, {
	TextFieldProps as MuiTextFieldProps
} from '@material-ui/core/TextField';
import clsx from 'clsx';
import * as React from 'react';

export interface TextFieldProps extends Omit<MuiTextFieldProps, 'onChange'> {
	onChange?: (value: string) => void;
	disableCapitalization?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	inputCaret: {
		background: isDesktop()
			? theme.palette.background.paper
			: theme.palette.background.default,
		'&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
			content: '"autofill-detector"',
			WebkitBoxShadow: `0 0 0 100px ${
				isDesktop()
					? theme.palette.background.paper
					: theme.palette.background.default
			} inset !important`,
			backgroundClip: 'content-box !important',
			'-webkit-text-fill-color': theme.palette.text.primary
		}
	},
	// Firefox autocomplete fix
	inputFilterNone: {
		filter: 'none'
	}
}));

const disableCapitalizationInputProps = {
	autoCorrect: 'off',
	autoCapitalize: 'none',
	autoComplete: 'none'
};

export const TextField: React.FunctionComponent<TextFieldProps> = ({
	InputProps = {},
	onChange,
	variant = 'outlined',
	type = 'text',
	disableCapitalization,
	inputProps,
	...props
}) => {
	const styles = useStyles();

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			onChange && onChange(event.target.value as string);
		},
		[onChange]
	);

	const inputClasses = InputProps.classes;
	const inputClass = clsx(
		styles.inputCaret,
		styles.inputFilterNone,
		inputClasses && inputClasses.input
	);

	if (type === 'date' && isMobile() && isSafari()) {
		return (
			<MuiTextField
				{...props}
				type={type}
				onChange={handleChange}
				inputProps={inputProps}
				variant={variant as 'outlined'} // https://github.com/mui-org/material-ui/issues/15697
			/>
		);
	}

	return (
		<MuiTextField
			{...props}
			type={type}
			variant={variant as 'outlined'} // https://github.com/mui-org/material-ui/issues/15697
			onChange={handleChange}
			inputProps={{
				...inputProps,
				...(disableCapitalization
					? disableCapitalizationInputProps
					: {})
			}}
			InputProps={{
				...InputProps,
				classes: {
					...inputClasses,
					input: inputClass
				}
			}}
		/>
	);
};
