import { Trans, useTranslation } from '@gaming-shell/i18n';
import { makeStyles, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import * as React from 'react';
import { PromotionCounter } from 'sports/components/PromotionCounter';

const useStyles = makeStyles((theme) => ({
	footer: {
		marginTop: theme.spacing(1.5)
	},
	link: {
		color: theme.palette.primary.main
	}
}));

let ZeroMarginOpeningSoonCounter: React.FC = () => {
	const t = useTranslation();
	const title = t('player:promotions.descriptions.timeWindowOpeningSoon');
	const classes = useStyles();
	const footer = (
		<Trans
			ns="player"
			label="player:promotions.descriptions.promotitionAnnouncement"
			values={{ promotion: t('player:promotions.zeroMarginOdds') }}
			components={[
				<Link
					href={TWITTER_URL}
					key="twitter_url"
					className={classes.link}
					target="_blank"
				>
					Twitter
				</Link>,
				<Link
					href={DISCORD_URL}
					key="discord_url"
					className={classes.link}
					target="_blank"
				>
					Discord
				</Link>
			]}
		/>
	);
	return (
		<>
			<PromotionCounter title={title} current={0} total={1000} isLocked />
			<Typography
				variant="caption"
				color="textSecondary"
				className={classes.footer}
			>
				{footer}
			</Typography>
		</>
	);
};

ZeroMarginOpeningSoonCounter = React.memo(ZeroMarginOpeningSoonCounter);

export { ZeroMarginOpeningSoonCounter };
