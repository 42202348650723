import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

const allMarkets = {
	popular: [
		{
			marketType: MarketType.esport_fifa_match_odds
		},
		{
			marketType: MarketType.esport_fifa_asian_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.esport_fifa_total_goals,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.esport_fifa_corner_nr
		},
		{
			marketType: MarketType.esport_fifa_draw_no_bet
		},
		{
			marketType: MarketType.esport_fifa_goal_nr
		},
		{
			marketType: MarketType.esport_fifa_booking_nr
		},
		{
			marketType: MarketType.esport_fifa_correct_score,
			view: MarketView.CorrectScore
		}
	],
	goals: [
		{
			marketType: MarketType.esport_fifa_both_teams_to_score
		},
		{
			marketType: MarketType.esport_fifa_team_total_goals,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.esport_fifa_team_clean_sheet
		},
		{
			marketType: MarketType.esport_fifa_team_win_to_nil
		},
		{
			marketType: MarketType.esport_fifa_halftime_fulltime_result
		}
	],
	specials: [
		{
			marketType: MarketType.esport_fifa_corner_match_odds
		},
		{
			marketType: MarketType.esport_fifa_corner_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.esport_fifa_total_corners
		},
		{
			marketType: MarketType.esport_fifa_double_chance
		},
		{
			marketType: MarketType.esport_fifa_booking_match_odds
		},
		{
			marketType: MarketType.esport_fifa_total_booking_points
		},
		{
			marketType: MarketType.esport_fifa_total_bookings
		}
	],
	firstHalf: [
		{
			marketType: MarketType.esport_fifa_match_odds_period_first_half
		},
		{
			marketType: MarketType.esport_fifa_asian_handicap_period_first_half,
			view: MarketView.Handicap
		},
		{
			marketType:
				MarketType.esport_fifa_exact_total_goals_period_first_half,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.esport_fifa_correct_score_period_1st_half,
			view: MarketView.CorrectScore
		},
		{
			marketType: MarketType.esport_fifa_total_goals_period_first_half,
			view: MarketView.Goals
		},
		{
			marketType:
				MarketType.esport_fifa_team_total_goals_period_first_half,
			view: MarketView.Goals
		}
	],
	secondHalf: [
		{
			marketType: MarketType.esport_fifa_match_odds_period_second_half
		},
		{
			marketType:
				MarketType.esport_fifa_asian_handicap_period_second_half,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.esport_fifa_total_goals_period_second_half,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.esport_fifa_correct_score_period_2nd_half,
			view: MarketView.CorrectScore
		},
		{
			marketType:
				MarketType.esport_fifa_exact_total_goals_period_second_half,
			view: MarketView.Goals
		},
		{
			marketType:
				MarketType.esport_fifa_team_total_goals_period_second_half,
			view: MarketView.Goals
		}
	],
	extraTime: [
		{
			marketType: MarketType.esport_fifa_match_odds_period_extratime
		},
		{
			marketType: MarketType.esport_fifa_asian_handicap_period_extratime,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.esport_fifa_total_goals_period_extratime,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.esport_fifa_penalty_shootout_odd_even
		}
	]
};

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: allMarkets.popular
	},
	{
		label: 'markets.goals',
		markets: allMarkets.goals
	},
	{
		label: 'markets.specials',
		markets: allMarkets.specials
	},
	{
		label: 'markets.firstHalf',
		markets: allMarkets.firstHalf
	},
	{
		label: 'markets.secondHalf',
		markets: allMarkets.secondHalf
	},
	{
		label: 'markets.extraTime',
		markets: allMarkets.extraTime
	},
	{
		label: 'descriptions.all',
		markets: [
			...allMarkets.popular,
			...allMarkets.goals,
			...allMarkets.specials,
			...allMarkets.firstHalf,
			...allMarkets.secondHalf,
			...allMarkets.extraTime
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.esport_fifa_match_odds
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.esport_fifa_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
