import { makeStyles, Theme } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import {
	createDynamicStyle,
	makeDynamicStyles,
	withHook
} from 'materialbet-common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { EventMetadata } from 'sports/api/sports';
import { EventTeamInfo } from 'sports/components/displays/EventTeamInfo';
import { PrimaryText } from 'sports/components/displays/Text';
import { Players } from 'sports/components/eventList/Event';
import { EventListVariant } from 'sports/components/eventList/types';
import { SportType } from 'sports/config/sport';
import {
	getCompetitorAwayName,
	getCompetitorHomeName,
	getEventMetadata,
	getPlayers,
	getStatus
} from 'sports/selectors/eventSelectors';
import { showEventPitchers } from 'sports/utils/event';
import { findStartingPitchers } from 'sports/utils/playerUtils';
import { DeepReadonly } from 'utility-types';

const useStyles = makeStyles((theme: Theme) => ({
	vsSpacing: {
		textAlign: 'center',
		margin: theme.spacing(0.5),
		maxWidth: 'max-content'
	}
}));

const useVariantStyles = makeDynamicStyles({
	root: createDynamicStyle(
		{
			display: 'grid',
			alignItems: 'center',
			overflow: 'hidden',
			width: '100%'
		},
		{
			wide: {
				gridTemplateColumns: 'auto auto minmax(0, 1fr)',
				gridAutoFlow: 'column'
			},
			narrow: {
				gridTemplateColumns: '100%',
				gridAutoFlow: 'row'
			}
		}
	)
});

export interface EventTeamsUiProps {
	sportKey: string;
	homeTeam: string;
	awayTeam: string;
	eventStatus?: DeepReadonly<EventMetadata['eventStatus']>;
	variant?: EventListVariant;
	textVariant?: TypographyProps['variant'];
	metadata?: DeepReadonly<EventMetadata>;
	playerNames?: {
		home?: string;
		away?: string;
	};
}

export const EventTeamsUi: React.FunctionComponent<EventTeamsUiProps> = ({
	homeTeam,
	awayTeam,
	sportKey,
	metadata,
	variant = 'wide',
	textVariant,
	playerNames
}) => {
	const classes = useStyles();
	const { root } = useVariantStyles(variant);
	const customTextVariant = textVariant
		? textVariant
		: variant === 'narrow'
		? 'caption'
		: 'body2';
	return (
		<div className={root}>
			<EventTeamInfo
				team={homeTeam}
				playerNames={playerNames?.home}
				sportKey={sportKey}
				eventStatus={metadata && metadata.eventStatus}
				score={metadata && metadata.score}
				setScores={metadata && metadata.setScores}
				variant={variant}
				textVariant={textVariant}
			/>
			{variant !== 'narrow' && (
				<div className={classes.vsSpacing}>
					<PrimaryText variant={customTextVariant}>v</PrimaryText>
				</div>
			)}
			<EventTeamInfo
				team={awayTeam}
				playerNames={playerNames?.away}
				sportKey={sportKey}
				eventStatus={metadata && metadata.eventStatus}
				score={metadata && metadata.score}
				setScores={metadata && metadata.setScores}
				away
				variant={variant}
				textVariant={textVariant}
			/>
		</div>
	);
};

export const EventTeams = withHook(
	({ eventId, sportKey }: { eventId: number; sportKey: SportType }) => {
		const status = useSelector(getStatus(eventId)) || '';
		const homeTeam = useSelector(getCompetitorHomeName(eventId)) || '';
		const awayTeam = useSelector(getCompetitorAwayName(eventId)) || '';
		const players = useSelector(getPlayers(eventId)) as Players;
		const metadata = useSelector(
			getEventMetadata(eventId)
		) as EventMetadata;
		const { eventStatus } = metadata || {};
		const playerNames = findStartingPitchers(
			showEventPitchers(status) ? players : {}
		);
		return {
			sportKey,
			homeTeam,
			awayTeam,
			players,
			playerNames,
			metadata,
			eventStatus
		};
	}
)(EventTeamsUi);
