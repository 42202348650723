import { Translate } from '@gaming-shell/i18n';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { LinkButtonBase } from 'materialbet-common';
import * as React from 'react';
import { MarketType } from 'sports-sdk';
import { SportNameTranslation } from 'sports/components/displays/SportNameTranslation';
import { EventList } from 'sports/components/eventList';
import {
	EventListFormatProps,
	EventListVariant
} from 'sports/components/eventList/types';
import { PaperVariant } from 'sports/components/materialWrapper/types';
import { SportType } from 'sports/config/sport';
import { defaultEventListLimit } from 'sports/config/sportsapi';
import { EventTreeMeta } from 'sports/modules/sports/sportsMeta';
import {
	getEventGroupsEventsNumber,
	trimEventGroups
} from 'sports/utils/event';
import { sportRoute } from 'sports/utils/route';

export interface LiveEventListProps extends EventListFormatProps {
	sport?: EventTreeMeta; //Contain the competition list and id of a sport
	sportKey: SportType;
	marketKey: MarketType;
	displayLimit?: number;
	/**@description hide the button for navigate to sport*/
	hideNavigateAllButton?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	moreSportLiveEvents: ({
		variant,
		paperVariant
	}: {
		variant: EventListVariant;
		paperVariant?: PaperVariant;
	}) => ({
		padding:
			paperVariant === 'flat'
				? variant === 'narrow'
					? theme.spacing(1)
					: theme.spacing(2)
				: theme.spacing(2, 0),
		color: theme.palette.primary.main
	}),
	spacing: {
		marginBottom: theme.spacing(1)
	},
	noEventsWrapper: {
		marginTop: theme.spacing(1),
		width: '100%'
	}
}));

export const LiveEventList: React.FunctionComponent<LiveEventListProps> = ({
	sport,
	sportKey,
	marketKey,
	variant = 'wide',
	paperVariant,
	paperTitleVariant,
	competitionTitleVariant,
	displayLimit,
	disableCompetitionHeader,
	disableMultipleMarketHeader,
	disableMarketHeader,
	withCompetitionDivider,
	disablePriceList,
	disableCompetitionIcon,
	hideNavigateAllButton,
	orientation
}) => {
	const classes = useStyles({ variant, paperVariant });
	const eventCount = React.useMemo(() => {
		if (!sport) {
			return 0;
		}
		return getEventGroupsEventsNumber(sport.items);
	}, [sport]);
	const groupList = React.useMemo(() => {
		if (!sport) {
			return null;
		}
		const needTrim =
			displayLimit && displayLimit < eventCount && displayLimit < 20;
		return needTrim
			? trimEventGroups(
					sport.items,
					displayLimit || defaultEventListLimit - 1
			  )
			: sport.items;
	}, [displayLimit, eventCount, sport]);
	const extraMargin =
		!disableCompetitionHeader && paperTitleVariant !== 'contained-small';
	return (
		<>
			{extraMargin && <div className={classes.spacing} />}
			{!groupList && (
				<div className={classes.noEventsWrapper}>
					<Typography align="center" variant="subtitle1">
						<Translate
							label="event.currentlyNoLiveEvents"
							ns="sports"
						/>
					</Typography>
				</div>
			)}
			{groupList && (
				<EventList
					key={sportKey}
					sportKey={sportKey}
					groupList={groupList}
					marketKey={marketKey}
					variant={variant}
					paperVariant={paperVariant}
					paperTitleVariant={paperTitleVariant}
					competitionTitleVariant={competitionTitleVariant}
					eventsLoaded
					live
					withCompetitionDivider={withCompetitionDivider}
					disableToEventIcon={variant === 'narrow'}
					disableMarketHeader={disableMarketHeader}
					disableMultipleMarketHeader={disableMultipleMarketHeader}
					disableCompetitionHeader={disableCompetitionHeader}
					disablePriceList={disablePriceList}
					disableCompetitionIcon={disableCompetitionIcon}
					disableMarketHeaderDate
					orientation={orientation}
				/>
			)}
			{!hideNavigateAllButton && (
				<LinkButtonBase
					to={sportRoute(
						sportKey,
						eventCount ? 'inPlay' : 'competitions'
					)}
				>
					<Typography
						className={classes.moreSportLiveEvents}
						variant="button"
					>
						<Translate label="actions.seeAll" />{' '}
						<SportNameTranslation sportKey={sportKey} />
					</Typography>
				</LinkButtonBase>
			)}
		</>
	);
};
