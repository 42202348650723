/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Identifier
 */
export interface Identifier {
	/**
	 *
	 * @type {string}
	 * @memberof Identifier
	 */
	key?: string;
	/**
	 *
	 * @type {string}
	 * @memberof Identifier
	 */
	name?: string;
}

export function IdentifierFromJSON(json: any): Identifier {
	return IdentifierFromJSONTyped(json, false);
}

export function IdentifierFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): Identifier {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		key: !exists(json, 'key') ? undefined : json['key'],
		name: !exists(json, 'name') ? undefined : json['name']
	};
}

export function IdentifierToJSON(value?: Identifier | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		key: value.key,
		name: value.name
	};
}
