// note that the common module is not a module in duck sense, as it does not export a reducer
// we need seperate file for common actions, as we cannot import an action from root in any other
// module due to circular dependencies
import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { StateType } from 'typesafe-actions';

import {
	freespinsEpic,
	freespinsReducer as freespins
} from './freespinsModule';
import { gamesEpic, gamesReducer as games } from './gamesModule';
import loading from './loadingsModule';
import meta from './metaModule';
import {
	rolloverBonusesEpic,
	rolloverBonusesReducer as rolloverBonuses
} from './rolloverBonusesModule';
import sections, { sectionsEpic } from './sectionsModule';
import studios, { studiosEpic } from './studiosModule';
import { winnersReducer as winners } from './winnersModule';

export const rootReducer = combineReducers({
	games,
	sections,
	studios,
	winners,
	loading,
	meta,
	freespins,
	rolloverBonuses
});

export const rootEpic = combineEpics(
	gamesEpic,
	sectionsEpic,
	studiosEpic,
	freespinsEpic,
	rolloverBonusesEpic
);

export type RootState = StateType<typeof rootReducer>;
export type CasinoState = RootState;
