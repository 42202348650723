import { isMobile } from '@gaming-shell/device';
import clsx from 'clsx';
import * as React from 'react';

import { fillArray } from '../../utils/array';
import { PreviewHeaderSkeleton } from '../PreviewHeader/PreviewHeader.skeleton';
import { useStyles } from './Preview.styles';

export interface PreviewSkeletonProps {
	skeleton?: React.ReactNode;
	items?: number;
	className?: string;
}
export const PreviewSkeleton: React.FC<PreviewSkeletonProps> = ({
	skeleton,
	items = 5,
	className
}) => {
	const classes = useStyles();
	const skeletons =
		skeleton &&
		fillArray(items, (i) => (
			<React.Fragment key={i}>{skeleton}</React.Fragment>
		));
	return (
		<div
			style={{
				overflow: isMobile() ? undefined : 'hidden'
			}}
		>
			<PreviewHeaderSkeleton />
			<div className={clsx(classes.skeletonSlider, className)}>
				{skeletons}
			</div>
		</div>
	);
};
