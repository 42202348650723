"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResetMfa = void 0;
var device_1 = require("@gaming-shell/device");
var i18n_1 = require("@gaming-shell/i18n");
var layout_1 = require("@gaming-shell/layout");
var api_1 = require("@materialbet-core/api");
var __1 = require("../..");
var player_1 = require("@materialbet-core/player");
var ResetMfaLayout_1 = require("./ResetMfaLayout");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var react_router_1 = require("react-router");
var validations = {
    login: [
        {
            isValid: function (value) { return (value ? __1.isEmail(value) : true); },
            message: React.createElement(i18n_1.Translate, { label: "signIn.invalidEmail", ns: "player" })
        }
    ]
};
exports.ResetMfa = function (props) {
    var _a = React.useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var trans = i18n_1.useTranslation();
    var enqueueSnackbar = materialbet_common_1.useSnackbar().enqueueSnackbar;
    var authToken = player_1.usePlayerAuthToken();
    var isLoggedIn = player_1.usePlayerLoggedIn();
    var updatePlayer = player_1.useUpdatePlayer();
    var resetMfa = function (login, code, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    retry) {
        setIsSubmitting(true);
        return api_1.api
            .postResetMfa({
            login: login,
            // eslint-disable-next-line @typescript-eslint/camelcase
            one_time_reset_key: code
        }, authToken)
            .then(function (response) {
            var token = response
                .authenticity_token;
            if (response.result === 'INVALID_AUTHENTICITY_TOKEN') {
                updatePlayer(function (player) { return (__assign(__assign({}, player), { authToken: token })); });
                authToken = token;
                // setTimeout ensures that the next sign in placed in an event queue which is called after this method returned (js version of defer)
                setTimeout(function () { return retry(login, code); });
                return;
            }
            if (response.result === 'OK') {
                enqueueSnackbar(trans('player:security.resetSuccess'));
                props.history.push(materialbet_common_1.BaseRoutes.root.create());
            }
            else if (response.result === 'ONE_TIME_RESET_KEY_INVALID') {
                enqueueSnackbar(trans('player:mfa.resetKeyInvalid'));
            }
        })
            .catch(function () {
            enqueueSnackbar(trans('player:descriptions.failedRequest'));
        })
            .finally(function () {
            setIsSubmitting(false);
        });
    };
    var retryableResetMfa = __1.retryable(resetMfa, function () {
        enqueueSnackbar(trans('player:descriptions.failedRequest'));
        return Promise.resolve();
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(layout_1.DefaultAppBar, { type: device_1.isDesktop() ? 'home' : 'page', title: trans('player:mfa.resetMfa') }),
        isLoggedIn && React.createElement(react_router_1.Redirect, { to: materialbet_common_1.BaseRoutes.root.create() }),
        React.createElement(__1.AuthWrapp, { fullScreen: true },
            React.createElement(__1.SingleColumnLayout, { title: React.createElement(i18n_1.Translate, { label: "mfa.resetMfaAuth", ns: "player" }) },
                React.createElement(ResetMfaLayout_1.ResetMfaLayout, { onSubmitClick: retryableResetMfa, loginLabel: React.createElement(i18n_1.Translate, { label: "profile.email", ns: "player" }), codeLabel: React.createElement(i18n_1.Translate, { label: "mfa.resetKey", ns: "player" }), submitButtonText: React.createElement(i18n_1.Translate, { label: "mfa.resetMfaSubmit", ns: "player" }), validations: validations, isSubmitting: isSubmitting })))));
};
