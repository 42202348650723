import { isMobile } from '@gaming-shell/device';
import { useLanguage } from '@gaming-shell/i18n';
import { fillArray } from 'materialbet-common';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MarketType } from 'sports-sdk';
import Loading from 'sports/components/displays/Loading';
import { EventList } from 'sports/components/eventList';
import { EventListCompetitionSkeleton } from 'sports/components/eventList/EventListCompetition';
import { EventListFormatProps } from 'sports/components/eventList/types';
import config from 'sports/config/app';
import {
	actions as eventActions,
	EventsActions,
	loads
} from 'sports/modules/eventsModule';
import { RootState } from 'sports/modules/root';
import {
	actions as topCouponsActions,
	loads as topCouponsLoads,
	TopCouponActions
} from 'sports/modules/sports/topCoupons';
import { loadedOnce } from 'sports/selectors/loading';
import { getEventTree } from 'sports/selectors/sports';
import { trimEventGroups } from 'sports/utils/event';
import { useTimeZone } from 'sports/utils/hooks';
import { useEventListUpdatePusher } from 'sports/utils/pusherUtils';

import { useCountryEffect } from '../../utils/hooks';

export interface SmartEventListProps
	extends SportsEventsParams,
		EventListFormatProps {
	/** @description a market record for market to be shown on the list */
	market?: Record<string, MarketType | undefined>;
	noEvents?: JSX.Element;
	displayLimit?: number;
}
export interface SportsEventsParams {
	from?: number;
	to?: number;
	endpoint:
		| 'events'
		| 'popular'
		| 'topcoupon'
		| 'popularCompetitions'
		| 'outrights';
	upcoming?: boolean;
	live?: boolean;
	virtual?: boolean;
	limit?: number;
	priority?: number;
	sports: string[];
	markets?: MarketType[];
	players?: boolean;
	topCouponKey?: string;
	numComp?: number;
}
export const useEventTree = ({
	from,
	to,
	endpoint,
	live,
	upcoming,
	virtual,
	limit,
	priority,
	sports,
	markets,
	players,
	numComp,
	topCouponKey
}: SportsEventsParams) => {
	const locale = useLanguage();
	const dispatch = useDispatch();
	const timeZone = useTimeZone();
	useCountryEffect(() => {
		let action: EventsActions | TopCouponActions | null = null;
		let interval: NodeJS.Timeout | undefined;
		switch (endpoint) {
			case 'events':
				action = eventActions.fetchEvents({
					sports,
					limit,
					markets,
					locale,
					upcoming,
					live,
					virtual,
					priority,
					from,
					to,
					timeZone,
					players
				});
				break;
			case 'topcoupon':
				if (topCouponKey) {
					action = topCouponsActions.fetchTopCouponEvents({
						key: topCouponKey,
						limit,
						markets,
						locale,
						timeZone
					});
				}
				break;
			case 'popularCompetitions':
				action = eventActions.fetchPopularCompetitions({
					sports,
					markets,
					players,
					numComp,
					locale,
					timeZone
				});
				break;
			case 'outrights':
				action = eventActions.fetchOutrights({
					sports,
					markets,
					priority,
					limit,
					locale,
					timeZone
				});
				break;
			default:
				action = eventActions.fetchPopular({
					sports,
					limit,
					markets,
					locale,
					timeZone
				});
		}
		if (live || virtual) {
			dispatch(action); // Call once before the next interval
			interval = setInterval(() => {
				dispatch(action);
			}, config.eventPollingInterval);
		} else {
			dispatch(action);
		}
		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [
		from,
		to,
		endpoint,
		live,
		upcoming,
		limit,
		virtual,
		priority,
		locale,
		sports,
		markets,
		dispatch,
		timeZone,
		topCouponKey,
		players,
		numComp
	]);
	let name = '';
	switch (endpoint) {
		case 'events':
			name = loads.events({
				sports,
				limit,
				markets,
				locale,
				upcoming,
				live,
				virtual,
				priority,
				from,
				to,
				players
			});
			break;
		case 'topcoupon':
			if (topCouponKey) {
				name = topCouponsLoads.topcoupons({
					key: topCouponKey,
					limit,
					markets,
					locale
				});
			}
			break;
		case 'popularCompetitions':
			name = loads.popularCompetitions({
				markets,
				players,
				numComp,
				locale
			});
			break;
		case 'outrights':
			name = loads.outrights({
				sports,
				markets,
				priority,
				limit,
				locale
			});
			break;
		default:
			name = loads.popular({ sports, limit, markets, locale });
	}

	const loaded = useSelector(
		(state: RootState) => !!loadedOnce(state, { name })
	);
	useEventListUpdatePusher({
		list: 'tree',
		params: {
			from,
			to,
			live,
			upcoming,
			limit,
			virtual,
			priority,
			locale,
			sports,
			markets
		}
	});
	const params = {
		sports,
		upcoming,
		live,
		virtual,
		from,
		to,
		endpoint,
		topCouponKey
	};

	const eventTree = useSelector((state: RootState) =>
		getEventTree(state, {
			params
		})
	);
	return { loaded, eventTree };
};
export const SmartEventList: React.FunctionComponent<SmartEventListProps> = (
	props
) => {
	const { loaded, eventTree } = useEventTree(props);
	if (!loaded) {
		return ENABLE_SPORT_SKELETON ? (
			<>
				{fillArray(3, (i) => (
					<EventListCompetitionSkeleton
						key={i}
						variant={isMobile() ? 'narrow' : 'wide'}
						paperVariant={isMobile() ? 'flat' : undefined}
					/>
				))}
			</>
		) : (
			<Loading />
		);
	}
	if (!eventTree || !eventTree.length) {
		return props.noEvents || null;
	}
	const apiLimit = props.limit || 20; // 20 is the default from api doc
	const sports = eventTree.map((sport) => (
		<EventList
			key={sport.id}
			sportKey={sport.id}
			groupList={
				props.displayLimit && props.displayLimit < apiLimit
					? trimEventGroups(sport.items, props.displayLimit)
					: sport.items
			}
			eventsLoaded={loaded}
			marketKey={props.market && props.market[sport.id]}
			variant={props.variant}
			disableToEventIcon={isMobile()}
			paperVariant={props.paperVariant}
			live={props.live}
			disableMarketHeaderDate={props.disableMarketHeaderDate}
		/>
	));
	return <>{sports}</>;
};
