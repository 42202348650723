import { useCreateErrorActionButton } from './BetslipErrorActionButton';
import { BetslipReducerStatus, StakeLocation } from './types';

export interface BetslipErrorActionProps {
	/**@description expects a single status flag */
	error: BetslipReducerStatus;
	stakeLocation?: StakeLocation;
}
export const BetslipErrorAction: React.FunctionComponent<BetslipErrorActionProps> = (
	props
) => {
	const { error, stakeLocation } = props;
	const button = useCreateErrorActionButton(error, stakeLocation);
	return button;
};
