import { useLanguage } from '@gaming-shell/i18n';
import { SideMenuItem } from '@gaming-shell/layout';
import Collapse from '@material-ui/core/Collapse';
import { SportsRoutes } from 'materialbet-common';
import { SportIcon } from 'materialbet-icons';
import * as React from 'react';
import { useLocation } from 'react-router';
import { getSportsName } from 'sports-sdk/sports-core';
import { defaultSportInfo, sportInfo, SportType } from 'sports/config/sport';
import { useSports } from 'sports/selectors/sports';
import { getSdkLocale } from 'sports/utils/locale';
import { sportRoute } from 'sports/utils/route';

interface SportSideMenuItemProps {
	sportKey: SportType;
}
/** @description a component that contain only the main item for featured sports*/
let SportSideMenuItem: React.FC<SportSideMenuItemProps> = (props) => {
	const { sportKey } = props;
	const language = useLanguage();
	const locale = getSdkLocale(language);
	const sports = useSports();
	const { pathname: currentRoute } = useLocation();
	const { sportKey: routeSportKey } = SportsRoutes.sport.getPathParams(
		currentRoute
	);
	const sport = sports[sportKey];
	const open = !!sport?.eventCount || !!sport?.outrightCount;
	const singleSportInfo = sportInfo[sportKey] || defaultSportInfo;
	const tab = singleSportInfo.defaultTab;
	return (
		<Collapse in={open} timeout="auto" unmountOnExit>
			<SideMenuItem
				key={sportKey}
				icon={<SportIcon sportKey={sportKey} />}
				label={getSportsName(sportKey, locale)}
				href={sportRoute(sportKey, tab)}
				active={sportKey === routeSportKey}
				variant="subtitle2"
			/>
		</Collapse>
	);
};

SportSideMenuItem = React.memo(SportSideMenuItem);
export { SportSideMenuItem };
