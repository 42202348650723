"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignUpConfirmationLayout = void 0;
var core_1 = require("@material-ui/core");
var styles_1 = require("@material-ui/core/styles");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var styles = function (theme) {
    return styles_1.createStyles({
        divider: {
            margin: theme.spacing(3) + "px 0 " + theme.spacing(1) + "px"
        },
        email: {
            marginTop: theme.spacing(2),
            wordBreak: 'break-word'
        },
        extraButton: {
            margin: theme.spacing(3, 0, 2),
            color: theme.palette.primary.main
        }
    });
};
var SignUpConfirmationLayout = function (props) {
    var mainText = props.mainText, classes = props.classes, email = props.email, extraText = props.extraText, onExtraButtonClick = props.onExtraButtonClick, isSubmitting = props.isSubmitting, extraButtonText = props.extraButtonText;
    return (React.createElement(core_1.Box, null,
        React.createElement(core_1.Box, null,
            React.createElement(core_1.Typography, { variant: "body1" }, mainText)),
        React.createElement(core_1.Typography, { variant: "h6", paragraph: true, className: classes.email }, email),
        React.createElement(core_1.Divider, { className: classes.divider }),
        React.createElement(core_1.Box, null,
            React.createElement(core_1.Typography, { variant: "caption" }, extraText)),
        React.createElement(materialbet_common_1.ProgressButton, { submitting: isSubmitting, className: classes.extraButton, variant: "outlined", onClick: onExtraButtonClick, disabled: isSubmitting, fullWidth: true }, extraButtonText)));
};
var StyledSignUpConfirmationLayout = styles_1.withStyles(styles)(SignUpConfirmationLayout);
exports.SignUpConfirmationLayout = StyledSignUpConfirmationLayout;
