import { usePlayerUuid } from '@materialbet-core/player';
import { FreespinsParams } from 'casino/modules/freespinsModule';
import { getImageUrl } from 'materialbet-common';

export const useFreeSpinsParams = (): FreespinsParams => {
	return { playerUuid: usePlayerUuid() };
};
/** @description return the image url with cloudinary*/
export const getFreespinsImageUrl = (imgUrl: string) => {
	// this probably will need to be adjusted
	const cloudinaryUrl = getImageUrl(imgUrl, { width: 400, height: 305 });
	return cloudinaryUrl;
};
