import { ApiConfiguration } from 'materialbet-common';
import { Results as CommonResults } from '@materialbet-core/api';

export enum VerificationStatus {
	REQUESTED = 'REQUESTED',
	INITIATED = 'INITIATED',
	PROCESSING = 'PROCESSING',
	APPROVED = 'APPROVED',
	DENIED = 'DENIED'
}

export enum VerificationReason {
	SELF_VERIFICATION = 'SELF_VERIFICATION',
	FIAT_WITHDRAWAL_REQUESTED = 'FIAT_WITHDRAWAL_REQUESTED'
}

export enum PostVerificationConflict {
	ALREADY_REQUESTED = 'ALREADY_REQUESTED',
	ALREADY_INITIATED = 'ALREADY_INITIATED',
	ALREADY_PROCESSING = 'ALREADY_PROCESSING',
	ALREADY_VERIFIED = 'ALREADY_VERIFIED',
	ALREADY_DENIED = 'ALREADY_DENIED',
	TOO_MANY_FAILED_VERIFICATIONS = 'TOO_MANY_FAILED_VERIFICATIONS'
}

export enum PutVerificationAction {
	INITIATE = 'INITIATE',
	ACK_SUBMISSION = 'ACK_SUBMISSION'
}

export enum PutVerificationConflict {
	ALREADY_INITIATED = 'ALREADY_INITIATED', // INTIATE
	ALREADY_PROCESSING = 'ALREADY_PROCESSING',
	ALREADY_VERIFIED = 'ALREADY_VERIFIED',
	ALREADY_DENIED = 'ALREADY_DENIED',
	NOT_INITIATED = 'NOT_INITIATED' // ACK_SUBMISSION
}

export enum Results {
	NOT_FOUND = 'NOT_FOUND',
	INVALID_REASON = 'INVALID_REASON',
	INVALID_ACTION = 'INVALID_ACTION'
}

export interface Unauthorized {
	result: CommonResults.NOT_SIGNED_IN;
}

export interface NotFound {
	result: Results.NOT_FOUND;
}

export interface PostVerificationRequest {
	reason: VerificationReason;
}

export interface PostVerificationResponse201 {
	id: string;
	result: CommonResults.OK;
	status: VerificationStatus;
}

export interface PostVerificationResponse409 {
	result: PostVerificationConflict;
}

export interface PostVerificationResponse422 {
	result: Results.INVALID_REASON;
}

export type PostVerificationResponse =
	| Unauthorized
	| PostVerificationResponse201
	| PostVerificationResponse409
	| PostVerificationResponse422;

export interface GetVerificationDetailsResponse200 {
	result: CommonResults.OK;
	id: string;
	redirect_url: string;
	status: VerificationStatus;
	reason: VerificationReason;
}

export type GetVerificationDetailsResponse =
	| Unauthorized
	| GetVerificationDetailsResponse200
	| NotFound;

export interface PutVerificationRequest {
	action: PutVerificationAction;
}

export interface PutVerificationResponse422 {
	result: Results.INVALID_ACTION;
}
export interface PutVerificationResponse409 {
	result: PutVerificationConflict;
}

export interface PutVerificationResponse202 {
	result: CommonResults.OK;
	status: VerificationStatus;
	redirect_url?: string;
}

export type PutVerificationResponse =
	| Unauthorized
	| NotFound
	| PutVerificationResponse422
	| PutVerificationResponse202
	| PutVerificationResponse409;

export interface JumioApi {
	getVerificationDetails(): Promise<GetVerificationDetailsResponse>;
	postVerification(
		request: PostVerificationRequest
	): Promise<PostVerificationResponse>;
	putVerification(
		id: string,
		request: PutVerificationRequest
	): Promise<PutVerificationResponse>;
}

export const createJumioApi = (configuration: ApiConfiguration): JumioApi => {
	const baseOptions = configuration.baseOptions || {};
	const basePath = configuration.basePath || '';

	const createOptions = (
		method: string,
		body?: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		headers?: Record<string, any>
	) => {
		const options = { ...baseOptions, method, body };
		options.headers = { ...baseOptions.headers, ...headers };
		return options;
	};

	const api = {
		getVerificationDetails: async () => {
			return fetch(
				basePath + 'current_kyc_verification',
				baseOptions
			).then((response) => {
				const { status } = response;
				if (status === 200) {
					return response.json();
				} else {
					throw response.json();
				}
			});
		},
		postVerification: async (request: PostVerificationRequest) => {
			const options = createOptions('POST', JSON.stringify(request));
			return fetch(basePath + 'kyc_verification', options).then(
				(response) => {
					const { status } = response;
					if (status === 201) {
						return response.json();
					} else {
						throw response.json();
					}
				}
			);
		},
		putVerification: async (
			id: string,
			request: PutVerificationRequest
		) => {
			const options = createOptions('PUT', JSON.stringify(request));
			return fetch(basePath + `kyc_verification/${id}`, options).then(
				(response) => {
					const { status } = response;
					if (status === 202) {
						return response.json();
					} else {
						throw response.json();
					}
				}
			);
		}
	};

	return api;
};

const configuration = {
	basePath: `${BASE_API_URL}/api/v1/player/`,
	baseOptions: {
		credentials: 'include',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		}
	}
};

export const jumioApiV1 = createJumioApi(configuration);
