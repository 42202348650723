import { isDesktop } from '@gaming-shell/device';
import { Drawer, Popover, Theme, makeStyles } from '@material-ui/core';
import { BackdropProps } from '@material-ui/core/Backdrop';
import { DrawerProps } from '@material-ui/core/Drawer';
import { PopoverOrigin, PopoverProps } from '@material-ui/core/Popover';
import * as React from 'react';

import { getIsSubmitting } from '../betslipSelectors/betslipSelectors';
import { useBetslipSelector } from '../hooks/useBetslipSelector';

interface QuickBetslipWrapperProps {
	open: boolean;
	anchorEl: Element | null;
	onClose: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		border: `1px solid ${theme.palette.divider}`
	}
}));

const defaultAnchorOrigin: PopoverOrigin = {
	vertical: 'center',
	horizontal: 'center'
};
const defaultTransformOrigin: PopoverOrigin = {
	vertical: 'center',
	horizontal: 'center'
};
const backdropProps: Partial<BackdropProps> = {
	style: { backgroundColor: 'transparent' }
};
export const QuickBetslipWrapper: React.FunctionComponent<
	QuickBetslipWrapperProps & PopoverProps & DrawerProps
> = ({
	children,
	open,
	onClose,
	anchorEl,
	anchorOrigin = defaultAnchorOrigin,
	transformOrigin = defaultTransformOrigin,
	anchor = 'bottom'
}) => {
	const classes = useStyles();
	const isSubmitting = useBetslipSelector(getIsSubmitting);
	if (isDesktop()) {
		return (
			<>
				<Popover
					classes={classes}
					open={open}
					anchorEl={anchorEl}
					onClose={onClose}
					anchorOrigin={anchorOrigin}
					transformOrigin={transformOrigin}
					disablePortal={true} // there is a bug within react that causes react to throw an error when the anchorElement is removed while the popover is open: https://github.com/facebook/react/issues/14811#issuecomment-516786628
					BackdropProps={isSubmitting ? undefined : backdropProps} // popover overrides modals backdrop, by setting undefined we restore it
					elevation={24}
				>
					{children}
				</Popover>
			</>
		);
	}
	return (
		<Drawer anchor={anchor} open={open} onClose={onClose}>
			{children}
		</Drawer>
	);
};
