import { isMobile } from '@gaming-shell/device';
import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

// allMarkets mapping created for sports that requires market Tabs
// So we can easily group create an all markets tab
const allMarkets = {
	popular: [
		{
			marketGroup: [
				{
					marketType: MarketType.american_football_handicap,
					view: MarketView.Handicap,
					disableAltLines: true
				},
				{
					marketType: MarketType.american_football_totals,
					view: MarketView.Goals,
					disableAltLines: true
				},
				{
					marketType: MarketType.american_football_moneyline,
					disableAltLines: true
				}
			],
			view: MarketView.GameLine
		},
		{
			marketType: MarketType.american_football_handicap,
			view: MarketView.Handicap,
			disableMainLine: true,
			collapsedByDefault: isMobile()
		},
		{
			marketType: MarketType.american_football_totals,
			view: MarketView.Goals,
			disableMainLine: true,
			collapsedByDefault: isMobile()
		},
		{
			marketType: MarketType.american_football_team_totals,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.american_football_next_scoring_type
		},
		{
			marketType: MarketType.american_football_race_to_points
		}
	],
	quarters: [
		{
			marketType: MarketType.american_football_quarter_1x2
		},
		{
			marketType: MarketType.american_football_quarter_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.american_football_quarter_handicap_incl_ot,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.american_football_quarter_total,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.american_football_quarter_totals_incl_ot,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.american_football_highest_scoring_quarter
		},
		{
			marketType:
				MarketType.american_football_team_highest_scoring_quarter
		}
	],
	specials: [
		{
			marketType: MarketType.american_football_double_chance
		},
		{
			marketType: MarketType.american_football_draw_no_bet
		},
		{
			marketType: MarketType.american_football_totals_period_second_half,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.american_football_team_odd_even
		},
		{
			marketType: MarketType.american_football_odd_even
		},
		{
			marketType: MarketType.american_football_freetext
		},
		{
			marketType: MarketType.american_football_drive_play_play_type
		}, //pending steve's categorization
		{
			marketType:
				MarketType.american_football_drive_play_total_yards_gained
		}, //pending steve's categorization
		{
			marketType: MarketType.american_football_drive_result
		}, //pending steve's categorization
		{
			marketType: MarketType.american_football_touchdown_scorer
		} //pending steve's categorization
	],
	firstHalf: [
		{
			marketType: MarketType.american_football_1x2_period_first_half
		},
		{
			marketType: MarketType.american_football_handicap_period_first_half,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.american_football_totals_period_first_half,
			view: MarketView.Goals
		},
		{
			marketType:
				MarketType.american_football_team_total_period_first_half,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.american_football_halftime_fulltime_result
		}
	]
};

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: allMarkets.popular
	},
	{
		label: 'markets.quarters',
		markets: allMarkets.quarters
	},
	{
		label: 'markets.specials',
		markets: allMarkets.specials
	},
	{
		label: 'markets.firstHalf',
		markets: allMarkets.firstHalf
	},
	{
		label: 'descriptions.all',
		markets: [
			...allMarkets.popular,
			...allMarkets.quarters,
			...allMarkets.specials,
			...allMarkets.firstHalf
		]
	}
];

export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.american_football_handicap
			},
			{
				marketType: MarketType.american_football_moneyline
			},
			{
				marketType: MarketType.american_football_totals
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.american_football_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
