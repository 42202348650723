import { useTranslation } from '@gaming-shell/i18n';
import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import clsx from 'clsx';
import { CinemaModeIcon } from 'materialbet-icons';
import * as React from 'react';

interface CinemaModeButtonProps {
	active?: boolean;
	className?: string;
	onClick: (event: React.SyntheticEvent) => void;
	/** @description icon font size for the icon insdie the button*/
	fontSize?: SvgIconProps['fontSize'];
	color?: SvgIconProps['color'];
}
const useStyles = makeStyles({
	root: {
		opacity: 0.6
	},
	active: {
		opacity: 1
	}
});
let CinemaModeButton: React.FunctionComponent<CinemaModeButtonProps> = (
	props
) => {
	const { active, onClick, className, fontSize, color } = props;
	const classes = useStyles();
	const trans = useTranslation();
	return (
		<Tooltip title={trans('descriptions.cinemaMode') || ''} placement="top">
			<IconButton
				size="small"
				onClick={onClick}
				className={clsx(
					classes.root,
					{ [classes.active]: active },
					className
				)}
				aria-label={trans('descriptions.cinemaMode')}
			>
				<CinemaModeIcon color={color} fontSize={fontSize} />
			</IconButton>
		</Tooltip>
	);
};

CinemaModeButton = React.memo(CinemaModeButton);
export { CinemaModeButton };
