import { getMinimumMaxStake } from '../betslipSelectors/betslipSelectors';
import { BetslipReducerSelection, BetslipState } from '../types';
import { createProducer } from './betslipActions';
import { removeStatusInObject } from './betslipReducerUtils';
import { BetslipStatusFlags } from './BetslipStatusFlags';

const correctSelectionStake = (draft: BetslipReducerSelection) => {
	removeStatusInObject(draft, BetslipStatusFlags.StakeValidationStatus);
	if (draft.stake < draft.minStake) {
		draft.stake = draft.minStake;
		return;
	}
	if (draft.stake > draft.maxStake) {
		draft.stake = draft.maxStake;
		return;
	}
};
const correctStakeInSelections = (draft: BetslipState) => {
	const selections = draft.betslip.selections;
	selections.forEach((s, i) => {
		correctSelectionStake(s);
		if (draft.changes) {
			correctSelectionStake(draft.changes.selections[i]);
		}
	});
};
export const correctStake = createProducer<'correctStake'>(
	(draft, { payload: stakeLocation }) => {
		if (draft.isSubmitting) {
			return;
		}
		if (stakeLocation.allSelections) {
			correctStakeInSelections(draft);
		} else if (stakeLocation.straight) {
			const selectionIndex = stakeLocation.straight.selectionIndex;
			const selection = draft.betslip.selections[selectionIndex];
			if (!selection) {
				return;
			}
			correctSelectionStake(selection);
			if (draft.changes) {
				correctSelectionStake(draft.changes.selections[selectionIndex]);
			}
		} else if (stakeLocation.multiple) {
			const multiple =
				draft.betslip.multiples[stakeLocation.multiple.system];
			const maxStake = getMinimumMaxStake(draft);
			if (multiple.stake > maxStake) {
				multiple.stake = maxStake;
				removeStatusInObject(
					multiple,
					BetslipStatusFlags.StakeAboveMax
				);
			}
		}
	}
);
