import {
	getTrailingDecimals,
	Currency,
	FiatCurrency
} from 'materialbet-common';
import fiatConfig from './FiatCashier/config';

const amountAllowedCharactersRegex = /^[0-9\.,]*$/;
const separators = ['.', ','];
const twoSeparatorsUsed = (amount: string) =>
	separators.filter((separator) => amount.includes(separator)).length > 1;
const duplicatedSeparator = (amount: string, separator: string): boolean =>
	amount.split(separator).length > 2;

export const isAmountValid = (
	amount: string,
	decimalPlaces: number
): boolean | null => {
	const separator =
		separators.find((separator) => amount.includes(separator)) || '.';
	return (
		amount.match(amountAllowedCharactersRegex) &&
		!twoSeparatorsUsed(amount) &&
		!duplicatedSeparator(amount, separator) &&
		getTrailingDecimals(amount, separators) <= decimalPlaces
	);
};

export const isFiatCashier = (currency: Currency) =>
	fiatConfig.fiatCurrencies.includes(currency as FiatCurrency);
