"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuyCurrencyButton = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var core_1 = require("@material-ui/core");
var api_1 = require("@materialbet-core/api");
var player_1 = require("@materialbet-core/player");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var routing_1 = require("@gaming-shell/routing");
var useMoonPayAvailable_1 = require("./useMoonPayAvailable");
exports.BuyCurrencyButton = function (_a) {
    var onClick = _a.onClick, showDialog = _a.showDialog, props = __rest(_a, ["onClick", "showDialog"]);
    var enabled = useMoonPayAvailable_1.useMoonPayAvailable();
    var trans = i18n_1.useTranslation();
    var activeCurrency = player_1.usePlayerCurrency();
    var currencyConfig = materialbet_common_1.getCurrencyConfig(activeCurrency);
    var walletAddress = player_1.usePlayerDepositAddress();
    var externalCustomerId = player_1.usePlayerId();
    var _b = React.useState(!!showDialog), dialogOpen = _b[0], setDialogOpen = _b[1];
    var handleToggleDialog = React.useCallback(function () {
        setDialogOpen(function (prev) { return !prev; });
    }, [setDialogOpen]);
    var handleClose = React.useCallback(function () {
        setDialogOpen(false);
        if (showDialog) {
            routing_1.history.push(materialbet_common_1.PlayerRoutes.account.create());
        }
    }, [showDialog, setDialogOpen]);
    var handleSignatureCreation = React.useCallback(function (moonPayUrl) { return __awaiter(void 0, void 0, void 0, function () {
        var response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4, api_1.api.getMoonPaySignature(moonPayUrl)];
                case 1:
                    response = _a.sent();
                    if ((response === null || response === void 0 ? void 0 : response.result) === 'OK') {
                        return [2, response.player.signature];
                    }
                    return [3, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3, 3];
                case 3: return [2, ''];
            }
        });
    }); }, []);
    var handleClickAndToggle = React.useCallback(function (event) {
        onClick && onClick(event);
        handleToggleDialog();
    }, [handleToggleDialog, onClick]);
    if (!enabled) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(core_1.Button, __assign({ onClick: handleClickAndToggle, color: "secondary", variant: "outlined" }, props), trans('common:actions.buy', {
            currency: currencyConfig === null || currencyConfig === void 0 ? void 0 : currencyConfig.label
        })),
        React.createElement(materialbet_common_1.MoonPayDialog, { open: showDialog || dialogOpen, onClose: handleClose, onSignatureCreation: handleSignatureCreation, apiUrl: MOONPAY_API_URL, apiKey: MOONPAY_API_KEY, currencyCode: currencyConfig === null || currencyConfig === void 0 ? void 0 : currencyConfig.defaultVariant, externalCustomerId: externalCustomerId, walletAddress: walletAddress, showWalletAddressForm: MOONPAY_SHOW_WALLET_ADDRESS_FORM, withApplePay: MOONPAY_ENABLE_APPLE_PAY })));
};
