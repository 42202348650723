/** Creates a sports route */
import { pathParam, route } from '@gaming-shell/routing';

import {
	createCompetitionRouteParams,
	getCompetitionRouteParams
} from '../utils/sportsRouteUtils';

const sportsRoute = route(['sports']);

const competitionRoute = sportsRoute.add([
	pathParam('sportKey'),
	pathParam('competitionKey')
]);
const outrightRoute = sportsRoute.add([
	pathParam('sportKey'),
	'outrights',
	pathParam('competitionKey')
]);
const createCompetitionProxy = <
	T extends typeof competitionRoute | typeof outrightRoute
>(
	underlyingRoute: T
): T => ({
	...underlyingRoute,
	create: (params) =>
		underlyingRoute.create(createCompetitionRouteParams(params)),
	createRelative: (params) =>
		underlyingRoute.createRelative(createCompetitionRouteParams(params)),
	getPathParams: (path) =>
		getCompetitionRouteParams(underlyingRoute.getPathParams(path)),
	useParams: () => getCompetitionRouteParams(underlyingRoute.useParams())
});

export const SportsRoutes = {
	root: sportsRoute,
	bets: sportsRoute.add(['bets']),
	live: sportsRoute.add(['live']),
	competition: createCompetitionProxy(competitionRoute),
	event: createCompetitionProxy(competitionRoute.add([pathParam('eventId')])),
	eventWithKey: createCompetitionProxy(
		competitionRoute.add([pathParam('eventId'), pathParam('eventKey')])
	),
	outright: createCompetitionProxy(
		outrightRoute.add([pathParam('eventId'), pathParam('eventKey')])
	),
	sport: sportsRoute.add([pathParam('sportKey')]),
	// Below will be use for tab selection on the single sport page
	sportCompetitions: sportsRoute.add([pathParam('sportKey'), 'competitions']),
	sportHighlights: sportsRoute.add([pathParam('sportKey'), 'highlights']),
	sportToday: sportsRoute.add([pathParam('sportKey'), 'today']),
	sportTomorrow: sportsRoute.add([pathParam('sportKey'), 'tomorrow']),
	sportVirtuals: sportsRoute.add([pathParam('sportKey'), 'virtuals']),
	sportInPlay: sportsRoute.add([pathParam('sportKey'), 'inPlay']),
	sportOutrights: sportsRoute.add([pathParam('sportKey'), 'outrights']), // same as outrights now, add one to make clear that its for tab
	topCoupon: sportsRoute.add([
		pathParam('sportKey'),
		'popular',
		pathParam('couponKey')
	]),
	virtualSports: sportsRoute.add(['virtuals'])
};

// For the pattern and typing
const singleSportRoute = sportsRoute.add([pathParam('sportKey'), '']);
export type SingleSportTabRoute = typeof singleSportRoute;
