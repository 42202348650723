import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	iframeWidget: {
		alignItems: 'stretch',
		transition: 'left 1s linear, top 1s linear',
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'inline-flex',
		flexDirection: 'column'
	},
	background: ({ backgroundImgUrl }: { backgroundImgUrl?: string }) => ({
		background: theme.palette.background.paper,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundImage: backgroundImgUrl
			? `url(${backgroundImgUrl})`
			: undefined
	}),
	cinema: {
		position: 'fixed',
		top: 0,
		left: 0,
		zIndex: theme.zIndex.drawer,
		width: '100vw',
		height: '100vh'
	}
}));
