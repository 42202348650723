/** @description a function to return ratio width/height from string */
export const getRatioFromString = (ratio: string): number => {
	const parts = ratio.split(':');
	const [width = 0, height = 0] = parts.map((x) => parseInt(x));
	if (height === 0) {
		return 1; // Everything divide by zero will be infinity, avoid the edge case
	}
	return width / height || 1;
};

/** @description a function to return x/y in a percentage ratio, 4:3 => x:y => 3/4 => 75% */
export const parseRatio = (ratio: string): number => {
	const deaultValue = (3 / 4) * 100;
	if (ratio) {
		const parsedRatio = ratio.split(':');
		if (parsedRatio.length === 2) {
			const parsedX = parseInt(parsedRatio[0], 10);
			const parsedY = parseInt(parsedRatio[1], 10);
			if (isNaN(parsedX) || isNaN(parsedY)) {
				return deaultValue;
			}
			return (parsedY / parsedX) * 100;
		}
	}
	return deaultValue;
};
