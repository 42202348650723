"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onRender = void 0;
/**@description callback used for React.Profiler to log render stats. Only to be passed in as a prop to <React.Profiler/>.  https://reactjs.org/docs/profiler.html*/
exports.onRender = function (id, // the "id" prop of the Profiler tree that has just committed
phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
actualDuration, // time spent rendering the committed update
baseDuration, // estimated time to render the entire subtree without memoization
startTime, // when React began rendering this update
commitTime, // when React committed this update
interactions // the Set of interactions belonging to this update
) {
    //eslint-disable-next-line
    console.table({
        id: id,
        phase: phase,
        actualDuration: actualDuration,
        baseDuration: baseDuration,
        startTime: startTime,
        commitTime: commitTime,
        interactions: interactions
    });
};
