import { CasinoState } from 'casino/modules/rootModule';
import { createForEntitySelector } from 'materialbet-common';

export type RolloverBonusId = string | undefined;
const rolloverBonusesSelector = (state: CasinoState) => state.rolloverBonuses;
export const getRolloverBonus = (id: RolloverBonusId) => (state: CasinoState) =>
	id === undefined ? undefined : rolloverBonusesSelector(state)[id];

const forRolloverBonus = createForEntitySelector(getRolloverBonus);
export const getRolloverBonusAmount = forRolloverBonus((b) => b.amount);
export const getRolloverBonusRolloverRequirement = forRolloverBonus(
	(b) => b.rollover_requirement
);
export const getRolloverBonusCurrentPlaythroughAmount = forRolloverBonus(
	(b) => b.current_playthrough_amount
);
export const getRolloverBonusPlaythroughProgressPercentage = forRolloverBonus(
	(b) => b.playthrough_progress_percentage
);
export const getRolloverBonusTotalPlaythroughAmount = forRolloverBonus(
	(b) => b.total_playthrough_amount
);
export const getRolloverBonusExpiresAt = forRolloverBonus((b) => b.expires_at);
export const getRolloverBonusCasinoCurrency = forRolloverBonus(
	(b) => b.casino_currency
);
