import { Box, Divider, Theme, Typography, makeStyles } from '@material-ui/core';
import * as React from 'react';

import { PromoCardContext } from '../../PromoCard';

export interface PromoCollapsedContentProps {
	title?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
	divider: {
		marginBottom: theme.spacing(2)
	}
}));

export const PromoCollapsedContent: React.FunctionComponent<PromoCollapsedContentProps> = ({
	title,
	children
}) => {
	const classes = useStyles();
	const { expired } = React.useContext(PromoCardContext);

	return (
		<Box paddingX={2} marginBottom={2} marginTop={expired && 1}>
			{!expired && <Divider className={classes.divider} />}
			<Typography variant="subtitle1">{title}</Typography>
			<Box maxHeight={200} overflow="auto">
				<Typography variant="body2" color="textSecondary">
					{children}
				</Typography>
			</Box>
		</Box>
	);
};
