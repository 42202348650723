import { normalize, schema } from 'normalizr';
import { Action } from 'redux';
import {
	ActionsObservable,
	Epic,
	ofType,
	StateObservable
} from 'redux-observable';
import { from, of, queueScheduler, Subject } from 'rxjs';
import {
	catchError,
	groupBy,
	map,
	mergeMap,
	observeOn,
	switchAll,
	throttleTime
} from 'rxjs/operators';
import { getType } from 'typesafe-actions';

import { EntitiesType } from './actions';

interface CreateMetaParams<TArgs, TPromiseResult> {
	payload: TArgs;
	result?: TPromiseResult;
}
interface FetchEntityEpicOptions {
	throttleTime: number;
}
/** Not sure if there is another way, but for now we need this extra type to curry the the type of createFetchEntityEpic function in the domain */
export type CreateFetchEntityEpic<
	TEpicDependencies,
	TArgs,
	TPromiseResult,
	TMetaPayload,
	TEntityKeys extends string
> = (params: {
	actionCreator: (
		payload: TArgs
	) => {
		type: string;
		payload: TArgs;
	};
	fetch: (
		parameters: TArgs,
		dependencies: TEpicDependencies
	) => Promise<TPromiseResult>;
	resultSchema: schema.Entity<any> | schema.Entity<any>[];
	resultSelector?:
		| ((result: TPromiseResult, parameters: TArgs) => unknown)
		| undefined;
	createMeta?:
		| ((params: CreateMetaParams<TArgs, TPromiseResult>) => TMetaPayload)
		| undefined;
	options?: FetchEntityEpicOptions | undefined;
	updateEntitiesAction: (
		entities: EntitiesType<TEntityKeys>,
		meta?: TMetaPayload | undefined
	) => {
		type: string;
		payload: EntitiesType<TEntityKeys>;
		meta?: TMetaPayload | undefined;
	};
}) => Epic<Action<string>, Action<string>, void, TEpicDependencies>;

/**
 * Creates an epic that on dispatch of given @param actionCreator type executes the @param fetch und normalizes the result with the given @param resultSchema.
 * @returns an updateEntities action if or an raisError action if an error ocurred
 * @param actionCreator The action which should be handled.
 * @param fetch An function which returns a promise.
 * @param resultSchema The schema by which the result is to be normalized.
 * @param resultSelector Optional. Select a part of the result to be normalized.
 */

export const createFetchEntityEpic = <
	TArgs,
	TPromiseResult,
	TEpicDependencies,
	TMetaPayload,
	TEntities
>(params: {
	actionCreator: (payload: TArgs) => { type: string; payload: TArgs };
	fetch: (
		parameters: TArgs,
		dependencies: TEpicDependencies
	) => Promise<TPromiseResult>;
	resultSchema: schema.Entity | schema.Entity[];
	resultSelector?: (result: TPromiseResult, parameters: TArgs) => unknown;
	createMeta?: (
		params: CreateMetaParams<TArgs, TPromiseResult>
	) => TMetaPayload;
	options?: FetchEntityEpicOptions;
	updateEntitiesAction: (
		entities: TEntities,
		meta?: TMetaPayload
	) => { type: string; payload: TEntities; meta?: TMetaPayload };
}) => {
	const newEpic: Epic<
		Action<string>,
		Action<string>,
		void,
		TEpicDependencies
	> = (action$, _, dependencies) =>
		action$.pipe(
			mergeMap((a) =>
				of(a).pipe(
					// we need to create a new observable here since catchError completes the observable if an error occurs
					ofType(getType(params.actionCreator)),
					groupBy(({ payload }: { type: string; payload: TArgs }) =>
						JSON.stringify(payload)
					),
					map((group) =>
						group.pipe(
							throttleTime(params.options?.throttleTime || 0)
						)
					), // prevents that the same api call gets executed multiple times within given throttle time
					switchAll(),
					mergeMap(({ payload }) =>
						from(
							params
								.fetch(payload, dependencies)
								.catch((error) => {
									// eslint-disable-next-line no-throw-literal
									throw { response: error, payload };
								})
						).pipe(map((result) => ({ payload, result })))
					),
					map(({ result, payload }) => {
						const toNormalize = params.resultSelector
							? params.resultSelector(result, payload)
							: result;

						const entities = (normalize(
							toNormalize,
							params.resultSchema
						).entities as unknown) as TEntities;
						// this could potentially be better typed
						const meta = params.createMeta
							? params.createMeta({ payload, result })
							: undefined;
						return params.updateEntitiesAction(entities, meta);
					}),
					catchError((error) => {
						const actions = [];
						if (error.response && error.payload) {
							// TODO Lets try to get additional typesafety in here as well
							const meta = params.createMeta
								? params.createMeta({ payload: error.payload })
								: undefined;
							actions.push(
								params.updateEntitiesAction(
									{} as TEntities,
									meta
								)
							);
						}

						return from(actions);
					})
				)
			)
		);
	return newEpic;
};

/**
 * testEpic is a simple test to compare the emited action against the expeected action for a given input action
 */
export const testEpic = (
	epic: Epic,
	expectedAction: Action,
	done: () => void,
	inputAction: Action,
	inputState?: object,
	dependencies?: object
) => {
	const actionSubject$ = new Subject<Action<unknown>>().pipe(
		observeOn(queueScheduler)
	) as Subject<Action<unknown>>;
	const stateSubject$ = new Subject<{}>().pipe(
		observeOn(queueScheduler)
	) as Subject<Action<unknown>>;

	const action$ = new ActionsObservable(actionSubject$);
	const state$ = new StateObservable(stateSubject$, inputState);
	const output$ = epic(action$, state$, dependencies);
	const onError = jest.fn(() => {});
	const onNext = jest.fn();
	output$.subscribe(onNext, onError, () => {
		expect(onError).not.toBeCalled();
		if (expectedAction) {
			const firstCallArguments = onNext.mock.calls[0];
			expect(onNext).toBeCalled();
			expect(firstCallArguments[0]).toMatchObject(expectedAction);
		}
		done();
	});
	actionSubject$.next(inputAction);
	actionSubject$.complete();
};
