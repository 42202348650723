import { useTranslation } from '@gaming-shell/i18n';
import { OnboardingTypes } from '@materialbet-core/api';
import {
	usePlayerCurrency,
	usePlayerForcedRoutes,
	usePlayerLoggedIn,
	usePlayerOnboardingStepsStatuses
} from '@materialbet-core/player';
import ForcedRedirect from 'core/components/Util/ForcedRedirect';
import {
	AuthRoutes,
	PlayerRoutes,
	SportsRoutes,
	TIER3_LOCATION_RESTRICTION_ENABLED,
	useSnackbar
} from 'materialbet-common';
import * as React from 'react';

import ForcedRedirectionSwitch from './ForcedRedirectionsSwitch';

const ForcedRedirections: React.FunctionComponent = () => {
	const trans = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const forcedRoutes = usePlayerForcedRoutes();
	const activeCurrency = usePlayerCurrency();
	const isLoggedIn = usePlayerLoggedIn();
	const onboardingStepsStatuses = usePlayerOnboardingStepsStatuses();

	return (
		<ForcedRedirectionSwitch>
			<ForcedRedirect
				to={PlayerRoutes.onboarding.create()}
				redirectCondition={!forcedRoutes.onboardingStatuses.signUp}
				allowedRoutes={[AuthRoutes.logout.create()]}
			/>
			{SPORTS_ONBOARDING_ENABLED && (
				<ForcedRedirect
					to={PlayerRoutes.onboarding.create()}
					state={{ type: OnboardingTypes.SPORTS }}
					redirectCondition={
						!forcedRoutes.onboardingStatuses.sports &&
						SportsRoutes.root.matches() &&
						!SportsRoutes.bets.matchesExactly() &&
						/* there's at least one not finished step */
						!!onboardingStepsStatuses.find(
							({ isFinished }) => !isFinished
						)
					}
					allowedRoutes={[AuthRoutes.logout.create()]}
				/>
			)}
			<ForcedRedirect
				to={PlayerRoutes.sms.create()}
				redirectCondition={forcedRoutes.sms}
				allowedRoutes={[
					AuthRoutes.logout.create(),
					PlayerRoutes.verification.create()
				]}
				sideEffect={() =>
					enqueueSnackbar(
						trans('player:sms.verificationRequired', {
							brand: BRAND_DISPLAY_NAME
						})
					)
				}
			/>
			{isLoggedIn && activeCurrency && (
				<ForcedRedirect
					to={PlayerRoutes.cashierWithdraw.create({
						currency: activeCurrency
					})}
					redirectCondition={
						forcedRoutes.countryRestrictionTier === 2
					}
					allowedRoutes={[AuthRoutes.logout.create()]}
					sideEffect={() =>
						enqueueSnackbar(
							trans('player:withdrawal.restrictedCountry')
						)
					}
				/>
			)}
			{TIER3_LOCATION_RESTRICTION_ENABLED && (
				<ForcedRedirect
					to={AuthRoutes.locationRestricted.create()}
					redirectCondition={
						forcedRoutes.countryRestrictionTier === 3
					}
				/>
			)}
		</ForcedRedirectionSwitch>
	);
};

export default ForcedRedirections;
