import { makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { BetPriceIndicator } from 'sports/components/displays/BetPrice';
import { FormattedOdds } from 'sports/components/displays/FormattedOdds';

import { useBetslipIsSubmitting } from '../hooks/useBetslipIsSubmitting';

export interface QuickBetslipPriceProps {
	price: number;
	changedPrice?: number;
	side: 'back' | 'lay';
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	indicatorSpacing: {
		paddingRight: theme.spacing(1)
	}
}));

export const QuickBetslipPrice: React.FunctionComponent<QuickBetslipPriceProps> = (
	props
) => {
	const { price, side, changedPrice } = props;
	const isSubmitting = useBetslipIsSubmitting();
	const odds = isSubmitting ? price : changedPrice || price;
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.indicatorSpacing}>
				<Typography color="primary" variant="h6">
					<FormattedOdds odds={odds} />
				</Typography>
			</div>
			<BetPriceIndicator odds={odds} side={side} />
		</div>
	);
};
