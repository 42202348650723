const storage = window.localStorage;

export const isStorageEnabled = (
	key: string,
	opts: { includeProd?: boolean } = {}
) =>
	(ENABLE_LOCALSTORAGE_FLAGS || opts.includeProd) &&
	storage &&
	storage.getItem(key) === 'true';

export const SPORTS_ONBOARDING_MODULE_ENABLED =
	isStorageEnabled('sportsOnboardingEnabled') || SPORTS_ONBOARDING_ENABLED;

export const ASIAN_VIEW_ENABLED =
	isStorageEnabled('asianView') || ENABLE_ASIAN_VIEW;

export const QUICK_DEPOSIT_ENABLED =
	isStorageEnabled('quickDeposit') || ENABLE_QUICK_DEPOSIT;

export const LEFT_NAVBAR_FOOTER_ENABLED =
	isStorageEnabled('navbarFooter') || ENABLE_NAVBAR_IMPROVEMENT;

export const TIER3_LOCATION_RESTRICTION_ENABLED =
	isStorageEnabled('tier3LocationRestrictionEnabled') ||
	TIER3_LOCATION_RESTRICTED_ENABLED;
