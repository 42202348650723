"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchNotificationsEpic = void 0;
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var typesafe_actions_1 = require("typesafe-actions");
var actions_1 = require("../actions");
var api_1 = require("../../api");
var utils_1 = require("../../utils");
exports.fetchNotificationsEpic = function (action$) {
    return action$.pipe(operators_1.filter(typesafe_actions_1.isActionOf(actions_1.actions.fetchNotifications.request)), operators_1.mergeMap(function () { return rxjs_1.from(api_1.api.getNotifications()); }), operators_1.filter(function (response) { return Array.isArray(response); }), operators_1.map(function (notifications) { return notifications.map(utils_1.mapApiResponseNotification); }), operators_1.map(function (notifications) {
        return actions_1.actions.fetchNotifications.success(notifications);
    }), operators_1.catchError(function (error) {
        return rxjs_1.of(actions_1.actions.fetchNotifications.failure(error ? error.toString() : 'unknown'));
    }));
};
