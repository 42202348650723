import * as React from 'react';
import { ReactReduxContextValue } from 'react-redux';
import { createStore } from 'redux';
import { ActionType } from 'typesafe-actions';

import { betslipActions, betslipReducer } from './betslipReducer';
import { defaultState } from './betslipReducer/betslipReducer';
import { BetslipState } from './types';

export const BetslipContext = React.createContext<
	ReactReduxContextValue<BetslipState, ActionType<typeof betslipActions>>
>({
	store: createStore<
		BetslipState,
		ActionType<typeof betslipActions>,
		unknown,
		unknown
	>(betslipReducer),
	storeState: defaultState
});
export type BetslipStore = Parameters<
	typeof BetslipContext['Provider']
>[0]['value']['store'];
