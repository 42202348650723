import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

const NearYou: React.FunctionComponent<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<path
				fill={props.fill}
				fillRule="nonzero"
				d="M17.179 16.252l.1.014c2.745.58 4.471 1.563 4.471 2.99 0 2.17-4.345 3.494-9.75 3.494s-9.75-1.325-9.75-3.494c0-1.424 1.717-2.405 4.451-2.987a.75.75 0 0 1 .312 1.467c-2.062.439-3.263 1.125-3.263 1.52 0 .862 3.713 1.994 8.25 1.994s8.25-1.132 8.25-1.994c0-.396-1.208-1.084-3.28-1.522a.75.75 0 1 1 .31-1.468zM12 1.25c3.727 0 6.75 3.005 6.75 6.714 0 1.463-.927 3.608-2.532 6.31a65.288 65.288 0 0 1-3.344 5.035 1.086 1.086 0 0 1-1.75-.003 65.27 65.27 0 0 1-3.342-5.032C6.177 11.572 5.25 9.427 5.25 7.964c0-3.71 3.023-6.714 6.75-6.714zm0 1.5c-2.9 0-5.25 2.335-5.25 5.214 0 1.096.856 3.077 2.322 5.544A62.856 62.856 0 0 0 12 17.958a62.857 62.857 0 0 0 2.928-4.45c1.466-2.467 2.322-4.448 2.322-5.544 0-2.879-2.35-5.214-5.25-5.214zm0 1.5a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5zm0 1.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5z"
			/>
		</SvgIcon>
	);
};
export default NearYou;
