"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unregister = exports.getRegistered = exports.register = void 0;
var store = new Map();
exports.register = function (id, component) {
    store.set(id, component);
};
exports.getRegistered = function (id) {
    // Return null as null is easier to handle in react than undefined (can be returned from render)
    if (store.has(id)) {
        /* disabled here as component can't be undefined here */
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return store.get(id);
    }
    return null;
};
exports.unregister = function (id) {
    store.delete(id);
};
