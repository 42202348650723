"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAnimationType = exports.appBarTypeToAnimationType = void 0;
var components_1 = require("./components");
var device_1 = require("@gaming-shell/device");
exports.appBarTypeToAnimationType = {
    window: {
        mobile: components_1.AnimatedAppBarIconType.Close,
        desktop: components_1.AnimatedAppBarIconType.Back
    },
    page: {
        mobile: components_1.AnimatedAppBarIconType.Back,
        desktop: components_1.AnimatedAppBarIconType.Menu
    },
    home: {
        mobile: components_1.AnimatedAppBarIconType.Menu,
        desktop: components_1.AnimatedAppBarIconType.Menu
    }
};
exports.getAnimationType = function (type) {
    return exports.appBarTypeToAnimationType[type][device_1.getPlatform()];
};
