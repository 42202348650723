import { gtag } from '@gaming-shell/gtm';
import { usePlayer, usePlayerLoggedIn } from '@materialbet-core/player';
import * as React from 'react';

export const Analytics: React.FunctionComponent<unknown> = () => {
	const isLoggedIn = usePlayerLoggedIn();
	const player = usePlayer();
	React.useEffect(() => {
		if (isLoggedIn) {
			const { authToken, ...loggedInUser } = player;
			gtag({ event: 'onLoggedIn', loggedInUser }); // this fires an event in GTM and adds the loggedInUser variable to the datalayer
		}
	}, [isLoggedIn]);
	return null;
};
