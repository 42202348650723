import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		left: 0,
		bottom: 0,
		top: 0,
		right: 0,
		zIndex: Object.values(theme.zIndex).reduce((sum, z) => sum + z, 0),
		backgroundColor: theme.palette.background.default,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around'
	}
}));

export const LoginLoading: React.FC = () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<CircularProgress />
		</div>
	);
};
