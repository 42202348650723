import { useMediaWidth } from 'materialbet-common';
import { sportPreviewCardDimensions } from 'sports/components/SportPreviewCard/SportPreviewCard.config';

export const useGetSportPreviewCardWidth = () => {
	const mediaWidth = useMediaWidth();
	return (
		sportPreviewCardDimensions[mediaWidth]?.width ||
		sportPreviewCardDimensions.xs.width
	);
};
