import { Translate } from '@gaming-shell/i18n';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { SportType } from 'sports/config/sport';
import { useAllSports } from 'sports/utils/sports';

import { SportSideMenuItem } from '../SportSideMenuItem';

/** @description a component of a nav items list of all sports sorted */
let CommonSportsList: React.FC = () => {
	const sports = useAllSports();
	const sportsList = React.useMemo(
		() =>
			sports.map((sportKey: SportType) => (
				<SportSideMenuItem key={sportKey} sportKey={sportKey} />
			)),
		[sports]
	);
	if (!sports.length) {
		return null;
	}
	const allSports = (
		<ListItem dense>
			<Typography variant="caption" color="textSecondary">
				<Translate label="sports.content.allSports" />
			</Typography>
		</ListItem>
	);
	return (
		<>
			{allSports}
			{sportsList}
		</>
	);
};

CommonSportsList = React.memo(CommonSportsList);
export { CommonSportsList };
