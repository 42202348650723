// Prop Selectors
export interface IdProp {
	id: number | string;
}
export interface IdsProps {
	ids: Array<number | string>;
}
export interface KeyProp {
	key: string;
}
export interface KeysProps {
	keys: string[];
}

export const idPropSelector = (_: unknown, { id }: IdProp) => id;
export const keyPropSelector = (_: unknown, { key }: KeyProp) => key;

export const idsPropSelector = (_: unknown, { ids }: IdsProps) => ids;
export const keysPropSelector = (_: unknown, { keys }: KeysProps) => keys;
