"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppLayout = exports.toolbarHeight = exports.drawerWidth = void 0;
var device_1 = require("@gaming-shell/device");
var core_1 = require("@material-ui/core/");
var styles_1 = require("@material-ui/core/styles");
var React = __importStar(require("react"));
var Announcements_1 = __importStar(require("./Announcements"));
var AppBar_1 = require("./AppBar");
var BetCount_1 = require("./AppBar/BetCount");
var betterAppBar_1 = require("./betterAppBar");
var BottomNavigation_1 = require("./BottomNavigation");
var Content_1 = require("./Content");
var ContentContext_1 = require("./Content/ContentContext");
var Footer_1 = require("./Footer");
var MenuDrawer_1 = require("./MenuDrawer");
var Context_1 = require("./MenuDrawer/Context");
var MenuProvider_1 = require("./MenuDrawer/MenuProvider");
var SideMenu_1 = require("./SideMenu");
var SideMenuDrawer_1 = require("./SideMenuDrawer");
var hooks_1 = require("./SideMenuDrawer/hooks");
var TopLoadingIndicator_1 = __importStar(require("./TopLoadingIndicator"));
exports.drawerWidth = DRAWER_WIDTH[device_1.getPlatform()];
exports.toolbarHeight = APP_BAR_HEIGHT[device_1.getPlatform()];
var useStyles = styles_1.makeStyles(function (theme) { return ({
    root: {
        display: 'flex'
    },
    drawer: {
        width: exports.drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: exports.drawerWidth,
        top: device_1.isDesktop() ? exports.toolbarHeight : 0,
        height: device_1.isDesktop() ? "calc(100% - " + exports.toolbarHeight + "px)" : '100%',
        boxShadow: theme.shadows[10],
        zIndex: device_1.isDesktop() ? theme.zIndex.appBar - 1 : undefined,
        backgroundColor: theme.palette.background.default,
        border: 0
    },
    invisible: {
        position: 'static',
        visibility: 'hidden'
    },
    bottomNav: {
        position: 'fixed',
        width: '100%',
        bottom: 0,
        marginLeft: device_1.isDesktop() ? exports.drawerWidth : undefined,
        boxShadow: '0px 10px 20px 10px rgba(0,0,0,0.5)'
    }
}); });
var AppLayoutRaw = function (_a) {
    var children = _a.children;
    var classes = useStyles();
    var isOpen = React.useContext(Context_1.SideMenuContext).isOpen;
    var closeDrawer = hooks_1.useCloseSideMenuDrawer();
    var openDrawer = hooks_1.useOpenSideMenuDrawer();
    var isDrawerOpen = hooks_1.useIsSideMenuDrawerOpen();
    var type = React.useContext(AppBar_1.AppBarDataContext).type;
    var open = (type === 'home' || (type === 'page' && device_1.isDesktop())) && isOpen;
    var isContentWidthModified = ENABLE_NEW_SIDE_MENU ? isDrawerOpen : isOpen;
    return (React.createElement(React.Fragment, null,
        React.createElement(betterAppBar_1.AppBarPortalContainer, null),
        ENABLE_NEW_SIDE_MENU ? (React.createElement(SideMenuDrawer_1.SideMenuDrawer, null)) : (React.createElement(MenuDrawer_1.MenuDrawer, { open: open, onCloseClick: closeDrawer, onOpen: openDrawer, classes: {
                root: classes.drawer,
                paper: classes.drawerPaper
            } })),
        React.createElement(Content_1.Content, { drawerWidth: exports.drawerWidth + "px", open: ENABLE_NEW_SIDE_MENU ? isDrawerOpen : open },
            React.createElement(TopLoadingIndicator_1.default, null,
                React.createElement(Announcements_1.default, { widthModifier: isContentWidthModified ? exports.drawerWidth : 0 }, children))),
        device_1.isMobile() && (React.createElement(React.Fragment, null,
            React.createElement("nav", { className: classes.bottomNav },
                React.createElement(BottomNavigation_1.BottomNav, null)),
            React.createElement("nav", { className: classes.invisible },
                React.createElement(BottomNavigation_1.BottomNav, null))))));
};
var StyledAppLayout = React.memo(AppLayoutRaw);
exports.AppLayout = function (_a) {
    var children = _a.children;
    return (React.createElement(ContentContext_1.ContentStateProvider, null,
        React.createElement(MenuProvider_1.SideMenuProvider, null,
            React.createElement(SideMenuDrawer_1.SideMenuDrawerProvider, null,
                React.createElement(SideMenu_1.SideMenuProvider, null,
                    React.createElement(Footer_1.FooterProvider, null,
                        React.createElement(BottomNavigation_1.BottomNavigationProvider, null,
                            React.createElement(AppBar_1.AppBarProvider, null,
                                React.createElement(betterAppBar_1.AppBarPortalProvider, null,
                                    React.createElement(betterAppBar_1.AccountButtonProvider, null,
                                        React.createElement(Announcements_1.AnnouncementsProvider, null,
                                            React.createElement(TopLoadingIndicator_1.TopLoadingIndicatorProvider, null,
                                                React.createElement(core_1.CssBaseline, null),
                                                React.createElement(BetCount_1.BetCountProvider, null,
                                                    React.createElement(StyledAppLayout, null, children))))))))))))));
};
