import Grid, { GridProps } from '@material-ui/core/Grid';
import * as React from 'react';

export type ColumnProps = Omit<GridProps, 'container' | 'direction'>;

// Don't test as there's nothing to test here
/* istanbul ignore file */
export const Column: React.FunctionComponent<ColumnProps> = ({
	children,
	...props
}) => {
	return (
		<Grid container direction="column" {...props}>
			{children}
		</Grid>
	);
};
