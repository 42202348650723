import {
	useChangeLanguage as useChangeLanguageI18n,
	useTranslation
} from '@gaming-shell/i18n';
import { setLanguageInPath } from '@gaming-shell/routing';
import { useSnackbar } from 'notistack';
import * as React from 'react';

export interface UseChangeAppLanguageArgument {
	key: string;
	name: string;
}
export type UseChangeAppLanguageOutput = (
	language: UseChangeAppLanguageArgument
) => void;

/** @description returns function which:
 * - changes i18n language
 * - modifies locale in path
 * - displays snackbar about successful language update */
export const useChangeAppLanguage = (): UseChangeAppLanguageOutput => {
	const changeLanguage = useChangeLanguageI18n();
	const { enqueueSnackbar } = useSnackbar();
	const trans = useTranslation();

	return React.useCallback(
		(language: UseChangeAppLanguageArgument) => {
			changeLanguage(language.key);
			setLanguageInPath(language.key);

			enqueueSnackbar(
				trans('player:preferences.preferredLanguageSuccess', {
					language: language.name
				})
			);
		},
		[changeLanguage, enqueueSnackbar, trans]
	);
};
