import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

const allMarkets = {
	popular: [
		{
			marketType: MarketType.aussie_rules_match_odds
		},
		{
			marketType: MarketType.aussie_rules_winner
		},
		{
			marketType: MarketType.aussie_rules_totals,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.aussie_rules_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.aussie_rules_double_chance
		},
		{
			marketType: MarketType.aussie_rules_odd_even
		},
		{
			marketType: MarketType.aussie_rules_1st_half_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.aussie_rules_quarter_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.aussie_rules_draw_no_bet
		},
		{
			marketType: MarketType.aussie_rules_team_total,
			view: MarketView.Goals
		}
	],
	firstHalf: [
		{
			marketType: MarketType.aussie_rules_1st_half_1x2
		},
		{
			marketType: MarketType.aussie_rules_1st_half_total,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.aussie_rules_1st_half_draw_no_bet
		},
		{
			marketType: MarketType.aussie_rules_1st_half_team_total,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.aussie_rules_1st_half_double_chance
		},
		{
			marketType: MarketType.aussie_rules_1st_half_odd_even
		}
	],
	specials: [
		{
			marketType: MarketType.aussie_rules_quarter_1x2
		},
		{
			marketType: MarketType.aussie_rules_quarter_total,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.aussie_rules_quarter_draw_no_bet
		},
		{
			marketType: MarketType.aussie_rules_quarter_winning_margin
		},
		{
			marketType: MarketType.aussie_rules_quarter_team_total,
			view: MarketView.Goals
		}
	],
	players: [
		{
			marketType: MarketType.aussie_rules_player_goals
		},
		{
			marketType: MarketType.aussie_rules_player_disposals
		},
		{
			marketType: MarketType.aussie_rules_player_kicks
		},

		{
			marketType: MarketType.aussie_rules_player_handballs
		},
		{
			marketType: MarketType.aussie_rules_player_tackles
		},
		{
			marketType: MarketType.aussie_rules_player_marks
		}
	]
};

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: allMarkets.popular
	},
	{
		label: 'markets.firstHalf',
		markets: allMarkets.firstHalf
	},
	{
		label: 'markets.players',
		markets: allMarkets.players
	},
	{
		label: 'markets.specials',
		markets: allMarkets.specials
	},
	{
		label: 'descriptions.all',
		markets: [
			...allMarkets.popular,
			...allMarkets.firstHalf,
			...allMarkets.players,
			...allMarkets.specials
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.aussie_rules_winner
			},
			{
				marketType: MarketType.aussie_rules_totals
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.aussie_rules_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
