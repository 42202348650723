/* eslint-disable react-hooks/rules-of-hooks */
import { Translate } from '@gaming-shell/i18n';
import { Collapse } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import {
	liveSideMenuCategories,
	rngSideMenuCategories
} from 'casino/config/casinoCategoriesConfig';
import { getSideMenuCatgorySlugs } from 'casino/selectors/casinoSelectors/casinoSelectors';
import { useSectionsLoaded, useSectionsParams } from 'casino/util/sectionUtils';
import { withHook } from 'materialbet-common';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { CasinoSideMenuLobbyItem } from '../CasinoSideMenuLobbyItem';
import { CasinoSideMenuSectionItem } from '../CasinoSideMenuSectionItem';

export interface CasinoSideMenuSectionListProps {
	categorySlugs: string[];
	isLoading: boolean;
	live: boolean;
}
let CasinoSideMenuSectionListUi: React.FunctionComponent<CasinoSideMenuSectionListProps> = ({
	categorySlugs,
	isLoading,
	live
}) => {
	const sections = categorySlugs.map((categorySlug) => (
		<CasinoSideMenuSectionItem
			key={categorySlug}
			categorySlug={categorySlug}
			live={live}
		/>
	));
	return (
		<React.Fragment>
			<ListItem dense>
				<Typography variant="caption" color="textSecondary">
					<Translate
						label={
							live
								? 'casino.content.liveCasino'
								: 'casino.content.rngCasino'
						}
					/>
				</Typography>
			</ListItem>
			<CasinoSideMenuLobbyItem live={live} />
			<Collapse in={!isLoading} timeout="auto" unmountOnExit>
				{sections}
			</Collapse>
		</React.Fragment>
	);
};
CasinoSideMenuSectionListUi = React.memo(CasinoSideMenuSectionListUi);
export { CasinoSideMenuSectionListUi };

export const useLiveCasinoSideMenuListProps = (): CasinoSideMenuSectionListProps => {
	const live = true;
	if (ENABLE_SECTIONLESS_SIDEMENU) {
		return {
			categorySlugs: liveSideMenuCategories,
			isLoading: false,
			live
		};
	}
	const params = useSectionsParams({ live });
	const categorySlugs = useSelector(getSideMenuCatgorySlugs(params));
	const isLoading = !useSectionsLoaded(params);
	return { categorySlugs, isLoading, live };
};
export const useRngCasinoSideMenuListProps = (): CasinoSideMenuSectionListProps => {
	const live = false;
	if (ENABLE_SECTIONLESS_SIDEMENU) {
		return {
			categorySlugs: rngSideMenuCategories,
			isLoading: false,
			live
		};
	}
	const params = useSectionsParams({ live });
	const categorySlugs = useSelector(getSideMenuCatgorySlugs(params));
	const isLoading = !useSectionsLoaded(params);
	return { categorySlugs, isLoading, live };
};
export const LiveCasinoSideMenuSectionList = withHook(
	useLiveCasinoSideMenuListProps
)(CasinoSideMenuSectionListUi);
export const RngCasinoSideMenuSectionList = withHook(
	useRngCasinoSideMenuListProps
)(CasinoSideMenuSectionListUi);
