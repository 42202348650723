/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Casino API
 * Casino API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as portableFetch from 'portable-fetch';
import * as url from 'url';

import { Configuration } from './configuration';

const BASE_PATH = 'http://http:/casino-api'.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
	csv: ',',
	ssv: ' ',
	tsv: '\t',
	pipes: '|'
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
	(url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
	url: string;
	options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
	protected configuration: Configuration | undefined;

	constructor(
		configuration?: Configuration,
		protected basePath: string = BASE_PATH,
		protected fetch: FetchAPI = portableFetch
	) {
		if (configuration) {
			this.configuration = configuration;
			this.basePath = configuration.basePath || this.basePath;
		}
	}
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
	name: 'RequiredError' = 'RequiredError';
	constructor(public field: string, msg?: string) {
		super(msg);
	}
}

/**
 *
 * @export
 * @interface FreespinsOffer
 */
export interface FreespinsOffer {
	/**
	 *
	 * @type {string}
	 * @memberof FreespinsOffer
	 */
	uuid?: string;
	/**
	 *
	 * @type {string}
	 * @memberof FreespinsOffer
	 */
	casino_currency?: string;
	/**
	 *
	 * @type {string}
	 * @memberof FreespinsOffer
	 */
	distributor_currency?: string;
	/**
	 *
	 * @type {string}
	 * @memberof FreespinsOffer
	 */
	spins_awarded?: string;
	/**
	 *
	 * @type {string}
	 * @memberof FreespinsOffer
	 */
	distributor_spin_value?: string;
	/**
	 *
	 * @type {string}
	 * @memberof FreespinsOffer
	 */
	expires_at?: string;
	/**
	 *
	 * @type {string}
	 * @memberof FreespinsOffer
	 */
	rollover_requirement?: string;
	/**
	 *
	 * @type {string}
	 * @memberof FreespinsOffer
	 */
	rollover_expires_in_days?: string;
	/**
	 *
	 * @type {string}
	 * @memberof FreespinsOffer
	 */
	created_at?: string;
	/**
	 *
	 * @type {Game}
	 * @memberof FreespinsOffer
	 */
	game?: Game;
}

/**
 *
 * @export
 * @interface Game
 */
export interface Game {
	/**
	 * The \"type\" or \"category\" of the game. For example \"slots\"
	 * @type {string}
	 * @memberof Game
	 */
	type?: Game.TypeEnum;
	/**
	 *
	 * @type {string}
	 * @memberof Game
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof Game
	 */
	game_key?: string;
	/**
	 *
	 * @type {string}
	 * @memberof Game
	 */
	name?: string;
	/**
	 *
	 * @type {string}
	 * @memberof Game
	 */
	description?: string;
	/**
	 *
	 * @type {GameStudio}
	 * @memberof Game
	 */
	studio?: GameStudio;
	/**
	 *
	 * @type {GameDistributor}
	 * @memberof Game
	 */
	distributor?: GameDistributor;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof Game
	 */
	tags?: Array<string>;
	/**
	 *
	 * @type {GamePlatforms}
	 * @memberof Game
	 */
	platforms?: GamePlatforms;
	/**
	 *
	 * @type {boolean}
	 * @memberof Game
	 */
	enabled_desktop?: boolean;
	/**
	 *
	 * @type {boolean}
	 * @memberof Game
	 */
	enabled_mobile?: boolean;
	/**
	 * Indicates minimum bet size for the game.
	 * @type {any}
	 * @memberof Game
	 */
	min_stake?: any;
	/**
	 *
	 * @type {boolean}
	 * @memberof Game
	 */
	non_functional?: boolean;
	/**
	 * Language code to indicate the spoken language of the dealer. Only applies to live dealer games.
	 * @type {string}
	 * @memberof Game
	 */
	language?: string;
	/**
	 * Parameters needed to launch the game. The content and structure depends on studio and distributor. This is omitted (empty) for unauthenticated requests
	 * @type {any}
	 * @memberof Game
	 */
	launch_params?: any;
	/**
	 * If true then the game is a live dealer game. Otherwise it is an RNG game.
	 * @type {boolean}
	 * @memberof Game
	 */
	live?: boolean;
	/**
	 *
	 * @type {Array<GameExtras>}
	 * @memberof Game
	 */
	extras?: Array<GameExtras>;
	/**
	 *
	 * @type {string}
	 * @memberof Game
	 */
	lozenge?: string;
	/**
	 *
	 * @type {string}
	 * @memberof Game
	 */
	ratio?: string;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof Game
	 */
	supported_currencies?: Array<string>;
	/**
	 * The list of two-letter country codes where the game is NOT available. Only returned if the request is authenticated.
	 * @type {Array<string>}
	 * @memberof Game
	 */
	country_blacklist?: Array<string>;
}

/**
 * @export
 * @namespace Game
 */
export namespace Game {
	/**
	 * @export
	 * @enum {string}
	 */
	export enum TypeEnum {
		Baccarat = 'baccarat',
		BingoAndKeno = 'bingo_and_keno',
		Blackjack = 'blackjack',
		InstantWin = 'instant_win',
		JackpotSlots = 'jackpot_slots',
		Other = 'other',
		Roulette = 'roulette',
		Slots = 'slots',
		TableGame = 'table_game',
		TableGames = 'table_games',
		VideoPoker = 'video_poker',
		Virtual = 'virtual'
	}
}

/**
 *
 * @export
 * @interface GameDistributor
 */
export interface GameDistributor {
	/**
	 *
	 * @type {string}
	 * @memberof GameDistributor
	 */
	key?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GameDistributor
	 */
	short_key?: string;
}

/**
 *
 * @export
 * @interface GameExtras
 */
export interface GameExtras {
	/**
	 *
	 * @type {string}
	 * @memberof GameExtras
	 */
	name?: string;
	/**
	 *
	 * @type {any}
	 * @memberof GameExtras
	 */
	value?: any;
}

/**
 *
 * @export
 * @interface GamePlatforms
 */
export interface GamePlatforms {
	/**
	 *
	 * @type {GamePlatformsDesktop}
	 * @memberof GamePlatforms
	 */
	desktop?: GamePlatformsDesktop;
	/**
	 *
	 * @type {GamePlatformsMobile}
	 * @memberof GamePlatforms
	 */
	mobile?: GamePlatformsMobile;
}

/**
 *
 * @export
 * @interface GamePlatformsDesktop
 */
export interface GamePlatformsDesktop {
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsDesktop
	 */
	background_image?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsDesktop
	 */
	video_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsDesktop
	 */
	launch_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsDesktop
	 */
	logo_image_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsDesktop
	 */
	demo_video_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsDesktop
	 */
	image_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsDesktop
	 */
	portrait_image_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsDesktop
	 */
	character_image_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsDesktop
	 */
	character2_image_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsDesktop
	 */
	freeplay_launch_url?: string;
}

/**
 *
 * @export
 * @interface GamePlatformsMobile
 */
export interface GamePlatformsMobile {
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsMobile
	 */
	background_image?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsMobile
	 */
	video_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsMobile
	 */
	launch_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsMobile
	 */
	logo_image_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsMobile
	 */
	demo_video_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsMobile
	 */
	image_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsMobile
	 */
	portrait_image_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsMobile
	 */
	character_image_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsMobile
	 */
	character2_image_url?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GamePlatformsMobile
	 */
	freeplay_launch_url?: string;
}

/**
 *
 * @export
 * @interface GameRecord
 */
export interface GameRecord {
	/**
	 * The identifier of the game. Generated on the server when the game is created.
	 * @type {number}
	 * @memberof GameRecord
	 */
	id?: number;
	/**
	 * The UUID of the game. Generated on the server when the game is created.
	 * @type {string}
	 * @memberof GameRecord
	 */
	uuid?: string;
	/**
	 * The name of the game in all available locales. Key is the locale, value is the name of the game in that locale.
	 * @type {{ [key: string]: string; }}
	 * @memberof GameRecord
	 */
	names?: { [key: string]: string };
	/**
	 * The description of the game in all available locales. Key is the locale, value is the description of the game in that locale.
	 * @type {{ [key: string]: string; }}
	 * @memberof GameRecord
	 */
	descriptions?: { [key: string]: string };
	/**
	 * Identifier of the game. May be deprecated in the future.
	 * @type {string}
	 * @memberof GameRecord
	 */
	game_key?: string;
	/**
	 * The creator of the game. Examples: playngo, ezugi
	 * @type {string}
	 * @memberof GameRecord
	 */
	studio?: string;
	/**
	 * The distributor who access the game through. Can be the same as the studio. Examples: playngo, ezugi, microgaming
	 * @type {string}
	 * @memberof GameRecord
	 */
	distributor?: string;
	/**
	 * Type of the game. Examples: slots, roulette, instant_win
	 * @type {string}
	 * @memberof GameRecord
	 */
	game_type?: string;
	/**
	 * If true then the game is a live dealer game. Otherwise it is an RNG game.
	 * @type {boolean}
	 * @memberof GameRecord
	 */
	live?: boolean;
	/**
	 * The list of two-letter country codes where the game is NOT available.
	 * @type {Array<string>}
	 * @memberof GameRecord
	 */
	country_blacklist?: Array<string>;
	/**
	 * The index of the game to allow sorting. Required for live dealer games (when `live == true`). Not used for RNG games.
	 * @type {number}
	 * @memberof GameRecord
	 */
	display_order?: number;
	/**
	 * The featured index of the game to allow sorting the featured games.
	 * @type {number}
	 * @memberof GameRecord
	 */
	featured_order?: number;
	/**
	 * The aspect ratio of the game window. Only used for desktop Live Dealer games. Later might be used for desktop RNG games as well. Example: \"16:9\"
	 * @type {string}
	 * @memberof GameRecord
	 */
	ratio?: string;
	/**
	 * The list of currency codes which this game supports. E.g. BTC, BCH
	 * @type {Array<string>}
	 * @memberof GameRecord
	 */
	available_currencies?: Array<string>;
	/**
	 * The list of platforms what the game is available on. E.g. desktop, mobile
	 * @type {Array<string>}
	 * @memberof GameRecord
	 */
	available_platforms?: Array<GameRecord.AvailablePlatformsEnum>;
	/**
	 * Flag to indicate that the game is enabled. This flag allows games to be temporarily disabled.
	 * @type {boolean}
	 * @memberof GameRecord
	 */
	enabled?: boolean;
	/**
	 * Flag to indicate that the game is visible and listed on our website. Certain games are not visible, e.g. the \"mini live roulette\"
	 * @type {boolean}
	 * @memberof GameRecord
	 */
	visible?: boolean;
	/**
	 * Indicates minimum bet size for the game.
	 * @type {any}
	 * @memberof GameRecord
	 */
	min_stake?: any;
	/**
	 * Flag to indicate that the game is known to not work. It can be set when we know that some game don't work on STG but we still want to show the game tile.
	 * @type {boolean}
	 * @memberof GameRecord
	 */
	non_functional?: boolean;
	/**
	 * Flag to indicate that the game should be permanently disabled. E.g. the live dealer table has been closed by the studio or the RNG game is decommissioned.
	 * @type {boolean}
	 * @memberof GameRecord
	 */
	decommissioned?: boolean;
	/**
	 * Language code to indicate the spoken language of the dealer. Only applies to live dealer games.
	 * @type {string}
	 * @memberof GameRecord
	 */
	language?: string;
	/**
	 * Tags of the game, e.g. new, featured
	 * @type {Array<string>}
	 * @memberof GameRecord
	 */
	tags?: Array<string>;
	/**
	 * Rollover percentage for the game. Required for RNG games (when `live == false`)
	 * @type {number}
	 * @memberof GameRecord
	 */
	rollover_percentage?: number;
	/**
	 * RTP for the game. Required for RNG games (when `live == false`)
	 * @type {number}
	 * @memberof GameRecord
	 */
	rtp?: number;
	/**
	 * Launch parameters for the game. The actual keys and values depend on the studio and distributor.
	 * @type {any}
	 * @memberof GameRecord
	 */
	launch_params?: any;
	/**
	 * Image urls for the game. E.g. {\"desktop\": \"/casino/images/netent/alien-robots/desktop.jpg\", \"logo\": \"/casino/images/netent/alien-robots/logo.jpg\"}
	 * @type {{ [key: string]: string; }}
	 * @memberof GameRecord
	 */
	images?: { [key: string]: string };
	/**
	 * Extra feature attributes for the game. E.g. {\"reels\": 5, \"volatility\": \"high\"}
	 * @type {any}
	 * @memberof GameRecord
	 */
	extras?: any;
}

/**
 * @export
 * @namespace GameRecord
 */
export namespace GameRecord {
	/**
	 * @export
	 * @enum {string}
	 */
	export enum AvailablePlatformsEnum {
		Desktop = 'desktop',
		Mobile = 'mobile'
	}
}

/**
 *
 * @export
 * @interface GameStudio
 */
export interface GameStudio {
	/**
	 *
	 * @type {string}
	 * @memberof GameStudio
	 */
	name?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GameStudio
	 */
	key?: string;
	/**
	 *
	 * @type {string}
	 * @memberof GameStudio
	 */
	short_key?: string;
}

/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
	/**
	 *
	 * @type {Array<FreespinsOffer>}
	 * @memberof InlineResponse200
	 */
	freespins_offers?: Array<FreespinsOffer>;
}

/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
	/**
	 *
	 * @type {Array<RolloverBonus>}
	 * @memberof InlineResponse2001
	 */
	rollover_bonuses?: Array<RolloverBonus>;
}

/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
	/**
	 * an application-specific error code
	 * @type {string}
	 * @memberof InlineResponse2002
	 */
	code?: string;
}

/**
 *
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
	/**
	 * The total number of games
	 * @type {number}
	 * @memberof InlineResponse2003
	 */
	total?: number;
	/**
	 * Total number of pages
	 * @type {number}
	 * @memberof InlineResponse2003
	 */
	pages?: number;
	/**
	 *
	 * @type {Array<GameRecord>}
	 * @memberof InlineResponse2003
	 */
	list?: Array<GameRecord>;
}

/**
 *
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
	/**
	 * The total number of games
	 * @type {number}
	 * @memberof InlineResponse2004
	 */
	total?: number;
	/**
	 * Total number of pages
	 * @type {number}
	 * @memberof InlineResponse2004
	 */
	pages?: number;
	/**
	 * Unique identifier for this batch/list of recommendations
	 * @type {string}
	 * @memberof InlineResponse2004
	 */
	batch_identifier?: string;
	/**
	 *
	 * @type {Array<Game>}
	 * @memberof InlineResponse2004
	 */
	list?: Array<Game>;
}

/**
 *
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
	/**
	 *
	 * @type {Array<StudioWithGameCount>}
	 * @memberof InlineResponse2005
	 */
	list?: Array<StudioWithGameCount>;
}

/**
 *
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
	/**
	 * The total number of games
	 * @type {number}
	 * @memberof InlineResponse2006
	 */
	total?: number;
	/**
	 * Total number of pages
	 * @type {number}
	 * @memberof InlineResponse2006
	 */
	pages?: number;
	/**
	 *
	 * @type {Array<Game>}
	 * @memberof InlineResponse2006
	 */
	list?: Array<Game>;
}

/**
 *
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
	/**
	 * Version of this format, always present so can be pre-emptively read to determine if/how to validate
	 * @type {number}
	 * @memberof InlineResponse2007
	 */
	version?: number;
	/**
	 * A list of Casino content sections
	 * @type {Array<InlineResponse2007Sections>}
	 * @memberof InlineResponse2007
	 */
	sections?: Array<InlineResponse2007Sections>;
}

/**
 * The category page
 * @export
 * @interface InlineResponse2007CategoryPage
 */
export interface InlineResponse2007CategoryPage {
	/**
	 * Specifies the default sort index in sorts array
	 * @type {number}
	 * @memberof InlineResponse2007CategoryPage
	 */
	default_sort_index?: number;
	/**
	 * Specifies the default filter index in filters array
	 * @type {number}
	 * @memberof InlineResponse2007CategoryPage
	 */
	default_filter_index?: number;
	/**
	 *
	 * @type {Array<InlineResponse2007CategoryPageFilters>}
	 * @memberof InlineResponse2007CategoryPage
	 */
	filters?: Array<InlineResponse2007CategoryPageFilters>;
	/**
	 *
	 * @type {Array<InlineResponse2007CategoryPageSorts>}
	 * @memberof InlineResponse2007CategoryPage
	 */
	sorts?: Array<InlineResponse2007CategoryPageSorts>;
}

/**
 *
 * @export
 * @interface InlineResponse2007CategoryPageFilters
 */
export interface InlineResponse2007CategoryPageFilters {
	/**
	 * Specifies the translation key for the filter
	 * @type {string}
	 * @memberof InlineResponse2007CategoryPageFilters
	 */
	translation_key?: string;
	/**
	 * Specifies the filter ID
	 * @type {number}
	 * @memberof InlineResponse2007CategoryPageFilters
	 */
	id?: number;
}

/**
 *
 * @export
 * @interface InlineResponse2007CategoryPageSorts
 */
export interface InlineResponse2007CategoryPageSorts {
	/**
	 * Specifies the translation key for the sorting option
	 * @type {string}
	 * @memberof InlineResponse2007CategoryPageSorts
	 */
	translation_key?: string;
	/**
	 * Sorting options. It supports sorting by multiple attributes, like \"recommendation,popularity\". The sort order is ascending by default, to sort in descending order the attribute name must start with hyphen e.g. \"-name\" will sort z-a
	 * @type {Array<string>}
	 * @memberof InlineResponse2007CategoryPageSorts
	 */
	sort?: Array<InlineResponse2007CategoryPageSorts.SortEnum>;
	/**
	 * Specifies the icon name for the sorting option
	 * @type {string}
	 * @memberof InlineResponse2007CategoryPageSorts
	 */
	icon_name?: string;
}

/**
 * @export
 * @namespace InlineResponse2007CategoryPageSorts
 */
export namespace InlineResponse2007CategoryPageSorts {
	/**
	 * @export
	 * @enum {string}
	 */
	export enum SortEnum {
		Name = 'name',
		DName = '-name',
		Recommendation = 'recommendation',
		DRecommendation = '-recommendation',
		Popularity = 'popularity',
		DPopularity = '-popularity',
		BiggestPossibleWin = 'biggest_possible_win',
		DBiggestPossibleWin = '-biggest_possible_win',
		Id = 'id',
		DId = '-id'
	}
}

/**
 * Describes the limit for desktop and mobile
 * @export
 * @interface InlineResponse2007Limit
 */
export interface InlineResponse2007Limit {
	/**
	 * The maximum number of game tiles displayed on DESKTOP. If not present then there will be no limit
	 * @type {number}
	 * @memberof InlineResponse2007Limit
	 */
	desktop?: number;
	/**
	 * The maximum number of game tiles displayed on MOBILE. If not present then there will be no limit
	 * @type {number}
	 * @memberof InlineResponse2007Limit
	 */
	mobile?: number;
}

/**
 *
 * @export
 * @interface InlineResponse2007Sections
 */
export interface InlineResponse2007Sections {
	/**
	 * If true then only recommended games will be shown. Otherwise all games will be shown
	 * @type {boolean}
	 * @memberof InlineResponse2007Sections
	 */
	recommendations_only?: boolean;
	/**
	 *
	 * @type {InlineResponse2007CategoryPage}
	 * @memberof InlineResponse2007Sections
	 */
	category_page?: InlineResponse2007CategoryPage;
	/**
	 * Specifies the tile type of the section
	 * @type {string}
	 * @memberof InlineResponse2007Sections
	 */
	tile_type?: InlineResponse2007Sections.TileTypeEnum;
	/**
	 * List of slide indexes for casinoCarousel type.
	 * @type {Array<number>}
	 * @memberof InlineResponse2007Sections
	 */
	slides?: Array<number>;
	/**
	 * If present, only show games which has all of these tags. For example if we specify 'slots' and 'featured' then it will only show slot games which are featured
	 * @type {Array<string>}
	 * @memberof InlineResponse2007Sections
	 */
	game_tags?: Array<string>;
	/**
	 * If true then disable view all link. Otherwise show view all link.
	 * @type {boolean}
	 * @memberof InlineResponse2007Sections
	 */
	disable_view_all_link?: boolean;
	/**
	 * If present, only show games which are of one of these game types. For example if we specify 'blackjack' and 'roulette' then it will show both blackjack and roulette games only
	 * @type {Array<string>}
	 * @memberof InlineResponse2007Sections
	 */
	game_type?: Array<string>;
	/**
	 * Sorting options. It supports sorting by multiple attributes, like \"recommendation,popularity\". The sort order is ascending by default, to sort in descending order the attribute name must start with hyphen e.g. \"-name\" will sort z-a
	 * @type {Array<string>}
	 * @memberof InlineResponse2007Sections
	 */
	sort?: Array<InlineResponse2007Sections.SortEnum>;
	/**
	 * filters for game extras attribute
	 * @type {any}
	 * @memberof InlineResponse2007Sections
	 */
	filters?: any;
	/**
	 * Specifies the type of the section
	 * @type {string}
	 * @memberof InlineResponse2007Sections
	 */
	type: InlineResponse2007Sections.TypeEnum;
	/**
	 * The icon name.
	 * @type {string}
	 * @memberof InlineResponse2007Sections
	 */
	icon_name?: string;
	/**
	 *
	 * @type {InlineResponse2007Limit}
	 * @memberof InlineResponse2007Sections
	 */
	limit?: InlineResponse2007Limit;
	/**
	 * The translation key for the section header
	 * @type {string}
	 * @memberof InlineResponse2007Sections
	 */
	translation_key?: string;
	/**
	 * Specifies the id of the section
	 * @type {string}
	 * @memberof InlineResponse2007Sections
	 */
	id?: string;
	/**
	 * If present, only show games which are of one of these game studios. For example if we specify 'netent' and 'microgaming' then it will show both netent and microgaming games only
	 * @type {Array<string>}
	 * @memberof InlineResponse2007Sections
	 */
	game_studio?: Array<string>;
	/**
	 * Specifies the category slug of the section
	 * @type {string}
	 * @memberof InlineResponse2007Sections
	 */
	category_slug?: string;
}

/**
 * @export
 * @namespace InlineResponse2007Sections
 */
export namespace InlineResponse2007Sections {
	/**
	 * @export
	 * @enum {string}
	 */
	export enum TileTypeEnum {
		Tile = 'tile',
		Portrait = 'portrait',
		Character = 'character'
	}
	/**
	 * @export
	 * @enum {string}
	 */
	export enum SortEnum {
		Name = 'name',
		DName = '-name',
		Recommendation = 'recommendation',
		DRecommendation = '-recommendation',
		Popularity = 'popularity',
		DPopularity = '-popularity',
		BiggestPossibleWin = 'biggest_possible_win',
		DBiggestPossibleWin = '-biggest_possible_win'
	}
	/**
	 * @export
	 * @enum {string}
	 */
	export enum TypeEnum {
		CasinoCarousel = 'casinoCarousel',
		GameRow = 'gameRow'
	}
}

/**
 *
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
	/**
	 *
	 * @type {InlineResponse2008Version}
	 * @memberof InlineResponse2008
	 */
	version?: InlineResponse2008Version;
	/**
	 *
	 * @type {string}
	 * @memberof InlineResponse2008
	 */
	live?: string;
}

/**
 *
 * @export
 * @interface InlineResponse2008Version
 */
export interface InlineResponse2008Version {
	/**
	 *
	 * @type {string}
	 * @memberof InlineResponse2008Version
	 */
	name?: string;
	/**
	 *
	 * @type {string}
	 * @memberof InlineResponse2008Version
	 */
	hash?: string;
}

/**
 *
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
	/**
	 *
	 * @type {string}
	 * @memberof InlineResponse2009
	 */
	postgres?: string;
	/**
	 *
	 * @type {string}
	 * @memberof InlineResponse2009
	 */
	redis?: string;
}

/**
 *
 * @export
 * @interface InlineResponse403
 */
export interface InlineResponse403 {
	/**
	 * an application-specific error code
	 * @type {string}
	 * @memberof InlineResponse403
	 */
	code?: string;
}

/**
 *
 * @export
 * @interface InlineResponse404
 */
export interface InlineResponse404 {
	/**
	 * an application-specific error code
	 * @type {string}
	 * @memberof InlineResponse404
	 */
	code?: string;
}

/**
 *
 * @export
 * @interface InlineResponse4041
 */
export interface InlineResponse4041 {
	/**
	 * specific error code.
	 * @type {string}
	 * @memberof InlineResponse4041
	 */
	message?: InlineResponse4041.MessageEnum;
}

/**
 * @export
 * @namespace InlineResponse4041
 */
export namespace InlineResponse4041 {
	/**
	 * @export
	 * @enum {string}
	 */
	export enum MessageEnum {
		NOTFOUND = 'NOT_FOUND',
		UNAVAILABLECURRENCY = 'UNAVAILABLE_CURRENCY',
		UNAVAILABLECOUNTRY = 'UNAVAILABLE_COUNTRY',
		UNAVAILABLEPLATFORM = 'UNAVAILABLE_PLATFORM'
	}
}

/**
 *
 * @export
 * @interface InlineResponse4042
 */
export interface InlineResponse4042 {
	/**
	 *
	 * @type {Game}
	 * @memberof InlineResponse4042
	 */
	game?: Game;
	/**
	 * specific error code.
	 * @type {string}
	 * @memberof InlineResponse4042
	 */
	message?: InlineResponse4042.MessageEnum;
}

/**
 * @export
 * @namespace InlineResponse4042
 */
export namespace InlineResponse4042 {
	/**
	 * @export
	 * @enum {string}
	 */
	export enum MessageEnum {
		NOTFOUND = 'NOT_FOUND',
		UNAVAILABLECURRENCY = 'UNAVAILABLE_CURRENCY',
		UNAVAILABLECOUNTRY = 'UNAVAILABLE_COUNTRY',
		UNAVAILABLEPLATFORM = 'UNAVAILABLE_PLATFORM'
	}
}

/**
 *
 * @export
 * @interface InlineResponse4043
 */
export interface InlineResponse4043 {
	/**
	 * specific error code.
	 * @type {string}
	 * @memberof InlineResponse4043
	 */
	message?: InlineResponse4043.MessageEnum;
}

/**
 * @export
 * @namespace InlineResponse4043
 */
export namespace InlineResponse4043 {
	/**
	 * @export
	 * @enum {string}
	 */
	export enum MessageEnum {
		NOTFOUND = 'NOT_FOUND'
	}
}

/**
 *
 * @export
 * @interface InlineResponse422
 */
export interface InlineResponse422 {
	/**
	 * an application-specific error code
	 * @type {string}
	 * @memberof InlineResponse422
	 */
	code?: string;
}

/**
 *
 * @export
 * @interface InlineResponse4221
 */
export interface InlineResponse4221 {
	/**
	 * an application-specific error code
	 * @type {string}
	 * @memberof InlineResponse4221
	 */
	code?: string;
	/**
	 *
	 * @type {string}
	 * @memberof InlineResponse4221
	 */
	message?: string;
}

/**
 *
 * @export
 * @interface InlineResponse503
 */
export interface InlineResponse503 {
	/**
	 * an application-specific error code
	 * @type {string}
	 * @memberof InlineResponse503
	 */
	code?: string;
}

/**
 *
 * @export
 * @interface RolloverBonus
 */
export interface RolloverBonus {
	/**
	 *
	 * @type {string}
	 * @memberof RolloverBonus
	 */
	id?: string;
	/**
	 * Rollover bonus amount awarded
	 * @type {string}
	 * @memberof RolloverBonus
	 */
	amount?: string;
	/**
	 * Base currency (on our end) of rollover bonus amount
	 * @type {string}
	 * @memberof RolloverBonus
	 */
	casino_currency?: string;
	/**
	 *
	 * @type {string}
	 * @memberof RolloverBonus
	 */
	rollover_requirement?: string;
	/**
	 *
	 * @type {string}
	 * @memberof RolloverBonus
	 */
	current_playthrough_amount?: string;
	/**
	 * total_playthrough_amount - current_playthrough_amount
	 * @type {string}
	 * @memberof RolloverBonus
	 */
	remaining_playthrough_amount?: string;
	/**
	 * amount * rollover_requirement
	 * @type {string}
	 * @memberof RolloverBonus
	 */
	total_playthrough_amount?: string;
	/**
	 * (current_playthrough_amount / total_playthrough_amount) * 100
	 * @type {string}
	 * @memberof RolloverBonus
	 */
	playthrough_progress_percentage?: string;
	/**
	 *
	 * @type {string}
	 * @memberof RolloverBonus
	 */
	expires_at?: string;
	/**
	 *
	 * @type {string}
	 * @memberof RolloverBonus
	 */
	created_at?: string;
}

/**
 *
 * @export
 * @interface StudioWithGameCount
 */
export interface StudioWithGameCount {
	/**
	 * The studio key.
	 * @type {string}
	 * @memberof StudioWithGameCount
	 */
	key?: string;
	/**
	 * The name of the studio in the language specified by the \"lang\" parameter. If the studio name is not available in the requested language it will fall back to english.
	 * @type {string}
	 * @memberof StudioWithGameCount
	 */
	name?: string;
	/**
	 * The number of games available in this content section from this studio.
	 * @type {number}
	 * @memberof StudioWithGameCount
	 */
	game_count?: number;
}

/**
 * DefaultApi - fetch parameter creator
 * @export
 */
export const DefaultApiFetchParamCreator = function (
	configuration?: Configuration
) {
	return {
		/**
		 *
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		clearCacheDelete(options: any = {}): FetchArgs {
			const localVarPath = `/clear_cache`;
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'DELETE' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {boolean} signed_in
		 * @param {string} locale
		 * @param {'mobile' | 'desktop'} platform
		 * @param {string} currency
		 * @param {string} [country_code]
		 * @param {Array<string>} [player_tags]
		 * @param {boolean} [visited_lobby]
		 * @param {boolean} [live]
		 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
		 * @param {boolean} [debug]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidContentSectionsGet(
			player_uuid: string,
			signed_in: boolean,
			locale: string,
			platform: 'mobile' | 'desktop',
			currency: string,
			country_code?: string,
			player_tags?: Array<string>,
			visited_lobby?: boolean,
			live?: boolean,
			brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
			debug?: boolean,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'player_uuid' is not null or undefined
			if (player_uuid === null || player_uuid === undefined) {
				throw new RequiredError(
					'player_uuid',
					'Required parameter player_uuid was null or undefined when calling v1PlayersPlayerUuidContentSectionsGet.'
				);
			}
			// verify required parameter 'signed_in' is not null or undefined
			if (signed_in === null || signed_in === undefined) {
				throw new RequiredError(
					'signed_in',
					'Required parameter signed_in was null or undefined when calling v1PlayersPlayerUuidContentSectionsGet.'
				);
			}
			// verify required parameter 'locale' is not null or undefined
			if (locale === null || locale === undefined) {
				throw new RequiredError(
					'locale',
					'Required parameter locale was null or undefined when calling v1PlayersPlayerUuidContentSectionsGet.'
				);
			}
			// verify required parameter 'platform' is not null or undefined
			if (platform === null || platform === undefined) {
				throw new RequiredError(
					'platform',
					'Required parameter platform was null or undefined when calling v1PlayersPlayerUuidContentSectionsGet.'
				);
			}
			// verify required parameter 'currency' is not null or undefined
			if (currency === null || currency === undefined) {
				throw new RequiredError(
					'currency',
					'Required parameter currency was null or undefined when calling v1PlayersPlayerUuidContentSectionsGet.'
				);
			}
			const localVarPath = `/v1/players/{player_uuid}/content_sections`.replace(
				`{${'player_uuid'}}`,
				encodeURIComponent(String(player_uuid))
			);
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			if (signed_in !== undefined) {
				localVarQueryParameter['signed-in'] = signed_in;
			}

			if (locale !== undefined) {
				localVarQueryParameter['locale'] = locale;
			}

			if (country_code !== undefined) {
				localVarQueryParameter['country-code'] = country_code;
			}

			if (platform !== undefined) {
				localVarQueryParameter['platform'] = platform;
			}

			if (player_tags) {
				localVarQueryParameter['player-tags'] = player_tags.join(
					COLLECTION_FORMATS['csv']
				);
			}

			if (currency !== undefined) {
				localVarQueryParameter['currency'] = currency;
			}

			if (visited_lobby !== undefined) {
				localVarQueryParameter['visited-lobby'] = visited_lobby;
			}

			if (live !== undefined) {
				localVarQueryParameter['live'] = live;
			}

			if (brand !== undefined) {
				localVarQueryParameter['brand'] = brand;
			}

			if (debug !== undefined) {
				localVarQueryParameter['debug'] = debug;
			}

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		}
	};
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
	return {
		/**
		 *
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		clearCacheDelete(
			options?: any
		): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = DefaultApiFetchParamCreator(
				configuration
			).clearCacheDelete(options);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response;
					} else {
						throw response;
					}
				});
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {boolean} signed_in
		 * @param {string} locale
		 * @param {'mobile' | 'desktop'} platform
		 * @param {string} currency
		 * @param {string} [country_code]
		 * @param {Array<string>} [player_tags]
		 * @param {boolean} [visited_lobby]
		 * @param {boolean} [live]
		 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
		 * @param {boolean} [debug]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidContentSectionsGet(
			player_uuid: string,
			signed_in: boolean,
			locale: string,
			platform: 'mobile' | 'desktop',
			currency: string,
			country_code?: string,
			player_tags?: Array<string>,
			visited_lobby?: boolean,
			live?: boolean,
			brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
			debug?: boolean,
			options?: any
		): (
			fetch?: FetchAPI,
			basePath?: string
		) => Promise<InlineResponse2007> {
			const localVarFetchArgs = DefaultApiFetchParamCreator(
				configuration
			).v1PlayersPlayerUuidContentSectionsGet(
				player_uuid,
				signed_in,
				locale,
				platform,
				currency,
				country_code,
				player_tags,
				visited_lobby,
				live,
				brand,
				debug,
				options
			);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		}
	};
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
	configuration?: Configuration,
	fetch?: FetchAPI,
	basePath?: string
) {
	return {
		/**
		 *
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		clearCacheDelete(options?: any) {
			return DefaultApiFp(configuration).clearCacheDelete(options)(
				fetch,
				basePath
			);
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {boolean} signed_in
		 * @param {string} locale
		 * @param {'mobile' | 'desktop'} platform
		 * @param {string} currency
		 * @param {string} [country_code]
		 * @param {Array<string>} [player_tags]
		 * @param {boolean} [visited_lobby]
		 * @param {boolean} [live]
		 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
		 * @param {boolean} [debug]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidContentSectionsGet(
			player_uuid: string,
			signed_in: boolean,
			locale: string,
			platform: 'mobile' | 'desktop',
			currency: string,
			country_code?: string,
			player_tags?: Array<string>,
			visited_lobby?: boolean,
			live?: boolean,
			brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
			debug?: boolean,
			options?: any
		) {
			return DefaultApiFp(
				configuration
			).v1PlayersPlayerUuidContentSectionsGet(
				player_uuid,
				signed_in,
				locale,
				platform,
				currency,
				country_code,
				player_tags,
				visited_lobby,
				live,
				brand,
				debug,
				options
			)(fetch, basePath);
		}
	};
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
	/**
	 *
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof DefaultApi
	 */
	public clearCacheDelete(options?: any) {
		return DefaultApiFp(this.configuration).clearCacheDelete(options)(
			this.fetch,
			this.basePath
		);
	}

	/**
	 *
	 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
	 * @param {boolean} signed_in
	 * @param {string} locale
	 * @param {'mobile' | 'desktop'} platform
	 * @param {string} currency
	 * @param {string} [country_code]
	 * @param {Array<string>} [player_tags]
	 * @param {boolean} [visited_lobby]
	 * @param {boolean} [live]
	 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
	 * @param {boolean} [debug]
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof DefaultApi
	 */
	public v1PlayersPlayerUuidContentSectionsGet(
		player_uuid: string,
		signed_in: boolean,
		locale: string,
		platform: 'mobile' | 'desktop',
		currency: string,
		country_code?: string,
		player_tags?: Array<string>,
		visited_lobby?: boolean,
		live?: boolean,
		brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
		debug?: boolean,
		options?: any
	) {
		return DefaultApiFp(
			this.configuration
		).v1PlayersPlayerUuidContentSectionsGet(
			player_uuid,
			signed_in,
			locale,
			platform,
			currency,
			country_code,
			player_tags,
			visited_lobby,
			live,
			brand,
			debug,
			options
		)(this.fetch, this.basePath);
	}
}

/**
 * FavouritesApi - fetch parameter creator
 * @export
 */
export const FavouritesApiFetchParamCreator = function (
	configuration?: Configuration
) {
	return {
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidFavouritesGamesGameUuidDelete(
			player_uuid: string,
			game_uuid: string,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'player_uuid' is not null or undefined
			if (player_uuid === null || player_uuid === undefined) {
				throw new RequiredError(
					'player_uuid',
					'Required parameter player_uuid was null or undefined when calling v1PlayersPlayerUuidFavouritesGamesGameUuidDelete.'
				);
			}
			// verify required parameter 'game_uuid' is not null or undefined
			if (game_uuid === null || game_uuid === undefined) {
				throw new RequiredError(
					'game_uuid',
					'Required parameter game_uuid was null or undefined when calling v1PlayersPlayerUuidFavouritesGamesGameUuidDelete.'
				);
			}
			const localVarPath = `/v1/players/{player_uuid}/favourites/games/{game_uuid}`
				.replace(
					`{${'player_uuid'}}`,
					encodeURIComponent(String(player_uuid))
				)
				.replace(
					`{${'game_uuid'}}`,
					encodeURIComponent(String(game_uuid))
				);
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'DELETE' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidFavouritesGamesGameUuidPut(
			player_uuid: string,
			game_uuid: string,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'player_uuid' is not null or undefined
			if (player_uuid === null || player_uuid === undefined) {
				throw new RequiredError(
					'player_uuid',
					'Required parameter player_uuid was null or undefined when calling v1PlayersPlayerUuidFavouritesGamesGameUuidPut.'
				);
			}
			// verify required parameter 'game_uuid' is not null or undefined
			if (game_uuid === null || game_uuid === undefined) {
				throw new RequiredError(
					'game_uuid',
					'Required parameter game_uuid was null or undefined when calling v1PlayersPlayerUuidFavouritesGamesGameUuidPut.'
				);
			}
			const localVarPath = `/v1/players/{player_uuid}/favourites/games/{game_uuid}`
				.replace(
					`{${'player_uuid'}}`,
					encodeURIComponent(String(player_uuid))
				)
				.replace(
					`{${'game_uuid'}}`,
					encodeURIComponent(String(game_uuid))
				);
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'PUT' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		}
	};
};

/**
 * FavouritesApi - functional programming interface
 * @export
 */
export const FavouritesApiFp = function (configuration?: Configuration) {
	return {
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidFavouritesGamesGameUuidDelete(
			player_uuid: string,
			game_uuid: string,
			options?: any
		): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = FavouritesApiFetchParamCreator(
				configuration
			).v1PlayersPlayerUuidFavouritesGamesGameUuidDelete(
				player_uuid,
				game_uuid,
				options
			);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response;
					} else {
						throw response;
					}
				});
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidFavouritesGamesGameUuidPut(
			player_uuid: string,
			game_uuid: string,
			options?: any
		): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = FavouritesApiFetchParamCreator(
				configuration
			).v1PlayersPlayerUuidFavouritesGamesGameUuidPut(
				player_uuid,
				game_uuid,
				options
			);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response;
					} else {
						throw response;
					}
				});
			};
		}
	};
};

/**
 * FavouritesApi - factory interface
 * @export
 */
export const FavouritesApiFactory = function (
	configuration?: Configuration,
	fetch?: FetchAPI,
	basePath?: string
) {
	return {
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidFavouritesGamesGameUuidDelete(
			player_uuid: string,
			game_uuid: string,
			options?: any
		) {
			return FavouritesApiFp(
				configuration
			).v1PlayersPlayerUuidFavouritesGamesGameUuidDelete(
				player_uuid,
				game_uuid,
				options
			)(fetch, basePath);
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidFavouritesGamesGameUuidPut(
			player_uuid: string,
			game_uuid: string,
			options?: any
		) {
			return FavouritesApiFp(
				configuration
			).v1PlayersPlayerUuidFavouritesGamesGameUuidPut(
				player_uuid,
				game_uuid,
				options
			)(fetch, basePath);
		}
	};
};

/**
 * FavouritesApi - object-oriented interface
 * @export
 * @class FavouritesApi
 * @extends {BaseAPI}
 */
export class FavouritesApi extends BaseAPI {
	/**
	 *
	 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
	 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof FavouritesApi
	 */
	public v1PlayersPlayerUuidFavouritesGamesGameUuidDelete(
		player_uuid: string,
		game_uuid: string,
		options?: any
	) {
		return FavouritesApiFp(
			this.configuration
		).v1PlayersPlayerUuidFavouritesGamesGameUuidDelete(
			player_uuid,
			game_uuid,
			options
		)(this.fetch, this.basePath);
	}

	/**
	 *
	 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
	 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof FavouritesApi
	 */
	public v1PlayersPlayerUuidFavouritesGamesGameUuidPut(
		player_uuid: string,
		game_uuid: string,
		options?: any
	) {
		return FavouritesApiFp(
			this.configuration
		).v1PlayersPlayerUuidFavouritesGamesGameUuidPut(
			player_uuid,
			game_uuid,
			options
		)(this.fetch, this.basePath);
	}
}

/**
 * FreespinsoffersApi - fetch parameter creator
 * @export
 */
export const FreespinsoffersApiFetchParamCreator = function (
	configuration?: Configuration
) {
	return {
		/**
		 *
		 * @param {string} player_id Identifier of player (UUID)
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		apiV1FreespinsOffersGet(
			player_id: string,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'player_id' is not null or undefined
			if (player_id === null || player_id === undefined) {
				throw new RequiredError(
					'player_id',
					'Required parameter player_id was null or undefined when calling apiV1FreespinsOffersGet.'
				);
			}
			const localVarPath = `/api/v1/freespins_offers`;
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			if (player_id !== undefined) {
				localVarQueryParameter['player_id'] = player_id;
			}

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		}
	};
};

/**
 * FreespinsoffersApi - functional programming interface
 * @export
 */
export const FreespinsoffersApiFp = function (configuration?: Configuration) {
	return {
		/**
		 *
		 * @param {string} player_id Identifier of player (UUID)
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		apiV1FreespinsOffersGet(
			player_id: string,
			options?: any
		): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
			const localVarFetchArgs = FreespinsoffersApiFetchParamCreator(
				configuration
			).apiV1FreespinsOffersGet(player_id, options);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		}
	};
};

/**
 * FreespinsoffersApi - factory interface
 * @export
 */
export const FreespinsoffersApiFactory = function (
	configuration?: Configuration,
	fetch?: FetchAPI,
	basePath?: string
) {
	return {
		/**
		 *
		 * @param {string} player_id Identifier of player (UUID)
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		apiV1FreespinsOffersGet(player_id: string, options?: any) {
			return FreespinsoffersApiFp(configuration).apiV1FreespinsOffersGet(
				player_id,
				options
			)(fetch, basePath);
		}
	};
};

/**
 * FreespinsoffersApi - object-oriented interface
 * @export
 * @class FreespinsoffersApi
 * @extends {BaseAPI}
 */
export class FreespinsoffersApi extends BaseAPI {
	/**
	 *
	 * @param {string} player_id Identifier of player (UUID)
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof FreespinsoffersApi
	 */
	public apiV1FreespinsOffersGet(player_id: string, options?: any) {
		return FreespinsoffersApiFp(this.configuration).apiV1FreespinsOffersGet(
			player_id,
			options
		)(this.fetch, this.basePath);
	}
}

/**
 * GamesApi - fetch parameter creator
 * @export
 */
export const GamesApiFetchParamCreator = function (
	configuration?: Configuration
) {
	return {
		/**
		 *
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1GamesGameUuidGet(
			game_uuid: string,
			filter_country?: string,
			filter_currency?: string,
			filter_platform?: 'mobile' | 'desktop',
			lang?: string,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'game_uuid' is not null or undefined
			if (game_uuid === null || game_uuid === undefined) {
				throw new RequiredError(
					'game_uuid',
					'Required parameter game_uuid was null or undefined when calling v1GamesGameUuidGet.'
				);
			}
			const localVarPath = `/v1/games/{game_uuid}`.replace(
				`{${'game_uuid'}}`,
				encodeURIComponent(String(game_uuid))
			);
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			if (filter_country !== undefined) {
				localVarQueryParameter['filter_country'] = filter_country;
			}

			if (filter_currency !== undefined) {
				localVarQueryParameter['filter_currency'] = filter_currency;
			}

			if (filter_platform !== undefined) {
				localVarQueryParameter['filter_platform'] = filter_platform;
			}

			if (lang !== undefined) {
				localVarQueryParameter['lang'] = lang;
			}

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		},
		/**
		 *
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {'mobile' | 'desktop'} platform
		 * @param {string} [lang]
		 * @param {boolean} [freeplay]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1GamesGameUuidLaunchUrlGet(
			game_uuid: string,
			platform: 'mobile' | 'desktop',
			lang?: string,
			freeplay?: boolean,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'game_uuid' is not null or undefined
			if (game_uuid === null || game_uuid === undefined) {
				throw new RequiredError(
					'game_uuid',
					'Required parameter game_uuid was null or undefined when calling v1GamesGameUuidLaunchUrlGet.'
				);
			}
			// verify required parameter 'platform' is not null or undefined
			if (platform === null || platform === undefined) {
				throw new RequiredError(
					'platform',
					'Required parameter platform was null or undefined when calling v1GamesGameUuidLaunchUrlGet.'
				);
			}
			const localVarPath = `/v1/games/{game_uuid}/launch_url`.replace(
				`{${'game_uuid'}}`,
				encodeURIComponent(String(game_uuid))
			);
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			if (lang !== undefined) {
				localVarQueryParameter['lang'] = lang;
			}

			if (freeplay !== undefined) {
				localVarQueryParameter['freeplay'] = freeplay;
			}

			if (platform !== undefined) {
				localVarQueryParameter['platform'] = platform;
			}

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		},
		/**
		 *
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {GameRecord} [GameRecord]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1GamesGameUuidPut(
			game_uuid: string,
			GameRecord?: GameRecord,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'game_uuid' is not null or undefined
			if (game_uuid === null || game_uuid === undefined) {
				throw new RequiredError(
					'game_uuid',
					'Required parameter game_uuid was null or undefined when calling v1GamesGameUuidPut.'
				);
			}
			const localVarPath = `/v1/games/{game_uuid}`.replace(
				`{${'game_uuid'}}`,
				encodeURIComponent(String(game_uuid))
			);
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'PUT' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			localVarHeaderParameter['Content-Type'] = 'application/json';

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);
			const needsSerialization =
				<any>'GameRecord' !== 'string' ||
				localVarRequestOptions.headers['Content-Type'] ===
					'application/json';
			localVarRequestOptions.body = needsSerialization
				? JSON.stringify(GameRecord || {})
				: GameRecord || '';

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		},
		/**
		 *
		 * @param {boolean} [live]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1GamesGet(live?: boolean, options: any = {}): FetchArgs {
			const localVarPath = `/v1/games`;
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			if (live !== undefined) {
				localVarQueryParameter['live'] = live;
			}

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		},
		/**
		 *
		 * @param {GameRecord} [GameRecord]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1GamesPost(GameRecord?: GameRecord, options: any = {}): FetchArgs {
			const localVarPath = `/v1/games`;
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'POST' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			localVarHeaderParameter['Content-Type'] = 'application/json';

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);
			const needsSerialization =
				<any>'GameRecord' !== 'string' ||
				localVarRequestOptions.headers['Content-Type'] ===
					'application/json';
			localVarRequestOptions.body = needsSerialization
				? JSON.stringify(GameRecord || {})
				: GameRecord || '';

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidGamesGameUuidGet(
			player_uuid: string,
			game_uuid: string,
			filter_country?: string,
			filter_currency?: string,
			filter_platform?: 'mobile' | 'desktop',
			lang?: string,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'player_uuid' is not null or undefined
			if (player_uuid === null || player_uuid === undefined) {
				throw new RequiredError(
					'player_uuid',
					'Required parameter player_uuid was null or undefined when calling v1PlayersPlayerUuidGamesGameUuidGet.'
				);
			}
			// verify required parameter 'game_uuid' is not null or undefined
			if (game_uuid === null || game_uuid === undefined) {
				throw new RequiredError(
					'game_uuid',
					'Required parameter game_uuid was null or undefined when calling v1PlayersPlayerUuidGamesGameUuidGet.'
				);
			}
			const localVarPath = `/v1/players/{player_uuid}/games/{game_uuid}`
				.replace(
					`{${'player_uuid'}}`,
					encodeURIComponent(String(player_uuid))
				)
				.replace(
					`{${'game_uuid'}}`,
					encodeURIComponent(String(game_uuid))
				);
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			if (filter_country !== undefined) {
				localVarQueryParameter['filter_country'] = filter_country;
			}

			if (filter_currency !== undefined) {
				localVarQueryParameter['filter_currency'] = filter_currency;
			}

			if (filter_platform !== undefined) {
				localVarQueryParameter['filter_platform'] = filter_platform;
			}

			if (lang !== undefined) {
				localVarQueryParameter['lang'] = lang;
			}

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {'mobile' | 'desktop'} platform
		 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
		 * @param {string} [lang]
		 * @param {boolean} [freeplay]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidGamesGameUuidLaunchUrlGet(
			player_uuid: string,
			game_uuid: string,
			platform: 'mobile' | 'desktop',
			brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
			lang?: string,
			freeplay?: boolean,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'player_uuid' is not null or undefined
			if (player_uuid === null || player_uuid === undefined) {
				throw new RequiredError(
					'player_uuid',
					'Required parameter player_uuid was null or undefined when calling v1PlayersPlayerUuidGamesGameUuidLaunchUrlGet.'
				);
			}
			// verify required parameter 'game_uuid' is not null or undefined
			if (game_uuid === null || game_uuid === undefined) {
				throw new RequiredError(
					'game_uuid',
					'Required parameter game_uuid was null or undefined when calling v1PlayersPlayerUuidGamesGameUuidLaunchUrlGet.'
				);
			}
			// verify required parameter 'platform' is not null or undefined
			if (platform === null || platform === undefined) {
				throw new RequiredError(
					'platform',
					'Required parameter platform was null or undefined when calling v1PlayersPlayerUuidGamesGameUuidLaunchUrlGet.'
				);
			}
			const localVarPath = `/v1/players/{player_uuid}/games/{game_uuid}/launch_url`
				.replace(
					`{${'player_uuid'}}`,
					encodeURIComponent(String(player_uuid))
				)
				.replace(
					`{${'game_uuid'}}`,
					encodeURIComponent(String(game_uuid))
				);
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			if (brand !== undefined) {
				localVarQueryParameter['brand'] = brand;
			}

			if (lang !== undefined) {
				localVarQueryParameter['lang'] = lang;
			}

			if (freeplay !== undefined) {
				localVarQueryParameter['freeplay'] = freeplay;
			}

			if (platform !== undefined) {
				localVarQueryParameter['platform'] = platform;
			}

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {'all' | 'recommendation'} filter_category Filters the games by category. \&quot;all\&quot; returns all games (so no filtering), \&quot;recommendation\&quot; returns only the recommended games for the player
		 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {number} [filter_filter_id]
		 * @param {boolean} [filter_live]
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [filter_section_id]
		 * @param {string} [filter_studio]
		 * @param {Array<string>} [filter_tags] Only return games which have all of these tags. If it&#39;s not present or empty array then it returns all games. For example if specify \&quot;filter_tags&#x3D;new,featured\&quot; it will return only games which are tagged with both \&quot;new\&quot; and \&quot;featured\&quot;
		 * @param {Array<string>} [filter_themes] Only return games which have at least one of these themes, so adding more elements to filter_themes will return more and more games. If it&#39;s not present or empty array then it returns all games.
		 * @param {'tile' | 'portrait' | 'character'} [filter_tile_type] Filter game by image tile type.
		 * @param {Array<string>} [filter_type] Only return games with the specified type, e.g. \&quot;slots\&quot;. When this filter is not specified or empty then it will return all games. Multiple types can be specified as well, for example \&quot;filter_type&#x3D;slots,table_games\&quot; will return the union of all \&quot;slots\&quot; and \&quot;table_games\&quot; in one list
		 * @param {'popular' | 'new_rng' | 'best_live_games' | 'best_table_games'} [filter_list] Only return games specified in the given list. The lists are stored in the backend.
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {Array<'name' | '-name' | 'recommendation' | '-recommendation' | 'popularity' | '-popularity' | 'biggest_possible_win' | '-biggest_possible_win' | 'id' | '-id' | 'featured_order' | '-featured_order'>} [sort] Sorting options. It supports sorting by multiple attributes, like \&quot;recommendation,popularity\&quot;. The sort order is ascending by default, to sort in descending order the attribute name must start with hyphen e.g. \&quot;-name\&quot; will sort z-a
		 * @param {number} [page]
		 * @param {number} [page_size]
		 * @param {boolean} [lobby]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidGamesGet(
			player_uuid: string,
			filter_category: 'all' | 'recommendation',
			brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
			filter_country?: string,
			filter_currency?: string,
			filter_filter_id?: number,
			filter_live?: boolean,
			filter_platform?: 'mobile' | 'desktop',
			filter_section_id?: string,
			filter_studio?: string,
			filter_tags?: Array<string>,
			filter_themes?: Array<string>,
			filter_tile_type?: 'tile' | 'portrait' | 'character',
			filter_type?: Array<string>,
			filter_list?:
				| 'popular'
				| 'new_rng'
				| 'best_live_games'
				| 'best_table_games',
			lang?: string,
			sort?: Array<
				| 'name'
				| '-name'
				| 'recommendation'
				| '-recommendation'
				| 'popularity'
				| '-popularity'
				| 'biggest_possible_win'
				| '-biggest_possible_win'
				| 'id'
				| '-id'
				| 'featured_order'
				| '-featured_order'
			>,
			page?: number,
			page_size?: number,
			lobby?: boolean,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'player_uuid' is not null or undefined
			if (player_uuid === null || player_uuid === undefined) {
				throw new RequiredError(
					'player_uuid',
					'Required parameter player_uuid was null or undefined when calling v1PlayersPlayerUuidGamesGet.'
				);
			}
			// verify required parameter 'filter_category' is not null or undefined
			if (filter_category === null || filter_category === undefined) {
				throw new RequiredError(
					'filter_category',
					'Required parameter filter_category was null or undefined when calling v1PlayersPlayerUuidGamesGet.'
				);
			}
			const localVarPath = `/v1/players/{player_uuid}/games`.replace(
				`{${'player_uuid'}}`,
				encodeURIComponent(String(player_uuid))
			);
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			if (brand !== undefined) {
				localVarQueryParameter['brand'] = brand;
			}

			if (filter_category !== undefined) {
				localVarQueryParameter['filter_category'] = filter_category;
			}

			if (filter_country !== undefined) {
				localVarQueryParameter['filter_country'] = filter_country;
			}

			if (filter_currency !== undefined) {
				localVarQueryParameter['filter_currency'] = filter_currency;
			}

			if (filter_filter_id !== undefined) {
				localVarQueryParameter['filter_filter_id'] = filter_filter_id;
			}

			if (filter_live !== undefined) {
				localVarQueryParameter['filter_live'] = filter_live;
			}

			if (filter_platform !== undefined) {
				localVarQueryParameter['filter_platform'] = filter_platform;
			}

			if (filter_section_id !== undefined) {
				localVarQueryParameter['filter_section_id'] = filter_section_id;
			}

			if (filter_studio !== undefined) {
				localVarQueryParameter['filter_studio'] = filter_studio;
			}

			if (filter_tags) {
				localVarQueryParameter['filter_tags'] = filter_tags.join(
					COLLECTION_FORMATS['csv']
				);
			}

			if (filter_themes) {
				localVarQueryParameter['filter_themes'] = filter_themes.join(
					COLLECTION_FORMATS['csv']
				);
			}

			if (filter_tile_type !== undefined) {
				localVarQueryParameter['filter_tile_type'] = filter_tile_type;
			}

			if (filter_type) {
				localVarQueryParameter['filter_type'] = filter_type.join(
					COLLECTION_FORMATS['csv']
				);
			}

			if (filter_list !== undefined) {
				localVarQueryParameter['filter_list'] = filter_list;
			}

			if (lang !== undefined) {
				localVarQueryParameter['lang'] = lang;
			}

			if (sort) {
				localVarQueryParameter['sort'] = sort.join(
					COLLECTION_FORMATS['csv']
				);
			}

			if (page !== undefined) {
				localVarQueryParameter['page'] = page;
			}

			if (page_size !== undefined) {
				localVarQueryParameter['page_size'] = page_size;
			}

			if (lobby !== undefined) {
				localVarQueryParameter['lobby'] = lobby;
			}

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {number} [filter_filter_id]
		 * @param {boolean} [filter_live]
		 * @param {string} [filter_section_id]
		 * @param {string} [filter_studio]
		 * @param {Array<string>} [filter_type] Only return games with the specified type, e.g. \&quot;slots\&quot;. When this filter is not specified or empty then it will return all games. Multiple types can be specified as well, for example \&quot;filter_type&#x3D;slots,table_games\&quot; will return the union of all \&quot;slots\&quot; and \&quot;table_games\&quot; in one list
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidMostRelevantGameGet(
			player_uuid: string,
			filter_platform?: 'mobile' | 'desktop',
			filter_country?: string,
			filter_currency?: string,
			filter_filter_id?: number,
			filter_live?: boolean,
			filter_section_id?: string,
			filter_studio?: string,
			filter_type?: Array<string>,
			lang?: string,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'player_uuid' is not null or undefined
			if (player_uuid === null || player_uuid === undefined) {
				throw new RequiredError(
					'player_uuid',
					'Required parameter player_uuid was null or undefined when calling v1PlayersPlayerUuidMostRelevantGameGet.'
				);
			}
			const localVarPath = `/v1/players/{player_uuid}/most_relevant_game`.replace(
				`{${'player_uuid'}}`,
				encodeURIComponent(String(player_uuid))
			);
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			if (filter_platform !== undefined) {
				localVarQueryParameter['filter_platform'] = filter_platform;
			}

			if (filter_country !== undefined) {
				localVarQueryParameter['filter_country'] = filter_country;
			}

			if (filter_currency !== undefined) {
				localVarQueryParameter['filter_currency'] = filter_currency;
			}

			if (filter_filter_id !== undefined) {
				localVarQueryParameter['filter_filter_id'] = filter_filter_id;
			}

			if (filter_live !== undefined) {
				localVarQueryParameter['filter_live'] = filter_live;
			}

			if (filter_section_id !== undefined) {
				localVarQueryParameter['filter_section_id'] = filter_section_id;
			}

			if (filter_studio !== undefined) {
				localVarQueryParameter['filter_studio'] = filter_studio;
			}

			if (filter_type) {
				localVarQueryParameter['filter_type'] = filter_type.join(
					COLLECTION_FORMATS['csv']
				);
			}

			if (lang !== undefined) {
				localVarQueryParameter['lang'] = lang;
			}

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {number} [page_size]
		 * @param {number} [page]
		 * @param {'favourites' | 'recommended'} [tab]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidMyCasinoGamesGet(
			player_uuid: string,
			filter_country?: string,
			filter_currency?: string,
			filter_platform?: 'mobile' | 'desktop',
			lang?: string,
			page_size?: number,
			page?: number,
			tab?: 'favourites' | 'recommended',
			options: any = {}
		): FetchArgs {
			// verify required parameter 'player_uuid' is not null or undefined
			if (player_uuid === null || player_uuid === undefined) {
				throw new RequiredError(
					'player_uuid',
					'Required parameter player_uuid was null or undefined when calling v1PlayersPlayerUuidMyCasinoGamesGet.'
				);
			}
			const localVarPath = `/v1/players/{player_uuid}/my_casino_games`.replace(
				`{${'player_uuid'}}`,
				encodeURIComponent(String(player_uuid))
			);
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			if (filter_country !== undefined) {
				localVarQueryParameter['filter_country'] = filter_country;
			}

			if (filter_currency !== undefined) {
				localVarQueryParameter['filter_currency'] = filter_currency;
			}

			if (filter_platform !== undefined) {
				localVarQueryParameter['filter_platform'] = filter_platform;
			}

			if (lang !== undefined) {
				localVarQueryParameter['lang'] = lang;
			}

			if (page_size !== undefined) {
				localVarQueryParameter['page_size'] = page_size;
			}

			if (page !== undefined) {
				localVarQueryParameter['page'] = page;
			}

			if (tab !== undefined) {
				localVarQueryParameter['tab'] = tab;
			}

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {'all' | 'recommendation'} filter_category Filters the games by category. \&quot;all\&quot; returns all games (so no filtering), \&quot;recommendation\&quot; returns only the recommended games for the player
		 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {number} [filter_filter_id]
		 * @param {boolean} [filter_live]
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [filter_section_id]
		 * @param {string} [filter_studio]
		 * @param {Array<string>} [filter_tags] Only return games which have all of these tags. If it&#39;s not present or empty array then it returns all games. For example if specify \&quot;filter_tags&#x3D;new,featured\&quot; it will return only games which are tagged with both \&quot;new\&quot; and \&quot;featured\&quot;
		 * @param {Array<string>} [filter_themes] Only return games which have at least one of these themes, so adding more elements to filter_themes will return more and more games. If it&#39;s not present or empty array then it returns all games.
		 * @param {'tile' | 'portrait' | 'character'} [filter_tile_type] Filter game by image tile type.
		 * @param {Array<string>} [filter_type] Only return games with the specified type, e.g. \&quot;slots\&quot;. When this filter is not specified or empty then it will return all games. Multiple types can be specified as well, for example \&quot;filter_type&#x3D;slots,table_games\&quot; will return the union of all \&quot;slots\&quot; and \&quot;table_games\&quot; in one list
		 * @param {'popular' | 'new_rng' | 'best_live_games' | 'best_table_games'} [filter_list] Only return games specified in the given list. The lists are stored in the backend.
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {Array<'game_count' | '-game_count' | 'name' | '-name'>} [sort] Sorting options. It supports sorting by multiple attributes, like \&quot;game_count,name\&quot;. The sort order is ascending by default, to sort for descending order the attribute name must start with hyphen e.g. \&quot;-name\&quot; will sort z-a. The default sort is &#x60;-game_count&#x60;
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidStudiosGet(
			player_uuid: string,
			filter_category: 'all' | 'recommendation',
			brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
			filter_country?: string,
			filter_currency?: string,
			filter_filter_id?: number,
			filter_live?: boolean,
			filter_platform?: 'mobile' | 'desktop',
			filter_section_id?: string,
			filter_studio?: string,
			filter_tags?: Array<string>,
			filter_themes?: Array<string>,
			filter_tile_type?: 'tile' | 'portrait' | 'character',
			filter_type?: Array<string>,
			filter_list?:
				| 'popular'
				| 'new_rng'
				| 'best_live_games'
				| 'best_table_games',
			lang?: string,
			sort?: Array<'game_count' | '-game_count' | 'name' | '-name'>,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'player_uuid' is not null or undefined
			if (player_uuid === null || player_uuid === undefined) {
				throw new RequiredError(
					'player_uuid',
					'Required parameter player_uuid was null or undefined when calling v1PlayersPlayerUuidStudiosGet.'
				);
			}
			// verify required parameter 'filter_category' is not null or undefined
			if (filter_category === null || filter_category === undefined) {
				throw new RequiredError(
					'filter_category',
					'Required parameter filter_category was null or undefined when calling v1PlayersPlayerUuidStudiosGet.'
				);
			}
			const localVarPath = `/v1/players/{player_uuid}/studios`.replace(
				`{${'player_uuid'}}`,
				encodeURIComponent(String(player_uuid))
			);
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			if (brand !== undefined) {
				localVarQueryParameter['brand'] = brand;
			}

			if (filter_category !== undefined) {
				localVarQueryParameter['filter_category'] = filter_category;
			}

			if (filter_country !== undefined) {
				localVarQueryParameter['filter_country'] = filter_country;
			}

			if (filter_currency !== undefined) {
				localVarQueryParameter['filter_currency'] = filter_currency;
			}

			if (filter_filter_id !== undefined) {
				localVarQueryParameter['filter_filter_id'] = filter_filter_id;
			}

			if (filter_live !== undefined) {
				localVarQueryParameter['filter_live'] = filter_live;
			}

			if (filter_platform !== undefined) {
				localVarQueryParameter['filter_platform'] = filter_platform;
			}

			if (filter_section_id !== undefined) {
				localVarQueryParameter['filter_section_id'] = filter_section_id;
			}

			if (filter_studio !== undefined) {
				localVarQueryParameter['filter_studio'] = filter_studio;
			}

			if (filter_tags) {
				localVarQueryParameter['filter_tags'] = filter_tags.join(
					COLLECTION_FORMATS['csv']
				);
			}

			if (filter_themes) {
				localVarQueryParameter['filter_themes'] = filter_themes.join(
					COLLECTION_FORMATS['csv']
				);
			}

			if (filter_tile_type !== undefined) {
				localVarQueryParameter['filter_tile_type'] = filter_tile_type;
			}

			if (filter_type) {
				localVarQueryParameter['filter_type'] = filter_type.join(
					COLLECTION_FORMATS['csv']
				);
			}

			if (filter_list !== undefined) {
				localVarQueryParameter['filter_list'] = filter_list;
			}

			if (lang !== undefined) {
				localVarQueryParameter['lang'] = lang;
			}

			if (sort) {
				localVarQueryParameter['sort'] = sort.join(
					COLLECTION_FORMATS['csv']
				);
			}

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		}
	};
};

/**
 * GamesApi - functional programming interface
 * @export
 */
export const GamesApiFp = function (configuration?: Configuration) {
	return {
		/**
		 *
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1GamesGameUuidGet(
			game_uuid: string,
			filter_country?: string,
			filter_currency?: string,
			filter_platform?: 'mobile' | 'desktop',
			lang?: string,
			options?: any
		): (fetch?: FetchAPI, basePath?: string) => Promise<Game> {
			const localVarFetchArgs = GamesApiFetchParamCreator(
				configuration
			).v1GamesGameUuidGet(
				game_uuid,
				filter_country,
				filter_currency,
				filter_platform,
				lang,
				options
			);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		},
		/**
		 *
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {'mobile' | 'desktop'} platform
		 * @param {string} [lang]
		 * @param {boolean} [freeplay]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1GamesGameUuidLaunchUrlGet(
			game_uuid: string,
			platform: 'mobile' | 'desktop',
			lang?: string,
			freeplay?: boolean,
			options?: any
		): (
			fetch?: FetchAPI,
			basePath?: string
		) => Promise<InlineResponse2002> {
			const localVarFetchArgs = GamesApiFetchParamCreator(
				configuration
			).v1GamesGameUuidLaunchUrlGet(
				game_uuid,
				platform,
				lang,
				freeplay,
				options
			);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		},
		/**
		 *
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {GameRecord} [GameRecord]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1GamesGameUuidPut(
			game_uuid: string,
			GameRecord?: GameRecord,
			options?: any
		): (fetch?: FetchAPI, basePath?: string) => Promise<GameRecord> {
			const localVarFetchArgs = GamesApiFetchParamCreator(
				configuration
			).v1GamesGameUuidPut(game_uuid, GameRecord, options);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		},
		/**
		 *
		 * @param {boolean} [live]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1GamesGet(
			live?: boolean,
			options?: any
		): (
			fetch?: FetchAPI,
			basePath?: string
		) => Promise<InlineResponse2003> {
			const localVarFetchArgs = GamesApiFetchParamCreator(
				configuration
			).v1GamesGet(live, options);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		},
		/**
		 *
		 * @param {GameRecord} [GameRecord]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1GamesPost(
			GameRecord?: GameRecord,
			options?: any
		): (fetch?: FetchAPI, basePath?: string) => Promise<GameRecord> {
			const localVarFetchArgs = GamesApiFetchParamCreator(
				configuration
			).v1GamesPost(GameRecord, options);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidGamesGameUuidGet(
			player_uuid: string,
			game_uuid: string,
			filter_country?: string,
			filter_currency?: string,
			filter_platform?: 'mobile' | 'desktop',
			lang?: string,
			options?: any
		): (fetch?: FetchAPI, basePath?: string) => Promise<Game> {
			const localVarFetchArgs = GamesApiFetchParamCreator(
				configuration
			).v1PlayersPlayerUuidGamesGameUuidGet(
				player_uuid,
				game_uuid,
				filter_country,
				filter_currency,
				filter_platform,
				lang,
				options
			);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {'mobile' | 'desktop'} platform
		 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
		 * @param {string} [lang]
		 * @param {boolean} [freeplay]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidGamesGameUuidLaunchUrlGet(
			player_uuid: string,
			game_uuid: string,
			platform: 'mobile' | 'desktop',
			brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
			lang?: string,
			freeplay?: boolean,
			options?: any
		): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = GamesApiFetchParamCreator(
				configuration
			).v1PlayersPlayerUuidGamesGameUuidLaunchUrlGet(
				player_uuid,
				game_uuid,
				platform,
				brand,
				lang,
				freeplay,
				options
			);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response;
					} else {
						throw response;
					}
				});
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {'all' | 'recommendation'} filter_category Filters the games by category. \&quot;all\&quot; returns all games (so no filtering), \&quot;recommendation\&quot; returns only the recommended games for the player
		 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {number} [filter_filter_id]
		 * @param {boolean} [filter_live]
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [filter_section_id]
		 * @param {string} [filter_studio]
		 * @param {Array<string>} [filter_tags] Only return games which have all of these tags. If it&#39;s not present or empty array then it returns all games. For example if specify \&quot;filter_tags&#x3D;new,featured\&quot; it will return only games which are tagged with both \&quot;new\&quot; and \&quot;featured\&quot;
		 * @param {Array<string>} [filter_themes] Only return games which have at least one of these themes, so adding more elements to filter_themes will return more and more games. If it&#39;s not present or empty array then it returns all games.
		 * @param {'tile' | 'portrait' | 'character'} [filter_tile_type] Filter game by image tile type.
		 * @param {Array<string>} [filter_type] Only return games with the specified type, e.g. \&quot;slots\&quot;. When this filter is not specified or empty then it will return all games. Multiple types can be specified as well, for example \&quot;filter_type&#x3D;slots,table_games\&quot; will return the union of all \&quot;slots\&quot; and \&quot;table_games\&quot; in one list
		 * @param {'popular' | 'new_rng' | 'best_live_games' | 'best_table_games'} [filter_list] Only return games specified in the given list. The lists are stored in the backend.
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {Array<'name' | '-name' | 'recommendation' | '-recommendation' | 'popularity' | '-popularity' | 'biggest_possible_win' | '-biggest_possible_win' | 'id' | '-id' | 'featured_order' | '-featured_order'>} [sort] Sorting options. It supports sorting by multiple attributes, like \&quot;recommendation,popularity\&quot;. The sort order is ascending by default, to sort in descending order the attribute name must start with hyphen e.g. \&quot;-name\&quot; will sort z-a
		 * @param {number} [page]
		 * @param {number} [page_size]
		 * @param {boolean} [lobby]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidGamesGet(
			player_uuid: string,
			filter_category: 'all' | 'recommendation',
			brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
			filter_country?: string,
			filter_currency?: string,
			filter_filter_id?: number,
			filter_live?: boolean,
			filter_platform?: 'mobile' | 'desktop',
			filter_section_id?: string,
			filter_studio?: string,
			filter_tags?: Array<string>,
			filter_themes?: Array<string>,
			filter_tile_type?: 'tile' | 'portrait' | 'character',
			filter_type?: Array<string>,
			filter_list?:
				| 'popular'
				| 'new_rng'
				| 'best_live_games'
				| 'best_table_games',
			lang?: string,
			sort?: Array<
				| 'name'
				| '-name'
				| 'recommendation'
				| '-recommendation'
				| 'popularity'
				| '-popularity'
				| 'biggest_possible_win'
				| '-biggest_possible_win'
				| 'id'
				| '-id'
				| 'featured_order'
				| '-featured_order'
			>,
			page?: number,
			page_size?: number,
			lobby?: boolean,
			options?: any
		): (
			fetch?: FetchAPI,
			basePath?: string
		) => Promise<InlineResponse2004> {
			const localVarFetchArgs = GamesApiFetchParamCreator(
				configuration
			).v1PlayersPlayerUuidGamesGet(
				player_uuid,
				filter_category,
				brand,
				filter_country,
				filter_currency,
				filter_filter_id,
				filter_live,
				filter_platform,
				filter_section_id,
				filter_studio,
				filter_tags,
				filter_themes,
				filter_tile_type,
				filter_type,
				filter_list,
				lang,
				sort,
				page,
				page_size,
				lobby,
				options
			);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {number} [filter_filter_id]
		 * @param {boolean} [filter_live]
		 * @param {string} [filter_section_id]
		 * @param {string} [filter_studio]
		 * @param {Array<string>} [filter_type] Only return games with the specified type, e.g. \&quot;slots\&quot;. When this filter is not specified or empty then it will return all games. Multiple types can be specified as well, for example \&quot;filter_type&#x3D;slots,table_games\&quot; will return the union of all \&quot;slots\&quot; and \&quot;table_games\&quot; in one list
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidMostRelevantGameGet(
			player_uuid: string,
			filter_platform?: 'mobile' | 'desktop',
			filter_country?: string,
			filter_currency?: string,
			filter_filter_id?: number,
			filter_live?: boolean,
			filter_section_id?: string,
			filter_studio?: string,
			filter_type?: Array<string>,
			lang?: string,
			options?: any
		): (fetch?: FetchAPI, basePath?: string) => Promise<Game> {
			const localVarFetchArgs = GamesApiFetchParamCreator(
				configuration
			).v1PlayersPlayerUuidMostRelevantGameGet(
				player_uuid,
				filter_platform,
				filter_country,
				filter_currency,
				filter_filter_id,
				filter_live,
				filter_section_id,
				filter_studio,
				filter_type,
				lang,
				options
			);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {number} [page_size]
		 * @param {number} [page]
		 * @param {'favourites' | 'recommended'} [tab]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidMyCasinoGamesGet(
			player_uuid: string,
			filter_country?: string,
			filter_currency?: string,
			filter_platform?: 'mobile' | 'desktop',
			lang?: string,
			page_size?: number,
			page?: number,
			tab?: 'favourites' | 'recommended',
			options?: any
		): (
			fetch?: FetchAPI,
			basePath?: string
		) => Promise<InlineResponse2006> {
			const localVarFetchArgs = GamesApiFetchParamCreator(
				configuration
			).v1PlayersPlayerUuidMyCasinoGamesGet(
				player_uuid,
				filter_country,
				filter_currency,
				filter_platform,
				lang,
				page_size,
				page,
				tab,
				options
			);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {'all' | 'recommendation'} filter_category Filters the games by category. \&quot;all\&quot; returns all games (so no filtering), \&quot;recommendation\&quot; returns only the recommended games for the player
		 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {number} [filter_filter_id]
		 * @param {boolean} [filter_live]
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [filter_section_id]
		 * @param {string} [filter_studio]
		 * @param {Array<string>} [filter_tags] Only return games which have all of these tags. If it&#39;s not present or empty array then it returns all games. For example if specify \&quot;filter_tags&#x3D;new,featured\&quot; it will return only games which are tagged with both \&quot;new\&quot; and \&quot;featured\&quot;
		 * @param {Array<string>} [filter_themes] Only return games which have at least one of these themes, so adding more elements to filter_themes will return more and more games. If it&#39;s not present or empty array then it returns all games.
		 * @param {'tile' | 'portrait' | 'character'} [filter_tile_type] Filter game by image tile type.
		 * @param {Array<string>} [filter_type] Only return games with the specified type, e.g. \&quot;slots\&quot;. When this filter is not specified or empty then it will return all games. Multiple types can be specified as well, for example \&quot;filter_type&#x3D;slots,table_games\&quot; will return the union of all \&quot;slots\&quot; and \&quot;table_games\&quot; in one list
		 * @param {'popular' | 'new_rng' | 'best_live_games' | 'best_table_games'} [filter_list] Only return games specified in the given list. The lists are stored in the backend.
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {Array<'game_count' | '-game_count' | 'name' | '-name'>} [sort] Sorting options. It supports sorting by multiple attributes, like \&quot;game_count,name\&quot;. The sort order is ascending by default, to sort for descending order the attribute name must start with hyphen e.g. \&quot;-name\&quot; will sort z-a. The default sort is &#x60;-game_count&#x60;
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidStudiosGet(
			player_uuid: string,
			filter_category: 'all' | 'recommendation',
			brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
			filter_country?: string,
			filter_currency?: string,
			filter_filter_id?: number,
			filter_live?: boolean,
			filter_platform?: 'mobile' | 'desktop',
			filter_section_id?: string,
			filter_studio?: string,
			filter_tags?: Array<string>,
			filter_themes?: Array<string>,
			filter_tile_type?: 'tile' | 'portrait' | 'character',
			filter_type?: Array<string>,
			filter_list?:
				| 'popular'
				| 'new_rng'
				| 'best_live_games'
				| 'best_table_games',
			lang?: string,
			sort?: Array<'game_count' | '-game_count' | 'name' | '-name'>,
			options?: any
		): (
			fetch?: FetchAPI,
			basePath?: string
		) => Promise<InlineResponse2005> {
			const localVarFetchArgs = GamesApiFetchParamCreator(
				configuration
			).v1PlayersPlayerUuidStudiosGet(
				player_uuid,
				filter_category,
				brand,
				filter_country,
				filter_currency,
				filter_filter_id,
				filter_live,
				filter_platform,
				filter_section_id,
				filter_studio,
				filter_tags,
				filter_themes,
				filter_tile_type,
				filter_type,
				filter_list,
				lang,
				sort,
				options
			);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		}
	};
};

/**
 * GamesApi - factory interface
 * @export
 */
export const GamesApiFactory = function (
	configuration?: Configuration,
	fetch?: FetchAPI,
	basePath?: string
) {
	return {
		/**
		 *
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1GamesGameUuidGet(
			game_uuid: string,
			filter_country?: string,
			filter_currency?: string,
			filter_platform?: 'mobile' | 'desktop',
			lang?: string,
			options?: any
		) {
			return GamesApiFp(configuration).v1GamesGameUuidGet(
				game_uuid,
				filter_country,
				filter_currency,
				filter_platform,
				lang,
				options
			)(fetch, basePath);
		},
		/**
		 *
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {'mobile' | 'desktop'} platform
		 * @param {string} [lang]
		 * @param {boolean} [freeplay]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1GamesGameUuidLaunchUrlGet(
			game_uuid: string,
			platform: 'mobile' | 'desktop',
			lang?: string,
			freeplay?: boolean,
			options?: any
		) {
			return GamesApiFp(configuration).v1GamesGameUuidLaunchUrlGet(
				game_uuid,
				platform,
				lang,
				freeplay,
				options
			)(fetch, basePath);
		},
		/**
		 *
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {GameRecord} [GameRecord]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1GamesGameUuidPut(
			game_uuid: string,
			GameRecord?: GameRecord,
			options?: any
		) {
			return GamesApiFp(configuration).v1GamesGameUuidPut(
				game_uuid,
				GameRecord,
				options
			)(fetch, basePath);
		},
		/**
		 *
		 * @param {boolean} [live]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1GamesGet(live?: boolean, options?: any) {
			return GamesApiFp(configuration).v1GamesGet(live, options)(
				fetch,
				basePath
			);
		},
		/**
		 *
		 * @param {GameRecord} [GameRecord]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1GamesPost(GameRecord?: GameRecord, options?: any) {
			return GamesApiFp(configuration).v1GamesPost(GameRecord, options)(
				fetch,
				basePath
			);
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidGamesGameUuidGet(
			player_uuid: string,
			game_uuid: string,
			filter_country?: string,
			filter_currency?: string,
			filter_platform?: 'mobile' | 'desktop',
			lang?: string,
			options?: any
		) {
			return GamesApiFp(
				configuration
			).v1PlayersPlayerUuidGamesGameUuidGet(
				player_uuid,
				game_uuid,
				filter_country,
				filter_currency,
				filter_platform,
				lang,
				options
			)(fetch, basePath);
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {'mobile' | 'desktop'} platform
		 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
		 * @param {string} [lang]
		 * @param {boolean} [freeplay]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidGamesGameUuidLaunchUrlGet(
			player_uuid: string,
			game_uuid: string,
			platform: 'mobile' | 'desktop',
			brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
			lang?: string,
			freeplay?: boolean,
			options?: any
		) {
			return GamesApiFp(
				configuration
			).v1PlayersPlayerUuidGamesGameUuidLaunchUrlGet(
				player_uuid,
				game_uuid,
				platform,
				brand,
				lang,
				freeplay,
				options
			)(fetch, basePath);
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {'all' | 'recommendation'} filter_category Filters the games by category. \&quot;all\&quot; returns all games (so no filtering), \&quot;recommendation\&quot; returns only the recommended games for the player
		 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {number} [filter_filter_id]
		 * @param {boolean} [filter_live]
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [filter_section_id]
		 * @param {string} [filter_studio]
		 * @param {Array<string>} [filter_tags] Only return games which have all of these tags. If it&#39;s not present or empty array then it returns all games. For example if specify \&quot;filter_tags&#x3D;new,featured\&quot; it will return only games which are tagged with both \&quot;new\&quot; and \&quot;featured\&quot;
		 * @param {Array<string>} [filter_themes] Only return games which have at least one of these themes, so adding more elements to filter_themes will return more and more games. If it&#39;s not present or empty array then it returns all games.
		 * @param {'tile' | 'portrait' | 'character'} [filter_tile_type] Filter game by image tile type.
		 * @param {Array<string>} [filter_type] Only return games with the specified type, e.g. \&quot;slots\&quot;. When this filter is not specified or empty then it will return all games. Multiple types can be specified as well, for example \&quot;filter_type&#x3D;slots,table_games\&quot; will return the union of all \&quot;slots\&quot; and \&quot;table_games\&quot; in one list
		 * @param {'popular' | 'new_rng' | 'best_live_games' | 'best_table_games'} [filter_list] Only return games specified in the given list. The lists are stored in the backend.
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {Array<'name' | '-name' | 'recommendation' | '-recommendation' | 'popularity' | '-popularity' | 'biggest_possible_win' | '-biggest_possible_win' | 'id' | '-id' | 'featured_order' | '-featured_order'>} [sort] Sorting options. It supports sorting by multiple attributes, like \&quot;recommendation,popularity\&quot;. The sort order is ascending by default, to sort in descending order the attribute name must start with hyphen e.g. \&quot;-name\&quot; will sort z-a
		 * @param {number} [page]
		 * @param {number} [page_size]
		 * @param {boolean} [lobby]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidGamesGet(
			player_uuid: string,
			filter_category: 'all' | 'recommendation',
			brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
			filter_country?: string,
			filter_currency?: string,
			filter_filter_id?: number,
			filter_live?: boolean,
			filter_platform?: 'mobile' | 'desktop',
			filter_section_id?: string,
			filter_studio?: string,
			filter_tags?: Array<string>,
			filter_themes?: Array<string>,
			filter_tile_type?: 'tile' | 'portrait' | 'character',
			filter_type?: Array<string>,
			filter_list?:
				| 'popular'
				| 'new_rng'
				| 'best_live_games'
				| 'best_table_games',
			lang?: string,
			sort?: Array<
				| 'name'
				| '-name'
				| 'recommendation'
				| '-recommendation'
				| 'popularity'
				| '-popularity'
				| 'biggest_possible_win'
				| '-biggest_possible_win'
				| 'id'
				| '-id'
				| 'featured_order'
				| '-featured_order'
			>,
			page?: number,
			page_size?: number,
			lobby?: boolean,
			options?: any
		) {
			return GamesApiFp(configuration).v1PlayersPlayerUuidGamesGet(
				player_uuid,
				filter_category,
				brand,
				filter_country,
				filter_currency,
				filter_filter_id,
				filter_live,
				filter_platform,
				filter_section_id,
				filter_studio,
				filter_tags,
				filter_themes,
				filter_tile_type,
				filter_type,
				filter_list,
				lang,
				sort,
				page,
				page_size,
				lobby,
				options
			)(fetch, basePath);
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {number} [filter_filter_id]
		 * @param {boolean} [filter_live]
		 * @param {string} [filter_section_id]
		 * @param {string} [filter_studio]
		 * @param {Array<string>} [filter_type] Only return games with the specified type, e.g. \&quot;slots\&quot;. When this filter is not specified or empty then it will return all games. Multiple types can be specified as well, for example \&quot;filter_type&#x3D;slots,table_games\&quot; will return the union of all \&quot;slots\&quot; and \&quot;table_games\&quot; in one list
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidMostRelevantGameGet(
			player_uuid: string,
			filter_platform?: 'mobile' | 'desktop',
			filter_country?: string,
			filter_currency?: string,
			filter_filter_id?: number,
			filter_live?: boolean,
			filter_section_id?: string,
			filter_studio?: string,
			filter_type?: Array<string>,
			lang?: string,
			options?: any
		) {
			return GamesApiFp(
				configuration
			).v1PlayersPlayerUuidMostRelevantGameGet(
				player_uuid,
				filter_platform,
				filter_country,
				filter_currency,
				filter_filter_id,
				filter_live,
				filter_section_id,
				filter_studio,
				filter_type,
				lang,
				options
			)(fetch, basePath);
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {number} [page_size]
		 * @param {number} [page]
		 * @param {'favourites' | 'recommended'} [tab]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidMyCasinoGamesGet(
			player_uuid: string,
			filter_country?: string,
			filter_currency?: string,
			filter_platform?: 'mobile' | 'desktop',
			lang?: string,
			page_size?: number,
			page?: number,
			tab?: 'favourites' | 'recommended',
			options?: any
		) {
			return GamesApiFp(
				configuration
			).v1PlayersPlayerUuidMyCasinoGamesGet(
				player_uuid,
				filter_country,
				filter_currency,
				filter_platform,
				lang,
				page_size,
				page,
				tab,
				options
			)(fetch, basePath);
		},
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {'all' | 'recommendation'} filter_category Filters the games by category. \&quot;all\&quot; returns all games (so no filtering), \&quot;recommendation\&quot; returns only the recommended games for the player
		 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
		 * @param {string} [filter_country]
		 * @param {string} [filter_currency]
		 * @param {number} [filter_filter_id]
		 * @param {boolean} [filter_live]
		 * @param {'mobile' | 'desktop'} [filter_platform]
		 * @param {string} [filter_section_id]
		 * @param {string} [filter_studio]
		 * @param {Array<string>} [filter_tags] Only return games which have all of these tags. If it&#39;s not present or empty array then it returns all games. For example if specify \&quot;filter_tags&#x3D;new,featured\&quot; it will return only games which are tagged with both \&quot;new\&quot; and \&quot;featured\&quot;
		 * @param {Array<string>} [filter_themes] Only return games which have at least one of these themes, so adding more elements to filter_themes will return more and more games. If it&#39;s not present or empty array then it returns all games.
		 * @param {'tile' | 'portrait' | 'character'} [filter_tile_type] Filter game by image tile type.
		 * @param {Array<string>} [filter_type] Only return games with the specified type, e.g. \&quot;slots\&quot;. When this filter is not specified or empty then it will return all games. Multiple types can be specified as well, for example \&quot;filter_type&#x3D;slots,table_games\&quot; will return the union of all \&quot;slots\&quot; and \&quot;table_games\&quot; in one list
		 * @param {'popular' | 'new_rng' | 'best_live_games' | 'best_table_games'} [filter_list] Only return games specified in the given list. The lists are stored in the backend.
		 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
		 * @param {Array<'game_count' | '-game_count' | 'name' | '-name'>} [sort] Sorting options. It supports sorting by multiple attributes, like \&quot;game_count,name\&quot;. The sort order is ascending by default, to sort for descending order the attribute name must start with hyphen e.g. \&quot;-name\&quot; will sort z-a. The default sort is &#x60;-game_count&#x60;
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidStudiosGet(
			player_uuid: string,
			filter_category: 'all' | 'recommendation',
			brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
			filter_country?: string,
			filter_currency?: string,
			filter_filter_id?: number,
			filter_live?: boolean,
			filter_platform?: 'mobile' | 'desktop',
			filter_section_id?: string,
			filter_studio?: string,
			filter_tags?: Array<string>,
			filter_themes?: Array<string>,
			filter_tile_type?: 'tile' | 'portrait' | 'character',
			filter_type?: Array<string>,
			filter_list?:
				| 'popular'
				| 'new_rng'
				| 'best_live_games'
				| 'best_table_games',
			lang?: string,
			sort?: Array<'game_count' | '-game_count' | 'name' | '-name'>,
			options?: any
		) {
			return GamesApiFp(configuration).v1PlayersPlayerUuidStudiosGet(
				player_uuid,
				filter_category,
				brand,
				filter_country,
				filter_currency,
				filter_filter_id,
				filter_live,
				filter_platform,
				filter_section_id,
				filter_studio,
				filter_tags,
				filter_themes,
				filter_tile_type,
				filter_type,
				filter_list,
				lang,
				sort,
				options
			)(fetch, basePath);
		}
	};
};

/**
 * GamesApi - object-oriented interface
 * @export
 * @class GamesApi
 * @extends {BaseAPI}
 */
export class GamesApi extends BaseAPI {
	/**
	 *
	 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
	 * @param {string} [filter_country]
	 * @param {string} [filter_currency]
	 * @param {'mobile' | 'desktop'} [filter_platform]
	 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof GamesApi
	 */
	public v1GamesGameUuidGet(
		game_uuid: string,
		filter_country?: string,
		filter_currency?: string,
		filter_platform?: 'mobile' | 'desktop',
		lang?: string,
		options?: any
	) {
		return GamesApiFp(this.configuration).v1GamesGameUuidGet(
			game_uuid,
			filter_country,
			filter_currency,
			filter_platform,
			lang,
			options
		)(this.fetch, this.basePath);
	}

	/**
	 *
	 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
	 * @param {'mobile' | 'desktop'} platform
	 * @param {string} [lang]
	 * @param {boolean} [freeplay]
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof GamesApi
	 */
	public v1GamesGameUuidLaunchUrlGet(
		game_uuid: string,
		platform: 'mobile' | 'desktop',
		lang?: string,
		freeplay?: boolean,
		options?: any
	) {
		return GamesApiFp(this.configuration).v1GamesGameUuidLaunchUrlGet(
			game_uuid,
			platform,
			lang,
			freeplay,
			options
		)(this.fetch, this.basePath);
	}

	/**
	 *
	 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
	 * @param {GameRecord} [GameRecord]
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof GamesApi
	 */
	public v1GamesGameUuidPut(
		game_uuid: string,
		GameRecord?: GameRecord,
		options?: any
	) {
		return GamesApiFp(this.configuration).v1GamesGameUuidPut(
			game_uuid,
			GameRecord,
			options
		)(this.fetch, this.basePath);
	}

	/**
	 *
	 * @param {boolean} [live]
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof GamesApi
	 */
	public v1GamesGet(live?: boolean, options?: any) {
		return GamesApiFp(this.configuration).v1GamesGet(live, options)(
			this.fetch,
			this.basePath
		);
	}

	/**
	 *
	 * @param {GameRecord} [GameRecord]
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof GamesApi
	 */
	public v1GamesPost(GameRecord?: GameRecord, options?: any) {
		return GamesApiFp(this.configuration).v1GamesPost(GameRecord, options)(
			this.fetch,
			this.basePath
		);
	}

	/**
	 *
	 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
	 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
	 * @param {string} [filter_country]
	 * @param {string} [filter_currency]
	 * @param {'mobile' | 'desktop'} [filter_platform]
	 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof GamesApi
	 */
	public v1PlayersPlayerUuidGamesGameUuidGet(
		player_uuid: string,
		game_uuid: string,
		filter_country?: string,
		filter_currency?: string,
		filter_platform?: 'mobile' | 'desktop',
		lang?: string,
		options?: any
	) {
		return GamesApiFp(
			this.configuration
		).v1PlayersPlayerUuidGamesGameUuidGet(
			player_uuid,
			game_uuid,
			filter_country,
			filter_currency,
			filter_platform,
			lang,
			options
		)(this.fetch, this.basePath);
	}

	/**
	 *
	 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
	 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
	 * @param {'mobile' | 'desktop'} platform
	 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
	 * @param {string} [lang]
	 * @param {boolean} [freeplay]
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof GamesApi
	 */
	public v1PlayersPlayerUuidGamesGameUuidLaunchUrlGet(
		player_uuid: string,
		game_uuid: string,
		platform: 'mobile' | 'desktop',
		brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
		lang?: string,
		freeplay?: boolean,
		options?: any
	) {
		return GamesApiFp(
			this.configuration
		).v1PlayersPlayerUuidGamesGameUuidLaunchUrlGet(
			player_uuid,
			game_uuid,
			platform,
			brand,
			lang,
			freeplay,
			options
		)(this.fetch, this.basePath);
	}

	/**
	 *
	 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
	 * @param {'all' | 'recommendation'} filter_category Filters the games by category. \&quot;all\&quot; returns all games (so no filtering), \&quot;recommendation\&quot; returns only the recommended games for the player
	 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
	 * @param {string} [filter_country]
	 * @param {string} [filter_currency]
	 * @param {number} [filter_filter_id]
	 * @param {boolean} [filter_live]
	 * @param {'mobile' | 'desktop'} [filter_platform]
	 * @param {string} [filter_section_id]
	 * @param {string} [filter_studio]
	 * @param {Array<string>} [filter_tags] Only return games which have all of these tags. If it&#39;s not present or empty array then it returns all games. For example if specify \&quot;filter_tags&#x3D;new,featured\&quot; it will return only games which are tagged with both \&quot;new\&quot; and \&quot;featured\&quot;
	 * @param {Array<string>} [filter_themes] Only return games which have at least one of these themes, so adding more elements to filter_themes will return more and more games. If it&#39;s not present or empty array then it returns all games.
	 * @param {'tile' | 'portrait' | 'character'} [filter_tile_type] Filter game by image tile type.
	 * @param {Array<string>} [filter_type] Only return games with the specified type, e.g. \&quot;slots\&quot;. When this filter is not specified or empty then it will return all games. Multiple types can be specified as well, for example \&quot;filter_type&#x3D;slots,table_games\&quot; will return the union of all \&quot;slots\&quot; and \&quot;table_games\&quot; in one list
	 * @param {'popular' | 'new_rng' | 'best_live_games' | 'best_table_games'} [filter_list] Only return games specified in the given list. The lists are stored in the backend.
	 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
	 * @param {Array<'name' | '-name' | 'recommendation' | '-recommendation' | 'popularity' | '-popularity' | 'biggest_possible_win' | '-biggest_possible_win' | 'id' | '-id' | 'featured_order' | '-featured_order'>} [sort] Sorting options. It supports sorting by multiple attributes, like \&quot;recommendation,popularity\&quot;. The sort order is ascending by default, to sort in descending order the attribute name must start with hyphen e.g. \&quot;-name\&quot; will sort z-a
	 * @param {number} [page]
	 * @param {number} [page_size]
	 * @param {boolean} [lobby]
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof GamesApi
	 */
	public v1PlayersPlayerUuidGamesGet(
		player_uuid: string,
		filter_category: 'all' | 'recommendation',
		brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
		filter_country?: string,
		filter_currency?: string,
		filter_filter_id?: number,
		filter_live?: boolean,
		filter_platform?: 'mobile' | 'desktop',
		filter_section_id?: string,
		filter_studio?: string,
		filter_tags?: Array<string>,
		filter_themes?: Array<string>,
		filter_tile_type?: 'tile' | 'portrait' | 'character',
		filter_type?: Array<string>,
		filter_list?:
			| 'popular'
			| 'new_rng'
			| 'best_live_games'
			| 'best_table_games',
		lang?: string,
		sort?: Array<
			| 'name'
			| '-name'
			| 'recommendation'
			| '-recommendation'
			| 'popularity'
			| '-popularity'
			| 'biggest_possible_win'
			| '-biggest_possible_win'
			| 'id'
			| '-id'
			| 'featured_order'
			| '-featured_order'
		>,
		page?: number,
		page_size?: number,
		lobby?: boolean,
		options?: any
	) {
		return GamesApiFp(this.configuration).v1PlayersPlayerUuidGamesGet(
			player_uuid,
			filter_category,
			brand,
			filter_country,
			filter_currency,
			filter_filter_id,
			filter_live,
			filter_platform,
			filter_section_id,
			filter_studio,
			filter_tags,
			filter_themes,
			filter_tile_type,
			filter_type,
			filter_list,
			lang,
			sort,
			page,
			page_size,
			lobby,
			options
		)(this.fetch, this.basePath);
	}

	/**
	 *
	 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
	 * @param {'mobile' | 'desktop'} [filter_platform]
	 * @param {string} [filter_country]
	 * @param {string} [filter_currency]
	 * @param {number} [filter_filter_id]
	 * @param {boolean} [filter_live]
	 * @param {string} [filter_section_id]
	 * @param {string} [filter_studio]
	 * @param {Array<string>} [filter_type] Only return games with the specified type, e.g. \&quot;slots\&quot;. When this filter is not specified or empty then it will return all games. Multiple types can be specified as well, for example \&quot;filter_type&#x3D;slots,table_games\&quot; will return the union of all \&quot;slots\&quot; and \&quot;table_games\&quot; in one list
	 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof GamesApi
	 */
	public v1PlayersPlayerUuidMostRelevantGameGet(
		player_uuid: string,
		filter_platform?: 'mobile' | 'desktop',
		filter_country?: string,
		filter_currency?: string,
		filter_filter_id?: number,
		filter_live?: boolean,
		filter_section_id?: string,
		filter_studio?: string,
		filter_type?: Array<string>,
		lang?: string,
		options?: any
	) {
		return GamesApiFp(
			this.configuration
		).v1PlayersPlayerUuidMostRelevantGameGet(
			player_uuid,
			filter_platform,
			filter_country,
			filter_currency,
			filter_filter_id,
			filter_live,
			filter_section_id,
			filter_studio,
			filter_type,
			lang,
			options
		)(this.fetch, this.basePath);
	}

	/**
	 *
	 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
	 * @param {string} [filter_country]
	 * @param {string} [filter_currency]
	 * @param {'mobile' | 'desktop'} [filter_platform]
	 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
	 * @param {number} [page_size]
	 * @param {number} [page]
	 * @param {'favourites' | 'recommended'} [tab]
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof GamesApi
	 */
	public v1PlayersPlayerUuidMyCasinoGamesGet(
		player_uuid: string,
		filter_country?: string,
		filter_currency?: string,
		filter_platform?: 'mobile' | 'desktop',
		lang?: string,
		page_size?: number,
		page?: number,
		tab?: 'favourites' | 'recommended',
		options?: any
	) {
		return GamesApiFp(
			this.configuration
		).v1PlayersPlayerUuidMyCasinoGamesGet(
			player_uuid,
			filter_country,
			filter_currency,
			filter_platform,
			lang,
			page_size,
			page,
			tab,
			options
		)(this.fetch, this.basePath);
	}

	/**
	 *
	 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
	 * @param {'all' | 'recommendation'} filter_category Filters the games by category. \&quot;all\&quot; returns all games (so no filtering), \&quot;recommendation\&quot; returns only the recommended games for the player
	 * @param {'cloudbet' | 'latinbet24' | 'materialbet'} [brand]
	 * @param {string} [filter_country]
	 * @param {string} [filter_currency]
	 * @param {number} [filter_filter_id]
	 * @param {boolean} [filter_live]
	 * @param {'mobile' | 'desktop'} [filter_platform]
	 * @param {string} [filter_section_id]
	 * @param {string} [filter_studio]
	 * @param {Array<string>} [filter_tags] Only return games which have all of these tags. If it&#39;s not present or empty array then it returns all games. For example if specify \&quot;filter_tags&#x3D;new,featured\&quot; it will return only games which are tagged with both \&quot;new\&quot; and \&quot;featured\&quot;
	 * @param {Array<string>} [filter_themes] Only return games which have at least one of these themes, so adding more elements to filter_themes will return more and more games. If it&#39;s not present or empty array then it returns all games.
	 * @param {'tile' | 'portrait' | 'character'} [filter_tile_type] Filter game by image tile type.
	 * @param {Array<string>} [filter_type] Only return games with the specified type, e.g. \&quot;slots\&quot;. When this filter is not specified or empty then it will return all games. Multiple types can be specified as well, for example \&quot;filter_type&#x3D;slots,table_games\&quot; will return the union of all \&quot;slots\&quot; and \&quot;table_games\&quot; in one list
	 * @param {'popular' | 'new_rng' | 'best_live_games' | 'best_table_games'} [filter_list] Only return games specified in the given list. The lists are stored in the backend.
	 * @param {string} [lang] The language code used to launch games in a specific locale. If this query param is not specified then the launch URLs will not contain any specific language.
	 * @param {Array<'game_count' | '-game_count' | 'name' | '-name'>} [sort] Sorting options. It supports sorting by multiple attributes, like \&quot;game_count,name\&quot;. The sort order is ascending by default, to sort for descending order the attribute name must start with hyphen e.g. \&quot;-name\&quot; will sort z-a. The default sort is &#x60;-game_count&#x60;
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof GamesApi
	 */
	public v1PlayersPlayerUuidStudiosGet(
		player_uuid: string,
		filter_category: 'all' | 'recommendation',
		brand?: 'cloudbet' | 'latinbet24' | 'materialbet',
		filter_country?: string,
		filter_currency?: string,
		filter_filter_id?: number,
		filter_live?: boolean,
		filter_platform?: 'mobile' | 'desktop',
		filter_section_id?: string,
		filter_studio?: string,
		filter_tags?: Array<string>,
		filter_themes?: Array<string>,
		filter_tile_type?: 'tile' | 'portrait' | 'character',
		filter_type?: Array<string>,
		filter_list?:
			| 'popular'
			| 'new_rng'
			| 'best_live_games'
			| 'best_table_games',
		lang?: string,
		sort?: Array<'game_count' | '-game_count' | 'name' | '-name'>,
		options?: any
	) {
		return GamesApiFp(this.configuration).v1PlayersPlayerUuidStudiosGet(
			player_uuid,
			filter_category,
			brand,
			filter_country,
			filter_currency,
			filter_filter_id,
			filter_live,
			filter_platform,
			filter_section_id,
			filter_studio,
			filter_tags,
			filter_themes,
			filter_tile_type,
			filter_type,
			filter_list,
			lang,
			sort,
			options
		)(this.fetch, this.basePath);
	}
}

/**
 * GamesessionsApi - fetch parameter creator
 * @export
 */
export const GamesessionsApiFetchParamCreator = function (
	configuration?: Configuration
) {
	return {
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidGamesGameUuidGamesessionsRecordBetPost(
			player_uuid: string,
			game_uuid: string,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'player_uuid' is not null or undefined
			if (player_uuid === null || player_uuid === undefined) {
				throw new RequiredError(
					'player_uuid',
					'Required parameter player_uuid was null or undefined when calling v1PlayersPlayerUuidGamesGameUuidGamesessionsRecordBetPost.'
				);
			}
			// verify required parameter 'game_uuid' is not null or undefined
			if (game_uuid === null || game_uuid === undefined) {
				throw new RequiredError(
					'game_uuid',
					'Required parameter game_uuid was null or undefined when calling v1PlayersPlayerUuidGamesGameUuidGamesessionsRecordBetPost.'
				);
			}
			const localVarPath = `/v1/players/{player_uuid}/games/{game_uuid}/gamesessions/record_bet`
				.replace(
					`{${'player_uuid'}}`,
					encodeURIComponent(String(player_uuid))
				)
				.replace(
					`{${'game_uuid'}}`,
					encodeURIComponent(String(game_uuid))
				);
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'POST' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		}
	};
};

/**
 * GamesessionsApi - functional programming interface
 * @export
 */
export const GamesessionsApiFp = function (configuration?: Configuration) {
	return {
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidGamesGameUuidGamesessionsRecordBetPost(
			player_uuid: string,
			game_uuid: string,
			options?: any
		): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = GamesessionsApiFetchParamCreator(
				configuration
			).v1PlayersPlayerUuidGamesGameUuidGamesessionsRecordBetPost(
				player_uuid,
				game_uuid,
				options
			);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response;
					} else {
						throw response;
					}
				});
			};
		}
	};
};

/**
 * GamesessionsApi - factory interface
 * @export
 */
export const GamesessionsApiFactory = function (
	configuration?: Configuration,
	fetch?: FetchAPI,
	basePath?: string
) {
	return {
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidGamesGameUuidGamesessionsRecordBetPost(
			player_uuid: string,
			game_uuid: string,
			options?: any
		) {
			return GamesessionsApiFp(
				configuration
			).v1PlayersPlayerUuidGamesGameUuidGamesessionsRecordBetPost(
				player_uuid,
				game_uuid,
				options
			)(fetch, basePath);
		}
	};
};

/**
 * GamesessionsApi - object-oriented interface
 * @export
 * @class GamesessionsApi
 * @extends {BaseAPI}
 */
export class GamesessionsApi extends BaseAPI {
	/**
	 *
	 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
	 * @param {string} game_uuid Identifier with optional namespace of the Game, for example: \&quot;cloudbet:identifier\&quot;
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof GamesessionsApi
	 */
	public v1PlayersPlayerUuidGamesGameUuidGamesessionsRecordBetPost(
		player_uuid: string,
		game_uuid: string,
		options?: any
	) {
		return GamesessionsApiFp(
			this.configuration
		).v1PlayersPlayerUuidGamesGameUuidGamesessionsRecordBetPost(
			player_uuid,
			game_uuid,
			options
		)(this.fetch, this.basePath);
	}
}

/**
 * HealthcheckApi - fetch parameter creator
 * @export
 */
export const HealthcheckApiFetchParamCreator = function (
	configuration?: Configuration
) {
	return {
		/**
		 *
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		liveGet(options: any = {}): FetchArgs {
			const localVarPath = `/live`;
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		},
		/**
		 *
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		readyGet(options: any = {}): FetchArgs {
			const localVarPath = `/ready`;
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		}
	};
};

/**
 * HealthcheckApi - functional programming interface
 * @export
 */
export const HealthcheckApiFp = function (configuration?: Configuration) {
	return {
		/**
		 *
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		liveGet(
			options?: any
		): (
			fetch?: FetchAPI,
			basePath?: string
		) => Promise<InlineResponse2008> {
			const localVarFetchArgs = HealthcheckApiFetchParamCreator(
				configuration
			).liveGet(options);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		},
		/**
		 *
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		readyGet(
			options?: any
		): (
			fetch?: FetchAPI,
			basePath?: string
		) => Promise<InlineResponse2009> {
			const localVarFetchArgs = HealthcheckApiFetchParamCreator(
				configuration
			).readyGet(options);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		}
	};
};

/**
 * HealthcheckApi - factory interface
 * @export
 */
export const HealthcheckApiFactory = function (
	configuration?: Configuration,
	fetch?: FetchAPI,
	basePath?: string
) {
	return {
		/**
		 *
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		liveGet(options?: any) {
			return HealthcheckApiFp(configuration).liveGet(options)(
				fetch,
				basePath
			);
		},
		/**
		 *
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		readyGet(options?: any) {
			return HealthcheckApiFp(configuration).readyGet(options)(
				fetch,
				basePath
			);
		}
	};
};

/**
 * HealthcheckApi - object-oriented interface
 * @export
 * @class HealthcheckApi
 * @extends {BaseAPI}
 */
export class HealthcheckApi extends BaseAPI {
	/**
	 *
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof HealthcheckApi
	 */
	public liveGet(options?: any) {
		return HealthcheckApiFp(this.configuration).liveGet(options)(
			this.fetch,
			this.basePath
		);
	}

	/**
	 *
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof HealthcheckApi
	 */
	public readyGet(options?: any) {
		return HealthcheckApiFp(this.configuration).readyGet(options)(
			this.fetch,
			this.basePath
		);
	}
}

/**
 * RecommendationApi - fetch parameter creator
 * @export
 */
export const RecommendationApiFetchParamCreator = function (
	configuration?: Configuration
) {
	return {
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} generated_for
		 * @param {string} [batch_identifier]
		 * @param {Array<string>} [request_body]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidRecommendationsGamesPut(
			player_uuid: string,
			generated_for: string,
			batch_identifier?: string,
			request_body?: Array<string>,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'player_uuid' is not null or undefined
			if (player_uuid === null || player_uuid === undefined) {
				throw new RequiredError(
					'player_uuid',
					'Required parameter player_uuid was null or undefined when calling v1PlayersPlayerUuidRecommendationsGamesPut.'
				);
			}
			// verify required parameter 'generated_for' is not null or undefined
			if (generated_for === null || generated_for === undefined) {
				throw new RequiredError(
					'generated_for',
					'Required parameter generated_for was null or undefined when calling v1PlayersPlayerUuidRecommendationsGamesPut.'
				);
			}
			const localVarPath = `/v1/players/{player_uuid}/recommendations/games`.replace(
				`{${'player_uuid'}}`,
				encodeURIComponent(String(player_uuid))
			);
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'PUT' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			if (generated_for !== undefined) {
				localVarQueryParameter['generated-for'] = generated_for;
			}

			if (batch_identifier !== undefined) {
				localVarQueryParameter['batch-identifier'] = batch_identifier;
			}

			localVarHeaderParameter['Content-Type'] = 'application/json';

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);
			const needsSerialization =
				<any>'Array&lt;string&gt;' !== 'string' ||
				localVarRequestOptions.headers['Content-Type'] ===
					'application/json';
			localVarRequestOptions.body = needsSerialization
				? JSON.stringify(request_body || {})
				: request_body || '';

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		}
	};
};

/**
 * RecommendationApi - functional programming interface
 * @export
 */
export const RecommendationApiFp = function (configuration?: Configuration) {
	return {
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} generated_for
		 * @param {string} [batch_identifier]
		 * @param {Array<string>} [request_body]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidRecommendationsGamesPut(
			player_uuid: string,
			generated_for: string,
			batch_identifier?: string,
			request_body?: Array<string>,
			options?: any
		): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
			const localVarFetchArgs = RecommendationApiFetchParamCreator(
				configuration
			).v1PlayersPlayerUuidRecommendationsGamesPut(
				player_uuid,
				generated_for,
				batch_identifier,
				request_body,
				options
			);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response;
					} else {
						throw response;
					}
				});
			};
		}
	};
};

/**
 * RecommendationApi - factory interface
 * @export
 */
export const RecommendationApiFactory = function (
	configuration?: Configuration,
	fetch?: FetchAPI,
	basePath?: string
) {
	return {
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} generated_for
		 * @param {string} [batch_identifier]
		 * @param {Array<string>} [request_body]
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidRecommendationsGamesPut(
			player_uuid: string,
			generated_for: string,
			batch_identifier?: string,
			request_body?: Array<string>,
			options?: any
		) {
			return RecommendationApiFp(
				configuration
			).v1PlayersPlayerUuidRecommendationsGamesPut(
				player_uuid,
				generated_for,
				batch_identifier,
				request_body,
				options
			)(fetch, basePath);
		}
	};
};

/**
 * RecommendationApi - object-oriented interface
 * @export
 * @class RecommendationApi
 * @extends {BaseAPI}
 */
export class RecommendationApi extends BaseAPI {
	/**
	 *
	 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
	 * @param {string} generated_for
	 * @param {string} [batch_identifier]
	 * @param {Array<string>} [request_body]
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof RecommendationApi
	 */
	public v1PlayersPlayerUuidRecommendationsGamesPut(
		player_uuid: string,
		generated_for: string,
		batch_identifier?: string,
		request_body?: Array<string>,
		options?: any
	) {
		return RecommendationApiFp(
			this.configuration
		).v1PlayersPlayerUuidRecommendationsGamesPut(
			player_uuid,
			generated_for,
			batch_identifier,
			request_body,
			options
		)(this.fetch, this.basePath);
	}
}

/**
 * RolloverbonusesApi - fetch parameter creator
 * @export
 */
export const RolloverbonusesApiFetchParamCreator = function (
	configuration?: Configuration
) {
	return {
		/**
		 *
		 * @param {string} player_id Identifier of player (UUID)
		 * @param {string} [casino_currency] Currency on our end (i.e. player currency) (e.g. BTC, BCH, ETH). If not provided, rollover bonuses of any casino currencies are returned.
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		apiV1RolloverBonusesGet(
			player_id: string,
			casino_currency?: string,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'player_id' is not null or undefined
			if (player_id === null || player_id === undefined) {
				throw new RequiredError(
					'player_id',
					'Required parameter player_id was null or undefined when calling apiV1RolloverBonusesGet.'
				);
			}
			const localVarPath = `/api/v1/rollover_bonuses`;
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			if (player_id !== undefined) {
				localVarQueryParameter['player_id'] = player_id;
			}

			if (casino_currency !== undefined) {
				localVarQueryParameter['casino_currency'] = casino_currency;
			}

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		}
	};
};

/**
 * RolloverbonusesApi - functional programming interface
 * @export
 */
export const RolloverbonusesApiFp = function (configuration?: Configuration) {
	return {
		/**
		 *
		 * @param {string} player_id Identifier of player (UUID)
		 * @param {string} [casino_currency] Currency on our end (i.e. player currency) (e.g. BTC, BCH, ETH). If not provided, rollover bonuses of any casino currencies are returned.
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		apiV1RolloverBonusesGet(
			player_id: string,
			casino_currency?: string,
			options?: any
		): (
			fetch?: FetchAPI,
			basePath?: string
		) => Promise<InlineResponse2001> {
			const localVarFetchArgs = RolloverbonusesApiFetchParamCreator(
				configuration
			).apiV1RolloverBonusesGet(player_id, casino_currency, options);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		}
	};
};

/**
 * RolloverbonusesApi - factory interface
 * @export
 */
export const RolloverbonusesApiFactory = function (
	configuration?: Configuration,
	fetch?: FetchAPI,
	basePath?: string
) {
	return {
		/**
		 *
		 * @param {string} player_id Identifier of player (UUID)
		 * @param {string} [casino_currency] Currency on our end (i.e. player currency) (e.g. BTC, BCH, ETH). If not provided, rollover bonuses of any casino currencies are returned.
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		apiV1RolloverBonusesGet(
			player_id: string,
			casino_currency?: string,
			options?: any
		) {
			return RolloverbonusesApiFp(configuration).apiV1RolloverBonusesGet(
				player_id,
				casino_currency,
				options
			)(fetch, basePath);
		}
	};
};

/**
 * RolloverbonusesApi - object-oriented interface
 * @export
 * @class RolloverbonusesApi
 * @extends {BaseAPI}
 */
export class RolloverbonusesApi extends BaseAPI {
	/**
	 *
	 * @param {string} player_id Identifier of player (UUID)
	 * @param {string} [casino_currency] Currency on our end (i.e. player currency) (e.g. BTC, BCH, ETH). If not provided, rollover bonuses of any casino currencies are returned.
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof RolloverbonusesApi
	 */
	public apiV1RolloverBonusesGet(
		player_id: string,
		casino_currency?: string,
		options?: any
	) {
		return RolloverbonusesApiFp(this.configuration).apiV1RolloverBonusesGet(
			player_id,
			casino_currency,
			options
		)(this.fetch, this.basePath);
	}
}

/**
 * SitemapApi - fetch parameter creator
 * @export
 */
export const SitemapApiFetchParamCreator = function (
	configuration?: Configuration
) {
	return {
		/**
		 *
		 * @param {string} language The language of the sitemap
		 * @param {Array<string>} all_languages All available languages (includes &#x60;language&#x60; as well)
		 * @param {string} base_domain The base domain of the website, e.g. https://www.cloudbet.com
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1SitemapGet(
			language: string,
			all_languages: Array<string>,
			base_domain: string,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'language' is not null or undefined
			if (language === null || language === undefined) {
				throw new RequiredError(
					'language',
					'Required parameter language was null or undefined when calling v1SitemapGet.'
				);
			}
			// verify required parameter 'all_languages' is not null or undefined
			if (all_languages === null || all_languages === undefined) {
				throw new RequiredError(
					'all_languages',
					'Required parameter all_languages was null or undefined when calling v1SitemapGet.'
				);
			}
			// verify required parameter 'base_domain' is not null or undefined
			if (base_domain === null || base_domain === undefined) {
				throw new RequiredError(
					'base_domain',
					'Required parameter base_domain was null or undefined when calling v1SitemapGet.'
				);
			}
			const localVarPath = `/v1/sitemap`;
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			if (language !== undefined) {
				localVarQueryParameter['language'] = language;
			}

			if (all_languages) {
				localVarQueryParameter['all_languages'] = all_languages.join(
					COLLECTION_FORMATS['csv']
				);
			}

			if (base_domain !== undefined) {
				localVarQueryParameter['base_domain'] = base_domain;
			}

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		}
	};
};

/**
 * SitemapApi - functional programming interface
 * @export
 */
export const SitemapApiFp = function (configuration?: Configuration) {
	return {
		/**
		 *
		 * @param {string} language The language of the sitemap
		 * @param {Array<string>} all_languages All available languages (includes &#x60;language&#x60; as well)
		 * @param {string} base_domain The base domain of the website, e.g. https://www.cloudbet.com
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1SitemapGet(
			language: string,
			all_languages: Array<string>,
			base_domain: string,
			options?: any
		): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
			const localVarFetchArgs = SitemapApiFetchParamCreator(
				configuration
			).v1SitemapGet(language, all_languages, base_domain, options);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		}
	};
};

/**
 * SitemapApi - factory interface
 * @export
 */
export const SitemapApiFactory = function (
	configuration?: Configuration,
	fetch?: FetchAPI,
	basePath?: string
) {
	return {
		/**
		 *
		 * @param {string} language The language of the sitemap
		 * @param {Array<string>} all_languages All available languages (includes &#x60;language&#x60; as well)
		 * @param {string} base_domain The base domain of the website, e.g. https://www.cloudbet.com
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1SitemapGet(
			language: string,
			all_languages: Array<string>,
			base_domain: string,
			options?: any
		) {
			return SitemapApiFp(configuration).v1SitemapGet(
				language,
				all_languages,
				base_domain,
				options
			)(fetch, basePath);
		}
	};
};

/**
 * SitemapApi - object-oriented interface
 * @export
 * @class SitemapApi
 * @extends {BaseAPI}
 */
export class SitemapApi extends BaseAPI {
	/**
	 *
	 * @param {string} language The language of the sitemap
	 * @param {Array<string>} all_languages All available languages (includes &#x60;language&#x60; as well)
	 * @param {string} base_domain The base domain of the website, e.g. https://www.cloudbet.com
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof SitemapApi
	 */
	public v1SitemapGet(
		language: string,
		all_languages: Array<string>,
		base_domain: string,
		options?: any
	) {
		return SitemapApiFp(this.configuration).v1SitemapGet(
			language,
			all_languages,
			base_domain,
			options
		)(this.fetch, this.basePath);
	}
}

/**
 * StudiosApi - fetch parameter creator
 * @export
 */
export const StudiosApiFetchParamCreator = function (
	configuration?: Configuration
) {
	return {
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} section_id
		 * @param {string} filter_lang The player&#39;s selected language. It can be used to filter games.
		 * @param {string} filter_brand The brand of the website what the player is using.
		 * @param {string} filter_currency The player&#39;s currently selected currency.
		 * @param {'mobile' | 'desktop'} filter_platform
		 * @param {Array<'game_count' | '-game_count' | 'name' | '-name'>} [sort] Sorting options. It supports sorting by multiple attributes, like \&quot;game_count,name\&quot;. The sort order is ascending by default, to sort in descending order the attribute name must start with hyphen e.g. \&quot;-name\&quot; will sort z-a. The default sort is &#x60;-game_count&#x60;
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidGameRowsSectionIdStudiosGet(
			player_uuid: string,
			section_id: string,
			filter_lang: string,
			filter_brand: string,
			filter_currency: string,
			filter_platform: 'mobile' | 'desktop',
			sort?: Array<'game_count' | '-game_count' | 'name' | '-name'>,
			options: any = {}
		): FetchArgs {
			// verify required parameter 'player_uuid' is not null or undefined
			if (player_uuid === null || player_uuid === undefined) {
				throw new RequiredError(
					'player_uuid',
					'Required parameter player_uuid was null or undefined when calling v1PlayersPlayerUuidGameRowsSectionIdStudiosGet.'
				);
			}
			// verify required parameter 'section_id' is not null or undefined
			if (section_id === null || section_id === undefined) {
				throw new RequiredError(
					'section_id',
					'Required parameter section_id was null or undefined when calling v1PlayersPlayerUuidGameRowsSectionIdStudiosGet.'
				);
			}
			// verify required parameter 'filter_lang' is not null or undefined
			if (filter_lang === null || filter_lang === undefined) {
				throw new RequiredError(
					'filter_lang',
					'Required parameter filter_lang was null or undefined when calling v1PlayersPlayerUuidGameRowsSectionIdStudiosGet.'
				);
			}
			// verify required parameter 'filter_brand' is not null or undefined
			if (filter_brand === null || filter_brand === undefined) {
				throw new RequiredError(
					'filter_brand',
					'Required parameter filter_brand was null or undefined when calling v1PlayersPlayerUuidGameRowsSectionIdStudiosGet.'
				);
			}
			// verify required parameter 'filter_currency' is not null or undefined
			if (filter_currency === null || filter_currency === undefined) {
				throw new RequiredError(
					'filter_currency',
					'Required parameter filter_currency was null or undefined when calling v1PlayersPlayerUuidGameRowsSectionIdStudiosGet.'
				);
			}
			// verify required parameter 'filter_platform' is not null or undefined
			if (filter_platform === null || filter_platform === undefined) {
				throw new RequiredError(
					'filter_platform',
					'Required parameter filter_platform was null or undefined when calling v1PlayersPlayerUuidGameRowsSectionIdStudiosGet.'
				);
			}
			const localVarPath = `/v1/players/{player_uuid}/game_rows/{section_id}/studios`
				.replace(
					`{${'player_uuid'}}`,
					encodeURIComponent(String(player_uuid))
				)
				.replace(
					`{${'section_id'}}`,
					encodeURIComponent(String(section_id))
				);
			const localVarUrlObj = url.parse(localVarPath, true);
			let baseOptions;
			if (configuration) {
				baseOptions = configuration.baseOptions;
			}
			const localVarRequestOptions = Object.assign(
				{ method: 'GET' },
				baseOptions,
				options
			);
			const localVarHeaderParameter = {} as any;
			const localVarQueryParameter = {} as any;

			if (filter_lang !== undefined) {
				localVarQueryParameter['filter[lang]'] = filter_lang;
			}

			if (filter_brand !== undefined) {
				localVarQueryParameter['filter[brand]'] = filter_brand;
			}

			if (filter_currency !== undefined) {
				localVarQueryParameter['filter[currency]'] = filter_currency;
			}

			if (filter_platform !== undefined) {
				localVarQueryParameter['filter[platform]'] = filter_platform;
			}

			if (sort) {
				localVarQueryParameter['sort'] = sort.join(
					COLLECTION_FORMATS['csv']
				);
			}

			localVarUrlObj.query = Object.assign(
				{},
				localVarUrlObj.query,
				localVarQueryParameter,
				options.query
			);
			// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
			delete localVarUrlObj.search;
			localVarRequestOptions.headers = Object.assign(
				{},
				localVarHeaderParameter,
				options.headers
			);

			return {
				url: url.format(localVarUrlObj),
				options: localVarRequestOptions
			};
		}
	};
};

/**
 * StudiosApi - functional programming interface
 * @export
 */
export const StudiosApiFp = function (configuration?: Configuration) {
	return {
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} section_id
		 * @param {string} filter_lang The player&#39;s selected language. It can be used to filter games.
		 * @param {string} filter_brand The brand of the website what the player is using.
		 * @param {string} filter_currency The player&#39;s currently selected currency.
		 * @param {'mobile' | 'desktop'} filter_platform
		 * @param {Array<'game_count' | '-game_count' | 'name' | '-name'>} [sort] Sorting options. It supports sorting by multiple attributes, like \&quot;game_count,name\&quot;. The sort order is ascending by default, to sort in descending order the attribute name must start with hyphen e.g. \&quot;-name\&quot; will sort z-a. The default sort is &#x60;-game_count&#x60;
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidGameRowsSectionIdStudiosGet(
			player_uuid: string,
			section_id: string,
			filter_lang: string,
			filter_brand: string,
			filter_currency: string,
			filter_platform: 'mobile' | 'desktop',
			sort?: Array<'game_count' | '-game_count' | 'name' | '-name'>,
			options?: any
		): (
			fetch?: FetchAPI,
			basePath?: string
		) => Promise<InlineResponse2005> {
			const localVarFetchArgs = StudiosApiFetchParamCreator(
				configuration
			).v1PlayersPlayerUuidGameRowsSectionIdStudiosGet(
				player_uuid,
				section_id,
				filter_lang,
				filter_brand,
				filter_currency,
				filter_platform,
				sort,
				options
			);
			return (
				fetch: FetchAPI = portableFetch,
				basePath: string = BASE_PATH
			) => {
				return fetch(
					basePath + localVarFetchArgs.url,
					localVarFetchArgs.options
				).then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						throw response;
					}
				});
			};
		}
	};
};

/**
 * StudiosApi - factory interface
 * @export
 */
export const StudiosApiFactory = function (
	configuration?: Configuration,
	fetch?: FetchAPI,
	basePath?: string
) {
	return {
		/**
		 *
		 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
		 * @param {string} section_id
		 * @param {string} filter_lang The player&#39;s selected language. It can be used to filter games.
		 * @param {string} filter_brand The brand of the website what the player is using.
		 * @param {string} filter_currency The player&#39;s currently selected currency.
		 * @param {'mobile' | 'desktop'} filter_platform
		 * @param {Array<'game_count' | '-game_count' | 'name' | '-name'>} [sort] Sorting options. It supports sorting by multiple attributes, like \&quot;game_count,name\&quot;. The sort order is ascending by default, to sort in descending order the attribute name must start with hyphen e.g. \&quot;-name\&quot; will sort z-a. The default sort is &#x60;-game_count&#x60;
		 * @param {*} [options] Override http request option.
		 * @throws {RequiredError}
		 */
		v1PlayersPlayerUuidGameRowsSectionIdStudiosGet(
			player_uuid: string,
			section_id: string,
			filter_lang: string,
			filter_brand: string,
			filter_currency: string,
			filter_platform: 'mobile' | 'desktop',
			sort?: Array<'game_count' | '-game_count' | 'name' | '-name'>,
			options?: any
		) {
			return StudiosApiFp(
				configuration
			).v1PlayersPlayerUuidGameRowsSectionIdStudiosGet(
				player_uuid,
				section_id,
				filter_lang,
				filter_brand,
				filter_currency,
				filter_platform,
				sort,
				options
			)(fetch, basePath);
		}
	};
};

/**
 * StudiosApi - object-oriented interface
 * @export
 * @class StudiosApi
 * @extends {BaseAPI}
 */
export class StudiosApi extends BaseAPI {
	/**
	 *
	 * @param {string} player_uuid Identifier with optional namespace of the Player, for example: \&quot;cloudbet:12345678-1234-1234-1234-123456789012\&quot;
	 * @param {string} section_id
	 * @param {string} filter_lang The player&#39;s selected language. It can be used to filter games.
	 * @param {string} filter_brand The brand of the website what the player is using.
	 * @param {string} filter_currency The player&#39;s currently selected currency.
	 * @param {'mobile' | 'desktop'} filter_platform
	 * @param {Array<'game_count' | '-game_count' | 'name' | '-name'>} [sort] Sorting options. It supports sorting by multiple attributes, like \&quot;game_count,name\&quot;. The sort order is ascending by default, to sort in descending order the attribute name must start with hyphen e.g. \&quot;-name\&quot; will sort z-a. The default sort is &#x60;-game_count&#x60;
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 * @memberof StudiosApi
	 */
	public v1PlayersPlayerUuidGameRowsSectionIdStudiosGet(
		player_uuid: string,
		section_id: string,
		filter_lang: string,
		filter_brand: string,
		filter_currency: string,
		filter_platform: 'mobile' | 'desktop',
		sort?: Array<'game_count' | '-game_count' | 'name' | '-name'>,
		options?: any
	) {
		return StudiosApiFp(
			this.configuration
		).v1PlayersPlayerUuidGameRowsSectionIdStudiosGet(
			player_uuid,
			section_id,
			filter_lang,
			filter_brand,
			filter_currency,
			filter_platform,
			sort,
			options
		)(this.fetch, this.basePath);
	}
}
