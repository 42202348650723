import api from 'casino/api/casino';
import { rootEpic, rootReducer } from 'casino/modules/rootModule';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';

export interface CasinoEpicDependencies {
	api: typeof api;
}
const epicDependencies: CasinoEpicDependencies = { api };
export const epicMiddleware = createEpicMiddleware({
	dependencies: epicDependencies
});
const composeEnhancers = composeWithDevTools({ name: 'casino-2.0' });
const casinoStore = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(epicMiddleware))
);

epicMiddleware.run(rootEpic);

export { casinoStore };
export type CasinoStore = typeof casinoStore;
