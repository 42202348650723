import { IconButton, Theme, makeStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import clx from 'classnames';
import * as React from 'react';

interface BetslipStakeFormHeaderProps {
	close: () => void;
	disabled: boolean;
	className?: string;
}
const useStyles = makeStyles((theme: Theme) => ({
	close: {
		marginRight: theme.spacing(1)
	},
	container: {
		display: 'flex',
		alignItems: 'center'
	}
}));
export const BetslipStakeFormHeader: React.FunctionComponent<BetslipStakeFormHeaderProps> = (
	props
) => {
	const { close, disabled, className, children } = props;
	const classes = useStyles();
	return (
		<div className={clx(className, classes.container)}>
			<IconButton
				onClick={close}
				color="default"
				size="small"
				disabled={disabled}
				className={classes.close}
			>
				<Close />
			</IconButton>
			{children}
		</div>
	);
};
