import clsx from 'clsx';
import * as React from 'react';

import { Fullscreen } from '../Fullscreen';
import { useIframeAreaStyles } from './IframeArea.styles';
import { useIframeStyles } from './useIframeStyles';

export interface IframeAreaProps {
	/** @description title for the iframe */
	name: string;
	/** @description src url for the iframe */
	url?: string;
	/** @description ratio for the iframe to be shown */
	ratio?: string;
	/** @description background image when area is larger than the ratioed iframe */
	backgroundImgUrl?: string;
	/** @description className for the main component */
	className?: string;
	/** Mode controls*/
	fullscreen: boolean;
	onFullscreenChange: (isFullScreen: boolean) => void;
	cinema: boolean;
	/** Components for different states */
	errorMessage?: React.ReactNode;
	loadingComponent?: React.ReactNode;
}
/** @description a component for responsive iframe where it handle cinema, fullscreen and normal mode based on the parent size */
let IframeArea: React.FC<IframeAreaProps> = (props) => {
	const {
		name,
		url,
		backgroundImgUrl,
		fullscreen,
		onFullscreenChange,
		cinema,
		className,
		ratio,
		errorMessage,
		loadingComponent
	} = props;

	const [iframeLoaded, setIFrameLoaded] = React.useState(false);
	const onIframeLoad = React.useCallback(() => {
		setIFrameLoaded(true);
	}, []);
	const iframeWrapperRef = React.useRef<HTMLDivElement | null>(null);
	const iframeClasses = useIframeStyles({
		ref: iframeWrapperRef,
		ratio,
		fullscreen,
		cinema
	});
	const iframeAreaClasses = useIframeAreaStyles({
		backgroundImgUrl
	});
	return (
		<Fullscreen
			enabled={fullscreen}
			onChange={onFullscreenChange}
			className={clsx(
				iframeAreaClasses.iframeArea,
				className,
				fullscreen && iframeAreaClasses.fullscreenIframeArea,
				cinema && iframeAreaClasses.cinemaIframeArea,
				(!cinema || fullscreen) && iframeAreaClasses.background
			)}
		>
			{!iframeLoaded && url && (
				<div className={iframeAreaClasses.loadingWrapper}>
					{loadingComponent}
				</div>
			)}
			{url ? (
				<div
					ref={iframeWrapperRef}
					className={clsx(
						iframeClasses.root,
						iframeAreaClasses.iframeWrapper,
						(fullscreen || cinema) &&
							iframeAreaClasses.fullscreenContent
					)}
				>
					<iframe
						title={name}
						src={url}
						scrolling="no"
						className={iframeAreaClasses.iframe}
						onLoad={onIframeLoad}
					/>
				</div>
			) : (
				errorMessage
			)}
		</Fullscreen>
	);
};

IframeArea = React.memo(IframeArea);
export { IframeArea };
