import { Translate } from '@gaming-shell/i18n';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	ListItemIcon,
	ListItemText,
	MenuItem
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import * as React from 'react';

export interface ClearBetslipProps {
	onClear: () => void;
}

let ClearBetslip: React.FunctionComponent<ClearBetslipProps> = (props) => {
	const { onClear } = props;
	const [open, setOpen] = React.useState(false);
	const handleConfirm = () => {
		setOpen(false);
		onClear();
	};
	const handleClose = () => {
		setOpen(false);
	};
	return (
		<>
			<MenuItem onClick={() => setOpen(true)}>
				<ListItemIcon>
					<Delete />
				</ListItemIcon>
				<ListItemText>
					<Translate label="betting.clearAllBets" ns="sports" />
				</ListItemText>
			</MenuItem>
			<Dialog open={open} onClose={handleClose}>
				<DialogContent>
					<DialogContentText>
						<Translate
							label="betting.confirmClearBetslip"
							ns="sports"
						/>
					</DialogContentText>
					<DialogActions>
						<Button onClick={handleConfirm}>
							<Translate label="descriptions.yes" />
						</Button>
						<Button onClick={() => setOpen(false)}>
							<Translate label="descriptions.no" />
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</>
	);
};
ClearBetslip = React.memo(ClearBetslip);
export { ClearBetslip };
