import { useLanguage } from '@gaming-shell/i18n';
import * as React from 'react';
import { getSportsName } from 'sports-sdk/sports-core';
import { getSdkLocale } from 'sports/utils/locale';

export interface BreadcrumbSportLabelProps {
	sportKey: string;
}

export const SportNameTranslation: React.FunctionComponent<BreadcrumbSportLabelProps> = (
	props
) => {
	const { sportKey } = props;
	const language = useLanguage();
	return <>{getSportsName(sportKey, getSdkLocale(language))}</>;
};
