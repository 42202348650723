"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toggle = void 0;
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
var __1 = require("../../");
exports.Toggle = function (_a) {
    var className = _a.className, LeftLabelProps = _a.LeftLabelProps, RightLabelProps = _a.RightLabelProps, SwitchProps = _a.SwitchProps, leftLabel = _a.leftLabel, rightLabel = _a.rightLabel, onChange = _a.onChange, _b = _a.checked, checkedProp = _b === void 0 ? false : _b;
    var _c = React.useState(checkedProp), checked = _c[0], setChecked = _c[1];
    React.useEffect(function () {
        setChecked(checkedProp);
    }, [checkedProp]);
    var handleChange = React.useCallback(function (event) {
        onChange(event);
        setChecked(event.target.checked);
    }, [onChange, setChecked]);
    return (React.createElement(__1.Row, { alignItems: "center", className: className },
        leftLabel && (React.createElement(__1.Typography, __assign({ color: "textSecondary", variant: "body2" }, LeftLabelProps), leftLabel)),
        React.createElement(core_1.Switch, __assign({ checked: checked, onChange: handleChange }, SwitchProps)),
        rightLabel && (React.createElement(__1.Typography, __assign({ color: "textSecondary", variant: "body2" }, RightLabelProps), rightLabel))));
};
