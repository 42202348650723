import { IconButton, Menu } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import * as React from 'react';

import { AcceptBetterOdds } from '../AcceptBetterOdds';
import { betslipActions } from '../betslipReducer';
import { getIsSubmitting } from '../betslipSelectors/betslipSelectors';
import { useBetslipDispatch } from '../hooks/useBetslipDispatch';
import { useBetslipSelector } from '../hooks/useBetslipSelector';
import { ClearBetslip } from './ClearBetslip';

const style = { height: '32px' };
export const BigBetslipOptions: React.FunctionComponent<unknown> = () => {
	const dispatch = useBetslipDispatch();
	const isSubmitting = useBetslipSelector(getIsSubmitting);
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLElement | null>();
	const clearBets = () => {
		dispatch(betslipActions.clear());
		setOpen(false);
	};
	return (
		<>
			<IconButton
				onClick={() => setOpen(true)}
				ref={(el) => {
					anchorRef.current = el;
				}}
				size="small"
				style={style}
				disabled={isSubmitting}
			>
				<MoreVert />
			</IconButton>
			<Menu
				open={open}
				anchorEl={anchorRef.current}
				onClose={() => setOpen(false)}
				disableScrollLock={true}
			>
				<AcceptBetterOdds />
				<ClearBetslip onClear={clearBets} />
			</Menu>
		</>
	);
};
