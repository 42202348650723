import * as React from 'react';
import { Platform, getPlatform } from '@gaming-shell/device';

export type CreatePlatformSwitchInput<DesktopComponent, MobileComponent> = {
	[key in Platform]: React.FC<DesktopComponent> | React.FC<MobileComponent>;
};

export type CreatePlatformSwitchOutput<
	DesktopComponent,
	MobileComponent
> = React.FC<DesktopComponent & MobileComponent>;

/* will server proper component for desktop / mobile version based on 'device' function from @gaming-shell/device package */
export const createPlatformSwitch = <
	DesktopComponent extends {},
	MobileComponent extends {}
>(
	components: CreatePlatformSwitchInput<DesktopComponent, MobileComponent>
): CreatePlatformSwitchOutput<DesktopComponent, MobileComponent> => {
	const PlatformSwitchComponent: React.FC<DesktopComponent &
		MobileComponent> = (props) => {
		/* Be careful, this will be global for all components created via this hoc */
		const Component = components[getPlatform()];

		return <Component {...props} />;
	};

	PlatformSwitchComponent.displayName = `createPlatformSwitch(${components.mobile.displayName}, ${components.desktop.displayName})`;
	return PlatformSwitchComponent;
};
