import { isMobile } from '@gaming-shell/device';
import {
	useCloseJoinMessageSnackbar,
	useOpenJoinMessageSnackbar
} from '@gaming-shell/layout';
import { Theme } from '@gaming-shell/theming';
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { SelectionAdditionalInfo } from 'sports/components/SelectionAdditionalInfo';
import { usePlayerCurrencyVariantConfig } from 'sports/utils/currency';
import { useSelectionLevelPusherUpdate } from 'sports/utils/pusherUtils';

import { BetslipErrorSlip } from '../BetslipErrorSlip';
import { BetslipSelectionResult } from '../BetslipSelectionResult';
import { getIsSubmitting } from '../betslipSelectors/betslipSelectors';
import { BetslipStakeFormHeader } from '../BetslipStakeFormHeader';
import { createOnSelectionUpdate } from '../createOnSelectionUpdate';
import { useBetslipDispatch } from '../hooks/useBetslipDispatch';
import { useBetslipSelector } from '../hooks/useBetslipSelector';
import { useSelectionCanAddToBigBetslip } from '../hooks/useSelectionCanAddToBigBetslip';
import { usePersistBetslipOnUnload } from '../persistBetslip';
import {
	BetslipReducerSelection,
	BetslipReducerStatus,
	QuickBetslipVariant,
	StakeLocation
} from '../types';
import { QuickBetslipPrice } from './QuickBetslipPrice';
import { useOnAddToBigBetslip } from './QuickBetslipProvider';
import { QuickBetslipSelectionInfo } from './QuickBetslipSelectionInfo';
import { QuickBetslipStakeInputForm } from './QuickBetslipStakeInputForm';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: isMobile() ? '100%' : 376
	},
	header: {
		overflow: 'hidden',
		padding: theme.spacing(2, 2, 1, 2)
	},
	item: ({ error }: { error: boolean }) => ({
		width: '100%',
		padding: theme.spacing(0, 2, 2, 2),
		backgroundColor: error ? theme.palette.background.top.main : undefined
	}),
	info: {
		gridTemplateColumns: '1fr auto',
		display: 'grid',
		flexGrow: 1,
		position: 'relative'
	}
}));

export interface QuickBetslipProps {
	selection: BetslipReducerSelection;
	changedPrice?: number;
	betslipStatus: BetslipReducerStatus;
	setStake: (stake: number) => void;
	onStakeDelete: () => void;
	onPlaceBetClick: () => void;
	onClose: () => void;
	variant: QuickBetslipVariant;
}
const quickBetslipStakeLocation: StakeLocation = {
	straight: { selectionIndex: 0 }
};
let QuickBetslip: React.FunctionComponent<QuickBetslipProps> = (props) => {
	// TODO add message and selections
	const {
		selection,
		changedPrice,
		onClose,
		setStake,
		onStakeDelete,
		onPlaceBetClick,
		betslipStatus,
		variant
	} = props;
	const openJoinSnackbarMessage = useOpenJoinMessageSnackbar();
	const closeJoinSnackbarMessage = useCloseJoinMessageSnackbar();
	React.useEffect(() => {
		closeJoinSnackbarMessage();

		return () => {
			openJoinSnackbarMessage();
		};
	}, [closeJoinSnackbarMessage, openJoinSnackbarMessage]);
	const isSubmitting = useBetslipSelector(getIsSubmitting);
	const dispatch = useBetslipDispatch();

	const disableForm = isSubmitting;
	const classes = useStyles({ error: disableForm });
	const onAddToBigBetslip = useOnAddToBigBetslip();
	const currencyConfig = usePlayerCurrencyVariantConfig();
	const {
		minStake,
		maxStake,
		stake,
		price,
		marketName,
		outcomeName,
		outcome,
		params,
		eventId,
		marketKey,
		submarketKey
	} = selection;
	const disableIncreaseStake = stake >= maxStake;
	const onSelectionUpdate = createOnSelectionUpdate(dispatch);
	useSelectionLevelPusherUpdate({
		outcome,
		params,
		eventId,
		marketKey,
		submarketKey,
		onSelectionUpdate
	});
	usePersistBetslipOnUnload(true);
	const canSelectionAddToBigBetslip = useSelectionCanAddToBigBetslip(
		selection
	);
	const disableSecondaryButton = !canSelectionAddToBigBetslip;
	return (
		<div className={classes.root}>
			<BetslipStakeFormHeader
				close={onClose}
				disabled={isSubmitting}
				className={classes.header}
			>
				<div className={classes.info}>
					<QuickBetslipSelectionInfo
						outcomeName={outcomeName}
						marketName={marketName}
					/>

					<QuickBetslipPrice
						changedPrice={changedPrice}
						price={price}
						side="back"
					/>
				</div>
			</BetslipStakeFormHeader>
			<BetslipErrorSlip
				errors={betslipStatus}
				minStake={minStake}
				maxStake={maxStake}
				changedPrice={changedPrice}
				stakeLocation={quickBetslipStakeLocation}
			/>

			<div className={classes.item}>
				<SelectionAdditionalInfo selectionIndex={0} />
				<QuickBetslipStakeInputForm
					stake={stake}
					currencyConfig={currencyConfig}
					minStake={minStake}
					maxStake={maxStake}
					onChange={setStake}
					onStakeDelete={onStakeDelete}
					disabled={disableForm}
					disableIncreaseStake={disableIncreaseStake}
					disableSecondaryButton={disableSecondaryButton}
					onConfirmClick={onPlaceBetClick}
					onSecondaryActionClick={onAddToBigBetslip}
					variant={variant}
					isSubmitting={isSubmitting}
					selectionResult={
						<BetslipSelectionResult
							price={changedPrice || price}
							stake={stake}
							currencyConfig={currencyConfig}
							label="sports:betting.toReturn"
						/>
					}
				/>
			</div>
		</div>
	);
};
QuickBetslip = React.memo(QuickBetslip);
export { QuickBetslip };
