import Grid, { GridProps } from '@material-ui/core/Grid';
import * as React from 'react';

export type RowProps = Omit<GridProps, 'container' | 'direction'>;

// Don't test as there's nothing to test here
/* istanbul ignore file */
export const Row: React.FunctionComponent<RowProps> = ({
	children,
	...props
}) => {
	return (
		<Grid container direction="row" {...props}>
			{children}
		</Grid>
	);
};
