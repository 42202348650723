import * as React from 'react';

import { defaultIframeRatio } from '../configs/ratio';
import {
	fillAvailableSpaceWhileKeepingRatio,
	getDefaultDimensions
} from './dimensionsUtils';
import { getRatioFromString } from './ratioUtils';

export interface IframeDimensionsParams {
	availableHeight: number;
	availableWidth: number;
	ratio?: string;
}

export const getIframeDimensions = ({
	availableHeight,
	availableWidth,
	ratio = defaultIframeRatio
}: IframeDimensionsParams) => {
	// Ratio
	const aspectRatio = getRatioFromString(ratio);
	const { defaultWidth, defaultHeight } = getDefaultDimensions(ratio);
	const iframeDimensions = fillAvailableSpaceWhileKeepingRatio({
		width: availableWidth,
		height: availableHeight,
		ratio: aspectRatio
	});
	// Height and width for game
	const height =
		iframeDimensions.height > 0
			? `${iframeDimensions.height}px`
			: defaultHeight;
	const width =
		iframeDimensions.width > 0
			? `${iframeDimensions.width}px`
			: defaultWidth;
	return {
		height,
		width
	};
};

export interface IframeWidgetDimensionsParams {
	iframeWidth: string;
	headerHeight: number;
	availableHeight: number;
	actionBarHeight: number;
	cinema?: boolean;
}
export const getIframeWidgetDimensions = ({
	iframeWidth,
	headerHeight,
	availableHeight,
	actionBarHeight,
	cinema
}: IframeWidgetDimensionsParams) => {
	// Height and Width of widget
	const widgetHeight =
		availableHeight > 0
			? `${headerHeight + availableHeight + actionBarHeight}px`
			: '100%';
	const widgetWidth = iframeWidth;
	return {
		height: cinema ? '100vh' : widgetHeight,
		width: cinema ? '100vw' : widgetWidth
	};
};

export interface IframeWidgetControlsParams {
	defaultFullscreen?: boolean;
	defaultCinema?: boolean;
	defaultShowSidemenu?: boolean;
}
/** @description controls for iframe widget that contain a iframe area and other components */
export const useIframeWidgetControls = (
	params?: IframeWidgetControlsParams
) => {
	const {
		defaultFullscreen = false,
		defaultCinema = false,
		defaultShowSidemenu = false
	} = params || {};
	const [fullscreen, setFullscreen] = React.useState(defaultFullscreen);
	const [cinema, setCinema] = React.useState(defaultCinema);
	const [showSidemenu, setShowSidemenu] = React.useState(defaultShowSidemenu);
	return {
		fullscreen,
		setFullscreen,
		cinema,
		setCinema,
		showSidemenu,
		setShowSidemenu
	};
};
