"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var core_1 = require("@material-ui/core");
var styles_1 = require("@material-ui/styles");
var Provider_1 = require("./topLoadingIndicator/Provider");
var TopLoadingIndicator = function (_a) {
    var children = _a.children, isLoadingProps = _a.isLoading;
    var isLoading = Provider_1.useTopLoadingIndicatorValue().isLoading;
    var leavingScreen = styles_1.useTheme().transitions.duration.leavingScreen;
    return (React.createElement(React.Fragment, null,
        React.createElement(core_1.Fade, { in: isLoading || isLoadingProps, timeout: {
                enter: 0,
                exit: leavingScreen,
            }, unmountOnExit: true },
            React.createElement(core_1.LinearProgress, { color: 'secondary' })),
        children));
};
var Provider_2 = require("./topLoadingIndicator/Provider");
Object.defineProperty(exports, "TopLoadingIndicatorProvider", { enumerable: true, get: function () { return Provider_2.default; } });
Object.defineProperty(exports, "useTopLoadingIndicator", { enumerable: true, get: function () { return Provider_2.useTopLoadingIndicator; } });
exports.default = TopLoadingIndicator;
