import { isPast } from 'date-fns';
import { filterMap } from 'materialbet-common';
import { Group } from 'sports/components/eventList/types';

export const getEventGroupsEventsNumber = (items: Group[]) => {
	let total = 0;
	for (const item of items) {
		total += item.items.length || 0;
	}
	return total;
};
export const trimEventGroups = (groups: Group[], limit: number) => {
	if (getEventGroupsEventsNumber(groups) < limit) {
		return groups;
	}
	let total = 0;
	const newGroups = filterMap(
		groups,
		(group) => !!group.id,
		(group) => {
			if (total >= limit) {
				return {
					id: group.id,
					items: []
				};
			}
			const groupItems = group.items.slice(0, limit - total);
			total += groupItems.length;
			return {
				id: group.id,
				items: groupItems
			};
		}
	).filter((group) => group.items.length);
	return newGroups;
};
export const filterEventGroupsEvents = (groups: Group[], events: number[]) => {
	if (events.length === 0) {
		return groups;
	}
	const newGroups = filterMap(
		groups,
		(group) => !!group.id,
		(group) => {
			const newEvents = filterMap(
				group.items || [],
				(event) => !events.includes(event),
				(event) => event
			);
			return {
				id: group.id,
				items: newEvents
			};
		}
	).filter((group) => group.items.length);
	return newGroups;
};

export const isEventStarted = (startTime: string | undefined): boolean => {
	if (!startTime) {
		return false;
	}
	const eventStarted = isPast(new Date(startTime));
	return eventStarted;
};
export const showEventMarkets = (status: string) =>
	['PRE_TRADING', 'TRADING', 'TRADING_LIVE', 'INTERRUPTED'].includes(status);
export const isEventEnded = (status: string) => status === 'RESULTED';
export const isEventTradingSuspended = (status: string) =>
	['AWAITING_RESULTS', 'POST_TRADING'].includes(status);
export const isEventLive = (status: string) =>
	['TRADING_LIVE', 'INTERRUPTED'].includes(status);
export const eventCanShowStream = (status: string) =>
	['TRADING_LIVE', 'INTERRUPTED', 'POST_TRADING'].includes(status);
export const showEventPitchers = (status: string) => status === 'TRADING';
export const parseUrlEventId = (eventIdString?: string) => {
	if (!eventIdString) {
		return;
	}
	const eventId = eventIdString?.replace('e', '');
	return +eventId || undefined;
};

export const parseUrlEventAsLegacyCompetitionId = (eventIdString: string) => {
	if (!eventIdString || !eventIdString.startsWith('c')) {
		return;
	}
	const competitionId = eventIdString?.replace('c', '');
	return +competitionId || undefined;
};
