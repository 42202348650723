"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromoDetailsSection = void 0;
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
var useStyles = core_1.makeStyles(function (theme) {
    var _a, _b;
    return ({
        root: (_a = {},
            _a['& > .MuiBox-root:first-child'] = (_b = {
                    borderTop: "1px solid " + theme.palette.divider,
                    marginTop: 0,
                    padding: theme.spacing(2) + "px 0 " + theme.spacing(1) + "px 0"
                },
                _b[theme.breakpoints.down('xs')] = {
                    marginTop: theme.spacing(2)
                },
                _b),
            _a['& > .MuiBox-root'] = {
                borderTop: "1px solid " + theme.palette.divider,
                marginTop: theme.spacing(1),
                padding: theme.spacing(2) + "px 0 " + theme.spacing(1) + "px 0"
            },
            _a['& > .MuiBox-root:last-child'] = {
                borderBottom: "1px solid " + theme.palette.divider,
                padding: theme.spacing(1) + "px 0 " + theme.spacing(1) + "px 0"
            },
            _a)
    });
});
exports.PromoDetailsSection = function (_a) {
    var children = _a.children;
    var classes = useStyles();
    return React.createElement(core_1.Box, { className: classes.root }, children);
};
