import { Menu, Theme, makeStyles, Divider } from '@material-ui/core';
import * as React from 'react';
import { useLocation } from 'react-router';
import { usePrevious, PlayerRoutes } from 'materialbet-common';
import {
	BuyCurrencyButton,
	QuickDepositButton,
	DepositButton,
	BalanceDetails
} from '@gaming-shell/layout';
import {
	LiveChatMenuItem,
	MyAccountMenuItem,
	MessagesMenuItem,
	BetHistoryMenuItem
} from '../AccountMenuItems';

import { isMobile } from '@gaming-shell/device';

export interface AccountMenuProps {
	anchorEl: HTMLElement | null;
	onClose: () => void;
}

const useMenuStyles = makeStyles({
	paper: {
		minWidth: 265
	},
	list: {
		padding: 0
	}
});

const useStyles = makeStyles((theme: Theme) => ({
	singleColumn: {
		flex: 1
	},
	menuItem: {
		minHeight: 40
	},
	icon: {
		marginRight: theme.spacing(2)
	},
	divider: {
		marginBottom: theme.spacing(1)
	},
	actionButtonWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(1)
	},
	actionButton: {
		flex: 1,
		margin: theme.spacing(1)
	}
}));

const menuOrigins = {
	vertical: 'top',
	horizontal: 'right'
} as const;

const quickDepositButtonEnabled = ENABLE_QUICK_DEPOSIT && !isMobile();
const messagesMenuItemEnabled = INBOX_ENABLED && isMobile();
export const AccountMenu: React.FC<AccountMenuProps> = ({
	anchorEl,
	onClose
}) => {
	const menuClasses = useMenuStyles();
	const classes = useStyles();
	const { pathname } = useLocation();
	const prevPathname = usePrevious(pathname);
	// Should close itself on pathname change
	React.useEffect(() => {
		if (prevPathname !== pathname) {
			onClose();
		}
	}, [pathname, prevPathname, onClose]);

	return (
		<Menu
			anchorEl={anchorEl}
			open={!!anchorEl}
			onClose={onClose}
			keepMounted
			anchorOrigin={menuOrigins}
			transformOrigin={menuOrigins}
			classes={menuClasses}
		>
			<BalanceDetails />
			<Divider className={classes.divider} />
			<MyAccountMenuItem />
			{messagesMenuItemEnabled && <MessagesMenuItem />}
			<LiveChatMenuItem onClick={onClose} />
			<BetHistoryMenuItem />

			{/* Horizontal buttons container */}
			<div className={classes.actionButtonWrapper}>
				<BuyCurrencyButton
					className={classes.actionButton}
					onClick={onClose}
					showDialog={PlayerRoutes.moonpay.matchesExactly()}
				/>
				{!quickDepositButtonEnabled && (
					<DepositButton
						className={classes.actionButton}
						onClick={onClose}
					/>
				)}
				{quickDepositButtonEnabled && (
					<QuickDepositButton
						className={classes.actionButton}
						onClick={onClose}
					/>
				)}
			</div>
		</Menu>
	);
};
