import { isMobile } from '@gaming-shell/device';
import { Translate } from '@gaming-shell/i18n';
import {
	Button,
	InputAdornment,
	makeStyles,
	TextField,
	Theme
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BigNumber from 'bignumber.js';
import * as React from 'react';
import { stakeInputMaxDecimals } from 'sports/config/app';
import { CurrencyVariantConfig } from 'sports/config/currency';
import { getVariantCurrencyValue } from 'sports/utils/currency';
import { handleStakeInputChange, useTrailing } from 'sports/utils/stake';

import { betslipActions } from '../betslipReducer';
import { useBetslipDispatch } from '../hooks';
import { CurrentEditStakeView } from './types';

export interface UiBigBetslipSelectionStakeInputProps {
	stake: number;
	selectionIndex: number;
	disabled?: boolean;
	onStakeChange: (stake: number) => void;
	showAddStake: boolean;
	onAddStakeClick: () => void;
	setCurrentEditStakeView: (view?: CurrentEditStakeView) => void;
	currencyConfig: CurrencyVariantConfig;
}
export type BigBetslipSelectionStakeInputProps = Omit<
	UiBigBetslipSelectionStakeInputProps,
	'onStakeChange'
>;
const useStyles = makeStyles((theme: Theme) => ({
	button: {
		color: theme.palette.primary.main,
		'&:hover': {
			background: 'transparent'
		}
	},
	textInput: {
		textAlign: 'right'
	}
}));

let UiBigBetslipSelectionStakeInput: React.FunctionComponent<UiBigBetslipSelectionStakeInputProps> = (
	props
) => {
	const {
		selectionIndex,
		stake,
		disabled,
		onStakeChange,
		setCurrentEditStakeView,
		currencyConfig,
		showAddStake,
		onAddStakeClick
	} = props;
	const classes = useStyles();
	const [trailing, setTrailing] = useTrailing(stake);
	const variantStake = getVariantCurrencyValue(currencyConfig, stake);
	const stakeString = `${new BigNumber(variantStake).toFixed()}${
		trailing.trail
	}`;
	const handleChange = React.useCallback(
		handleStakeInputChange(
			currencyConfig,
			onStakeChange,
			setTrailing,
			stakeInputMaxDecimals
		),
		[onStakeChange, currencyConfig]
	);

	const onFieldClick = React.useCallback(() => {
		if (isMobile()) {
			setCurrentEditStakeView({ straight: { selectionIndex } });
		}
	}, [selectionIndex, setCurrentEditStakeView]);

	if (!stake && showAddStake) {
		return (
			<Button className={classes.button} onClick={onAddStakeClick}>
				<AddIcon /> <Translate label="betting.stake" ns="sports" />
			</Button>
		);
	}
	return (
		<TextField
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						{currencyConfig.label}
					</InputAdornment>
				)
			}}
			inputProps={{
				className: classes.textInput
			}}
			onChange={handleChange}
			value={stakeString}
			disabled={disabled || isMobile()}
			onClick={onFieldClick}
		/>
	);
};
UiBigBetslipSelectionStakeInput = React.memo(UiBigBetslipSelectionStakeInput);

let BigBetslipSelectionStakeInput: React.FunctionComponent<BigBetslipSelectionStakeInputProps> = (
	props
) => {
	const { selectionIndex } = props;
	const dispatch = useBetslipDispatch();
	const handleStakeChange = React.useCallback(
		(stake: number) =>
			dispatch(
				betslipActions.updateSelectionStake({ selectionIndex, stake })
			),
		[selectionIndex, dispatch]
	);
	return (
		<UiBigBetslipSelectionStakeInput
			onStakeChange={handleStakeChange}
			{...props}
		/>
	);
};
BigBetslipSelectionStakeInput = React.memo(BigBetslipSelectionStakeInput);
export { UiBigBetslipSelectionStakeInput, BigBetslipSelectionStakeInput };
