import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import BigNumber from 'bignumber.js';
import * as React from 'react';
import { getVariantCurrencyValue } from 'sports/utils/currency';

import { CurrencyVariantConfig } from '../../../config/currency';
import { BetslipErrorSlip } from '../BetslipErrorSlip';
import {
	getIsSubmitting,
	getSelection,
	getStraightsRemainingReturn,
	getStraightsRemainingStake
} from '../betslipSelectors/betslipSelectors';
import { BetslipStakeFormHeader } from '../BetslipStakeFormHeader';
import { useBetslipSelector } from '../hooks/useBetslipSelector';
import { QuickBetslipStakeInputForm } from '../QuickBetslip/QuickBetslipStakeInputForm';
import { BetslipReducerStatus } from '../types';
import { BigBetslipSinglesSummary } from './BigBetslipSinglesSummary';

const useStyles = makeStyles((theme: Theme) => ({
	root: { padding: theme.spacing(2) },
	header: { paddingBottom: theme.spacing(1) }
}));
export interface UiBigBetslipEditSelectionStakeFormProps {
	onSetAllStakes: (stake: number) => void;
	onSetSelectionStake: (stake: number) => void;
	onCloseEditStake: () => void;
	/** @description total stake if the given selection is excluded */
	remainingTotalStake: number;
	/** @description return amount if the selection excluded */
	remainingTotalReturn: number;
	price: number;
	minStake: number;
	maxStake: number;
	initialStake?: number;
	currencyConfig: CurrencyVariantConfig;
	errors: BetslipReducerStatus;
	isSubmitting: boolean;
	outcomeName: string;
	eventName: string;
}

let UiBigBetslipEditSelectionStakeForm: React.FunctionComponent<UiBigBetslipEditSelectionStakeFormProps> = (
	props
) => {
	const {
		onSetSelectionStake,
		onSetAllStakes,
		remainingTotalStake,
		remainingTotalReturn,
		price,
		currencyConfig,
		errors,
		outcomeName,
		eventName,
		minStake,
		maxStake,
		onCloseEditStake,
		isSubmitting,
		initialStake = 0
	} = props;
	const [stake, setStake] = React.useState(initialStake);
	const classes = useStyles();

	const totalStake = new BigNumber(remainingTotalStake)
		.plus(stake)
		.toNumber();
	const totalReturn = new BigNumber(stake)
		.multipliedBy(price)
		.plus(remainingTotalReturn)
		.toNumber();
	const totalStakeVariant = getVariantCurrencyValue(
		currencyConfig,
		totalStake
	);
	const totalReturnVariant = getVariantCurrencyValue(
		currencyConfig,
		totalReturn
	);
	const onConfirmClick = () => {
		onSetSelectionStake(stake);
	};
	const onSecondaryActionClick = () => {
		onSetAllStakes(stake);
	};
	return (
		<>
			<BetslipErrorSlip
				errors={errors}
				minStake={minStake}
				maxStake={maxStake}
			/>
			<div className={classes.root}>
				<BetslipStakeFormHeader
					disabled={false}
					close={onCloseEditStake}
					className={classes.header}
				>
					<Box display="flex" flexDirection="column">
						<Typography variant="h6">{outcomeName}</Typography>
						<Typography>{eventName}</Typography>
					</Box>
				</BetslipStakeFormHeader>
				<QuickBetslipStakeInputForm
					stake={stake}
					minStake={minStake}
					maxStake={maxStake}
					onChange={setStake}
					currencyConfig={currencyConfig}
					onStakeDelete={() => setStake(0)}
					onConfirmClick={onConfirmClick}
					onSecondaryActionClick={onSecondaryActionClick}
					isSubmitting={isSubmitting}
					variant="editStake"
					selectionResult={
						<BigBetslipSinglesSummary
							totalStake={totalStakeVariant.toString()}
							totalReturn={totalReturnVariant.toString()}
							currencyLabel={currencyConfig.label}
						/>
					}
				/>
			</div>
		</>
	);
};
UiBigBetslipEditSelectionStakeForm = React.memo(
	UiBigBetslipEditSelectionStakeForm
);

export type BigBetslipEditSelectionStakeFormProps = Omit<
	UiBigBetslipEditSelectionStakeFormProps,
	| 'minStake'
	| 'maxStake'
	| 'price'
	| 'errors'
	| 'remainingTotalStake'
	| 'remainingTotalReturn'
	| 'outcomeName'
	| 'eventName'
	| 'isSubmitting'
> & { selectionIndex: number };

let BigBetslipEditSelectionStakeForm: React.FunctionComponent<BigBetslipEditSelectionStakeFormProps> = (
	props
) => {
	const { selectionIndex, ...other } = props;
	const selection = useBetslipSelector((state) =>
		getSelection(state, { selectionIndex })
	);
	const remainingTotalStake = useBetslipSelector((state) =>
		getStraightsRemainingStake(state, { selectionIndex })
	);
	const remainingTotalReturn = useBetslipSelector((state) =>
		getStraightsRemainingReturn(state, { selectionIndex })
	);
	const isSubmitting = useBetslipSelector(getIsSubmitting);
	if (!selection) {
		return null;
	}
	return (
		<UiBigBetslipEditSelectionStakeForm
			minStake={selection.minStake}
			maxStake={selection.maxStake}
			price={selection.price}
			errors={selection.status}
			outcomeName={selection.outcomeName}
			eventName={selection.eventName}
			remainingTotalStake={remainingTotalStake}
			remainingTotalReturn={remainingTotalReturn}
			isSubmitting={isSubmitting}
			initialStake={selection.stake}
			{...other}
		/>
	);
};
BigBetslipEditSelectionStakeForm = React.memo(BigBetslipEditSelectionStakeForm);

export { UiBigBetslipEditSelectionStakeForm, BigBetslipEditSelectionStakeForm };
