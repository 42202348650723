import { parseOdds } from 'sports/utils/odds';
import { SelectionUpdate } from 'sports/utils/pusherUtils';

import { betslipActions } from './betslipReducer';
import { BetslipStatusFlags } from './betslipReducer/BetslipStatusFlags';
import { useBetslipDispatch } from './hooks/useBetslipDispatch';
import { BetslipUpdateSelectionPayload } from './types';

export const createOnSelectionUpdate = (
	dispatch: ReturnType<typeof useBetslipDispatch>
) => (selection: SelectionUpdate) => {
	const { status, ...other } = selection;
	const payload: BetslipUpdateSelectionPayload = {
		...other,
		status: BetslipStatusFlags.Default
	};

	if (status === 'SELECTION_DISABLED') {
		payload.status = BetslipStatusFlags.MarketSuspended;
	}
	if (payload.price) {
		payload.price = parseOdds(payload.price);
	}
	payload.minStake = payload.minStake || 0;
	dispatch(betslipActions.changeSelection(payload));
};
