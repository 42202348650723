import { Translate } from '@gaming-shell/i18n';
import {
	Checkbox,
	ListItemIcon,
	ListItemText,
	MenuItem,
	makeStyles
} from '@material-ui/core';
import * as React from 'react';

import { betslipActions } from './betslipReducer';
import { getAcceptBetterOdds } from './betslipSelectors/betslipSelectors';
import { useBetslipSelector } from './hooks';
import { useBetslipDispatch } from './hooks/useBetslipDispatch';

const useStyles = makeStyles({
	checkbox: {
		padding: 0
	}
});
export const AcceptBetterOdds: React.FunctionComponent<unknown> = () => {
	const acceptBetterOdds = useBetslipSelector(getAcceptBetterOdds);
	const dispatch = useBetslipDispatch();
	const onClick = () => {
		dispatch(betslipActions.setAcceptBetterOdds(!acceptBetterOdds));
	};
	const classes = useStyles();
	return (
		<MenuItem onClick={onClick}>
			<ListItemIcon>
				<Checkbox
					className={classes.checkbox}
					edge="end"
					checked={acceptBetterOdds}
					tabIndex={-1}
					disableRipple={true}
					color="primary"
					size="small"
					disableTouchRipple={true}
					disableFocusRipple={true}
				/>
			</ListItemIcon>
			<ListItemText>
				<Translate label="betting.acceptBetterOdds" ns="sports" />
			</ListItemText>
		</MenuItem>
	);
};
