import { withHook } from 'materialbet-common';
import { EventPromotionPlayerCount } from 'sports/components/EventPromotionPlayerCount';

export const ZeroMarginSlotsCounter = withHook(
	({ eventId, hideFooter }: { eventId: number; hideFooter?: boolean }) => ({
		eventId,
		hideFooter,
		promotion: 'ZERO_MARGIN'
	})
)(EventPromotionPlayerCount);
