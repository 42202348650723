import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

const Other: React.FunctionComponent<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<path
				fill={props.fill}
				fillRule="nonzero"
				d="M7.775 10.917c.723 0 1.308.585 1.308 1.308v4.8c0 .723-.585 1.308-1.308 1.308h-4.8a1.308 1.308 0 0 1-1.308-1.308v-4.8c0-.723.585-1.308 1.308-1.308h4.8zm9.25 0c.723 0 1.308.585 1.308 1.308v4.8c0 .723-.585 1.308-1.308 1.308h-4.8a1.308 1.308 0 0 1-1.308-1.308v-4.8c0-.723.585-1.308 1.308-1.308h4.8zm-9.25 1.25h-4.8a.058.058 0 0 0-.058.058v4.8c0 .032.026.058.058.058h4.8a.058.058 0 0 0 .058-.058v-4.8a.058.058 0 0 0-.058-.058zm9.25 0h-4.8a.058.058 0 0 0-.058.058v4.8c0 .032.026.058.058.058h4.8a.058.058 0 0 0 .058-.058v-4.8a.058.058 0 0 0-.058-.058zm-9.25-10.5c.723 0 1.308.585 1.308 1.308v4.8c0 .723-.585 1.308-1.308 1.308h-4.8a1.308 1.308 0 0 1-1.308-1.308v-4.8c0-.723.585-1.308 1.308-1.308h4.8zm6.85 0a3.708 3.708 0 1 1 0 7.416 3.708 3.708 0 0 1 0-7.416zm-6.85 1.25h-4.8a.058.058 0 0 0-.058.058v4.8c0 .032.026.058.058.058h4.8a.058.058 0 0 0 .058-.058v-4.8a.058.058 0 0 0-.058-.058zm6.85 0a2.458 2.458 0 1 0 0 4.916 2.458 2.458 0 0 0 0-4.916z"
			/>
		</SvgIcon>
	);
};
export default Other;
