import * as React from 'react';
import { performSignOut } from 'auth/api/token';
import { useSetAuth } from 'auth/components/AuthProvider/AuthContext';

export const useSignOut = (callback: () => void): boolean => {
	const setAuth = useSetAuth();

	const [hasError, setHasError] = React.useState(false);

	React.useEffect(() => {
		performSignOut().then((res) => {
			if (res.status === 200) {
				setAuth(false);
				callback();
			} else {
				setHasError(true);
			}
		});
	}, [setAuth, callback]);
	return hasError;
};
