import {
	BaseRoutes,
	CasinoRoutes,
	EsportsRoutes,
	PlayerRoutes,
	SportsRoutes
} from '../routes';

const rootRoutes = [BaseRoutes.root, CasinoRoutes.root, SportsRoutes.root];

/** @description it is a function return the home page of the current route (sports,esports, casino, player) */
export const getRootRouteOfCurrentRoute = () => {
	const isCasinoRoute = CasinoRoutes.root.matches();
	if (isCasinoRoute) {
		return CasinoRoutes.root.create();
	}
	const isSportsRoute = SportsRoutes.root.matches();
	if (isSportsRoute) {
		return SportsRoutes.root.create();
	}
	const isEsportsRoute =
		ENABLE_ESPORTS_SECTION && EsportsRoutes.root.matches();
	if (isEsportsRoute) {
		return EsportsRoutes.root.create();
	}
	const isCoreRoute = PlayerRoutes.root.matches();
	if (isCoreRoute) {
		return PlayerRoutes.root.create();
	}
	return BaseRoutes.root.create();
};

/** @description it is a function to check if its the root page of any domain */
export const isRootRoute = () =>
	rootRoutes.some((rootRoute) => rootRoute.matchesExactly());
