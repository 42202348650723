import * as React from 'react';
import { SportsRoutes } from 'materialbet-common';
import { Translate } from '@gaming-shell/i18n';
import { MyBetsIcon } from 'materialbet-icons';

import { AccountMenuItem } from '../AccountMenuItem';

export const BetHistoryMenuItem: React.FC = () => (
	<AccountMenuItem href={SportsRoutes.bets.create()}>
		<MyBetsIcon />
		<Translate label="descriptions.betHistory" ns="common" />
	</AccountMenuItem>
);
