import { warning } from '@gaming-shell/logging';
import { api } from '@materialbet-core/api';
import {
	usePlayerCurrenciesAvailable,
	usePlayerIsOnboarded,
	usePlayerLoggedIn,
	usePlayerMinDepositAmounts,
	useUpdatePlayer
} from '@materialbet-core/player';
import { isFiatCashier } from 'core/pages/Account/cashierUtils';
import { CryptoCurrency, usePrevious } from 'materialbet-common';
import * as React from 'react';

interface CurrencyMinDepositAmount {
	currency: CryptoCurrency;
	minDepositAmount: number;
}

export const useLoadMinDepositAmountsEffect = () => {
	const updatePlayer = useUpdatePlayer();

	const isLoggedIn = usePlayerLoggedIn();
	const minDepositAmounts = usePlayerMinDepositAmounts();
	const isPlayerOnboarded = usePlayerIsOnboarded();

	const availableCurrencies = usePlayerCurrenciesAvailable();
	const previousAvailableCurrencies = usePrevious(availableCurrencies);

	const fetchMinDepositAmount = React.useCallback(
		async (currency: CryptoCurrency) => {
			return api
				.getDepositProviders(currency)
				.then((response) => {
					if (!response.result) {
						return {
							currency: [currency],
							minDepositAmount: response.providers?.[0]?.minimum
						};
					} else if (response.result === 'VALIDATION_ERROR') {
						warning(
							'Wrong currency passed to API when fetching minimum deposit.',
							currency
						);
					}

					return undefined;
				})
				.catch(() => {
					return undefined;
				});
		},
		[]
	);

	/**
	 * Loading min deposit amount when  all requirements are met:
	 * 1) logged in player
	 * 2) crypto currency
	 * 3) min deposit amount for currency isn't already loaded
	 * 4) player is already onboarded
	 *
	 * previousAvailableCurrencies and availableCurrencies comparison to prevent cyclic player update
	 */
	React.useEffect(() => {
		if (
			isLoggedIn &&
			isPlayerOnboarded &&
			previousAvailableCurrencies !== availableCurrencies
		) {
			const updatedMinDepositAmountsPromises = availableCurrencies
				.filter(
					(currency) =>
						!isFiatCashier(currency) && !minDepositAmounts[currency]
				)
				.map((currency) =>
					fetchMinDepositAmount(currency as CryptoCurrency)
				);
			Promise.all(updatedMinDepositAmountsPromises).then((results) => {
				const definedResults: CurrencyMinDepositAmount[] = (results.filter(
					(el) => !!el && !!el.minDepositAmount
				) as unknown) as CurrencyMinDepositAmount[];
				const updatedMinDepositAmounts = definedResults.reduce(
					(acc, { currency, minDepositAmount }) => {
						acc[currency] = minDepositAmount;

						return acc;
					},
					{} as Partial<Record<CryptoCurrency, number>>
				);

				updatePlayer((player) => ({
					...player,
					minDepositAmounts: {
						...player.minDepositAmounts,
						...updatedMinDepositAmounts
					}
				}));
			});
		}
	}, [
		fetchMinDepositAmount,
		isLoggedIn,
		isPlayerOnboarded,
		availableCurrencies,
		minDepositAmounts,
		updatePlayer,
		previousAvailableCurrencies
	]);
};
