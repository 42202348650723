"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchNoResultsMessage = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
var react_instantsearch_dom_1 = require("react-instantsearch-dom");
var materialbet_common_1 = require("materialbet-common");
var useStyles = core_1.makeStyles(function (theme) {
    var _a;
    return core_1.createStyles((_a = {
            root: {
                padding: theme.spacing(1)
            }
        },
        _a[theme.breakpoints.down('sm')] = {
            root: {
                textAlign: 'center'
            }
        },
        _a));
});
var SearchNoResultsMessage = function (_a) {
    var _b, _c;
    var allSearchResults = _a.allSearchResults, isSearchStalled = _a.isSearchStalled, filters = _a.filters, searching = _a.searching;
    var classes = useStyles();
    var hits = (allSearchResults &&
        typeof allSearchResults === 'object' && ((_b = materialbet_common_1.flatten(Object.entries(allSearchResults).map(function (_a) {
        var _ = _a[0], searchResults = _a[1];
        return searchResults === null || searchResults === void 0 ? void 0 : searchResults.hits;
    }))) === null || _b === void 0 ? void 0 : _b.filter(function (hit) {
        if (((filters === null || filters === void 0 ? void 0 : filters.length) || 0) === 0) {
            return true;
        }
        var category = materialbet_common_1.mapTagsToCategory(hit === null || hit === void 0 ? void 0 : hit._tags);
        var includedInFilter = filters === null || filters === void 0 ? void 0 : filters.find(function (filter) {
            return filter.name === category ||
                filter.name === (hit === null || hit === void 0 ? void 0 : hit.sportKey);
        });
        return includedInFilter;
    }))) ||
        [];
    if (((_c = allSearchResults === null || allSearchResults === void 0 ? void 0 : allSearchResults.hits) === null || _c === void 0 ? void 0 : _c.length) > 0 ||
        hits.length > 0 ||
        isSearchStalled ||
        searching)
        return null;
    return (React.createElement(core_1.Typography, { classes: classes, "aria-label": "search-no-results-message" },
        React.createElement(i18n_1.Translate, { label: "application.descriptions.searchNoResultsFound", ns: "common" })));
};
var ConnectedSearchNoResultsMessage = react_instantsearch_dom_1.connectHits(react_instantsearch_dom_1.connectStateResults(SearchNoResultsMessage));
exports.SearchNoResultsMessage = ConnectedSearchNoResultsMessage;
