/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
	CompetitionWithCategory,
	CompetitionWithCategoryFromJSON,
	CompetitionWithCategoryFromJSONTyped,
	CompetitionWithCategoryToJSON,
	EventMedia,
	EventMediaFromJSON,
	EventMediaFromJSONTyped,
	EventMediaToJSON,
	EventMetadata,
	EventMetadataFromJSON,
	EventMetadataFromJSONTyped,
	EventMetadataToJSON,
	Identifier,
	IdentifierFromJSON,
	IdentifierFromJSONTyped,
	IdentifierToJSON,
	Market,
	MarketFromJSON,
	MarketFromJSONTyped,
	MarketToJSON,
	Player,
	PlayerFromJSON,
	PlayerFromJSONTyped,
	PlayerToJSON,
	Stream,
	StreamFromJSON,
	StreamFromJSONTyped,
	StreamToJSON,
	TeamIdentifier,
	TeamIdentifierFromJSON,
	TeamIdentifierFromJSONTyped,
	TeamIdentifierToJSON
} from './';

/**
 *
 * @export
 * @interface EventResponse
 */
export interface EventResponse {
	/**
	 *
	 * @type {number}
	 * @memberof EventResponse
	 */
	availability?: number;
	/**
	 *
	 * @type {TeamIdentifier}
	 * @memberof EventResponse
	 */
	away?: TeamIdentifier;
	/**
	 *
	 * @type {number}
	 * @memberof EventResponse
	 */
	betradarId?: number;
	/**
	 *
	 * @type {boolean}
	 * @memberof EventResponse
	 */
	blank?: boolean;
	/**
	 *
	 * @type {CompetitionWithCategory}
	 * @memberof EventResponse
	 */
	competition?: CompetitionWithCategory;
	/**
	 * cutoff time is the same as the deprecated startTime
	 * @type {string}
	 * @memberof EventResponse
	 */
	cutoffTime?: string;
	/**
	 *
	 * @type {TeamIdentifier}
	 * @memberof EventResponse
	 */
	home?: TeamIdentifier;
	/**
	 *
	 * @type {number}
	 * @memberof EventResponse
	 */
	id?: number;
	/**
	 *
	 * @type {string}
	 * @memberof EventResponse
	 */
	key?: string;
	/**
	 *
	 * @type {{ [key: string]: Market; }}
	 * @memberof EventResponse
	 */
	markets?: { [key: string]: Market };
	/**
	 *
	 * @type {EventMedia}
	 * @memberof EventResponse
	 */
	media?: EventMedia;
	/**
	 *
	 * @type {EventMetadata}
	 * @memberof EventResponse
	 */
	metadata?: EventMetadata;
	/**
	 *
	 * @type {string}
	 * @memberof EventResponse
	 */
	name?: string;
	/**
	 *
	 * @type {{ [key: string]: Player; }}
	 * @memberof EventResponse
	 */
	players?: { [key: string]: Player };
	/**
	 *
	 * @type {Array<string>}
	 * @memberof EventResponse
	 */
	promotions?: Array<string>;
	/**
	 *
	 * @type {number}
	 * @memberof EventResponse
	 */
	sequence?: number;
	/**
	 *
	 * @type {Identifier}
	 * @memberof EventResponse
	 */
	sport?: Identifier;
	/**
	 * Below are deprecated fields
	 * @type {string}
	 * @memberof EventResponse
	 */
	startTime?: string;
	/**
	 *
	 * @type {string}
	 * @memberof EventResponse
	 */
	status?: string;
	/**
	 *
	 * @type {Array<Stream>}
	 * @memberof EventResponse
	 */
	streams?: Array<Stream>;
	/**
	 *
	 * @type {number}
	 * @memberof EventResponse
	 */
	submarketsCount?: number;
	/**
	 *
	 * @type {number}
	 * @memberof EventResponse
	 */
	type?: number;
}

export function EventResponseFromJSON(json: any): EventResponse {
	return EventResponseFromJSONTyped(json, false);
}

export function EventResponseFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): EventResponse {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		availability: !exists(json, 'availability')
			? undefined
			: json['availability'],
		away: !exists(json, 'away')
			? undefined
			: TeamIdentifierFromJSON(json['away']),
		betradarId: !exists(json, 'betradarId')
			? undefined
			: json['betradarId'],
		blank: !exists(json, 'blank') ? undefined : json['blank'],
		competition: !exists(json, 'competition')
			? undefined
			: CompetitionWithCategoryFromJSON(json['competition']),
		cutoffTime: !exists(json, 'cutoffTime')
			? undefined
			: json['cutoffTime'],
		home: !exists(json, 'home')
			? undefined
			: TeamIdentifierFromJSON(json['home']),
		id: !exists(json, 'id') ? undefined : json['id'],
		key: !exists(json, 'key') ? undefined : json['key'],
		markets: !exists(json, 'markets')
			? undefined
			: mapValues(json['markets'], MarketFromJSON),
		media: !exists(json, 'media')
			? undefined
			: EventMediaFromJSON(json['media']),
		metadata: !exists(json, 'metadata')
			? undefined
			: EventMetadataFromJSON(json['metadata']),
		name: !exists(json, 'name') ? undefined : json['name'],
		players: !exists(json, 'players')
			? undefined
			: mapValues(json['players'], PlayerFromJSON),
		promotions: !exists(json, 'promotions')
			? undefined
			: json['promotions'],
		sequence: !exists(json, 'sequence') ? undefined : json['sequence'],
		sport: !exists(json, 'sport')
			? undefined
			: IdentifierFromJSON(json['sport']),
		startTime: !exists(json, 'startTime') ? undefined : json['startTime'],
		status: !exists(json, 'status') ? undefined : json['status'],
		streams: !exists(json, 'streams')
			? undefined
			: (json['streams'] as Array<any>).map(StreamFromJSON),
		submarketsCount: !exists(json, 'submarketsCount')
			? undefined
			: json['submarketsCount'],
		type: !exists(json, 'type') ? undefined : json['type']
	};
}

export function EventResponseToJSON(value?: EventResponse | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		availability: value.availability,
		away: TeamIdentifierToJSON(value.away),
		betradarId: value.betradarId,
		blank: value.blank,
		competition: CompetitionWithCategoryToJSON(value.competition),
		cutoffTime: value.cutoffTime,
		home: TeamIdentifierToJSON(value.home),
		id: value.id,
		key: value.key,
		markets:
			value.markets === undefined
				? undefined
				: mapValues(value.markets, MarketToJSON),
		media: EventMediaToJSON(value.media),
		metadata: EventMetadataToJSON(value.metadata),
		name: value.name,
		players:
			value.players === undefined
				? undefined
				: mapValues(value.players, PlayerToJSON),
		promotions: value.promotions,
		sequence: value.sequence,
		sport: IdentifierToJSON(value.sport),
		startTime: value.startTime,
		status: value.status,
		streams:
			value.streams === undefined
				? undefined
				: (value.streams as Array<any>).map(StreamToJSON),
		submarketsCount: value.submarketsCount,
		type: value.type
	};
}
