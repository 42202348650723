import { Typography } from '@material-ui/core';
import * as React from 'react';

export interface PromoDetailsItemProps {
	title?: React.ReactNode;
	value?: React.ReactNode;
}

export const PromoDetailsItem: React.FunctionComponent<PromoDetailsItemProps> = ({
	title,
	value
}) => (
	<>
		<Typography variant="body2" color="textSecondary">
			{title}
		</Typography>
		<Typography variant="h6">{value}</Typography>
	</>
);
