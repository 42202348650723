"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoonPayApplePayButton = void 0;
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
var i18n_1 = require("@gaming-shell/i18n");
var materialbet_icons_1 = require("materialbet-icons");
var useStyles = core_1.makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(1.5, 0),
        backgroundColor: theme.palette.type === 'dark'
            ? theme.palette.common.white
            : theme.palette.common.black,
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.type === 'dark'
            ? theme.palette.common.black
            : theme.palette.common.white
    },
    resetLink: {
        textDecoration: 'none',
        color: 'inherit',
        width: '100%'
    },
    icon: {
        width: theme.spacing(6),
        height: theme.spacing(6)
    },
    text: {
        fontFamily: '-apple-system',
        paddingRight: theme.spacing(0.75),
        fontSize: '1.25em',
        fontWeight: theme.typography.fontWeightLight
    }
}); });
exports.MoonPayApplePayButton = function (_a) {
    var url = _a.url;
    var classes = useStyles();
    return (
    // That's intended to use normal link here as we want to push out user to moonpay page
    React.createElement("a", { href: url, target: "_blank", className: classes.resetLink },
        React.createElement("div", { className: classes.root },
            React.createElement(core_1.Typography, { variant: "subtitle2", className: classes.text },
                React.createElement(i18n_1.Translate, { label: "application.descriptions.buyWithAProvider" })),
            React.createElement(materialbet_icons_1.ApplePay, { className: classes.icon }))));
};
