import { getPlatform } from '@gaming-shell/device';
import { useLanguage } from '@gaming-shell/i18n';
import {
	usePlayerCurrency,
	usePlayerLoading,
	usePlayerLoggedIn,
	usePlayerUuid
} from '@materialbet-core/player';
import { GameEntity } from 'casino/casinoSchemas';
import { gamesPerSection } from 'casino/config/lobbyConfig';
import {
	actions,
	GameParams,
	GamesParams,
	loads,
	MyGamesParams
} from 'casino/modules/gamesModule';
import { getLoadedOnce } from 'casino/selectors/loadingSelectors';
import { getMyGameList, getMyGamesCount } from 'casino/selectors/metaSelectors';
import { isIntegerString } from 'casino/util/isIntegerString';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeepReadonly } from 'utility-types';

export type GamesHooksParams = Omit<
	GamesParams,
	'playerUuid' | 'platform' | 'currency' | 'language'
>;
export type MyGamesHooksParams = Omit<
	MyGamesParams,
	'playerUuid' | 'platform' | 'currency' | 'language'
>;
export type StudioGamesHooksParams = Pick<
	GamesParams,
	'studioKey' | 'page' | 'pageSize'
>;
export type GameHooksParams = Omit<
	GameParams,
	'playerUuid' | 'platform' | 'currency' | 'language'
>;
const lobbyGamesParams: Pick<
	GamesParams,
	'category' | 'pageSize' | 'page' | 'lobby'
> = {
	category: 'all',
	pageSize: gamesPerSection,
	page: 1,
	lobby: true
};
const defaultGamesParams: Pick<
	GamesParams,
	'category' | 'pageSize' | 'page'
> = {
	category: 'all',
	pageSize: gamesPerSection,
	page: 1
};
export const useGamesFixedParams = () => {
	const playerUuid = usePlayerUuid();
	const platform = getPlatform();
	const currency = usePlayerCurrency();
	const language = useLanguage();
	return {
		playerUuid,
		platform,
		currency,
		language
	};
};

export const useGamesParams = (params: GamesHooksParams) => {
	const fixedParams = useGamesFixedParams();
	const allParams = {
		...params,
		...fixedParams
	};
	return allParams;
};

export const useMyGamesParams = (params: MyGamesHooksParams): MyGamesParams => {
	const fixedParams = useGamesFixedParams();
	const allParams = {
		...params,
		...fixedParams
	};
	return allParams;
};

export const useGameParams = (params: GameHooksParams): GameParams => {
	const fixedParams = useGamesFixedParams();
	const allParams = {
		...params,
		...fixedParams
	};
	return allParams;
};
export const getLobbyGamesParams = (
	params: ReturnType<typeof useGamesFixedParams> &
		Omit<
			GamesHooksParams,
			'pageSize' | 'category' | 'studioKey' | 'sort' | 'lobby' | 'page'
		>
) => ({
	...params,
	...lobbyGamesParams
});
export const useLobbyGamesParams = (
	params: Omit<
		GamesHooksParams,
		'pageSize' | 'category' | 'studioKey' | 'sort' | 'lobby' | 'page'
	>
): GamesParams =>
	useGamesParams({
		...params,
		...lobbyGamesParams
	});

export const useUpdateFavorite = () => {
	const playerUuid = usePlayerUuid();
	const dispatch = useDispatch();
	return (gameId: string, isFavorite?: boolean) => {
		dispatch(
			actions.postFavorite({
				gameId,
				playerUuid,
				isFavorite: !!isFavorite
			})
		);
	};
};

export const isLiveGame = (game?: DeepReadonly<GameEntity>) =>
	!!game?.live || !!(game && !isIntegerString(game.id)); // Workaround when there is no boolean given

/** @desctiption fetch games by given params */
export const useDispatchGames = (params: GamesHooksParams) => {
	const dispatch = useDispatch();
	const playerIsLoading = usePlayerLoading();
	const allParams = useGamesParams({
		...defaultGamesParams,
		...params
	});
	const isLoaded = useSelector(getLoadedOnce(loads.games(allParams)));
	const {
		playerUuid,
		category,
		platform,
		currency,
		live,
		language,
		page,
		pageSize,
		sectionId,
		studioKey,
		list,
		sort,
		lobby,
		themeKeys
	} = allParams;
	React.useEffect(() => {
		if (playerIsLoading || isLoaded) {
			return;
		}
		dispatch(
			actions.fetchGames({
				playerUuid,
				category,
				platform,
				currency,
				live,
				language,
				page,
				pageSize,
				sectionId,
				studioKey,
				list,
				sort,
				lobby,
				themeKeys
			})
		);
	}, [
		playerIsLoading,
		playerUuid,
		category,
		platform,
		currency,
		live,
		language,
		page,
		pageSize,
		sectionId,
		list,
		studioKey,
		sort,
		lobby,
		isLoaded,
		dispatch,
		themeKeys
	]);
};

/** @desctiption fetch my games by given params, return loading state, count and game ids */
export const useMyGames = (params: MyGamesHooksParams) => {
	const playerIsLoading = usePlayerLoading();
	const playerIsLoggedIn = usePlayerLoggedIn();
	const playerIsLoggedOut = !playerIsLoading && !playerIsLoggedIn;
	const dispatch = useDispatch();
	const myGamesParams = useMyGamesParams(params);
	const {
		playerUuid,
		platform,
		currency,
		language,
		page,
		pageSize
	} = myGamesParams;
	const gameIds = useSelector(getMyGameList(myGamesParams));
	const gamesLoaded = useSelector(
		getLoadedOnce(loads.myGames(myGamesParams))
	);
	const gamesCount = useSelector(
		getMyGamesCount({ ...myGamesParams, page: page > 1 ? page - 1 : 1 })
	);
	const dispatchGames = React.useCallback(
		(page: number) => {
			dispatch(
				actions.fetchMyGames({
					playerUuid,
					platform,
					currency,
					language,
					page,
					pageSize
				})
			);
		},
		[playerUuid, platform, currency, language, dispatch, pageSize]
	);
	React.useEffect(() => {
		if (playerIsLoading || playerIsLoggedOut) {
			return;
		}
		dispatchGames(page);
	}, [playerIsLoading, page, playerUuid, dispatchGames, playerIsLoggedOut]);
	return {
		gameIds,
		gamesCount,
		gamesLoaded
	};
};
