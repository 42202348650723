import { useTranslation } from '@gaming-shell/i18n';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {
	usePlayerCurrency,
	usePlayerCurrencyVariant,
	useUpdatePlayer
} from '@materialbet-core/player';
import {
	getCurrencyConfig,
	getCurrencyVariantConfig,
	Select,
	useSnackbar,
	withHook
} from 'materialbet-common';
import * as React from 'react';

import { persistCurrencyVariant } from '../../utils/cookies';

export interface CurrencyVariantSelectProps {
	selectClassName?: string;
	inputClassName?: string;
	rootClassName?: string;
	disabled?: boolean;
	fullWidth?: boolean;
}
// material-uis outlined input uses a fieldset with a hardcoded 125px padding,
// there will be a gap between the actual select field and the wrapping outline
// if the values of the select are far smaller than that
const style = { minWidth: 125 };
export interface SelectProps {
	value: string;
	onChange: (value: string) => void;
	options: Array<{ value: string; name: string }>;
}
export const CurrencyVariantSelectUi: React.FC<
	CurrencyVariantSelectProps & SelectProps
> = ({
	rootClassName,
	inputClassName,
	selectClassName,
	fullWidth,
	disabled,
	value,
	options,
	onChange
}) => {
	return (
		<Select
			className={rootClassName}
			value={value}
			onChange={onChange}
			name="preferredCurrencyDisplay"
			variant="outlined"
			disabled={disabled || options.length <= 1}
			fullWidth={fullWidth}
			input={
				<OutlinedInput
					style={style}
					labelWidth={300}
					notched={false}
					className={inputClassName}
				/>
			}
			classes={{
				select: selectClassName
			}}
			options={options}
		/>
	);
};
export const useCurrencyVariantSelect = (): SelectProps => {
	const { enqueueSnackbar } = useSnackbar();
	const trans = useTranslation();
	const currency = usePlayerCurrency();
	const currencyConfigVariants = Object.keys(
		getCurrencyConfig(currency)?.variants || []
	);
	const updatePlayer = useUpdatePlayer();
	const currencyVariant = usePlayerCurrencyVariant();

	const onChange = React.useCallback(
		(newVariant: string) => {
			persistCurrencyVariant(newVariant);
			updatePlayer((player) => ({
				...player,
				currencyVariant: newVariant
			}));
			enqueueSnackbar(
				trans('player:preferences.preferredCurrencyDisplaySuccess', {
					variant: newVariant
				})
			);
		},
		[updatePlayer, enqueueSnackbar, trans]
	);

	const variantOptions = currencyConfigVariants.map((variant) => ({
		value: variant,
		name: variant
	}));

	const currentValidVariant = getCurrencyVariantConfig(
		currency,
		currencyVariant
	).label;
	return {
		onChange,
		value: currentValidVariant,
		options: variantOptions
	};
};
export const CurrencyVariantSelect = withHook(useCurrencyVariantSelect)(
	CurrencyVariantSelectUi
);
