"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWhatsAppStyles = exports.useTelegramStyles = exports.useTwitterStyles = exports.useGoogleStyles = exports.useFacebookStyles = exports.useStyles = void 0;
var core_1 = require("@material-ui/core");
var styles_1 = require("@material-ui/core/styles");
var facebookColor = '#3b5998';
var facebookColorHover = '#2f477a';
var twitterColor = '#55acee';
var twitterColorHover = '#007dbb';
var telegramColor = '#0088cc';
var telegramColorHover = '#005b9b';
var whatsappColor = '#25D366';
var whatsappColorHover = '#00a038';
exports.useStyles = core_1.makeStyles(function (theme) { return ({
    socialMediaButtonWrapper: {
        display: 'inline-flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    socialMediaLabel: {
        marginTop: theme.spacing(1)
    }
}); });
exports.useFacebookStyles = core_1.makeStyles(function (theme) { return ({
    root: {
        color: theme.palette.common.white,
        backgroundColor: facebookColor,
        fillColor: '#fff',
        '&:hover': {
            backgroundColor: facebookColorHover,
            '@media (hover: none)': {
                backgroundColor: facebookColorHover
            }
        }
    }
}); });
exports.useGoogleStyles = core_1.makeStyles(function (theme) { return ({
    root: {
        backgroundColor: theme.palette.common.white,
        color: styles_1.fade(theme.palette.common.black, 0.7),
        border: 'none'
    },
    disabled: {
        backgroundColor: styles_1.fade(theme.palette.common.white, 0.84),
        color: styles_1.fade(theme.palette.common.black, 0.44) + " !important"
    }
}); });
exports.useTwitterStyles = core_1.makeStyles(function (theme) { return ({
    root: {
        color: theme.palette.common.white,
        backgroundColor: twitterColor,
        fillColor: '#fff',
        '&:hover': {
            backgroundColor: twitterColorHover,
            '@media (hover: none)': {
                backgroundColor: twitterColorHover
            }
        }
    }
}); });
exports.useTelegramStyles = core_1.makeStyles(function (theme) { return ({
    root: {
        color: theme.palette.common.white,
        backgroundColor: telegramColor,
        fillColor: '#fff',
        '&:hover': {
            backgroundColor: telegramColorHover,
            '@media (hover: none)': {
                backgroundColor: telegramColorHover
            }
        }
    }
}); });
exports.useWhatsAppStyles = core_1.makeStyles(function (theme) { return ({
    root: {
        color: theme.palette.common.white,
        backgroundColor: whatsappColor,
        fillColor: '#fff',
        '&:hover': {
            backgroundColor: whatsappColorHover,
            '@media (hover: none)': {
                backgroundColor: whatsappColorHover
            }
        }
    }
}); });
