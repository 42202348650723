import { Translate } from '@gaming-shell/i18n';
import { SideMenuItem, SideMenuItemProps } from '@gaming-shell/layout';
import LobbyIcon from 'casino/components/Icons/Categories/Lobby';
import { getLobbyRoute } from 'casino/util/routes';
import { withHook, withMappedProps } from 'materialbet-common';
import * as React from 'react';
import { useLocation } from 'react-router';

export interface CasinoSideMenuLobbyItemProps {
	live: boolean;
	active: boolean;
}
export const useCasinoSideMenuLobbyItem = ({ live }: { live: boolean }) => {
	const active = getLobbyRoute(live).matchesExactly(useLocation().pathname);
	return {
		icon: <LobbyIcon />,
		label: <Translate label="casino.content.lobby" />,
		href: getLobbyRoute(live).create(),
		active,
		variant: 'subtitle2'
	};
};
export const CasinoSideMenuLobbyItem = withHook(useCasinoSideMenuLobbyItem)(
	withMappedProps(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		({ live: _, ...props }: { live: unknown } & SideMenuItemProps) => props
	)(SideMenuItem)
);
