import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

const InstantWin: React.FunctionComponent<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<g>
				<path
					fill={props.fill}
					d="M21.2,10h-5.4c-0.1,0-0.1,0-0.2,0.1l-2.6,8.8c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2,0,0.2,0l8.1-8.8c0,0,0-0.1,0-0.2C21.3,10,21.3,10,21.2,10z"
				/>
				<path
					fill={props.fill}
					d="M8.3,10.1c0-0.1-0.1-0.1-0.2-0.1H2.7c-0.1,0-0.1,0-0.2,0.1s0,0.1,0,0.2l8.1,8.9c0,0,0.1,0.1,0.2,0S11,19,11,18.9C11,18.9,8.3,10.1,8.3,10.1z"
				/>
				<path
					fill={props.fill}
					d="M14.6,10c0,0-0.1-0.1-0.1-0.1h-5c0,0-0.1,0-0.1,0.1s0,0.1,0,0.2l2.5,9c0,0.1,
					0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1l2.5-9C14.7,10.2,14.7,10.1,14.6,10z"
				/>
				<path
					fill={props.fill}
					d="M21.4,9L19,5.2c0,0-0.1-0.1-0.1-0.1s-0.1,0-0.1,0.1L16.3,9c0,0,0,0.1,0,0.2c0,0,
					0.1,0.1,0.2,0.1h4.8c0.1,0,0.1,0,0.2-0.1C21.5,9.1,21.5,9,21.4,9z"
				/>
				<path
					fill={props.fill}
					d="M9.4,9.1c0,0,0.1,0.1,0.2,0.1h4.8c0.1,0,0.1,0,0.2-0.1c0,0,0-0.1,0-0.2l-2.4-3.8c0,
					0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0.1L9.5,9C9.3,9,9.3,9.1,9.4,9.1z"
				/>
				<path
					fill={props.fill}
					d="M7.5,9.2c0.1,0,0.1,0,0.2-0.1c0,0,0-0.1,0-0.2L5.2,5.2c0,0-0.1-0.1-0.1-0.1S5,5.1,5,5.2L2.5,9c0,0,0,0.1,0,0.2c0,0,0.1,0.1,0.2,0.1H7.5z"
				/>
				<path
					fill={props.fill}
					d="M8.3,8.7c0,0,0.1,0.1,0.1,0.1s0.1,0,0.1-0.1L11,4.9c0,0,0-0.1,0-0.2s-0.1-0.1-0.2-0.1H6.1c-0.1,0-0.1,0-0.2,0.1s0,0.1,0,0.2L8.3,8.7z"
				/>
				<path
					fill={props.fill}
					d="M15.3,8.7c0,0,0.1,0.1,0.1,0.1s0.1,0,0.1-0.1L18,4.9c0,0,0-0.1,
					0-0.2s-0.1-0.1-0.2-0.1h-4.8c-0.1,0-0.1,0-0.2,0.1s0,0.1,0,0.2C12.8,4.9,15.3,8.7,15.3,8.7z"
				/>
			</g>
		</SvgIcon>
	);
};
export default InstantWin;
