"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./hooks"), exports);
var Provider_1 = require("./Provider");
Object.defineProperty(exports, "Provider", { enumerable: true, get: function () { return Provider_1.Provider; } });
var phraseApp_1 = require("./phraseApp");
Object.defineProperty(exports, "Translation", { enumerable: true, get: function () { return phraseApp_1.Translation; } });
var Translate_1 = require("./Translate");
Object.defineProperty(exports, "Translate", { enumerable: true, get: function () { return Translate_1.Translate; } });
var Trans_1 = require("./Trans");
Object.defineProperty(exports, "Trans", { enumerable: true, get: function () { return Trans_1.Trans; } });
var getDefaultLanguage_1 = require("./internal/getDefaultLanguage");
Object.defineProperty(exports, "getDefaultLanguage", { enumerable: true, get: function () { return getDefaultLanguage_1.getDefaultLanguage; } });
var loadLocales_1 = require("./internal/loadLocales");
Object.defineProperty(exports, "loadLocales", { enumerable: true, get: function () { return loadLocales_1.loadLocales; } });
__exportStar(require("./internal/utils"), exports);
