import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { Translate } from '@gaming-shell/i18n';
import { ApplePay } from 'materialbet-icons';

export interface MoonPayApplePayButton {
	url: string;
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: theme.spacing(1.5, 0),
		backgroundColor:
			theme.palette.type === 'dark'
				? theme.palette.common.white
				: theme.palette.common.black,
		borderRadius: theme.shape.borderRadius,
		color:
			theme.palette.type === 'dark'
				? theme.palette.common.black
				: theme.palette.common.white
	},
	resetLink: {
		textDecoration: 'none',
		color: 'inherit',
		width: '100%'
	},
	icon: {
		width: theme.spacing(6),
		height: theme.spacing(6)
	},
	text: {
		fontFamily: '-apple-system',
		paddingRight: theme.spacing(0.75),
		fontSize: '1.25em',
		fontWeight: theme.typography.fontWeightLight
	}
}));

export const MoonPayApplePayButton: React.FC<MoonPayApplePayButton> = ({
	url
}) => {
	const classes = useStyles();

	return (
		// That's intended to use normal link here as we want to push out user to moonpay page
		<a href={url} target="_blank" className={classes.resetLink}>
			<div className={classes.root}>
				<Typography variant="subtitle2" className={classes.text}>
					<Translate label="application.descriptions.buyWithAProvider" />
				</Typography>
				<ApplePay className={classes.icon} />
			</div>
		</a>
	);
};
