import { useLanguage } from '@gaming-shell/i18n';
import { getSportsName, Locale } from 'sports-sdk/sports-core';
import {
	SportType,
	featuredSports,
	supportedCommonSports,
	supportedEsports,
	supportedOutrightSports,
	supportedSports
} from 'sports/config/sport';
import { getSdkLocale } from 'sports/utils/locale';

export const sortSports = (sports: SportType[], language: Locale) => {
	return sports.sort((sportA: SportType, sportB: SportType) => {
		const sportNameA = getSportsName(sportA, language);
		const sportNameB = getSportsName(sportB, language);
		if (sportNameA > sportNameB) {
			return 1;
		}
		if (sportNameB > sportNameA) {
			return -1;
		}
		return 0;
	});
};
/** @description hooks to return sorted and filter common sports list for all common sports*/
export const useCommonAndOutrightSports = () => {
	const language = useLanguage();
	const locale = getSdkLocale(language);
	const sports = sortSports(
		supportedCommonSports.concat(supportedOutrightSports),
		locale
	);
	return sports;
};
/** @description hooks to return sorted sports list for all sports*/
export const useAllSports = () => {
	const language = useLanguage();
	const locale = getSdkLocale(language);
	const sports = sortSports(supportedSports, locale);
	return sports;
};
/** @description hooks to return featured common sports list.
 * it would be declare by player preference or location later, so named featured
 */
export const useFeaturedSports = () => {
	return featuredSports;
};
/** @description hooks to return non featured common sports list. Not not item in the featured list */
export const useOtherCommonSports = () => {
	const commonSports = useCommonAndOutrightSports();
	const featuredSports = useFeaturedSports();
	return commonSports.filter(
		(sportKey) => !featuredSports.includes(sportKey)
	);
};
/** @description hooks to return sorted and filter esports list */
export const useEsports = () => {
	const language = useLanguage();
	const locale = getSdkLocale(language);
	const sports = sortSports(supportedEsports, locale);
	return sports;
};
