"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountBalance = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var core_1 = require("@material-ui/core");
var player_1 = require("@materialbet-core/player");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var AccountBalance = function (_a) {
    var className = _a.className;
    var variant = player_1.usePlayerCurrencyVariant();
    var amount = player_1.usePlayerWallet().amount;
    var currency = player_1.usePlayerCurrency();
    return (React.createElement("div", { className: className, "aria-label": "account-balance" },
        React.createElement(core_1.Typography, { variant: "caption", color: "textSecondary", "aria-label": "title" },
            React.createElement(i18n_1.Translate, { label: "player.content.account", ns: "common" })),
        React.createElement(core_1.Typography, { variant: "body1", "aria-label": "account-balance-value" }, materialbet_common_1.formatCurrency(amount, currency, {
            variant: variant,
            maxDecimalPlaces: ACCOUNT_MENU_MAX_DECIMAL_PLACES,
            keepTrailingZeros: true
        }))));
};
exports.AccountBalance = AccountBalance;
exports.AccountBalance = AccountBalance = React.memo(AccountBalance);
