import { defaultSportInfo } from 'sports/config/sport/defaultSportInfo';

import { baseInfo } from './baseInfo';
import { marketInfo } from './marketInfo';

export const golf = {
	...defaultSportInfo,
	...baseInfo,
	...marketInfo
};
