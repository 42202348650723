"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fillArray = exports.rotateRight = exports.remove = exports.append = exports.replace = exports.flatten = exports.mapFilter = exports.filterMap = void 0;
exports.filterMap = function (array, filter, map) {
    return array.reduce(function (prev, ele) {
        if (!filter(ele)) {
            return prev;
        }
        prev.push(map(ele));
        return prev;
    }, []);
};
exports.mapFilter = function (array, map, filter) {
    return array.reduce(function (prev, ele) {
        var mapped = map(ele);
        if (!filter(mapped)) {
            return prev;
        }
        prev.push(mapped);
        return prev;
    }, []);
};
exports.flatten = function (array) {
    return array.reduce(function (prev, ele) {
        prev.push.apply(prev, ele);
        return prev;
    }, []);
};
/** @description Returns a new array, with the element at index replaced with the given element */
exports.replace = function (array, element, index) {
    return __spreadArrays(array.slice(0, index), [
        element
    ], array.slice(index + 1, array.length));
};
/** @description Returns a new array, with the element appended as the last element in the array */
exports.append = function (array, element) {
    return __spreadArrays(array, [element]);
};
/** @description Returns a new array, with the element at index removed */
exports.remove = function (array, index) {
    return __spreadArrays(array.slice(0, index), array.slice(index + 1, array.length));
};
/** @description Moves the element at index 0 to the last index, and the last element to index 0 */
exports.rotateRight = function (array) {
    var arrayCopy = __spreadArrays(array);
    if (arrayCopy.length <= 0) {
        return arrayCopy;
    }
    arrayCopy.push(arrayCopy.shift());
    return arrayCopy;
};
/** @description Creates an array of length defined by first arg and when the elements inside the array don't matter */
exports.fillArray = function (length, map) {
    return Array.from({ length: length }, function (_, i) { return map(i); });
};
