import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import * as React from 'react';
import { Link } from 'react-router-dom';

export interface ZeroMarginEventWidgetPartProps {
	title: string;
	titleLink?: string;
	caption?: React.ReactNode;
	linkTitle: string;
	linkTo: string;
	counter: React.ReactNode;
	footer?: React.ReactNode;
}
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(1)
	},
	row: {
		display: 'flex',
		justifyItems: 'space-between',
		alignItems: 'center'
	},
	link: {
		color: theme.palette.secondary.main,
		marginLeft: 'auto'
	},
	icon: {
		color: theme.palette.secondary.main
	}
}));
// TODO  add more styling for the rest of the component
export const ZeroMarginEventWidgetPart: React.FC<ZeroMarginEventWidgetPartProps> = ({
	title,
	titleLink,
	caption,
	linkTitle,
	linkTo,
	counter,
	footer
}) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div>
				<div className={classes.row}>
					{caption ? (
						<Typography variant="caption" color="textSecondary">
							{caption}
						</Typography>
					) : (
						<ButtonBase to={titleLink || ''} component={Link}>
							<Typography variant="body1">{title}</Typography>
						</ButtonBase>
					)}
					<ButtonBase
						className={classes.link}
						to={linkTo}
						component={Link}
					>
						{linkTitle}
						<ChevronRight className={classes.icon} />
					</ButtonBase>
				</div>
				{caption && (
					<ButtonBase to={titleLink || ''} component={Link}>
						<Typography variant="body1">{title}</Typography>
					</ButtonBase>
				)}
			</div>
			{counter}
			<Typography>{footer}</Typography>
		</div>
	);
};
