import { warning } from '@gaming-shell/logging';
import { bitsIncludeAll, bitsIncludeSome } from 'sports/utils/bitmaskUtils';
import { parseOdds } from 'sports/utils/odds';
import { createReducer, getType } from 'typesafe-actions';

import { getTotalBetslipStatus } from '../betslipSelectors/';
import {
	BetslipReducerMultiple,
	BetslipState,
	SupportedSystem
} from '../types';
import {
	BetslipActions,
	betslipActions,
	createProducer
} from './betslipActions';
import {
	addSelectionToSelections,
	addStatusInObject,
	clearBetslipStatus,
	createChanges,
	getBetslipIndexToPlaceBetMap,
	getTotalStatus,
	isSameSelection,
	removeIvalidSelectionsFromSelections,
	removeSelectionFromSelections,
	removeStatusInObject,
	revalidateChangesExistence,
	setChangeStatusInUpdate,
	setMaxStakeInSelections,
	setSelectionStakeInSelections,
	setStakeInSelections,
	statusIncludes,
	validateCorrelatedSelections,
	validateMultiples,
	validateStakeAgainstSelection,
	validateStraight
} from './betslipReducerUtils';
import { BetslipStatusFlags, getBetslipStatusFlag } from './BetslipStatusFlags';
import { correctStake } from './correctStake';

// any code change to the betslip state that that makes it incompatibale with the unchanged state should increase the version here, so as that the persisted betslip will be discarded instead of loaded into a possible unusuable state
export const betslipStateVersion = '2';

const addSelection = createProducer<'addSelection'>((draft, { payload }) => {
	const { selection } = payload;
	if (draft.isSubmitting) {
		return;
	}
	addSelectionToSelections(draft.betslip.selections, selection);
	if (draft.mode === 'multiples') {
		validateCorrelatedSelections(draft.betslip.selections);
	}
	if (draft.changes) {
		addSelectionToSelections(draft.changes.selections, selection);
	}
});

const updateSelectionStake = createProducer<'updateSelectionStake'>(
	(draft, { payload }) => {
		if (draft.isSubmitting) {
			return;
		}
		const { stake, selectionIndex } = payload;
		const selection = setSelectionStakeInSelections(
			draft.betslip.selections,
			selectionIndex,
			stake
		);
		if (selection && selection.stake) {
			validateStakeAgainstSelection(selection);
		}
		if (draft.changes) {
			setSelectionStakeInSelections(
				draft.changes.selections,
				selectionIndex,
				stake
			);
		}
	}
);
const resetSelectionStake = createProducer<'resetSelectionStake'>(
	(draft, { payload: selectionIndex }) => {
		if (draft.isSubmitting) {
			return;
		}
		const selection = setSelectionStakeInSelections(
			draft.betslip.selections,
			selectionIndex,
			0
		);
		if (selection) {
			removeStatusInObject(
				selection,
				BetslipStatusFlags.StakeValidationStatus
			);
		}
		if (draft.changes) {
			setSelectionStakeInSelections(
				draft.changes.selections,
				selectionIndex,
				0
			);
		}
	}
);
const removeSelection = createProducer<'removeSelection'>(
	(draft, { payload: index }) => {
		if (draft.isSubmitting) {
			return;
		}
		const removedSelection = removeSelectionFromSelections(
			draft.betslip.selections,
			index
		);
		if (!removedSelection) {
			return;
		}
		if (
			statusIncludes(
				removedSelection.status,
				BetslipStatusFlags.CorrelatedSelections
			)
		) {
			validateCorrelatedSelections(draft.betslip.selections);
		}
		if (!draft.changes) {
			return;
		}
		removeSelectionFromSelections(draft.changes.selections, index);
		const selections = draft.changes.selections;
		const multiples = Object.values(
			draft.changes.multiples
		) as BetslipReducerMultiple[]; // object.values has bad typing

		const status = getTotalStatus([...selections, ...multiples]);
		if (status === BetslipStatusFlags.Default) {
			delete draft.changes;
		}
	}
);
const updateMultipleStake = createProducer<'updateMultipleStake'>(
	(draft, { payload }) => {
		if (draft.isSubmitting) {
			return;
		}
		const { system, stake } = payload;

		draft.betslip.multiples[system].stake = stake;
		if (draft.changes) {
			draft.changes.multiples[system].stake = stake;
		}
	}
);

const setPlaceBetChanges = createProducer<'setPlaceBetChanges'>(
	(draft, { payload }) => {
		const { bets, selections, status } = payload;

		draft.isSubmitting = false;
		delete draft.betslipId;

		if (
			status === 'ACCEPTED' ||
			status === 'PENDING_ACCEPTANCE' ||
			status === 'VALID'
		) {
			// This should never happen
			warning(
				'received placebetchanges action with non error status',
				payload
			);
			return;
		}
		const indexMapping = getBetslipIndexToPlaceBetMap(bets);
		selections.forEach((s, i) => {
			const selectionStatusFlag = getBetslipStatusFlag(
				s.status || 'VALID'
			);
			if (
				bitsIncludeSome(
					BetslipStatusFlags.CausesChange,
					selectionStatusFlag
				)
			) {
				if (!draft.changes) {
					draft.changes = createChanges(draft.betslip);
				}
				const change = draft.changes.selections[i];
				if (!change) {
					warning(
						'Cannot find changed selection for placebet response selection Index',
						{ selectionIndex: i },
						payload,
						draft
					);
					return;
				}
				change.status = selectionStatusFlag;
				change.price = parseOdds(parseFloat(s.price));
				return;
			}
			const selectionDraft = draft.betslip.selections[i];
			if (!selectionDraft) {
				warning(
					'Cannot find selection for placebet response selection Index',
					{ selectionIndex: i },
					payload,
					draft
				);
				return;
			}
			const bet = bets[indexMapping.selections[i]];
			if (!bet) {
				// is parlay bet
				selectionDraft.status = selectionStatusFlag;
				return;
			}
			selectionDraft.status =
				getBetslipStatusFlag(bet.status || 'VALID') |
				selectionStatusFlag;
			if (
				bitsIncludeAll(
					BetslipStatusFlags.UpdateMaxStake,
					selectionDraft.status
				)
			) {
				// we could do some additional parsing here but we dont have any information about the currency right now
				selectionDraft.maxStake = parseFloat(bet.stake);
			}
			if (
				bitsIncludeAll(
					BetslipStatusFlags.UpdateStake,
					selectionDraft.status
				)
			) {
				// we could do some additional parsing here but we dont have any information about the currency right now
				selectionDraft.stake = parseFloat(bet.stake);
			}
			if (selectionDraft.status === BetslipStatusFlags.StakeBelowMin) {
				const parsedStake = parseFloat(bet.stake);
				selectionDraft.minStake =
					parsedStake > selectionDraft.minStake
						? parsedStake
						: selectionDraft.minStake;
			}
		});
		Object.keys(draft.betslip.multiples).forEach((key) => {
			// on JS level all keys are string - always
			// so parsing to number just for TS doesnt make any sense
			// as it would be parsed back to a string by the JS interpreter
			const system = (key as unknown) as SupportedSystem;
			const betIndex = indexMapping.multiple[system];
			if (betIndex === undefined) {
				// singles betslip
				return;
			}
			const bet = bets[betIndex];
			const multiple = draft.betslip.multiples[system];
			const multipleStatusFlag = getBetslipStatusFlag(
				bet.status || 'VALID'
			);
			multiple.status = multipleStatusFlag;
		});
		const totalStatus = getTotalBetslipStatus(draft);
		if (totalStatus === BetslipStatusFlags.Default) {
			draft.status = getBetslipStatusFlag(status);
		}
	}
);

const changeSelection = createProducer<'changeSelection'>(
	(draft, { payload: update }) => {
		if (draft.isSubmitting) {
			return;
		}
		const selectionIndex = draft.betslip.selections.findIndex((s) =>
			isSameSelection(s, update)
		);
		const selection = draft.betslip.selections[selectionIndex];
		if (!selection) {
			return;
		}

		const { status } = update;
		update.status = BetslipStatusFlags.Default;
		setChangeStatusInUpdate(draft, update);

		if (status === BetslipStatusFlags.Default) {
			// market is not suspended
			removeStatusInObject(selection, BetslipStatusFlags.MarketSuspended);
		} else {
			// market is suspended
			addStatusInObject(selection, BetslipStatusFlags.MarketSuspended);
		}
		if (update.status === BetslipStatusFlags.Default) {
			// no price update
			if (draft.changes) {
				removeStatusInObject(
					draft.changes.selections[selectionIndex],
					BetslipStatusFlags.PriceAboveMarket
				);
				revalidateChangesExistence(draft);
			}
			update.status = selection.status;
			Object.assign(selection, update);
			return;
		}

		if (update.status === BetslipStatusFlags.PriceAboveMarket) {
			if (!draft.changes) {
				draft.changes = createChanges(draft.betslip);
			}
			Object.assign(draft.changes.selections[selectionIndex], update);
		}
	}
);

const validate = createProducer<'validate'>((draft, { payload }) => {
	const { funds } = payload;
	clearBetslipStatus(draft.betslip);
	draft.status = BetslipStatusFlags.Default;
	if (draft.mode === 'singles') {
		validateStraight(draft, funds);
		return;
	}
	if (draft.mode === 'multiples') {
		validateMultiples(draft, funds);
	}
});
const submit = createProducer<'submit'>((draft, { payload }) => {
	draft.isSubmitting = true;
	draft.betslipId = payload.betslipId;
});
const setAcceptBetterOdds = createProducer<'setAcceptBetterOdds'>(
	(draft, { payload: acceptBetterOdds }) => {
		if (draft.isSubmitting) {
			return;
		}
		draft.acceptBetterOdds = acceptBetterOdds;
	}
);
export const defaultState: BetslipState = {
	mode: 'singles',
	status: BetslipStatusFlags.Default,
	isSubmitting: false,
	acceptBetterOdds: true,
	betslip: {
		selections: [],
		multiples: { 0: { stake: 0, status: BetslipStatusFlags.Default } }
	}
};
const clear = createProducer<'clear'>(() => {
	return defaultState;
});
const setBetslip = createProducer<'setBetslip'>(
	(draft, { payload: betslip }) => {
		if (draft.isSubmitting) {
			return;
		}
		draft.changes = undefined;
		draft.betslip = betslip;
		draft.status = BetslipStatusFlags.Default;
	}
);
const setState = createProducer<'setState'>((_, { payload: state }) => state);

const updateAllSelectionStakes = createProducer<'updateAllSelectionStakes'>(
	(draft, { payload: stake }) => {
		if (draft.isSubmitting) {
			return;
		}
		setStakeInSelections(draft, stake);
	}
);
const setAllSelectionMaxStake = createProducer<'setAllSelectionsMaxStake'>(
	(draft) => {
		if (draft.isSubmitting) {
			return;
		}
		setMaxStakeInSelections(draft.betslip.selections);
		if (draft.changes) {
			setMaxStakeInSelections(draft.changes.selections);
		}
	}
);

const removeInvalidSelections = createProducer<'removeInvalidSelections'>(
	(draft) => {
		if (draft.isSubmitting) {
			return;
		}
		removeIvalidSelectionsFromSelections(draft);
	}
);

const applyChanges = createProducer<'applyChanges'>((draft) => {
	if (draft.isSubmitting || !draft.changes) {
		return;
	}
	const changes = draft.changes;
	draft.betslip.selections = changes.selections.map((c, i) => {
		c.status = draft.betslip.selections[i].status;
		return c;
	});
	Object.keys(draft.changes.multiples).forEach((key) => {
		changes.multiples = draft.betslip.multiples[key].status;
	});
	delete draft.changes;
});
const setMode = createProducer<'setMode'>((draft, { payload: mode }) => {
	draft.mode = mode;
});
export const betslipReducer = createReducer<BetslipState, BetslipActions>(
	defaultState,
	{
		[getType(betslipActions.addSelection)]: addSelection,
		[getType(betslipActions.updateSelectionStake)]: updateSelectionStake,
		[getType(betslipActions.removeSelection)]: removeSelection,
		[getType(betslipActions.updateMultipleStake)]: updateMultipleStake,
		[getType(betslipActions.setPlaceBetChanges)]: setPlaceBetChanges,
		[getType(betslipActions.changeSelection)]: changeSelection,
		[getType(betslipActions.validate)]: validate,
		[getType(betslipActions.submit)]: submit,
		[getType(betslipActions.setAcceptBetterOdds)]: setAcceptBetterOdds,
		[getType(betslipActions.clear)]: clear,
		[getType(betslipActions.setBetslip)]: setBetslip,
		[getType(betslipActions.setState)]: setState,
		[getType(
			betslipActions.updateAllSelectionStakes
		)]: updateAllSelectionStakes,
		[getType(
			betslipActions.setAllSelectionsMaxStake
		)]: setAllSelectionMaxStake,
		[getType(betslipActions.applyChanges)]: applyChanges,
		[getType(betslipActions.resetSelectionStake)]: resetSelectionStake,
		[getType(betslipActions.correctStake)]: correctStake,
		[getType(
			betslipActions.removeInvalidSelections
		)]: removeInvalidSelections,
		[getType(betslipActions.setMode)]: setMode
	}
);
