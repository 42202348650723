import { SingleSportTabRoute, SportsRoutes } from 'materialbet-common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { SportTab } from 'sports/config/sport';
import { getEventStartTime } from 'sports/selectors/eventSelectors';
import { isEventStarted, parseUrlEventId } from 'sports/utils/event';

export const sportsPath = SportsRoutes.root.create;
export const inPlayPath = SportsRoutes.live.create;

export const sportTabRoutes: Record<SportTab, SingleSportTabRoute> = {
	inPlay: SportsRoutes.sportInPlay,
	competitions: SportsRoutes.sportCompetitions,
	highlights: SportsRoutes.sportHighlights,
	today: SportsRoutes.sportToday,
	tomorrow: SportsRoutes.sportTomorrow,
	virtuals: SportsRoutes.sportVirtuals,
	outrights: SportsRoutes.sportOutrights
};
// Return route object if given path matches any of sport tab routes
export const getSportRouteWithTab = (path?: string) => {
	const match = Object.keys(sportTabRoutes).find((key) => {
		const route = sportTabRoutes[key];
		return route.matchesExactly(path);
	});
	return match ? sportTabRoutes[match] : null;
};
export const sportRoute = (sportKey: string, tab?: SportTab) =>
	tab
		? sportTabRoutes[tab].create({ sportKey })
		: SportsRoutes.sport.create({ sportKey });

/** @deprecated Use SportsRoutes.eventWithKey instead */
export const eventRoute = (
	eventId: number | string,
	sportKey: string,
	competitionKey: string,
	eventKey?: string
) =>
	eventKey
		? SportsRoutes.eventWithKey.create({
				sportKey,
				eventId,
				competitionKey,
				eventKey
		  })
		: SportsRoutes.event.create({ sportKey, eventId, competitionKey });

export const competitionRoute = (competitionKey: string, sportKey: string) =>
	SportsRoutes.competition.create({ sportKey, competitionKey });

export const topCouponRoute = (couponKey: string, sportKey: string) =>
	SportsRoutes.topCoupon.create({ couponKey, sportKey });
export const betsPageRoute = SportsRoutes.bets.create;
export const livePageRoute = SportsRoutes.live.create;

export const useIsLiveEventPage = () => {
	const match = useRouteMatch<{ eventId: string }>({
		path: SportsRoutes.event.template,
		strict: true
	});
	const eventId = match?.params?.eventId;
	const id = parseUrlEventId(eventId) || '';
	const eventStartTime = useSelector(getEventStartTime(id));
	return React.useMemo(() => {
		const isEventStart = isEventStarted(eventStartTime);
		return match && isEventStart;
	}, [match, eventStartTime]);
};
