import * as React from 'react';

import { getBetslipSelectionIdentifierString } from '../betslipSelectors/betslipSelectorsUtils';
import { useSelectionIsInBigBetslip } from '../BigBetslip/BigBetslipProvider';
import { useSelectionIsInQuickBetslip } from '../QuickBetslip/QuickBetslipProvider';
import { BetslipSelectionIdentity } from '../types';

export const useSelectionIsInBetslip = (
	selection: BetslipSelectionIdentity
) => {
	const identity = React.useMemo(
		() => getBetslipSelectionIdentifierString(selection),
		[selection]
	);
	const selectionIsInBigBetslip = useSelectionIsInBigBetslip(identity);
	const selectionIsInQuickBetslip = useSelectionIsInQuickBetslip(identity);
	return selectionIsInQuickBetslip || selectionIsInBigBetslip;
};
