import querystring from 'querystring';

const parse = (...args: any[]) => {
	const [str, ...rest] = args;
	const query = str.split('?')[1];
	return querystring.parse(query, ...rest);
};

export default {
	parse
};
