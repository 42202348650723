import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

export const NumPadIcon: React.FunctionComponent<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<g fill="#6D7278" fillRule="evenodd">
				<circle cx="9" cy="23" r="2" />
				<circle cx="16" cy="16" r="2" />
				<circle cx="9" cy="16" r="2" />
				<circle cx="2" cy="16" r="2" />
				<circle cx="16" cy="9" r="2" />
				<circle cx="9" cy="9" r="2" />
				<circle cx="2" cy="9" r="2" />
				<circle cx="16" cy="2" r="2" />
				<circle cx="9" cy="2" r="2" />
				<circle cx="2" cy="2" r="2" />
			</g>
		</SvgIcon>
	);
};
