import clsx from 'clsx';
import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { TransitionActions } from 'react-transition-group/Transition';

import { useStyles } from './AnimatedList.styles';

export type AnimatedListElement = {
	id: string;
	element: JSX.Element;
};
interface AnimatedListProps extends TransitionActions {
	elements: AnimatedListElement[];
	maxElements?: number;
}

export const AnimatedList: React.FC<AnimatedListProps> = (props) => {
	const { elements, maxElements, ...restProps } = props;
	const classes = useStyles();
	const trimmedElements = maxElements
		? elements.slice(0, maxElements)
		: elements;
	const [hasNewItem, setHasNewItem] = React.useState(true);
	const firstItem = trimmedElements?.[0]?.id;
	React.useEffect(() => {
		setHasNewItem(true);
		setTimeout(() => {
			setHasNewItem(false);
		}, 1000);
	}, [firstItem]);
	return (
		<div
			className={clsx(classes.animatedList, {
				[classes.hasNewItem]: hasNewItem
			})}
		>
			<TransitionGroup {...restProps} component={null}>
				{trimmedElements.map(({ id, element }, index) => (
					<CSSTransition
						key={id}
						timeout={1000}
						classNames={{
							enter: classes.itemEnter,
							enterActive: classes.itemEnterActive,
							exit: classes.itemExit,
							exitActive: classes.itemExitActive
						}}
					>
						{element}
					</CSSTransition>
				))}
			</TransitionGroup>
		</div>
	);
};
