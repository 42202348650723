"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var core_1 = require("@material-ui/core");
exports.useStyles = core_1.makeStyles({
    animatedList: {
        overflow: 'hidden',
        position: 'relative'
    },
    itemEnter: {
        opacity: 0,
        transform: 'translate(0, -100%)'
    },
    itemEnterActive: {
        opacity: 1,
        transform: 'translate(0, 0)',
        transition: 'all 0.75s ease-in-out'
    },
    itemExit: {
        position: 'absolute',
        opacity: 1,
        transform: 'translate(0, 0)'
    },
    itemExitActive: {
        opacity: 0,
        transform: 'translate(0, 100%)',
        transition: 'all 500ms ease-in-out'
    },
    hasNewItem: {
        animation: '$slideDown 1s'
    },
    '@keyframes slideDown': {
        '0%': { transform: 'translateY(-20%)' },
        '100%': { transform: 'translateY(0)' }
    }
});
