import { americanFootball } from './americanFootball';
import { arenaOfValor } from './arenaOfValor';
import { aussieRules } from './aussieRules';
import { badminton } from './badminton';
import { bandy } from './bandy';
import { baseball } from './baseball';
import { basketball } from './basketball';
import { beachSoccer } from './beachSoccer';
import { beachVolleyball } from './beachVolleyball';
import { bowls } from './bowls';
import { boxing } from './boxing';
import { callOfDuty } from './callOfDuty';
import { chess } from './chess';
import { counterStrike } from './counterStrike';
import { cricket } from './cricket';
import { cycling } from './cycling';
import { darts } from './darts';
import { dota2 } from './dota2';
import { fieldHockey } from './fieldHockey';
import { fifa } from './fifa';
import { floorball } from './floorball';
import { futsal } from './futsal';
import { golf } from './golf';
import { handball } from './handball';
import { hearthStone } from './hearthStone';
import { iceHockey } from './iceHockey';
import { kingOfGlory } from './kingOfGlory';
import { leagueOfLegends } from './leagueOfLegends';
import { mma } from './mma';
import { motorsport } from './motorsport';
import { nba2k } from './nba2k';
import { overwatch } from './overwatch';
import { pesapallo } from './pesapallo';
import { politics } from './politics';
import { rainbowSix } from './rainbowSix';
import { rocketLeague } from './rocketLeague';
import { rugby } from './rugby';
import { rugbyLeague } from './rugbyLeague';
import { rugbyUnion } from './rugbyUnion';
import { sailing } from './sailing';
import { snooker } from './snooker';
import { soccer } from './soccer';
import { starCraft } from './starCraft';
import { tableTennis } from './tableTennis';
import { tennis } from './tennis';
import { SportInfo, SportType } from './types';
import { volleyball } from './volleyball';
import { waterpolo } from './waterpolo';
import { worldLottery } from './worldLottery';

/** @description additional info about sports for display purposes */
// For any fast implementation can just use the key here use defaultSportInfo and add custom key, however, please add a sport if not urgent
export const sportInfo: Partial<Record<SportType, SportInfo>> = {
	'american-football': americanFootball,
	'aussie-rules': aussieRules,
	badminton,
	bandy,
	baseball,
	basketball,
	'beach-soccer': beachSoccer,
	'beach-volleyball': beachVolleyball,
	bowls,
	boxing,
	cricket,
	chess,
	cycling,
	darts,
	'field-hockey': fieldHockey,
	floorball,
	futsal,
	golf,
	handball,
	'ice-hockey': iceHockey,
	mma,
	motorsport,
	pesapallo,
	rugby,
	'rugby-union': rugbyUnion,
	'rugby-league': rugbyLeague,
	snooker,
	soccer,
	sailing,
	'table-tennis': tableTennis,
	tennis,
	volleyball,
	waterpolo,
	'world-lottery': worldLottery,
	// kabaddi,
	// greyhound,
	// Esports
	'call-of-duty': callOfDuty,
	'counter-strike': counterStrike,
	overwatch,
	'dota-2': dota2,
	'league-of-legends': leagueOfLegends,
	starcraft: starCraft,
	'esport-nba2k': nba2k,
	'rainbow-six': rainbowSix,
	hearthstone: hearthStone,
	'rocket-league': rocketLeague,
	'esport-arena-of-valor': arenaOfValor,
	'esport-fifa': fifa,
	'esport-king-of-glory': kingOfGlory,
	// Entertainment
	politics
};
