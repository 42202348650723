// https://github.com/snakesilk/react-fullscreen/blob/master/src/index.jsx
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import screenfull from 'screenfull';

interface FullscreenProps {
	children: React.ReactNode;
	enabled: boolean;
	onChange: (isFullScreen: boolean) => void;
	className?: string;
}

const useStyles = makeStyles({
	root: {
		height: '100%',
		width: '100%'
	}
});

export const Fullscreen: React.FunctionComponent<FullscreenProps> = ({
	children,
	enabled,
	onChange,
	className
}) => {
	const ref = React.useRef<HTMLDivElement | null>(null);
	const classes = useStyles();
	const onFullscreenChanged = React.useCallback(() => {
		onChange(screenfull.isEnabled && screenfull.isFullscreen);
	}, [onChange]);
	React.useEffect(() => {
		if (screenfull.isEnabled) {
			// Handle closing screenfull, e.g. keyboard
			screenfull.onchange(onFullscreenChanged);
		}
		return () => {
			if (screenfull.isEnabled) {
				screenfull.off('change', onFullscreenChanged);
			}
		};
	}, [onFullscreenChanged]);
	React.useEffect(() => {
		if (!screenfull.isEnabled || !ref.current) {
			return;
		}
		if (enabled) {
			// Open the fullscreen mode
			screenfull.request(ref.current);
		} else {
			// Leave fullscreen here
			screenfull.exit();
		}
	}, [enabled]);
	return (
		<div ref={ref} className={clsx({ [classes.root]: enabled }, className)}>
			{children}
		</div>
	);
};
