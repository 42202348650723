"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoPageLayout = void 0;
var device_1 = require("@gaming-shell/device");
var theming_1 = require("@gaming-shell/theming");
var core_1 = require("@material-ui/core");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var useStyles = core_1.makeStyles(function (theme) { return ({
    modal: {
        backgroundColor: theme.palette.background.default
    },
    content: {
        minHeight: '100%',
        padding: theme.spacing(3, 3, 6)
    },
    centered: {
        margin: 'auto'
    },
    brandLogo: {
        marginRight: 'auto',
        height: device_1.isDesktop() ? 24 : 20
    },
    img: {
        width: 280
    }
}); });
exports.InfoPageLayout = function (_a) {
    var children = _a.children, hideBrandLogo = _a.hideBrandLogo, imgSrc = _a.imgSrc, imgAlt = _a.imgAlt;
    var classes = useStyles();
    var brandLogoUrl = theming_1.useLogoUrls()[device_1.getPlatform()];
    return (React.createElement(core_1.Modal, { open: true, hideBackdrop: true, className: classes.modal },
        React.createElement(materialbet_common_1.Column, { className: classes.content },
            !hideBrandLogo && (React.createElement("img", { src: brandLogoUrl, alt: BRAND_DISPLAY_NAME, className: classes.brandLogo })),
            React.createElement(materialbet_common_1.Column, { className: classes.centered, justify: "center", alignItems: "center" },
                React.createElement("img", { src: imgSrc, alt: imgAlt, className: classes.img }),
                children))));
};
