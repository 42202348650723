import { Selection } from 'sports/api/sports/';

import { BetslipStatusFlags } from '../betslipReducer/BetslipStatusFlags';
import {
	BetslipReducerSelection,
	BetslipUpdateSelectionPayload
} from '../types';

export const createSelectionUpdate = (
	eventSelection: Selection | undefined,
	selection: BetslipReducerSelection
): BetslipUpdateSelectionPayload => {
	if (!eventSelection) {
		return { ...selection, status: BetslipStatusFlags.MarketSuspended };
	}
	return {
		...selection,
		price: eventSelection.price || selection.price,
		maxStake: eventSelection.maxStake || selection.maxStake,
		status:
			eventSelection.status === 'SELECTION_DISABLED'
				? BetslipStatusFlags.MarketSuspended
				: BetslipStatusFlags.Default
	};
};
