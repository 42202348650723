import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: [
			{
				marketType: MarketType.futsal_match_odds
			},
			{
				marketType: MarketType.futsal_handicap,
				view: MarketView.Handicap
			},
			{
				marketType: MarketType.futsal_totals,
				view: MarketView.Goals
			},
			{
				marketType: MarketType.futsal_draw_no_bet
			},
			{
				marketType: MarketType.futsal_double_chance
			},
			{
				marketType: MarketType.futsal_odd_even
			}
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.futsal_match_odds
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.futsal_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
