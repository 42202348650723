import { Typography } from 'materialbet-common';
import * as React from 'react';
import { ActionBarButton, ActionBarButtonProps } from '@gaming-shell/layout';

export const AccountBalanceFundsAmount: React.FC<ActionBarButtonProps> = ({
	className,
	children,
	variant = 'text',
	...rest
}) => {
	return (
		<ActionBarButton
			{...rest}
			variant={variant}
			aria-label="account-balance-funds-amount"
			className={className}
		>
			<Typography variant="body2" textTransform="none">
				{children}
			</Typography>
		</ActionBarButton>
	);
};
