import produce from 'immer';
import { actions as commonActions } from 'sports/modules/common';
import { ActionType, createAction, getType } from 'typesafe-actions';

export type EventTreesMeta = Record<string, EventTreeMeta[]>;
export interface SportsMeta {
	pendingBetsCount?: number;
	completedBetsCount?: number;
	competitionEvents?: CompetitionEventsMeta;
	sportsTree?: SportsTreeMeta[];
	eventTrees?: EventTreesMeta;
	ipCountry?: string;
}
export interface BetHistoryMeta {
	[key: string]: number[];
}
export interface SportEventsMeta {
	[sportId: number]: EventTreeCompetitionMeta[];
	[sportId: string]: EventTreeCompetitionMeta[];
}
export interface CompetitionEventsMeta {
	[competitionRequest: string]: number[];
}
export interface EventTreeMeta {
	/** @description sportKey */
	id: string;
	/** @description competitions */
	items: EventTreeCompetitionMeta[];
}
export interface EventTreeCompetitionMeta {
	/** @description competitionKey */
	id: string;
	/** @description eventIds */
	items: Array<number>;
}
export interface SportsTreeMeta {
	id: number | string;
	categories: SportsTreeCategoryMeta[];
}
export interface SportsTreeCategoryMeta {
	id: number | string;
	competitions: Array<number | string>;
}

export const SportsMetaKey = 'sportsMeta';
export type SportsMetaKeys = keyof SportsMeta;
// there is no way to ensure that action.meta.sportsMeta would actually follow our typing, so it is a bit risky here in regards to typesafety
export interface AddEventsToCompetitionEventsPayload {
	eventIds: number[];
	key: string;
	list: 'competition';
}
export interface AddEventsToTreePayload {
	eventIds: number[];
	key: string;
	sportKey: string;
	competitionKey: string;
	list: 'tree';
}
export type AddEventsPayload =
	| AddEventsToCompetitionEventsPayload
	| AddEventsToTreePayload;
export interface RemoveEventsPayload {
	eventIds: number[];
	key: string;
	list: 'tree' | 'competition';
}
export const sportsMetaActions = {
	addEvents: createAction(
		'sportsMeta/addEvents',
		(resolve) => (payload: AddEventsPayload) => resolve(payload)
	),
	removeEvents: createAction(
		'sportsMeta/removeEvents',
		(resolve) => (payload: RemoveEventsPayload) => resolve(payload)
	)
};
const removeEvents = produce(
	(
		draft: SportsMeta,
		action: ActionType<typeof sportsMetaActions.removeEvents>
	) => {
		const { eventIds, key, list } = action.payload;
		if (list === 'competition' && draft.competitionEvents?.[key]) {
			draft.competitionEvents[key] = draft.competitionEvents[key].filter(
				(id) => !eventIds.includes(id)
			);
			return;
		}
		if (list === 'tree' && draft.eventTrees?.[key]) {
			const sports = draft.eventTrees[key];
			sports.forEach((s) =>
				s.items.forEach(
					(c) =>
						(c.items = c.items.filter(
							(id) => !eventIds.includes(id)
						))
				)
			);
			return;
		}
		return;
	}
);
const addEvents = (
	state: SportsMeta,
	action: ActionType<typeof sportsMetaActions.addEvents>
): SportsMeta => {
	const { key, list, eventIds } = action.payload;
	if (list === 'competition') {
		const filteredEventIds = eventIds.filter(
			(id) => !state.competitionEvents?.[key]?.includes(id)
		);
		return {
			...state,
			competitionEvents: {
				...(state.competitionEvents || {}),
				[key]: [
					...filteredEventIds,
					...(state.competitionEvents?.[key] || [])
				]
			}
		};
	}
	if (list === 'tree') {
		const {
			sportKey,
			competitionKey
		} = action.payload as AddEventsToTreePayload;
		const sports = state.eventTrees?.[key] || [];
		const sportIndex = sports.findIndex(({ id }) => id === sportKey);
		const sport = sports[sportIndex] || { id: sportKey, items: [] };
		const competitionIndex = sport.items.findIndex(
			({ id }) => id === competitionKey
		);
		const competition = sport.items[competitionIndex] || {
			id: competitionKey,
			items: []
		};
		// add the new sport / competition to the end of the list if they arent in the tree yet
		const sportInputIndex = sportIndex >= 0 ? sportIndex : sports.length;
		const competitionInputIndex =
			competitionIndex >= 0 ? competitionIndex : sport.items.length;
		const replaceCompetition = competitionIndex >= 0;
		const replaceSport = sportInputIndex >= 0;
		const newEvents = [...competition.items];
		newEvents.splice(0, 0, ...eventIds);
		const newCompetition = { ...competition, items: newEvents };
		const newCompetitions = [...sport.items];
		newCompetitions.splice(
			competitionInputIndex,
			replaceCompetition ? 1 : 0,
			newCompetition
		);
		const newSport = { ...sport, items: newCompetitions };
		const newSports = [...sports];
		newSports.splice(sportInputIndex, replaceSport ? 1 : 0, newSport);
		const eventTrees = { ...state.eventTrees, [key]: newSports };
		return {
			...state,
			eventTrees
		};
	}
	return state;
};
export default (
	state: SportsMeta = { eventTrees: {}, competitionEvents: {} },
	action: { [key: string]: unknown }
) => {
	if (action.type === getType(commonActions.clear)) {
		return {};
	}
	if (action.type === getType(sportsMetaActions.addEvents)) {
		return addEvents(
			state,
			action as ActionType<typeof sportsMetaActions.addEvents>
		);
	}
	if (action.type === getType(sportsMetaActions.removeEvents)) {
		return removeEvents(
			state,
			action as ActionType<typeof sportsMetaActions.removeEvents>
		);
	}
	if (
		!(
			typeof action.meta === 'object' &&
			action.meta &&
			action.meta[SportsMetaKey]
		)
	) {
		return state;
	}

	const sportsMeta: SportsMeta | undefined = action.meta[SportsMetaKey];
	if (!sportsMeta) {
		return state;
	}
	if (sportsMeta.eventTrees && state.eventTrees) {
		sportsMeta.eventTrees = {
			...state.eventTrees,
			...sportsMeta.eventTrees
		};
	}
	if (sportsMeta.competitionEvents && state.competitionEvents) {
		sportsMeta.competitionEvents = {
			...state.competitionEvents,
			...sportsMeta.competitionEvents
		};
	}

	return { ...state, ...(action.meta[SportsMetaKey] as SportsMeta) };
};
