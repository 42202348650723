import { makeStyles, Theme, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import clx from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
	getCompetitionCategory,
	getCompetitionName
} from 'sports/selectors/competitionSelectors';

import { EventGroupHeaderIcon } from '../EventGroupHeaderIcon';

export type CompetitionTitleVariant = 'primary' | 'secondary';
export interface EventListCompetitionHeaderProps {
	sportsKey: string;
	enableIcon: boolean;
	competitionKey: string;
	disableSportIcon?: boolean;
	variant?: CompetitionTitleVariant;
	textVariant?: TypographyProps['variant'];
}

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		width: '100%'
	},
	header: {
		flexGrow: 1,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		textAlign: 'left'
	},
	secondary: {
		/**@description medium emphasis */
		opacity: 0.6
	},
	trailingIconSpace: {
		marginLeft: theme.spacing(2)
	}
}));

let EventListCompetitionHeader: React.FunctionComponent<EventListCompetitionHeaderProps> = (
	props
) => {
	const {
		enableIcon,
		competitionKey,
		sportsKey,
		disableSportIcon,
		variant = 'primary',
		textVariant
	} = props;
	const classes = useStyles({ enableIcon });
	const fullName = useSelector(getCompetitionName(competitionKey));
	const categoryKey = useSelector(getCompetitionCategory(competitionKey));
	const customTextVariant = textVariant
		? textVariant
		: variant === 'primary'
		? 'subtitle2'
		: 'caption';
	return (
		<div className={classes.root}>
			{enableIcon && (
				<EventGroupHeaderIcon
					categoryKey={categoryKey}
					sportsKey={sportsKey}
					disableSportIcon={disableSportIcon}
				/>
			)}
			<Typography
				variant={customTextVariant}
				className={clx(classes.header, {
					[classes.secondary]: variant === 'secondary',
					[classes.trailingIconSpace]: !enableIcon
				})}
			>
				{fullName}
			</Typography>
		</div>
	);
};

EventListCompetitionHeader = React.memo(EventListCompetitionHeader);

export { EventListCompetitionHeader };
