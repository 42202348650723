"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateForm = exports.isFormDisabled = void 0;
__exportStar(require("./auth"), exports);
exports.isFormDisabled = function (values) {
    return Object.values(values).some(function (el) { return !el.optional && (!el.value || !!el.error); });
};
exports.validateForm = function (values, validators) {
    var validations = values.map(function (value) {
        var failedValidation = (validators[value.validator] || []).find(function (validation) { return !validation.isValid(value.value); });
        return {
            key: value.key,
            isValid: !failedValidation,
            message: failedValidation ? failedValidation.message : ''
        };
    });
    return validations.reduce(function (acc, _a) {
        var key = _a.key, isValid = _a.isValid, message = _a.message;
        if (!isValid) {
            acc[key] = {
                value: values.find(function (value) { return value.key === key; }).value,
                error: message
            };
        }
        return acc;
    }, {});
};
