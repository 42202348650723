import { isMobile } from '@gaming-shell/device';
import { MarketType } from 'sports-sdk';

import { MarketConfig, MarketView, TabConfiguration } from '../types';

const allMarkets: Record<string, MarketConfig[]> = {
	popular: [
		{
			marketGroup: [
				{
					marketType: MarketType.ice_hockey_handicap,
					view: MarketView.Handicap,
					disableAltLines: true
				},
				{
					marketType: MarketType.ice_hockey_totals,
					view: MarketView.Goals,
					disableAltLines: true
				},
				{
					marketType: MarketType.ice_hockey_winner,
					disableAltLines: true
				}
			],
			view: MarketView.GameLine
		},

		{
			marketType: MarketType.ice_hockey_handicap,
			view: MarketView.Handicap,
			collapsedByDefault: isMobile(),
			disableMainLine: true
		},
		{
			marketType: MarketType.ice_hockey_totals,
			view: MarketView.Goals,
			disableMainLine: true,
			collapsedByDefault: isMobile()
		},
		{
			marketType: MarketType.ice_hockey_team_total,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.ice_hockey_correct_score,
			view: MarketView.CorrectScore
		},
		{
			marketType: MarketType.ice_hockey_1x2
		},
		{
			marketType: MarketType.ice_hockey_handicap_regular_time,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.ice_hockey_total,
			view: MarketView.Goals
		},
		{
			marketType:
				MarketType.ice_hockey_correct_score_incl_overtime_and_penalties
		}
	],
	goals: [
		{
			marketType: MarketType.ice_hockey_both_teams_to_score
		},
		{
			marketType: MarketType.ice_hockey_period_both_teams_to_score
		},
		{
			marketType: MarketType.ice_hockey_exact_goals
		},
		{
			marketType: MarketType.ice_hockey_team_exact_goals
		},
		{
			marketType: MarketType.ice_hockey_goal_nr
		},
		{
			marketType: MarketType.ice_hockey_team_to_score_in_all_periods
		},
		{
			marketType: MarketType.ice_hockey_which_team_to_score
		},
		{
			marketType: MarketType.ice_hockey_last_goal
		},
		{
			marketType: MarketType.ice_hockey_all_periods_under
		}
	],
	specials: [
		{
			marketType: MarketType.ice_hockey_period_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.ice_hockey_period_total,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.ice_hockey_period_last_team_to_score
		},
		{
			marketType: MarketType.ice_hockey_period_correct_score
		},
		{
			marketType: MarketType.ice_hockey_period_which_team_to_score
		},
		{
			marketType: MarketType.ice_hockey_winning_margin
		},
		{
			marketType: MarketType.ice_hockey_highest_scoring_period
		},
		{
			marketType: MarketType.ice_hockey_team_clean_sheet
		},
		{
			marketType: MarketType.ice_hockey_period_1x2
		},
		{
			marketType:
				MarketType.ice_hockey_which_team_wins_the_rest_of_the_match
		},
		{
			marketType: MarketType.ice_hockey_1x2_both_teams_to_score
		},
		{
			marketType: MarketType.ice_hockey_double_chance
		},
		{
			marketType:
				MarketType.ice_hockey_winning_margin_incl_overtime_and_penalties
		}
	]
};

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: allMarkets.popular
	},
	{
		label: 'markets.goals',
		markets: allMarkets.goals
	},
	{
		label: 'markets.specials',
		markets: allMarkets.specials
	},
	{
		label: 'descriptions.all',
		markets: [
			...allMarkets.popular,
			...allMarkets.goals,
			...allMarkets.specials
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.ice_hockey_winner
			},
			{
				marketType: MarketType.ice_hockey_totals
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.ice_hockey_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
