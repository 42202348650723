import * as cookie from 'cookie';

import { PersistedBetslip } from '../types';

export const getPersistedBetslip = () => {
	const cookies = cookie.parse(document.cookie);
	const stringified = cookies['betslip'];
	if (!stringified) {
		return undefined;
	}
	try {
		const betslip = JSON.parse(stringified);
		return betslip as PersistedBetslip;
	} catch (_) {
		return undefined;
	}
};
