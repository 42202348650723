"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./BrasilFlagRound"), exports);
__exportStar(require("./ChinaFlagRound"), exports);
__exportStar(require("./GreeceFlagRound"), exports);
__exportStar(require("./GermanFlagRound"), exports);
__exportStar(require("./HungaryFlagRound"), exports);
__exportStar(require("./IndiaFlagRound"), exports);
__exportStar(require("./IndonesiaFlagRound"), exports);
__exportStar(require("./ItalyFlagRound"), exports);
__exportStar(require("./JapanFlagRound"), exports);
__exportStar(require("./LatviaFlagRound"), exports);
__exportStar(require("./MaltaFlagRound"), exports);
__exportStar(require("./NetherlandsFlagRound"), exports);
__exportStar(require("./PolandFlagRound"), exports);
__exportStar(require("./PortugalFlagRound"), exports);
__exportStar(require("./RussiaFlagRound"), exports);
__exportStar(require("./SouthKoreaFlagRound"), exports);
__exportStar(require("./SpainFlagRound"), exports);
__exportStar(require("./TaiwanFlagRound"), exports);
__exportStar(require("./TurkeyFlagRound"), exports);
__exportStar(require("./FranceFlagRound"), exports);
__exportStar(require("./EnglandFlagRound"), exports);
__exportStar(require("./UnitedKingdomRound"), exports);
