import { Translate } from '@gaming-shell/i18n';
import { Box, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { resultPrecision } from 'sports/config/app';
import { formatPrecision } from 'sports/utils/number';
import { useFormatOdds } from 'sports/utils/odds';

import { betslipActions } from '../betslipReducer';
import { useBetslipDispatch } from '../hooks/useBetslipDispatch';
import { BigBetslipParlayStakeInput } from './BigBetslipParlayStakeInput';
import { CurrentEditStakeView } from './types';

export interface BigBetslipTotalSummaryProps {
	price: number;
	stake: number;
	potentialWin: string;
	currencyLabel: string;
	setCurrentEditStakeView: (view: CurrentEditStakeView) => void;
}
const useStyles = makeStyles({
	label: {
		flex: 1
	},
	result: {
		whiteSpace: 'nowrap',
		lineHeight: 1,
		width: '40%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	stakeInput: {
		width: '40%'
	}
});
export const BigBetslipParlaySummary: React.FunctionComponent<BigBetslipTotalSummaryProps> = (
	props
) => {
	const {
		price,
		potentialWin,
		currencyLabel,
		stake,
		setCurrentEditStakeView
	} = props;
	const dispatch = useBetslipDispatch();
	const onStakeChange = (stake: number) =>
		dispatch(betslipActions.updateMultipleStake({ stake, system: 0 }));
	const onEditStake = () =>
		setCurrentEditStakeView({ multiple: { system: 0 } });

	const formatOdds = useFormatOdds();
	const formattedParlayOdds = formatOdds(price);
	const potentialWinValue = parseFloat(potentialWin);
	const classes = useStyles();
	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography color="textSecondary">
					<Translate label="betting.odds" ns="sports" />
				</Typography>
				<Typography color="textSecondary">
					{formattedParlayOdds}
				</Typography>
			</Box>
			<Box display="flex" justifyContent="space-between">
				<Typography color="textSecondary">
					<Translate label="betting.totalStake" ns="sports" />
				</Typography>
				<div className={classes.stakeInput}>
					<BigBetslipParlayStakeInput
						stake={stake}
						onStakeChange={onStakeChange}
						onEditStake={onEditStake}
					/>
				</div>
			</Box>
			<Box display="flex" justifyContent="space-between">
				<Typography color="textPrimary" className={classes.label}>
					<Translate label="betting.potentialTotalWin" ns="sports" />
				</Typography>
				<Typography color="textPrimary" className={classes.result}>
					<span>{currencyLabel} </span>
					<span>
						{formatPrecision(potentialWinValue, resultPrecision)}
					</span>
				</Typography>
			</Box>
		</>
	);
};
