import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { usePlayerLoggedIn } from '@materialbet-core/player';
import * as React from 'react';
import { useHistory } from 'react-router';

interface RedirectLoggedOutUserButtonProps {
	to?: string;
	ButtonComponent?: React.ComponentType<ButtonProps>;
}

// If to path is provided, button will redirect user onClick when not logged in. If no path provided, button will behave as normal button.
export const RedirectLoggedOutUserButton: React.FunctionComponent<RedirectLoggedOutUserButtonProps &
	ButtonProps> = (props) => {
	const isLoggedIn = usePlayerLoggedIn();
	const history = useHistory();
	const { to, onClick, ButtonComponent = Button, ...buttonProps } = props;
	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		if (to && !isLoggedIn) {
			history.push(to);
			return;
		}
		if (onClick) {
			onClick(event);
		}
	};
	return <ButtonComponent onClick={handleClick} {...buttonProps} />;
};
