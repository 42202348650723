import { filterMap } from 'materialbet-common';
import { PlaceBetV2Bet } from 'sports/api/sportsbetting/api';

import { BetslipReducerBetslip, SupportedSystem } from '../types';
import { createPlaceBetSelection } from './createPlaceBetSelection';
import { PlaceBetPayload } from './types';

export const createMultiplePlaceBetFromBetslip = (
	betslip: BetslipReducerBetslip
): PlaceBetPayload => {
	const selections = betslip.selections.map(createPlaceBetSelection);
	const bets = filterMap(
		Object.keys(betslip.multiples),
		(k) => betslip.multiples[k].stake,
		(system) => {
			const multiple = betslip.multiples[system];
			const bet: PlaceBetV2Bet = {
				stake: multiple.stake.toString(),
				multiple: { systemValue: parseInt(system) as SupportedSystem }
			};
			return bet;
		}
	);
	return { selections, bets };
};
