"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DesktopHomeAppBar = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var player_1 = require("@materialbet-core/player");
var betterAppBar_1 = require("../../../..");
var hooks_1 = require("../../../../../SideMenuDrawer/hooks");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var DesktopHomeAppBar = function (_a) {
    var prevType = _a.prevType;
    var isLoggedIn = player_1.usePlayerLoggedIn();
    var loading = player_1.usePlayerLoading();
    var lang = i18n_1.useLanguage();
    var toggleSideMenu = hooks_1.useToggleSideMenuDrawer();
    var translate = i18n_1.useTranslation();
    var showPromotionNav = (!isLoggedIn && !loading && PROMOTIONS_URL) || ENABLE_PROMOTION_PAGE;
    var supportedApps = SUPPORTED_APPS.length;
    var hasEsport = ENABLE_ESPORTS_SECTION && SUPPORTED_APPS.includes('esports');
    var hideFrom = LEGACY_URL || BLOG_URL
        ? supportedApps + (showPromotionNav ? 1 : 0)
        : undefined;
    return (React.createElement(React.Fragment, null,
        React.createElement(betterAppBar_1.BrandWidthContainer, null,
            React.createElement(betterAppBar_1.MainNavButton, { type: "menu", fromType: prevType, onClick: toggleSideMenu }),
            React.createElement(betterAppBar_1.BrandLogo, null)),
        React.createElement(betterAppBar_1.AppBarCenter, null,
            React.createElement(betterAppBar_1.NavItems, { hideFrom: hideFrom },
                React.createElement(betterAppBar_1.NavItem, { href: materialbet_common_1.SportsRoutes.root.create(), route: materialbet_common_1.SportsRoutes.root.template },
                    React.createElement(i18n_1.Translate, { label: "subjects.sports.sports" })),
                hasEsport && (React.createElement(betterAppBar_1.NavItem, { href: materialbet_common_1.EsportsRoutes.root.create(), route: materialbet_common_1.EsportsRoutes.root.template },
                    React.createElement(i18n_1.Translate, { label: "subjects.sports.esports" }))),
                React.createElement(betterAppBar_1.NavItem, { href: materialbet_common_1.CasinoRoutes.root.create(), route: materialbet_common_1.CasinoRoutes.root.template },
                    React.createElement(i18n_1.Translate, { label: "casino.content.rngCasino" })),
                !isLoggedIn && !loading && PROMOTIONS_URL ? (React.createElement(betterAppBar_1.NavItem, { href: PROMOTIONS_URL.replace(':locale', lang), component: "a" },
                    React.createElement(i18n_1.Translate, { label: "application.content.promotions" }))) : ENABLE_PROMOTION_PAGE ? (React.createElement(betterAppBar_1.NavItem, { href: materialbet_common_1.PromotionsRoutes.root.create(), route: materialbet_common_1.PromotionsRoutes.root.template },
                    React.createElement(i18n_1.Translate, { label: "application.content.promotions" }))) : null,
                BLOG_URL && (React.createElement(betterAppBar_1.NavItem, { href: BLOG_URL.replace(':locale', lang), component: "a" },
                    React.createElement(i18n_1.Translate, { label: "application.content.blog" }))),
                LEGACY_URL && (React.createElement(betterAppBar_1.NavItem, { href: LEGACY_URL, component: "a" }, translate('descriptions.legacyVersionOfBrand', {
                    brand: BRAND_DISPLAY_NAME
                }))))),
        React.createElement(betterAppBar_1.Search, null),
        React.createElement(betterAppBar_1.DesktopActionBarSwitch, null,
            React.createElement(betterAppBar_1.DesktopSignedInActionBar, null),
            React.createElement(betterAppBar_1.DesktopNotSignedInActionBar, null))));
};
exports.DesktopHomeAppBar = DesktopHomeAppBar;
exports.DesktopHomeAppBar = DesktopHomeAppBar = React.memo(DesktopHomeAppBar);
