import { I18nSettings, Provider as LanguageProvider } from '@gaming-shell/i18n';
import { AppLayout } from '@gaming-shell/layout';
import { historyStack, HistoryStorageKey, Router } from '@gaming-shell/routing';
import { ThemeKey, ThemeProvider } from '@gaming-shell/theming';
import { PlayerProviders } from '@materialbet-core/player';
import { AuthProviderWrapper } from 'materialbet-auth';
import { SnackbarProvider, useBeforeUnload } from 'materialbet-common';
import { ExchangeRatesProvider } from 'materialbet-exchange-rates';
import { GraphqlProvider } from 'materialbet-player';
import * as React from 'react';

import { PusherProvider } from './internal/PusherProvider';
import { PlayerInboxReduxProvider } from 'materialbet-inbox';

const onBeforeUnloadHandler = () => {
	if (historyStack.length > 0) {
		const historyStackToSave = historyStack.slice(
			historyStack.length - MAX_HISTORY_RECORDS_STORED,
			historyStack.length
		);
		sessionStorage.setItem(
			HistoryStorageKey,
			JSON.stringify(historyStackToSave)
		);
	}
};

// we will put the theme provider in a seperate package with added statefull logic for dynamically changing the theme
export const Provider: React.FunctionComponent<unknown> = React.memo(
	({ children }) => {
		// TODO this should be loaded from somewhere probably... (global env variable)
		const i18nSettingsRef = React.useRef({
			debug: false,
			defaultLanguage: DEFAULT_LANGUAGE || FALLBACK_LANGUAGE
		} as I18nSettings);
		const defaultTheme = DEFAULT_THEME as ThemeKey;
		useBeforeUnload(onBeforeUnloadHandler);
		return (
			<Router>
				<ThemeProvider defaultTheme={defaultTheme}>
					<AuthProviderWrapper>
						<GraphqlProvider>
							<ExchangeRatesProvider>
								<LanguageProvider
									settings={i18nSettingsRef.current}
								>
									<PlayerProviders>
										<PusherProvider>
											<SnackbarProvider>
												<PlayerInboxReduxProvider>
													<AppLayout>
														{children}
													</AppLayout>
												</PlayerInboxReduxProvider>
											</SnackbarProvider>
										</PusherProvider>
									</PlayerProviders>
								</LanguageProvider>
							</ExchangeRatesProvider>
						</GraphqlProvider>
					</AuthProviderWrapper>
				</ThemeProvider>
			</Router>
		);
	}
);
