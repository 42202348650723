import { createAction } from 'typesafe-actions';

export type EntitiesType<TEntitiyKeys extends string> = {
	[key in TEntitiyKeys]: {
		[key: string]: unknown;
	};
};
export const createUpdateEntitiesAction = <
	TEntities extends EntitiesType<TEntityKeys>,
	TMeta,
	TType extends string,
	TEntityKeys extends string
>(
	type: TType
): UpdateEntitiesAction<TEntities, TMeta> =>
	createAction(type, (resolve) => (entities: TEntities, meta?: TMeta) =>
		resolve(entities, meta)
	) as UpdateEntitiesAction<TEntities, TMeta>;

export type UpdateEntitiesAction<TEntities, TMetaPayload> = (
	entities: TEntities,
	meta?: TMetaPayload
) => { type: string; payload: TEntities; meta?: TMetaPayload };
