import { Box, BoxProps, Typography } from '@material-ui/core';
import * as React from 'react';

export const ConsentBoxTitle: React.FunctionComponent<BoxProps> = ({
	children,
	...rest
}) => (
	<Box paddingBottom={2} {...rest}>
		<Typography role="consent-box-title" variant="body1">
			{children}
		</Typography>
	</Box>
);
