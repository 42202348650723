"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResetPassword = void 0;
var queryString = __importStar(require("querystring"));
var device_1 = require("@gaming-shell/device");
var i18n_1 = require("@gaming-shell/i18n");
var layout_1 = require("@gaming-shell/layout");
var api_1 = require("@materialbet-core/api");
var __1 = require("../..");
var player_1 = require("@materialbet-core/player");
var ResetPasswordLayout_1 = require("./ResetPasswordLayout");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
exports.ResetPassword = function (props) {
    var _a = React.useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var trans = i18n_1.useTranslation();
    var enqueueSnackbar = materialbet_common_1.useSnackbar().enqueueSnackbar;
    var authToken = player_1.usePlayerAuthToken();
    var query = queryString.parse(props.location.search.split('?')[1]);
    var updatePlayer = player_1.useUpdatePlayer();
    var resetPassword = function (csrfToken) { return function (password) {
        if (!query.reset_password_token) {
            enqueueSnackbar(trans('player:security.resetFailed'));
            props.history.push(materialbet_common_1.BaseRoutes.root.create());
            return;
        }
        setIsSubmitting(true);
        api_1.api.putResetPassword({
            password: password,
            // eslint-disable-next-line @typescript-eslint/camelcase
            password_confirmation: password,
            // eslint-disable-next-line @typescript-eslint/camelcase,
            reset_password_token: query.reset_password_token
        }, csrfToken)
            .then(function (response) {
            var token = response
                .authenticity_token;
            if (response.result === 'INVALID_AUTHENTICITY_TOKEN') {
                updatePlayer(function (player) { return (__assign(__assign({}, player), { authToken: token })); });
                // setTimeout ensures that the next sign in placed in an event queue which is called after this method returned (js version of defer)
                setTimeout(function () { return resetPassword(token)(password); });
                return;
            }
            if (response.result === 'OK') {
                enqueueSnackbar(trans('player:security.resetSuccess'));
                props.history.push(materialbet_common_1.BaseRoutes.root.create());
            }
            else if (response.result === 'TOKEN_INVALID') {
                enqueueSnackbar(trans('player:security.resetFailed'));
                props.history.push(materialbet_common_1.BaseRoutes.root.create());
            }
            else if (response.result === 'VALIDATION_ERROR' &&
                response.params['player[reset_password_token]']) {
                props.history.push(materialbet_common_1.AuthRoutes.signIn.create());
                enqueueSnackbar(trans('player:security.resetFailed'));
            }
            else if (response.result === 'SELF_EXCLUSION') {
                enqueueSnackbar(trans('player:signIn.selfExclusion'));
                props.history.push(materialbet_common_1.AuthRoutes.signIn.create());
            }
            else if (response.result === 'VALIDATION_ERROR' &&
                response.params['player[password]']) {
                enqueueSnackbar(trans('player:security.passwordMustHave'));
            }
        })
            .catch(function () {
            enqueueSnackbar(trans('player:descriptions.failedRequest'));
        })
            .finally(function () { return setIsSubmitting(false); });
    }; };
    return (React.createElement(React.Fragment, null,
        React.createElement(layout_1.DefaultAppBar, { type: device_1.isDesktop() ? 'home' : 'page', title: trans('player:security.resetPass') }),
        React.createElement(__1.AuthWrapp, { fullScreen: true },
            React.createElement(__1.SingleColumnLayout, { title: React.createElement(i18n_1.Translate, { label: "security.resetPass", ns: "player" }) },
                React.createElement(ResetPasswordLayout_1.ResetPasswordLayout, { onSubmitClick: resetPassword(authToken), passwordLabel: React.createElement(i18n_1.Translate, { label: "profile.password", ns: "player" }), submitButtonText: React.createElement(i18n_1.Translate, { label: "security.resetPass", ns: "player" }), validations: { password: __1.authValidators.password }, isSubmitting: isSubmitting })))));
};
