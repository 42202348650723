/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
	OutrightSelection,
	OutrightSelectionFromJSON,
	OutrightSelectionFromJSONTyped,
	OutrightSelectionToJSON
} from './';

/**
 * OutrightForCompetition is used for the /sports/competitions/{key}/outrights endpoint
 * This is a subset of Outright, please keep index compatibility with Outright
 * @export
 * @interface OutrightForCompetition
 */
export interface OutrightForCompetition {
	/**
	 *
	 * @type {boolean}
	 * @memberof OutrightForCompetition
	 */
	blank?: boolean;
	/**
	 *
	 * @type {string}
	 * @memberof OutrightForCompetition
	 */
	finishTime?: string;
	/**
	 *
	 * @type {number}
	 * @memberof OutrightForCompetition
	 */
	id?: number;
	/**
	 *
	 * @type {string}
	 * @memberof OutrightForCompetition
	 */
	key?: string;
	/**
	 *
	 * @type {string}
	 * @memberof OutrightForCompetition
	 */
	name?: string;
	/**
	 *
	 * @type {Array<OutrightSelection>}
	 * @memberof OutrightForCompetition
	 */
	selections?: Array<OutrightSelection>;
	/**
	 *
	 * @type {string}
	 * @memberof OutrightForCompetition
	 */
	status?: string;
}

export function OutrightForCompetitionFromJSON(
	json: any
): OutrightForCompetition {
	return OutrightForCompetitionFromJSONTyped(json, false);
}

export function OutrightForCompetitionFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): OutrightForCompetition {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		blank: !exists(json, 'blank') ? undefined : json['blank'],
		finishTime: !exists(json, 'finishTime')
			? undefined
			: json['finishTime'],
		id: !exists(json, 'id') ? undefined : json['id'],
		key: !exists(json, 'key') ? undefined : json['key'],
		name: !exists(json, 'name') ? undefined : json['name'],
		selections: !exists(json, 'selections')
			? undefined
			: (json['selections'] as Array<any>).map(OutrightSelectionFromJSON),
		status: !exists(json, 'status') ? undefined : json['status']
	};
}

export function OutrightForCompetitionToJSON(
	value?: OutrightForCompetition | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		blank: value.blank,
		finishTime: value.finishTime,
		id: value.id,
		key: value.key,
		name: value.name,
		selections:
			value.selections === undefined
				? undefined
				: (value.selections as Array<any>).map(OutrightSelectionToJSON),
		status: value.status
	};
}
