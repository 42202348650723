"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStaticMedia = exports.getCloudinaryImageUrl = void 0;
var array_1 = require("../utils/array");
var cloudinaryOptions = {
    // Dimensions
    width: 'w_{{value}}',
    height: 'h_{{value}}',
    // Position and cropping
    gravity: 'g_{{value}}',
    crop: 'c_{{value}}',
    // Styling
    opacity: 'o_{{value}}',
    background: 'b_rgb:{{value}}',
    // Image Quality
    quality: 'q_{{value}}',
    fetchFormat: 'f_{{value}}',
    devicePixelRatio: 'dpr_{{value}}'
};
var defaultOptions = {
    crop: 'fill',
    gravity: 'center',
    quality: 'auto:best',
    fetchFormat: 'auto',
    devicePixelRatio: 'auto'
};
/** @description image url helper to generate image url with cloudinary params prefix */
exports.getCloudinaryImageUrl = function (props) {
    var imageUrl = props.imageUrl, background = props.background, restProps = __rest(props, ["imageUrl", "background"]);
    var backgroundColorCode = background === null || background === void 0 ? void 0 : background.substring(1);
    var params = __assign(__assign(__assign({}, defaultOptions), restProps), { background: backgroundColorCode });
    // Base Url
    var url = CLOUDINARY_URL + '/image/fetch/';
    // Cloudinary Params
    var options = array_1.filterMap(Object.keys(cloudinaryOptions), 
    /* filter out the ones we can't translate https://bitgamelabs.slack.com/archives/CST11DK46/p1589199487071000 */
    function (key) { return params[key] !== undefined; }, function (key) {
        var param = params[key];
        var option = cloudinaryOptions[key];
        return option.replace('{{value}}', param);
    });
    url += options.join(',');
    // Actual Image
    url += "/" + imageUrl;
    return url;
};
/** @description image url helper to generate a string to point to local resources of a given static path*/
exports.getStaticMedia = function (path) {
    var auth = "https://" + BASE_SITE_BASIC_AUTH;
    var host = BASE_HOST || window.location.hostname;
    return auth + host + path;
};
