import {
	Card,
	CardActions,
	CardContent,
	Collapse,
	IconButton,
	makeStyles,
	Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React from 'react';

import { useToggle } from '../../hooks';

export interface ContentBlurbProps {
	title?: string;
	description?: string;
}

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	},
	heading: {
		padding: theme.spacing(2, 2, 0, 2),
		display: 'flex',
		justifyContent: 'space-between'
	},
	body: {
		padding: `${theme.spacing(1, 2, 2, 2)} !important`
	},
	description: {
		lineHeight: 1.5
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	}
}));

export const ContentBlurb: React.FC<ContentBlurbProps> = ({
	title,
	description
}) => {
	const [expanded, toggleExpansion] = useToggle(false);
	const classes = useStyles();
	if (!title || !description) {
		return null;
		// we dont use any skeleton as this will be a the bottom of the page,
		//and if no content is provided will not render at all
	}
	return (
		<Card className={classes.root}>
			<CardActions disableSpacing className={classes.heading}>
				<Typography
					component="h1"
					color="textSecondary"
					variant="body1"
				>
					{title}
				</Typography>
				<IconButton
					onClick={toggleExpansion}
					size="small"
					className={clsx({
						[classes.expandOpen]: expanded
					})}
				>
					<ExpandMoreIcon color="disabled" />
				</IconButton>
			</CardActions>
			<Collapse in={expanded} collapsedHeight={52}>
				<CardContent className={classes.body}>
					<Typography
						color="textSecondary"
						variant="caption"
						className={classes.description}
					>
						{description}
					</Typography>
				</CardContent>
			</Collapse>
		</Card>
	);
};
