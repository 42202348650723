"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLanguage = exports.useAddTranslations = exports.useChangeLanguage = exports.useTranslation = void 0;
var cookie_1 = require("./internal/cookie");
var phraseApp_1 = require("./phraseApp");
exports.useTranslation = function () {
    var t = phraseApp_1.useTranslation().t;
    return t;
};
exports.useChangeLanguage = function () {
    var i18n = phraseApp_1.useTranslation().i18n;
    return function (language) {
        i18n.changeLanguage(language);
        cookie_1.setLanguageInCookie(language);
    };
};
exports.useAddTranslations = function () {
    var i18n = phraseApp_1.useTranslation().i18n;
    return function (ln, ns, resources, deep, overwrite) { return i18n.addResourceBundle(ln, ns, resources, deep, overwrite); };
};
exports.useLanguage = function () {
    var i18n = phraseApp_1.useTranslation().i18n;
    return i18n.language;
};
