"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerProviders = exports.PlayerKycDetailsContext = exports.PlayerLocaleContext = exports.PlayerMinDepositAmountsContext = exports.PlayerDepositAddressesContext = exports.PlayerBonusDetailsContext = exports.PlayerTimeZoneContext = exports.PlayerPreferredSportsViewContext = exports.PlayerPreferredOddsFormatContext = exports.PlayerLastBetCreatedAtContext = exports.PlayerForcedRoutesContext = exports.PlayerOnboardingStepsStatusesContext = exports.PlayerIdContext = exports.PlayerNicknameContext = exports.PlayerEmailContext = exports.PlayerAuthTokenContext = exports.PlayerChannelContext = exports.PlayerLoadingContext = exports.PlayerLoggedInContext = exports.PlayerUuidContext = exports.PlayerWalletsContext = exports.PlayerCurrenciesAvailableContext = exports.PlayerCurrencyVariantContext = exports.PlayerCurrencyContext = exports.PlayerUpdateContext = exports.PlayerContext = exports.defaultState = exports.defaultWallet = exports.DEFAULT_PLAYER_UUID = void 0;
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var materialbet_auth_1 = require("materialbet-auth");
var materialbet_player_1 = require("materialbet-player");
exports.DEFAULT_PLAYER_UUID = '00000000-0000-0000-0000-000000000000';
exports.defaultWallet = {
    amount: 0,
    bonusAmount: 0
};
exports.defaultState = {
    id: 0,
    currency: DEFAULT_CURRENCY,
    currencyVariant: '',
    currenciesAvailable: [],
    uuid: exports.DEFAULT_PLAYER_UUID,
    channel: '',
    bonusDetails: {
        eligibleFor: []
    },
    wallets: {},
    isLoggedIn: false,
    authToken: '',
    email: '',
    nickname: null,
    isLoading: true,
    ipCountry: '',
    preferredOddsFormat: 'decimal',
    preferredSportsView: 'european',
    timeZone: '',
    locale: DEFAULT_LANGUAGE,
    lastBetCreatedAt: 0,
    forcedRoutes: {
        sms: false,
        countryRestrictionTier: null,
        onboardingStatuses: {
            signUp: true,
            sports: true
        }
    },
    onboardingStepsStatuses: [],
    depositAddresses: {},
    minDepositAmounts: {},
    stakeFactor: 1,
    kyc: { details: {}, loaded: false }
};
exports.PlayerContext = React.createContext(exports.defaultState);
exports.PlayerUpdateContext = React.createContext(function () { return void 0; });
exports.PlayerCurrencyContext = React.createContext(exports.defaultState.currency);
exports.PlayerCurrencyVariantContext = React.createContext(exports.defaultState.currency);
exports.PlayerCurrenciesAvailableContext = React.createContext(exports.defaultState.currenciesAvailable);
exports.PlayerWalletsContext = React.createContext(exports.defaultState.wallets);
exports.PlayerUuidContext = React.createContext(exports.defaultState.uuid);
exports.PlayerLoggedInContext = React.createContext(exports.defaultState.isLoggedIn);
exports.PlayerLoadingContext = React.createContext(exports.defaultState.isLoading);
exports.PlayerChannelContext = React.createContext(exports.defaultState.channel);
exports.PlayerAuthTokenContext = React.createContext(exports.defaultState.authToken);
exports.PlayerEmailContext = React.createContext(exports.defaultState.email);
exports.PlayerNicknameContext = React.createContext(exports.defaultState.nickname);
exports.PlayerIdContext = React.createContext(exports.defaultState.id);
exports.PlayerOnboardingStepsStatusesContext = React.createContext(exports.defaultState.onboardingStepsStatuses);
exports.PlayerForcedRoutesContext = React.createContext(exports.defaultState.forcedRoutes);
exports.PlayerLastBetCreatedAtContext = React.createContext(exports.defaultState.lastBetCreatedAt);
exports.PlayerPreferredOddsFormatContext = React.createContext(exports.defaultState.preferredOddsFormat);
exports.PlayerPreferredSportsViewContext = React.createContext(exports.defaultState.preferredSportsView);
exports.PlayerTimeZoneContext = React.createContext(exports.defaultState.timeZone);
exports.PlayerBonusDetailsContext = React.createContext(exports.defaultState.bonusDetails);
exports.PlayerDepositAddressesContext = React.createContext(exports.defaultState.depositAddresses);
exports.PlayerMinDepositAmountsContext = React.createContext(exports.defaultState.minDepositAmounts);
exports.PlayerLocaleContext = React.createContext(exports.defaultState.locale);
exports.PlayerKycDetailsContext = React.createContext(exports.defaultState.kyc);
exports.PlayerProviders = function (_a) {
    var children = _a.children;
    var _b = React.useState(exports.defaultState), player = _b[0], setPlayer = _b[1];
    var authEnabled = materialbet_common_1.hasAuthEnabled();
    var auth = materialbet_auth_1.useAuth();
    var _c = materialbet_player_1.useGetCurrentPlayer(), username = _c.username, timeZone = _c.timeZone, locale = _c.locale, isLoading = _c.isLoading;
    return (React.createElement(exports.PlayerUpdateContext.Provider, { value: setPlayer },
        React.createElement(exports.PlayerContext.Provider, { value: player },
            React.createElement(exports.PlayerDepositAddressesContext.Provider, { value: player.depositAddresses },
                React.createElement(exports.PlayerMinDepositAmountsContext.Provider, { value: player.minDepositAmounts },
                    React.createElement(exports.PlayerCurrencyContext.Provider, { value: player.currency },
                        React.createElement(exports.PlayerCurrencyVariantContext.Provider, { value: player.currencyVariant },
                            React.createElement(exports.PlayerCurrenciesAvailableContext.Provider, { value: player.currenciesAvailable },
                                React.createElement(exports.PlayerAuthTokenContext.Provider, { value: player.authToken },
                                    React.createElement(exports.PlayerWalletsContext.Provider, { value: player.wallets },
                                        React.createElement(exports.PlayerBonusDetailsContext.Provider, { value: player.bonusDetails },
                                            React.createElement(exports.PlayerUuidContext.Provider, { value: player.uuid },
                                                React.createElement(exports.PlayerLoggedInContext.Provider, { value: authEnabled
                                                        ? auth
                                                        : player.isLoggedIn },
                                                    React.createElement(exports.PlayerChannelContext.Provider, { value: player.channel },
                                                        React.createElement(exports.PlayerEmailContext.Provider, { value: player.email },
                                                            React.createElement(exports.PlayerNicknameContext.Provider, { value: authEnabled
                                                                    ? username
                                                                    : player.nickname },
                                                                React.createElement(exports.PlayerLoadingContext.Provider, { value: authEnabled
                                                                        ? isLoading
                                                                        : player.isLoading },
                                                                    React.createElement(exports.PlayerIdContext.Provider, { value: player.id },
                                                                        React.createElement(exports.PlayerForcedRoutesContext.Provider, { value: player.forcedRoutes },
                                                                            React.createElement(exports.PlayerPreferredOddsFormatContext.Provider, { value: player.preferredOddsFormat },
                                                                                React.createElement(exports.PlayerPreferredSportsViewContext.Provider, { value: player.preferredSportsView },
                                                                                    React.createElement(exports.PlayerLastBetCreatedAtContext.Provider, { value: player.lastBetCreatedAt },
                                                                                        React.createElement(exports.PlayerTimeZoneContext.Provider, { value: authEnabled
                                                                                                ? timeZone
                                                                                                : player.timeZone },
                                                                                            React.createElement(exports.PlayerLocaleContext.Provider, { value: authEnabled
                                                                                                    ? locale
                                                                                                    : player.locale },
                                                                                                React.createElement(exports.PlayerOnboardingStepsStatusesContext.Provider, { value: player.onboardingStepsStatuses },
                                                                                                    React.createElement(exports.PlayerKycDetailsContext.Provider, { value: player.kyc }, children))))))))))))))))))))))))));
};
