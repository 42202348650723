import { isMobile } from '@gaming-shell/device';
import clsx from 'clsx';
import * as React from 'react';
import Slider, { Settings } from 'react-slick';

import { useSlidesToScroll } from '../../utils/sliderUtils';
import { PreviewHeader } from '../PreviewHeader';
import { Slider as SliderComponent } from '../Slider';
import { PreviewSkeleton } from './Preview.skeleton';
import { useStyles } from './Preview.styles';

export interface PreviewProps {
	/** @description Width of the slider in px */
	width?: number; //Keep, because passing in className will override styles.
	/** @description Width of the slide in px */
	slideWidth: number;
	loaded: boolean;
	/** @description className for the slider */
	className?: string;
	totalCount?: number;
	title?: React.ReactNode;
	seeAllHref?: string;
	sliderElements?: JSX.Element[];
	/** @description component for slide skeleton to be rendered */
	slideSkeleton?: React.ReactNode;
	/** @description number of slide skeleton to be shown */
	skeletonItems?: number;
}
/**@description Base slider + heading component for preview */
let Preview: React.FunctionComponent<PreviewProps> = (props) => {
	const {
		width,
		slideWidth,
		className,
		loaded,
		totalCount,
		seeAllHref,
		title,
		sliderElements,
		slideSkeleton,
		skeletonItems
	} = props;
	const classes = useStyles();
	const divRef = React.useRef<HTMLDivElement | null>(null);
	const sliderRef = React.useRef<Slider | null>(null);
	const divWidth = divRef.current?.scrollWidth;
	const slidesToScrollNumber = useSlidesToScroll({
		sliderWidth: width || divWidth,
		slideWidth
	});
	const [slideIndex, setSlideIndex] = React.useState(0);

	const onNextClick = React.useCallback(() => {
		sliderRef.current?.slickNext();
	}, []);

	const onPrevClick = React.useCallback(() => {
		sliderRef.current?.slickPrev();
	}, []);

	const afterChangeCallback = React.useCallback((currentIndex: number) => {
		setSlideIndex && setSlideIndex(currentIndex);
	}, []);

	const settings: Settings = {
		/**@description currentIndex will jump by the number slidesToScroll is set  */
		afterChange: afterChangeCallback,
		infinite: false,
		variableWidth: true,
		initialSlide: 0,
		arrows: false,
		dots: false,
		className: clsx(classes.slider, className),
		slidesToScroll: slidesToScrollNumber
	};
	if (!loaded) {
		return (
			<PreviewSkeleton
				className={className}
				skeleton={slideSkeleton}
				items={skeletonItems}
			/>
		);
	}
	if (!sliderElements || !sliderElements.length) {
		return null;
	}
	const mobileSliderView = (
		<div className={clsx(classes.mobileSlider, className)}>
			{sliderElements}
		</div>
	);
	const desktopSliderView = (
		<SliderComponent {...settings} ref={sliderRef}>
			{sliderElements}
		</SliderComponent>
	);
	return (
		<div ref={divRef} style={{ width }}>
			<PreviewHeader
				count={totalCount || sliderElements?.length || 0}
				seeAllHref={seeAllHref}
				onNextClick={onNextClick}
				onPreviousClick={onPrevClick}
				isNextActive={
					slideIndex <=
					(sliderElements?.length || 0) - slidesToScrollNumber - 1
				}
				isPreviousActive={slideIndex !== 0}
				title={title}
			/>
			{isMobile() ? mobileSliderView : desktopSliderView}
		</div>
	);
};

Preview = React.memo(Preview);
export { Preview };
