import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

const allMarkets = {
	popular: [
		{
			marketType: MarketType.mma_winner
		},
		{
			marketType: MarketType.mma_1x2
		},
		{
			marketType: MarketType.mma_totals,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.boxing_winner_exact_rounds
		},
		{
			marketType: MarketType.boxing_winning_method
		},
		{
			marketType: MarketType.boxing_will_the_fight_go_the_distance
		}
	],
	specials: [
		{
			marketType: MarketType.mma_freetext
		}
	]
};

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: allMarkets.popular
	},
	{
		label: 'markets.specials',
		markets: allMarkets.specials
	},
	{
		label: 'descriptions.all',
		markets: [...allMarkets.popular, ...allMarkets.specials]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.mma_winner
			},
			{
				marketType: MarketType.mma_totals
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.mma_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
