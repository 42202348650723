import { register, SharedComponentIds } from 'shared-components';
import { SearchConfig } from 'sports/components/SearchConfig';
import { SearchFilters } from 'sports/components/SearchFilters';
import { SearchResults } from 'sports/components/SearchResults';
import { EsportsExternalPreview } from 'sports/components/SportPreview';
import { ProvidedZeroMarginWidget } from 'sports/components/ZeroMarginWidget';
import { SearchResultsProps } from 'sports/types/sharedComponents';

export const registerSharedComponents = () => {
	/* unfortunately casting is required due to algolia wrapper */
	register(
		SharedComponentIds.SportsSearchResults,
		(SearchResults as unknown) as React.FC<SearchResultsProps>
	);
	register(SharedComponentIds.SportsSearchConfigure, SearchConfig);
	register(SharedComponentIds.SportsSearchFilters, SearchFilters);
	register(
		SharedComponentIds.SportsZeroMarginWidget,
		ProvidedZeroMarginWidget
	);
	register(SharedComponentIds.EsportsPreview, EsportsExternalPreview);
};
