"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignUpConfirmation = void 0;
var device_1 = require("@gaming-shell/device");
var i18n_1 = require("@gaming-shell/i18n");
var layout_1 = require("@gaming-shell/layout");
var api_1 = require("@materialbet-core/api");
var __1 = require("../..");
var SignUpConfirmationLayout_1 = require("./SignUpConfirmationLayout");
var player_1 = require("@materialbet-core/player");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var react_router_1 = require("react-router");
exports.SignUpConfirmation = function (props) {
    var updatePlayer = player_1.useUpdatePlayer();
    var _a = React.useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var authToken = player_1.usePlayerAuthToken();
    var enqueueSnackbar = materialbet_common_1.useSnackbar().enqueueSnackbar;
    var trans = i18n_1.useTranslation();
    var resendConfirmation = function (csrfToken) { return function () {
        setIsSubmitting(true);
        api_1.api.postResendConfirmation({
            email: props.location.state.email.trim()
        }, csrfToken)
            .then(function (response) {
            if (response.result === 'INVALID_AUTHENTICITY_TOKEN') {
                var token_1 = response
                    .authenticity_token;
                updatePlayer(function (state) { return (__assign(__assign({}, state), { authToken: token_1 })); });
                // setTimeout ensures that the next sign in placed in an event queue which is called after this method returned (js version of defer)
                setTimeout(function () { return resendConfirmation(token_1)(); });
                return;
            }
            setIsSubmitting(false);
            if (response.result === 'OK') {
                enqueueSnackbar(trans('player:signUp.confirmationEmailSent'));
            }
        })
            .catch(function () {
            setIsSubmitting(false);
            // TODO: handle error
        });
    }; };
    return (React.createElement(React.Fragment, null,
        React.createElement(layout_1.DefaultAppBar, { title: device_1.isDesktop() ? '' : trans('player:signUp.confirmEmail'), type: device_1.isDesktop() ? 'home' : 'window' }),
        React.createElement(__1.AuthWrapp, { fullScreen: true }, !props.location.state ? (React.createElement(react_router_1.Redirect, { to: materialbet_common_1.AuthRoutes.signUp.create() })) : (React.createElement(__1.SingleColumnLayout, { title: React.createElement(i18n_1.Translate, { label: "signUp.confirmEmail", ns: "player" }) },
            React.createElement(SignUpConfirmationLayout_1.SignUpConfirmationLayout, { extraButtonText: React.createElement(i18n_1.Translate, { label: "signUp.confirmationEmailResend", ns: "player" }), extraText: React.createElement(i18n_1.Translate, { label: "signUp.checkSpam", ns: "player" }), mainText: React.createElement(i18n_1.Translate, { label: "signUp.completeSignUp", ns: "player" }), email: props.location.state.email, onExtraButtonClick: resendConfirmation(authToken), isSubmitting: isSubmitting }))))));
};
