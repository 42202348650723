import AmericanFootballImage from 'sports/assets/sports/american-football.jpg';
import AussieRulesImage from 'sports/assets/sports/aussie-rules.jpg';
import BadmintonImage from 'sports/assets/sports/badminton.jpg';
import BandyImage from 'sports/assets/sports/bandy.jpg';
import BaseballImage from 'sports/assets/sports/baseball.jpg';
import BasketballImage from 'sports/assets/sports/basketball.jpg';
import BeachSoccerImage from 'sports/assets/sports/beach-soccer.jpg';
import BeachVolleyImage from 'sports/assets/sports/beach-volleyball.jpg';
import BowlsImage from 'sports/assets/sports/bowls.jpg';
import BoxingImage from 'sports/assets/sports/boxing.jpg';
import CallOfDutyImage from 'sports/assets/sports/call-of-duty.jpg';
import ChessImage from 'sports/assets/sports/chess.jpg';
import CounterStrikeImage from 'sports/assets/sports/counter-strike.jpg';
import CricketImage from 'sports/assets/sports/cricket.jpg';
import CyclingImage from 'sports/assets/sports/cycling.jpg';
import DartsImage from 'sports/assets/sports/darts.jpg';
import Dota2Image from 'sports/assets/sports/dota-2.jpg';
import FieldHockeyImage from 'sports/assets/sports/field-hockey.jpg';
import FifaImage from 'sports/assets/sports/fifa.jpg';
import FloorballImage from 'sports/assets/sports/floorball.jpg';
import FutsalImage from 'sports/assets/sports/futsal.jpg';
import GolfImage from 'sports/assets/sports/golf.jpg';
import greyhoundImage from 'sports/assets/sports/greyhound.jpg';
import HandballImage from 'sports/assets/sports/handball.jpg';
import HearthstoneImage from 'sports/assets/sports/hearthstone.jpg';
import IceHockeyImage from 'sports/assets/sports/ice-hockey.jpg';
import KabaddiImage from 'sports/assets/sports/kabaddi.jpg';
import KingsOfGloryImage from 'sports/assets/sports/kings-of-glory.jpg';
import LeagueOfLegendsImage from 'sports/assets/sports/league-of-legends.jpg';
import MMAImage from 'sports/assets/sports/mma.jpg';
import MotorsportImage from 'sports/assets/sports/motorsport.jpg';
import NBA2KImage from 'sports/assets/sports/nba-2k.jpg';
import OverwatchImage from 'sports/assets/sports/overwatch.jpg';
import PesapalloImage from 'sports/assets/sports/pesapallo.jpg';
import PoliticsImage from 'sports/assets/sports/politics.jpg';
import RainbowSixImage from 'sports/assets/sports/rainbow-six.jpg';
import RocketLeagueImage from 'sports/assets/sports/rocket-league.jpg';
import RugbyLeagueImage from 'sports/assets/sports/rugby-league.jpg';
import RugbyUnionImage from 'sports/assets/sports/rugby-union.jpg';
import SailingImage from 'sports/assets/sports/sailing.jpg';
import SnookerImage from 'sports/assets/sports/snooker.jpg';
import SoccerImage from 'sports/assets/sports/soccer.jpg';
import StarcraftImage from 'sports/assets/sports/starcraft.jpg';
import TableTennisImage from 'sports/assets/sports/table-tennis.jpg';
import TennisImage from 'sports/assets/sports/tennis.jpg';
import VolleyImage from 'sports/assets/sports/volleyball.jpg';
import WaterpoloImage from 'sports/assets/sports/waterpolo.jpg';
import WorldLotteryImage from 'sports/assets/sports/world-lottery.jpg';
import ArenaOfValorImage from 'sports/assets/sports/arena-of-valor.jpg';
import { SportType } from 'sports/config/sport';

export const sportImage: Record<SportType, string> = {
	'american-football': AmericanFootballImage,
	'aussie-rules': AussieRulesImage,
	badminton: BadmintonImage,
	bandy: BandyImage,
	baseball: BaseballImage,
	basketball: BasketballImage,
	'beach-soccer': BeachSoccerImage,
	'beach-volleyball': BeachVolleyImage,
	bowls: BowlsImage,
	boxing: BoxingImage,
	'call-of-duty': CallOfDutyImage,
	chess: ChessImage,
	cricket: CricketImage,
	cycling: CyclingImage,
	darts: DartsImage,
	'field-hockey': FieldHockeyImage,
	floorball: FloorballImage,
	futsal: FutsalImage,
	golf: GolfImage,
	handball: HandballImage,
	hearthstone: HearthstoneImage,
	'ice-hockey': IceHockeyImage,
	mma: MMAImage,
	motorsport: MotorsportImage,
	pesapallo: PesapalloImage,
	'rainbow-six': RainbowSixImage,
	'rocket-league': RocketLeagueImage,
	rugby: RugbyUnionImage, // Its the old one, its just rugby union
	'rugby-union': RugbyUnionImage,
	'rugby-league': RugbyLeagueImage,
	snooker: SnookerImage,
	soccer: SoccerImage,
	sailing: SailingImage,
	starcraft: StarcraftImage,
	'table-tennis': TableTennisImage,
	tennis: TennisImage,
	volleyball: VolleyImage,
	waterpolo: WaterpoloImage,
	'counter-strike': CounterStrikeImage,
	overwatch: OverwatchImage,
	'dota-2': Dota2Image,
	'league-of-legends': LeagueOfLegendsImage,
	'world-lottery': WorldLotteryImage,
	kabaddi: KabaddiImage,
	greyhound: greyhoundImage,
	'esport-arena-of-valor': ArenaOfValorImage,
	'esport-nba2k': NBA2KImage,
	'esport-fifa': FifaImage,
	'esport-king-of-glory': KingsOfGloryImage,
	politics: PoliticsImage
};
