import { Card, CardContent } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import * as React from 'react';

import { SimplePreviewCardClasses } from './SimplePreviewCard';
import { useSimplePreviewCardStyles } from './SimplePreviewCard.styles';

interface SimplePreviewCardSkeletonProps {
	classes?: SimplePreviewCardClasses;
}
export const SimplePreviewCardSkeleton: React.FC<SimplePreviewCardSkeletonProps> = ({
	classes: externalClasses
}) => {
	const {
		simplePreviewCard,
		media,
		cardContent,
		link
	} = useSimplePreviewCardStyles();
	return (
		<div className={link}>
			<Card className={clsx(simplePreviewCard, externalClasses?.card)}>
				<div className={clsx(media, externalClasses?.media)}>
					<Skeleton
						variant="rect"
						component="div"
						height="100%"
						width="100%"
					/>
				</div>
				<CardContent
					className={clsx(cardContent, externalClasses?.cardContent)}
				>
					<Skeleton width="70%" />
				</CardContent>
			</Card>
		</div>
	);
};
