import { makeStyles, Theme } from '@material-ui/core';

import { sportPreviewCardDimensions } from './SportPreviewCard.config';

export const useSportPreviewCardStyles = makeStyles((theme: Theme) => ({
	card: {
		...sportPreviewCardDimensions.xs,
		[theme.breakpoints.up('sm')]: sportPreviewCardDimensions.sm,
		[theme.breakpoints.up('md')]: sportPreviewCardDimensions.md,
		[theme.breakpoints.up('lg')]: sportPreviewCardDimensions.lg,
		[theme.breakpoints.up('xl')]: sportPreviewCardDimensions.xl
	},
	media: {
		height: '75%'
	},
	cardContent: {
		height: '25%' // Get remain space of relative to image, to center the content inside
	}
}));
