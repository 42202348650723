import { useIsBonusAvailable } from '@gaming-shell/layout';
import { makeStyles, MenuItem, Theme, Typography } from '@material-ui/core';
import { Wallet } from '@materialbet-core/player';
import BigNumber from 'bignumber.js';
import {
	Currency,
	formatCurrency,
	getCurrencyVariantConfig
} from 'materialbet-common';
import * as React from 'react';

export interface BalanceMenuItemProps {
	onClick: () => void;
	currency: Currency;
	wallet: Wallet;
	disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	currencyMenuItem: {
		justifyContent: 'space-between'
	},
	currencyName: {
		marginRight: theme.spacing(1)
	}
}));

export const BalanceMenuItem: React.FC<BalanceMenuItemProps> = ({
	onClick,
	wallet: { amount, bonusAmount },
	currency,
	disabled
}) => {
	const classes = useStyles();

	const isBonusAvailable = useIsBonusAvailable(currency);
	const amountToDisplay = isBonusAvailable
		? new BigNumber(amount).plus(bonusAmount).toNumber()
		: amount;
	const currencyLabel = getCurrencyVariantConfig(currency).label;

	return (
		<MenuItem
			onClick={onClick}
			disabled={disabled}
			className={classes.currencyMenuItem}
		>
			<Typography variant="body2" className={classes.currencyName}>
				{currencyLabel}
			</Typography>
			<Typography variant="body2">
				{formatCurrency(amountToDisplay, currency, {
					currencyPosition: 'none',
					keepTrailingZeros: true,
					maxDecimalPlaces: 2
				})}
			</Typography>
		</MenuItem>
	);
};
