import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';

interface QuickBetslipSelectionInfoProps {
	marketName: string;
	outcomeName: string;
}

const useStyles = makeStyles({
	text: {
		flexGrow: 1,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap'
	}
});

export const QuickBetslipSelectionInfo: React.FunctionComponent<QuickBetslipSelectionInfoProps> = ({
	marketName,
	outcomeName
}) => {
	const classes = useStyles();
	return (
		<Box flexGrow="1" overflow="hidden" textOverflow="ellipsis">
			<Typography variant="h6" className={classes.text}>
				{outcomeName}
			</Typography>
			<Box display="flex" alignItems="center" overflow="hidden">
				<Typography className={classes.text}>{marketName}</Typography>
				<br />
			</Box>
		</Box>
	);
};
