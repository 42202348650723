import * as React from 'react';

import { BetslipBetPlacedSnackbar } from '../BetslipBetPlacedSnackbar';
import { BetslipReducerSelection, PlaceBetCallbackArgs } from '../types';

export interface QuickBetslipBetPlacedSnackbarProps {
	betslip: (PlaceBetCallbackArgs & { anchorEl: Element | null }) | null;
	onClose: () => void;
	openQuickBetslip: (
		selection: BetslipReducerSelection,
		anchorEl: Element | null
	) => void;
	closeBetslip: () => void;
}
export const QuickBetslipBetPlacedSnackbar: React.FunctionComponent<QuickBetslipBetPlacedSnackbarProps> = (
	props
) => {
	const { betslip, onClose, openQuickBetslip, closeBetslip } = props;
	const onPlaceAgain = () => {
		onClose();
		if (!betslip) {
			return;
		}
		closeBetslip();
		openQuickBetslip(betslip.betslip.selections[0], betslip.anchorEl);
	};
	return (
		<BetslipBetPlacedSnackbar
			open={!!betslip}
			onPlaceAgain={onPlaceAgain}
			onClose={onClose}
			betUuid={betslip?.betUuid}
		/>
	);
};
