import { Box } from '@material-ui/core';
import * as React from 'react';

export type PromoDetailsRowClassKey = 'divider';

export const PromoDetailsRow: React.FunctionComponent = ({ children }) => (
	<Box display="flex">
		{React.Children.map(
			children,
			(child: any, i) => child && <Box flex={2 - i}>{child}</Box>
		)}
	</Box>
);
