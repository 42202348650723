import * as React from 'react';
import { AuthContext, AuthControlContext } from './AuthContext';
import { checkSignedIn } from 'auth/api';
import { hasAuthEnabled } from 'materialbet-common';

export const AuthProvider: React.FunctionComponent<unknown> = ({
	children
}) => {
	const [auth, setAuth] = React.useState<boolean>(false);
	React.useEffect(() => {
		checkSignedIn().then((res) => {
			if (res.status === 200) {
				setAuth(true);
			} else {
				setAuth(false);
			}
		});
	}, [setAuth]);
	return (
		<AuthControlContext.Provider value={setAuth}>
			<AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
		</AuthControlContext.Provider>
	);
};

export const AuthProviderWrapper: React.FunctionComponent<unknown> = ({
	children
}) => {
	return hasAuthEnabled() ? (
		<AuthProvider>{children}</AuthProvider>
	) : (
		<>{children}</>
	);
};
