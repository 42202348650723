import * as React from 'react';

export type UseHandleFieldChangeHook = [
	string,
	(ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
	() => void
];

/* Handles extracting value from event */
export const useHandleFieldChange = (
	initial: string = ''
): UseHandleFieldChangeHook => {
	const [value, setValue] = React.useState(initial);
	const onChange = React.useCallback(
		(ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			const value = ev?.target?.value;
			// Undefined is essential here as we want to pass '' (empty string) to state
			if (value !== undefined) {
				setValue(value);
			}
		},
		[setValue]
	);
	const clear = React.useCallback(() => setValue(''), [setValue]);

	return [value, onChange, clear];
};
