import { useTranslation } from '@gaming-shell/i18n';
import { makeStyles, Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { formatCurrency, withHook } from 'materialbet-common';
import * as React from 'react';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import { PromotionCounter } from 'sports/components/PromotionCounter';
import {
	getEventPromotionCurrency,
	getEventPromotionCustomerLiabilityLimit,
	getEventPromotionCustomerLiabilityRemains
} from 'sports/selectors/eventPromotionsSelectors';
import { getZeroMarginPromotionId } from 'sports/utils/eventPromotionsUtils';

interface ZeroMarginLiabilityCounterProps {
	eventId: number;
	current: number;
	total: number;
	currency: string;
	isLocked?: boolean;
	hideFooter?: boolean;
	className?: string;
}
const useStyles = makeStyles((theme) => ({
	footer: {
		display: 'block',
		marginTop: theme.spacing(1.5)
	}
}));
let ZeroMarginLiabilityCounterUi: React.FC<ZeroMarginLiabilityCounterProps> = (
	props
) => {
	const { current, total, currency, isLocked, hideFooter, className } = props;
	const classes = useStyles();
	const t = useTranslation();
	const amount = formatCurrency(current, currency, {
		currencyPosition: 'none',
		maxDecimalPlaces: 0
	});
	const title = (
		<CountUp
			start={total}
			end={parseFloat(amount)}
			formattingFn={(amount: number) =>
				t('player:promotions.descriptions.remainingWinAmount', {
					amount: `$${amount}`
				})
			}
			preserveValue
		/>
	);
	const footer = t(
		'player:promotions.descriptions.maximumZeroMarginPotentialWinAmount',
		{ amount: total, currency }
	);
	return (
		<div className={className}>
			<PromotionCounter
				current={parseFloat(amount)}
				total={total}
				isLocked={isLocked}
				title={title}
				color="secondary"
			/>
			<Collapse in={!hideFooter}>
				<Typography
					variant="caption"
					color="textSecondary"
					className={classes.footer}
				>
					{footer}
				</Typography>
			</Collapse>
		</div>
	);
};

ZeroMarginLiabilityCounterUi = React.memo(ZeroMarginLiabilityCounterUi);
export { ZeroMarginLiabilityCounterUi };

export const ZeroMarginLiabilityCounter = withHook(
	({
		eventId,
		className,
		hideFooter
	}: Pick<
		ZeroMarginLiabilityCounterProps,
		'eventId' | 'hideFooter' | 'className'
	>): ZeroMarginLiabilityCounterProps => {
		const promotionId = getZeroMarginPromotionId(eventId);
		const remain =
			useSelector(
				getEventPromotionCustomerLiabilityRemains(promotionId)
			) || 0;
		const currency =
			useSelector(getEventPromotionCurrency(promotionId)) || 'USD';
		const total =
			parseFloat(
				useSelector(
					getEventPromotionCustomerLiabilityLimit(promotionId)
				) || '1000.0'
			) || 1000;
		const isLocked = remain <= 0;
		return {
			eventId,
			current: remain,
			total,
			currency,
			isLocked,
			hideFooter,
			className
		};
	}
)(ZeroMarginLiabilityCounterUi);
