import { Box } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box';
import * as React from 'react';

import { PromoCardContext } from '../../PromoCard';

export const PromoContent: React.FunctionComponent<BoxProps> = ({
	children,
	...rest
}) => {
	const { expired } = React.useContext(PromoCardContext);
	return expired ? null : (
		<Box padding={2} {...rest}>
			{children}
		</Box>
	);
};
