import { useLanguage } from '@gaming-shell/i18n';
import { debug, SportsTopics } from '@gaming-shell/logging';
import { usePusher } from '@gaming-shell/pusher';
import { usePlayerCurrencyVariant } from '@materialbet-core/player';
import { normalize } from 'normalizr';
import { useDispatch, useStore } from 'react-redux';
import { MarketType } from 'sports-sdk/sports-core';
import { EventMetadata, EventResponse, Market } from 'sports/api/sports';
import { actions as commonActions } from 'sports/modules/common';
import {
	actions as eventActions,
	CompetitionEventsParams,
	EventsParams,
	getCompetitionEventsKey,
	getEventsKey
} from 'sports/modules/eventsModule';
import { sportsMetaActions } from 'sports/modules/sports/sportsMeta';
import { EventEntity, SelectionEntity, SubmarketEntity } from 'sports/schema';
import * as schema from 'sports/schema';
import { useStakeFactor } from 'sports/selectors/sportsplayer';
import { getMinStake, useCurrency } from 'sports/utils/currency';
import {
	getLiveEventIdsForCompetitionEventsParams,
	getLiveEventIdsForEventsParams
} from 'sports/utils/meta';
import { reassembleFrames } from 'sports/utils/pusherparser';
import { calculateStake } from 'sports/utils/stake';
import { getZeroMarginMaxStakeInEuro } from 'sports/utils/zeroMarginUtils';
import { usePlayerCurrencyExchangeRate } from 'materialbet-exchange-rates';
import { useExchangeRatesStore } from 'materialbet-exchange-rates';

export interface SubmarketLevelPusherArgs {
	eventId: number;
	marketKey: MarketType;
	submarketKey: string;
}
export interface SelectionUpdate {
	price?: number;
	minStake?: number;
	maxStake?: number;
	status?: 'SELECTION_DISABLED' | 'SELECTION_ENABLED';
	eventId: number;
	marketKey: MarketType;
	submarketKey: string;
	outcome: string;
	params: string;
}
export interface BetslipSelectionPusherArgs extends SubmarketLevelPusherArgs {
	outcome: string;
	params: string;
	onSelectionUpdate: (selection: SelectionEntity) => void;
}
export interface MarketLevelPusherArgs {
	eventId: number;
	marketKey: MarketType;
}

// eslint-disable-next-line no-useless-escape
const sanitize = /[^a-zA-Z\d\@\-\=,\._;]/g;
export const sanitizeChannel = (channelName: string) => {
	return channelName.replace(sanitize, '_');
};

export const useSubmarketLevelPusherUpdate = (
	props: SubmarketLevelPusherArgs
) => {
	const { eventId, marketKey, submarketKey } = props;
	const channel = sanitizeChannel(
		`event_v6_${eventId}_${marketKey}_${submarketKey}`
	);

	const dispatch = useDispatch();
	usePusher(
		'update',
		channel,
		reassembleFrames((data: unknown, _: number, messageType?: string) => {
			const submarket = data as SubmarketEntity;
			if (messageType === 'SubmarketV6') {
				dispatch(
					eventActions.updateSubmarket({
						eventId,
						marketKey,
						submarketKey,
						submarket
					})
				);
				debug(SportsTopics.PusherUpdatedSubmarket, {
					eventId,
					marketKey,
					submarketKey,
					channel,
					data: submarket
				});
			}
		})
	);
};
export const useSelectionLevelPusherUpdate = (
	props: BetslipSelectionPusherArgs
) => {
	const {
		eventId,
		marketKey,
		submarketKey,
		onSelectionUpdate,
		outcome,
		params
	} = props;
	const channel = sanitizeChannel(
		`event_v6_${eventId}_${marketKey}_${submarketKey}`
	);
	const exchangeRate = usePlayerCurrencyExchangeRate();
	const currency = useCurrency();
	const variant = usePlayerCurrencyVariant();
	const stakeFactor = useStakeFactor();
	const store = useStore();
	const exchangeRatesStore = useExchangeRatesStore();
	usePusher(
		'update',
		channel,
		reassembleFrames((data: unknown, _: number, messageType?: string) => {
			const submarket = data as SubmarketEntity;
			if (messageType === 'SubmarketV6') {
				const selections = submarket.selections;
				if (!selections) {
					return;
				}
				const selection = selections.find(
					(s) => s.outcome === outcome && s.params === params
				);
				if (!selection) {
					return;
				}

				const minStake = selection.minStake
					? calculateStake(
							selection.minStake,
							exchangeRate || '0',
							stakeFactor,
							currency
					  ) || getMinStake(currency, variant)
					: undefined;
				const zeroMarginMaxStakeInEuro =
					selection.price &&
					getZeroMarginMaxStakeInEuro({
						eventId,
						price: selection.price,
						state: store.getState(),
						exchangeRatesState: exchangeRatesStore.getState(),
						marketKey: marketKey
					});
				const maxStakeInEuro =
					zeroMarginMaxStakeInEuro || selection.maxStake;
				const maxStake =
					maxStakeInEuro &&
					calculateStake(
						maxStakeInEuro,
						exchangeRate || '0',
						stakeFactor,
						currency
					);

				const update: SelectionUpdate = {
					eventId,
					marketKey,
					submarketKey,
					outcome: selection.outcome || outcome,
					params: selection.params || params,
					maxStake: maxStake, // TODO needs to be calcualted with excahn
					minStake: minStake,
					status: selection.status,
					price: selection.price
				};
				onSelectionUpdate(update);
			}
		})
	);
};
export const useMarketLevelPusherUpdate = (props: MarketLevelPusherArgs) => {
	const { eventId, marketKey } = props;
	const channel = sanitizeChannel(`event_v6_${eventId}_${marketKey}`);
	const dispatch = useDispatch();
	usePusher('update', channel, (market: Market) => {
		if (!market) {
			return;
		}
		dispatch(
			eventActions.updateMarket({
				eventId,
				marketKey,
				market
			})
		);
		debug(SportsTopics.PusherUpdatedMarket, {
			eventId,
			marketKey,
			channel,
			data: market
		});
	});
};

export const useEventMetadataPusher = (id?: number) => {
	const event = 'update';
	const channel = `metadata_${id}`;
	const dispatch = useDispatch();
	usePusher(
		event,
		channel,
		reassembleFrames((data: unknown, _: number, messageType?: string) => {
			const metadata = data as EventMetadata;
			if (messageType === 'EventMetadata') {
				dispatch(
					eventActions.updateEventMetadata({
						metadata: metadata,
						eventId: id || ''
					})
				);
				debug(SportsTopics.PusherUpdatedEventMetadata, {
					eventId: id || 0,
					channel,
					data: metadata
				});
			}
		})
	);
};

interface EventStatusUpdate {
	id: number;
	status: string;
}

export const useEventStatusPusher = (id?: number) => {
	const event = 'update';
	const channel = `event_${id}_status`;
	const dispatch = useDispatch();
	usePusher(
		event,
		channel,
		reassembleFrames((update: EventStatusUpdate) => {
			dispatch(eventActions.updateStatus(update));
			debug(SportsTopics.PusherUpdatedEventStatus, {
				eventId: id || 0,
				channel,
				status: update.status,
				data: update
			});
		})
	);
};
export interface EventListUpdateRemove {
	action: 'LIST_REMOVE';
	events: number[];
}
export interface EventListUpdateAdd {
	action: 'LIST_ADD';
	events: EventResponse[];
}
export type EventListUpdate = EventListUpdateAdd | EventListUpdateRemove;
export interface EventListTreePusherArgs {
	list: 'tree';
	params: EventsParams;
}
export interface EventListCompetitionPusherArgs {
	list: 'competition';
	params: CompetitionEventsParams;
}
export type EventListPusherArgs =
	| EventListCompetitionPusherArgs
	| EventListTreePusherArgs;
export const useEventListUpdatePusher = ({
	list,
	params
}: EventListPusherArgs) => {
	const event = 'update';
	const channel = 'v6_live_events_list_update';
	const dispatch = useDispatch();
	const language = useLanguage();
	const key =
		list === 'tree'
			? getEventsKey(params as EventsParams)
			: getCompetitionEventsKey(params as CompetitionEventsParams);
	usePusher(
		event,
		channel,
		reassembleFrames((update: EventListUpdate) => {
			if (update.action === 'LIST_ADD') {
				const normalized = normalize(update.events, [schema.events]);
				const events = normalized.entities.events as Record<
					string,
					EventEntity
				>;
				let eventIds: number[] = [];

				if (list === 'tree') {
					eventIds = getLiveEventIdsForEventsParams(
						Object.values(events),
						params as EventsParams
					);
				}
				if (list === 'competition') {
					eventIds = getLiveEventIdsForCompetitionEventsParams(
						Object.values(events),
						params as CompetitionEventsParams
					);
				}
				dispatch(commonActions.updateEntities(normalized.entities));
				eventIds.forEach((id) => {
					const sportKey = events[id].sport;
					const competitionKey = events[id].competition;
					if (!sportKey || !competitionKey) {
						return;
					}
					dispatch(
						sportsMetaActions.addEvents({
							key,
							// currently pusher will only return one event anyway so no need to make effort to support retreiving multiple
							// events, we would need to group events in the meta utils then accordingly
							eventIds: [id],
							list,
							sportKey,
							competitionKey
						})
					);
					dispatch(eventActions.fetchMain({ id, locale: language }));
				});
				return;
			} else if (update.action === 'LIST_REMOVE') {
				dispatch(
					sportsMetaActions.removeEvents({
						key,
						eventIds: update.events,
						list
					})
				);
				return;
			}
		})
	);
};
interface EventStatusUpdate {
	action: 'clear_markets';
}
export const useEventControl = (id?: number) => {
	const event = 'update';
	const channel = `event_${id}_control`;
	const dispatch = useDispatch();
	usePusher(event, channel, (update: EventStatusUpdate) => {
		if (id && update.action === 'clear_markets') {
			dispatch(eventActions.clearMarkets({ id }));
		}
	});
};
