import { getPlatform } from '@gaming-shell/device';
import { IconButton, IconButtonProps, makeStyles } from '@material-ui/core';
import * as React from 'react';

import {
	AnimatedHamburgerIcon,
	AnimatedHamburgerIconType
} from './components/AnimatedHamburgerIcon';

export interface HamburgerButtonProps extends Omit<IconButtonProps, 'type'> {
	// TODO APPBAR - Remove 'home' | 'page' | 'window' after switching to new appbar
	type: 'home' | 'page' | 'window' | AnimatedHamburgerIconType;
}

const useStyles = makeStyles({
	hamburger: {
		backgroundColor: '#FFFFFF'
	}
});

// TODO APPBAR - Maps appbar states with hamburger icon states
// TODO APPBAR - Should be removed (is handled by new appbar itself)
const hamburgerTypes = {
	window: {
		mobile: AnimatedHamburgerIconType.CLOSE,
		desktop: AnimatedHamburgerIconType.BACK
	},
	page: {
		mobile: AnimatedHamburgerIconType.BACK,
		desktop: AnimatedHamburgerIconType.MENU
	},
	home: {
		mobile: AnimatedHamburgerIconType.MENU,
		desktop: AnimatedHamburgerIconType.MENU
	}
};

export const HamburgerButton: React.FunctionComponent<HamburgerButtonProps> = ({
	type,
	...rest
}) => {
	const classes = useStyles();
	// TODO APPBAR - remove after newappbar ready
	let hamburgerType;
	if (!hamburgerTypes[type]) hamburgerType = type;
	else hamburgerType = hamburgerTypes[type][getPlatform()];

	return (
		<IconButton {...rest}>
			<AnimatedHamburgerIcon
				type={hamburgerType}
				className={classes.hamburger}
			/>
		</IconButton>
	);
};
