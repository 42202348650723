import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

export interface LinkCommonProps {
	/**
	 * sets href of anchor tag, routing will handled by inside react-router's Link component
	 * if you use route object from @gaming-shell/routing, then pass create function here (it will be invoked by react-router automatically)
	 */
	href: string | (() => string);
	onClick?: (ev: React.MouseEvent) => void;
	component?: React.ReactNode;
	replace?: boolean;
}

export const createLink = <T extends {}>(Component: React.FC<T>) => {
	const CustomLinkComponent: React.ForwardRefRenderFunction<
		any,
		T & LinkCommonProps
	> = ({ children, href, onClick, replace, ...rest }, ref) => {
		/* Be careful, this will be global for all Link components created via this hoc */

		/**
		 * Fixes issue where user have history polluted because of redirects to same site he/she actually currently is.
		 * ex. user is on /casino and clicks on link to /casino, history is being polluted with /casino routes and back arrow stops behaving correctly
		 */
		const { pathname, search } = useLocation();
		const fullPath = pathname + search;
		const handleClick = React.useCallback(
			(ev: React.MouseEvent) => {
				if (
					href === fullPath ||
					(typeof href === 'function' && href() === fullPath)
				) {
					ev.preventDefault();
				}
				onClick && onClick(ev);
			},
			[href, fullPath, onClick]
		);

		return (
			// @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
			<Component
				component={Link}
				to={href}
				href={href}
				{...rest}
				onClick={handleClick}
				innerRef={ref}
				replace={replace}
			>
				{children}
			</Component>
		);
	};

	CustomLinkComponent.displayName = `createLink(${Component.displayName})`;
	// requires ref https://github.com/mui-org/material-ui/issues/15903
	return React.forwardRef(CustomLinkComponent);
};

export const createMemoizedLink = <T extends {}>(Component: React.FC<T>) => {
	const Link = createLink(Component);
	return React.memo(Link);
};
