import { useTranslation } from '@gaming-shell/i18n';
import { api } from '@materialbet-core/api';
import { Player, useUpdatePlayer } from '@materialbet-core/player';
import { useSnackbar, Currency } from 'materialbet-common';
import * as React from 'react';
import { useRefreshPlayerCurrencies } from './useRefreshPlayerCurrencies';

export interface ApiCallbackArguments {
	currency: Currency;
	authToken: string;
	onSuccessSideEffect?: () => void;
	onFailureSideEffect?: () => void;
}

export interface UseSetActiveCurrencyOutput {
	isSubmitting: boolean;
	apiCallback: (args: ApiCallbackArguments) => void;
}

const useSetActiveCurrency = ({
	skipSnackbar = false
} = {}): UseSetActiveCurrencyOutput => {
	const [isSubmitting, setIsSubmitting] = React.useState(false);

	const updatePlayer = useUpdatePlayer();
	const { enqueueSnackbar } = useSnackbar();
	const trans = useTranslation();

	const refreshPlayerCurrencies = useRefreshPlayerCurrencies();

	const apiCallback = React.useCallback(
		({
			currency,
			authToken,
			onSuccessSideEffect,
			onFailureSideEffect
		}: ApiCallbackArguments) => {
			setIsSubmitting(true);
			api.postUpdateCurrency(currency, authToken)
				.then((response) => {
					const token = (response as { authenticity_token: string })
						.authenticity_token;
					if (response.result === 'INVALID_AUTHENTICITY_TOKEN') {
						updatePlayer((player: Player) => ({
							...player,
							authToken: token
						}));
						// setTimeout ensures that the next sign in placed in an event queue which is called after this method returned (js version of defer)
						setTimeout(() =>
							apiCallback({
								currency,
								authToken: token,
								onSuccessSideEffect,
								onFailureSideEffect
							})
						);
						return;
					}
					if (response.result === 'OK') {
						const wallet = {
							amount: response.wallet.amount,
							bonusAmount: response.wallet.bonus_amount
						};
						updatePlayer((player: Player) => ({
							...player,
							currency,
							wallets: {
								...player.wallets,
								[currency]: wallet
							}
						}));
						!skipSnackbar &&
							enqueueSnackbar(
								trans('player:myAccount.changedCurrency')
							);
						onSuccessSideEffect && onSuccessSideEffect();
					} else if (
						response.result === 'VALIDATION_ERROR' ||
						response.result === 'INVALID_CURRENCY'
					) {
						refreshPlayerCurrencies();
						enqueueSnackbar(
							trans('player:myAccount.invalidCurrency')
						);
						onFailureSideEffect && onFailureSideEffect();
					}
				})
				.catch(() => {
					enqueueSnackbar(trans('player:descriptions.failedRequest'));
				})
				.finally(() => setIsSubmitting(false));
		},
		[
			updatePlayer,
			enqueueSnackbar,
			trans,
			refreshPlayerCurrencies,
			skipSnackbar
		]
	);

	return {
		isSubmitting,
		apiCallback
	};
};

export default useSetActiveCurrency;
