import * as React from 'react';
import { useSetCookie } from 'auth/utils/useSetCookie';
import { TokenCookieError } from 'auth/utils/errorHandlers';
import { useSnackbar } from 'notistack';
import { useTranslation } from '@gaming-shell/i18n';

export interface AuthSideEffectsProps {
	authReturnKey: string;
}

export const AuthSideEffects: React.FunctionComponent<AuthSideEffectsProps> = ({
	authReturnKey
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const trans = useTranslation();

	const { hasError, messageKey }: TokenCookieError = useSetCookie(
		authReturnKey
	);
	if (hasError) {
		enqueueSnackbar(trans(messageKey));
	}
	return null;
};
