import { Translate } from '@gaming-shell/i18n';
import { SideMenuItem } from '@gaming-shell/layout';
import { EsportsRoutes, SportsRoutes } from 'materialbet-common';
import * as React from 'react';
import { useLocation } from 'react-router';
import InPlayIcon from 'sports/components/icons/navigation/InPlay';
import LobbyIcon from 'sports/components/icons/navigation/Lobby';
import MyBetsIcon from 'sports/components/icons/navigation/MyBets';

/** @description a component that contain only the main item for navigation: Home, My Bets, In Play */
let SportsNavigationList: React.FC = () => {
	const { pathname } = useLocation();
	const isEsports =
		ENABLE_ESPORTS_SECTION && EsportsRoutes.root.matches(pathname);
	const Routes = isEsports ? EsportsRoutes : SportsRoutes;
	const isSportHome = Routes.root.matchesExactly(pathname);
	const isMyBets = Routes.bets.matches(pathname);
	const isLive = Routes.live.matches(pathname);
	return (
		<React.Fragment>
			<SideMenuItem
				icon={<LobbyIcon />}
				label={<Translate label="descriptions.home" />}
				href={Routes.root.create()}
				active={isSportHome}
				variant="subtitle2"
			/>
			<SideMenuItem
				icon={<MyBetsIcon />}
				label={<Translate label="descriptions.myBets" />}
				href={Routes.bets.create()}
				active={isMyBets}
				variant="subtitle2"
			/>
			<SideMenuItem
				icon={<InPlayIcon />}
				label={<Translate label="sports.content.inPlay" />}
				href={Routes.live.create()}
				active={isLive}
				variant="subtitle2"
			/>
		</React.Fragment>
	);
};

SportsNavigationList = React.memo(SportsNavigationList);
export { SportsNavigationList };
