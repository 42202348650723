import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

const LiveCasinoRoulette: React.FunctionComponent<SvgIconProps> = (props) => {
	return (
		// Custom viewbox is used to center the icon
		<SvgIcon viewBox="0 0 20 20" {...props}>
			<path
				fill={props.fill}
				fillRule="nonzero"
				d="M10 1.667a8.333 8.333 0 1 1 0 16.666 8.333 8.333 0 0 1 0-16.666zM6.388 14.496l-.935.936a7.052 7.052 0 0 0 3.922 1.624v-1.323a5.73 5.73 0 0 1-1.557-.393l-.009-.005a5.733 5.733 0 0 1-1.42-.84zm7.224 0a5.74 5.74 0 0 1-2.986 1.237l-.001 1.323a7.052 7.052 0 0 0 3.922-1.625l-.935-.935zm2.121-3.87a5.74 5.74 0 0 1-1.237 2.986l.936.935a7.052 7.052 0 0 0 1.624-3.921h-1.323zm-12.79-.001a7.052 7.052 0 0 0 1.626 3.923l.935-.936a5.74 5.74 0 0 1-1.237-2.986l-1.323-.001zm7.69 2.504a3.207 3.207 0 0 1-1.243.004l-.51 1.244a4.522 4.522 0 0 0 2.27-.008l-.516-1.24zm-3.279-1.343l-1.24.517c.19.319.416.612.675.874l.02.014.017.018c.268.267.57.5.899.693l.509-1.243a3.21 3.21 0 0 1-.88-.873zm5.305-.02a3.21 3.21 0 0 1-.873.88l.518 1.24c.316-.188.608-.414.869-.671l.018-.024a4.51 4.51 0 0 0 .711-.917l-1.243-.507zM10 8.059a1.936 1.936 0 0 0-.754.153A1.942 1.942 0 1 0 10 8.058zm-4.377.822a4.522 4.522 0 0 0 .008 2.27l1.24-.517a3.207 3.207 0 0 1-.004-1.243l-1.244-.51zm8.746-.03l-1.24.516a3.207 3.207 0 0 1 .005 1.244l1.243.509a4.522 4.522 0 0 0-.008-2.27zm-9.8-3.397a7.052 7.052 0 0 0-1.625 3.922h1.323a5.74 5.74 0 0 1 1.238-2.987l-.936-.935zm10.862 0l-.935.936a5.74 5.74 0 0 1 1.237 2.986h1.323a7.052 7.052 0 0 0-1.625-3.922zM6.818 6.794l-.01.015a4.504 4.504 0 0 0-.71.916l1.243.509a3.21 3.21 0 0 1 .873-.88l-.517-1.24c-.32.19-.616.42-.879.68zm5.456-.696l-.507 1.243c.346.231.645.528.879.873l1.24-.517a4.535 4.535 0 0 0-.676-.875l-.019-.013a4.508 4.508 0 0 0-.917-.71zM10 5.483c-.398 0-.783.052-1.15.148l.518 1.24a3.207 3.207 0 0 1 1.242-.005l.508-1.243A4.522 4.522 0 0 0 10 5.483zm-.625-2.54A7.052 7.052 0 0 0 5.452 4.57l.935.936a5.74 5.74 0 0 1 2.988-1.238V2.944zm1.25 0v1.324a5.74 5.74 0 0 1 2.987 1.237l.936-.935a7.052 7.052 0 0 0-3.922-1.625z"
			/>
		</SvgIcon>
	);
};
export default LiveCasinoRoulette;
