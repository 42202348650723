import livesport365 from './livesport365';
import sports from './sports';
import sportsbetting from './sportsbetting';
import sportsplayers from './sportsplayers';
import sportssharing from './sportssharing';

const api = {
	sports,
	sportsbetting,
	sportssharing,
	livesport365,
	sportsplayers
};
export default api;
