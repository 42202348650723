"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultLanguage = void 0;
var cookie_1 = require("./cookie");
var getRouteLanguage_1 = require("./getRouteLanguage");
var supportedLangauges = SUPPORTED_LANGUAGES;
exports.getDefaultLanguage = function () {
    var routeLanguage = getRouteLanguage_1.getRouteLanguage();
    if (ENABLE_LOCALIZED_ROUTES && supportedLangauges.includes(routeLanguage)) {
        return routeLanguage;
    }
    var savedLanguage = cookie_1.getLanguageFromCookie();
    if (supportedLangauges.includes(savedLanguage)) {
        return savedLanguage;
    }
    var browserLanguage = window.navigator.language.substr(0, 2);
    if (supportedLangauges.includes(browserLanguage)) {
        return browserLanguage;
    }
    return DEFAULT_LANGUAGE;
};
