import { Translate, useTranslation } from '@gaming-shell/i18n';
import {
	Box,
	Button,
	Divider,
	IconButton,
	makeStyles,
	Theme,
	useTheme
} from '@material-ui/core';
import { motion } from 'framer-motion';
import { CopyIcon, QrCodeIcon } from 'materialbet-icons';
import * as React from 'react';

import {
	ButtonLink,
	Column,
	CopyButton,
	CurrencyIcon,
	Dialog,
	QrImage,
	Row,
	Typography
} from '..';
import { Currency, getCurrencyConfig, Select } from '../..';
import { PlayerRoutes } from '../../routes';

export interface CurrentWalletDetails {
	currency: Currency;
	depositAddress: string;
	formattedCurrency: string;
	minDepositAmount: number;
	depositNote?: string;
}

export interface QuickDepositProps {
	open?: boolean;
	onClose: () => void;
	currencies: Currency[];
	currentWalletDetails: CurrentWalletDetails;
	onCurrencyChange: (currency: Currency) => void;
	onCopyClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	address: {
		wordBreak: 'break-word'
	},
	paper: {
		width: 712,
		maxWidth: 'none'
	},
	column: {
		flex: 1
	},
	content: {
		marginTop: theme.spacing(4)
	},
	contentSpacing: {
		marginTop: theme.spacing(3),
		minHeight: 205
	},
	copyButton: {
		textTransform: 'none',
		flex: 1
	},
	qrImageWrapper: {
		marginRight: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start'
	},
	minDeposit: {
		margin: `${theme.spacing(2)}px 0`
	},
	currencyIcon: {
		marginRight: theme.spacing(1.5)
	},
	qrCodeButton: {
		marginLeft: theme.spacing(1)
	},
	depositNote: {
		marginTop: theme.spacing(1)
	}
}));

const qrImageMotionVariants = {
	hidden: {
		opacity: 0,
		x: -300
	},
	visible: {
		opacity: 1,
		x: 0
	}
};

export const QuickDeposit: React.FC<QuickDepositProps> = ({
	currentWalletDetails,
	currencies,
	onCurrencyChange,
	onCopyClick,
	open = false,
	onClose
}) => {
	const classes = useStyles();
	const {
		transitions: {
			duration: { standard: standardTransitionDuration }
		}
	} = useTheme();
	const trans = useTranslation();
	const [showQrCode, setShowQrCode] = React.useState(false);

	const toggleQrCodeVisibility = React.useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			setShowQrCode((show) => !show);
		},
		[setShowQrCode]
	);

	const handleClose = React.useCallback(() => {
		setShowQrCode(false);
		onClose();
	}, [onClose, setShowQrCode]);

	const currentCurrencyLabel =
		getCurrencyConfig(currentWalletDetails.currency)?.label ||
		currentWalletDetails.currency;

	return (
		<Dialog
			dialogTitle={<Translate label="actions.deposit" ns="common" />}
			onClose={handleClose}
			open={open}
			classes={{
				paper: classes.paper
			}}
			buttons={
				<>
					<ButtonLink
						color="primary"
						variant="text"
						onClick={handleClose}
						href={PlayerRoutes.cashierDeposit.create({
							currency: currentWalletDetails.currency
						})}
						aria-label="cashier link"
					>
						<Translate label="cashier.cashier" ns="player" />
					</ButtonLink>
					<Button onClick={handleClose} aria-label="close">
						<Translate label="actions.close" ns="common" />
					</Button>
				</>
			}
		>
			<Divider />
			<Row className={classes.content}>
				<Row
					justify="flex-start"
					alignItems="center"
					className={classes.column}
				>
					<CurrencyIcon
						currency={currentWalletDetails.currency}
						className={classes.currencyIcon}
					/>
					<Typography variant="h6">
						{currentWalletDetails.formattedCurrency}
					</Typography>
				</Row>
				<Select
					className={classes.column}
					value={currentWalletDetails.currency}
					onChange={onCurrencyChange}
					name="currency"
					variant="outlined"
					color="primary"
					options={currencies.map((currency) => ({
						value: currency,
						name: getCurrencyConfig(currency)?.label || currency
					}))}
				/>
			</Row>
			<Row
				className={classes.contentSpacing}
				justify="space-between"
				alignItems="flex-start"
			>
				{showQrCode && (
					<motion.div
						variants={qrImageMotionVariants}
						initial="hidden"
						animate="visible"
						className={classes.qrImageWrapper}
						transition={{
							ease: 'easeOut',
							duration: standardTransitionDuration / 1000
						}}
					>
						<QrImage
							src={`${QR_SERVICE_URL}?data=${currentWalletDetails.depositAddress}`}
							alt={trans('player:wallet.qrAddress')}
						/>
					</motion.div>
				)}
				<Column className={classes.column}>
					<Row alignItems="center">
						<CopyButton
							onClick={onCopyClick}
							valueToCopy={currentWalletDetails.depositAddress}
							className={classes.copyButton}
							aria-label="deposit address"
						>
							<Box flex={1} textAlign="left">
								<Typography color="textPrimary">
									<Translate
										label="wallet.depositAddress"
										ns="player"
									/>
								</Typography>
								<Typography
									className={classes.address}
									variant="body2"
									color="textSecondary"
								>
									{currentWalletDetails.depositAddress}
								</Typography>
							</Box>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="flex-end"
								paddingLeft={2}
							>
								<CopyIcon color="primary" />
							</Box>
						</CopyButton>
						<IconButton
							className={classes.qrCodeButton}
							onClick={toggleQrCodeVisibility}
							aria-label="toggle qr visibility"
						>
							<QrCodeIcon color="primary" />
						</IconButton>
					</Row>
					<Typography
						className={classes.minDeposit}
						component="div"
						variant="caption"
						color="textSecondary"
					>
						{trans('player:cashier.minimumDeposit', {
							value: `${currentCurrencyLabel} ${currentWalletDetails.minDepositAmount}`
						})}
					</Typography>
					{currentWalletDetails.depositNote && (
						<Typography
							component="div"
							className={classes.depositNote}
							variant="body2"
							color="textPrimary"
							aria-label="deposit note"
						>
							{currentWalletDetails.depositNote}
						</Typography>
					)}
				</Column>
			</Row>
		</Dialog>
	);
};
