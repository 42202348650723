import deepmerge from 'deepmerge';
import produce from 'immer';
import { ActionType, createAction, getType } from 'typesafe-actions';

import { WinnerType } from './winnersModule';

/** @description maps a stringified action payload to an array of section ids */
export type SectionsMeta = Record<string, string[]>;
/** @description list of game ids for a particular request */
export type GameListsMeta = Record<string, string[]>;
/** @description number of total games for a type of request (excluding pagination parameters)*/
export type GameCountsMeta = Record<string, number>;
/** @description list of studio keys for a given payload */
export type StudiosMeta = Record<string, string[]>;
/** @description list of freespins for a particular player */
export type FreespinsMeta = Record<string, string[]>;
/** @description list of rolloverbonuses for a particular player */
export type RolloverBonusesMeta = Record<string, string[]>;
/** @description winning amount of winners in EUR for different winners type */
export type WinningAmountMeta = Record<string, number>;
export interface CasinoMeta {
	sections: SectionsMeta;
	gameLists: GameListsMeta;
	gameCounts: GameCountsMeta;
	studios: StudiosMeta;
	freespins: FreespinsMeta;
	rolloverBonuses: RolloverBonusesMeta;
	winningAmount: WinningAmountMeta;
}
export const defaultCasinoMeta: CasinoMeta = {
	sections: {},
	gameLists: {},
	gameCounts: {},
	studios: {},
	freespins: {},
	rolloverBonuses: {},
	winningAmount: {}
};

export interface WinningAmountPayload {
	amount: number;
	type: WinnerType;
}

export const casinoMetaActions = {
	addWinningAmount: createAction(
		'casinoMeta/addWinningAmount',
		(resolve) => (payload: WinningAmountPayload) => resolve(payload)
	)
};

const addWinningAmount = produce(
	(
		state: CasinoMeta,
		action: ActionType<typeof casinoMetaActions.addWinningAmount>
	) => {
		const { type, amount } = action.payload;
		const newWinningAmount = (state.winningAmount?.[type] || 0) + amount;
		const winningAmount = {
			...state.winningAmount,
			[type]: newWinningAmount
		};
		return {
			...state,
			winningAmount
		};
	}
);

export const CasinoMetaKey = 'casinoMeta';
export type CasinoMetaKeys = keyof CasinoMeta;
//overwrite arrays
const arrayMerge = <T>(_: T[], sourceArray: T[]) => sourceArray;
export default (
	state: CasinoMeta = defaultCasinoMeta,
	action: { [key: string]: unknown }
) => {
	if (action.type === getType(casinoMetaActions.addWinningAmount)) {
		return addWinningAmount(
			state,
			action as ActionType<typeof casinoMetaActions.addWinningAmount>
		);
	}
	if (
		!(
			typeof action.meta === 'object' &&
			action.meta &&
			action.meta[CasinoMetaKey]
		)
	) {
		return state;
	}
	const casinoMeta: CasinoMeta = action.meta[CasinoMetaKey];
	return deepmerge(state, casinoMeta, { arrayMerge });
};
