import { Translate } from '@gaming-shell/i18n';
import { Snackbar, SnackbarContent, makeStyles } from '@material-ui/core';
import * as React from 'react';

const useSnackbarStyles = makeStyles({
	root: {
		minWidth: '0px !important'
	}
});
interface ShowNoExchangeRatesSnackbarContext {
	showNoExchangeRatesSnackbar: (show: boolean) => void;
}

export const ShowNoExchangeRatesSnackbarContext = React.createContext<
	ShowNoExchangeRatesSnackbarContext
>({ showNoExchangeRatesSnackbar: () => void undefined });

export const useShowNoExchangeRatesSnackbar = () => {
	const { showNoExchangeRatesSnackbar } = React.useContext(
		ShowNoExchangeRatesSnackbarContext
	);
	return showNoExchangeRatesSnackbar;
};

const NoExchangeRatesSnackbarContent: React.FC<unknown> = () => {
	const snackbarClasses = useSnackbarStyles();
	return (
		<SnackbarContent
			classes={snackbarClasses}
			message={<Translate label="betting.unavailable" ns="sports" />}
		/>
	);
};

export const NoExchangeRatesSnackbar: React.FC<unknown> = ({ children }) => {
	const [show, setShow] = React.useState(false);
	const onClose = React.useCallback(() => setShow(false), []);
	const { current: value } = React.useRef({
		showNoExchangeRatesSnackbar: setShow
	});
	return (
		<ShowNoExchangeRatesSnackbarContext.Provider value={value}>
			<Snackbar
				open={show}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				autoHideDuration={3000}
				onClose={onClose}
			>
				<NoExchangeRatesSnackbarContent />
			</Snackbar>
			{children}
		</ShowNoExchangeRatesSnackbarContext.Provider>
	);
};
