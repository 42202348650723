import { usePusherProxy } from '@gaming-shell/pusher/dist/internal/hook';
import sportsbettingApi from 'sports/api/sportsbetting';
import { PlaceBetV2Response } from 'sports/api/sportsbetting/api';
import { handlePlaceBetResponse } from 'sports/utils/placeBetV2';

import { betslipActions } from '../betslipReducer';
import { useBetslipDispatch } from '../hooks/useBetslipDispatch';
import { PlaceBetCallback } from '../placeBet/useBetslipPlaceBet';
import { BetslipMode, BetslipReducerBetslip } from '../types';

export const useUpdateInflightBetslip = (
	onBetPlaced: PlaceBetCallback,
	playerChannel: string
) => {
	const pusher = usePusherProxy();
	const dispatch = useBetslipDispatch();
	const onError = (response: PlaceBetV2Response) => {
		dispatch(betslipActions.setPlaceBetChanges(response));
	};
	return (
		referenceId: string,
		betslip: BetslipReducerBetslip,
		mode: BetslipMode
	) => {
		sportsbettingApi
			.getBetResponseV2({ referenceId })
			.then((res) =>
				handlePlaceBetResponse(
					res,
					playerChannel,
					pusher,
					() => onBetPlaced({ betslip, mode }),
					onError,
					'poll'
				)
			)
			.catch((err: unknown) => {
				const response = err as PlaceBetV2Response;
				if (response.referenceId) {
					handlePlaceBetResponse(
						response,
						playerChannel,
						pusher,
						() => undefined,
						onError,
						'poll'
					);
				} else {
					// we just clear the betslip for now to prevent deadlocking the betslip forever
					// in the future we might show some error or handle this case differently
					dispatch(betslipActions.clear());
				}
			});
	};
};
