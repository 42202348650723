"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.store = void 0;
var redux_1 = require("redux");
var redux_devtools_extension_1 = require("redux-devtools-extension");
var modules_1 = require("../modules");
var composeEnhancers = redux_devtools_extension_1.composeWithDevTools({
    name: 'inbox-player-messaging'
});
exports.store = redux_1.createStore(modules_1.reducer, composeEnhancers(redux_1.applyMiddleware(modules_1.epicMiddleware)));
modules_1.epicMiddleware.run(modules_1.epics);
