"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPathParam = exports.pathParam = void 0;
var indicators_1 = require("../indicators");
exports.pathParam = function (pathParam) {
    return ({
        pathParam: pathParam
    });
};
exports.createPathParam = function (param) {
    return "" + indicators_1.ParamIndicators.PATH + param;
};
