export const filterMap = <T, R>(
	array: T[],
	filter: (element: T) => boolean,
	map: (element: T) => R
): R[] =>
	array.reduce((prev, ele) => {
		if (!filter(ele)) {
			return prev;
		}
		prev.push(map(ele));
		return prev;
	}, [] as R[]);

export const mapFilter = <T, R>(
	array: T[],
	map: (element: T) => R,
	filter: (element: R) => boolean
): R[] =>
	array.reduce((prev, ele) => {
		const mapped = map(ele);
		if (!filter(mapped)) {
			return prev;
		}
		prev.push(mapped);
		return prev;
	}, [] as R[]);

export const mapJoin = <T>(
	array: T[],
	map: (element: T) => string,
	separator: string | undefined
): string =>
	array.reduce<string>((prev, ele, i) => {
		const mapped = map(ele);
		prev += (i === 0 ? '' : separator || '') + mapped;
		return prev;
	}, '');

export const flatten = <T>(array: T[][]) =>
	array.reduce((prev, ele) => {
		prev.push(...ele);
		return prev;
	}, [] as T[]);

/** @description Returns a new array, with the element at index replaced with the given element */
export const replace = <T>(array: T[], element: T, index: number) => {
	return [
		...array.slice(0, index),
		element,
		...array.slice(index + 1, array.length)
	];
};
/** @description Returns a new array, with the element appended as the last element in the array */
export const append = <T>(array: T[], element: T) => {
	return [...array, element];
};
/** @description Returns a new array, with the element at index removed */
export const remove = <T>(array: T[], index: number) => {
	return [...array.slice(0, index), ...array.slice(index + 1, array.length)];
};
export const minimum = <T>(array: T[], isLess: (x: T, than: T) => boolean) =>
	array.reduce((min, ele) => (isLess(ele, min) ? ele : min), array[0]);
