import * as React from 'react';

/** @description returns the value of the previous render */
export const usePrevious = <T extends unknown>(value: T): T | undefined => {
	const ref = React.useRef<T>();

	React.useEffect(() => {
		ref.current = value;
	}, [value]);

	// Return previous value
	return ref.current;
};
