import { Platform, isMobile } from '@gaming-shell/device';
import { Grid, makeStyles } from '@material-ui/core';
import { Language } from '@materialbet/translations';
import { fillArray } from 'materialbet-common';
import * as React from 'react';
import { HitsProvided } from 'react-instantsearch-core';
import clsx from 'clsx';

import { SearchResultTile } from '../Item';

export interface SearchResultItem {
	id: string;
	gameKey: string;
	providerKey: string;
	rank: number;
	name: { [key in Language]: string };
	images?: Record<Platform, string>;
	_tags: string[];
}

export type AlgoliaHitsProvided = HitsProvided<SearchResultItem>;

export interface SearchResultsContentProps {
	skeleton: boolean;
}

const useStyles = makeStyles((theme) => ({
	mobile: {
		overflow: 'auto',
		maxWidth: `calc(100% + ${theme.spacing(1)}px)`,
		flexWrap: 'nowrap'
	}
}));

export const SearchResultsContent: React.FC<SearchResultsContentProps &
	AlgoliaHitsProvided> = ({ hits, skeleton }) => {
	const classes = useStyles();
	return (
		<Grid
			className={clsx(isMobile() && classes.mobile)}
			container
			spacing={2}
		>
			{!skeleton &&
				hits.map((item) => (
					<SearchResultTile key={item.id} {...item} />
				))}
			{/* Skeletons */}
			{skeleton &&
				fillArray(SEARCH_CASINO_GAMES_PER_PAGE, (i) => (
					<SearchResultTile key={`skeleton-${i}`} skeleton />
				))}
		</Grid>
	);
};
