const tagsMap = {
	virtual: 'virtuals'
};
/** @deprecated  */
export enum CasinoCategories {
	Baccarat = 'baccarat',
	LiveBaccarat = 'live_baccarat',
	BlackJack = 'blackjack',
	LiveBlackJack = 'live_blackjack',
	Featured = 'featured',
	LiveFeatured = 'live_featured',
	NearYou = 'popular_near_you',
	JackpotSlots = 'jackpot_slots',
	MyCasino = 'my_casino',
	Other = 'other',
	Roulette = 'roulette',
	LiveCasinoRoulette = 'live_roulette',
	Slots = 'slots',
	TableGames = 'tableGame',
	LiveCasinoTableGames = 'live_table_games',
	Virtuals = 'virtuals',
	Popular = 'popular',
	VideoPoker = 'video_poker',
	InstantWin = 'instant_win',
	WildChristmas = 'wild_christmas',
	Promotions = 'promotions',
	Recommendations = 'recommendations',
	Trending = 'games_trending_right_now'
}
/** @deprecated  */
export enum LiveCasinoCategories {
	Featured = 'featured',
	Roulette = 'roulette',
	Baccarat = 'baccarat',
	Blackjack = 'blackjack',
	TableGames = 'table_games',
	Other = 'other'
}
/** @deprecated  */
export enum RngCasinoCategories {
	Featured = 'featured',
	Slots = 'slots',
	JackpotSlots = 'jackpot_slots',
	Baccarat = 'baccarat',
	Blackjack = 'blackjack',
	Roulette = 'roulette',
	TableGames = 'table_games',
	Virtuals = 'virtuals',
	All = 'all'
}

/* requires virtual not 'virtuals' */
const rngCasinoCategoriesMapping = {
	[RngCasinoCategories.Virtuals]: 'virtual',
	[RngCasinoCategories.All]: undefined
};

/* requires virtual not 'virtuals' */
const liveCasinoCategoriesMapping = {
	[LiveCasinoCategories.TableGames]: 'other_table_games'
};

const casinoCategoryMapper = (
	mapper: { [K in RngCasinoCategories | LiveCasinoCategories]?: string }
) => (slug: string) => {
	const mappedCategory = mapper.hasOwnProperty(slug) ? mapper[slug] : slug;
	return { label: slug, value: mappedCategory };
};
/** @deprecated  */
export const rngCasinoCategoriesMapper = casinoCategoryMapper(
	rngCasinoCategoriesMapping
);
/** @deprecated  */
export const liveCasinoCategoriesMapper = casinoCategoryMapper(
	liveCasinoCategoriesMapping
);
/** @deprecated  */
/* There's inconsistency between tags we receive hence the mapping */
export const mapTagsToCategory = (tags?: string[]) =>
	tags
		?.map((tag) => (tagsMap.hasOwnProperty(tag) ? tagsMap[tag] : tag))
		?.find((tag) => Object.values<string>(CasinoCategories).includes(tag));
/** @deprecated  */
export const mapCategoryToTag = (category: string) =>
	Object.entries(tagsMap).find(([, value]) => value === category)?.[0] ||
	category;
