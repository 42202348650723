import clsx from 'clsx';
import * as React from 'react';

import { defaultIframeRatio } from '../../configs/ratio';
import { useStyles } from './IframeWidget.styles';
import { useIframeWidgetStyles } from './useIframeWidgetStyles';

export interface IframeWidgetProps {
	ratio?: string;
	backgroundImgUrl?: string;
	className?: string;
	fullscreen?: boolean;
	cinema?: boolean;
	showSidemenu?: boolean;
	children?: React.ReactNode;
}

/** @description a widget to contain iframe with header(optional), footer(optional), sidemenu(optional) as children, a wrapper to wrap children and calculate size */
export const IframeWidget: React.FC<IframeWidgetProps> = (props) => {
	const {
		children,
		className,
		backgroundImgUrl,
		ratio = defaultIframeRatio,
		fullscreen,
		cinema,
		showSidemenu
	} = props;
	const classes = useStyles({
		backgroundImgUrl
	});
	const [, triggerRerender] = React.useState(false);
	const ref = React.useRef<HTMLDivElement | null>(null);
	const iframeWidgetClasses = useIframeWidgetStyles({
		ref,
		ratio,
		fullscreen,
		cinema,
		showSidemenu
	});
	React.useEffect(() => {
		if (ref.current) {
			triggerRerender(true);
		}
	}, []);
	return (
		<section
			ref={ref}
			className={clsx(
				classes.iframeWidget,
				iframeWidgetClasses.root,
				className,
				cinema && classes.cinema,
				cinema && classes.background
			)}
		>
			{children}
		</section>
	);
};
