import { PlayerSideMenuPortal } from '@gaming-shell/layout';
import * as React from 'react';

import { PlayerSideMenuContent } from './PlayerSideMenuContent';

/** @description this component will return basic side menu for the core to side menu portal */
let PlayerSideMenu: React.FunctionComponent = () => {
	return (
		<PlayerSideMenuPortal>
			<PlayerSideMenuContent />
		</PlayerSideMenuPortal>
	);
};

PlayerSideMenu = React.memo(PlayerSideMenu);
export { PlayerSideMenu };
