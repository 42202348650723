export const hexToRgb = (hex: string) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16)
		  }
		: {
				r: 255,
				g: 255,
				b: 255
		  };
};
export const hashCode = (str: string) => {
	const salted = str + str; // were doubling the string here so the colors looks nice for very specific strings
	let hash = 0;
	for (let i = 0; i < salted.length; i++) {
		hash = salted.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hash;
};

export const intToHex = (i: number) => {
	const c = (i & 0x00ffffff).toString(16).toUpperCase();

	return '00000'.substring(0, 6 - c.length) + c;
};
