import { MarketType } from 'sports-sdk';

import { TabConfiguration, MarketView } from '../types';

const allMarkets = {
	popular: [
		{
			marketType: MarketType.esport_nba2k_1x2
		},
		{
			marketType: MarketType.esport_nba2k_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.esport_nba2k_winner_incl_overtime
		},
		{
			marketType: MarketType.esport_nba2k_totals,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.esport_nba2k_point_range,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.esport_nba2k_scoring_type_pointnr,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.esport_nba2k_winning_margin
		}
	],
	firstHalf: [
		{
			marketType: MarketType.esport_nba2k_1st_half_1x2
		},
		{
			marketType: MarketType.esport_nba2k_handicap_period_first_half,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.esport_nba2k_1st_half_draw_no_bet
		},
		{
			marketType: MarketType.esport_nba2k_total_period_first_half,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.esport_nba2k_1st_half_winning_margin
		},
		{
			marketType: MarketType.esport_nba2k_1st_half_team_total,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.esport_nba2k_halftime_fulltime
		},
		{
			marketType: MarketType.esport_nba2k_odd_even_period_first_half
		}
	],
	points: [
		{
			marketType:
				MarketType.esport_nba2k_any_team_total_maximum_consecutive_points
		},

		{
			marketType: MarketType.esport_nba2k_race_to_points
		},
		{
			marketType: MarketType.esport_nba2k_race_to_points_incl_ot
		},
		{
			marketType: MarketType.esport_nba2k_any_team_to_lead_by_points
		},
		{
			marketType: MarketType.esport_nba2k_team_to_lead_by_points
		},
		{
			marketType: MarketType.esport_nba2k_highest_scoring_quarter
		},
		{
			marketType:
				MarketType.esport_nba2k_team_total_maximum_consecutive_points
		},
		{
			marketType: MarketType.esport_nba2k_team_totals,
			view: MarketView.Goals
		}
	],
	specials: [
		{
			marketType: MarketType.esport_nba2k_quarter_1x2
		},
		{
			marketType: MarketType.esport_nba2k_quarter_handicap,
			view: MarketView.Handicap
		},

		{
			marketType: MarketType.esport_nba2k_quarter_totals,
			view: MarketView.Goals
		},

		{
			marketType: MarketType.esport_nba2k_quarter_winning_margin
		},
		{
			marketType: MarketType.esport_nba2k_2nd_half_1x2
		},
		{
			marketType: MarketType.esport_nba2k_2nd_half_1x2_incl_ot
		},
		{
			marketType: MarketType.esport_nba2k_2nd_half_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.esport_nba2k_handicap_period_second_half,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.esport_nba2k_2nd_half_total,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.esport_nba2k_total_period_second_half,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.esport_nba2k_odd_even
		}
	]
};

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: allMarkets.popular
	},
	{
		label: 'markets.firstHalf',
		markets: allMarkets.firstHalf
	},
	{
		label: 'markets.points',
		markets: allMarkets.points
	},
	{
		label: 'markets.specials',
		markets: allMarkets.specials
	},
	{
		label: 'descriptions.all',
		markets: [
			...allMarkets.popular,
			...allMarkets.firstHalf,
			...allMarkets.points,
			...allMarkets.specials
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.esport_nba2k_winner_incl_overtime
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.esport_nba2k_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
