import { isMobile } from '@gaming-shell/device';
import { Translate } from '@gaming-shell/i18n';
import { Theme } from '@gaming-shell/theming';
import { makeStyles, Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withHook } from 'materialbet-common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { EventMetadata } from 'sports/api/sports';
import { enableLiveLinearProgress } from 'sports/config/feature';
import {
	getEventMetadata,
	getEventStartTime,
	getStatus
} from 'sports/selectors/eventSelectors';
import {
	isEventEnded,
	isEventLive,
	isEventTradingSuspended
} from 'sports/utils/event';
import { useTimeZone } from 'sports/utils/hooks';
import { shouldEventTime } from 'sports/utils/sportsEventStatus';

import TimeDisplay from './Date';
import { Timer } from './Timer';

const liveTimeList = ['soccer'];

const useLiveStatusStyle = makeStyles((theme: Theme) => {
	return {
		root: {
			height: '2px'
		},
		colorPrimary: {
			backgroundColor: theme.palette.secondary.main
		},
		barColorPrimary: {
			backgroundColor: theme.palette.background.top.main
		}
	};
});

const useEventTimeStyles = makeStyles((theme: Theme) => ({
	root: {
		paddingRight: theme.spacing(2)
	}
}));

// Use to display the start time/live time/ live text for the event
// Animated progress bar for live events
interface EventTimeProps {
	sportKey: string;
	status: string;
	startTime: string;
	eventStatus?: EventMetadata['eventStatus'];
	eventTimeExtended?: EventMetadata['eventTimeExtended'];
}

export const EventTime: React.FunctionComponent<EventTimeProps> = ({
	sportKey,
	status,
	startTime,
	eventStatus,
	eventTimeExtended
}) => {
	const timeZone = useTimeZone();
	const eventTimeStyles = useEventTimeStyles();
	const showLiveTime = liveTimeList.includes(sportKey) && eventTimeExtended;
	const isLive = isEventLive(status);
	const isTradingSuspended = isEventTradingSuspended(status);
	const progressStyles = useLiveStatusStyle();
	const isTimerDisabled = shouldEventTime(
		sportKey,
		eventStatus,
		eventTimeExtended
	);
	const ended = isEventEnded(status);
	const showTimer = isLive && showLiveTime;
	const showStartTime =
		!ended && !isLive && !showLiveTime && !isTradingSuspended;
	const showLinearProgress = isLive && enableLiveLinearProgress;
	const showLiveLabel = (isLive || isTradingSuspended) && !showLiveTime;
	const showEnded = ended;
	return (
		<Typography
			variant={isMobile() ? 'caption' : 'body2'}
			color={isLive ? 'secondary' : 'textSecondary'}
			className={eventTimeStyles.root}
		>
			{showTimer && eventTimeExtended && (
				<Timer
					initialTime={eventTimeExtended}
					disabled={isTimerDisabled}
				/>
			)}
			{showLiveLabel && <Translate label="descriptions.live" />}
			{showStartTime && (
				<TimeDisplay date={startTime} timeZone={timeZone} />
			)}
			{showLinearProgress && <LinearProgress classes={progressStyles} />}
			{showEnded && <Translate label="descriptions.ended" />}
		</Typography>
	);
};

export const ConnectedEventTime = withHook(
	({ eventId, sportKey }: { eventId: number; sportKey: string }) => {
		const status = useSelector(getStatus(eventId));
		const eventMetadata = useSelector(getEventMetadata(eventId));
		const startTime = useSelector(getEventStartTime(eventId));
		const { eventStatus, eventTimeExtended } = eventMetadata || {};
		return {
			status,
			startTime,
			sportKey,
			eventStatus,
			eventTimeExtended
		};
	}
)(EventTime);
