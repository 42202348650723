import {
	SearchResultsProps,
	SearchResultItem
} from 'sports/types/sharedComponents';
import * as React from 'react';
import { connectHits, connectStateResults } from 'react-instantsearch-dom';
import { Hit } from 'react-instantsearch-core';
import { makeStyles, Divider } from '@material-ui/core';

import { SearchResultsHeader } from './Header';
import {
	AlgoliaHitsProvided,
	SearchResultsContentProps,
	SearchResultsContent
} from './Content';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2, 1, 0, 1)
	},
	divider: {
		margin: theme.spacing(1, 0, 2, 0)
	}
}));

const groupBySportKey = (
	array: Hit<SearchResultItem>[]
): { [key: string]: Hit<SearchResultItem>[] } =>
	array.reduce((objectsByKeyValue, obj) => {
		const sportKey = obj.sportKey;
		if (sportKey) {
			objectsByKeyValue[sportKey] = (
				objectsByKeyValue[sportKey] || []
			).concat(obj);
		}
		return objectsByKeyValue;
	}, {});

const SearchResults: React.FC<SearchResultsProps & AlgoliaHitsProvided> = ({
	isSearchStalled,
	searching,
	filters,
	hits
}) => {
	const classes = useStyles();
	/* don't return anything if there's no data and there isn't any search in progress */
	if (hits.length === 0 && !isSearchStalled) return null;
	const groupedHits = Object.entries(groupBySportKey(hits)).filter(
		([sportKey]) =>
			!filters?.length ||
			filters?.find((filter) => filter.name === sportKey)
	);

	return (
		<>
			{groupedHits.map(([sportKey, hits]) => (
				<div key={sportKey} className={classes.root}>
					<SearchResultsHeader sportKey={sportKey} />
					<Divider className={classes.divider} />
					<SearchResultsContent
						hits={hits}
						skeleton={isSearchStalled || searching}
					/>
				</div>
			))}
		</>
	);
};

/* that's required workaround because of algolia bug https://github.com/algolia/react-instantsearch/issues/137 */
const ConnectedSearchResults = connectHits(
	(connectStateResults(SearchResults) as unknown) as React.FC<
		SearchResultsContentProps & AlgoliaHitsProvided
	>
);
export { ConnectedSearchResults as SearchResults };
