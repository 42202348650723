import { useLanguage } from '@gaming-shell/i18n';
import BigNumber from 'bignumber.js';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isPrimaryMarket } from 'sports-sdk';
import { MarketType } from 'sports-sdk/sports-core';
import { eventPromotionPollInterval } from 'sports/config/zeromargin';
import { actions } from 'sports/modules/eventPromotionsModule';
import { SportsState } from 'sports/modules/root';
import {
	getEventPromotionCurrency,
	getEventPromotionCustomerLiabilityRemains
} from 'sports/selectors/eventPromotionsSelectors/eventPromotionsSelectors';
import { getIsZeroMarginPromoActive } from 'sports/selectors/eventSelectors';
import { parseOdds } from 'sports/utils/odds';

import { getEventHasZeroMarginPromotion } from '../../selectors/eventSelectors/eventSelectors';
import { getEventPromotionId } from '../eventPromotionsUtils';
import { startPolling } from '../scheduleUtils';
import {
	getCurrencyExchangeRate,
	ExchangeRatesState
} from 'materialbet-exchange-rates';

export const useIsZeroMarginPromotionActive = (eventId: number) =>
	useSelector(getIsZeroMarginPromoActive(eventId, useLanguage()));

export const getZeroMarginMaxStakeInEuro = ({
	eventId,
	price,
	state,
	exchangeRatesState,
	marketKey
}: {
	eventId: number;
	price: number;
	state: SportsState;
	exchangeRatesState: ExchangeRatesState;
	marketKey: MarketType;
}) => {
	if (
		!isPrimaryMarket(marketKey) ||
		!getEventHasZeroMarginPromotion(eventId)(state)
	) {
		return;
	}
	const promoId = getEventPromotionId({
		eventId,
		promotion: 'zero_margin'
	});
	const promoCurrency = getEventPromotionCurrency(promoId)(state) || 'USD';

	const remainingLiability = getEventPromotionCustomerLiabilityRemains(
		promoId
	)(state);
	if (!remainingLiability) {
		return;
	}
	const exchangeRate =
		getCurrencyExchangeRate(promoCurrency)(exchangeRatesState) || '1';

	const remainingLiabilityInEur = new BigNumber(remainingLiability).dividedBy(
		exchangeRate
	);

	const maxStake = remainingLiabilityInEur
		.dividedBy(parseOdds(new BigNumber(price).minus(1).toNumber()))
		.toNumber();
	return maxStake;
};
/** @descriptions polls the eventPromotion endpoint at the `eventPromotionPollInterval`, if the given event has zero margin promotion */
export const useDispatchActiveZeroMarginEventPromotion = (eventId?: number) => {
	const dispatch = useDispatch();
	const hasPromotion = useSelector(getEventHasZeroMarginPromotion(eventId));
	React.useEffect(() => {
		if (!hasPromotion || !eventId) {
			return;
		}
		return startPolling({
			onPoll: () =>
				dispatch(
					actions.fetchByEvent({ eventId, promotion: 'zero_margin' })
				),
			interval: eventPromotionPollInterval
		});
	}, [hasPromotion, eventId, dispatch]);
};
