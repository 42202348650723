import { SideMenuFooter } from '@gaming-shell/layout';
import { Divider } from '@material-ui/core';
import { usePlayerLoggedIn } from '@materialbet-core/player';
import * as React from 'react';

/** @description a component that contain side menu footer for sport */

let SportsSideMenuFooter: React.FC = () => {
	const isLoggedIn = usePlayerLoggedIn();
	if (!isLoggedIn) {
		return null;
	}
	return (
		<React.Fragment>
			<Divider />
			<SideMenuFooter showPlayerSettings />
		</React.Fragment>
	);
};

SportsSideMenuFooter = React.memo(SportsSideMenuFooter);
export { SportsSideMenuFooter };
