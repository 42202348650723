import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

const MyCasino: React.FunctionComponent<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<path
				fill={props.fill}
				fillRule="nonzero"
				d="M12 5.5l.76-.76a5.41 5.41 0 0 1 8.671 1.415 5.404 5.404 0 0 1-1.01 6.234l-7.88 8.22a.75.75 0 0 1-1.082 0l-7.867-8.206a5.41 5.41 0 0 1 6.24-8.675c.523.258.999.6 1.408 1.012l.76.76zm7.35 5.839a3.909 3.909 0 0 0 0-5.539 3.91 3.91 0 0 0-5.53 0l-1.29 1.29a.75.75 0 0 1-1.06 0L10.178 5.8a3.81 3.81 0 0 0-1.016-.73 3.91 3.91 0 0 0-4.5 6.282L12 19.006l7.35-7.667z"
			/>
		</SvgIcon>
	);
};
export default MyCasino;
