import { defaultSportInfo } from 'sports/config/sport/defaultSportInfo';

import { SportInfo } from '../types';
import { baseInfo } from './baseInfo';
import { marketInfo } from './marketInfo';

export const politics: SportInfo = {
	...defaultSportInfo,
	...baseInfo,
	...marketInfo
} as SportInfo;
