import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: [
			{
				marketType: MarketType.snooker_winner
			},
			{
				marketType: MarketType.snooker_handicap,
				view: MarketView.Handicap
			},
			{
				marketType: MarketType.snooker_totals,
				view: MarketView.Goals
			},
			{
				marketType: MarketType.snooker_correct_score,
				view: MarketView.CorrectScore
			},
			{
				marketType: MarketType.snooker_odd_even_frames
			}
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.snooker_winner
			},
			{
				marketType: MarketType.snooker_handicap
			},
			{
				marketType: MarketType.snooker_totals
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.snooker_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
