import { useTranslation } from '@gaming-shell/i18n';
import { Divider, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { usePlayerLoggedIn } from '@materialbet-core/player';
import clsx from 'clsx';
import {
	ButtonLink,
	getCloudinaryImageUrl,
	SportsRoutes,
	withHook
} from 'materialbet-common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Banner } from 'sports/components/displays/Banner';
import { CustomPaper } from 'sports/components/materialWrapper/CustomPaper';
import { SportType } from 'sports/config/sport';
import {
	zeroMarginSmallImageUrl,
	zeroMarginWideImageUrl,
	zeroMarginWithTitleSmallImageUrl,
	zeroMarginWithTitleWideImageUrl
} from 'sports/config/zeromargin';
import {
	getCompetitionBlank,
	getCompetitionName,
	getCompetitionSportKey
} from 'sports/selectors/competitionSelectors';
import { getEventCompetitionKey } from 'sports/selectors/eventSelectors';

import {
	zeroMarginCompetitionKey,
	zeroMarginSportKey
} from '../../config/zeromargin';
import { usePollZeroMarginEventPromotion } from '../../utils/usePollZeroMarginEventPromotion/usePollZeroMarginEventPromotion';
import { ZeroMarginEventWidgetPlayerPart } from '../ZeroMarginEventWidgetPlayerPart';
import { ZeroMarginEventWidgetPromoPart } from '../ZeroMarginEventWidgetPromoPart/ZeroMarginEventPromoPart';

export interface ZeroMarginEventWidgetProps {
	sportKey: SportType;
	/** @description if we dont pass in an event id, it means it hasnt started yet */
	eventId?: number;
	competitionKey: string;
	competitionName?: string;
	competitionRoute?: string;
	blank?: boolean;
	isPlayerLoggedIn?: boolean;
	/** @description declare size of the image */
	imageSize?: 'small' | 'normal';
	/** @description size of the card narrow or wide */
	variant?: 'narrow' | 'wide';
	/** @description can supply additional classname to apply styles to the root of the widget, handy for spacing and margins */
	className?: string;
}
const useStyles = makeStyles((theme: Theme) => ({
	zeroMarginWidget: {
		padding: theme.spacing(0, 0, 1, 0)
	},
	banner: {
		backgroundSize: 'cover'
	},
	title: {
		padding: theme.spacing(2, 2, 1, 2)
	},
	competitionButton: {
		justifyContent: 'left',
		alignItems: 'flex-end',
		marginTop: theme.spacing(1),
		borderColor: theme.palette.divider
	},
	section: {
		padding: theme.spacing(1)
	},
	buttonSectionDivider: {
		marginTop: theme.spacing(1)
	},
	playerSpendDivider: {
		margin: theme.spacing(1, 0)
	}
}));

let ZeroMarginEventWidgetUi: React.FunctionComponent<ZeroMarginEventWidgetProps> = (
	props
) => {
	const {
		competitionName,
		competitionRoute,
		isPlayerLoggedIn,
		imageSize = 'normal',
		variant = 'narrow',
		eventId,
		sportKey,
		className
	} = props;
	const classes = useStyles();
	const trans = useTranslation();
	const imageHeight = imageSize === 'normal' ? 128 : 30; // from design
	const imageWidth = variant === 'narrow' ? 344 : 522; // from design
	const imageWithTitleUrl =
		variant === 'narrow'
			? zeroMarginWithTitleSmallImageUrl
			: zeroMarginWithTitleWideImageUrl;
	const imageWithoutTitleUrl =
		variant === 'narrow' ? zeroMarginSmallImageUrl : zeroMarginWideImageUrl;
	const imageUrl =
		imageSize === 'normal' ? imageWithTitleUrl : imageWithoutTitleUrl;
	const bannerUrl = getCloudinaryImageUrl({
		imageUrl,
		width: imageWidth,
		height: imageHeight,
		crop: 'fill',
		gravity: imageSize === 'normal' ? 'center' : 'west' // center when have title, left when its no title
	});
	return (
		<CustomPaper className={clsx(classes.zeroMarginWidget, className)}>
			<Banner
				bannerUrl={bannerUrl}
				display="flex"
				flexBasis={'100%'}
				flexGrow="1"
				height={`${imageHeight}px`}
				flexWrap="wrap"
				className={classes.banner}
			/>
			<ZeroMarginEventWidgetPromoPart
				eventId={eventId}
				sportKey={sportKey}
			/>
			{isPlayerLoggedIn && eventId && (
				<>
					<Divider
						variant="fullWidth"
						className={classes.playerSpendDivider}
					/>
					<ZeroMarginEventWidgetPlayerPart eventId={eventId} />
				</>
			)}
			{competitionRoute && (
				<>
					<Divider
						variant="fullWidth"
						className={classes.buttonSectionDivider}
					/>
					<div className={classes.section}>
						<ButtonLink
							variant="outlined"
							color="primary"
							className={classes.competitionButton}
							href={competitionRoute}
						>
							{trans('sports:event.seeCompetitionGames', {
								competition: competitionName
							})}
						</ButtonLink>
					</div>
				</>
			)}
		</CustomPaper>
	);
};
ZeroMarginEventWidgetUi = React.memo(ZeroMarginEventWidgetUi);

const useZeroMarginEventWidgetProps = ({
	eventId,
	imageSize = 'normal',
	variant = 'narrow',
	className
}: Pick<
	ZeroMarginEventWidgetProps,
	'eventId' | 'imageSize' | 'variant' | 'className'
>): ZeroMarginEventWidgetProps => {
	const {
		sportKey: routeSportKey,
		competitionKey: routeCompetitionKey
	} = useParams();
	usePollZeroMarginEventPromotion(eventId);
	const competitionKey =
		useSelector(getEventCompetitionKey(eventId)) ||
		zeroMarginCompetitionKey;
	const name = useSelector(getCompetitionName(competitionKey));
	const sportKey =
		useSelector(getCompetitionSportKey(competitionKey)) ||
		zeroMarginSportKey;
	const blank = useSelector(getCompetitionBlank(competitionKey));
	const isPlayerLoggedIn = usePlayerLoggedIn();
	const onCompetitionPage =
		routeSportKey + '-' + routeCompetitionKey === competitionKey;
	const competitionRoute = React.useMemo(
		() =>
			!onCompetitionPage
				? SportsRoutes.competition.create({
						sportKey:
							sportKey || routeSportKey || zeroMarginSportKey,
						competitionKey:
							competitionKey || zeroMarginCompetitionKey
				  })
				: undefined,
		[sportKey, routeSportKey, competitionKey, onCompetitionPage]
	);
	return {
		eventId,
		sportKey: (sportKey || routeSportKey || '') as SportType,
		competitionKey: competitionKey || '',
		competitionName: name,
		competitionRoute,
		isPlayerLoggedIn,
		blank,
		imageSize,
		variant,
		className
	};
};

/** @description zero margin widget for a given competition */
export const ZeroMarginEventWidget = withHook(useZeroMarginEventWidgetProps)(
	ZeroMarginEventWidgetUi
);
