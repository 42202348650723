"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSimplePreviewCardStyles = void 0;
var core_1 = require("@material-ui/core");
exports.useSimplePreviewCardStyles = core_1.makeStyles(function (theme) { return ({
    simplePreviewCard: {
        background: theme.palette.background.top.main,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: theme.shape.borderRadius
    },
    link: {
        padding: theme.spacing(1),
        '&:hover': {
            background: 'transparent'
        }
    },
    media: {
        height: '75%'
    },
    cardContent: {
        padding: theme.spacing(1.5, 2),
        paddingBottom: theme.spacing(1.5) + "px !important",
        textTransform: 'none',
        height: '25%',
        display: 'flex',
        alignContent: 'center'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    title: {
        lineHeight: 1.19 //Reduce line height based on design
    },
    ellipsisTextOverflow: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
}); });
