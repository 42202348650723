import { ButtonBase } from '@material-ui/core';
import { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

type LinkButtonBaseProps = ButtonBaseProps & { to: LinkProps['to'] };
// TODO can probably turn this into a HOC or something
export const LinkButtonBase: React.FunctionComponent<LinkButtonBaseProps> = (
	props
) => {
	// prettier-ignore
	return (<ButtonBase {...props} component={Link as React.ElementType} />)
};
