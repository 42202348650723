import * as React from 'react';
import { PlayerRoutes } from 'materialbet-common';
import { MyAccountIcon } from 'materialbet-icons';
import { Translate } from '@gaming-shell/i18n';

import { AccountMenuItem } from '../AccountMenuItem';

export const MyAccountMenuItem: React.FC = () => (
	<AccountMenuItem href={PlayerRoutes.account.create()}>
		<MyAccountIcon />
		<Translate label="myAccount.myAccount" ns="player" />
	</AccountMenuItem>
);
