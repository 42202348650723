import { Box, Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

import { PromoCardContext } from '../../PromoCard';
import { PromoHeaderImage } from '../PromoHeaderImage';
import { PromoHeaderTitle } from '../PromoHeaderTitle';

export type PromoHeaderClassKey =
	| 'root'
	| 'leftBox'
	| 'rightBox'
	| 'media'
	| 'expandBtn';

export interface PromoHeaderProps {
	title?: string;
	expires?: string;
	expired?: boolean;
	imageUrl?: string;
	onExpand?: () => void;
	classes?: Partial<Record<PromoHeaderClassKey, string>>;
	variant?: 'horizontal' | 'vertical';
	cardMediaClassName?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	vertical: ({ expired }: PromoHeaderProps) => ({
		[theme.breakpoints.down('xs')]: {
			flexWrap: 'wrap',
			display: 'flex',
			justifyContent: 'space-between',
			padding: expired ? theme.spacing(2) : theme.spacing(2, 2, 0, 2)
		}
	}),
	horizontal: {
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		flex: 1
	},
	sections: {
		flex: 1,
		padding: `0 ${theme.spacing(2)}px`,
		[theme.breakpoints.down('xs')]: {
			padding: 0
		}
	},
	verticalSections: {
		[theme.breakpoints.down('xs')]: {
			flexBasis: '100%'
		}
	}
}));

export const PromoHeader: React.FunctionComponent<PromoHeaderProps> = ({
	children,
	cardMediaClassName
}) => {
	const {
		variant,
		imageUrl,
		title,
		expires,
		expired,
		onExpand
	} = React.useContext(PromoCardContext);
	const classes = useStyles({ expired });

	const headerContent = (
		<>
			<PromoHeaderTitle
				title={title}
				subtitle={expires}
				onArrowClick={onExpand}
			/>
			{!expired && (
				<Box
					className={clsx(
						classes.sections,
						classes[`${variant}Sections`]
					)}
				>
					{children}
				</Box>
			)}
		</>
	);
	let wrappedHeaderContent = null;
	if (variant === 'horizontal')
		// if it's horizontal wrap it with box
		wrappedHeaderContent = (
			<Box className={classes.horizontal}>{headerContent}</Box>
		);

	return (
		<Box className={classes[variant!]}>
			{imageUrl && (
				<PromoHeaderImage
					className={cardMediaClassName}
					variant={variant}
					imageUrl={imageUrl}
					expired={expired}
				/>
			)}
			{wrappedHeaderContent || headerContent}
		</Box>
	);
};
