import * as React from 'react';

/* currently only 'en' language is supported thus setting different one will result in bad request*/
/** @description a helper to fetch data json for a given path*/
export const useStaticContent = <T extends {}>(
	url: string,
	language = 'en'
) => {
	const [content, setContent] = React.useState<T>();
	const [isLoading, setLoading] = React.useState(true);
	const key = `/${language}/${url}`;
	React.useEffect(() => {
		const localizedUrl = `https://${window.location.hostname}${key}.json`;
		fetch(localizedUrl, {
			headers: { Authorization: BASE_API_BASIC_AUTH }
		})
			.then((response) => response.json())
			.then((response) => {
				if (ENABLE_STATIC_CONTENT_DRAFT || !response.draft) {
					setContent(response);
					setLoading(false);
				} else {
					throw response;
				}
			})
			.catch((_) => {
				// page just stays empty for now
				// TODO should display error
				if (language !== 'en') {
					fetch(localizedUrl, {
						headers: { Authorization: BASE_API_BASIC_AUTH }
					})
						.then((response) => response.json())
						.then((response) => {
							if (
								ENABLE_STATIC_CONTENT_DRAFT ||
								!response.draft
							) {
								setContent(response);
								setLoading(false);
							} else {
								throw response;
							}
						})
						.catch((_) => {
							setLoading(false);
						});
				} else {
					setLoading(false);
				}
			});
	}, [key]);

	return { content, isLoading };
};

export const toKey = (value: string) => value.toLowerCase().replace(/\s/g, '_');
