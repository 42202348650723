import { styled } from '@material-ui/core';
import SlickSlider from 'react-slick';

// slick-theme.css
// https://github.com/kenwheeler/slick/blob/master/slick/slick-theme.css

// slick.css
// https://github.com/kenwheeler/slick/blob/master/slick/slick.css

/** @description just act like react-slick slider but with customized styles */
export const Slider = styled(SlickSlider)((props) => ({
	position: 'relative',
	display: 'block',
	boxSizing: 'border-box',
	webkitTouchCallout: 'none',
	webkitUserSelect: 'none',
	userSelect: 'none',
	touchAction: 'pan-y',
	webkitTapHighlightColor: 'transparent',
	'&.slick-dotted': { marginBottom: '30px' },
	'& .slick-arrow.slick-hidden': {
		display: 'none'
	},
	'& .slick-list': {
		position: 'relative',
		overflow: 'hidden',
		display: 'block',
		margin: 0,
		padding: 0,
		transform: 'translate3d(0, 0, 0)',
		'&:focus': {
			outline: 'none'
		},
		'&.dragging': {
			cursor: 'pointer'
		},
		'.slick-loading &': {
			background:
				"#fff slick-image-url('ajax-loader.gif') center center no-repeat"
		}
	},
	'& .slick-track': {
		position: 'relative',
		left: 0,
		top: 0,
		display: 'flex',
		alignItems: 'center',
		transform: 'translate3d(0, 0, 0)',
		'&:before, &:after': {
			content: '',
			display: 'table'
		},
		'&:after': {
			clear: 'both'
		},
		'.slick-loading &': {
			visibility: 'hidden'
		}
	},
	'& .slick-slide': {
		float: 'left',
		height: '100%',
		minHeight: 1,
		// margin: props.theme.spacing(1),
		'[dir="rtl"] &': {
			float: 'right'
		},
		'& img': {
			display: 'block'
		},
		'&.slick-loading img': {
			display: 'none'
		},
		'&.dragging img': {
			pointerEvents: 'none'
		},
		'.slick-initialized &': {
			display: 'block'
		},
		'.slick-loading &': {
			visibility: 'hidden'
		},
		'.slick-vertical &': {
			display: 'block',
			height: 'auto',
			border: '1px solid transparent'
		}
	},
	'& .slick-prev, & .slick-next': {
		fontSize: '0',
		lineHeight: '0',
		position: 'absolute',
		top: '50%',
		display: 'block',
		width: '20px',
		height: '20px',
		padding: '0',
		WebkitTransform: 'translate(0, -50%)',
		msTransform: 'translate(0, -50%)',
		transform: 'translate(0, -50%)',
		cursor: 'pointer',
		color: 'transparent',
		background: 'transparent',
		border: 'none',
		outline: 'none',
		'&:before': {
			fontSize: 16,
			lineHeight: '1',
			opacity: 0.75,
			color: 'white',
			webkitFontSmoothing: 'antialiased',
			mozOsxFontSmoothing: 'grayscale'
		},
		'&:hover, &:focus': {
			color: 'transparent',
			outline: 'none',
			background: 'transparent',
			'&:before': {
				opacity: 1
			}
		},
		'&.slick-disabled': {
			'&:before': {
				opacity: 0.25
			}
		}
	},
	'& .slick-prev': {
		left: '-18px',
		'[dir="rtl"] &': {
			left: 'auto',
			right: '-18px'
		},
		'&:before': {
			content: "'←'",
			'[dir="rtl"] &': {
				content: "'→'"
			}
		}
	},
	'& .slick-next': {
		right: '-18px',
		'[dir="rtl"] &': {
			left: '-18px',
			right: 'auto'
		},
		'&:before': {
			content: "'→'",
			'[dir="rtl"] &': {
				content: "'←'"
			}
		}
	},
	'& .slick-dots': {
		position: 'absolute',
		bottom: '-25px',
		display: 'block',
		width: '100%',
		padding: '0',
		margin: '0',
		listStyle: 'none',
		textAlign: 'center',
		'& li': {
			position: 'relative',
			display: 'inline-block',
			margin: props.theme.spacing(0, 0.5),
			padding: 5,
			cursor: 'pointer',
			'& button': {
				fontSize: 0,
				lineHeight: 0,
				display: 'block',
				padding: 0,
				cursor: 'pointer',
				color: 'transparent',
				border: 0,
				outline: 'none',
				background: 'transparent',
				position: 'relative',
				'&:before': {
					fontSize: 32,
					lineHeight: '24px',
					position: 'absolute',
					top: 0,
					left: 0,
					width: 10,
					height: 10,
					content: "'•'",
					textAlign: 'center',
					opacity: 0.25,
					color: 'white',
					webkitFontSmoothing: 'antialiased',
					mozOsxFontSmoothing: 'grayscale'
				},
				'&:hover, &:focus': {
					outline: 'none',
					'&:before': {
						opacity: 0.6,
						color: props.theme.palette.primary.main
					}
				}
			},
			'&.slick-active': {
				'& button:before': {
					opacity: 1,
					color: props.theme.palette.primary.main
				}
			}
		}
	}
})) as React.ComponentType<
	React.Props<SlickSlider> & {
		ref: React.MutableRefObject<SlickSlider | null>;
	}
>;
// styled's typing is not keeping the ref prop so we have to add it back here
