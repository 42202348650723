"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CuracaoSeal = void 0;
var React = __importStar(require("react"));
var addScript = function (url, onLoad) {
    var scriptNode = document.createElement('script');
    var firstScriptNode = document.getElementsByTagName('script')[0];
    scriptNode.async = true;
    scriptNode.src = url;
    scriptNode.onload = onLoad;
    var parent = firstScriptNode.parentNode;
    if (parent) {
        parent.insertBefore(scriptNode, null);
    }
};
exports.CuracaoSeal = React.memo(function (_a) {
    var id = _a.id;
    var url = "https://" + id + ".curacao-egaming.com/ceg-seal.js";
    var elementId = "ceg-" + id;
    var globalVar = elementId.replace(/-/g, '_');
    React.useEffect(function () {
        addScript(url, function () {
            var initializer = window[globalVar];
            if (initializer && initializer.init) {
                initializer.init();
            }
            else {
                console.warn('Curacao license script did no set global variable: ' +
                    globalVar);
            }
        });
    }, []);
    return (React.createElement("div", { id: elementId, "data-ceg-seal-id": id, "data-ceg-image-size": "50", "data-ceg-image-type": "basic-small" }));
});
