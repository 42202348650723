import { SportsState } from 'sports/modules/root';

type EventPromotionId = string;
/** @file as the sports selectors file is growin large, createing this one to create selectors for single fields in the event entity */
const eventsPromotionsSelector = (state: SportsState) => state.eventPromotions;

export const getEventPromotion = (state: SportsState, id: EventPromotionId) =>
	id === undefined ? undefined : eventsPromotionsSelector(state)[id];

const forEventPromotion = <T>(
	selector: (promo: NonNullable<ReturnType<typeof getEventPromotion>>) => T
) => (id: EventPromotionId) => (state: SportsState) => {
	const p = getEventPromotion(state, id);
	return p !== undefined ? selector(p) : p;
};
export const getEventPromotionCustomerLiability = forEventPromotion(
	(p) => p.customerLiability
);
export const getEventPromotionCustomerLiabilityLimit = forEventPromotion(
	(p) => p.customerLiabilityLimit
);
export const getEventPromotionCustomerLiabilityRemains = forEventPromotion(
	(p) => {
		const liability = parseFloat(p.customerLiability || '0') || 0;
		const liabilityLimit = parseFloat(p.customerLiabilityLimit || '0') || 0;
		const libabilityRemains = liabilityLimit - liability;
		const roundedLibabilityRemains =
			libabilityRemains >= 1 ? libabilityRemains : 0;
		return roundedLibabilityRemains;
	}
);
export const getEventPromotionCustomerBets = forEventPromotion(
	(p) => p.customerBets
);
export const getEventPromotionSlotsTaken = forEventPromotion(
	(p) => p.slotsTaken
);
export const getEventPromotionSlotsAvailable = forEventPromotion(
	(p) => p.slotsAvailable
);
export const getEventPromotionCurrency = forEventPromotion((p) => p.currency);
