import * as React from 'react';
import fetchIntercept from 'fetch-intercept';
import {
	usePlayerLoggedIn,
	useUpdatePlayer,
	defaultState,
	Player
} from '@materialbet-core/player';
import { useHistory } from 'react-router';
import { useSnackbar, AuthRoutes } from 'materialbet-common';
import { History } from 'history';

const playerRegex = /api\/v\d+\/player\//;
export const sessionExpirationInterceptor = (
	history: History,
	enqueueSnackbar: (text: string) => void,
	updatePlayer: React.Dispatch<React.SetStateAction<Player>>
) =>
	fetchIntercept.register({
		response: function (response) {
			if (response.status === 401 && response.url.match(playerRegex)) {
				updatePlayer((player) => ({
					...defaultState,
					ipCountry: player.ipCountry,
					isLoading: false
				}));
				enqueueSnackbar('Your session expired. Please sign in again.');
				history.push(AuthRoutes.signIn.create());
			}
			return response;
		}
	});

export const useTrackSession = () => {
	const isLoggedIn = usePlayerLoggedIn();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const updatePlayer = useUpdatePlayer();

	React.useEffect(() => {
		if (!isLoggedIn) {
			return;
		}
		const unregisterSession = sessionExpirationInterceptor(
			history,
			enqueueSnackbar,
			updatePlayer
		);
		return unregisterSession;
	}, [isLoggedIn, enqueueSnackbar, history, updatePlayer]);
};
