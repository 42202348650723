import * as React from 'react';
import { CurrencyVariantConfig } from 'sports/config/currency';
import { useTrailing } from 'sports/utils/stake';
import { trackBetslipMobileKeyboardOpen } from 'sports/utils/trackingUtils/trackingUtils';

import { QuickBetslipVariant } from '../types';
import { QuickBetslipStakeControl } from './QuickBetslipStakeControl';
import { QuickBetslipStakeField } from './QuickBetslipStakeField';

export interface QuickBetslipStakeInputFormProps {
	minStake: number;
	maxStake: number;
	stake: number;
	onChange: (stake: number) => void;
	onStakeDelete: () => void;
	onConfirmClick: () => void;
	onSecondaryActionClick: () => void;
	disabled?: boolean;
	disableIncreaseStake?: boolean;
	selectionResult: JSX.Element;
	variant: QuickBetslipVariant;
	isSubmitting: boolean;
	currencyConfig: CurrencyVariantConfig;
	disableConfirmButton?: boolean;
	disableSecondaryButton?: boolean;
}
let QuickBetslipStakeInputForm: React.FunctionComponent<QuickBetslipStakeInputFormProps> = (
	props
) => {
	const {
		minStake,
		maxStake,
		stake,
		variant,
		onChange,
		onStakeDelete,
		disabled,
		onConfirmClick,
		onSecondaryActionClick,
		selectionResult,
		isSubmitting,
		currencyConfig,
		disableIncreaseStake,
		disableConfirmButton,
		disableSecondaryButton
	} = props;
	const [showDialpad, setShowDialpad] = React.useState(false);
	const toggleShowDialpad = React.useCallback(() => {
		setShowDialpad((s) => {
			if (!s) {
				trackBetslipMobileKeyboardOpen();
			}
			return !s;
		});
	}, [setShowDialpad]);
	const [trailing, setTrailing] = useTrailing(stake);
	React.useLayoutEffect(() => {
		if (stake !== trailing.forStake) {
			setTrailing({ trail: '', forStake: stake });
		}
	}, [stake, trailing, setTrailing]);
	const handleOnStakeDelete = React.useCallback(() => {
		setTrailing({ trail: '', forStake: 0 });
		onStakeDelete();
	}, [setTrailing, onStakeDelete]);
	return (
		<>
			<QuickBetslipStakeField
				toggleInputMethod={toggleShowDialpad}
				stake={stake}
				trailing={trailing.trail}
				setTrailing={setTrailing}
				showDialpad={showDialpad}
				onChange={onChange}
				currencyConfig={currencyConfig}
			/>

			{selectionResult}
			<QuickBetslipStakeControl
				stake={stake}
				minStake={minStake}
				maxStake={maxStake}
				onStakeChange={onChange}
				onStakeDelete={handleOnStakeDelete}
				showDialpad={showDialpad}
				disabled={disabled}
				disableIncreaseStake={disableIncreaseStake}
				disableSecondaryButton={disableSecondaryButton}
				onConfirmClick={onConfirmClick}
				onSecondaryActionClick={onSecondaryActionClick}
				variant={variant}
				trailing={trailing.trail}
				setTrailing={setTrailing}
				isSubmitting={isSubmitting}
				disableConfirmButton={disableConfirmButton}
			/>
		</>
	);
};
QuickBetslipStakeInputForm = React.memo(QuickBetslipStakeInputForm);
export { QuickBetslipStakeInputForm };
