import {
	Divider,
	Paper,
	Theme,
	makeStyles,
	PaperProps
} from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

import { Column, Row, RowProps } from '../../Layout';
import { isDesktop } from '@gaming-shell/device';

export interface BannerProps extends PaperProps {
	buttons?: React.ReactNode;
	text: React.ReactNode;
	icon?: React.ReactNode;
	hideDivider?: boolean;
	ContentProps?: Partial<RowProps>;
}

const useStyles = makeStyles((theme: Theme) => ({
	column: {
		padding: theme.spacing(isDesktop() ? 2 : 1)
	},
	row: {
		'&> :first-child': {
			marginRight: theme.spacing(2)
		}
	},
	buttons: {
		marginLeft: 'auto' //for desktop alignment
	}
}));

export const Banner: React.FunctionComponent<BannerProps> = ({
	elevation = 0,
	buttons,
	text,
	icon,
	hideDivider,
	className,
	square,
	ContentProps: { className: contentClassName, ...restContentProps } = {}
}) => {
	const classes = useStyles();

	return (
		<Paper elevation={elevation} className={className} square={square}>
			<Column className={classes.column} alignItems="flex-end">
				<Row
					wrap="nowrap"
					className={clsx(contentClassName, icon && classes.row)}
					alignItems={isDesktop() ? 'center' : 'flex-start'}
					{...restContentProps}
				>
					{icon}
					{text}
					{isDesktop() && (
						<div className={classes.buttons}>{buttons}</div>
					)}
				</Row>
				{!isDesktop() && buttons}
			</Column>
			{!hideDivider && <Divider />}
		</Paper>
	);
};
