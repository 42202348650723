import { Theme } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	button: {
		marginTop: theme.spacing(2)
	}
}));
export const BigBetslipActionPanelButton: React.FunctionComponent<
	ButtonProps
> = (props) => {
	const classes = useStyles();
	return (
		<Button
			className={classes.button}
			variant="contained"
			size="large"
			fullWidth={true}
			{...props}
		/>
	);
};
