export type AnnouncementPath = 'account' | 'sports' | 'casino' | '';
export enum AnnouncementImportance {
	CRITICAL = 'CRITICAL',
	WARNING = 'WARNING',
	NOTICE = 'NOTICE',
	INFO = 'INFO'
}
export interface Announcement {
	id: number;
	title: string;
	description: string;
	importance: AnnouncementImportance;
	validFrom: number;
	validTo: number;
	sitePath: AnnouncementPath;
	country: string;
	language: string;
}
