import { SportsView } from '@materialbet-core/player';
import * as cookie from 'cookie';

export const sportsViewCookieName = 'sportsView';

export const getPersistedSportsView = (): SportsView | undefined => {
	const cookies = cookie.parse(document.cookie);
	return cookies[sportsViewCookieName] as SportsView;
};

export const persistSportsView = (variant: SportsView) => {
	document.cookie = `${sportsViewCookieName}=${variant};path=/`;
};

export const resetSportsView = () => {
	document.cookie =
		sportsViewCookieName + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
