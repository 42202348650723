import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	background: ({ imageUrl }: { imageUrl: string }) => ({
		backgroundImage: `url(${imageUrl})`
	})
});

/** @description Handy hook which returns className with background-image with passed imageUrl */
export const useBackgroundStyle = (imageUrl?: string) => {
	if (imageUrl) {
		const { background: backgroundClassName } = useStyles({ imageUrl });
		return backgroundClassName;
	}

	return undefined;
};
