"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandLogo = void 0;
var device_1 = require("@gaming-shell/device");
var theming_1 = require("@gaming-shell/theming");
var core_1 = require("@material-ui/core");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var useStyles = core_1.makeStyles(function (theme) { return ({
    root: {
        marginRight: theme.spacing(1)
    },
    img: {
        alignSelf: 'center',
        height: device_1.isMobile() ? '20px' : '24px'
    }
}); });
var ButtonBaseLink = materialbet_common_1.createMemoizedLink(core_1.ButtonBase);
var BrandLogo = function () {
    var classes = useStyles();
    var logoUrl = theming_1.useLogoUrls()[device_1.getPlatform()];
    var handleUrlChange = React.useCallback(function () {
        if (materialbet_common_1.CasinoRoutes.root.matches()) {
            return materialbet_common_1.CasinoRoutes.root.create();
        }
        if (ENABLE_ESPORTS_SECTION && materialbet_common_1.EsportsRoutes.root.matches()) {
            return materialbet_common_1.EsportsRoutes.root.create();
        }
        return materialbet_common_1.SportsRoutes.root.create();
    }, []);
    return (React.createElement(ButtonBaseLink, { className: classes.root, href: handleUrlChange, disableRipple: true, disableTouchRipple: true, "aria-label": "Logo" },
        React.createElement("img", { src: logoUrl, className: classes.img })));
};
exports.BrandLogo = BrandLogo;
exports.BrandLogo = BrandLogo = React.memo(BrandLogo);
