import { Theme, makeStyles } from '@material-ui/core';
import * as React from 'react';

import {
	PromoCollapsedContent,
	PromoExpandableCard,
	PromoExpandableCardProps,
	PromoStatus
} from './components';

export type PromoCardVariants = 'horizontal' | 'vertical';

export interface PromoCardProps
	extends Omit<PromoExpandableCardProps, 'variant'> {
	termsTitle?: string;
	terms?: React.ReactNode;
	title?: string;
	expires?: string;
	expired?: boolean;
	active?: boolean;
	variant?: PromoCardVariants;
	actions?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		position: 'relative'
	},
	content: {
		padding: 0
	},
	actions: {
		padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
			2
		)}px`
	}
}));

export interface PromoCardContext extends PromoCardProps {
	onExpand?: () => void;
}

export const PromoCardContext = React.createContext<PromoCardContext>({
	variant: 'vertical'
});

export const PromoCard: React.FunctionComponent<PromoCardProps> = ({
	title,
	expires,
	expired,
	termsTitle,
	terms,
	actions,
	variant = 'vertical',
	imageUrl,
	active,
	children,
	...rest
}) => {
	const classes = useStyles();
	const [collapsed, setCollapsed] = React.useState(false);
	const handleExpand = React.useCallback(
		() => setCollapsed((prev) => !prev),
		[setCollapsed]
	);

	return (
		<PromoCardContext.Provider
			value={{
				variant,
				imageUrl,
				expired,
				title,
				expires,
				onExpand: terms ? handleExpand : undefined
			}}
		>
			<PromoExpandableCard
				classes={classes}
				collapsedContent={
					<PromoCollapsedContent title={termsTitle}>
						{terms}
					</PromoCollapsedContent>
				}
				cardActionItems={!expired && actions}
				collapsed={collapsed}
				skipCardContentWrapper
				{...rest}
			>
				{!expired && active && <PromoStatus />}
				{children}
			</PromoExpandableCard>
		</PromoCardContext.Provider>
	);
};
