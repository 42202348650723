import * as React from 'react';

import { BetslipContext } from '../BetslipContext';
import { persistBetslip } from './persistBetslip';

export const usePersistBetslipOnUnload = (onlyOnSubmitting = false) => {
	const betslipContext = React.useContext(BetslipContext);
	const onUnloadRegisteredRef = React.useRef(false);
	const store = betslipContext.store;
	React.useEffect(() => {
		if (onUnloadRegisteredRef.current) {
			return;
		}
		const persist = () => {
			const state = store.getState();

			if (
				!state.betslip.selections.length ||
				(onlyOnSubmitting && !state.isSubmitting)
			) {
				return;
			}
			persistBetslip(state);
		};

		window.addEventListener('beforeunload', persist);
		onUnloadRegisteredRef.current = true;
		return () => window.removeEventListener('beforeunload', persist);
		// we never expect the store to change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
