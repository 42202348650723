import { Grid } from '@material-ui/core';
import { OutcomeButton } from 'materialbet-sports/src/components/outcome/OutcomeButton';
import { OutcomeDisplayText } from 'materialbet-sports/src/components/outcome/OutcomeDisplayText';
import * as React from 'react';
import { connect } from 'react-redux';
import { MarketType } from 'sports-sdk';
import { deferComponentRender } from 'sports/hocs/deferComponentRender';
import { RootState } from 'sports/modules/root';
import { makeGetFirstOrderdSubmarketKey } from 'sports/selectors/selectionSelectors';
import { useOutcomeVariables } from 'sports/utils/hooks';

import { ShortOutcomeProps } from './types';

/**@description Controls the outcome content format displayed on root sports page, in-play/Today/Tomorrow of single sports page*/
export const ShortOutcome: React.FC<ShortOutcomeProps> = (props) => {
	const {
		size,
		variant,
		eventId,
		marketKey,
		outcome,
		submarketKey,
		params
	} = props;

	/**@description Used for outcomeVariable hook */
	const {
		isInBetslip,
		stakeLabel,
		outcomeClasses,
		disabled,
		styles
	} = useOutcomeVariables({
		eventId,
		marketKey,
		submarketKey: submarketKey || '',
		outcome,
		params
	});

	const hasTeamName = /(away|home|draw_draw)/.test(outcome || '');
	return (
		<Grid
			item={true}
			sm={size || 12}
			md={hasTeamName && size && size < 4 ? 6 : size}
			lg={size}
			direction="column"
			container={true}
		>
			<OutcomeButton
				disabled={disabled}
				title={stakeLabel}
				className={outcomeClasses.button}
				active={isInBetslip}
				eventId={eventId}
				marketKey={marketKey}
				submarketKey={submarketKey || ''}
				outcome={outcome || ''}
				style={styles}
				params={params}
			>
				<OutcomeDisplayText
					eventId={eventId}
					outcome={outcome}
					params={params}
					marketType={marketKey}
					submarketKey={submarketKey}
					variant={variant}
					simplified={true}
					shorten={true}
				/>
			</OutcomeButton>
		</Grid>
	);
};

const mapStateToProps = () => {
	const getSubmarketKey = makeGetFirstOrderdSubmarketKey();
	return (
		state: RootState,
		props: {
			eventId: number;
			marketKey: MarketType;
			outcome?: string;
		}
	) => {
		const { eventId, marketKey, outcome } = props;

		const submarketKey = getSubmarketKey(state, {
			eventId,
			marketType: marketKey,
			outcome
		});
		return {
			submarketKey
		};
	};
};
export const ConnectedShortOutcome = connect(mapStateToProps)(ShortOutcome);
export const DeferredConnectedShortOutcome = deferComponentRender()(
	ConnectedShortOutcome
);
export const DeferredShortOutcome = deferComponentRender()(ShortOutcome);
