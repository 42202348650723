import { CasinoApiSection } from 'casino/api/casino';
import { schema } from 'normalizr';

import {
	FreespinsOffer,
	Game,
	GameExtras,
	GameRecord,
	GameStudio,
	RolloverBonus,
	StudioWithGameCount
} from './api/casino/api';

export const games = new schema.Entity('games');
export const sections = new schema.Entity('sections');
export const studios = new schema.Entity(
	'studios',
	{},
	{ idAttribute: (value: StudioEntity) => value.key }
);
export const rolloverBonuses = new schema.Entity('rolloverBonuses');
export const freespins = new schema.Entity(
	'freespins',
	{},
	{ idAttribute: (value: FreespinEntity) => value.uuid }
);
export const winners = new schema.Entity('winners');
games.define({
	studio: studios
});
freespins.define({
	game: games
});

type NullableProps<T extends object> = { [key in keyof T]: T[key] | null };
export type GameEntity = NullableProps<
	Omit<GameRecord & Game, 'studio' | 'id' | 'extras' | 'min_stake'>
> & {
	id: string;
	/** @description key referencing game studio */
	studio: string;
	extras?: GameExtras[]; // one of the two types has it typed as any, widening it to any in the entity - not good
	min_stake?: Record<string, number> | null;
};

export type SectionEntity = NullableProps<Omit<CasinoApiSection, 'id'>> & {
	id: string;
};

export type StudioEntity = Omit<GameStudio | StudioWithGameCount, 'key'> & {
	// we just need to assume it always has a key - if not it will never be able to be referenced anyway
	key: string;
};

/** @description the data type of winner from the pusher */
export type Winner = {
	/** @description id of winner casino round */
	id: string;
	/** @description encrypted name of the user, e.g. big****** */
	nickname: string;
	/** @description amount of winning in the given currency */
	amount: number;
	/** @description currency of the winning amount */
	currency: string;
	/**  @description name of the game the recent winner played for the win*/
	gameName: string;
	/**  @description studio of the game for the winner */
	studioName: string;
	/**  @description timestring of the winning round */
	createTime: string;
	/**  @description id of the game */
	gameId?: string;
	/**  @description key of game*/
	gameKey?: string;
	/**  @description type key of the game */
	gameType?: string;
	isLive?: boolean;
	countryBlacklist?: string[];
	supportedCurrencies?: string[];
	platforms?: string[];
};

/** @description the data type of winner store in the store, not supposed to see countryBlacklist */
export type WinnerEntity = Omit<Winner, 'countryBlacklist'>;

export type FreespinEntity = NullableProps<
	Omit<FreespinsOffer, 'game' | 'uuid'>
> & {
	uuid: string;
	/** @description gameId */
	game: string;
};
export type RolloverBonusEntity = NullableProps<Omit<RolloverBonus, 'id'>> & {
	id: string;
};
export type CasinoEntityKeys = 'games' | 'sections' | 'studios';
interface CasinoEntitiesStructure {
	games?: Record<string, GameEntity>;
	sections?: Record<string, SectionEntity>;
	studios?: Record<string, StudioEntity>;
	winners?: Record<string, WinnerEntity[]>;
	freespins?: Record<string, FreespinEntity>;
	rolloverBonuses?: Record<string, RolloverBonusEntity>;
}
export type CasinoEntitiesKeys = keyof CasinoEntitiesStructure;
// TODO create type with lookup fopr index signature
type CasinoEntitiesIndexSiganture = {
	[key in CasinoEntitiesKeys]: CasinoEntities[key];
};
export type CasinoEntities = CasinoEntitiesIndexSiganture;
