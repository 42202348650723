import { CasinoState } from 'casino/modules/rootModule';

export type StudioId = string | undefined;
const studiosSelector = (state: CasinoState) => state.studios;
export const getStudio = (id: StudioId) => (state: CasinoState) =>
	id === undefined ? undefined : studiosSelector(state)[id];
export const getStudioName = (id: StudioId) => (state: CasinoState) =>
	getStudio(id)(state)?.name;
export const getStudios = (ids?: StudioId[]) => (state: CasinoState) =>
	ids?.map((id) => getStudio(id)(state));
