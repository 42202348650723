/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
	CompetitionResponse,
	CompetitionResponseFromJSON,
	CompetitionResponseToJSON,
	CompetitionWithCategoryResponse,
	CompetitionWithCategoryResponseFromJSON,
	CompetitionWithCategoryResponseToJSON,
	EventListResponse,
	EventListResponseFromJSON,
	EventListResponseToJSON,
	EventResponse,
	EventResponseFromJSON,
	EventResponseToJSON,
	SportWithCategoryResponse,
	SportWithCategoryResponseFromJSON,
	SportWithCategoryResponseToJSON,
	SportsResponse,
	SportsResponseFromJSON,
	SportsResponseToJSON,
	TopCouponsListResponse,
	TopCouponsListResponseFromJSON,
	TopCouponsListResponseToJSON,
	TopCouponsResponse,
	TopCouponsResponseFromJSON,
	TopCouponsResponseToJSON
} from '../models';

export interface GetCompetitionEventsRequest {
	key: string;
	from?: number;
	to?: number;
	markets?: Array<string>;
	players?: boolean;
	limit?: number;
	live?: boolean;
	locale?: string;
}

export interface GetCompetitionKeyRequest {
	id: number;
	locale?: string;
}

export interface GetCompetitionOutrightsRequest {
	key: string;
	markets?: Array<string>;
	limit?: number;
	locale?: string;
}

export interface GetEventsRequest {
	from?: number;
	to?: number;
	sports?: Array<string>;
	markets?: Array<string>;
	live?: boolean;
	players?: boolean;
	upcoming?: boolean;
	priority?: number;
	streaming?: boolean;
	virtual?: boolean;
	limit?: number;
	locale?: string;
}

export interface GetMainEventRequest {
	id: number;
	markets?: Array<string>;
	locale?: string;
}

export interface GetMainOutrightRequest {
	id: number;
	markets?: Array<string>;
	locale?: string;
}

export interface GetOutrightsRequest {
	sports?: Array<string>;
	markets?: Array<string>;
	priority?: number;
	limit?: number;
	locale?: string;
}

export interface GetPopularCompetitionsRequest {
	markets?: Array<string>;
	players?: boolean;
	numComp?: number;
	locale?: string;
}

export interface GetPopularEventsRequest {
	sports?: Array<string>;
	markets?: Array<string>;
	players?: boolean;
	limit?: number;
	locale?: string;
}

export interface GetSportRequest {
	key: string;
	entities?: GetSportEntitiesEnum;
	locale?: string;
}

export interface GetSportsRequest {
	locale?: string;
}

export interface GetTopCouponRequest {
	key: string;
	markets?: Array<string>;
	players?: boolean;
	limit?: number;
	locale?: string;
}

export interface GetTopCouponsListRequest {
	sports?: Array<string>;
	locale?: string;
}

/**
 *
 */
export class APIVersion60Api extends runtime.BaseAPI {
	/**
	 * Get live and upcoming events for given competition key. If market filtering is enabled, metadata is not displayed.
	 */
	async getCompetitionEventsRaw(
		requestParameters: GetCompetitionEventsRequest
	): Promise<runtime.ApiResponse<CompetitionResponse>> {
		if (
			requestParameters.key === null ||
			requestParameters.key === undefined
		) {
			throw new runtime.RequiredError(
				'key',
				'Required parameter requestParameters.key was null or undefined when calling getCompetitionEvents.'
			);
		}

		const queryParameters: runtime.HTTPQuery = {};

		if (requestParameters.from !== undefined) {
			queryParameters['from'] = requestParameters.from;
		}

		if (requestParameters.to !== undefined) {
			queryParameters['to'] = requestParameters.to;
		}

		if (requestParameters.markets) {
			queryParameters['markets'] = requestParameters.markets;
		}

		if (requestParameters.players !== undefined) {
			queryParameters['players'] = requestParameters.players;
		}

		if (requestParameters.limit !== undefined) {
			queryParameters['limit'] = requestParameters.limit;
		}

		if (requestParameters.live !== undefined) {
			queryParameters['live'] = requestParameters.live;
		}

		if (requestParameters.locale !== undefined) {
			queryParameters['locale'] = requestParameters.locale;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request({
			path: `/v6/sports/competitions/{key}/events`.replace(
				`{${'key'}}`,
				encodeURIComponent(String(requestParameters.key))
			),
			method: 'GET',
			headers: headerParameters,
			query: queryParameters
		});

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CompetitionResponseFromJSON(jsonValue)
		);
	}

	/**
	 * Get live and upcoming events for given competition key. If market filtering is enabled, metadata is not displayed.
	 */
	async getCompetitionEvents(
		key: string,
		from?: number,
		to?: number,
		markets?: Array<string>,
		players?: boolean,
		limit?: number,
		live?: boolean,
		locale?: string
	): Promise<CompetitionResponse> {
		const response = await this.getCompetitionEventsRaw({
			key: key,
			from: from,
			to: to,
			markets: markets,
			players: players,
			limit: limit,
			live: live,
			locale: locale
		});
		return await response.value();
	}

	/**
	 * Get competition key + category details for given competition ID.
	 */
	async getCompetitionKeyRaw(
		requestParameters: GetCompetitionKeyRequest
	): Promise<runtime.ApiResponse<CompetitionWithCategoryResponse>> {
		if (
			requestParameters.id === null ||
			requestParameters.id === undefined
		) {
			throw new runtime.RequiredError(
				'id',
				'Required parameter requestParameters.id was null or undefined when calling getCompetitionKey.'
			);
		}

		const queryParameters: runtime.HTTPQuery = {};

		if (requestParameters.locale !== undefined) {
			queryParameters['locale'] = requestParameters.locale;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request({
			path: `/v6/sports/competition-key/{id}`.replace(
				`{${'id'}}`,
				encodeURIComponent(String(requestParameters.id))
			),
			method: 'GET',
			headers: headerParameters,
			query: queryParameters
		});

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CompetitionWithCategoryResponseFromJSON(jsonValue)
		);
	}

	/**
	 * Get competition key + category details for given competition ID.
	 */
	async getCompetitionKey(
		id: number,
		locale?: string
	): Promise<CompetitionWithCategoryResponse> {
		const response = await this.getCompetitionKeyRaw({
			id: id,
			locale: locale
		});
		return await response.value();
	}

	/**
	 * Get outrights for given competition key. If market filtering is enabled, metadata is not displayed.
	 */
	async getCompetitionOutrightsRaw(
		requestParameters: GetCompetitionOutrightsRequest
	): Promise<runtime.ApiResponse<CompetitionResponse>> {
		if (
			requestParameters.key === null ||
			requestParameters.key === undefined
		) {
			throw new runtime.RequiredError(
				'key',
				'Required parameter requestParameters.key was null or undefined when calling getCompetitionOutrights.'
			);
		}

		const queryParameters: runtime.HTTPQuery = {};

		if (requestParameters.markets) {
			queryParameters['markets'] = requestParameters.markets;
		}

		if (requestParameters.limit !== undefined) {
			queryParameters['limit'] = requestParameters.limit;
		}

		if (requestParameters.locale !== undefined) {
			queryParameters['locale'] = requestParameters.locale;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request({
			path: `/v6/sports/competitions/{key}/outrights`.replace(
				`{${'key'}}`,
				encodeURIComponent(String(requestParameters.key))
			),
			method: 'GET',
			headers: headerParameters,
			query: queryParameters
		});

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CompetitionResponseFromJSON(jsonValue)
		);
	}

	/**
	 * Get outrights for given competition key. If market filtering is enabled, metadata is not displayed.
	 */
	async getCompetitionOutrights(
		key: string,
		markets?: Array<string>,
		limit?: number,
		locale?: string
	): Promise<CompetitionResponse> {
		const response = await this.getCompetitionOutrightsRaw({
			key: key,
			markets: markets,
			limit: limit,
			locale: locale
		});
		return await response.value();
	}

	/**
	 * Markets and players are not included by default.
	 * Get list of events(by default return both upcoming and live)
	 */
	async getEventsRaw(
		requestParameters: GetEventsRequest
	): Promise<runtime.ApiResponse<EventListResponse>> {
		const queryParameters: runtime.HTTPQuery = {};

		if (requestParameters.from !== undefined) {
			queryParameters['from'] = requestParameters.from;
		}

		if (requestParameters.to !== undefined) {
			queryParameters['to'] = requestParameters.to;
		}

		if (requestParameters.sports) {
			queryParameters['sports'] = requestParameters.sports;
		}

		if (requestParameters.markets) {
			queryParameters['markets'] = requestParameters.markets;
		}

		if (requestParameters.live !== undefined) {
			queryParameters['live'] = requestParameters.live;
		}

		if (requestParameters.players !== undefined) {
			queryParameters['players'] = requestParameters.players;
		}

		if (requestParameters.upcoming !== undefined) {
			queryParameters['upcoming'] = requestParameters.upcoming;
		}

		if (requestParameters.priority !== undefined) {
			queryParameters['priority'] = requestParameters.priority;
		}

		if (requestParameters.streaming !== undefined) {
			queryParameters['streaming'] = requestParameters.streaming;
		}

		if (requestParameters.virtual !== undefined) {
			queryParameters['virtual'] = requestParameters.virtual;
		}

		if (requestParameters.limit !== undefined) {
			queryParameters['limit'] = requestParameters.limit;
		}

		if (requestParameters.locale !== undefined) {
			queryParameters['locale'] = requestParameters.locale;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request({
			path: `/v6/sports/events`,
			method: 'GET',
			headers: headerParameters,
			query: queryParameters
		});

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			EventListResponseFromJSON(jsonValue)
		);
	}

	/**
	 * Markets and players are not included by default.
	 * Get list of events(by default return both upcoming and live)
	 */
	async getEvents(
		from?: number,
		to?: number,
		sports?: Array<string>,
		markets?: Array<string>,
		live?: boolean,
		players?: boolean,
		upcoming?: boolean,
		priority?: number,
		streaming?: boolean,
		virtual?: boolean,
		limit?: number,
		locale?: string
	): Promise<EventListResponse> {
		const response = await this.getEventsRaw({
			from: from,
			to: to,
			sports: sports,
			markets: markets,
			live: live,
			players: players,
			upcoming: upcoming,
			priority: priority,
			streaming: streaming,
			virtual: virtual,
			limit: limit,
			locale: locale
		});
		return await response.value();
	}

	/**
	 * This will show all available main markets by default
	 * Get event with markets for given event ID
	 */
	async getMainEventRaw(
		requestParameters: GetMainEventRequest
	): Promise<runtime.ApiResponse<EventResponse>> {
		if (
			requestParameters.id === null ||
			requestParameters.id === undefined
		) {
			throw new runtime.RequiredError(
				'id',
				'Required parameter requestParameters.id was null or undefined when calling getMainEvent.'
			);
		}

		const queryParameters: runtime.HTTPQuery = {};

		if (requestParameters.markets) {
			queryParameters['markets'] = requestParameters.markets;
		}

		if (requestParameters.locale !== undefined) {
			queryParameters['locale'] = requestParameters.locale;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request({
			path: `/v6/sports/events/{id}`.replace(
				`{${'id'}}`,
				encodeURIComponent(String(requestParameters.id))
			),
			method: 'GET',
			headers: headerParameters,
			query: queryParameters
		});

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			EventResponseFromJSON(jsonValue)
		);
	}

	/**
	 * This will show all available main markets by default
	 * Get event with markets for given event ID
	 */
	async getMainEvent(
		id: number,
		markets?: Array<string>,
		locale?: string
	): Promise<EventResponse> {
		const response = await this.getMainEventRaw({
			id: id,
			markets: markets,
			locale: locale
		});
		return await response.value();
	}

	/**
	 * This will show all available main markets by default Response is similar to event endpoint
	 * Get outright with markets for given outright ID
	 */
	async getMainOutrightRaw(
		requestParameters: GetMainOutrightRequest
	): Promise<runtime.ApiResponse<EventResponse>> {
		if (
			requestParameters.id === null ||
			requestParameters.id === undefined
		) {
			throw new runtime.RequiredError(
				'id',
				'Required parameter requestParameters.id was null or undefined when calling getMainOutright.'
			);
		}

		const queryParameters: runtime.HTTPQuery = {};

		if (requestParameters.markets) {
			queryParameters['markets'] = requestParameters.markets;
		}

		if (requestParameters.locale !== undefined) {
			queryParameters['locale'] = requestParameters.locale;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request({
			path: `/v6/sports/outrights/{id}`.replace(
				`{${'id'}}`,
				encodeURIComponent(String(requestParameters.id))
			),
			method: 'GET',
			headers: headerParameters,
			query: queryParameters
		});

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			EventResponseFromJSON(jsonValue)
		);
	}

	/**
	 * This will show all available main markets by default Response is similar to event endpoint
	 * Get outright with markets for given outright ID
	 */
	async getMainOutright(
		id: number,
		markets?: Array<string>,
		locale?: string
	): Promise<EventResponse> {
		const response = await this.getMainOutrightRaw({
			id: id,
			markets: markets,
			locale: locale
		});
		return await response.value();
	}

	/**
	 * Markets are not included by default.
	 * Get list of outrights
	 */
	async getOutrightsRaw(
		requestParameters: GetOutrightsRequest
	): Promise<runtime.ApiResponse<EventListResponse>> {
		const queryParameters: runtime.HTTPQuery = {};

		if (requestParameters.sports) {
			queryParameters['sports'] = requestParameters.sports;
		}

		if (requestParameters.markets) {
			queryParameters['markets'] = requestParameters.markets;
		}

		if (requestParameters.priority !== undefined) {
			queryParameters['priority'] = requestParameters.priority;
		}

		if (requestParameters.limit !== undefined) {
			queryParameters['limit'] = requestParameters.limit;
		}

		if (requestParameters.locale !== undefined) {
			queryParameters['locale'] = requestParameters.locale;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request({
			path: `/v6/sports/outrights`,
			method: 'GET',
			headers: headerParameters,
			query: queryParameters
		});

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			EventListResponseFromJSON(jsonValue)
		);
	}

	/**
	 * Markets are not included by default.
	 * Get list of outrights
	 */
	async getOutrights(
		sports?: Array<string>,
		markets?: Array<string>,
		priority?: number,
		limit?: number,
		locale?: string
	): Promise<EventListResponse> {
		const response = await this.getOutrightsRaw({
			sports: sports,
			markets: markets,
			priority: priority,
			limit: limit,
			locale: locale
		});
		return await response.value();
	}

	/**
	 * Markets and players are not included by default.
	 * Get popular competitions
	 */
	async getPopularCompetitionsRaw(
		requestParameters: GetPopularCompetitionsRequest
	): Promise<runtime.ApiResponse<EventListResponse>> {
		const queryParameters: runtime.HTTPQuery = {};

		if (requestParameters.markets) {
			queryParameters['markets'] = requestParameters.markets;
		}

		if (requestParameters.players !== undefined) {
			queryParameters['players'] = requestParameters.players;
		}

		if (requestParameters.numComp !== undefined) {
			queryParameters['num-comp'] = requestParameters.numComp;
		}

		if (requestParameters.locale !== undefined) {
			queryParameters['locale'] = requestParameters.locale;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request({
			path: `/v6/sports/popular/competitions`,
			method: 'GET',
			headers: headerParameters,
			query: queryParameters
		});

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			EventListResponseFromJSON(jsonValue)
		);
	}

	/**
	 * Markets and players are not included by default.
	 * Get popular competitions
	 */
	async getPopularCompetitions(
		markets?: Array<string>,
		players?: boolean,
		numComp?: number,
		locale?: string
	): Promise<EventListResponse> {
		const response = await this.getPopularCompetitionsRaw({
			markets: markets,
			players: players,
			numComp: numComp,
			locale: locale
		});
		return await response.value();
	}

	/**
	 * Markets and players are not included by default.
	 * Get popular events
	 */
	async getPopularEventsRaw(
		requestParameters: GetPopularEventsRequest
	): Promise<runtime.ApiResponse<EventListResponse>> {
		const queryParameters: runtime.HTTPQuery = {};

		if (requestParameters.sports) {
			queryParameters['sports'] = requestParameters.sports;
		}

		if (requestParameters.markets) {
			queryParameters['markets'] = requestParameters.markets;
		}

		if (requestParameters.players !== undefined) {
			queryParameters['players'] = requestParameters.players;
		}

		if (requestParameters.limit !== undefined) {
			queryParameters['limit'] = requestParameters.limit;
		}

		if (requestParameters.locale !== undefined) {
			queryParameters['locale'] = requestParameters.locale;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request({
			path: `/v6/sports/popular/events`,
			method: 'GET',
			headers: headerParameters,
			query: queryParameters
		});

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			EventListResponseFromJSON(jsonValue)
		);
	}

	/**
	 * Markets and players are not included by default.
	 * Get popular events
	 */
	async getPopularEvents(
		sports?: Array<string>,
		markets?: Array<string>,
		players?: boolean,
		limit?: number,
		locale?: string
	): Promise<EventListResponse> {
		const response = await this.getPopularEventsRaw({
			sports: sports,
			markets: markets,
			players: players,
			limit: limit,
			locale: locale
		});
		return await response.value();
	}

	/**
	 * Competitions are grouped by categories.
	 * Get competitions of a sport.
	 */
	async getSportRaw(
		requestParameters: GetSportRequest
	): Promise<runtime.ApiResponse<SportWithCategoryResponse>> {
		if (
			requestParameters.key === null ||
			requestParameters.key === undefined
		) {
			throw new runtime.RequiredError(
				'key',
				'Required parameter requestParameters.key was null or undefined when calling getSport.'
			);
		}

		const queryParameters: runtime.HTTPQuery = {};

		if (requestParameters.entities !== undefined) {
			queryParameters['entities'] = requestParameters.entities;
		}

		if (requestParameters.locale !== undefined) {
			queryParameters['locale'] = requestParameters.locale;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request({
			path: `/v6/sports/{key}`.replace(
				`{${'key'}}`,
				encodeURIComponent(String(requestParameters.key))
			),
			method: 'GET',
			headers: headerParameters,
			query: queryParameters
		});

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			SportWithCategoryResponseFromJSON(jsonValue)
		);
	}

	/**
	 * Competitions are grouped by categories.
	 * Get competitions of a sport.
	 */
	async getSport(
		key: string,
		entities?: GetSportEntitiesEnum,
		locale?: string
	): Promise<SportWithCategoryResponse> {
		const response = await this.getSportRaw({
			key: key,
			entities: entities,
			locale: locale
		});
		return await response.value();
	}

	/**
	 * Sports are ordered by alphabetical order.
	 * Get list of sports.
	 */
	async getSportsRaw(
		requestParameters: GetSportsRequest
	): Promise<runtime.ApiResponse<SportsResponse>> {
		const queryParameters: runtime.HTTPQuery = {};

		if (requestParameters.locale !== undefined) {
			queryParameters['locale'] = requestParameters.locale;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request({
			path: `/v6/sports`,
			method: 'GET',
			headers: headerParameters,
			query: queryParameters
		});

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			SportsResponseFromJSON(jsonValue)
		);
	}

	/**
	 * Sports are ordered by alphabetical order.
	 * Get list of sports.
	 */
	async getSports(locale?: string): Promise<SportsResponse> {
		const response = await this.getSportsRaw({ locale: locale });
		return await response.value();
	}

	/**
	 * By default there is no limit to the number of events returned
	 * Get list of events for a single top coupon
	 */
	async getTopCouponRaw(
		requestParameters: GetTopCouponRequest
	): Promise<runtime.ApiResponse<TopCouponsResponse>> {
		if (
			requestParameters.key === null ||
			requestParameters.key === undefined
		) {
			throw new runtime.RequiredError(
				'key',
				'Required parameter requestParameters.key was null or undefined when calling getTopCoupon.'
			);
		}

		const queryParameters: runtime.HTTPQuery = {};

		if (requestParameters.markets) {
			queryParameters['markets'] = requestParameters.markets;
		}

		if (requestParameters.players !== undefined) {
			queryParameters['players'] = requestParameters.players;
		}

		if (requestParameters.limit !== undefined) {
			queryParameters['limit'] = requestParameters.limit;
		}

		if (requestParameters.locale !== undefined) {
			queryParameters['locale'] = requestParameters.locale;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request({
			path: `/v6/sports/topcoupons/{key}`.replace(
				`{${'key'}}`,
				encodeURIComponent(String(requestParameters.key))
			),
			method: 'GET',
			headers: headerParameters,
			query: queryParameters
		});

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			TopCouponsResponseFromJSON(jsonValue)
		);
	}

	/**
	 * By default there is no limit to the number of events returned
	 * Get list of events for a single top coupon
	 */
	async getTopCoupon(
		key: string,
		markets?: Array<string>,
		players?: boolean,
		limit?: number,
		locale?: string
	): Promise<TopCouponsResponse> {
		const response = await this.getTopCouponRaw({
			key: key,
			markets: markets,
			players: players,
			limit: limit,
			locale: locale
		});
		return await response.value();
	}

	/**
	 * Markets and players are not included by default.
	 * Get list of top coupons
	 */
	async getTopCouponsListRaw(
		requestParameters: GetTopCouponsListRequest
	): Promise<runtime.ApiResponse<TopCouponsListResponse>> {
		const queryParameters: runtime.HTTPQuery = {};

		if (requestParameters.sports) {
			queryParameters['sports'] = requestParameters.sports;
		}

		if (requestParameters.locale !== undefined) {
			queryParameters['locale'] = requestParameters.locale;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request({
			path: `/v6/sports/topcoupons`,
			method: 'GET',
			headers: headerParameters,
			query: queryParameters
		});

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			TopCouponsListResponseFromJSON(jsonValue)
		);
	}

	/**
	 * Markets and players are not included by default.
	 * Get list of top coupons
	 */
	async getTopCouponsList(
		sports?: Array<string>,
		locale?: string
	): Promise<TopCouponsListResponse> {
		const response = await this.getTopCouponsListRaw({
			sports: sports,
			locale: locale
		});
		return await response.value();
	}
}

/**
 * @export
 * @enum {string}
 */
export enum GetSportEntitiesEnum {
	All = 'all',
	Event = 'event',
	Outright = 'outright'
}
