import { useTranslation } from '@gaming-shell/i18n';
import { IconButton } from '@material-ui/core';
import { IconButtonProps } from '@material-ui/core/IconButton';
import * as React from 'react';
import { DeleteIcon } from 'sports/components/icons/betslip';

import { betslipActions } from '../betslipReducer';
import { useBetslipDispatch } from '../hooks/useBetslipDispatch';

const style = { height: '32px' };
export interface UiBigBetslipRemoveSelectionProps extends IconButtonProps {
	onRemove: () => void;
}
export type BigBetslipRemoveSelectionProps = Omit<
	UiBigBetslipRemoveSelectionProps,
	'onRemove'
> & { selectionIndex: number };
let UiBigBetslipRemoveSelection: React.FunctionComponent<UiBigBetslipRemoveSelectionProps> = (
	props
) => {
	const { onRemove, ...other } = props;
	const translate = useTranslation();
	const title = translate('actions.remove');
	return (
		<IconButton
			onClick={onRemove}
			size="small"
			title={title}
			style={style}
			{...other}
		>
			<DeleteIcon />
		</IconButton>
	);
};
UiBigBetslipRemoveSelection = React.memo(UiBigBetslipRemoveSelection);
let BigBetslipRemoveSelection: React.FunctionComponent<BigBetslipRemoveSelectionProps> = (
	props
) => {
	const { selectionIndex, ...other } = props;
	const dispatch = useBetslipDispatch();
	const removeSelection = () => {
		dispatch(betslipActions.removeSelection(selectionIndex));
	};
	return (
		<UiBigBetslipRemoveSelection onRemove={removeSelection} {...other} />
	);
};
BigBetslipRemoveSelection = React.memo(BigBetslipRemoveSelection);
export { UiBigBetslipRemoveSelection, BigBetslipRemoveSelection };
