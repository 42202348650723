import { usePlayerChannel } from '@materialbet-core/player';
import * as React from 'react';

import { betslipActions, betslipStateVersion } from '../betslipReducer';
import { useBetslipDispatch } from '../hooks';
import { PlaceBetCallback } from '../placeBet/useBetslipPlaceBet';
import { refetchBetslipData } from '../refetchBetslipData/refetchBetslipData';
import { PersistedBetslip } from '../types';
import { clearPersistedBetslip } from './clearPersistedBetslip';
import { getPersistedBetslip } from './getPersistedBetslip';
import { useUpdateInflightBetslip } from './useUpdateInflightBetslip';

const hasCorrectStructure = (persistedBetslip: PersistedBetslip) => {
	// just checking if certain elements exist on the object, and pseudo type check by
	// looking for existence of type-typical functions
	// If we make changes to the structure of the BetslipState we should check here specificly for that
	const { version, betslip: state } = persistedBetslip;
	return (
		version === betslipStateVersion &&
		state.betslip?.selections?.push &&
		state.betslip.multiples[0] &&
		state.acceptBetterOdds.valueOf &&
		state.isSubmitting.valueOf
	);
};
export const useLoadBetslipOnMount = (onBetPlaced: PlaceBetCallback) => {
	const dispatchBetslip = useBetslipDispatch();
	const playerChannel = usePlayerChannel();
	const updateInflightBetslip = useUpdateInflightBetslip(
		onBetPlaced,
		playerChannel
	);
	React.useEffect(() => {
		const persistedBetslip = getPersistedBetslip();
		if (!persistedBetslip) {
			return;
		}
		if (!hasCorrectStructure(persistedBetslip)) {
			clearPersistedBetslip();
			return;
		}
		const { betslip } = persistedBetslip;
		if (betslip.betslipId && !playerChannel) {
			// without player channel we might still potentially end up in a deadlock of the betslip
			return;
		}

		dispatchBetslip(betslipActions.setState(betslip));
		clearPersistedBetslip();
		if (betslip.betslipId) {
			updateInflightBetslip(
				betslip.betslipId,
				betslip.betslip,
				betslip.mode
			);
		}
		refetchBetslipData(betslip.betslip.selections, dispatchBetslip);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [playerChannel]);
};
