import { Translate } from '@gaming-shell/i18n';
import { IconButton, IconButtonProps, Typography } from '@material-ui/core';
import clsx from 'clsx';
import {
	FacebookIcon,
	GoogleIcon,
	TelegramIcon,
	TwitterIcon,
	WhatsAppIcon
} from 'materialbet-icons';
import * as React from 'react';

import { withHook } from '../../hocs/withHook';
import {
	useFacebookStyles,
	useGoogleStyles,
	useStyles,
	useTelegramStyles,
	useTwitterStyles,
	useWhatsAppStyles
} from './SocialMediaButton.styles';

type SocialMedia = 'facebook' | 'google' | 'twitter' | 'telegram' | 'whatsapp';

const icons: Record<SocialMedia, React.ReactNode> = {
	facebook: <FacebookIcon fillColor="#fff" />, // Always white here, as it will be on a colored background
	google: <GoogleIcon />,
	twitter: <TwitterIcon fillColor="#fff" />, // Always white here, as it will be on a colored background
	telegram: <TelegramIcon viewBox="0 0 24 22" />, // Make a offset to make it look center
	whatsapp: <WhatsAppIcon fillColor="#fff" /> // Always white here, as it will be on a colored background
};

export interface SocialMediaButtonProps extends IconButtonProps {
	socialMedia: SocialMedia;
	icon: React.ReactNode;
	enableLabel?: boolean;
	href?: string;
	target?: string;
}

/** @description social media icon button show with the background */
let SocialMediaButtonUi: React.FunctionComponent<SocialMediaButtonProps> = ({
	socialMedia,
	icon,
	classes,
	enableLabel,
	href,
	className,
	target
}) => {
	const buttonClasses = useStyles();
	const handleClick = (event: React.MouseEvent) => {
		event.stopPropagation();
	};
	return (
		<div
			className={clsx(buttonClasses.socialMediaButtonWrapper, className)}
		>
			<IconButton
				classes={classes}
				aria-label={socialMedia}
				component="a"
				href={href}
				target={target}
				onClick={handleClick}
			>
				{icon}
			</IconButton>
			{enableLabel && (
				<Typography
					variant="caption"
					color="textSecondary"
					className={buttonClasses.socialMediaLabel}
				>
					<Translate
						label={`subjects.providers.${socialMedia}`}
						ns="common"
					/>
				</Typography>
			)}
		</div>
	);
};

SocialMediaButtonUi = React.memo(SocialMediaButtonUi);

const useSocialMediaButtonProps = (
	props: Omit<SocialMediaButtonProps, 'icon' | 'classes'>
): SocialMediaButtonProps => {
	const { socialMedia } = props;
	const facebookClasses = useFacebookStyles();
	const googleClasses = useGoogleStyles();
	const twitterClasses = useTwitterStyles();
	const telegramClasses = useTelegramStyles();
	const whatsAppClasses = useWhatsAppStyles();
	const classes: Record<SocialMedia, IconButtonProps['classes']> = {
		facebook: facebookClasses,
		google: googleClasses,
		twitter: twitterClasses,
		telegram: telegramClasses,
		whatsapp: whatsAppClasses
	};
	return {
		...props,
		icon: icons[socialMedia],
		classes: classes[socialMedia]
	};
};

export const SocialMediaButton = withHook(useSocialMediaButtonProps)(
	SocialMediaButtonUi
);
