import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import api from 'sports/api';

import { createClearStoreOnCountryChange } from './api/middleware/clearStoreOnCountryChange';
import { rootEpic, rootReducer } from './modules/root';

export type API = typeof api;
export interface EpicDependencies {
	api: typeof api;
}

const epicDependencies: EpicDependencies = {
	api: { ...api }
};
export const epicMiddleware = createEpicMiddleware({
	dependencies: epicDependencies
});
const composeEnhancers = composeWithDevTools({ name: 'sports' });
const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(epicMiddleware))
);
epicDependencies.api.sports = api.sports.withPostMiddleware(
	createClearStoreOnCountryChange(store.dispatch)
);

epicMiddleware.run(rootEpic);

export default store;
export type Store = typeof store;
