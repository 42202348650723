export interface CloudinaryImageOptions {
	width?: number;
	height?: number;
	fill?: boolean;
	dpr?: boolean;
	quality?: 'auto';
	format?: 'auto';
}

const defaultOptions: CloudinaryImageOptions = {
	width: 320,
	height: 320,
	fill: true,
	dpr: true,
	quality: 'auto',
	format: 'auto'
};

const optionsToCloudinaryOptions: { [key: string]: string } = {
	width: 'w_{{value}}',
	height: 'h_{{value}}',
	fill: 'c_fill',
	dpr: 'dpr_1',
	quality: 'q_{{value}}',
	format: 'f_{{value}}'
};

export function mapOptionsToCloudinaryParams(options: CloudinaryImageOptions) {
	return Object.entries(options).map(([key, value]) => {
		const cloudinaryParam = optionsToCloudinaryOptions[key];
		// if placeholder exist replace it with passed value
		if (cloudinaryParam.includes('{{value}}')) {
			return cloudinaryParam.replace('{{value}}', value);
		}

		// else return cloudinary param or exact value of passed property
		return cloudinaryParam || value;
	});
}

/* gets image url from CDN through cloudinary */
export function getImageUrl(
	cdnImagePath: string,
	options: CloudinaryImageOptions = defaultOptions
) {
	const mappedOptions = mapOptionsToCloudinaryParams({
		...defaultOptions,
		...options
	});
	return (
		cdnImagePath &&
		`${CLOUDINARY_URL}/image/fetch/${mappedOptions.join(
			','
		)}/${CDN_URL}/${cdnImagePath.replace(/^\//, '')}`
	);
}
