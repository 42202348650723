/* eslint-disable @typescript-eslint/camelcase */
import { SvgIconProps } from '@material-ui/core';
import AllIcon from 'casino/components/Icons/Categories/All';
import Baccarat from 'casino/components/Icons/Categories/Baccarat';
import BlackJack from 'casino/components/Icons/Categories/BlackJack';
import Cristmas from 'casino/components/Icons/Categories/Cristmas';
import Featured from 'casino/components/Icons/Categories/Featured';
import InstantWin from 'casino/components/Icons/Categories/InstantWin';
import JackpotSlots from 'casino/components/Icons/Categories/JackpotSlots';
import LiveCasinoRoulette from 'casino/components/Icons/Categories/LiveCasinoRoulette';
import LiveCasinoTableGames from 'casino/components/Icons/Categories/LiveCasinoTableGames';
import MyCasino from 'casino/components/Icons/Categories/MyCasino';
import NearYou from 'casino/components/Icons/Categories/NearYou';
import Other from 'casino/components/Icons/Categories/Other';
import Popular from 'casino/components/Icons/Categories/Popular';
import Promotions from 'casino/components/Icons/Categories/Promotions';
import Slots from 'casino/components/Icons/Categories/Slots';
import Studios from 'casino/components/Icons/Categories/Studios';
import VideoPoker from 'casino/components/Icons/Categories/VideoPoker';
import Virtuals from 'casino/components/Icons/Categories/Virtuals';
import * as React from 'react';

export const categoryIcons = {
	all: AllIcon,
	baccarat: Baccarat,
	live_baccarat: Baccarat,
	blackjack: BlackJack,
	live_blackjack: BlackJack,
	featured: Featured,
	live_featured: Featured,
	popular_near_you: NearYou,
	jackpot_slots: JackpotSlots,
	my_casino: MyCasino,
	other: Other,
	roulette: LiveCasinoRoulette,
	live_roulette: LiveCasinoRoulette,
	slots: Slots,
	studios: Studios, // TODO replace with proper key when received
	tableGame: LiveCasinoTableGames,
	live_table_games: LiveCasinoTableGames,
	table_games: LiveCasinoTableGames,
	virtuals: Virtuals,
	popular: Popular,
	video_poker: VideoPoker,
	instant_win: InstantWin,
	wild_christmas: Cristmas,
	promotions: Promotions,
	recommendations: Popular,
	games_trending_right_now: Popular
};

export interface CategoryIconProps extends SvgIconProps {
	category: string;
}

export const CategoryIcon: React.FunctionComponent<CategoryIconProps> = ({
	category,
	...rest
}) => {
	const Icon = categoryIcons[category] || '';
	if (!Icon) return null;

	return <Icon {...rest} />;
};

export default CategoryIcon;
