import { useLanguage, useTranslation } from '@gaming-shell/i18n';
import { usePlayerCurrencyVariant } from '@materialbet-core/player';
import clsx from 'clsx';
import { TopButton } from 'materialbet-sports/src/components/buttons/TopButton';
import * as React from 'react';
import { isBoundToPitchers, isPrimaryMarket } from 'sports-sdk';
import { getMarketName, getOutcome, MarketType } from 'sports-sdk/sports-core';
import { forEventGetEventTeamNames } from 'sports/selectors/eventSelectors';
import { makeGetSelection } from 'sports/selectors/selectionSelectors';
import { getEvent } from 'sports/selectors/sports';
import { getStakeFactor } from 'sports/selectors/sportsplayer';
import store from 'sports/store';
import {
	useCurrency,
	usePlayerCurrencyVariantConfig
} from 'sports/utils/currency';
import { getSdkLocale } from 'sports/utils/locale';
import { getPlayersMustStartStrings } from 'sports/utils/playerUtils';
import { usePromotionStyles } from 'sports/utils/promotionStyles';
import { calculatedMinStake, calculateStake } from 'sports/utils/stake';
import {
	getZeroMarginMaxStakeInEuro,
	useIsZeroMarginPromotionActive
} from 'sports/utils/zeroMarginUtils';

import { BetslipReducerSelection } from '../betterBetslip';
import { BetslipStatusFlags } from '../betterBetslip/betslipReducer/BetslipStatusFlags';
import { useAddToBetslip } from '../betterBetslip/hooks/useAddToBetslip';
import { useShowNoExchangeRatesSnackbar } from '../util/NoExchangeRatesSnackbar';
import {
	useExchangeRatesStore,
	getCurrencyExchangeRate
} from 'materialbet-exchange-rates';
const maxDecimalPlaces = BETSLIP_MAX_INPUT_DP;
export interface OutcomeButtonProps {
	disabled?: boolean;
	title?: string;
	className?: string;
	active?: boolean;
	outcome: string;
	eventId: number;
	params: string;
	marketKey: MarketType;
	submarketKey: string;
	style?: React.CSSProperties;
}
export const useOpenClose = (
	outcome: string,
	eventId: number,
	marketType: MarketType,
	submarketKey: string,
	params: string
) => {
	const addToBetslip = useAddToBetslip();
	const toggleError = useShowNoExchangeRatesSnackbar();
	const currency = useCurrency();
	const language = useLanguage();
	const t = useTranslation();
	const sdkLocale = getSdkLocale(language);
	const variant = usePlayerCurrencyVariant();
	const { factor = 1 } = usePlayerCurrencyVariantConfig();
	const exchangeRatesStore = useExchangeRatesStore();
	const onClick = React.useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			e.stopPropagation();
			const state = store.getState();
			const exchangeRatesState = exchangeRatesStore.getState();
			const event = getEvent(state, { id: eventId });
			const stakeFactor = getStakeFactor(state);
			const selection = makeGetSelection()(state, {
				eventId,
				params,
				outcome,
				marketType,
				submarketKey
			});
			const availableRates = getCurrencyExchangeRate(currency)(
				exchangeRatesState
			);
			if (!selection || !event) {
				toggleError(true);
				return;
			}
			const zeroMarginMaxStakeInEuro =
				selection.price &&
				getZeroMarginMaxStakeInEuro({
					eventId,
					price: selection.price,
					state,
					exchangeRatesState,
					marketKey: marketType
				});
			const minStakeInCurrency = calculatedMinStake({
				currency,
				currencyVariant: variant,
				factor,
				availableRates,
				stake: selection.minStake || 0,
				maxDecimalPlaces
			});
			const maxStakeInCurrency = calculateStake(
				zeroMarginMaxStakeInEuro || selection.maxStake || 0,
				availableRates || '0',
				stakeFactor,
				currency,
				{
					maxDecimalPlaces
				}
			);

			const [parsedOutcome, err] = getOutcome(
				sdkLocale,
				(event as unknown) as Parameters<typeof getOutcome>[1],
				marketType,
				selection as Parameters<typeof getOutcome>[3]
			);
			const [marketName] = getMarketName(
				sdkLocale,
				selection as Parameters<typeof getMarketName>[1],
				marketType,
				(event as unknown) as Parameters<typeof getMarketName>[3]
			);
			if (err || !parsedOutcome || !marketName || !availableRates) {
				toggleError(true);
				return;
			}
			const outcomeName = parsedOutcome.name;
			const isPitcherBound = isBoundToPitchers({
				params
			});
			const additionalInfo = isPitcherBound
				? getPlayersMustStartStrings(
						t,
						parsedOutcome.variables,
						forEventGetEventTeamNames(event)
				  )
				: undefined;
			// Keep the code here for further usage
			const newBetslipSelection: BetslipReducerSelection = {
				eventId,
				submarketKey,
				marketKey: marketType,
				params,
				outcome,
				outcomeName,
				marketName,
				price: selection.price || 0,
				maxStake: maxStakeInCurrency || 0,
				minStake: minStakeInCurrency || 0,
				eventName: event?.name || '',
				stake: 0,
				status: BetslipStatusFlags.Default,
				additionalInfo
			};

			addToBetslip(newBetslipSelection, e.currentTarget);
			e.preventDefault();
			e.stopPropagation();
		},
		[
			addToBetslip,
			currency,
			eventId,
			factor,
			marketType,
			outcome,
			params,
			sdkLocale,
			submarketKey,
			toggleError,
			variant,
			t,
			exchangeRatesStore
		]
	);
	return [onClick] as [
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	];
};

let OutcomeButton: React.FunctionComponent<OutcomeButtonProps> = (props) => {
	const {
		outcome,
		eventId,
		marketKey,
		submarketKey,
		title,
		className,
		active,
		style,
		params,
		disabled
	} = props;
	const promotionClasses = usePromotionStyles();
	const isPrimary = isPrimaryMarket(marketKey);
	const isZeroMarginPromotion = useIsZeroMarginPromotionActive(eventId);
	const enablePromotionStyles = isPrimary && isZeroMarginPromotion;
	const [onClick] = useOpenClose(
		outcome,
		eventId,
		marketKey,
		submarketKey,
		params
	);

	return (
		<TopButton
			onClick={onClick}
			disabled={!!disabled}
			title={title}
			className={clsx(
				className,
				enablePromotionStyles && promotionClasses.outcomeBorder
			)}
			active={active}
			style={style}
		>
			{props.children}
		</TopButton>
	);
};
OutcomeButton = React.memo(OutcomeButton);
export { OutcomeButton };
