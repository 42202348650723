import { useLanguage } from '@gaming-shell/i18n';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import zhTW from 'date-fns/locale/zh-TW';
import { Locale } from 'sports-sdk/sports-core';
import { SportRadarLanguage } from 'sports/types/sportradar';

// eslint-disable-next-line @typescript-eslint/camelcase
const locales = { en, de, zh_tw: zhTW };
// languages from sport radar
const sportRadarLanguages: SportRadarLanguage[] = [
	'en',
	'es',
	'de',
	'it',
	'el',
	'hu',
	'id',
	'lv',
	'pl',
	'pt',
	'ru',
	'zht',
	'zh',
	'nl',
	'ja',
	'ko',
	'fr',
	'tr',
	'hr',
	'sk',
	'sl',
	'no',
	'se',
	'da',
	'cs',
	'fi',
	'bs',
	'bg',
	'sr',
	'ro',
	'et',
	'mk',
	'lt',
	'vi',
	'aa',
	'aze',
	'ka',
	'heb',
	'sqi',
	'ukr',
	'srl',
	'me',
	'br',
	'hye',
	'sw',
	'km'
];

const sportRadarLanguagesMap: Record<string, SportRadarLanguage> = {
	// eslint-disable-next-line @typescript-eslint/camelcase
	zh_tw: 'zht',
	// eslint-disable-next-line @typescript-eslint/camelcase
	zh_cn: 'zh',
	// eslint-disable-next-line @typescript-eslint/camelcase
	pt_br: 'pt'
};

// That is kinda hardcoded right now, we might design this properly in the future then this should be replaced with something from common
const regionSeparator = '_';
const sdkRegionSeparator = '_';
const sportsApiRegionSeparator = '-';
export const getSdkLocale = (language: string): Locale => {
	if (language.length === 2) {
		return Locale[language] || Locale.en;
	}
	const [lang, region] = language.split(regionSeparator);
	const sdkLocale = lang + sdkRegionSeparator + region.toUpperCase();
	return Locale[sdkLocale] || Locale.en; // hardcode english as fallback language, as its the source for all other language (thats not config thing)
};
export const useSdkLocale = () => {
	const language = useLanguage();
	return getSdkLocale(language);
};
export const getSportsApiLocale = (language: string): string => {
	if (language.length === 2) {
		return Locale[language] || Locale.en;
	}
	const [lang, region] = language.split(regionSeparator);
	const sportsApiLocale =
		lang + sportsApiRegionSeparator + region.toUpperCase();
	return sportsApiLocale;
};

export const getDateFnsLocale = (language: string) => {
	// Always return english if its not enabled
	if (!ENABLE_LOCALIZED_DATE) {
		return locales['en'];
	}
	return locales[language] || locales['en'];
};

export const getSportRadarLocale = (language: string): SportRadarLanguage => {
	if (sportRadarLanguagesMap[language]) {
		return sportRadarLanguagesMap[language];
	}
	if (sportRadarLanguages.includes(language as SportRadarLanguage)) {
		return language as SportRadarLanguage;
	}
	return 'en';
};
