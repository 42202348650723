"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SnackbarProvider = void 0;
var device_1 = require("@gaming-shell/device");
var i18n_1 = require("@gaming-shell/i18n");
var core_1 = require("@material-ui/core");
var notistack_1 = require("notistack");
var React = __importStar(require("react"));
var useStyles = core_1.makeStyles(function (theme) { return ({
    close: {
        padding: theme.spacing(0.5)
    }
}); });
var CloseButton = function (_a) {
    var id = _a.id;
    var styles = useStyles();
    var closeSnackbar = notistack_1.useSnackbar().closeSnackbar;
    return (React.createElement(core_1.Button, { "aria-label": "Close", color: "primary", className: styles.close, onClick: function () { return closeSnackbar(id); } },
        React.createElement(i18n_1.Translate, { label: "actions.close", ns: "common" })));
};
var maxNumberOfSnackbars = device_1.isMobile()
    ? MAX_NUMBER_OF_SNACKBARS_MOBILE
    : MAX_NUMBER_OF_SNACKBARS_DESKTOP;
var ANCHOR_ORIGIN = {
    vertical: 'bottom',
    horizontal: 'left'
};
exports.SnackbarProvider = React.memo(function (_a) {
    var children = _a.children;
    return (React.createElement(notistack_1.SnackbarProvider, { maxSnack: maxNumberOfSnackbars, anchorOrigin: ANCHOR_ORIGIN, autoHideDuration: 6000, dense: device_1.isMobile(), preventDuplicate: true, action: function (key) { return React.createElement(CloseButton, { id: key }); } }, children));
});
