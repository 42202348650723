import { Skeleton } from '@material-ui/lab';
import { usePlayerNickname } from '@materialbet-core/player';
import * as React from 'react';

let PlayerNickname: React.FC = () => {
	const nickname = usePlayerNickname();
	if (!nickname) {
		return <Skeleton />;
	}
	return <>{nickname}</>;
};
PlayerNickname = React.memo(PlayerNickname);
export { PlayerNickname };
