"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileSearchResults = void 0;
var device_1 = require("@gaming-shell/device");
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
var topOffset = APP_BAR_HEIGHT[device_1.getPlatform()];
var useStyles = core_1.makeStyles(function (theme) { return ({
    root: {
        position: 'fixed',
        overflow: 'auto',
        width: '100%',
        height: "calc(100vh - " + topOffset + "px)",
        backgroundColor: theme.palette.background.paper,
        marginTop: topOffset
    }
}); });
exports.MobileSearchResults = function (_a) {
    var open = _a.open, children = _a.children;
    var classes = useStyles();
    return (React.createElement(core_1.Popper, { open: true, keepMounted: true },
        React.createElement(core_1.Fade, { in: open },
            React.createElement("div", { className: classes.root }, children))));
};
