import { isMobile } from '@gaming-shell/device';
import { Theme } from '@gaming-shell/theming';
import { ButtonBase, IconButton, makeStyles } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import * as React from 'react';

//eslint-disable-next-line
const noop = () => {};

export interface PaperTitleProps {
	label: React.ReactElement;
	additional?: React.ReactElement;
	/** @description position of the additional, put it the last if pass right */
	additionalPosition?: 'left' | 'right';
	open?: boolean;
	onOpenChange: (open: boolean) => void;
	disableCollapsible?: boolean;
	variant?: PaperTitleVariant;
}

export type PaperTitleVariant =
	// add top and bottom padding of 2 to the title
	| 'text'
	// variant with small will provide a smaller padding
	// add top padding,bottom padding,background, bottom border to to the title
	| 'contained-small'
	// add top and bottom padding of1 to the title
	| 'text-small'
	// add top padding and bottom padding and bottom border to the title
	| 'outline-small';

const usePaperTitleStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center'
	},
	header: {
		justifyContent: 'flex-start',
		maxWidth: 'max-content', // Avoid it to take the whole row
		flex: 1,
		overflow: 'hidden',
		// Dont wrap the text and keep them on single line
		// Assume no line break for text and always use single line
		'& *': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		}
	},
	text: {
		padding: theme.spacing(2, 0)
	},
	floatRight: {
		marginLeft: 'auto'
	},
	/** @description Provide a title with lower height */
	'text-small': {
		padding: theme.spacing(1, 0)
	},
	'contained-small': ({
		disableCollapsible
	}: {
		disableCollapsible?: boolean;
	}) => ({
		/** @description The icon button has padding itself, reduce padding when there is icon for collapse */
		padding: disableCollapsible
			? theme.spacing(1, 0)
			: theme.spacing(0.5, 0),
		borderBottom: `1px solid ${theme.palette.divider}`,
		backgroundColor: theme.palette.background.top.main
	}),
	'outline-small': ({
		disableCollapsible
	}: {
		disableCollapsible?: boolean;
	}) => ({
		/** @description The icon button has padding itself, reduce padding when there is icon for collapse */
		padding: disableCollapsible
			? theme.spacing(1, 0)
			: theme.spacing(0.5, 0),
		borderBottom: `1px solid ${theme.palette.divider}`
	})
}));
const useExpandIconStyle = makeStyles((theme: Theme) => ({
	root: {
		opacity: 0.6,
		padding: theme.spacing(0.5)
	}
}));
export const PaperTitle: React.FunctionComponent<PaperTitleProps> = (props) => {
	const {
		open,
		label,
		onOpenChange,
		additional,
		additionalPosition = 'left',
		disableCollapsible,
		variant = 'text'
	} = props;
	const toggle = (event: React.MouseEvent<HTMLButtonElement>) => {
		event && event.stopPropagation();
		onOpenChange(!open);
	};

	const rowToggle =
		isMobile() && !disableCollapsible ? () => onOpenChange(!open) : noop;
	const paperTitleClasses = usePaperTitleStyles({ disableCollapsible });
	const expandIconClasses = useExpandIconStyle();
	return (
		<div
			className={clsx(paperTitleClasses.root, paperTitleClasses[variant])}
			onClick={rowToggle}
		>
			<ButtonBase onClick={toggle} className={paperTitleClasses.header}>
				{label}
			</ButtonBase>
			{additional && (
				<div
					className={clsx({
						[paperTitleClasses.floatRight]:
							additionalPosition === 'right'
					})}
				>
					{additional}
				</div>
			)}
			{!disableCollapsible && (
				<IconButton
					className={clsx(expandIconClasses.root, {
						[paperTitleClasses.floatRight]:
							!additional || additionalPosition === 'left'
					})}
					onClick={toggle}
					aria-label="toggleExpand"
				>
					{open ? <ExpandLess /> : <ExpandMore />}
				</IconButton>
			)}
		</div>
	);
};
