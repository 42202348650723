import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import ChevronRight from '@material-ui/icons/ChevronRight';
import clx from 'classnames';
import * as React from 'react';
import { useFormatOdds } from 'sports/utils/odds';

import { useBetslipIsSubmitting } from './hooks';

export interface BetslipSelectionPriceProps {
	price: number;
	changedPrice?: number;
	variant?: TypographyProps['variant'];
	color?: TypographyProps['color'];
	className?: string;
}
const useStyles = makeStyles((theme: Theme) => ({
	old: {
		textDecoration: 'line-through',
		color: theme.palette.text.disabled
	}
}));
export const BetslipSelectionPrice: React.FunctionComponent<BetslipSelectionPriceProps> = (
	props
) => {
	const { price, changedPrice, variant, className, color } = props;
	const classes = useStyles();
	const formatOdds = useFormatOdds();
	const formattedPrice = formatOdds(price);
	const formattedChangedPrice = changedPrice && formatOdds(changedPrice);
	const isUpdated = !!(changedPrice && price !== changedPrice);
	const isSubmitting = useBetslipIsSubmitting();
	return (
		<Box
			className={className}
			display="flex"
			flexDirection="row"
			alignItems="center"
		>
			<Typography
				variant={variant}
				color={color}
				className={clx({ [classes.old]: isUpdated })}
			>
				{formattedPrice}
			</Typography>
			{isUpdated && !isSubmitting && (
				<>
					<ChevronRight />
					<Typography variant={variant} color={color}>
						{formattedChangedPrice}
					</Typography>
				</>
			)}
		</Box>
	);
};
