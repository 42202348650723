import { makeStyles, Theme } from '@material-ui/core/';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { useOpenCloseMenu } from 'materialbet-common';
import * as React from 'react';

import { BalanceMenu } from './BalanceMenu';
import { AccountBalancePureButton } from './AccountBalancePureButton';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(0.75)
	},
	endIcon: {
		marginLeft: 0
	}
}));

export const AccountBalanceButtonCurrencySwitch: React.FC = () => {
	const classes = useStyles();

	const [handleOpenMenu, handleCloseMenu, anchorEl] = useOpenCloseMenu();
	const SelectIcon = !!anchorEl ? ArrowDropUpIcon : ArrowDropDownIcon;

	return (
		<>
			<AccountBalancePureButton
				onClick={handleOpenMenu}
				className={classes.root}
				endIcon={<SelectIcon data-testid="dropdown-menu-icon" />}
				classes={{
					endIcon: classes.endIcon
				}}
			/>
			<BalanceMenu
				anchorEl={anchorEl}
				onClose={handleCloseMenu}
				aria-label="Account balance menu"
			/>
		</>
	);
};
