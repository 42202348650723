import { SideMenuFooter } from '@gaming-shell/layout';
import { Divider } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { usePlayerLoggedIn } from '@materialbet-core/player';
import clsx from 'clsx';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	divider: {
		marginBottom: theme.spacing(2)
	}
}));
/** @description a component that contain side menu footer for casino */

let CasinoSideMenuFooter: React.FC = () => {
	const isLoggedIn = usePlayerLoggedIn();
	const classes = useStyles();
	return (
		<React.Fragment>
			<Divider className={clsx({ [classes.divider]: !isLoggedIn })} />
			{isLoggedIn && <SideMenuFooter />}
		</React.Fragment>
	);
};

CasinoSideMenuFooter = React.memo(CasinoSideMenuFooter);
export { CasinoSideMenuFooter };
