/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
	EventMedia,
	EventMediaFromJSON,
	EventMediaFromJSONTyped,
	EventMediaToJSON,
	EventMetadata,
	EventMetadataFromJSON,
	EventMetadataFromJSONTyped,
	EventMetadataToJSON,
	Identifier,
	IdentifierFromJSON,
	IdentifierFromJSONTyped,
	IdentifierToJSON,
	Market,
	MarketFromJSON,
	MarketFromJSONTyped,
	MarketToJSON,
	Player,
	PlayerFromJSON,
	PlayerFromJSONTyped,
	PlayerToJSON
} from './';

/**
 * EventForEventList is used for the /events endpoint
 * Please keep index compatibility with private.EventForEventList
 * @export
 * @interface EventForEventList
 */
export interface EventForEventList {
	/**
	 *
	 * @type {number}
	 * @memberof EventForEventList
	 */
	availability?: number;
	/**
	 *
	 * @type {Identifier}
	 * @memberof EventForEventList
	 */
	away?: Identifier;
	/**
	 *
	 * @type {number}
	 * @memberof EventForEventList
	 */
	betradarId?: number;
	/**
	 *
	 * @type {boolean}
	 * @memberof EventForEventList
	 */
	blank?: boolean;
	/**
	 *
	 * @type {Identifier}
	 * @memberof EventForEventList
	 */
	home?: Identifier;
	/**
	 *
	 * @type {number}
	 * @memberof EventForEventList
	 */
	id?: number;
	/**
	 *
	 * @type {string}
	 * @memberof EventForEventList
	 */
	key?: string;
	/**
	 *
	 * @type {{ [key: string]: Market; }}
	 * @memberof EventForEventList
	 */
	markets?: { [key: string]: Market };
	/**
	 *
	 * @type {number}
	 * @memberof EventForEventList
	 */
	marketsCount?: number;
	/**
	 *
	 * @type {EventMedia}
	 * @memberof EventForEventList
	 */
	media?: EventMedia;
	/**
	 *
	 * @type {EventMetadata}
	 * @memberof EventForEventList
	 */
	metadata?: EventMetadata;
	/**
	 *
	 * @type {string}
	 * @memberof EventForEventList
	 */
	name?: string;
	/**
	 *
	 * @type {{ [key: string]: Player; }}
	 * @memberof EventForEventList
	 */
	players?: { [key: string]: Player };
	/**
	 *
	 * @type {Array<string>}
	 * @memberof EventForEventList
	 */
	promotions?: Array<string>;
	/**
	 *
	 * @type {string}
	 * @memberof EventForEventList
	 */
	startTime?: string;
	/**
	 *
	 * @type {string}
	 * @memberof EventForEventList
	 */
	status?: string;
}

export function EventForEventListFromJSON(json: any): EventForEventList {
	return EventForEventListFromJSONTyped(json, false);
}

export function EventForEventListFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): EventForEventList {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		availability: !exists(json, 'availability')
			? undefined
			: json['availability'],
		away: !exists(json, 'away')
			? undefined
			: IdentifierFromJSON(json['away']),
		betradarId: !exists(json, 'betradarId')
			? undefined
			: json['betradarId'],
		blank: !exists(json, 'blank') ? undefined : json['blank'],
		home: !exists(json, 'home')
			? undefined
			: IdentifierFromJSON(json['home']),
		id: !exists(json, 'id') ? undefined : json['id'],
		key: !exists(json, 'key') ? undefined : json['key'],
		markets: !exists(json, 'markets')
			? undefined
			: mapValues(json['markets'], MarketFromJSON),
		marketsCount: !exists(json, 'marketsCount')
			? undefined
			: json['marketsCount'],
		media: !exists(json, 'media')
			? undefined
			: EventMediaFromJSON(json['media']),
		metadata: !exists(json, 'metadata')
			? undefined
			: EventMetadataFromJSON(json['metadata']),
		name: !exists(json, 'name') ? undefined : json['name'],
		players: !exists(json, 'players')
			? undefined
			: mapValues(json['players'], PlayerFromJSON),
		promotions: !exists(json, 'promotions')
			? undefined
			: json['promotions'],
		startTime: !exists(json, 'startTime') ? undefined : json['startTime'],
		status: !exists(json, 'status') ? undefined : json['status']
	};
}

export function EventForEventListToJSON(value?: EventForEventList | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		availability: value.availability,
		away: IdentifierToJSON(value.away),
		betradarId: value.betradarId,
		blank: value.blank,
		home: IdentifierToJSON(value.home),
		id: value.id,
		key: value.key,
		markets:
			value.markets === undefined
				? undefined
				: mapValues(value.markets, MarketToJSON),
		marketsCount: value.marketsCount,
		media: EventMediaToJSON(value.media),
		metadata: EventMetadataToJSON(value.metadata),
		name: value.name,
		players:
			value.players === undefined
				? undefined
				: mapValues(value.players, PlayerToJSON),
		promotions: value.promotions,
		startTime: value.startTime,
		status: value.status
	};
}
