import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

import { TextField, TextFieldProps } from '../..';

export type AuthCodeFieldProps = Omit<TextFieldProps, 'type'>;

const useStyles = makeStyles({
	code: {
		letterSpacing: 4
	}
});

export const AuthCodeField: React.FC<AuthCodeFieldProps> = ({
	inputProps,
	...props
}) => {
	const classes = useStyles();

	return (
		<TextField
			type="number"
			role="textbox"
			inputProps={{
				...inputProps,
				className: clsx(inputProps?.className, classes.code)
			}}
			{...props}
		/>
	);
};
