import { combineEpics } from 'redux-observable';
import { MarketType } from 'sports-sdk';
import { getDefaultHeaders } from 'sports/api/sports';
import * as schemas from 'sports/schema';
import {
	setSingleSportCompetitionKeyInEvent,
	setSingleSportKeyInCompetitions
} from 'sports/utils/entity';
import { createFetchEntityEpic } from 'sports/utils/epic';
import { getSportsApiLocale } from 'sports/utils/locale';
import {
	buildEventTreesMetaKey,
	createEventTreesMeta,
	finishLoading
} from 'sports/utils/meta';
import { createEntityReducer } from 'sports/utils/reducer';
import { ActionType, createAction } from 'typesafe-actions';
import deepmerge from 'deepmerge';

export default createEntityReducer<schemas.TopCouponsEntity>(
	schemas.topCoupons.key
);

interface TopCouponsParams {
	key?: string;
	players?: boolean;
	limit?: number;
	sports?: string[];
	markets?: MarketType[];
	locale: string;
}

////////////////
/// Actions ///
//////////////
export const actions = {
	fetchTopCoupons: createAction(
		'topCoupons/fetchTopCoupons',
		(resolve) => (payload: {
			sports?: string[];
			locale: string;
			timeZone: string;
		}) => resolve(payload)
	),
	fetchTopCouponEvents: createAction(
		'topCoupons/fetchTopCouponEvents',
		(resolve) => (payload: {
			key: string;
			players?: boolean;
			limit?: number;
			locale: string;
			markets?: MarketType[];
			timeZone: string;
		}) => resolve(payload)
	)
};

export type TopCouponActions = ActionType<typeof actions>;

const getTopCouponKey = (params: TopCouponsParams) => {
	let string = '';
	if (params.sports) {
		string += `_sports_${params.sports.join(',')}`;
	}
	if (params.limit) {
		string += `_limit_${params.limit}`;
	}

	string += `_locale_${params.locale}`;

	if (params.players) {
		string += `_players_${params.players}`;
	}
	if (params.markets) {
		string += `_markets_${params.markets.join(',')}`;
	}
	return string;
};

export const loads = {
	topcoupons: (params: TopCouponsParams) =>
		`topCoupons/${params.key}` + getTopCouponKey(params)
};

const getTopCouponEventsEpic = createFetchEntityEpic(
	actions.fetchTopCouponEvents,
	({ key, markets, players, limit, locale, timeZone }, { api }) =>
		api.sports
			.withPreMiddleware(async (context) =>
				deepmerge(context, {
					init: { headers: getDefaultHeaders(timeZone) }
				})
			)
			.getTopCoupon(
				key,
				markets,
				players,
				limit,
				getSportsApiLocale(locale)
			),
	schemas.topCoupons,
	(result) => {
		if (!result || !result.sport) {
			return result;
		}
		setSingleSportCompetitionKeyInEvent(result);
		setSingleSportKeyInCompetitions(result.sport);
		return result;
	},
	({ payload, result }) => {
		const load = finishLoading(loads.topcoupons(payload));
		if (!result || !result.sport) {
			return load;
		}
		const formatedResult = { sports: [result.sport] };
		const eventTreeKey = buildEventTreesMetaKey({
			...payload,
			topCouponKey: payload.key,
			endpoint: 'topcoupon',
			sports: result.sport.key ? [result.sport.key] : []
		});
		const sportsMeta = createEventTreesMeta(formatedResult, eventTreeKey);
		return { ...load, ...sportsMeta };
	}
);

const getTopCouponsListEpic = createFetchEntityEpic(
	actions.fetchTopCoupons,
	({ sports, locale, timeZone }, { api }) =>
		api.sports
			.withPreMiddleware(async (context) =>
				deepmerge(context, {
					init: { headers: getDefaultHeaders(timeZone) }
				})
			)
			.getTopCouponsList(sports, getSportsApiLocale(locale)),
	[schemas.topCoupons],
	(result) => {
		if (!result.topCouponsListMetas) {
			return [];
		}
		return result.topCouponsListMetas;
	},
	({ payload, result }) => {
		const loadingMeta = finishLoading(loads.topcoupons(payload));
		if (!result) {
			return loadingMeta;
		}
		return { ...loadingMeta };
	}
);

export const topCouponsEpic = combineEpics(
	getTopCouponEventsEpic,
	getTopCouponsListEpic
);
