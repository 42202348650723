"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginOnlySignIn = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var core_1 = require("@material-ui/core");
var player_1 = require("@materialbet-core/player");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var react_router_1 = require("react-router");
var __1 = require("../..");
var SingleColumnLayout_1 = require("../../components/SingleColumnLayout/SingleColumnLayout");
var useSignIn_1 = require("../../utils/useSignIn");
var BasicSignIn_1 = require("./BasicSignIn");
var IDontHaveAnAccount_1 = require("./IDontHaveAnAccount");
var useStyles = core_1.makeStyles(function (theme) { return ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.background.default,
        zIndex: theme.zIndex.appBar + 1,
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto'
    }
}); });
exports.LoginOnlySignIn = function (props) {
    var isLoggedIn = player_1.usePlayerLoggedIn();
    var _a = useSignIn_1.useSignIn(), captchaRef = _a.captchaRef, signIn = _a.signIn, isSubmitting = _a.isSubmitting;
    var classes = useStyles();
    var onForgot = function () {
        props.history.push(materialbet_common_1.AuthRoutes.forgotPassword.create());
    };
    if (isLoggedIn) {
        return React.createElement(react_router_1.Redirect, { to: materialbet_common_1.BaseRoutes.root.create() });
    }
    return (React.createElement(SingleColumnLayout_1.SingleColumnLayout, { alwaysShowTitle: true, className: classes.root, title: React.createElement(i18n_1.Translate, { label: "actions.signIn", ns: "common" }) },
        React.createElement(BasicSignIn_1.BasicSignIn, { loginLabel: React.createElement(i18n_1.Translate, { label: "signIn.nicknameEmail", ns: "player" }), forgotPasswordButtonText: React.createElement(i18n_1.Translate, { label: "signIn.forgotPassword", ns: "player" }), onForgotPasswordClick: onForgot, recaptchaPublicKey: RECAPTCHA_PUBLIC_KEY, captchaRef: captchaRef, onSubmitClick: signIn, passwordLabel: React.createElement(i18n_1.Translate, { label: "profile.password", ns: "player" }), submitButtonText: React.createElement(i18n_1.Translate, { label: "actions.signIn", ns: "common" }), validations: __1.authValidators, isSubmitting: isSubmitting, rememberMeLabel: React.createElement(i18n_1.Translate, { label: "signIn.rememberMe", ns: "player" }) },
            React.createElement(IDontHaveAnAccount_1.IDontHaveAnAccount, null))));
};
