import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

const allMarkets = {
	popular: [
		{
			marketType: MarketType.table_tennis_winner
		},
		{
			marketType: MarketType.table_tennis_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.table_tennis_totals,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.table_tennis_correct_score,
			view: MarketView.CorrectScore
		},
		{
			marketType: MarketType.table_tennis_game_winner
		},
		{
			marketType: MarketType.table_tennis_game_point_handicap,
			view: MarketView.Handicap
		}
	],
	games: [
		{
			marketType: MarketType.table_tennis_game_odd_even
		},
		{
			marketType: MarketType.table_tennis_game_point
		},
		{
			marketType: MarketType.table_tennis_game_total_points,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.table_tennis_exact_games
		},
		{
			marketType: MarketType.table_tennis_game_race_to_points
		},
		{
			marketType:
				MarketType.table_tennis_how_many_games_will_be_decided_by_extra_points
		}
	]
};

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: allMarkets.popular
	},
	{
		/**@todo update translation string, string name tennisGames too specifc to tennis */
		label: 'markets.tennisGames',
		markets: allMarkets.games
	},
	{
		label: 'descriptions.all',
		markets: [...allMarkets.popular, ...allMarkets.games]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.table_tennis_winner
			},
			{
				marketType: MarketType.table_tennis_totals
			},
			{
				marketType: MarketType.table_tennis_handicap
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.table_tennis_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
