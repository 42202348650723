/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Sport is used for an individual sport's details in the /sports endpoint
 * @export
 * @interface Sport
 */
export interface Sport {
	/**
	 *
	 * @type {boolean}
	 * @memberof Sport
	 */
	blank?: boolean;
	/**
	 *
	 * @type {number}
	 * @memberof Sport
	 */
	competitionCount?: number;
	/**
	 *
	 * @type {number}
	 * @memberof Sport
	 */
	eventCount?: number;
	/**
	 *
	 * @type {string}
	 * @memberof Sport
	 */
	key?: string;
	/**
	 *
	 * @type {string}
	 * @memberof Sport
	 */
	name?: string;
	/**
	 *
	 * @type {number}
	 * @memberof Sport
	 */
	outrightCount?: number;
}

export function SportFromJSON(json: any): Sport {
	return SportFromJSONTyped(json, false);
}

export function SportFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): Sport {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		blank: !exists(json, 'blank') ? undefined : json['blank'],
		competitionCount: !exists(json, 'competitionCount')
			? undefined
			: json['competitionCount'],
		eventCount: !exists(json, 'eventCount')
			? undefined
			: json['eventCount'],
		key: !exists(json, 'key') ? undefined : json['key'],
		name: !exists(json, 'name') ? undefined : json['name'],
		outrightCount: !exists(json, 'outrightCount')
			? undefined
			: json['outrightCount']
	};
}

export function SportToJSON(value?: Sport | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		blank: value.blank,
		competitionCount: value.competitionCount,
		eventCount: value.eventCount,
		key: value.key,
		name: value.name,
		outrightCount: value.outrightCount
	};
}
