import { useLanguage, useTranslation } from '@gaming-shell/i18n';
import { MarketOptions, MarketType } from 'sports-sdk';
import {
	MarketConfig,
	MarketConfigOptions,
	sportDefaultMarketMapping,
	SportType
} from 'sports/config/sport';
import {
	getCompetitionMarketConfiguration,
	getEventMarketConfiguration,
	getOutrightMarketConfiguration
} from 'sports/utils/configUtils';

import { getSdkLocale } from './locale';

export const createMarketOptions = (
	language: string,
	options?: MarketConfigOptions
) => ({
	...options,
	locale: getSdkLocale(language)
});
export const useMarketOptionsArray = (options: MarketConfigOptions[]) => {
	const language = useLanguage();
	return options.map((o) => createMarketOptions(language, o));
};
/** @deprecated can be removed once all old placebet stuff is removed */
export const createMarketUrl = (
	selection: { outcome: string; params: string },
	marketKey: MarketType
) => {
	return `${marketKey}/${selection.outcome}${
		selection.params.length > 0 ? '?' : ''
	}${selection.params}`;
};
export const getMarketUrl = (selection: {
	outcome: string;
	params: string;
	marketKey: MarketType;
}) => {
	const { outcome, params, marketKey } = selection;
	return `${marketKey}/${outcome}${params.length > 0 ? '?' : ''}${params}`;
};
export const getMarketKeyFromMarketUrl = (marketUrl: string) => {
	return marketUrl.split('/')[0] as MarketType;
};
export const getOutcomeFromMarketUrl = (marketUrl: string) => {
	const [, subroute] = marketUrl.split('/');
	if (!subroute) {
		return '';
	}
	return subroute.split('?')[0];
};
export const getParamsFromMarketUrl = (marketUrl: string) => {
	const [, subroute] = marketUrl.split('/');
	if (!subroute) {
		return '';
	}
	return subroute.split('?')[1];
};
export const marketUrlsAreSameMarket = (
	marketUrl: string,
	otherMarketUrl: string
) => {
	const [key1] = marketUrl.split('/');
	const [key2] = otherMarketUrl.split('/');
	if (key1 !== key2) {
		return false;
	}
	const [, params1] = marketUrl.split('?');
	const [, params2] = otherMarketUrl.split('?');
	return params1 === params2;
};
export const useMarketOptions = (
	options?: MarketConfigOptions
): MarketOptions => {
	const language = useLanguage();
	return createMarketOptions(language, options);
};

/** Expects a valid marketUrl with support by SDK. Will not do any verification or validation */
export const getMarketTypeFromMarketUrl = (marketUrl: string) => {
	const splits = marketUrl.split('/');
	const marketType = splits[0] as MarketType;
	return marketType;
};

const reduceMarketCallback = (
	acc: MarketType[],
	marketConfig: MarketConfig
) => {
	if ('marketType' in marketConfig) {
		acc.push(marketConfig.marketType);
		return acc;
	}
	return acc;
};
export const getCompetitionLevelMarketTypes = (sport: SportType) =>
	getCompetitionMarketConfiguration(sport).markets.reduce(
		reduceMarketCallback,
		[]
	);
export const getOutrightMarketTypes = (sport: SportType) =>
	getOutrightMarketConfiguration(sport).markets.reduce(
		reduceMarketCallback,
		[]
	);
const overUnderMarketIdentifiers = ['.total', '_total'];

export const isOverUnderMarket = (marketKey?: string) =>
	marketKey &&
	overUnderMarketIdentifiers.some((k) => marketKey.indexOf(k) > -1);

export const useMarketHeader = (length: number, marketKey?: MarketType) => {
	const trans = useTranslation();
	switch (length) {
		case 0:
			return [];
		case 2:
			if (isOverUnderMarket(marketKey)) {
				const overText = trans('sports:markets.over');
				const underText = trans('sports:markets.under');
				return [overText, underText];
			}
			return ['1', '2'];
		case 3:
			return ['1', 'X', '2'];
		default:
			return [];
	}
};

/**@description push markets inside marketGroup into all markets array, to be used in hasAnyMarkets() */
export const flattenMarketConfig = (
	allMarkets: MarketType[],
	marketConfig: MarketConfig
) => {
	if ('marketGroup' in marketConfig) {
		marketConfig.marketGroup.forEach((singleMarketConfig) =>
			allMarkets.push(singleMarketConfig.marketType)
		);
		return allMarkets;
	}
	allMarkets.push(marketConfig.marketType);
	return allMarkets;
};

/**@description return the last tab for event market config, it would be all or a single tab market for the sport */
export const getEventLevelMarketTypes = (sport: SportType) => {
	const config = getEventMarketConfiguration(sport);
	return config[config.length - 1].markets.reduce(reduceMarketCallback, []);
};

/**@description return the default market of the given sport */
export const getSportDefaultMarket = (sport: SportType) =>
	sportDefaultMarketMapping[sport];
