import { Button, ButtonProps } from '@material-ui/core';
import copy from 'clipboard-copy';
import * as React from 'react';

export interface CopyButtonProps
	extends Omit<ButtonProps, 'color' | 'variant'> {
	valueToCopy: string;
	color?: ButtonProps['color'];
	variant?: ButtonProps['variant'];
}

export const CopyButton: React.FC<CopyButtonProps> = ({
	variant = 'outlined',
	color = 'primary',
	onClick,
	valueToCopy,
	...otherButtonProps
}) => {
	const handleClick = React.useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();
			copy(valueToCopy);
			onClick && onClick(event);
		},
		[onClick, valueToCopy]
	);
	return (
		<Button
			variant={variant}
			color={color}
			onClick={handleClick}
			{...otherButtonProps}
		/>
	);
};
