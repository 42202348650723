import * as React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { BaseRoutes } from 'materialbet-common';
import { Translate } from '@gaming-shell/i18n';
import { CanceledPageContainer } from './CanceledPageContainer';
import { SuccessPageContainer } from './SuccessPageContainer';

const useStyles = makeStyles((theme) => ({
	paper: {
		minWidth: '50%',
		maxWidth: 600,
		margin: theme.spacing(3.5, 0, 2)
	},
	header: {
		margin: theme.spacing(3.5, 0, 2)
	},
	information: {
		marginBottom: theme.spacing(4),
		maxWidth: 400
	}
}));

export const TransactionResultPage: React.FC = () => {
	const classes = useStyles();
	const isCancelPageResult = BaseRoutes.providerTransactionCanceled.matchesExactly();

	const WrapperComponent = isCancelPageResult
		? CanceledPageContainer
		: SuccessPageContainer;

	return (
		<WrapperComponent>
			<Typography className={classes.header} variant="h5">
				{isCancelPageResult && (
					<Translate
						label="cashier.fiatTransactionCanceled"
						ns="player"
					/>
				)}
				{!isCancelPageResult && (
					<Translate
						label="cashier.fiatTransactionSuccessful"
						ns="player"
					/>
				)}
			</Typography>
			<Typography
				className={classes.information}
				variant="body2"
				color="textSecondary"
			>
				{isCancelPageResult && <>You can now close this window.</>}
				{!isCancelPageResult && (
					<>
						Your transaction was completed and funds successfully
						added to your account. You can now close this window.
					</>
				)}
				{/* Hardcoded for now without translation - product team request, this page is going to be modified by marketing team. */}
			</Typography>
		</WrapperComponent>
	);
};
