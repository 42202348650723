import { useLanguage } from '@gaming-shell/i18n';
import Auth from '@materialbet-core/auth';
import { usePlayerLoading, usePlayerLoggedIn } from '@materialbet-core/player';
import { LoginLoading } from 'LoginLoading';
import { AuthRoutes } from 'materialbet-common';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';

const ActualLoginOnlyRedirect: React.FC = ({ children }) => {
	const isLoggedIn = usePlayerLoggedIn();
	const isLoading = usePlayerLoading();
	useLanguage();
	if (isLoading) {
		return <LoginLoading />;
	}
	if (!isLoggedIn) {
		return (
			<Switch>
				<Route path={AuthRoutes.root.template}>
					<Auth />
				</Route>
				<Redirect from="/" to={AuthRoutes.signIn.create()} />
			</Switch>
		);
	}
	return <>{children}</>;
};

export const LoginOnlyRedirect: React.FC = LOGIN_ONLY
	? ActualLoginOnlyRedirect
	: ({ children }) => <>{children}</>;
