"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRootRoute = exports.getRootRouteOfCurrentRoute = void 0;
var routes_1 = require("../routes");
var rootRoutes = [routes_1.BaseRoutes.root, routes_1.CasinoRoutes.root, routes_1.SportsRoutes.root];
/** @description it is a function return the home page of the current route (sports,esports, casino, player) */
exports.getRootRouteOfCurrentRoute = function () {
    var isCasinoRoute = routes_1.CasinoRoutes.root.matches();
    if (isCasinoRoute) {
        return routes_1.CasinoRoutes.root.create();
    }
    var isSportsRoute = routes_1.SportsRoutes.root.matches();
    if (isSportsRoute) {
        return routes_1.SportsRoutes.root.create();
    }
    var isEsportsRoute = ENABLE_ESPORTS_SECTION && routes_1.EsportsRoutes.root.matches();
    if (isEsportsRoute) {
        return routes_1.EsportsRoutes.root.create();
    }
    var isCoreRoute = routes_1.PlayerRoutes.root.matches();
    if (isCoreRoute) {
        return routes_1.PlayerRoutes.root.create();
    }
    return routes_1.BaseRoutes.root.create();
};
/** @description it is a function to check if its the root page of any domain */
exports.isRootRoute = function () {
    return rootRoutes.some(function (rootRoute) { return rootRoute.matchesExactly(); });
};
