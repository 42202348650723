import { gtag, initialize as initializeGtm } from '@gaming-shell/gtm';
import {
	initDebugGlobals,
	initialize as initiliazeLogging
} from '@gaming-shell/logging';
import { initMonitoring } from '@gaming-shell/monitoring';
import { history, setLanguageInPath } from '@gaming-shell/routing';
import { ThemeKey, getTheme } from '@gaming-shell/theming';
import { initialize as initializeZendesk } from '@materialbet/zendesk';
import { getDefaultLanguage, toLocalLanguage } from '@gaming-shell/i18n';

/* Set proper language in path on initial script load */
const defaultLanguage = getDefaultLanguage();
setLanguageInPath(toLocalLanguage(defaultLanguage));

export const startup = () => {
	if (GTM_ID) {
		const gtmId = GTM_ID;
		initializeGtm(gtmId);
		history.listen((location) => {
			/* eslint-disable @typescript-eslint/camelcase */
			gtag('config', gtmId, { page_path: location.pathname });
			gtag('event', 'screen_view', { location: location.pathname });
		});
	}
	if (ZENDESK_KEY) {
		const theme = getTheme((DEFAULT_THEME as ThemeKey) || 'MAB');
		const color = theme.palette.primary.main;
		// we delay initializing of zendesk as it loads a quite heavy script
		setTimeout(() => initializeZendesk(ZENDESK_KEY, color), 5000);
	}
	if (ROLLBAR_ACCESS_TOKEN) {
		initiliazeLogging({
			rollbarAccessToken: ROLLBAR_ACCESS_TOKEN,
			env: window.location.host,
			enabled: true,
			brand: BRAND_NAME
		});
	}

	if (ENABLE_DEBUG) {
		initDebugGlobals();
	}
	if (ENABLE_RUM) {
		initMonitoring();
	}
};
