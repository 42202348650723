import { isDesktop } from '@gaming-shell/device';
import { Translate, useTranslation } from '@gaming-shell/i18n';
import { IconButton, Typography } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import React from 'react';

import { LinkButtonBase } from '../LinkButtonBase';
import { useStyles } from './PreviewHeader.styles';

export interface PreviewHeaderProps {
	/** @description Number that will be used to display the link to more games */
	count: number;
	/** @description url for the see all button  */
	seeAllHref?: string;
	/** @description Will only be called on click if isNextActive is true */
	onNextClick: () => void;
	/** @description Will only be called on click if isPreviousActive is true */
	onPreviousClick: () => void;
	/** @description if false, will disable calling onNextCallback */
	isNextActive: boolean;
	/** @description if false, will disable calling onPreviousClick */
	isPreviousActive: boolean;
	/** @description title element for the preview */
	title?: React.ReactNode;
}

export const PreviewHeader: React.FC<PreviewHeaderProps> = (props) => {
	const {
		isNextActive,
		isPreviousActive,
		onNextClick,
		onPreviousClick,
		seeAllHref,
		count,
		title
	} = props;
	const t = useTranslation();
	const classes = useStyles();
	return (
		<div className={classes.root}>
			{title}
			{isDesktop() && (
				<>
					<IconButton
						disabled={!isPreviousActive}
						title={t('actions.previousPage')}
						onClick={
							(isPreviousActive && onPreviousClick) || undefined
						}
						size="small"
						className={classes.leftArrowButton}
					>
						<KeyboardArrowLeftIcon />
					</IconButton>
					<IconButton
						disabled={!isNextActive}
						title={t('actions.nextPage')}
						onClick={(isNextActive && onNextClick) || undefined}
						size="small"
					>
						<KeyboardArrowRightIcon />
					</IconButton>
				</>
			)}
			{seeAllHref && (
				<div className={classes.seeAll}>
					<LinkButtonBase
						to={seeAllHref}
						aria-label={t('actions.seeAll')}
					>
						<Typography color="textSecondary" variant="body2">
							<Translate label="actions.seeAll" />
						</Typography>
						<Typography color="secondary" variant="body2">
							&nbsp;
							{count}
						</Typography>
					</LinkButtonBase>
				</div>
			)}
		</div>
	);
};
