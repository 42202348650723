import { Translate } from '@gaming-shell/i18n';
import { Snackbar } from '@material-ui/core';
import { usePlayerCurrency } from '@materialbet-core/player';
import * as React from 'react';
import { useCopyBets } from 'sports/utils/betShareUtils';
import {
	trackAddToBigBetslip,
	trackCopyToBigBetslip,
	trackOnBetPlaced,
	trackOnBetslipClose
} from 'sports/utils/trackingUtils';

import { usePlayerCurrencyVariantConfig } from '../../../utils/currency';
import { betslipActions } from '../betslipReducer';
import { getSelectionCount } from '../betslipSelectors';
import {
	getBetslipSelectionsIdentityString,
	getCurrentTotalStake,
	getIsSubmitting,
	getTotalBetslipStatus
} from '../betslipSelectors/betslipSelectors';
import { containsSameMarketSelection } from '../betslipSelectors/betslipSelectorsUtils';
import { useBetslipSelector, useBetslipStore } from '../hooks';
import { useBetslipDispatch } from '../hooks/useBetslipDispatch';
import {
	BetslipReducerBetslip,
	BetslipReducerSelection,
	PlaceBetCallbackArgs
} from '../types';
import { BetslipPersister } from './BetslipPersister';
import { BigBetslip } from './BigBetslip';
import { BigBetslipBetPlacedSnackbar } from './BigBetslipBetPlacedSnackbar';

interface BigBetslipContextValue {
	addSelectionToBigBetslip: (selection: BetslipReducerSelection) => void;
	selectionIsInBigBetslip: (selectionIdentity: string) => boolean;
	bigBetslipIsActive: boolean;
}
const defaultBigBetslipValue = {
	selectionIsInBigBetslip: () => false,
	addSelectionToBigBetslip: () => void undefined,
	bigBetslipIsActive: false
};
const BigBetslipContext = React.createContext<BigBetslipContextValue>(
	defaultBigBetslipValue
);
export const useAddToBigBetslip = () => {
	const { addSelectionToBigBetslip } = React.useContext(BigBetslipContext);
	return addSelectionToBigBetslip;
};
export const useSelectionIsInBigBetslip = (selection: string) => {
	const { selectionIsInBigBetslip } = React.useContext(BigBetslipContext);
	return selectionIsInBigBetslip(selection);
};
export const useBigBetslipIsActive = () => {
	const { bigBetslipIsActive } = React.useContext(BigBetslipContext);
	return bigBetslipIsActive;
};
let BigBetslipProvider: React.FunctionComponent = ({ children }) => {
	const [open, setOpen] = React.useState(false);
	const [showMaxSelectionsInfo, setShowMaxSelectionsInfo] = React.useState(
		false
	);
	const store = useBetslipStore();
	const playerCurrency = usePlayerCurrency();
	const selectionsIdentity = useBetslipSelector(
		getBetslipSelectionsIdentityString
	);
	const selectionCount = useBetslipSelector(getSelectionCount);
	const status = useBetslipSelector(getTotalBetslipStatus);
	const dispatch = useBetslipDispatch();
	const currencyConfig = usePlayerCurrencyVariantConfig();
	const bigBetslipIsActive = selectionCount > 0;
	const [
		betPlacedSnackbar,
		setBetPlacedSnackbar
	] = React.useState<PlaceBetCallbackArgs | null>(null);
	const addSelectionToBigBetslip = React.useCallback(
		(selection: BetslipReducerSelection) => {
			if (
				// this could be even more performant by just only relying on the redux store and then query on the latest state instead
				selectionCount >= 8 &&
				!containsSameMarketSelection(selectionsIdentity, selection)
			) {
				setShowMaxSelectionsInfo(true);
				return;
			}
			trackAddToBigBetslip();
			dispatch(betslipActions.addSelection({ selection }));
		},
		[selectionsIdentity, selectionCount, dispatch]
	);
	const isSubmitting = useBetslipSelector(getIsSubmitting);
	const selectionIsInBigBetslip = React.useCallback(
		(identity: string) => selectionsIdentity.includes(identity),
		[selectionsIdentity]
	);
	React.useEffect(() => {
		if (!bigBetslipIsActive) {
			setOpen(false);
		}
	}, [bigBetslipIsActive]);
	React.useEffect(() => {
		// we need to get the current count from the store, in case the state has been updated between render and effect call (e.g. by another effect)
		const latestSelectionCount = getSelectionCount(store.getState());
		if (latestSelectionCount === 1) {
			// we dont allow using the parlay tab if there is only one selections
			dispatch(betslipActions.setMode('singles'));
		}
		if (latestSelectionCount === 0) {
			// in case we just remove all selections, we should remove clear the betslip
			// if betslip is cleared via menu - then this gets called aswell but only once so its fine
			trackOnBetslipClose();
			dispatch(betslipActions.clear());
		}
	}, [selectionCount, dispatch, store]);
	const openBigBetslip = React.useCallback(() => {
		setOpen(true);
	}, [setOpen]);
	const onBetPlaced = React.useCallback(
		(args: PlaceBetCallbackArgs) => {
			setOpen(false);
			const state = store.getState();
			trackOnBetPlaced({
				value: getCurrentTotalStake(state),
				currency: playerCurrency
			});
			dispatch(betslipActions.clear());
			setBetPlacedSnackbar(args);
		},
		[dispatch, setBetPlacedSnackbar, store, playerCurrency]
	);
	const value = React.useMemo(
		() => ({
			addSelectionToBigBetslip,
			selectionIsInBigBetslip,
			bigBetslipIsActive
		}),
		[addSelectionToBigBetslip, selectionIsInBigBetslip, bigBetslipIsActive]
	);
	const setBetslip = (betslip: BetslipReducerBetslip) =>
		dispatch(betslipActions.setBetslip(betslip));
	const disableMultiples = selectionCount <= 1;
	const onCopyBetsDone = (source: string) => {
		openBigBetslip();
		trackCopyToBigBetslip(source);
	};
	useCopyBets({ done: onCopyBetsDone });
	return (
		<BigBetslipContext.Provider value={value}>
			<BetslipPersister onBetPlaced={onBetPlaced} />
			<>{children}</>
			{selectionCount ? (
				<BigBetslip
					open={open}
					setOpen={setOpen}
					currencyConfig={currencyConfig}
					selectionCount={selectionCount}
					isSubmitting={isSubmitting}
					showBetslipButton={bigBetslipIsActive}
					onBetPlaced={onBetPlaced}
					errors={status}
					disableMultiples={disableMultiples}
				/>
			) : null}
			<BigBetslipBetPlacedSnackbar
				betslip={betPlacedSnackbar}
				onClose={() => setBetPlacedSnackbar(null)}
				openBigBetslip={openBigBetslip}
				setBetslip={setBetslip}
			/>
			<Snackbar
				open={showMaxSelectionsInfo}
				onClose={() => setShowMaxSelectionsInfo(false)}
				autoHideDuration={3000}
				message={
					<Translate
						ns="sports"
						label="betting.betslipReachedMaxSelections"
					/>
				}
			/>
		</BigBetslipContext.Provider>
	);
};
BigBetslipProvider = React.memo(BigBetslipProvider);
export { BigBetslipProvider };
