"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavItems = void 0;
var core_1 = require("@material-ui/core");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var Buttons_1 = require("../Buttons");
var NavItemsMenu_1 = require("../NavItemsMenu");
var useStyles = core_1.makeStyles({
    menu: {
        overflow: 'hidden'
    }
});
exports.NavItems = function (_a) {
    var hideFrom = _a.hideFrom, children = _a.children;
    var _b = materialbet_common_1.useOpenCloseMenu(), handleOpenMenu = _b[0], handleCloseMenu = _b[1], anchorEl = _b[2];
    var theme = core_1.useTheme();
    var classes = useStyles();
    var matches = core_1.useMediaQuery(theme.breakpoints.down('sm'));
    var navItems = React.Children.toArray(children).filter(function (item) { return !!item; });
    var total = navItems.length;
    var restChildren = [];
    if (matches && total > SUPPORTED_APPS.length) {
        restChildren = navItems.splice(SUPPORTED_APPS.length, total - 1);
    }
    else if (hideFrom) {
        restChildren = navItems.splice(hideFrom, total - 1);
    }
    return (React.createElement(React.Fragment, null,
        navItems,
        restChildren.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement(Buttons_1.DotsButton, { onClick: handleOpenMenu }),
            React.createElement(NavItemsMenu_1.NavItemsMenu, { anchorEl: anchorEl, onClose: handleCloseMenu, className: classes.menu }, restChildren)))));
};
