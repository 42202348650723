import { useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { SportType } from 'sports/config/sport';
import { SportsState } from 'sports/modules/root';
import {
	getCompetitionKey,
	getEvent,
	getStatus,
	getStreamUrl
} from 'sports/selectors/eventSelectors';
import { eventCanShowStream } from 'sports/utils/event';
import { getBannerImage } from 'sports/utils/media';
import {
	getCompetitionStreamUrl,
	getCompetitionStreamProvider
} from 'sports/selectors/competitionSelectors';

const twitchUrl = 'https://player.twitch.tv/';
export const twitchCompetitionUrls = {
	'league-of-legends-international-lpl': twitchUrl + '?channel=lpl',
	'league-of-legends-international-oceanic-pro-league':
		twitchUrl + '?channel=riotgamesoce',
	'league-of-legends-international-cblol':
		twitchUrl + '?channel=riotgamesbrazil',
	'league-of-legends-international-pcs': twitchUrl + '?channel=lolpacifictw',
	'league-of-legends-international-tcl':
		twitchUrl + '?channel=riotgamesturkish',
	'league-of-legends-international-vcs': twitchUrl + '?channel=vcsenglish',
	'league-of-legends-international-lcl': twitchUrl + '?channel=riotgamesru',
	'league-of-legends-international-lck': twitchUrl + '?channel=lck',
	'league-of-legends-international-lcs': twitchUrl + '?channel=lcs',
	'league-of-legends-international-lec': twitchUrl + '?channel=lec',
	'league-of-legends-international-ultraliga':
		twitchUrl + '?channel=polsatgames',
	'league-of-legends-international-ljl': twitchUrl + '?channel=riotgamesjp',
	'league-of-legends-international-lvp-superliga-orange':
		twitchUrl + '?channel=lvpes',
	'counter-strike-international-esl-pro-league':
		twitchUrl + '?channel=esl_csgo',
	'counter-strike-international-esl-anz-championship':
		twitchUrl + '?channel=esl_australia',
	'counter-strike-international-esl-meisterschaft':
		twitchUrl + '?channel=esl_pubg_de',
	'counter-strike-international-flashpoint':
		twitchUrl + '?channel=flashpointcs',
	'counter-strike-international-efire-master-league':
		twitchUrl + '?channel=efireleague',
	'counter-strike-international-telia-esports-series':
		twitchUrl + '?channel=tes_csgo',
	'counter-strike-international-aorus-league':
		twitchUrl + '?channel=aorusleague',
	'counter-strike-international-huya-darkhorse-league':
		twitchUrl + '?channel=aferatv',
	'dota-2-international-china-pro-league': twitchUrl + '?channel=pro_cast2',
	'dota-2-international-esl-one': twitchUrl + '?channel=esl_dota2',
	'dota-2-international-weplay': twitchUrl + '?channel=weplayesport_en',
	'dota-2-thailand-thailand-celestial-cup':
		twitchUrl + '?channel=choppachappa',
	'dota-2-international-pro-association': twitchUrl + '?channel=dota2ruhub3',
	'dota-2-international-bts-pro-series':
		twitchUrl + '?channel=beyondthesummit',
	'dota-2-international-esl-one-los-angeles':
		twitchUrl + '?channel=esl_dota2', // same stream link as international-esl-one, intentional. May merge later.
	'starcraft-international-gsl-super-tournament': twitchUrl + '?channel=gsl',
	'esport-fifa-international-liga-pro-fifa-2020':
		twitchUrl + '?channel=ligapro1',
	'esport-fifa-international-cyberleague-pro-series-rpl':
		twitchUrl + '?channel=russian_premierliga',
	'esport-fifa-international-stay-and-play-cup':
		twitchUrl + '?channel=easportsfifa',
	'esport-nba2k-international-nba-cyber-pacific-division':
		twitchUrl + '?channel=cyberlivearena4'
};

export const iframeCompetitionUrls = { ...twitchCompetitionUrls };
export const useEventTwitchStreamUrl = (props: {
	eventId: number;
	competitionKey: string;
}) => {
	const { eventId, competitionKey } = props;
	const streamIndex = 0;
	const eventStreamUrl = useSelector((state: SportsState) =>
		getStreamUrl(streamIndex)(eventId)(state)
	);
	// Handele twich here need to add parent for url for twitch
	const twitchCompetitionUrl = twitchCompetitionUrls[competitionKey];
	const streamUrl = eventStreamUrl || twitchCompetitionUrl;

	if (!streamUrl) {
		return;
	}
	return `${streamUrl}&parent=${window.location.hostname}`;
};

export const getCompetitionTwitchStreamUrl = (props: {
	competitionKey: string;
	competitionStreamUrl?: string;
}) => {
	const { competitionKey, competitionStreamUrl } = props;

	// Handele twich here need to add parent for url for twitch
	const twitchCompetitionUrl = twitchCompetitionUrls[competitionKey];
	const streamUrl = competitionStreamUrl || twitchCompetitionUrl;
	if (!streamUrl) {
		return;
	}
	return `${streamUrl}&parent=${window.location.hostname}`;
};

export interface CompetitionStreamProps {
	competitionKey: string;
	sportKey: SportType;
}

export const useCompetitionStreamUrl = (competitionKey: string) => {
	const competitionStreamUrl = useSelector(
		getCompetitionStreamUrl(0)(competitionKey)
	);

	const competitionStreamProvider = useSelector(
		getCompetitionStreamProvider(0)(competitionKey)
	);

	if (competitionStreamProvider === 'twitch') {
		const twitchStreamUrl = getCompetitionTwitchStreamUrl({
			competitionKey,
			competitionStreamUrl
		});
		return twitchStreamUrl;
	}

	return competitionStreamUrl;
};

export const useCompetitionStreamProps = (props: CompetitionStreamProps) => {
	const { competitionKey, sportKey } = props;
	const theme = useTheme();
	const backgroundImgUrl = getBannerImage({
		sportsKey: sportKey as string,
		opacity: theme.palette.type === 'dark' ? 40 : 30, // Too light to have 40 for light theme, use it and wait for design review
		background:
			theme.palette.type === 'dark'
				? theme.palette.background.top.main
				: '#FFFFFF',
		height: 'ih'
	});
	const competitionUrl = useCompetitionStreamUrl(competitionKey || '');
	const iframeCompUrls = iframeCompetitionUrls[competitionKey || ''];
	const streamUrl = competitionUrl || iframeCompUrls;

	return {
		streamAvailable: !!streamUrl,
		streamUrl,
		backgroundImgUrl,
		competitionKey
	};
};

export interface EventStreamProps {
	eventId: number;
	sportKey: SportType;
	ratio?: string;
	className?: string;
	isAutoPlay?: boolean;
	showControls?: boolean;
}

export const useEventStreamProps = (props: EventStreamProps) => {
	const { eventId, sportKey, ratio, className } = props;
	const theme = useTheme();
	const event = useSelector((state: SportsState) => getEvent(state, eventId));
	const eventStatus =
		useSelector((state: SportsState) => getStatus(eventId)(state)) || '';
	const competitionKey = useSelector(getCompetitionKey(eventId)) || '';
	const backgroundImgUrl = getBannerImage({
		sportsKey: sportKey,
		image: event?.media?.image,
		opacity: theme.palette.type === 'dark' ? 40 : 30, // Too light to have 40 for light theme, use it and wait for design review
		background:
			theme.palette.type === 'dark'
				? theme.palette.background.top.main
				: '#FFFFFF',
		height: 'ih'
	});
	const streamAvailable = eventCanShowStream(eventStatus || '');
	const twitchStreamUrl = useEventTwitchStreamUrl({
		eventId,
		competitionKey
	});
	const streamUrl = twitchStreamUrl || iframeCompetitionUrls[competitionKey];
	return {
		eventId,
		sportKey,
		backgroundImgUrl,
		ratio,
		className,
		eventStatus,
		streamAvailable,
		eventStreamUrl: streamUrl
	};
};
