"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMainNavButtonClickHandler = void 0;
var hooks_1 = require("../../../SideMenuDrawer/hooks");
var components_1 = require("../../components");
var routing_1 = require("@gaming-shell/routing");
var materialbet_common_1 = require("materialbet-common");
var createCloseAction = function (fallbackRoute) { return function () {
    return routing_1.history.replace(fallbackRoute);
}; };
var createBackAction = function (fallbackRoute) {
    return function () {
        routing_1.canGoBack() ? routing_1.goBack() : routing_1.history.replace(fallbackRoute);
    };
};
exports.useMainNavButtonClickHandler = function (type, defaultBackRoute) {
    var toggleSideMenu = hooks_1.useToggleSideMenuDrawer();
    var fallbackRoute = defaultBackRoute || materialbet_common_1.getRootRouteOfCurrentRoute();
    switch (type) {
        case components_1.AnimatedAppBarIconType.Close:
            return createCloseAction(fallbackRoute);
        case components_1.AnimatedAppBarIconType.Back:
            return createBackAction(fallbackRoute);
        default:
            return toggleSideMenu;
    }
};
