import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

const allMarkets = {
	popular: [
		{
			marketType: MarketType.counter_strike_winner
		},
		{
			marketType: MarketType.counter_strike_total_maps,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.counter_strike_match_odds
		},
		{
			marketType: MarketType.counter_strike_map_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.counter_strike_map_round_kill_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.counter_strike_map_1st_half_winner
		},
		{
			marketType: MarketType.counter_strike_map_1x2
		},
		{
			marketType: MarketType.counter_strike_correct_score_in_maps,
			view: MarketView.CorrectScore
		},
		{
			marketType: MarketType.counter_strike_map_will_there_be_overtime
		}
	],
	kills: [
		{
			marketType: MarketType.counter_strike_map_round_total_kills
		},
		{
			marketType: MarketType.counter_strike_map_xth_kill
		},
		{
			marketType: MarketType.counter_strike_map_player_with_most_deaths
		},
		{
			marketType: MarketType.counter_strike_map_round_player_with_kills
		},
		{
			marketType: MarketType.counter_strike_map_round_kill_number
		},
		{
			marketType: MarketType.counter_strike_map_round_team_total_kills
		},
		{
			marketType: MarketType.counter_strike_map_will_there_be_an_ace
		}
	],
	specials: [
		{
			marketType: MarketType.counter_strike_map_winner
		},
		{
			marketType: MarketType.counter_strike_map_knife_round_winner
		},
		{
			marketType: MarketType.counter_strike_map_player_with_most_kills
		},
		{
			marketType: MarketType.counter_strike_map_race_to_rounds
		},
		{
			marketType: MarketType.counter_strike_map_round_handicap
		},
		{
			marketType: MarketType.counter_strike_map_round_winner
		},
		{
			marketType: MarketType.counter_strike_map_total_rounds
		},
		{
			marketType: MarketType.counter_strike_map_total_headshots
		},
		{
			marketType: MarketType.counter_strike_map_round_bomb_defused
		},
		{
			marketType: MarketType.counter_strike_map_round_bomb_planted
		},
		{
			marketType: MarketType.counter_strike_map_odd_even_rounds
		}
	]
};

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: allMarkets.popular
	},
	{
		label: 'markets.kills',
		markets: allMarkets.kills
	},
	{
		label: 'markets.specials',
		markets: allMarkets.specials
	},
	{
		label: 'descriptions.all',
		markets: [
			...allMarkets.popular,
			...allMarkets.kills,
			...allMarkets.specials
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.counter_strike_winner
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.counter_strike_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
