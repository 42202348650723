import {
	DialogActions,
	DialogContent,
	DialogTitle,
	Dialog as MuiDialog
} from '@material-ui/core';
import {
	DialogClassKey as MuiDialogClassKey,
	DialogProps as MuiDialogProps
} from '@material-ui/core/Dialog';
import * as React from 'react';

export type DialogClassKey =
	| MuiDialogClassKey
	| 'title'
	| 'content'
	| 'actions';

export interface DialogProps extends MuiDialogProps {
	buttons?: React.ReactNode;
	dialogTitle: React.ReactNode;
	classes?: Partial<Record<DialogClassKey, string>>;
}

export const Dialog: React.FunctionComponent<DialogProps> = ({
	buttons,
	dialogTitle,
	classes = {},
	children,
	...otherProps
}) => {
	const {
		title: titleClassName,
		content: contentClassName,
		actions: actionsClassName,
		...muiDialogClasses
	} = classes;

	return (
		<MuiDialog classes={muiDialogClasses} {...otherProps}>
			<DialogTitle className={titleClassName}>{dialogTitle}</DialogTitle>
			<DialogContent className={contentClassName}>
				{children}
			</DialogContent>
			{buttons && (
				<DialogActions className={actionsClassName}>
					{buttons}
				</DialogActions>
			)}
		</MuiDialog>
	);
};
