import { useTheme } from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import useMediaQuery from '@material-ui/core/useMediaQuery';

/** @description get the current breakpoint of the media width */
// Ref: https://material-ui.com/components/use-media-query/
export const useMediaWidth = (): Breakpoint => {
	const theme = useTheme();

	const sm = useMediaQuery(theme.breakpoints.up('sm'));
	const md = useMediaQuery(theme.breakpoints.up('md'));
	const lg = useMediaQuery(theme.breakpoints.up('lg'));
	const xl = useMediaQuery(theme.breakpoints.up('xl'));
	if (xl) {
		return 'xl';
	}
	if (lg) {
		return 'lg';
	}
	if (md) {
		return 'md';
	}
	if (sm) {
		return 'sm';
	}
	return 'xs';
};
