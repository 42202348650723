import { makeStyles, Theme } from '@material-ui/core';
import Box, { BoxProps } from '@material-ui/core/Box';
import clsx from 'clsx';
import * as React from 'react';

export interface BannerProps extends BoxProps {
	bannerUrl: string;
}
const useStyle = makeStyles((theme: Theme) => ({
	root: ({ bannerUrl }: { bannerUrl: string }) => ({
		backgroundImage: `url('${bannerUrl}')`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center top',
		backgroundColor: theme.palette.type === 'dark' ? 'black' : 'white'
	})
}));

export const Banner: React.FunctionComponent<BannerProps> = (props) => {
	const { bannerUrl, className, ...boxProps } = props;
	const classes = useStyle({ bannerUrl });
	return <Box className={clsx(classes.root, className)} {...boxProps} />;
};
