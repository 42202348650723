import * as React from 'react';

import {
	PlaceBetCallback,
	useBetslipPlaceBet
} from '../placeBet/useBetslipPlaceBet';
import {
	BigBetslipPlaceBetButton,
	BigBetslipPlaceBetButtonProps
} from './BigBetslipPlaceBetButton';

export const BigBetslipPlaceStraightsBetButton: React.FunctionComponent<Omit<
	BigBetslipPlaceBetButtonProps,
	'onPlaceBet'
> & {
	onBetPlaced: PlaceBetCallback;
}> = (props) => {
	const { onBetPlaced, ...other } = props;
	const placeStraightsBet = useBetslipPlaceBet();
	const placeBet = () => placeStraightsBet(onBetPlaced);
	return <BigBetslipPlaceBetButton onPlaceBet={placeBet} {...other} />;
};
