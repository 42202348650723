import { getPlatform } from '@gaming-shell/device';
import { CasinoState } from 'casino/modules/rootModule';
import { isLiveGame } from 'casino/util';
import { isFavorite } from 'casino/util/gamesUtils/isFavorite';

export type GameId = string | number | undefined;
const gamesSelector = (state: CasinoState) => state.games;
export const getGame = (id: GameId) => (state: CasinoState) =>
	id === undefined ? undefined : gamesSelector(state)[id];
export const getGames = (ids?: GameId[]) => (state: CasinoState) =>
	!ids || ids?.length === 0 ? undefined : ids.map((id) => getGame(id)(state));
/** @description This is a simple select on the game, it goes through the tags array to determine if the `my-casino` tag is present.
 *  If it turns out to take up too much time (depending on how many games are rendered and how many tags a game has), we could create a reselect selector for it */
export const getGameIsFavorite = (id: GameId) => (state: CasinoState) =>
	isFavorite(getGame(id)(state));
export const getGameIsLive = (id: GameId) => (state: CasinoState) =>
	isLiveGame(getGame(id)(state));
export const getGameName = (id: GameId) => (state: CasinoState) =>
	getGame(id)(state)?.name;
export const getGameStudio = (id: GameId) => (state: CasinoState) =>
	getGame(id)(state)?.studio;
export const getGamesStudios = (ids: GameId[]) => (state: CasinoState) =>
	getGames(ids)(state)?.map((game) => game?.studio);
export const getDistinctGamesStudios = (id: GameId[]) => (
	state: CasinoState
) => {
	const studiosKeys = getGames(id)(state)?.map((game) => game?.studio);
	const uniqueStudiosKeys = studiosKeys?.reduce<string[]>((acc, curr) => {
		if (curr && !acc.includes(curr)) {
			acc.push(curr);
		}
		return acc;
	}, []); // Only get unique studios keys
	return uniqueStudiosKeys;
};
export const getGameImageUrl = (id: GameId) => (state: CasinoState) =>
	getGame(id)(state)?.platforms?.[getPlatform()]?.image_url;
