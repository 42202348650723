import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LiveResponse200 } from 'sports/api/livesport365/api';
import { actions as commonActions } from 'sports/modules/common';
import { FinishLoadingMeta } from 'sports/modules/loading';
import { EpicDependencies } from 'sports/store';
import { ActionType, createAction, getType } from 'typesafe-actions';

import { Livesport365MatchEntity } from '../../schema';

export const loads = {
	update: 'livesport365/update'
};
export const actions = {
	fetch: createAction('livesport365/fetch', (resolve) => () => resolve()),
	update: createAction(
		'livesport365/update',
		(resolve) => (payload: LiveResponse200) =>
			resolve(payload, {
				load: {
					name: loads.update,
					receivedAt: new Date().getTime()
				} as FinishLoadingMeta
			})
	)
};

export type Livesport365Actions = ActionType<typeof actions>;
export default (
	state: { [key: string]: Livesport365MatchEntity } = {},
	action: Livesport365Actions
) => {
	switch (action.type) {
		case getType(actions.update): {
			return action.payload;
		}
		default:
			return state;
	}
};
const fetchLiveSport: Epic<
	Action<string>,
	Action<string>,
	object,
	EpicDependencies
> = (action$, $state, dependencies) => {
	return action$.pipe(
		ofType(getType(actions.fetch)),
		mergeMap(() => from(dependencies.api.livesport365.getLiveMatches())),
		map((response) => {
			if (!response) {
				throw Error('Exchange rates response did not include rates');
			}
			return actions.update(response);
		}),
		catchError((error) =>
			of(
				commonActions.raiseError({
					error: error ? error.toString() : 'unknown',
					action: getType(actions.fetch),
					message: 'Error occured updating player details'
				})
			)
		)
	);
};
export const livesport365Epic = combineEpics(fetchLiveSport);
