"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SsoHandler = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var useHandleError_1 = require("./useHandleError");
var materialbet_common_1 = require("materialbet-common");
var backRoute_1 = require("../../utils/backRoute");
var SsoAction;
(function (SsoAction) {
    SsoAction["SIGN_IN"] = "signed_in";
    SsoAction["SIGN_UP"] = "sign_up";
    SsoAction["ERROR"] = "error";
    SsoAction["ONBOARDING"] = "onboarding";
})(SsoAction || (SsoAction = {}));
exports.SsoHandler = function (_a) {
    var history = _a.history, location = _a.location;
    var trans = i18n_1.useTranslation();
    var enqueueSnackbar = materialbet_common_1.useSnackbar().enqueueSnackbar;
    var handleError = useHandleError_1.useHandleError();
    var query = materialbet_common_1.querystring.parse(location.search);
    if (query.code && !Array.isArray(query.code)) {
        handleError(query.code.toUpperCase(), { redirect: true });
    }
    else {
        switch (query.sso_action) {
            case SsoAction.SIGN_UP:
                var email = query["player[email]"], nickname = query["player[nickname]"], ssoToken = query["player[sso_token]"];
                history.push(materialbet_common_1.AuthRoutes.signUp.create(), {
                    email: email,
                    nickname: nickname,
                    ssoToken: ssoToken
                });
                break;
            case SsoAction.ONBOARDING:
                history.push(materialbet_common_1.PlayerRoutes.onboarding.create());
                break;
            case SsoAction.ERROR:
                enqueueSnackbar(trans('player:descriptions.failedRequest'));
                history.push(materialbet_common_1.BaseRoutes.root.create());
                break;
            case SsoAction.SIGN_IN:
                enqueueSnackbar(trans('player:signIn.success'));
                var location_1 = backRoute_1.getBackRoute();
                history.push(location_1);
                break;
            default:
                history.push(materialbet_common_1.BaseRoutes.root.create());
        }
    }
    return null;
};
