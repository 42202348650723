// note that the common module is not a module in duck sense, as it does not export a reducer
// we need seperate file for common actions, as we cannot import an action from root in any other
// module due to circular dependencies
import { Entities } from 'sports/schema';
import { BalanceUpdated } from 'sports/types/pusherModels';
import { MetaPayload, sportsMeta } from 'sports/utils/meta';
import { ActionType, createAction } from 'typesafe-actions';

export interface ErrorPayload {
	action: string;
	message?: string;
	error: unknown;
}

export const actions = {
	updateEntities: createAction(
		'root/updateEntities',
		(resolve) => (entities: Entities, meta?: MetaPayload) =>
			resolve(entities, meta)
	),
	raiseError: createAction(
		'root/raiseError',
		(resolve) => (payload: ErrorPayload) => {
			return resolve(payload);
		}
	),
	clear: createAction('root/clear', (resolve) => () => resolve()),
	updateBalance: createAction(
		'root/updateBalance',
		(resolve) => (payload: BalanceUpdated) => resolve(payload)
	),
	updateIpCountry: createAction(
		'root/updateIpCountry',
		(resolve) => (ipCountry: string) =>
			resolve(undefined, sportsMeta({ ipCountry }))
	)
};

export type CommonActions = ActionType<typeof actions>;
