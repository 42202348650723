import { useLocation } from 'react-router';

import { CasinoRoutes, EsportsRoutes, SportsRoutes } from '../../routes';

/** @description Hook to get base route for current route */
export const useBaseRoute = () => {
	const { pathname } = useLocation();

	if (CasinoRoutes.root.matches(pathname)) {
		return CasinoRoutes.root.create();
	}
	if (ENABLE_ESPORTS_SECTION && EsportsRoutes.root.matches(pathname)) {
		return EsportsRoutes.root.create();
	}
	return SportsRoutes.root.create();
};
