import * as React from 'react';
import { actions, usePlayerInboxDispatch } from 'materialbet-inbox';

export const useDismissNotification = () => {
	const dispatch = usePlayerInboxDispatch();

	const dismissNotificationCallback = React.useCallback(
		(notificationId: string) => {
			dispatch(actions.dismissNotification(notificationId));
		},
		[dispatch]
	);

	return dismissNotificationCallback;
};
