import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';

import { BetslipSelectionPrice } from '../BetslipSelectionPrice';

export interface BigBetslipSelectionInfoProps {
	price: number;
	changedPrice?: number;
	outcomeName: string;
	marketName: string;
	eventName: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	price: {
		marginLeft: theme.spacing(1),
		color: theme.palette.primary.main
	},
	subtitle: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		width: '100%'
	},
	outcomeName: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	}
}));

export const BigBetslipSelectionInfo: React.FunctionComponent<BigBetslipSelectionInfoProps> = ({
	price,
	eventName,
	outcomeName,
	marketName,
	changedPrice
}) => {
	const classes = useStyles();
	return (
		<Box overflow="hidden" textOverflow="ellipsis">
			<Box display="flex" alignItems="center">
				<Typography className={classes.outcomeName} title={outcomeName}>
					{outcomeName}
				</Typography>
				<BetslipSelectionPrice
					price={price}
					className={classes.price}
					changedPrice={changedPrice}
				/>
			</Box>
			<Typography
				component="div"
				variant="caption"
				color="textSecondary"
				className={classes.subtitle}
				title={marketName}
			>
				{marketName}
			</Typography>
			{marketName !== eventName && (
				<Typography
					component="div"
					variant="caption"
					color="textSecondary"
					className={classes.subtitle}
					title={eventName}
				>
					{eventName}
				</Typography>
			)}
		</Box>
	);
};
