export const filterMap = <T, R>(
	array: T[],
	filter: (element: T) => boolean,
	map: (element: T) => R
): R[] =>
	array.reduce((prev, ele) => {
		if (!filter(ele)) {
			return prev;
		}
		prev.push(map(ele));
		return prev;
	}, [] as R[]);
export const mapFilter = <T, R>(
	array: T[],
	map: (element: T) => R,
	filter: (element: R) => boolean
): R[] =>
	array.reduce((prev, ele) => {
		const mapped = map(ele);
		if (!filter(mapped)) {
			return prev;
		}
		prev.push(mapped);
		return prev;
	}, [] as R[]);
export const flatten = <T>(array: T[][]) =>
	array.reduce((prev, ele) => {
		prev.push(...ele);
		return prev;
	}, [] as T[]);

/** @description Returns a new array, with the element at index replaced with the given element */
export const replace = <T>(array: T[], element: T, index: number) => {
	return [
		...array.slice(0, index),
		element,
		...array.slice(index + 1, array.length)
	];
};
/** @description Returns a new array, with the element appended as the last element in the array */
export const append = <T>(array: T[], element: T) => {
	return [...array, element];
};
/** @description Returns a new array, with the element at index removed */
export const remove = <T>(array: T[], index: number) => {
	return [...array.slice(0, index), ...array.slice(index + 1, array.length)];
};

/** @description Moves the element at index 0 to the last index, and the last element to index 0 */
export const rotateRight = <T>(array: T[]) => {
	const arrayCopy = [...array];
	if (arrayCopy.length <= 0) {
		return arrayCopy;
	}
	arrayCopy.push(arrayCopy.shift() as T);
	return arrayCopy;
};

/** @description Creates an array of length defined by first arg and when the elements inside the array don't matter */
export const fillArray = <T>(length: number, map: (index: number) => T) =>
	Array.from({ length }, (_, i) => map(i));
