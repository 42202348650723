import { MarketType } from 'sports-sdk';

import { BetslipReducerSelection } from '../types';

export const getSelectionsMarkets = (selections: BetslipReducerSelection[]) => {
	const markets = selections.reduce((marketMap, s) => {
		marketMap[s.marketKey] = true;
		return marketMap;
	}, {} as Record<MarketType, true>);
	return Object.keys(markets);
};
