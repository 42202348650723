"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NicknameButton = void 0;
var core_1 = require("@material-ui/core");
var styles_1 = require("@material-ui/core/styles");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var player_1 = require("@materialbet-core/player");
var typographyColor = {
    dark: function (theme) { return theme.palette.primary.main; },
    light: function (theme) { return styles_1.fade(theme.palette.common.white, 0.74); }
};
var useStyles = core_1.makeStyles(function (theme) { return ({
    root: {
        minWidth: 0,
        padding: theme.spacing(0.75, 0)
    },
    typography: {
        color: typographyColor[theme.palette.type](theme)
    }
}); });
exports.NicknameButton = function (_a) {
    var children = _a.children;
    var classes = useStyles();
    var currency = player_1.usePlayerCurrency();
    return (React.createElement(materialbet_common_1.ButtonLink, { href: materialbet_common_1.PlayerRoutes.cashierDeposit.create({
            currency: currency
        }), variant: "text", className: classes.root },
        React.createElement(materialbet_common_1.Typography, { variant: "caption", textTransform: "none", className: classes.typography }, children)));
};
