import { useLocation } from 'react-router';
import { useEffect } from 'react';

import { usePrevious } from '../usePrevious';

/** @description Hook to that triggers callback on path change */
export const useOnPathChange = (callback: () => void) => {
	const { pathname } = useLocation();
	const prevPathname = usePrevious(pathname);

	useEffect(() => {
		if (pathname && prevPathname && pathname !== prevPathname) {
			callback();
		}
	}, [callback, pathname, prevPathname]);
};
