import { BetslipSelectionIdentity } from '../types';

// Note that the separator must be something that would never be part of the an outcome, params or marketId.
// Given that we decided to use a url pattern for those value, I dont expect to see a '<>' due to that as those are invalid URI char
export const SelectionsIdentitySeparator = '<>';
export const getBetslipSelectionIdentifierString = (
	selection: BetslipSelectionIdentity
) =>
	`e:${selection.eventId};m:${selection.marketKey};p:${selection.params};o:${selection.outcome}`;

export const getBetslipSelectionMarketIdentifierString = (
	selection: BetslipSelectionIdentity
) => `e:${selection.eventId};m:${selection.marketKey};p:${selection.params}`;

export const isSameSelection = (
	s: BetslipSelectionIdentity,
	selection: BetslipSelectionIdentity
) =>
	getBetslipSelectionIdentifierString(s) ===
	getBetslipSelectionIdentifierString(selection);

export const containsSameMarketSelection = (
	selectionsIdentity: string,
	selection: BetslipSelectionIdentity
) =>
	selectionsIdentity.includes(
		getBetslipSelectionMarketIdentifierString(selection)
	);
