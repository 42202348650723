import {
	PlaceBetStatus,
	PlaceBetV2Bet,
	PlaceBetV2Selection
} from 'sports/api/sportsbetting/api';
import * as uuid from 'uuid';

import { BetslipStore } from '../BetslipContext';
import { betslipActions } from '../betslipReducer';
import { getAcceptBetterOdds } from '../betslipSelectors';
import { getCanPlaceBet } from '../betslipSelectors/betslipSelectors';
import { BetslipMode, BetslipReducerBetslip } from '../types';
import { createMultiplePlaceBetFromBetslip } from './createMultiplePlaceBetFromBetslip';
import { createStraightPlaceBetFromBetslip } from './createStraightPlaceBetFromBetslip';
import { BetslipPlaceBetArgs } from './types';

export interface PrePlaceBetParams {
	isSubmitting: boolean;
	funds: number;
	store: BetslipStore;
	currency: string;
}
const createPayload = (betslip: BetslipReducerBetslip, mode: BetslipMode) => {
	if (mode === 'singles') {
		return createStraightPlaceBetFromBetslip(betslip);
	}
	return createMultiplePlaceBetFromBetslip(betslip);
};
export const prePlaceBet = (args: PrePlaceBetParams) => {
	const { isSubmitting, funds, store, currency } = args;
	if (isSubmitting) {
		return;
	}

	store.dispatch(betslipActions.validate({ funds }));
	const betslipState = store.getState();
	const canPlace = getCanPlaceBet(betslipState);
	if (!canPlace) {
		return;
	}
	const referenceId = uuid.v1();
	const { selections, bets } = createPayload(
		betslipState.betslip,
		betslipState.mode
	);
	const acceptBetterOdds = getAcceptBetterOdds(betslipState);
	const params: BetslipPlaceBetArgs = {
		referenceId,
		selections,
		bets,
		acceptBetterOdds,
		currency
	};
	const onError = (
		status: PlaceBetStatus,
		bets: PlaceBetV2Bet[],
		selections: PlaceBetV2Selection[]
	) => {
		store.dispatch(
			betslipActions.setPlaceBetChanges({
				bets,
				selections,
				status
			})
		);
	};
	const submit = () =>
		store.dispatch(betslipActions.submit({ betslipId: referenceId }));
	return {
		onError,
		params,
		submit,
		betslip: betslipState.betslip,
		mode: betslipState.mode
	};
};
