import { ListItemText, makeStyles, Theme } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import {
	createDynamicStyle,
	makeDynamicStyles,
	SportsRoutes
} from 'materialbet-common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { MarketType } from 'sports-sdk';
import { EventMetadata } from 'sports/api/sports';
import { EventListEntryInfo } from 'sports/components/eventList/EventListEntryInfo';
import { ConnectedPriceList } from 'sports/components/lists/PriceList';
import { LinkListItem } from 'sports/components/util/LinkListItem';
import { eventEntryMinWidth } from 'sports/config/dimensions';
import { PlayerEntity } from 'sports/schema';
import {
	getEventCompetitionKey,
	getEventKey,
	getEventStatus
} from 'sports/selectors/eventSelectors';
import { getEventSportKey } from 'sports/selectors/sports';
import { hasActivePromotion } from 'sports/utils/hasActivePromotion';
import { usePromotionStyles } from 'sports/utils/promotionStyles';
import {
	useEventControl,
	useEventMetadataPusher,
	useEventStatusPusher
} from 'sports/utils/pusherUtils';
import { eventRoute } from 'sports/utils/route';
import {
	useDispatchActiveZeroMarginEventPromotion,
	useIsZeroMarginPromotionActive
} from 'sports/utils/zeroMarginUtils';

import { EventListVariant } from './types';

export type Players = Record<string, PlayerEntity>;

export interface EventListEntryProps {
	sportKey: string;
	id: number;
	competitionKey: string;
	eventKey: string;
	marketKey?: MarketType;
	metadata?: EventMetadata;
	variant?: EventListVariant;
	disableToEventIcon?: boolean;
	disableEventTime?: boolean;
	disablePriceList?: boolean;
	outrights?: boolean;
}
const useListItemTextWrapperStyle = makeDynamicStyles({
	listItemTextWrapper: createDynamicStyle(
		{
			justifyContent: 'space-between',
			display: 'grid',
			alignItems: 'stretch',
			width: '100%'
		},
		{
			// eslint-disable-next-line @typescript-eslint/camelcase
			narrow_true: {
				gridTemplateColumns: '100%'
			},
			// eslint-disable-next-line @typescript-eslint/camelcase
			narrow_false: {
				gridTemplateColumns: '50% 50%'
			},
			// eslint-disable-next-line @typescript-eslint/camelcase
			wide_true: {
				gridTemplateColumns: '1fr 24px'
			},
			// eslint-disable-next-line @typescript-eslint/camelcase
			wide_false: {
				gridTemplateColumns: '50% 1fr 24px'
			}
		},
		({
			variant,
			disablePriceList
		}: {
			variant: EventListVariant;
			disablePriceList?: boolean;
		}) => `${variant}_${!!disablePriceList}`
	)
});
const useListItemClass = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(0.5, 1),
		minHeight: 52,
		width: 'inherit'
	},
	narrowRoot: {
		minWidth: eventEntryMinWidth
	},
	iconWrapper: {
		maxWidth: 'max-content',
		display: 'flex',
		alignItems: 'center',
		marginLeft: '3px'
	},
	icon: {
		color: theme.palette.text.secondary
	}
}));
export const useContainerStyles = makeStyles({
	priceListWrapper: {
		display: 'flex',
		alignItems: 'stretch',
		overflow: 'hidden'
	}
});
const useListItemTextClass = makeStyles((theme: Theme) => ({
	primary: {
		color: theme.palette.primary.main,
		fontSize: '1rem !important',
		lineHeight: '1.2em !important',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'baseline',
		width: '100%'
	},
	secondary: {
		fontSize: '0.9rem !important'
	}
}));

let UiEventListEntry: React.FunctionComponent<EventListEntryProps> = (
	props
) => {
	const {
		variant = 'wide',
		disableToEventIcon,
		disablePriceList,
		outrights
	} = props;
	const {
		id: eventId,
		sportKey,
		competitionKey,
		eventKey,
		marketKey
	} = props;

	const classes = useListItemClass();
	const isZeroMarginPromotion = useIsZeroMarginPromotionActive(eventId);
	const enablePromotionStyles = hasActivePromotion(
		!!isZeroMarginPromotion,
		marketKey
	);
	// need to get the promotionEvent to set the liability for the maxStake
	useDispatchActiveZeroMarginEventPromotion(eventId);
	const promotionClasses = usePromotionStyles();
	// TODO this one seems to be heavy on performance still
	const { listItemTextWrapper } = useListItemTextWrapperStyle({
		variant: disableToEventIcon ? 'narrow' : variant,
		disablePriceList
	});
	const listItemTextClass = useListItemTextClass();
	const containerClasses = useContainerStyles();
	// TODO: use outright route
	return (
		<LinkListItem
			to={
				!outrights
					? eventRoute(eventId, sportKey, competitionKey, eventKey)
					: SportsRoutes.outright.create({
							sportKey,
							eventId,
							competitionKey,
							eventKey
					  })
			}
			className={clsx(
				classes.root,
				variant === 'narrow' && classes.narrowRoot,
				enablePromotionStyles && promotionClasses.highlight
			)}
			divider={false}
			disableTouchRipple={false}
			disableGutters
			dense
			button
		>
			<ListItemText
				classes={listItemTextClass}
				primary={
					<div className={listItemTextWrapper}>
						<EventListEntryInfo {...props} />
						{!disablePriceList && (
							<div className={containerClasses.priceListWrapper}>
								{props.marketKey && (
									<ConnectedPriceList
										marketKey={props.marketKey}
										eventId={props.id}
										variant={props.variant}
									/>
								)}
							</div>
						)}
						{!disableToEventIcon && (
							<div className={classes.iconWrapper}>
								<NavigateNextIcon className={classes.icon} />
							</div>
						)}
					</div>
				}
			/>
		</LinkListItem>
	);
};
UiEventListEntry = React.memo(UiEventListEntry);
let ConnectedEventListEntry: React.FunctionComponent<Omit<
	EventListEntryProps,
	'competitionKey' | 'eventKey' | 'sportKey'
>> = (props) => {
	const { id } = props;
	useEventStatusPusher(id);
	useEventMetadataPusher(id);
	useEventControl(id);
	const eventKey = useSelector(getEventKey(id));
	const sportKey = useSelector(getEventSportKey(id));
	const status = useSelector(getEventStatus(id));
	const competitionKey = useSelector(getEventCompetitionKey(id));
	if (!sportKey || !status || !id || !eventKey || !competitionKey) {
		return null;
	}
	return (
		<UiEventListEntry
			sportKey={sportKey}
			eventKey={eventKey}
			competitionKey={competitionKey}
			id={id}
			marketKey={props.marketKey}
			variant={props.variant}
			disableToEventIcon={props.disableToEventIcon}
			disableEventTime={props.disableEventTime}
			disablePriceList={props.disablePriceList}
			outrights={props.outrights}
		/>
	);
};
ConnectedEventListEntry = React.memo(ConnectedEventListEntry);
export { UiEventListEntry, ConnectedEventListEntry };
