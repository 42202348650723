import { isDesktop } from '@gaming-shell/device';
import {
	DefaultSideMenu,
	SideMenuItemList,
	SideMenuPortal,
	SportsSideMenuPortal
} from '@gaming-shell/layout';
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Provider } from 'react-redux';
import {
	CommonSportsList,
	FeaturedSportsList,
	SportsNavigationList,
	SportsSideMenuFooter,
	useSportsList,
	VirtualSportsItem
} from 'sports/components/sideMenu';
import { LiveEventNavigationWidget } from 'sports/components/widgets/LiveEventNavigationWidget';
import { useIsLiveEventPage } from 'sports/utils/route';

import { registerSharedComponents } from './sharedComponents';
import store from './store';

export const useStyles = makeStyles({
	liveEventNavigationWidget: {
		borderRadius: 0,
		boxShadow: 'none',
		width: '100%',
		height: '100%'
	}
});

// Just put in the same file to make it closer to it wrapper
const UnprovidedSportsSideMenu: React.FC = () => {
	useSportsList(); // This will fetch the sports
	const classes = useStyles();
	const isLiveEventPage = useIsLiveEventPage();
	if (isLiveEventPage && isDesktop() && ENABLE_SPORTS_SIDEMENU_LIVE_NAV) {
		return (
			<LiveEventNavigationWidget
				className={classes.liveEventNavigationWidget}
			/>
		);
	}
	return (
		<SideMenuItemList>
			<SportsNavigationList />
			<FeaturedSportsList />
			<CommonSportsList />
			{ENABLE_VIRTUAL_SPORTS_PAGE &&
				(isDesktop() || ENABLE_VIRTUAL_SPORTS_MOBILE) && (
					<VirtualSportsItem />
				)}
			<SportsSideMenuFooter />
		</SideMenuItemList>
	);
};

registerSharedComponents();
/** @description this component will return basic side menu for the sports */
let SportsSideMenu: React.FunctionComponent = () => {
	return (
		<>
			<SportsSideMenuPortal>
				<Provider store={store}>
					<UnprovidedSportsSideMenu />
				</Provider>
			</SportsSideMenuPortal>
			<SideMenuPortal>
				<DefaultSideMenu />
			</SideMenuPortal>
		</>
	);
};

SportsSideMenu = React.memo(SportsSideMenu);
export { SportsSideMenu };
