import { isPrimaryMarket } from 'sports-sdk';
import { BetslipSelectionIdentity } from '../types';
import { useIsZeroMarginPromotionActive } from 'sports/utils/zeroMarginUtils';
export const useSelectionCanAddToBigBetslip = (
	selection: BetslipSelectionIdentity
) => {
	const { eventId, marketKey } = selection;
	const isZeroMarginActive = useIsZeroMarginPromotionActive(eventId);
	const isPrimary = isPrimaryMarket(marketKey);
	const isZeroMarginSelection = isZeroMarginActive && isPrimary;
	return !isZeroMarginSelection;
};
