import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

const Featured: React.FunctionComponent<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<path
				fill={props.fill}
				fillRule="nonzero"
				d="M17.163 3.25c.307.005.603.08.87.213l.04.02c.213.113.406.263.57.446l.107.131 2.838 3.784c.549.693.583 1.654.093 2.392l-.105.144-8.038 9.638a2.02 2.02 0 0 1-1.488.731h-.02a2.02 2.02 0 0 1-1.487-.598l-.12-.132-8.01-9.653a2.028 2.028 0 0 1-.112-2.37l.101-.139 2.879-3.798a2.02 2.02 0 0 1 .498-.47.763.763 0 0 1 .333-.178c.179-.075.37-.126.568-.148l.2-.013h10.283zM14.85 9.869h-5.7L12 17.785l2.85-7.916zm-7.295 0H3.949l6.369 7.675-2.763-7.675zm12.494 0h-3.605l-2.738 7.606 6.343-7.606zM6.009 5.578L3.896 8.369h3.12L6.01 5.578zm11.988-.022l-1.013 2.813h3.123l-2.11-2.813zM12 5.25L9.66 8.369h4.679L12 5.25zm-1.5-.501H7.307l1.036 2.878L10.5 4.749zm6.193 0h-3.194l2.158 2.877 1.036-2.877z"
			/>
		</SvgIcon>
	);
};
export default Featured;
