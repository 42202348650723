import { MarketOptions, MarketType } from 'sports-sdk';

export enum MarketView {
	CorrectScore,
	Goals,
	Handicap,
	GameLine
}

export type MarketConfigOptions = Pick<
	MarketOptions,
	Exclude<keyof MarketOptions, 'locale'>
>;

/** @description optional market and group requirements used to prevent error in old createSubmarketData function*/
export interface SportMarketConfig {
	marketType?: MarketType;
	options?: MarketConfigOptions;
	view?: MarketView;
	groups?: MarketType[];
	collapsedByDefault?: boolean;
	disableMainLine?: boolean;
}

export interface SingleMarketConfig {
	marketType: MarketType;
	options?: MarketConfigOptions;
	view?: MarketView;
	collapsedByDefault?: boolean;
	disableMainLine?: boolean;
	disableAltLines?: boolean;
}

export interface MarketGroupConfig {
	marketGroup: SingleMarketConfig[];
	options?: MarketConfigOptions;
	view: MarketView;
}

export type MarketConfig = SingleMarketConfig | MarketGroupConfig;

/** @description tab config for sport markt */
export interface TabConfiguration {
	/** @description give key to tab if neccssary */
	key?: string;
	label: string; // artifical grouping, will be tab caption
	markets: MarketConfig[];
}

/** @description layout of different type of outcome, enum key for better type control */
export enum OutcomeLayout {
	CorrectScore,
	Goals,
	Handicap,
	GoalsShorten,
	MoneyLine,
	Default
}

export interface SportMarketInfo {
	/** @descrtipiton this will be the key for the tab for tan configuration for market */
	defaultEventMarketTab?: string;
	/** @descrtipiton market config for event level, required here even some sports will only have outright*/
	eventMarketConfig: TabConfiguration[];
	/** @descrtipiton market config for competition level, would change to single one if we sure its always single one*/
	competitionMarketConfig: TabConfiguration[];
	/** @descrtipiton market config for outright */
	outrightMarketConfig?: TabConfiguration;
}
