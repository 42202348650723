import { SideMenuItem } from '@gaming-shell/layout';
import { makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	sportIcon: {
		marginRight: theme.spacing(1)
	}
}));

export const SportSideMenuItemSkeleton: React.FC<unknown> = () => {
	const { sportIcon } = useStyles();
	return (
		<SideMenuItem
			icon={
				<Skeleton
					className={sportIcon}
					height={24}
					width={24}
					variant="circle"
				/>
			}
			label={<Skeleton width="80%" />}
			href=""
			variant="subtitle2"
		/>
	);
};
