import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

const LiveCasinoTableGames: React.FunctionComponent<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<path
				fill={props.fill}
				fillRule="nonzero"
				d="M2.699 12.882l.06-.002h18.537l.039.005a.75.75 0 0 1 .222.06l.017.009a.697.697 0 0 1 .14.087.736.736 0 0 1 .28.486l.006.103v4.11a.747.747 0 0 1-.233.543l-.032.029-.066.05-4.93 3.32a.764.764 0 0 1-.07.042l-.02.01-.02.01-.022.01.008-.004.014-.006a.722.722 0 0 1-.07.027l-.012.004-.011.004-.023.006.008-.002.015-.004a.744.744 0 0 1-.1.022l-.014.002-.102.007H7.68a.756.756 0 0 1-.116-.009l-.012-.002a.76.76 0 0 1-.181-.056l-.049-.025.01.006a.75.75 0 0 1-.051-.029l-.02-.013-4.93-3.32a.764.764 0 0 1-.066-.05l-.032-.029a.793.793 0 0 1-.069-.074l-.037-.052a.737.737 0 0 1-.12-.315L2 17.74v-4.134l.001-.02v.01l.001-.022a.752.752 0 0 1 .065-.254l.02-.04a.444.444 0 0 1 .03-.052l.01-.014a.764.764 0 0 1 .208-.209l.031-.02.086-.043.022-.01a.746.746 0 0 1 .123-.036l.075-.012.01-.001h.002l.015-.001zm4.584 8.813l.032.02.007.003-.039-.023zm8.287-4.026H8.43v2.641h7.14v-2.641zM3.5 15.032v2.309l3.43 2.309v-2.329L3.5 15.032zm17 0l-3.43 2.288v2.33l3.43-2.31v-2.308zm-1.726-.652H5.225l2.682 1.79h8.186l2.681-1.79zm-16.68-1.114l-.007.014-.006.01.005-.01.008-.014zm.371-.331l-.013.007-.033.015-.051.027.049-.026.048-.023zM12 2.19a3.631 3.631 0 0 1 3.556 2.898.747.747 0 0 1 .067.5 3.63 3.63 0 0 1-1.902 3.429c1.07.289 2.064.83 2.895 1.589a.75.75 0 0 1-1.012 1.108 5.515 5.515 0 0 0-3.59-1.444 5.53 5.53 0 0 0-3.618 1.444.75.75 0 1 1-1.012-1.108 7.008 7.008 0 0 1 2.898-1.59A3.63 3.63 0 0 1 12 2.19zM9.878 5.632l-.003.036-.005.152a2.13 2.13 0 0 0 4.158.653c-.338.065-.68.098-1.022.097a5.54 5.54 0 0 1-3.128-.938zM12 3.69c-.563 0-1.074.218-1.455.574l.046.036a4.05 4.05 0 0 0 2.41.77c.32 0 .638-.037.947-.112A2.13 2.13 0 0 0 12 3.69z"
			/>
		</SvgIcon>
	);
};
export default LiveCasinoTableGames;
