import { Translate, useTranslation } from '@gaming-shell/i18n';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { EsportsRoutes, SportsRoutes } from 'materialbet-common';
import { DiscordIcon, ESportsIcon, ExternalLinkIcon } from 'materialbet-icons';
import * as React from 'react';
import { useLocation } from 'react-router';
import { SportType } from 'sports/config/sport';
import { useEsports } from 'sports/utils/sports';

import { SportSideMenuItem } from '../SportSideMenuItem';

const useStyles = makeStyles((theme: Theme) => ({
	collapsibleItem: {
		cursor: 'pointer',
		padding: theme.spacing(1, 2),
		minHeight: 48 // Align with side menu item
	},
	icon: {
		color: theme.palette.text.disabled
	},
	actionIcon: {
		color: theme.palette.text.hint
	},
	link: {
		color: 'inherit'
	}
}));
export interface EsportsListProps {
	/** @description disable collapse, will always open if its disabled */
	disableCollapsible?: boolean;
}

/** @description a component that contain only the items of esports */
let EsportsList: React.FC<EsportsListProps> = ({ disableCollapsible }) => {
	const trans = useTranslation();
	const { icon, collapsibleItem, actionIcon, link } = useStyles();
	const sports = useEsports();
	const { pathname: currentRoute } = useLocation();
	const { sportKey: routeSportKey } = SportsRoutes.sport.getPathParams(
		currentRoute
	);
	const { sportKey: routeEsportKey } = EsportsRoutes.sport.getPathParams(
		currentRoute
	);
	const defaultOpen = sports.some(
		(sportKey) => sportKey === routeSportKey || sportKey === routeEsportKey
	);
	const [open, setOpen] = React.useState(disableCollapsible || false);
	const handleToggleOpen = React.useCallback(
		() => setOpen((isOpen: boolean) => !isOpen),
		[setOpen]
	);
	React.useEffect(() => {
		if (defaultOpen) {
			setOpen(defaultOpen);
		}
	}, [defaultOpen, setOpen]);
	const esports = React.useMemo(
		() =>
			sports.map((sportKey: SportType) => (
				<SportSideMenuItem key={sportKey} sportKey={sportKey} />
			)),
		[sports]
	);
	if (!sports.length) {
		return null;
	}
	return (
		<>
			{!disableCollapsible && (
				<ListItem
					dense
					onClick={handleToggleOpen}
					className={collapsibleItem}
					aria-label={trans('subjects.sports.esports')}
				>
					<ListItemIcon className={icon}>
						<ESportsIcon />
					</ListItemIcon>
					<ListItemText
						primary={
							<Typography variant="subtitle2">
								<Translate label="subjects.sports.esports" />
							</Typography>
						}
					/>
					{open ? (
						<ExpandLess className={actionIcon} />
					) : (
						<ExpandMore className={actionIcon} />
					)}
				</ListItem>
			)}
			<Collapse in={open} timeout="auto" unmountOnExit>
				{esports}
				{DISCORD_URL && (
					<ListItem
						href={DISCORD_URL}
						component="a"
						target="_blank"
						className={link}
					>
						<ListItemIcon className={icon}>
							<DiscordIcon />
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography variant="subtitle2">
									Discord
								</Typography>
							}
						/>
						<ExternalLinkIcon className={actionIcon} />
					</ListItem>
				)}
			</Collapse>
		</>
	);
};

EsportsList = React.memo(EsportsList);
export { EsportsList };
