import { useLanguage } from '@gaming-shell/i18n';
import * as React from 'react';
import { usePrevious } from 'sports/utils/hooks';

const useLanguageChange = () => {
	const language = useLanguage();
	const prevLanguage = usePrevious(language);
	React.useEffect(() => {
		if (language && prevLanguage !== language && window.SIR) {
			window.SIR('changeLanguage', language);
		}
	}, [language, prevLanguage]);
};

export const BRWidgetSideEffect: React.FunctionComponent<unknown> = () => {
	useLanguageChange();
	return null;
};
