/** @description Includes any bit of the otherBitmask */
export const bitsIncludeSome = (bitmask: number, otherBitmask: number) =>
	(bitmask & otherBitmask) > 0;
/** @description Returns true if every given active bit is included in allowedBits */
export const bitsIncludeAll = (allowedBits: number, givenBits: number) =>
	givenBits > 0 && (allowedBits & givenBits) === givenBits;

export const subtractBits = (from: number, remove: number) => from & ~remove;

export const mapActiveBits = <T>(
	bitmask: number,
	maxBit: number,
	mapper: (bit: number) => T
) => {
	const array: T[] = [];
	if (!bitmask) {
		return array;
	}
	for (let i = 0; i <= maxBit; i++) {
		const bit = bitmask & (1 << i);
		if (bit > 0) {
			array.push(mapper(bit));
		}
	}
	return array;
};
