import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

const Slots: React.FunctionComponent<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<path
				fill={props.fill}
				fillRule="nonzero"
				d="M10.15 2c3.213 0 5.938 1.543 6.267 3.7h.313c.867 0 1.57.703 1.57 1.57v2.519a1.98 1.98 0 1 1 2.45 2.822v3.712a3.221 3.221 0 0 1-2.449 3.114l-.001.993A1.57 1.57 0 0 1 16.73 22H3.57A1.57 1.57 0 0 1 2 20.43V7.27c0-.867.703-1.57 1.57-1.57h.313C4.213 3.542 6.937 2 10.15 2zm6.65 13.83H3.5v4.6c0 .039.031.07.07.07h13.16a.07.07 0 0 0 .07-.07v-1.479a.752.752 0 0 1 0-.342V15.83zm-9.12 2.2a.75.75 0 0 1 .102 1.493l-.102.007H5.22a.75.75 0 0 1-.102-1.493l.102-.007h2.46zm10.62-6.279l.001 6.097a1.72 1.72 0 0 0 .942-1.376l.007-.152v-3.725a1.988 1.988 0 0 1-.95-.844zM6.93 9.67H3.5v4.661h3.43V9.669zm4.939 0H8.43v4.661h3.439V9.669zm4.931 0h-3.431v4.661H16.8V9.669zM5.22 10.94h.005a1.047 1.047 0 0 1 1.045 1.017V12.042a1.05 1.05 0 0 1-.93 1.011l-.12.007h.001H5.212c-.538 0-.988-.413-1.046-.945L4.16 12c0-.546.413-.996.945-1.054l.114-.007-.002.001h.003zm4.93 0a1.06 1.06 0 0 1 1.06 1.048V12a1.06 1.06 0 0 1-.94 1.053l-.12.007A1.06 1.06 0 0 1 9.09 12v-.013l.006-.102a1.06 1.06 0 0 1 .939-.939l.115-.006zm4.93 0a1.06 1.06 0 0 1 1.06 1.048V12c0 .546-.413.996-.945 1.054l-.115.006A1.06 1.06 0 0 1 14.02 12v-.013l.006-.102a1.06 1.06 0 0 1 1.054-.945zm4.94-.65a.48.48 0 1 0 0 .96.48.48 0 0 0 0-.96zM16.73 7.2H3.57a.07.07 0 0 0-.07.07v.899h13.3V7.27a.07.07 0 0 0-.07-.07zm-6.58-3.7c-2.41 0-4.336 1.016-4.727 2.2h9.454c-.391-1.184-2.316-2.2-4.727-2.2z"
			/>
		</SvgIcon>
	);
};
export default Slots;
