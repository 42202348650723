import { Translate } from '@gaming-shell/i18n';
import { Theme } from '@gaming-shell/theming';
import {
	Button,
	CircularProgress,
	Grid,
	makeStyles,
	Typography
} from '@material-ui/core';
import { AuthRoutes } from 'materialbet-common';
import * as React from 'react';
import { AddToBetlipIcon } from 'sports/components/icons/betslip/AddToBetlipIcon';
import { BetslipIcon } from 'sports/components/icons/betslip/BetslipIcon';
import { BackspaceIcon } from 'sports/components/icons/common/BackspaceIcon';
import { RedirectLoggedOutUserButton } from 'sports/components/util/RedirectLoggedOutUserButton';

import { QuickBetslipVariant } from '../types';

export interface QuickBetslipActionButtonsProps {
	onDelete: () => void;
	showDialpad: boolean;
	disabled?: boolean;
	variant: QuickBetslipVariant;
	onConfirmClick: () => void;
	onSecondActionClick: () => void;
	isSubmitting: boolean;
	disableConfirmButton?: boolean;
	disableSecondaryButton?: boolean;
}

const useButtonStyles = makeStyles((theme: Theme) => ({
	placeBet: {
		minHeight: (showDialpad: boolean) => (showDialpad ? '100%' : '64px'),
		background: theme.palette.secondary.main,
		lineHeight: 1.25, // default was 1.75
		'&:hover': {
			background: theme.palette.secondary.main
		}
	},
	button: {
		minHeight: '64px',
		background: theme.palette.background.top.main,
		'&:hover': {
			background: theme.palette.background.top.main
		}
	}
}));
const useBetslipButtonStyles = makeStyles({
	label: { flexDirection: 'column' }
});
const useContainerStyles = makeStyles(() => ({
	root: {
		height: '100%',
		alignContent: 'flex-start'
	},
	disableMaxWidth: {
		maxWidth: (showDialpad: boolean) => (showDialpad ? 'unset' : undefined)
	}
}));
const lineHeightStyle = { lineHeight: 1 };

export const QuickBetslipActionButtons: React.FunctionComponent<QuickBetslipActionButtonsProps> = (
	props
) => {
	// TODO handle place bet
	const {
		showDialpad,
		onDelete,
		disabled,
		onConfirmClick,
		onSecondActionClick,
		variant,
		isSubmitting,
		disableConfirmButton = disabled,
		disableSecondaryButton = disabled
	} = props;
	const containerClasses = useContainerStyles(showDialpad);
	const betslipButtonClasses = useBetslipButtonStyles();
	const buttonClasses = useButtonStyles(showDialpad);
	const secondaryActionLabel =
		variant === 'placeBet' ? 'betting.betslip' : 'betting.toAll';
	const WrappedSecondaryActionIcon = ENABLE_NEW_ICON
		? AddToBetlipIcon
		: BetslipIcon;
	const secondaryActionIcon =
		variant === 'placeBet' ? <WrappedSecondaryActionIcon /> : null;
	return (
		<Grid
			container
			spacing={showDialpad ? 0 : 1}
			direction={showDialpad ? 'column' : 'row'}
			className={containerClasses.root}
			wrap="nowrap"
		>
			<Grid item xs={3} className={containerClasses.disableMaxWidth}>
				<Button
					size="large"
					className={buttonClasses.button}
					fullWidth={true}
					variant="contained"
					onClick={onDelete}
					disabled={disabled}
				>
					<Typography
						style={lineHeightStyle}
						color={disabled ? 'inherit' : 'textPrimary'}
					>
						<BackspaceIcon />
					</Typography>
				</Button>
			</Grid>
			{variant !== 'editAllStakes' && (
				<Grid item xs={3} className={containerClasses.disableMaxWidth}>
					<Button
						fullWidth
						className={buttonClasses.button}
						variant="contained"
						disabled={disabled || disableSecondaryButton}
						classes={betslipButtonClasses}
						onClick={onSecondActionClick}
					>
						<Typography
							variant="caption"
							color={
								disabled || disableSecondaryButton
									? 'inherit'
									: 'textPrimary'
							}
						>
							{secondaryActionIcon}
							{!secondaryActionIcon && (
								<Translate
									label={secondaryActionLabel}
									ns="sports"
								/>
							)}
						</Typography>
					</Button>
				</Grid>
			)}

			{variant === 'placeBet' ? (
				<Grid item xs={6} className={containerClasses.disableMaxWidth}>
					<RedirectLoggedOutUserButton
						to={AuthRoutes.signIn.create()}
						onClick={onConfirmClick}
						className={buttonClasses.placeBet}
						variant="contained"
						size="large"
						fullWidth={true}
						disabled={disabled || disableConfirmButton}
					>
						{isSubmitting ? (
							<CircularProgress variant="indeterminate" />
						) : (
							<Translate
								label="descriptions.betPlaced"
								ns="sports"
							/>
						)}
					</RedirectLoggedOutUserButton>
				</Grid>
			) : (
				<Grid
					item
					xs={variant === 'editAllStakes' ? 9 : 6}
					className={containerClasses.disableMaxWidth}
				>
					<Button
						fullWidth
						className={buttonClasses.placeBet}
						variant="contained"
						disabled={disabled}
						classes={betslipButtonClasses}
						onClick={onConfirmClick}
					>
						<Translate label="betting.confirmStake" ns="sports" />
					</Button>
				</Grid>
			)}
		</Grid>
	);
};
