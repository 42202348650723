import { useTranslation } from '@gaming-shell/i18n';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';

export interface ConfirmationDialogProps extends DialogProps {
	title: string;
	cancelLabel?: React.ReactNode;
	confirmLabel?: React.ReactNode;
	confirmLabelTranslationKey?: string;
	cancelLabelTranslationKey?: string;
	onCancel?: (event: React.MouseEvent) => void;
	onConfirm?: (event: React.MouseEvent) => void;
}

const useStyles = makeStyles({
	root: {
		'MuiPaper-root': {
			maxWidth: 400
		}
	}
});

export const ConfirmationDialog: React.FunctionComponent<ConfirmationDialogProps> = ({
	title,
	confirmLabelTranslationKey = 'common:descriptions.yes',
	cancelLabelTranslationKey = 'common:descriptions.no',
	confirmLabel,
	cancelLabel,
	children,
	onCancel,
	onConfirm,
	classes = {},
	...otherProps
}) => {
	const trans = useTranslation();
	const className = useStyles();

	return (
		<Dialog className={className.root} {...otherProps}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>{children}</DialogContent>
			<DialogActions>
				<Button onClick={onConfirm} color="primary">
					{confirmLabel
						? confirmLabel
						: trans(confirmLabelTranslationKey)}
				</Button>
				<Button onClick={onCancel} color="primary">
					{cancelLabel
						? cancelLabel
						: trans(cancelLabelTranslationKey)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
