"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BalanceDetails = void 0;
var core_1 = require("@material-ui/core");
var betterAppBar_1 = require("../..");
var React = __importStar(require("react"));
var components_1 = require("./components");
var useStyles = core_1.makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        padding: theme.spacing(0.5, 1.5)
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        margin: theme.spacing(1, 0.5),
        width: '100%',
        '& > *': {
            flex: 1
        }
    }
}); });
var UnForwardedBalanceDetails = function (_, ref) {
    var classes = useStyles();
    var isBonusAvailable = betterAppBar_1.useIsBonusAvailable();
    return (React.createElement("div", { className: classes.root, ref: ref },
        React.createElement(components_1.AccountBalance, { className: classes.item }),
        isBonusAvailable && React.createElement(components_1.ActiveBonus, { className: classes.item })));
};
exports.BalanceDetails = React.forwardRef(UnForwardedBalanceDetails);
