import { isMobile } from '@gaming-shell/device';
import { Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { createDynamicStyle, makeDynamicStyles } from 'materialbet-common';
import * as React from 'react';
import { PaperTitle } from 'sports/components/displays/PaperTitle';
import { CustomPaper } from 'sports/components/materialWrapper/CustomPaper';
import { PaperVariant } from 'sports/components/materialWrapper/types';
import { deferComponentRender } from 'sports/hocs/deferComponentRender';
import { useTimeZone } from 'sports/utils/hooks';

import { EventGroupDivider } from '../EventGroupDivider';
import { EventGroups } from '../EventGroups';
import { EventListCompetitionHeader } from '../EventListCompetitionHeader';
import { EventListDataProps, EventListFormatProps, Group } from '../types';

export interface EventListCompetitionProps
	extends EventListDataProps,
		EventListFormatProps {
	group: Group;
	defaultCollapsed?: boolean;
}

export const useVariantStyles = makeDynamicStyles({
	card: createDynamicStyle(
		(theme) => ({
			background: isMobile()
				? 'transparent'
				: theme.palette.background.paper
		}),
		{
			// is flat
			true: (theme) => ({
				marginBottom: theme.spacing(1)
			}),
			false: {
				marginBottom: 0
			}
		},
		({ paperVariant }: { paperVariant?: PaperVariant }) =>
			`${paperVariant !== 'flat' ? true : false}`
	)
});

export const useStyles = makeStyles((theme: Theme) => ({
	horizontalCard: {
		display: 'flex',
		overflow: 'visible',
		background: theme.palette.background.paper
	}
}));

export const EventListCompetition: React.FunctionComponent<EventListCompetitionProps> = (
	props
) => {
	const {
		group,
		sportKey,
		disableCollapsible,
		marketKey,
		defaultCollapsed,
		disableSportIcon,
		disableToEventIcon,
		disableCompetitionHeader,
		disableMarketHeader,
		disableMarketHeaderDate,
		disablePriceList,
		variant,
		paperVariant,
		paperTitleVariant,
		competitionTitleVariant,
		competitionTitleTextVariant,
		live,
		disableCompetitionIcon,
		withCompetitionDivider,
		disableEventTime,
		outrights,
		orientation = 'vertical'
	} = props;
	const [isExpanded, toggleExpansion] = React.useState(!defaultCollapsed);
	const toggle = () => toggleExpansion(!isExpanded);
	const customPaperVariant =
		paperVariant || (variant === 'narrow' ? 'flat' : undefined);
	const { card } = useVariantStyles({ paperVariant: customPaperVariant });
	const { horizontalCard } = useStyles();
	// remove border radius, they are on the same canvas and the border radius will give a slight padding which we don't want
	const squarePaper = customPaperVariant === 'flat';
	// TODO: Use props to pass in
	const timeZone = useTimeZone();
	return (
		<CustomPaper
			variant={customPaperVariant}
			className={clsx(
				card,
				orientation === 'horizontal' && horizontalCard
			)}
			square={squarePaper}
		>
			{!disableCompetitionHeader && (
				<PaperTitle
					open={isExpanded}
					label={
						<EventListCompetitionHeader
							sportsKey={sportKey}
							enableIcon={!disableCompetitionIcon}
							competitionKey={group.id}
							disableSportIcon={disableSportIcon}
							variant={competitionTitleVariant}
							textVariant={competitionTitleTextVariant}
						/>
					}
					disableCollapsible={disableCollapsible}
					onOpenChange={toggle}
					variant={paperTitleVariant}
					additional={
						<>
							{props.showEventCount && (
								<Typography
									variant={
										competitionTitleTextVariant ||
										(competitionTitleVariant === 'primary'
											? 'subtitle2'
											: 'caption')
									}
									color="secondary"
								>
									+{group.items.length}
								</Typography>
							)}
						</>
					}
					additionalPosition="right"
				/>
			)}
			{isExpanded ? (
				<>
					<EventGroups
						eventIds={group.items}
						marketKey={marketKey}
						variant={variant}
						disableToEventIcon={disableToEventIcon}
						disableFirstEventDivider={
							paperTitleVariant === 'contained-small'
						}
						disableEventTime={disableEventTime}
						disableMarketHeader={disableMarketHeader}
						disableMarketHeaderDate={disableMarketHeaderDate}
						disablePriceList={disablePriceList}
						live={live}
						timeZone={timeZone}
						outrights={outrights}
						orientation={orientation}
					/>
					{withCompetitionDivider && orientation === 'vertical' && (
						<Divider />
					)}
					{withCompetitionDivider && orientation === 'horizontal' && (
						<EventGroupDivider orientation="vertical" />
					)}
				</>
			) : null}
		</CustomPaper>
	);
};
export const DeferredEventListCompetition = deferComponentRender()(
	EventListCompetition
);
