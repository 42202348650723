"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BetCount = exports.useSetPendingBets = exports.usePendingBets = exports.BetCountProvider = void 0;
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var BetCountIcon_1 = require("./BetCountIcon");
var materialbet_common_1 = require("materialbet-common");
var betCountContextDefaultValue = {
    setPendingBets: function () { return void 0; }
};
var BetCountContext = React.createContext(betCountContextDefaultValue);
exports.BetCountProvider = function (_a) {
    var children = _a.children;
    var _b = React.useState(), pendingBets = _b[0], setPendingBets = _b[1];
    var value = { pendingBets: pendingBets, setPendingBets: setPendingBets };
    return (React.createElement(BetCountContext.Provider, { value: value }, children));
};
exports.usePendingBets = function () {
    return React.useContext(BetCountContext).pendingBets;
};
exports.useSetPendingBets = function () {
    return React.useContext(BetCountContext).setPendingBets;
};
var useStyles = core_1.makeStyles(function (theme) { return ({
    icon: {
        fill: theme.palette.primary.contrastText,
        fontSize: '2rem'
    },
    count: {
        position: 'absolute',
        fontSize: '0.9rem'
    }
}); });
exports.BetCount = function () {
    var count = exports.usePendingBets();
    var classes = useStyles();
    var Icon = count && count > 9 ? BetCountIcon_1.BetCountRotatedIcon : BetCountIcon_1.BetCountIcon;
    var viewBox = count && count > 9 ? '0 0 32 24' : '0 0 24 24';
    return (React.createElement(core_1.IconButton, { component: react_router_dom_1.Link, to: materialbet_common_1.SportsRoutes.bets.create() },
        React.createElement(Icon, { className: classes.icon, fontSize: "default", viewBox: viewBox }),
        React.createElement(core_1.Typography, { variant: "subtitle1", color: "primary", className: classes.count }, count !== undefined && count)));
};
