import { TFunction } from 'i18next';
import { PlayerEntity } from 'sports/schema';

export type Players = Record<string, PlayerEntity>;
export type PlayerPosition = 'starting-pitcher';
export interface StartingPitchers {
	home: string;
	away: string;
}
/**@description Returns first player for given team and position key given players object*/
export const getPlayerName = (
	players: Players | undefined,
	team: 'HOME' | 'AWAY',
	positionKey: string
) => {
	if (!players) {
		return undefined;
	}
	const player = Object.values(players).find(
		(p) => p.team === team && p.position?.key === positionKey
	);
	return player?.name;
};
/** @descriptions gets the starting pitchers for home and away team, returns undefined if name or player is not found */
export const findStartingPitchers = (players: Players) =>
	Object.values(players).reduce<Partial<StartingPitchers>>(
		(acc, player) => {
			if (player.position?.key !== 'starting-pitcher' || !player.name) {
				return acc;
			}
			if (player.team === 'HOME') {
				acc.home = player.name;
				return acc;
			}
			if (player.team === 'AWAY') {
				acc.away = player.name;
			}
			return acc;
		},
		{ home: undefined, away: undefined }
	);
/** @descriptions gets the starting pitchers for home and away team, returns 'N/A' if name or player is not found */
export const getStartingPitchers = (players: Players) => {
	const { home = 'N/A', away = 'N/A' } = findStartingPitchers(players);
	return { home, away };
};

export const getPlayersMustStartStrings = (
	t: TFunction,
	variables: Record<string, string | undefined>,
	teams: { home: string; away: string }
) => {
	const homePitcher = variables['home_pitcher'] || 'N/A';
	const awayPitcher = variables['away_pitcher'] || 'N/A';
	const home = t('sports:betting.playerMustStartForTeam', {
		player: homePitcher,
		team: teams.home
	});
	const away = t('sports:betting.playerMustStartForTeam', {
		player: awayPitcher,
		team: teams.away
	});
	return [home, away];
};
