"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createQueryParam = exports.queryParam = void 0;
var indicators_1 = require("../indicators");
exports.queryParam = function (queryParam) {
    return ({ queryParam: queryParam });
};
exports.createQueryParam = function (param) {
    return "" + indicators_1.ParamIndicators.QUERY + param;
};
