"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setNotificationsSeenTimestamp = exports.getNotificationsSeenTimestamp = void 0;
var NOTIFICATIONS_SEEN_LOCAL_STORAGE_TIMESTAMP_KEY = 'notificationsSeenTimestamp';
exports.getNotificationsSeenTimestamp = function () {
    var localStorageTimestamp = localStorage.getItem(NOTIFICATIONS_SEEN_LOCAL_STORAGE_TIMESTAMP_KEY) ||
        '0';
    var timestamp = Number.parseInt(localStorageTimestamp);
    return timestamp;
};
exports.setNotificationsSeenTimestamp = function (timestamp) {
    localStorage.setItem(NOTIFICATIONS_SEEN_LOCAL_STORAGE_TIMESTAMP_KEY, String(timestamp));
};
