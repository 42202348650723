import { FreespinPromotionCard } from 'casino/components/FreespinPromotionCard';
import { loads } from 'casino/modules/freespinsModule/freespinsModule';
import { getLoadedOnce } from 'casino/selectors/loadingSelectors';
import { getFreespins } from 'casino/selectors/metaSelectors';
import { useFreeSpinsParams } from 'casino/util/freespinsUtils';
import { withHook } from 'materialbet-common';
import * as React from 'react';
import { useSelector, useDispatch, Provider } from 'react-redux';
import { actions as freespinsActions } from 'casino/modules/freespinsModule';
import { casinoStore } from 'casino/store/store';
export interface FreespinsWidgetUiProps {
	freespinIds: string[];
	loaded: boolean;
}
const FreespinsWidgetUi: React.FC<FreespinsWidgetUiProps> = ({
	freespinIds,
	loaded
}) => {
	if (!loaded || !freespinIds.length) {
		return null;
	}
	const freespins = freespinIds.map((id) => (
		<FreespinPromotionCard key={id} id={id} />
	));
	return <>{freespins}</>;
};
const useFreespinsWidgetUiProps = (): FreespinsWidgetUiProps => {
	const params = useFreeSpinsParams();
	const freespinIds = useSelector(getFreespins(params)) || [];
	const loaded = useSelector(getLoadedOnce(loads.freespins(params)));
	const dispatch = useDispatch();
	const { playerUuid } = params;
	React.useEffect(() => {
		dispatch(freespinsActions.fetchFreespins({ playerUuid }));
	}, [dispatch, playerUuid]);
	return { freespinIds, loaded };
};

export const FreespinsWidget = withHook(useFreespinsWidgetUiProps)(
	FreespinsWidgetUi
);
export const FreespinsExternalWidget: React.FC = () => (
	<Provider store={casinoStore}>
		<FreespinsWidget />
	</Provider>
);
