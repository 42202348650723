"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTranslation = void 0;
var react_i18next_1 = require("react-i18next");
var config_1 = require("./config");
var tFunction_1 = require("./tFunction");
exports.useTranslation = function (ns, options) {
    var config = config_1.getConfig();
    var enabled = config_1.isEnabled();
    var t = react_i18next_1.useTranslation(ns, options);
    if (!enabled || !config) {
        return t;
    }
    var marshalledT = tFunction_1.generateTFunction(t.t, config);
    var response = [marshalledT, t.i18n, t.ready];
    response.t = marshalledT;
    response.i18n = t.i18n;
    response.ready = t.ready;
    return response;
};
