import * as React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { SportIcon } from 'materialbet-icons';
import { SportNameTranslation } from 'sports/components/displays/SportNameTranslation';

export interface SearchResultsHeaderProps {
	sportKey: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex'
	},
	icon: {
		marginRight: theme.spacing(1),
		width: theme.spacing(2),
		height: theme.spacing(2)
	},
	text: {
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: theme.typography.caption.fontSize
	}
}));

export const SearchResultsHeader: React.FC<SearchResultsHeaderProps> = ({
	sportKey
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<SportIcon sportKey={sportKey} className={classes.icon} />
			<Typography color="primary" className={classes.text}>
				<SportNameTranslation sportKey={sportKey} />
			</Typography>
		</div>
	);
};
