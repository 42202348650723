import { usePlayerCurrency } from '@materialbet-core/player';
import * as React from 'react';
import {
	trackOnBetPlaced,
	trackOnBetslipClose,
	trackOpenQuickBetslip
} from 'sports/utils/trackingUtils';

import { BetslipContext } from '../BetslipContext';
import { betslipActions } from '../betslipReducer';
import {
	getBetslipSelectionsIdentityString,
	getIsSubmitting,
	getSelection,
	getStraightsTotalStake
} from '../betslipSelectors/betslipSelectors';
import { useAddToBigBetslip } from '../BigBetslip';
import { useBetslipSelector } from '../hooks';
import { useBetslipDispatch } from '../hooks/useBetslipDispatch';
import { useBetslipPlaceBet } from '../placeBet/useBetslipPlaceBet';
import { BetslipReducerSelection, PlaceBetCallbackArgs } from '../types';
import { QuickBetslipBetPlacedSnackbar } from './QuickBetslipBetPlacedSnackbar';
import { QuickBetslipContainer } from './QuickBetslipContainer';

interface QuickBetslipControlContextValue {
	openQuickBetslip: (
		selection: BetslipReducerSelection,
		anchorEl: Element | null
	) => void;
	closeQuickBetslip: () => void;
	selectionIsInQuickBetslip: (selection: string) => boolean;
	onAddToBigBetslip: () => void;
}
const defaultQuickBetslipValue = {
	open: false,
	anchorEl: null
};
const defaultQuickBetslipControlValue = {
	openQuickBetslip: () => void undefined,
	closeQuickBetslip: () => void undefined,
	selectionIsInQuickBetslip: () => false,
	onBetPlaced: () => void undefined,
	onAddToBigBetslip: () => void undefined
};
const QuickBetslipControlContext = React.createContext<
	QuickBetslipControlContextValue
>(defaultQuickBetslipControlValue);

export const useOpenQuickbetslip = () => {
	const { openQuickBetslip } = React.useContext(QuickBetslipControlContext);
	return openQuickBetslip;
};
export const useCloseQuickBetslip = () => {
	const { closeQuickBetslip } = React.useContext(QuickBetslipControlContext);
	return closeQuickBetslip;
};

export const useOnAddToBigBetslip = () => {
	const { onAddToBigBetslip } = React.useContext(QuickBetslipControlContext);
	return onAddToBigBetslip;
};
export const useSelectionIsInQuickBetslip = (selection: string) => {
	const { selectionIsInQuickBetslip } = React.useContext(
		QuickBetslipControlContext
	);
	return selectionIsInQuickBetslip(selection);
};
let QuickBetslipProvider: React.FunctionComponent = ({ children }) => {
	const [betPlacedSnackbar, setBetPlacedSnackbar] = React.useState<
		(PlaceBetCallbackArgs & { anchorEl: Element | null }) | null
	>(null);
	const [anchorEl, setAnchorEl] = React.useState<Element | null>(
		defaultQuickBetslipValue.anchorEl
	);
	const selectionsIdentity = useBetslipSelector(
		getBetslipSelectionsIdentityString
	);
	// if identity is an empty string, we dont have any selections thus we dont show the quickbetslip
	const open = !!selectionsIdentity;
	const isSubmitting = useBetslipSelector(getIsSubmitting);
	const { store } = React.useContext(BetslipContext);
	const dispatch = useBetslipDispatch();
	const addToBigBetslip = useAddToBigBetslip();
	const openQuickBetslip = React.useCallback(
		(selection: BetslipReducerSelection, anchor: Element | null) => {
			if (open) {
				return;
			}
			setAnchorEl(anchor);
			dispatch(betslipActions.addSelection({ selection }));
			trackOpenQuickBetslip();
		},
		[open, dispatch, setAnchorEl]
	);
	const playerCurrency = usePlayerCurrency();
	const closeQuickBetslip = React.useCallback(() => {
		if (!open || isSubmitting) {
			return;
		}
		setAnchorEl(null);
		dispatch(betslipActions.clear());
		trackOnBetslipClose();
	}, [open, isSubmitting, setAnchorEl, dispatch]);

	const onBetPlaced = React.useCallback(
		(args: PlaceBetCallbackArgs) => {
			const savedAnchorEl = anchorEl;
			const state = store.getState();
			// we need to track betplaced before betslip closed - otherwise we wont know with which betslip the bet was placed
			trackOnBetPlaced({
				value: getStraightsTotalStake(state),
				currency: playerCurrency
			});
			closeQuickBetslip();
			setBetPlacedSnackbar({ ...args, anchorEl: savedAnchorEl });
		},
		[closeQuickBetslip, anchorEl, playerCurrency, store]
	);

	// Only close it when add bet to betslip
	const onAddToBigBetslip = React.useCallback(() => {
		const selection = getSelection(store.getState(), { selectionIndex: 0 });
		closeQuickBetslip();
		addToBigBetslip(selection);
	}, [store, closeQuickBetslip, addToBigBetslip]);
	const selectionIsInQuickBetslip = React.useCallback(
		// we expect only one selection so it should be equal
		(selection: string) => selectionsIdentity === selection,
		[selectionsIdentity]
	);
	const controlValue = React.useMemo(
		() => ({
			openQuickBetslip,
			closeQuickBetslip,
			onBetPlaced,
			onAddToBigBetslip,
			selectionIsInQuickBetslip
		}),
		[
			openQuickBetslip,
			closeQuickBetslip,
			onBetPlaced,
			onAddToBigBetslip,
			selectionIsInQuickBetslip
		]
	);

	const placeBet = useBetslipPlaceBet();
	const onPlaceBetClick = () => {
		dispatch(betslipActions.applyChanges());
		placeBet(onBetPlaced);
	};
	const onSnackbarClose = () => setBetPlacedSnackbar(null);
	return (
		<QuickBetslipControlContext.Provider value={controlValue}>
			{children}
			<QuickBetslipContainer
				open={open}
				anchorEl={anchorEl}
				onClose={closeQuickBetslip}
				onPlaceBetClick={onPlaceBetClick}
			/>
			<QuickBetslipBetPlacedSnackbar
				betslip={betPlacedSnackbar}
				openQuickBetslip={openQuickBetslip}
				onClose={onSnackbarClose}
				closeBetslip={closeQuickBetslip}
			/>
		</QuickBetslipControlContext.Provider>
	);
};
QuickBetslipProvider = React.memo(QuickBetslipProvider);
export { QuickBetslipProvider };
