import hoistNonReactStatic from 'hoist-non-react-statics';
import * as React from 'react';

// types are copied from @types/react-redux

export type Matching<InjectedProps, DecorationTargetProps> = {
	[P in keyof DecorationTargetProps]: P extends keyof InjectedProps
		? InjectedProps[P] extends DecorationTargetProps[P]
			? DecorationTargetProps[P]
			: InjectedProps[P]
		: DecorationTargetProps[P];
};

type GetProps<C> = C extends React.ComponentType<infer P> ? P : never;
/** @descriptions Run a hook and inject its return value into the component */
export const withHook = <THookProps = {}, TOwnProps = {}>(
	useHook: (props: TOwnProps) => THookProps
) => <C extends React.ComponentType<Matching<THookProps, GetProps<C>>>>(
	Component: C
) =>
	hoistNonReactStatic(
		React.memo((props: TOwnProps & Omit<GetProps<C>, keyof THookProps>) => {
			const hookProps = useHook(props);
			const allProps = {
				...props,
				...hookProps
			} as GetProps<C>;
			return React.createElement(Component, allProps);
		}),
		Component
	);
