import * as React from 'react';
import { MarketType } from 'sports-sdk/sports-core/sports-core-types';
import { isSameDay } from 'sports/utils/date';

import { ConnectedEventListEntry } from '../Event';
import { EventGroupDivider } from '../EventGroupDivider';
import { EventListHeadRow } from '../EventListHeadRow';
import { EventEntryFormatProps, EventListVariant } from '../types';

export interface EventEntry extends EventEntryFormatProps {
	marketKey?: MarketType;
	variant?: EventListVariant;
	eventId: number;
	startTime: string;
	previousStartTime?: string;
	disableEventDivider?: boolean;
	timeZone: string;
}

export const getEventEntry = (props: EventEntry): React.ReactNode[] => {
	const {
		eventId,
		startTime,
		previousStartTime,
		disableEventDivider,
		disableMarketHeader,
		disableMarketHeaderDate,
		marketKey,
		variant,
		disableEventTime,
		disableToEventIcon,
		disablePriceList,
		timeZone,
		outrights,
		orientation = 'vertical'
	} = props;
	const entry: React.ReactNode[] = [];
	const currentDate = new Date(startTime);
	const isDifferentDayThanBefore =
		!previousStartTime ||
		!isSameDay(new Date(previousStartTime), new Date(startTime), timeZone);
	if (
		((disableMarketHeader && orientation === 'vertical') ||
			!isDifferentDayThanBefore) &&
		!disableEventDivider
	) {
		entry.push(
			<EventGroupDivider
				key={`divider-${eventId}`}
				orientation={
					orientation === 'vertical' ? 'horizontal' : 'vertical'
				}
			/>
		);
	}
	if (!disableMarketHeader && isDifferentDayThanBefore) {
		const header = (
			<EventListHeadRow
				key={`date-header-${currentDate.getFullYear()}-${currentDate.getMonth()}-${currentDate.getDate()}`}
				date={currentDate}
				marketKey={marketKey}
				disableEventTime={disableEventTime || disableMarketHeaderDate}
				disableTrailingSpace={
					variant === 'narrow' || disableToEventIcon
				}
			/>
		);
		entry.push(header);
	}
	const event = (
		<ConnectedEventListEntry
			key={eventId}
			id={eventId}
			marketKey={marketKey}
			variant={variant}
			disableToEventIcon={disableToEventIcon}
			disableEventTime={disableEventTime}
			disablePriceList={disablePriceList}
			outrights={outrights}
		/>
	);
	entry.push(event);
	return entry;
};
