"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIframeStyles = void 0;
var styles_1 = require("@material-ui/core/styles");
var React = __importStar(require("react"));
var ratio_1 = require("../../configs/ratio");
var dimensionsUtils_1 = require("../../utils/dimensionsUtils");
var iframeUtils_1 = require("../../utils/iframeUtils");
var useDimensionsStyles = styles_1.makeStyles({
    root: function (_a) {
        var height = _a.height, width = _a.width;
        return ({
            width: width,
            height: height
        });
    }
});
exports.useIframeStyles = function (_a) {
    var ref = _a.ref, // The widget ref
    _b = _a.ratio, // The widget ref
    ratio = _b === void 0 ? ratio_1.defaultIframeRatio : _b, fullscreen = _a.fullscreen, cinema = _a.cinema;
    // Get Elements
    var area = ref === null || ref === void 0 ? void 0 : ref.current;
    var parent = area === null || area === void 0 ? void 0 : area.parentElement;
    var _c = dimensionsUtils_1.useElementDimensionsResize(parent), parentWidth = _c.width, parentHeight = _c.height;
    var _d = React.useState('normal'), triggerChangeMode = _d[1];
    // Hooks For setting cinema
    React.useEffect(function () {
        triggerChangeMode(fullscreen ? 'fullscreen' : cinema ? 'cinema' : 'normal');
    }, [cinema, fullscreen]);
    var iframeDimensions = React.useMemo(function () {
        return iframeUtils_1.getIframeDimensions({
            availableHeight: fullscreen ? window.innerHeight : parentHeight,
            availableWidth: fullscreen ? window.innerWidth : parentWidth,
            ratio: ratio
        });
    }, [ratio, fullscreen, parentHeight, parentWidth]);
    return useDimensionsStyles(iframeDimensions);
};
