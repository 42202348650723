import { isMobile } from '@gaming-shell/device';
import { Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

/** Component used to display QR image */
export interface QrImageProps {
	alt: string;
	src: string;
	className?: string;
}

const defaultQrImageSize = 150;
const useStyles = makeStyles((theme: Theme) => ({
	img: {
		width: isMobile() ? '100%' : defaultQrImageSize,
		height: isMobile() ? 'auto' : defaultQrImageSize,
		backgroundColor: theme.palette.common.white
	}
}));

// Don't test as there's nothing to test here
/* istanbul ignore file */
export const QrImage: React.FunctionComponent<QrImageProps> = ({
	alt,
	src,
	className
}) => {
	const styles = useStyles();

	return (
		<img
			src={src}
			alt={alt}
			className={clsx(styles.img, className)}
			role="img"
		/>
	);
};
