import { Translate } from '@gaming-shell/i18n';
import * as React from 'react';

import { BigBetslipActionPanelButton } from './BigBetslipActionPanelButton';

export interface BigBetslipAddStakeToAllButtonProps {
	onClick: () => void;
}
export const BigBetslipAddStakeToAllButton: React.FunctionComponent<
	BigBetslipAddStakeToAllButtonProps
> = (props) => {
	const { onClick } = props;
	return (
		<BigBetslipActionPanelButton color="primary" onClick={onClick}>
			<Translate label="betting.addStakeToAll" ns="sports" />
		</BigBetslipActionPanelButton>
	);
};
