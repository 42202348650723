import { useTranslation } from '@gaming-shell/i18n';
import {
	getRolloverBonusAmount,
	getRolloverBonusCasinoCurrency,
	getRolloverBonusCurrentPlaythroughAmount,
	getRolloverBonusExpiresAt,
	getRolloverBonusPlaythroughProgressPercentage,
	getRolloverBonusRolloverRequirement,
	getRolloverBonusTotalPlaythroughAmount
} from 'casino/selectors/rolloverBonusesSelectors';
import format from 'date-fns/format';
import {
	getCurrencyConfig,
	PlaythroughProgress,
	PromoCard,
	PromoContent,
	PromoDetailsItem,
	PromoDetailsRow,
	PromoDetailsSection,
	PromoHeader,
	withHook
} from 'materialbet-common';
import * as React from 'react';
import { useSelector } from 'react-redux';

export interface RolloverBonusCardUiProps {
	id: string;
	amount: string;
	rolloverRequirement: string;
	currentPlaythroughAmount: string;
	totalPlaythroughAmount: string;
	progressPercentage: string;
	/** @description ISO Date string */
	expiresAt: string;
	currency: string;
}

export const RolloverBonusCardUi: React.FC<RolloverBonusCardUiProps> = (
	props
) => {
	const {
		amount,
		currentPlaythroughAmount,
		totalPlaythroughAmount,
		progressPercentage,
		expiresAt,
		currency
	} = props;
	const trans = useTranslation();
	const currentPlaythrough = parseFloat(currentPlaythroughAmount);
	const totalPlaythrough = parseFloat(totalPlaythroughAmount);
	const progress = parseFloat(progressPercentage);
	const title = trans('player:promotions.titles.casinoBonus');
	const expiresAtDate = new Date(expiresAt);
	const expires = trans('descriptions.expiresAtDate', {
		date: format(expiresAtDate, 'dd.MM')
	});
	return (
		<PromoCard title={title} expires={expires}>
			<PromoHeader>
				<PromoDetailsSection>
					<PromoDetailsRow>
						{amount && (
							<PromoDetailsItem
								title={trans('common:descriptions.awarded')}
								value={amount}
							/>
						)}
						<PromoDetailsItem
							title={trans('common:descriptions.currency')}
							value={currency}
						/>
					</PromoDetailsRow>
				</PromoDetailsSection>
			</PromoHeader>
			<PromoContent>
				<PlaythroughProgress
					currency={currency}
					value={currentPlaythrough}
					target={totalPlaythrough}
					progress={progress}
					label={trans('player:promotions.playthroughProgress')}
				/>
			</PromoContent>
		</PromoCard>
	);
};
export const useRolloverBonuCardUiProps = ({ id }: { id: string }) => {
	const currency = useSelector(getRolloverBonusCasinoCurrency(id)) || '';
	const currencyLabel = getCurrencyConfig(currency)?.label || '';
	const amount = useSelector(getRolloverBonusAmount(id));
	const rolloverRequirement = useSelector(
		getRolloverBonusRolloverRequirement(id)
	);
	const currentPlaythroughAmount = useSelector(
		getRolloverBonusCurrentPlaythroughAmount(id)
	);
	const totalPlaythroughAmount = useSelector(
		getRolloverBonusTotalPlaythroughAmount(id)
	);
	const expiresAt = useSelector(getRolloverBonusExpiresAt(id));
	const progressPercentage = useSelector(
		getRolloverBonusPlaythroughProgressPercentage(id)
	);
	return {
		currency: currencyLabel,
		amount,
		progressPercentage,
		rolloverRequirement,
		currentPlaythroughAmount,
		totalPlaythroughAmount,
		expiresAt
	};
};
export const RolloverBonusCard = withHook(useRolloverBonuCardUiProps)(
	RolloverBonusCardUi
);
