import { useLanguage, useTranslation } from '@gaming-shell/i18n';
import { differenceInCalendarDays, format } from 'date-fns';
import { format as formatToTimeZone, utcToZonedTime } from 'date-fns-tz';
import * as React from 'react';
import { useTimeFormat } from 'sports/utils/date';
import { getDateFnsLocale } from 'sports/utils/locale';

interface TimeDisplayProps {
	date?: string | Date;
	timeZone?: string;
	format?: string;
}
interface TimerDisplayProps {
	date?: string;
	timer?: boolean;
}

interface DayDisplayProps {
	date: string | Date;
	dateFormat?: string;
	timeZone?: string;
}

export interface RelativeDayDisplayProps {
	date: string | Date;
	dateFormat?: string;
	timeZone?: string;
	//** Declare show the TIME or not if the date is today or tomorrow */
	disableTime?: boolean;
}

const useFormat = (date: Date, dateFormat: string, timeZone?: string) => {
	const language = useLanguage();
	// Handle timeZone empty or undefined
	if (!timeZone || timeZone.length === 0) {
		return format(date, dateFormat);
	}
	timeZone = timeZone.replace(' ', '_'); // We replace space with _ https://en.wikipedia.org/wiki/List_of_tz_database_time_zones is used by the library
	const timeZoneDate = utcToZonedTime(date, timeZone);
	const localeObject = getDateFnsLocale(language);
	return formatToTimeZone(timeZoneDate, dateFormat, {
		timeZone,
		locale: localeObject
	});
};

// Fallback date used so hook can be rendered unconditionally.
export const TimeDisplay: React.FunctionComponent<TimeDisplayProps> = (
	props
) => {
	const timeFormat = useTimeFormat();
	const { date, timeZone, format = timeFormat } = props;
	const newDate = new Date(date || '2019-08-21T07:41:15Z');
	const time = useFormat(newDate, format, timeZone);
	return <>{time}</>;
};

export const DayDisplay: React.FunctionComponent<DayDisplayProps> = (props) => {
	// TODO - below dateFormat may be region-specific.
	const { date, dateFormat = 'EEE, MMM dd', timeZone } = props;

	const monthDate = new Date(date);
	const formattedDate = useFormat(monthDate, dateFormat, timeZone);
	if (!date) {
		return null;
	}
	return <>{formattedDate}</>;
};

export const RelativeDayDisplay: React.FunctionComponent<RelativeDayDisplayProps> = (
	props
) => {
	const timeFormat = useTimeFormat();
	const { date, dateFormat = 'EEE, MMM dd', timeZone, disableTime } = props;
	const monthDate = new Date(date);
	const currentDate = new Date();
	const trans = useTranslation();
	const formattedDate = useFormat(monthDate, dateFormat, timeZone);
	const formattedTime = useFormat(monthDate, timeFormat, timeZone);
	const formattedCurrentDate = useFormat(currentDate, dateFormat, timeZone);
	if (!date) {
		return null;
	}
	const diff = differenceInCalendarDays(
		new Date(formattedDate).getTime(),
		new Date(formattedCurrentDate).getTime()
	);
	let token;
	// https://github.com/date-fns/date-fns/blob/master/src/formatRelative/index.js#L76
	if (diff < 0) {
		token = 'other';
	} else if (diff < 1) {
		token = !disableTime ? 'todayAtTime' : 'today';
	} else if (diff < 2) {
		token = !disableTime ? 'tomorrowAtTime' : 'tomorrow';
	} else {
		token = 'other';
	}
	if (token === 'other') {
		return <>{formattedDate}</>;
	}
	if (disableTime) {
		return <>{trans(`common:time.${token}`)}</>;
	}
	return <>{trans(`common:time.${token}`, { time: formattedTime })}</>;
};

export default TimeDisplay;
