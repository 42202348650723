import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

const path = (
	<path
		d="M25.1,6.5c-0.7,0-1.4,0.5-1.6,1.2C23,9.1,21.6,10,20.1,10c-1.5,0-2.9-0.9-3.4-2.4c-0.3-0.7-0.9-1.2-1.6-1.2
l-2.6,0c-1.7,0-3,1.3-3,3L9.5,33.9c0,1.7,1.3,3,3,3l2,0c0.8,0,1.5-0.5,1.6-1.3c0.4-1.6,1.8-2.7,3.5-2.7c1.6,0,3.1,1.1,3.5,2.7
c0.2,0.8,0.9,1.3,1.6,1.3l2.9,0c1.7,0,3-1.3,3-3l0-24.4c0-1.7-1.3-3-3-3L25.1,6.5z"
	/>
);
export const BetCountIcon: React.FunctionComponent<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<g transform="scale(0.65) translate(-2,-3) ">{path}</g>
		</SvgIcon>
	);
};

export const BetCountRotatedIcon: React.FunctionComponent<SvgIconProps> = (
	props
) => {
	return (
		<SvgIcon {...props}>
			<g transform="scale(1) translate(38, -8) rotate(90)">{path}</g>
		</SvgIcon>
	);
};
