import { Translate } from '@gaming-shell/i18n';
import { SideMenuItem } from '@gaming-shell/layout';
import { PlayerRoutes } from 'materialbet-common';
import { MyAccountIcon } from 'materialbet-icons';
import * as React from 'react';
import { useLocation } from 'react-router';

/** @description a component for single my account item in side menu */
let MyAccount: React.FC = () => {
	const accountRoute = PlayerRoutes.account.create();
	const { pathname } = useLocation();
	const isAccountRoute = PlayerRoutes.account.matchesExactly(pathname);

	return (
		<SideMenuItem
			icon={<MyAccountIcon />}
			label={<Translate label="myAccount.myAccount" ns="player" />}
			href={accountRoute}
			active={isAccountRoute}
			variant="subtitle2"
		/>
	);
};

MyAccount = React.memo(MyAccount);
export { MyAccount };
