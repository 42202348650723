import BigNumber from 'bignumber.js';
import { createSelector } from 'reselect';
import { mapJoin, minimum } from 'sports/utils/array';
import { bitsIncludeSome } from 'sports/utils/bitmaskUtils';
import { calculateMultiplesTotalPrice } from 'sports/utils/odds';

import { BetslipStatusFlags } from '../betslipReducer/BetslipStatusFlags';
import {
	BetslipReducerSelection,
	BetslipSelectionIdentity,
	BetslipState,
	SupportedSystem
} from '../types';
import {
	getBetslipSelectionIdentifierString,
	SelectionsIdentitySeparator
} from './betslipSelectorsUtils';

interface SelectionIndexProp {
	selectionIndex: number;
}
type SelectionIdentityProp = BetslipSelectionIdentity;
interface SystemProp {
	system: SupportedSystem;
}
const stateSelector = (state: BetslipState) => state;
const selectionIndexSelector = (
	_: BetslipState,
	{ selectionIndex }: SelectionIndexProp
) => selectionIndex;

const selectionIdentitySelector = (
	_: BetslipState,
	props: SelectionIdentityProp
) => getBetslipSelectionIdentifierString(props);
const systemSelector = (_: BetslipState, { system }: SystemProp) => system;
const statusSelector = (state: BetslipState) => state.status;
const selectionChangesSelector = (
	state: BetslipState
): BetslipReducerSelection[] | undefined => state.changes?.selections;

const selectionsSelector = (state: BetslipState) => state.betslip.selections;

export const multiplesSelector = (state: BetslipState) =>
	state.betslip.multiples;

export const modeSelector = (state: BetslipState) => state.mode;

export const getSelection = createSelector(
	[selectionsSelector, selectionIndexSelector],
	(selections, selectionIndex) => selections[selectionIndex]
);
export const getSelectionPrices = createSelector(
	[selectionsSelector],
	(selections) => selections.map((s) => s.price)
);
export const getSelectionStake = createSelector(
	[getSelection],
	(selection) => selection.stake
);
export const getSelectionAdditionalInfo = createSelector(
	[getSelection],
	(s) => s?.additionalInfo
);
const getSelectionChange = createSelector(
	[selectionChangesSelector, selectionIndexSelector],
	(selectionChanges, selectionIndex): BetslipReducerSelection | undefined =>
		selectionChanges?.[selectionIndex]
);
export const getChangedSelectionPrice = createSelector(
	[getSelectionChange],
	(selectionChange) => selectionChange && selectionChange.price
);
export const getMultiple = createSelector(
	[multiplesSelector, systemSelector],
	(multiples, system) => multiples[system]
);
export const getMultipleStake = createSelector(
	[getMultiple],
	(multiple) => multiple.stake
);
export const getQuickbetSlipStatus = createSelector(
	[selectionsSelector, statusSelector, selectionChangesSelector],
	(selections, status, changes) => {
		const selection = selections[0];
		const changeStatus = changes?.[0]?.status;
		const totalStatus =
			(selection?.status || 0) | (changeStatus || 0) | status;
		return totalStatus;
	}
);
export const getTotalSelectionStatus = createSelector(
	[getSelection, getSelectionChange],
	(selection, change) =>
		selection?.status | (change?.status || BetslipStatusFlags.Default)
);
export const getAcceptBetterOdds = (state: BetslipState) =>
	state.acceptBetterOdds;
export const getIsSubmitting = (state: BetslipState) => state.isSubmitting;

export const getStraightsTotalStake = createSelector(
	[selectionsSelector],
	(selections) =>
		selections
			.reduce((sum, { stake }) => sum.plus(stake), new BigNumber(0))
			.toNumber()
);
export const getStraightsRemainingStake = createSelector(
	[selectionsSelector, selectionIndexSelector],
	(selections, selectionIndex) =>
		selections
			.reduce(
				(sum, { stake }, i) =>
					sum.plus(selectionIndex !== i ? stake : 0),
				new BigNumber(0)
			)
			.toNumber()
);
export const getMultiplesTotalStake = createSelector(
	[multiplesSelector],
	(multiples) =>
		Object.keys(multiples)
			.reduce((sum, system) => {
				const { stake } = multiples[system];
				return sum.plus(stake);
			}, new BigNumber(0))
			.toNumber()
);
export const getCurrentTotalStake = (state: BetslipState) =>
	state.mode === 'multiples'
		? getMultiplesTotalStake(state)
		: getStraightsTotalStake(state);
export const getMultiplesTotalPrice = createSelector(
	[selectionsSelector],
	(selections) => calculateMultiplesTotalPrice(selections)
);
// TODO this is only valid right now, as we only have parlay once we support more systems will need to change the logic of this
export const getMultiplesTotalReturn = createSelector(
	[getMultiplesTotalPrice, getMultiplesTotalStake],
	(odds, stake) => new BigNumber(odds).multipliedBy(stake).toNumber()
);
export const getMultiplesMaxStake = createSelector(
	[selectionsSelector],
	(selections) =>
		Math.min(...selections.map((selection) => selection.maxStake))
);
export const getStraightsTotalReturn = createSelector(
	[selectionsSelector],
	(selections) =>
		selections
			.reduce(
				(acc, { stake, price }) =>
					acc.plus(new BigNumber(stake).multipliedBy(price)),
				new BigNumber(0)
			)
			.toNumber()
);
export const getStraightsRemainingReturn = createSelector(
	[selectionsSelector, selectionIndexSelector],
	(selections, selectionIndex) =>
		selections
			.reduce(
				(acc, { stake, price }, i) =>
					acc.plus(
						selectionIndex !== i
							? new BigNumber(stake).multipliedBy(price)
							: 0
					),
				new BigNumber(0)
			)
			.toNumber()
);
export const getSelectionCount = (state: BetslipState) =>
	selectionsSelector(state).length;
export const getHasChanges = (state: BetslipState) => !!state.changes;
export const getTotalBetslipStatus = createSelector(
	[stateSelector],
	(state) => {
		const totalSelectionStatus = state.betslip.selections.reduce(
			(status, selection, i) => {
				const changeStatus =
					state.changes?.selections[i]?.status ||
					BetslipStatusFlags.Default;
				return status | selection.status | changeStatus;
			},
			BetslipStatusFlags.Default
		);
		const totalMultiplesStatus = Object.entries(
			state.betslip.multiples
		).reduce((status, [key, value]) => {
			return status | value.status | state.changes?.multiples[key].status;
		}, BetslipStatusFlags.Default);
		return state.status | totalSelectionStatus | totalMultiplesStatus;
	}
);
export const getCanPlaceBet = createSelector(
	[getTotalBetslipStatus],
	(status) => {
		return !bitsIncludeSome(BetslipStatusFlags.CannotPlace, status);
	}
);
export const getCanValidate = createSelector(
	[getTotalBetslipStatus],
	(status) => {
		return !bitsIncludeSome(BetslipStatusFlags.CannotValidate, status);
	}
);
export const getBetslip = (state: BetslipState) => {
	const betslip = state.betslip;
	return betslip;
};

export const getBetslipSelectionsIdentityString = createSelector(
	[selectionsSelector],
	(selections) =>
		mapJoin(
			selections,
			getBetslipSelectionIdentifierString,
			SelectionsIdentitySeparator
		)
);
export const getSelectionIsInBetslip = createSelector(
	[getBetslipSelectionsIdentityString, selectionIdentitySelector],
	(selectionsIdentity, selectionIdentity) =>
		selectionsIdentity.includes(selectionIdentity)
);
export const getMinimumMaxStake = createSelector(
	[selectionsSelector],
	(selections) =>
		minimum(selections, (x, than) => x.maxStake < than.maxStake).maxStake
);
