/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
	Submarket,
	SubmarketFromJSON,
	SubmarketFromJSONTyped,
	SubmarketToJSON
} from './';

/**
 * Market is used as a part of Event and EventForCompetition
 * Used to build a list of all markets for a given Event
 * @export
 * @interface Market
 */
export interface Market {
	/**
	 *
	 * @type {{ [key: string]: Submarket; }}
	 * @memberof Market
	 */
	submarkets?: { [key: string]: Submarket };
}

export function MarketFromJSON(json: any): Market {
	return MarketFromJSONTyped(json, false);
}

export function MarketFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): Market {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		submarkets: !exists(json, 'submarkets')
			? undefined
			: mapValues(json['submarkets'], SubmarketFromJSON)
	};
}

export function MarketToJSON(value?: Market | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		submarkets:
			value.submarkets === undefined
				? undefined
				: mapValues(value.submarkets, SubmarketToJSON)
	};
}
