import { Skeleton } from '@material-ui/lab';
import * as React from 'react';

import { useStyles } from './PreviewHeader.styles';

export const PreviewHeaderSkeleton = () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Skeleton variant="text" width={180} />
		</div>
	);
};
