"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsBonusAvailable = void 0;
var react_router_1 = require("react-router");
var player_1 = require("@materialbet-core/player");
var materialbet_common_1 = require("materialbet-common");
exports.useIsBonusAvailable = function (currency) {
    var activeCurrency = player_1.usePlayerCurrency();
    var playerWallet = player_1.usePlayerWallet();
    var wallets = player_1.usePlayerWallets();
    var bonusAmount = (wallets[currency || activeCurrency] || playerWallet).bonusAmount;
    var eligibleFor = player_1.usePlayerBonusDetails().eligibleFor;
    var pathname = react_router_1.useLocation().pathname;
    var isBonusAvailable = materialbet_common_1.isBonusEligibleForPath(eligibleFor, pathname);
    return bonusAmount > 0 && isBonusAvailable;
};
