import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

const Lobby: React.FunctionComponent<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<path
				fill={props.fill}
				fillRule="nonzero"
				d="M18.78 12.84a.75.75 0 0 1 .743.648l.007.102v6.79a.75.75 0 0 1-.648.743l-.102.007h-4.93a.75.75 0 0 1-.743-.648l-.007-.102v-4.94a.48.48 0 0 0-.394-.472l-.086-.008h-1.24a.48.48 0 0 0-.47.386l-.01.094v4.94a.75.75 0 0 1-.648.743l-.102.007H5.22a.75.75 0 0 1-.743-.648l-.007-.102v-6.79a.75.75 0 0 1 1.493-.102l.007.102v6.04H9.4v-4.19a1.98 1.98 0 0 1 1.806-1.972l.174-.008h1.24a1.98 1.98 0 0 1 1.975 1.832l.005.148v4.19h3.43v-6.04a.75.75 0 0 1 .75-.75zM12 2.89c.467 0 .917.164 1.272.462l.128.118 1.683 1.683a.75.75 0 0 1 .617-.323h3.08a.75.75 0 0 1 .75.75V9.6l2.25 2.25a.75.75 0 0 1-.976 1.133l-.084-.073-8.38-8.38a.48.48 0 0 0-.607-.06l-.073.061-8.38 8.36a.75.75 0 0 1-1.132-.978l.072-.084 8.38-8.36a1.98 1.98 0 0 1 1.4-.58zm6.03 3.44h-1.58v.19l1.58 1.58V6.33z"
			/>
		</SvgIcon>
	);
};
export default Lobby;
