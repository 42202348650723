import { toISO639, useLanguage } from '@gaming-shell/i18n';
import * as React from 'react';

export const OnLanguageChange: React.FC = () => {
	const language = useLanguage();
	React.useEffect(() => {
		document.documentElement.lang = toISO639(language);
	}, [language]);
	return null;
};
