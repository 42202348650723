import * as React from 'react';

import { BetslipBetPlacedSnackbar } from '../BetslipBetPlacedSnackbar';
import { BetslipReducerBetslip, PlaceBetCallbackArgs } from '../types';

export interface BigBetslipBetPlacedSnackbarProps {
	betslip: PlaceBetCallbackArgs | null;
	onClose: () => void;
	openBigBetslip: () => void;
	setBetslip: (betslip: BetslipReducerBetslip) => void;
}
export const BigBetslipBetPlacedSnackbar: React.FunctionComponent<BigBetslipBetPlacedSnackbarProps> = (
	props
) => {
	const { betslip, onClose, openBigBetslip, setBetslip } = props;
	const onPlaceAgain = () => {
		onClose();
		if (!betslip) {
			return;
		}
		setBetslip(betslip.betslip);
		openBigBetslip();
	};
	// Check it allow to share the bet, allow to share single selection bet and parlay bet at the moment
	const canShareBet =
		betslip?.mode === 'multiples' ||
		(betslip?.mode === 'singles' &&
			betslip?.betslip?.selections?.length === 1);
	return (
		<BetslipBetPlacedSnackbar
			open={!!betslip}
			onPlaceAgain={onPlaceAgain}
			onClose={onClose}
			betUuid={canShareBet ? betslip?.betUuid : undefined}
		/>
	);
};
