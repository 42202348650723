import { isDesktop } from '@gaming-shell/device';
import { Translate } from '@gaming-shell/i18n';
import { SideMenuItem } from '@gaming-shell/layout';
import { LegacyAuthRoutes } from 'materialbet-common';
import { SignOutIcon } from 'materialbet-icons';
import * as React from 'react';
import { useLocation } from 'react-router';

/** @description a component for single logout item in side menu */
let Logout: React.FC = () => {
	const { pathname } = useLocation();
	const logoutRoute = LegacyAuthRoutes.logout.create();
	const isLogoutRoute = LegacyAuthRoutes.logout.matches(pathname);
	if (!isDesktop()) {
		return null;
	}
	return (
		<SideMenuItem
			icon={<SignOutIcon />}
			label={<Translate label="actions.signOut" />}
			active={isLogoutRoute}
			variant="subtitle2"
			href={logoutRoute}
		/>
	);
};

Logout = React.memo(Logout);
export { Logout };
