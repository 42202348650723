import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.primary.main
	},
	root: {
		display: 'flex',
		alignItems: 'center',
		margin: theme.spacing(2, 0, 1, 0)
	},
	button: { ...theme.typography.h6, textTransform: 'none' },
	seeAll: {
		flexGrow: 1,
		textAlign: 'right'
	},
	leftArrowButton: {
		marginLeft: theme.spacing(0.5)
	}
}));
