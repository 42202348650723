import { useTranslation } from '@gaming-shell/i18n';
import { usePlayerNickname } from '@materialbet-core/player';
import { SportsRoutes, withHook } from 'materialbet-common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getEventPromotionCustomerBets } from 'sports/selectors/eventPromotionsSelectors';
import { getZeroMarginPromotionId } from 'sports/utils/eventPromotionsUtils';

import { ZeroMarginEventWidgetPart } from '../ZeroMarginEventWidgetPart';
import { ZeroMarginLiabilityCounter } from '../ZeroMarginLiabilityCounter';

export const ZeroMarginEventWidgetPlayerPart = withHook(
	({ eventId }: { eventId: number }) => {
		const t = useTranslation();
		const promotionId = getZeroMarginPromotionId(eventId);
		const bets =
			useSelector(getEventPromotionCustomerBets(promotionId)) || 0;
		const title = usePlayerNickname();
		const linkTitle = t('sports:betting.xBetsPlaced', { count: bets });
		const linkTo = SportsRoutes.bets.create();
		const counter = <ZeroMarginLiabilityCounter eventId={eventId} />;
		return {
			title,
			linkTo,
			linkTitle,
			counter
		};
	}
)(ZeroMarginEventWidgetPart);
