import {
	CircularProgress,
	CircularProgressProps,
	makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

export interface ProgressWrapperProps {
	submitting: boolean;
	size?: number;
	className?: string;
	children: React.ReactNode;
	CircularProgressProps?: Partial<Omit<CircularProgressProps, 'size'>>;
}

const defaultCircularProgressSize = 24;
const useStyles = makeStyles({
	progress: ({ size }: { size: number }) => ({
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -size / 2,
		marginLeft: -size / 2
	}),
	wrapper: ({ size }: { size: number }) => ({
		display: 'inline-block',
		position: 'relative',
		minWidth: size,
		minHeight: size
	})
});

export const ProgressWrapper: React.FC<ProgressWrapperProps> = ({
	submitting,
	className,
	children,
	size = defaultCircularProgressSize,
	CircularProgressProps
}) => {
	const classes = useStyles({ size });

	return (
		<div className={clsx(classes.wrapper, className)}>
			{children}
			{submitting && (
				<CircularProgress
					{...CircularProgressProps}
					size={size}
					className={clsx(
						classes.progress,
						CircularProgressProps?.className
					)}
				/>
			)}
		</div>
	);
};
