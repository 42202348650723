"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardsIcon = void 0;
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
exports.CardsIcon = function (props) { return (React.createElement(core_1.SvgIcon, __assign({ viewBox: "0 0 24 24" }, props),
    React.createElement("path", { fillOpacity: ".87", d: "M16.532 9.908l2.194-5.493c.37-.956-.072-2.053-.984-2.447l-1.21-.524v8.464zM.136 5.437l4.478 11.22c.28.703.94 1.134 1.634 1.153.235 0 .479-.038.714-.141l6.654-2.859c.677-.29 1.092-.965 1.11-1.687.01-.253-.036-.506-.135-.75l-4.478-11.22C9.833.44 9.165.009 8.46 0c-.234 0-.47.047-.695.14L1.12 3C.199 3.392-.243 4.49.136 5.436zM11.524 0l3.143 8.381V2.01c0-1.106-.82-2.01-1.822-2.01h-1.321z", transform: "translate(3.095 3.048)" }))); };
