import { Box, BoxProps, Card } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

export interface ConsentBoxProps extends BoxProps {
	onConsentClick: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(2, 3, 3, 2)
	}
}));

export const ConsentBoxContext = React.createContext<
	(_: any, value: boolean) => void
>((_: any) => void 0);

export const ConsentBox: React.FunctionComponent<ConsentBoxProps> = ({
	children,
	className,
	onConsentClick,
	...rest
}) => {
	const classes = useStyles();
	const handleConsentChange = React.useCallback(
		(_, value: boolean) => {
			onConsentClick(value);
		},
		[onConsentClick]
	);

	return (
		<ConsentBoxContext.Provider value={handleConsentChange}>
			<Card className={className}>
				<Box className={classes.container} {...rest}>
					{children}
				</Box>
			</Card>
		</ConsentBoxContext.Provider>
	);
};
