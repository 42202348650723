import { IconButton } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { FullscreenIcon, SmallscreenIcon } from 'materialbet-icons';
import * as React from 'react';

interface FullscreenButtonProps {
	active?: boolean;
	className?: string;
	onClick: (event: React.SyntheticEvent) => void;
	/** @description icon font size for the icon insdie the button*/
	fontSize?: SvgIconProps['fontSize'];
	color?: SvgIconProps['color'];
}

let FullscreenButton: React.FunctionComponent<FullscreenButtonProps> = (
	props
) => {
	const { active, onClick, className, fontSize, color } = props;
	const Icon = active ? SmallscreenIcon : FullscreenIcon;
	return (
		<IconButton
			size="small"
			onClick={onClick}
			className={className}
			data-testid="FullscreenButton"
		>
			<Icon color={color} fontSize={fontSize} />
		</IconButton>
	);
};

FullscreenButton = React.memo(FullscreenButton);
export { FullscreenButton };
