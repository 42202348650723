import { isBefore } from 'date-fns';
import { mapFilter } from 'materialbet-common';
import { createSelector } from 'reselect';
import { MarketType } from 'sports-sdk';
import { zeroMarginCompetitionKey } from 'sports/config/zeromargin';
import { RootState, SportsState } from 'sports/modules/root';
import {
	getEventPromotion,
	getEventPromotionSlotsAvailable
} from 'sports/selectors/eventPromotionsSelectors';
import { getCompetitionEventsMetaParams } from 'sports/utils/competitionUtils';
import {
	EventPromotionIdentifier,
	getEventPromotionId,
	getZeroMarginPromotionId
} from 'sports/utils/eventPromotionsUtils';
import { iframeCompetitionUrls } from 'sports/utils/livestreamUtils';

import { getEventPromotionSlotsTaken } from '../eventPromotionsSelectors';
import { getCompetitionEvents } from '../metaSelectors';

export interface MatchDay {
	matchDay: string;
	startTime: number;
	eventIds: number[];
}
type EventId = string | number | undefined;
/** @description Maps a matchday identifier to matchday object */
export type MatchDays = Record<string, MatchDay>;
/** @file as the sports selectors file is growin large, createing this one to create selectors for single fields in the event entity */
const eventsSelector = (state: RootState) => state.events;
const eventIdsPropSelector = (
	_: unknown,
	{ eventIds }: { eventIds: number[] }
) => eventIds;

export const getEvent = (state: RootState, id: EventId) =>
	id === undefined ? undefined : eventsSelector(state)[id];
const forEvent = <T>(
	selector: (event: NonNullable<ReturnType<typeof getEvent> | undefined>) => T
) => (id: EventId) => (state: RootState) => {
	const e = getEvent(state, id);
	return e ? selector(e) : e;
};
export const forEventGetEventTeamNames = (
	event: Pick<NonNullable<ReturnType<typeof getEvent>>, 'home' | 'away'>
) => ({
	home: event.home?.name || 'N/A',
	away: event.away?.name || 'N/A'
});

export const getAvailability = forEvent((event) => event.availability);
export const getCompetitionKey = forEvent((event) => event.competition);
export const getStatus = forEvent((event) => event.status);
/**@description temporarily just getting first stream url */
export const getStreamUrl = (streamIndex: number) =>
	forEvent((event) => event?.streams?.[streamIndex]?.url);
export const hasLiveStream = forEvent(
	(event) =>
		!!event?.streams?.length ||
		!!iframeCompetitionUrls[event.competition || '']
);

export const getEventName = forEvent((event) => event.name);
export const getEventCompetitionKey = forEvent((event) => event.competition);
export const getEventStartTime = forEvent((event) => event.startTime);
export const getEventStatus = forEvent((event) => event.status);
export const getCompetitorHomeName = forEvent((event) => event.home?.name);
export const getCompetitorAwayName = forEvent((event) => event.away?.name);
export const getEventTeamNames = forEvent(forEventGetEventTeamNames);
export const getPlayers = forEvent((event) => event.players);
export const getEventKey = forEvent((event) => event.key);
export const getEventCutoffTime = forEvent((event) => event.cutoffTime);
export const getEventPromotions = forEvent((event) => event.promotions);
export const getEventMetadata = forEvent((event) => event.metadata);
export const getEventHasZeroMarginPromotion = forEvent(
	(e) => e.promotions?.includes('ZERO_MARGIN') || false
);
export const getMarkets = forEvent((event) => event.markets);
export const getMarket = (marketType: MarketType) =>
	forEvent((event) => event.markets?.[marketType]);
export const getSubmarketKeys = (marketType: MarketType) =>
	forEvent((event) =>
		Object.keys(event.markets?.[marketType]?.submarkets || {})
	);
export const getMatchDayKey = (
	matchDay: string | number,
	time: string | number
) => `${matchDay}-${new Date(time).getTime()}`;

export const makeGetMatchDays = () => {
	return createSelector(
		[eventsSelector, eventIdsPropSelector],
		(events, eventIds) =>
			eventIds.reduce<MatchDays>((acc, id) => {
				const event = events[id];
				if (!event || !event?.startTime || event?.blank) {
					return acc;
				}
				const matchDay = (event.metadata as
					| { matchDay?: string }
					| undefined)?.matchDay;
				if (!matchDay || !event.startTime) {
					return acc;
				}
				const key = getMatchDayKey(matchDay, event.startTime);
				if (!acc[key]) {
					acc[key] = {
						matchDay,
						startTime: new Date(event.startTime).getTime(),
						eventIds: []
					};
				}
				acc[key].eventIds.push(id);
				return acc;
			}, {})
	);
};

export const getEventIdByBetradarIdFromEventList = (
	state: RootState,
	betradarId: number,
	eventIds: number[]
) => eventIds.find((id) => state.events[id]?.betradarId === betradarId);

export const makeGetEventNameIdsFromEventList = () =>
	createSelector(
		[eventsSelector, eventIdsPropSelector],
		(events, eventIds) =>
			mapFilter(
				eventIds,
				(id) => {
					const event = events[id];
					if (!event?.name) {
						return;
					}
					return { name: event.name, id };
				},
				(x) => !!x
			) as { name: string; id: number }[]
	);

/** @description return valid zero margin promotion event with given event ids */
export const getZeroMarginEventIdsByEventsIds = (eventIds: number[]) => (
	state: RootState
) => {
	const eventIdWithPromotion = eventIds.find((id) => {
		const event = state.events[id];
		return event?.promotions?.includes('ZERO_MARGIN') || false;
	});
	if (eventIdWithPromotion) {
		return [eventIdWithPromotion];
	}
	return [];
};

/** @description get the status of the event promotion */
export const isEventPromotionActive = ({
	eventId,
	promotion
}: EventPromotionIdentifier) => (state: SportsState) => {
	const identifier = getEventPromotionId({
		eventId,
		promotion: promotion.toLowerCase()
	});
	const eventMetadata = getEventMetadata(eventId)(state);
	const eventHasPromotion = getEventPromotions(eventId)(state)?.includes(
		promotion
	);
	const eventStarted = eventMetadata?.eventStatus !== 'not_started';
	/** @todo need to get the state from metadata as well */
	if (!eventHasPromotion || eventStarted) {
		return false;
	}
	const eventPromotion = getEventPromotion(state, identifier);
	if (
		!eventPromotion ||
		eventPromotion.slotsTaken >= eventPromotion.slotsAvailable
	) {
		return false;
	}
	return true;
};

export const getIsZeroMarginPromoExpired = (eventId: number) => (
	state: SportsState
) => {
	const cutoffTime = getEventCutoffTime(eventId)(state);

	if (cutoffTime && isBefore(new Date(cutoffTime), new Date())) {
		return true;
	}

	const slotsTaken = getEventPromotionSlotsTaken(
		getZeroMarginPromotionId(eventId)
	)(state);

	const slotsAvailable = getEventPromotionSlotsAvailable(
		getZeroMarginPromotionId(eventId)
	)(state);
	if (slotsTaken === undefined || slotsAvailable === undefined) {
		return undefined;
	}
	return slotsTaken >= slotsAvailable;
};
export const getIsZeroMarginPromoActive = (eventId: number, locale: string) => (
	state: SportsState
) => {
	const hasPromotionTag = getEventHasZeroMarginPromotion(eventId)(state);

	const isExpired = getIsZeroMarginPromoExpired(eventId)(state);
	if (isExpired) {
		return false;
	}
	if (hasPromotionTag) {
		return true;
	}
	const eventIds =
		getCompetitionEvents(
			state,
			getCompetitionEventsMetaParams({
				competitionKey: zeroMarginCompetitionKey,
				locale
			})
		) || [];
	const zeroMarginEventId = getZeroMarginEventIdsByEventsIds(eventIds)(
		state
	)[0];
	return zeroMarginEventId === eventId;
};
