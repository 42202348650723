import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

const allMarkets = {
	popular: [
		{
			marketType: MarketType.soccer_match_odds
		},
		{
			marketType: MarketType.soccer_asian_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.soccer_total_goals,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.soccer_both_teams_to_score
		},
		{
			marketType: MarketType.soccer_team_total_goals,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.soccer_team_win_to_nil
		},
		{
			marketType: MarketType.soccer_asian_handicap_period_extratime,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.soccer_match_odds_period_extratime
		},
		{
			marketType: MarketType.soccer_total_goals_period_extratime,
			view: MarketView.Goals
		}
	],
	firstHalf: [
		{
			marketType: MarketType.soccer_match_odds_period_first_half
		},
		{
			marketType: MarketType.soccer_asian_handicap_period_first_half,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.soccer_total_goals_period_first_half,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.soccer_team_total_goals_period_first_half,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.soccer_exact_total_goals_period_first_half
		},
		{
			marketType: MarketType.soccer_halftime_fulltime_result
		},
		{
			marketType: MarketType.soccer_correct_score_period_1st_half,
			view: MarketView.CorrectScore
		}
	],
	secondHalf: [
		{
			marketType: MarketType.soccer_match_odds_period_second_half
		},
		{
			marketType: MarketType.soccer_asian_handicap_period_second_half,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.soccer_total_goals_period_second_half,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.soccer_team_total_goals_period_second_half,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.soccer_exact_total_goals_period_second_half
		},
		{
			marketType: MarketType.soccer_correct_score_period_2nd_half,
			view: MarketView.CorrectScore
		}
	],
	corners: [
		{
			marketType: MarketType.soccer_corner_match_odds
		},
		{
			marketType: MarketType.soccer_corner_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.soccer_total_corners,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.soccer_corner_nr
		},
		{
			marketType: MarketType.soccer_5_minutes_team_total_corners_from_to,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.soccer_1st_half_corner_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.soccer_last_corner
		}
	],
	specials: [
		{
			marketType: MarketType.soccer_correct_score,
			view: MarketView.CorrectScore
		},
		{
			marketType: MarketType.soccer_draw_no_bet
		},
		{
			marketType: MarketType.soccer_double_chance
		},
		{
			marketType: MarketType.soccer_goal_nr
		},
		{
			marketType: MarketType.soccer_team_clean_sheet
		},
		{
			marketType: MarketType.soccer_penalty_shootout_odd_even
		},
		{
			marketType: MarketType.soccer_freetext
		}
	],
	bookings: [
		{
			marketType: MarketType.soccer_booking_match_odds
		},
		{
			marketType: MarketType.soccer_booking_nr
		},
		{
			marketType: MarketType.soccer_total_bookings,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.soccer_total_booking_points,
			view: MarketView.Goals
		}
	],
	scorers: [
		{
			marketType: MarketType.soccer_goal_strike_zone
		},
		{
			marketType: MarketType.soccer_goalnr_goalscorer
		},
		{
			marketType: MarketType.soccer_anytime_goalscorer
		},
		{
			marketType: MarketType.soccer_last_goalscorer
		}
	]
};

export const eventMarketConfig: TabConfiguration[] = [
	{
		key: 'popular',
		label: 'descriptions.popular',
		markets: allMarkets.popular
	},
	{
		key: 'scorers',
		label: 'markets.scorers',
		markets: allMarkets.scorers
	},
	{
		key: 'firstHalf',
		label: 'markets.firstHalf',
		markets: allMarkets.firstHalf
	},
	{
		key: 'secondHalf',
		label: 'markets.secondHalf',
		markets: allMarkets.secondHalf
	},
	{
		key: 'corners',
		label: 'markets.corners',
		markets: allMarkets.corners
	},
	{
		key: 'bookings',
		label: 'markets.bookings',
		markets: allMarkets.bookings
	},
	{
		key: 'specials',
		label: 'markets.specials',
		markets: allMarkets.specials
	},
	{
		key: 'all',
		label: 'descriptions.all',
		markets: [
			...allMarkets.popular,
			...allMarkets.scorers,
			...allMarkets.firstHalf,
			...allMarkets.secondHalf,
			...allMarkets.corners,
			...allMarkets.bookings,
			...allMarkets.specials
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.soccer_match_odds
			},
			{
				marketType: MarketType.soccer_asian_handicap
			},
			{
				marketType: MarketType.soccer_total_goals
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.soccer_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
