import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

const allMarkets = {
	popular: [
		{
			marketType: MarketType.tennis_winner
		},
		{
			marketType: MarketType.tennis_game_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.tennis_set_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.tennis_total_games,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.tennis_set_correct_score,
			view: MarketView.CorrectScore
		},
		{
			marketType: MarketType.tennis_total_sets,
			view: MarketView.Goals
		}
	],
	sets: [
		{
			marketType: MarketType.tennis_any_set_to_nil
		},
		{
			marketType: MarketType.tennis_correct_score,
			view: MarketView.CorrectScore
		},
		{
			marketType: MarketType.tennis_exact_sets
		},
		{
			marketType: MarketType.tennis_team_to_win_a_set
		},
		{
			marketType: MarketType.tennis_winner_of_set
		},
		{
			marketType: MarketType.tennis_set_tiebreak_total_points,
			view: MarketView.Goals
		}
	],
	games: [
		{
			marketType: MarketType.tennis_team_total_games,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.tennis_set_game_correct_score
		},
		{
			marketType: MarketType.tennis_set_game_to_deuce
		},
		{
			marketType: MarketType.tennis_set_game_correct_score_or_break
		},
		{
			marketType: MarketType.tennis_odd_even_games
		}
	],
	specials: [
		{
			marketType: MarketType.tennis_exact_points
		},
		{
			marketType: MarketType.tennis_with_tiebreak
		},
		{
			marketType: MarketType.tennis_winner_in_game_and_set
		},
		{
			marketType: MarketType.tennis_game_handicap_in_set,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.tennis_point_in_game_and_set
		},
		{
			marketType: MarketType.tennis_race_to_points_in_set_and_game
		},
		{
			marketType: MarketType.tennis_race_to_games_in_set
		},
		{
			marketType: MarketType.tennis_total_games_in_set,
			view: MarketView.Goals
		}
	]
};

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: allMarkets.popular
	},
	{
		label: 'markets.sets',
		markets: allMarkets.sets
	},
	{
		label: 'markets.tennisGames',
		markets: allMarkets.games
	},
	{
		label: 'markets.specials',
		markets: allMarkets.specials
	},
	{
		label: 'descriptions.all',
		markets: [
			...allMarkets.popular,
			...allMarkets.sets,
			...allMarkets.specials
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.tennis_winner
			},
			{
				marketType: MarketType.tennis_set_handicap
			},
			{ marketType: MarketType.tennis_total_games }
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.tennis_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
