"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOpenSideMenuDrawer = void 0;
var Context_1 = require("../../MenuDrawer/Context");
var useConstRef_1 = require("../../utils/useConstRef");
var useSetOpen_1 = require("./useSetOpen");
exports.useOpenSideMenuDrawer = function () {
    var setOpen = useSetOpen_1.useSetOpen();
    var setOldDrawerOpen = Context_1.useSetSideMenu().setOpen;
    var setDrawerOpen = ENABLE_NEW_SIDE_MENU ? setOpen : setOldDrawerOpen;
    var openDrawer = useConstRef_1.useConstRef(function () { return setDrawerOpen(true); });
    return openDrawer;
};
