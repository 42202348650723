import { useLanguage, useTranslation } from '@gaming-shell/i18n';
import { TFunction } from 'i18next';
import { useSnackbar } from 'materialbet-common';
import { useLocation } from 'react-router';
import { isBoundToPitchers } from 'sports-sdk';
import {
	getMarketName,
	getOutcome,
	Locale,
	MarketType
} from 'sports-sdk/sports-core';
import sportsApi from 'sports/api/sports';
import { betslipActions } from 'sports/components/betterBetslip/betslipReducer';
import { isSameSelection } from 'sports/components/betterBetslip/betslipReducer/betslipReducerUtils';
import { BetslipStatusFlags } from 'sports/components/betterBetslip/betslipReducer/BetslipStatusFlags';
import { useBetslipDispatch } from 'sports/components/betterBetslip/hooks';
import { getEventSelectionByBetslipSelection } from 'sports/components/betterBetslip/refetchBetslipData/getEventSelectionByBetslipSelection';
import { getSelectionsMarkets } from 'sports/components/betterBetslip/refetchBetslipData/getSelectionsMarkets';
import { groupSelectionsByEventId } from 'sports/components/betterBetslip/refetchBetslipData/groupSelectionsByEventId';
import {
	BetslipReducerBetslip,
	BetslipReducerSelection,
	BetslipState
} from 'sports/components/betterBetslip/types';
import { forEventGetEventTeamNames } from 'sports/selectors/eventSelectors';
import { getSdkLocale } from 'sports/utils/locale';
import { getPlayersMustStartStrings } from 'sports/utils/playerUtils';

export interface ShareBet {
	eventId: string;
	marketKey: MarketType;
	submarketKey: string;
	params: string;
	outcome: string;
}

const hasCorrectStructure = (betslipState: BetslipState) => {
	return (
		betslipState.betslip?.selections?.push &&
		betslipState.betslip.multiples[0] &&
		betslipState.acceptBetterOdds.valueOf &&
		betslipState.isSubmitting.valueOf
	);
};
/** @description parse a base64 encoded uri bets to a array */
export const parseBase64Bets = (bets: string) => {
	const decodedBets = decodeURIComponent(bets);
	const jsonString = atob(decodedBets);
	try {
		const shareBets = JSON.parse(jsonString);
		if (!shareBets.length) {
			return;
		}
		return shareBets;
	} catch (e) {
		return;
	}
};

export const useUrlSearchBets = () => {
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const bets = params.get('bets');
	return bets;
};

export const fetchShareBetslipData = (params: {
	bets: ShareBet[];
	locale: Locale;
	t: TFunction;
}) => {
	const { bets, locale, t } = params;
	const betslipSelections = bets.map((bet) => ({
		...bet,
		eventId: +bet.eventId,
		marketName: '',
		eventName: '',
		outcomeName: '',
		stake: 0,
		maxStake: 0,
		minStake: 0,
		price: 0,
		status: BetslipStatusFlags.Default
	})) as BetslipReducerSelection[];
	const groups = groupSelectionsByEventId(betslipSelections); // use casting here as we know the fields are missing but the function dont need that
	const mode = bets.length > 1 ? 'multiples' : 'singles';
	const status = BetslipStatusFlags.Default;
	const isSubmitting = false;
	const acceptBetterOdds = true;
	const betslip: BetslipReducerBetslip = {
		selections: betslipSelections,
		multiples: {
			'0': {
				stake: 0,
				status: BetslipStatusFlags.Default
			}
		}
	};
	const promises = Object.keys(groups).map((eventId) => {
		const group = groups[parseFloat(eventId)];
		const markets = getSelectionsMarkets(group);
		return sportsApi
			.getMainEvent(parseFloat(eventId), markets)
			.then((event) => {
				group.forEach((s) => {
					let selectionStatus = BetslipStatusFlags.Default;
					const [parsedOutcome, outcomeErr] = getOutcome(
						locale,
						(event as unknown) as Parameters<typeof getOutcome>[1],
						s.marketKey,
						s as Parameters<typeof getOutcome>[3]
					);
					const [marketName, marketNameErr] = getMarketName(
						locale,
						s as Parameters<typeof getMarketName>[1],
						s.marketKey,
						(event as unknown) as Parameters<
							typeof getMarketName
						>[3]
					);
					if (outcomeErr || marketNameErr) {
						throw outcomeErr || marketNameErr;
					}
					if (!parsedOutcome || !marketName) {
						throw new Error('Missing outcome or market name');
					}
					const eventName = event.name;
					const outcomeName = parsedOutcome?.name;
					const eventSelection = getEventSelectionByBetslipSelection(
						event,
						s
					);
					if (!eventSelection || !outcomeName || !eventName) {
						throw new Error(
							'Missing event selection, outcome name or event name'
						);
					}
					const { price, maxStake, minStake } = eventSelection;
					if (
						price === undefined ||
						maxStake === undefined ||
						minStake === undefined
					) {
						throw new Error('Missing data for event selection');
					}
					const selectionIndex = betslip.selections.findIndex(
						(selection) => isSameSelection(selection, s)
					);
					if (selectionIndex < 0) {
						return;
					}
					if (!!event.blank) {
						selectionStatus = BetslipStatusFlags.Restricted;
					} else if (eventSelection.status !== 'SELECTION_ENABLED') {
						selectionStatus = BetslipStatusFlags.MarketSuspended;
					}
					const isPitcherBound = isBoundToPitchers({
						params: betslip.selections[selectionIndex].params
					});
					const additionalInfo = isPitcherBound
						? getPlayersMustStartStrings(
								t,
								parsedOutcome?.variables,
								forEventGetEventTeamNames(event)
						  )
						: undefined;
					betslip.selections[selectionIndex] = {
						...s,
						eventName,
						outcomeName,
						marketName,
						status: selectionStatus,
						price,
						maxStake,
						minStake,
						additionalInfo
					};
				});
			})
			.catch((e: Response) => {
				if (e && e.status && e.status > 300 && e.status < 400) {
					// network error can try again here
					// TODO build something to check network status - dont blindly retry if we know we are offline
				}
				throw e;
			});
	});
	return Promise.all(promises).then(() => {
		const betslipState: BetslipState = {
			mode,
			status,
			isSubmitting,
			acceptBetterOdds,
			betslip
		};
		if (!hasCorrectStructure(betslipState)) {
			throw new Error(t('sports:betting.result.malformedRequest'));
		}
		return betslipState;
	});
};

export const useCopyBets = ({ done }: { done?: (source: string) => void }) => {
	const language = useLanguage();
	const t = useTranslation();
	const sdkLocale = getSdkLocale(language);
	const { enqueueSnackbar } = useSnackbar();
	const queryBets = useUrlSearchBets();
	const dispatchBetslip = useBetslipDispatch();
	if (!queryBets || !ENABLE_BET_SHARE) {
		return;
	}
	const bets = parseBase64Bets(queryBets);
	if (!bets) {
		return;
	}
	fetchShareBetslipData({
		bets,
		locale: sdkLocale,
		t
	})
		.then((state: BetslipState) => {
			dispatchBetslip(betslipActions.setState(state));
			done && done('share'); // Hardcoded for now, could get from the query
		})
		.catch(() => {
			enqueueSnackbar(t('sports:betting.theBetslipIsNoLongerValid'));
		});
};
