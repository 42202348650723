import { makeStyles, Theme } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import clx from 'classnames';
import { SportIcon } from 'materialbet-icons';
import * as React from 'react';
import { getFlagIconUrl } from 'sports/utils/media';

const defaultEnableCategoryIconSports = ['soccer', 'handball'];
const useIconStyles = makeStyles((theme: Theme) => ({
	flag: {
		height: 24,
		width: 24,
		objectFit: 'contain',
		margin: theme.spacing(0, 1, 0, 2),
		flexShrink: 0
	},
	sportIcon: {
		opacity: 0.5,
		marginLeft: theme.spacing(2)
	}
}));

// Allow both undefined here, for fallback of category key to sports
interface EventGroupHeaderIconProps {
	categoryKey?: string;
	sportsKey?: string;
	disableSportIcon?: boolean;
}

export const EventGroupHeaderIcon: React.FunctionComponent<EventGroupHeaderIconProps> = (
	props
) => {
	const { categoryKey, sportsKey, disableSportIcon } = props;
	const flagIconUrl = categoryKey && getFlagIconUrl(categoryKey);
	const classes = useIconStyles();
	if (
		sportsKey &&
		!defaultEnableCategoryIconSports.includes(sportsKey) &&
		!disableSportIcon
	) {
		return (
			<SportIcon
				sportKey={sportsKey}
				className={clx(classes.flag, classes.sportIcon)}
			/>
		);
	}
	if (!flagIconUrl) {
		return (
			<LanguageIcon className={clx(classes.flag, classes.sportIcon)} />
		);
	}
	if (categoryKey) {
		return <img className={classes.flag} src={flagIconUrl} alt="" />;
	}
	return null;
};
