import { toISO639, useLanguage } from '@gaming-shell/i18n';
import * as React from 'react';
import Helmet from 'react-helmet';

const languages = SUPPORTED_LANGUAGES;
export const AlternateLanguageLinks: React.FC = () => {
	// we only care about the initial route for SEO - we specifically dont want to rerun this on each route change
	const location = window.location;
	const currentLanguage = useLanguage();
	const pathname = location.pathname;
	const links = languages.map((lang) => {
		if (lang === currentLanguage) {
			return null;
		}
		//simply replace the first occurence , this should work as long as we place the language in the front of the path
		const url = pathname.replace(currentLanguage, lang);
		const languageCode = toISO639(lang);
		return (
			<link
				key={languageCode}
				rel="alternate"
				hrefLang={languageCode}
				href={url}
			/>
		);
	});
	return <Helmet>{links}</Helmet>;
};
