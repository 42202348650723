import { generateUrlQuery, SportsRoutes } from 'materialbet-common';
import { getDefaultLanguage, toISO639 } from '@gaming-shell/i18n';
import {
	authRedirectBaseParams,
	tokenCookieBaseParams,
	signInParam,
	signUpParam
} from 'auth/config/route';
import { keyCloakParams } from 'auth/config/route';

export const getDefaultRedirectUri = () =>
	encodeURIComponent(
		`${window.location.origin}${SportsRoutes.root.create()}`
	);

export const getAuthRedirectBackUri = (type: string) => {
	const params = new URLSearchParams(window.location.search);
	keyCloakParams.forEach((param) => params.delete(param));
	if (!params.get(type)) params.append(type, 'true');
	return encodeURIComponent(
		`${window.location.origin}${
			window.location.pathname
		}?${params.toString()}`
	);
};

export const getAuthRedirectUrl = (
	type: string,
	defaultRedirectUri?: boolean
): string => {
	const authRouteParams = {
		...authRedirectBaseParams,
		/* eslint-disable @typescript-eslint/camelcase */
		ui_locales: toISO639(getDefaultLanguage()),
		/* eslint-disable @typescript-eslint/camelcase */
		redirect_uri: defaultRedirectUri
			? getDefaultRedirectUri()
			: getAuthRedirectBackUri(type)
	};
	const urlQuery = generateUrlQuery(authRouteParams);
	return `${AUTH_URL}/${type}?${urlQuery}`;
};

export const getCookieRequestBody = (code: string, param: string): string => {
	return generateUrlQuery({
		...tokenCookieBaseParams,
		/* eslint-disable @typescript-eslint/camelcase */
		redirect_uri: getAuthRedirectBackUri(param),
		code
	});
};

export const getAuthReturnKey = () => {
	const params = new URLSearchParams(window.location.search);
	const paramKeys = Array.from(params.keys());
	if (keyCloakParams.every((item) => paramKeys.indexOf(item) > -1)) {
		if (params.get(signInParam)) return signInParam;
		if (params.get(signUpParam)) return signUpParam;
	}
	return false;
};
