import { isMobile } from '@gaming-shell/device';
import { Theme } from '@gaming-shell/theming';
import { Paper, makeStyles } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
		background: isMobile()
			? theme.palette.background.paper
			: theme.palette.background.top.main
	}
}));

export const BigBetslipPanelContent: React.FunctionComponent<unknown> = ({
	children
}) => {
	const classes = useStyles();
	return (
		<Paper className={classes.root} square={!isMobile()}>
			{children}
		</Paper>
	);
};
