import { makeStyles, Theme } from '@material-ui/core/styles';

export const useIframeAreaStyles = makeStyles((theme: Theme) => ({
	background: ({ backgroundImgUrl }: { backgroundImgUrl?: string }) => ({
		background: `${theme.palette.background.paper}CC`, // Opacity 80
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundImage: backgroundImgUrl
			? `url(${backgroundImgUrl})`
			: undefined
	}),
	iframeWrapper: {
		position: 'relative',
		top: 0,
		width: '100%',
		height: '100%',
		transition: 'width 0.1s ease-in, height 0.1s ease-in' // transition when change screen size
	},
	iframe: {
		height: '100%',
		width: '100%',
		border: 0
	},
	iframeArea: {
		position: 'relative',
		transition: 'width 0.1s ease-in, height 0.1s ease-in', // transition when change screen size
		width: '100%',
		height: '100%',
		border: 0,
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	cinemaIframeArea: {
		paddingTop: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	// Override with important as the class is created before the ratio
	fullscreenIframeArea: {
		paddingTop: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh !important',
		width: '100vw !important'
	},
	fullscreenContent: {
		top: 'initial !important'
	},
	loadingWrapper: {
		position: 'absolute',
		height: '100%',
		width: '100%'
	},
	loading: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		transform: 'none',
		top: 'initial',
		left: 'initial',
		height: '100%',
		width: '100%',
		background: theme.palette.background.paper + '4D' // 30% opacity, just to make sure the text looks better
	}
}));
