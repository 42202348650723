import { FilterItem } from '@gaming-shell/layout';
import { mapCategoryToTag } from 'materialbet-common';
import { Hit } from 'react-instantsearch-core';

import { SearchResultItem } from '../Content';
import { groupByTags } from './groupByTags';

export const extractCategories = (hits: Hit<SearchResultItem>[]) => {
	const groupedHits = groupByTags(hits);
	const categories = Object.entries(groupedHits)
		.sort((a, b) => (a.length < b.length ? -1 : 1))
		.map<FilterItem>(([category]) => ({
			name: category,
			value: mapCategoryToTag(category)
		}));
	return categories;
};
