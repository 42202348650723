import { isMobile } from '@gaming-shell/device';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import clx from 'classnames';
import { SportIcon } from 'materialbet-icons';
import * as React from 'react';
import {
	PaperTitle,
	PaperTitleVariant
} from 'sports/components/displays/PaperTitle';
import { SportNameTranslation } from 'sports/components/displays/SportNameTranslation';
import { CustomPaper } from 'sports/components/materialWrapper/CustomPaper';
import { PaperVariant } from 'sports/components/materialWrapper/types';
import { SportType } from 'sports/config/sport';

export interface SportCardProps {
	sportKey: SportType;
	defaultCollapsed?: boolean;
	disableCollapsible?: boolean;
	paperVariant?: PaperVariant;
	paperTitleVariant?: PaperTitleVariant;
	children?: JSX.Element | JSX.Element[];
}

export const useStyles = makeStyles((theme: Theme) => ({
	card: ({ variant }: { variant: PaperVariant }) => ({
		marginBottom: variant === 'outline' ? theme.spacing(1) : 0,
		background: isMobile() ? 'transparent' : theme.palette.background.paper
	}),
	flag: {
		height: 24,
		width: 24,
		objectFit: 'contain',
		margin: theme.spacing(0, 1),
		flexShrink: 0
	},
	sportIcon: {
		opacity: 0.5
	}
}));

export const SportCard: React.FunctionComponent<SportCardProps> = (
	props: SportCardProps
) => {
	const {
		sportKey,
		defaultCollapsed,
		disableCollapsible,
		paperVariant = 'outline',
		paperTitleVariant,
		children
	} = props;
	const [isExpanded, toggleExpansion] = React.useState(!defaultCollapsed);
	const toggle = () => toggleExpansion(!isExpanded);
	const classes = useStyles({ variant: paperVariant });
	return (
		<CustomPaper variant={paperVariant} className={classes.card} square>
			<PaperTitle
				open={isExpanded}
				label={
					<>
						<SportIcon
							sportKey={sportKey}
							className={clx(classes.flag, classes.sportIcon)}
						/>
						<Typography variant="subtitle2">
							<SportNameTranslation sportKey={sportKey} />
						</Typography>
					</>
				}
				disableCollapsible={disableCollapsible}
				onOpenChange={toggle}
				variant={paperTitleVariant}
			/>
			{isExpanded && children}
		</CustomPaper>
	);
};
