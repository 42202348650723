import { makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
	createDynamicStyle,
	fillArray,
	makeDynamicStyles
} from 'materialbet-common';
import * as React from 'react';
import { EventListVariant } from 'sports/components/eventList/types';

const useStyles = makeStyles((theme: Theme) => ({
	event: {
		display: 'flex',
		margin: theme.spacing(1),
		alignItems: 'center'
	},
	/**@todo put into price list and create skeleton for price list */
	priceList: {
		display: 'flex',
		justifyContent: 'space-between',
		flexBasis: '50%'
	},
	priceButton: {
		borderRadius: '4px', //from top button,
		width: `calc((100% - ${theme.spacing(2)}px)/3)`
	}
}));

const useVariantStyles = makeDynamicStyles({
	team: createDynamicStyle(
		{
			display: 'flex',
			flexBasis: '50%'
		},
		{
			narrow: {
				flexDirection: 'column'
			},
			wide: {
				flexDirection: 'row'
			}
		}
	)
});

interface EventListSkeletonProps {
	variant?: EventListVariant;
	items?: number;
}

export const EventListSkeleton: React.FC<EventListSkeletonProps> = (props) => {
	const { variant = 'narrow', items = 5 } = props;
	const { event, priceList, priceButton } = useStyles();
	const { team } = useVariantStyles(variant);
	return (
		<>
			{fillArray(items, (i) => (
				<div className={event} key={i}>
					<div className={team}>
						<Skeleton width="80%" />
						{variant === 'narrow' && <Skeleton width="80%" />}
					</div>
					<div className={priceList}>
						{fillArray(3, (j) => (
							<Skeleton
								className={priceButton}
								variant="rect"
								height={32}
								key={`price_${i}_${j}`}
							/>
						))}
					</div>
				</div>
			))}
		</>
	);
};
