"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
var FutsalIcon = function (props) {
    return (React.createElement(core_1.SvgIcon, __assign({}, props),
        React.createElement("g", { stroke: "none", strokeWidth: "1", fill: props.fill, fillRule: "evenodd" },
            React.createElement("path", { d: "M7.2,8.93 L16.8,8.93 L16.8,13.73 C16.8,14.1442136 17.1357864,14.48 17.55,14.48 C17.9642136,14.48 18.3,14.1442136 18.3,13.73 L18.3,8.18 C18.3,7.76578644 17.9642136,7.43 17.55,7.43 L6.45,7.43 C6.03578644,7.43 5.7,7.76578644 5.7,8.18 L5.7,15.2285587 L3.5,17.0605871 L3.5,7 C3.5,6.05007023 4.27007023,5.28 5.22,5.28 L18.78,5.28 C19.2361724,5.28 19.6736611,5.46121377 19.9962237,5.78377634 C20.3187862,6.10633891 20.5,6.54382764 20.5,7 L20.5,17.0650739 L20.225446,16.8399559 C19.9050965,16.5773744 19.4325375,16.6242045 19.1699559,16.944554 C18.9073744,17.2649035 18.9542045,17.7374625 19.274554,18.0000441 L19.884554,18.5000441 C20.2565064,18.8167954 20.7934001,18.8906653 21.2497515,18.6804361 C21.7061028,18.4702068 21.9988662,18.0141353 22,17.5116896 L22,7 C22,6.1460029 21.660751,5.3269833 21.0568838,4.72311616 C20.4530167,4.11924903 19.6339971,3.78 18.78,3.78 L5.22,3.78 C3.44164311,3.78 2,5.22164311 2,7 L2,17.51 C2,18.2224251 2.57753039,18.8000933 3.29,18.8000933 C3.59447245,18.805821 3.90321756,18.6910837 4.14036927,18.4786865 L6.92993653,16.156334 C7.10105146,16.0138398 7.2,15.8026768 7.2,15.58 L7.2,8.93 Z", id: "Path", fill: props.fill, fillRule: "nonzero" }),
            React.createElement("path", { d: "M2.93966991,5.74033009 L5.93966991,8.74033009 C6.23256313,9.0332233 6.70743687,9.0332233 7.00033009,8.74033009 C7.2932233,8.44743687 7.2932233,7.97256313 7.00033009,7.67966991 L4.00033009,4.67966991 C3.70743687,4.3867767 3.23256313,4.3867767 2.93966991,4.67966991 C2.6467767,4.97256313 2.6467767,5.44743687 2.93966991,5.74033009 Z", id: "Path", fill: props.fill, fillRule: "nonzero" }),
            React.createElement("path", { d: "M19.9996699,4.67966991 L16.9996699,7.67966991 C16.7067767,7.97256313 16.7067767,8.44743687 16.9996699,8.74033009 C17.2925631,9.0332233 17.7674369,9.0332233 18.0603301,8.74033009 L21.0603301,5.74033009 C21.3532233,5.44743687 21.3532233,4.97256313 21.0603301,4.67966991 C20.7674369,4.3867767 20.2925631,4.3867767 19.9996699,4.67966991 Z", id: "Path", fill: props.fill, fillRule: "nonzero" }),
            React.createElement("path", { d: "M6.45,16.33 L11.18,16.33 C11.5942136,16.33 11.93,15.9942136 11.93,15.58 C11.93,15.1657864 11.5942136,14.83 11.18,14.83 L6.45,14.83 C6.03578644,14.83 5.7,15.1657864 5.7,15.58 C5.7,15.9942136 6.03578644,16.33 6.45,16.33 Z", id: "Path", fill: props.fill, fillRule: "nonzero" }),
            React.createElement("path", { d: "M6.45,11.4 L17.55,11.4 C17.9642136,11.4 18.3,11.0642136 18.3,10.65 C18.3,10.2357864 17.9642136,9.9 17.55,9.9 L6.45,9.9 C6.03578644,9.9 5.7,10.2357864 5.7,10.65 C5.7,11.0642136 6.03578644,11.4 6.45,11.4 Z", id: "Path", fill: props.fill, fillRule: "nonzero" }),
            React.createElement("path", { d: "M6.45,13.87 L17.55,13.87 C17.9642136,13.87 18.3,13.5342136 18.3,13.12 C18.3,12.7057864 17.9642136,12.37 17.55,12.37 L6.45,12.37 C6.03578644,12.37 5.7,12.7057864 5.7,13.12 C5.7,13.5342136 6.03578644,13.87 6.45,13.87 Z", id: "Path", fill: props.fill, fillRule: "nonzero" }),
            React.createElement("path", { d: "M10.9,15.58 L10.9,8.18 C10.9,7.76578644 10.5642136,7.43 10.15,7.43 C9.73578644,7.43 9.4,7.76578644 9.4,8.18 L9.4,15.58 C9.4,15.9942136 9.73578644,16.33 10.15,16.33 C10.5642136,16.33 10.9,15.9942136 10.9,15.58 Z", id: "Path", fill: props.fill, fillRule: "nonzero" }),
            React.createElement("path", { d: "M14.6,13.73 L14.6,8.18 C14.6,7.76578644 14.2642136,7.43 13.85,7.43 C13.4357864,7.43 13.1,7.76578644 13.1,8.18 L13.1,13.73 C13.1,14.1442136 13.4357864,14.48 13.85,14.48 C14.2642136,14.48 14.6,14.1442136 14.6,13.73 Z", id: "Path", fill: props.fill, fillRule: "nonzero" }),
            React.createElement("path", { d: "M15.29,21.27 C17.0683569,21.27 18.51,19.8283569 18.51,18.05 C18.51,16.2716431 17.0683569,14.83 15.29,14.83 C13.5116431,14.83 12.07,16.2716431 12.07,18.05 C12.07,19.8283569 13.5116431,21.27 15.29,21.27 Z M15.29,19.77 C14.3400702,19.77 13.57,18.9999298 13.57,18.05 C13.57,17.1000702 14.3400702,16.33 15.29,16.33 C16.2399298,16.33 17.01,17.1000702 17.01,18.05 C17.01,18.9999298 16.2399298,19.77 15.29,19.77 Z", id: "Oval", fill: props.fill, fillRule: "nonzero" }))));
};
exports.default = FutsalIcon;
