/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
	Identifier,
	IdentifierFromJSON,
	IdentifierFromJSONTyped,
	IdentifierToJSON
} from './';

/**
 * CompetitionWithCategoryResponse presents competition key with category
 * @export
 * @interface CompetitionWithCategoryResponse
 */
export interface CompetitionWithCategoryResponse {
	/**
	 *
	 * @type {Identifier}
	 * @memberof CompetitionWithCategoryResponse
	 */
	category?: Identifier;
	/**
	 *
	 * @type {string}
	 * @memberof CompetitionWithCategoryResponse
	 */
	key?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompetitionWithCategoryResponse
	 */
	name?: string;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof CompetitionWithCategoryResponse
	 */
	tags?: Array<string>;
}

export function CompetitionWithCategoryResponseFromJSON(
	json: any
): CompetitionWithCategoryResponse {
	return CompetitionWithCategoryResponseFromJSONTyped(json, false);
}

export function CompetitionWithCategoryResponseFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompetitionWithCategoryResponse {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		category: !exists(json, 'category')
			? undefined
			: IdentifierFromJSON(json['category']),
		key: !exists(json, 'key') ? undefined : json['key'],
		name: !exists(json, 'name') ? undefined : json['name'],
		tags: !exists(json, 'tags') ? undefined : json['tags']
	};
}

export function CompetitionWithCategoryResponseToJSON(
	value?: CompetitionWithCategoryResponse | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		category: IdentifierToJSON(value.category),
		key: value.key,
		name: value.name,
		tags: value.tags
	};
}
