"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSlider = void 0;
var react_1 = require("react");
var React = __importStar(require("react"));
var createShowArrowListener = function (sliderRef, sliderWrappRef, setArrowState) { return function () {
    var slider = sliderRef.current;
    var sliderWrapper = sliderWrappRef.current;
    var slidesWrapper = sliderWrapper && sliderWrapper.querySelector('ul');
    var sliderWidth = (slider && slider.clientWidth) || 0;
    var slidesWrapperWidth = (slidesWrapper && slidesWrapper.clientWidth) || 0;
    setArrowState(sliderWidth > slidesWrapperWidth);
    //** update slider after resize if slider was used */
}; };
exports.useSlider = function (sliderRef, sliderWrappRef, setArrowState) {
    var _a = react_1.useState(false), isNextDisabled = _a[0], setNextDisabled = _a[1];
    var _b = react_1.useState(true), isPrevDisabled = _b[0], setPrevDisabled = _b[1];
    var getCurrentTranslate = function (myElement) {
        var matrix = window
            .getComputedStyle(myElement)
            .getPropertyValue('transform')
            .replace(/[^0-9\-.,]/g, '')
            .split(',');
        var x = matrix[12] || matrix[4];
        return parseInt(x);
    };
    var getNewPosition = function (isNext, currentTranslate, slideToSwipe, slideWidth) {
        var newPosition = 0;
        if (isNext) {
            newPosition = currentTranslate - slideToSwipe * slideWidth;
        }
        else {
            newPosition = currentTranslate + slideToSwipe * slideWidth;
        }
        return newPosition;
    };
    var changeSlides = function (direction) {
        if (!sliderRef || !sliderWrappRef) {
            return;
        }
        /** Direction to change animation */
        var isNext = direction === 'next';
        /** Slider container */
        var slider = sliderRef.current;
        /** Slider container wrapper that transform */
        var sliderWrapper = sliderWrappRef.current;
        /** Slide of slider, need to calculate transition value  */
        var slideAll = sliderWrapper && sliderWrapper.querySelectorAll('ul li');
        /** Container with all slides, need to calculate transition max value */
        var slidesWrapper = sliderWrapper && sliderWrapper.querySelector('ul');
        if (!slider || !sliderWrapper || !slidesWrapper || !slideAll) {
            return;
        }
        /** Slider width in screen */
        var sliderWidth = slider.clientWidth;
        /** Width of all slides in slider  */
        var slidesWidth = slidesWrapper.clientWidth;
        /** Width of one single slide */
        var slideWidth = slidesWidth / slideAll.length;
        /** Slides number that should transform  */
        var slideToSwipe = Math.floor(sliderWidth / slideWidth);
        /** Get current transformX value of sliderWrapper */
        var currentTranslate = getCurrentTranslate(sliderWrapper);
        /** Get new translate value */
        var newPosition = getNewPosition(isNext, currentTranslate, slideToSwipe, slideWidth);
        var maxNextSliderTransitionValue = slidesWidth - sliderWidth;
        var maxNextAnimationValue = slidesWidth - sliderWidth;
        if ((!isNext && currentTranslate === 0) ||
            (isNext && currentTranslate === -maxNextSliderTransitionValue)) {
            return;
        }
        /** Set new transform value for slider */
        if (isNext && newPosition <= -maxNextAnimationValue) {
            //** Set slider to the last slide */
            sliderWrapper.style.transform = "translateX(-" + maxNextSliderTransitionValue + "px)";
            setNextDisabled(true);
            setPrevDisabled(false);
        }
        else if (!isNext && newPosition >= 0) {
            //** Set slider to the first slide */
            sliderWrapper.style.transform = 'translateX(0px)';
            setPrevDisabled(true);
            setNextDisabled(false);
        }
        else {
            //** Set slider to the next/prev slide */
            sliderWrapper.style.transform = "translateX(" + newPosition + "px)";
            setNextDisabled(false);
            setPrevDisabled(false);
        }
    };
    React.useEffect(function () {
        var showArrowListener = createShowArrowListener(sliderRef, sliderWrappRef, setArrowState);
        window.addEventListener('resize', showArrowListener);
        showArrowListener();
        return function () { return window.removeEventListener('resize', showArrowListener); };
    }, [setArrowState, sliderRef, sliderWrappRef]);
    return [isNextDisabled, isPrevDisabled, changeSlides];
};
exports.default = exports.useSlider;
