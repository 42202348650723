"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickDepositButton = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var core_1 = require("@material-ui/core");
var player_1 = require("@materialbet-core/player");
var materialbet_common_1 = require("materialbet-common");
var routing_1 = require("@gaming-shell/routing");
var React = __importStar(require("react"));
exports.QuickDepositButton = function (_a) {
    var onClick = _a.onClick, _b = _a.label, label = _b === void 0 ? 'deposit' : _b, rest = __rest(_a, ["onClick", "label"]);
    var trans = i18n_1.useTranslation();
    var activeCurrency = player_1.usePlayerCurrency();
    var availableCurrencies = player_1.usePlayerCurrenciesAvailable();
    var wallets = player_1.usePlayerWallets();
    var depositAddresses = player_1.usePlayerDepositAddresses();
    var enqueueSnackbar = materialbet_common_1.useSnackbar().enqueueSnackbar;
    var _c = materialbet_common_1.useToggle(), open = _c[0], toggleModal = _c[1];
    var countryRestrictionTier = player_1.usePlayerForcedRoutes().countryRestrictionTier;
    var disabled = countryRestrictionTier === 2;
    var _d = React.useState(activeCurrency), currentCurrency = _d[0], setCurrentCurrency = _d[1];
    var minDepositAmount = player_1.usePlayerCurrencyMinDepositAmount(currentCurrency) ||
        0;
    React.useEffect(function () {
        setCurrentCurrency(activeCurrency);
    }, [activeCurrency, open, setCurrentCurrency]);
    var availableCryptoCurrencies = React.useMemo(function () {
        return availableCurrencies.filter(function (currency) { var _a; return ((_a = materialbet_common_1.getCurrencyConfig(currency)) === null || _a === void 0 ? void 0 : _a.type) === materialbet_common_1.CurrencyType.CRYPTO; });
    }, [availableCurrencies]);
    var onCopyClick = React.useCallback(function () {
        enqueueSnackbar(trans('player:deposit.addressCopied'));
    }, [enqueueSnackbar, trans]);
    var onButtonClick = React.useCallback(function (event) {
        var _a;
        if (((_a = materialbet_common_1.getCurrencyConfig(activeCurrency)) === null || _a === void 0 ? void 0 : _a.type) === materialbet_common_1.CurrencyType.FIAT) {
            routing_1.history.push(materialbet_common_1.PlayerRoutes.cashierDeposit.create({
                currency: activeCurrency
            }));
            return;
        }
        toggleModal();
        onClick && onClick(event);
    }, [toggleModal, onClick, activeCurrency]);
    if (disabled) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(core_1.Button, __assign({ color: "secondary", variant: "contained", onClick: onButtonClick, "aria-label": "appbar-quick-deposit-button" }, rest),
            React.createElement(i18n_1.Translate, { label: "actions." + label, ns: "common" })),
        React.createElement(materialbet_common_1.QuickDeposit, { open: open, onClose: toggleModal, onCurrencyChange: setCurrentCurrency, onCopyClick: onCopyClick, currentWalletDetails: {
                currency: currentCurrency,
                formattedCurrency: materialbet_common_1.formatCurrency((wallets[currentCurrency] || {}).amount || 0, currentCurrency, {
                    keepTrailingZeros: true
                }),
                minDepositAmount: minDepositAmount,
                depositAddress: depositAddresses[currentCurrency]
            }, currencies: availableCryptoCurrencies, "aria-label": "Quick deposit" })));
};
