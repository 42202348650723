import * as queryString from 'querystring';

import { useTranslation } from '@gaming-shell/i18n';
import { history } from '@gaming-shell/routing';
import { AuthRoutes, PlayerRoutes } from 'materialbet-common';
import { BaseRoutes, useSnackbar } from 'materialbet-common';
import * as React from 'react';
import { useLocation } from 'react-router';

export const Notify: React.FC = () => {
	const { enqueueSnackbar } = useSnackbar();
	const trans = useTranslation();
	const { search } = useLocation();

	const AccountRoute = PlayerRoutes.account.create();
	const messagesMap: {
		[key: string]: {
			message?: string;
			redirectTo?: string;
			action?: () => void;
		};
	} = {
		PLAYER_CONFIRMED: {
			message: 'player:signUp.confirmationSuccessful',
			redirectTo: BaseRoutes.root.create()
		},
		PLAYER_ALREADY_CONFIRMED: {
			message: 'player:signUp.confirmedAlready',
			redirectTo: AuthRoutes.signIn.create()
		},
		CONFIRMATION_TOKEN_INVALID: {
			message: 'player:signUp.confirmationFailed',
			redirectTo: BaseRoutes.root.create()
		},
		WITHDRAWAL_CONFIRMED: {
			message: 'player:withdrawal.success',
			redirectTo: AccountRoute
		},
		WITHDRAWAL_TOKEN_INVALID: {
			message: 'player:withdrawal.invalidToken',
			redirectTo: AccountRoute
		},
		WITHDRAWAL_TOKEN_EXPIRED: {
			message: 'player:withdrawal.tokenExpired',
			redirectTo: AccountRoute
		},
		NOT_ALLOWED: {
			redirectTo: AccountRoute
		},
		NOT_SIGNED_IN: {
			message: 'common:application.descriptions.notSignedIn'
		}
	};

	const handleMessage = (message: string) => {
		const msg = messagesMap[message];
		if (msg) {
			msg.action && msg.action();
			msg.message && enqueueSnackbar(trans(msg.message));
			msg.redirectTo && history.push(msg.redirectTo);
		} else {
			history.push(BaseRoutes.root.create());
		}
		return null;
	};

	const query = queryString.parse(search.split('?')[1]);
	return Object.keys(query).length && query.msg
		? handleMessage(query.msg as string)
		: null;
};

export default Notify;
