import { makeStyles, Theme } from '@material-ui/core/styles';

// Tabs
// TODO tabs should be transparent by default, but they have been faultily over written in the theme to fit the background color of the masthead. This is a temporary solution and should be removed once we have the theme fixed
export const useTabStyles = makeStyles((theme: Theme) => ({
	root: {
		background: 'transparent',
		minWidth: 'max-content', // use the content width to make the tab button smaller,
		fontSize: theme.typography.body2.fontSize, // From Design, 14px,
		letterSpacing: 1.25 // From Design
	}
}));

export const useTabsStyles = makeStyles((theme: Theme) => ({
	root: {
		background: 'transparent',
		zIndex: theme.zIndex.appBar,
		position: 'inherit'
	},
	indicator: {
		backgroundColor: theme.palette.primary.main
	}
}));

export const useOutcomeStyles = makeStyles((theme: Theme) => ({
	root: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: 'flex',
		marginRight: theme.spacing(1)
	},
	button: {
		justifyContent: 'left',
		alignContent: 'center',
		display: 'flex'
	},
	simplifiedButton: {
		justifyContent: 'center',
		alignContent: 'center',
		display: 'flex'
	}
}));
