import { Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

export enum AnimatedHamburgerIconType {
	MENU = 'menu',
	BACK = 'caret',
	CLOSE = 'x'
}

// union of all values from enum above
type AnimatedHamburgerIconTypes = typeof AnimatedHamburgerIconType[keyof typeof AnimatedHamburgerIconType];

export interface AnimatedHamburgerIconProps {
	/* Determines icon and transition */
	type: AnimatedHamburgerIconType | AnimatedHamburgerIconTypes;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	hamburgerBox: {
		width: 18,
		height: 12,
		display: 'inline-block',
		position: 'relative',
		margin: '6px 3px'
	},
	bar: {
		width: 18,
		height: 2,
		backgroundColor: theme.palette.primary.contrastText,
		position: 'absolute',
		borderRadius: theme.shape.borderRadius,
		transitionProperty: 'transform, opacity',
		transitionDuration: `${theme.transitions.duration.standard / 1000}s`,
		transitionTimingFunction: 'ease'
	},
	before: {
		top: 0
	},
	inner: {
		top: 5
	},
	after: {
		bottom: 0
	},
	x: {
		'& .hamburger-before': {
			transform: 'translate3d(0, 5px, 0) rotate(45deg)'
		},
		'& .hamburger-inner': {
			transform: 'translateX(-12px)',
			opacity: 0
		},
		'& .hamburger-after': {
			transform: 'translate3d(0, -5px, 0) rotate(-45deg)'
		}
	},
	caret: {
		'& .hamburger-before': {
			transform: 'translate3d(0px, 2px, 0) rotate(-45deg) scale(0.5, 1)'
		},
		'& .hamburger-inner': {
			transform: 'translateX(-12px) scale(0.5, 1)',
			opacity: 0
		},
		'& .hamburger-after': {
			transform: 'translate3d(0px, -2px, 0) rotate(45deg) scale(0.5, 1)'
		}
	}
}));

export const AnimatedHamburgerIcon: React.FunctionComponent<AnimatedHamburgerIconProps> = ({
	type,
	className
}) => {
	const styles = useStyles();

	return (
		<div className={clsx(styles.hamburgerBox, styles[type])}>
			<div
				className={clsx(
					'hamburger-before',
					styles.bar,
					styles.before,
					className
				)}
			/>
			<div
				className={clsx(
					'hamburger-inner',
					styles.bar,
					styles.inner,
					className
				)}
			/>
			<div
				className={clsx(
					'hamburger-after',
					styles.bar,
					styles.after,
					className
				)}
			/>
		</div>
	);
};
