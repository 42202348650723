"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogoutButton = void 0;
var React = __importStar(require("react"));
var i18n_1 = require("@gaming-shell/i18n");
var routing_1 = require("@gaming-shell/routing");
var api_1 = require("@materialbet-core/api");
var player_1 = require("@materialbet-core/player");
var materialbet_common_1 = require("materialbet-common");
exports.LogoutButton = function (buttonProps) {
    var _a = React.useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var authToken = player_1.usePlayerAuthToken();
    var updatePlayer = player_1.useUpdatePlayer();
    var enqueueSnackbar = materialbet_common_1.useSnackbar().enqueueSnackbar;
    var trans = i18n_1.useTranslation();
    var onLogout = React.useCallback(function (token) { return function () {
        setIsSubmitting(true);
        api_1.api.postSignOut(token)
            .then(function (response) {
            var token = response
                .authenticity_token;
            if (response.result === 'INVALID_AUTHENTICITY_TOKEN') {
                updatePlayer(function (player) { return (__assign(__assign({}, player), { authToken: token })); });
                // setTimeout ensures that the next sign in placed in an event queue which is called after this method returned (js version of defer)
                setTimeout(function () { return onLogout(token)(); });
                return;
            }
            if (response.result === 'OK') {
                updatePlayer(function (player) { return (__assign(__assign({}, player_1.defaultState), { ipCountry: player.ipCountry, authToken: token, isLoading: false })); });
                routing_1.clearSessionHistory();
                routing_1.history.replace(materialbet_common_1.AuthRoutes.signIn.create());
            }
        })
            .catch(function () {
            enqueueSnackbar(trans('player:descriptions.failedRequest'));
        })
            .finally(function () { return setIsSubmitting(false); });
    }; }, [updatePlayer, setIsSubmitting, enqueueSnackbar, trans]);
    var newAuthSignOutHandler = function () {
        var path = materialbet_common_1.AuthRoutes.logout.create();
        routing_1.history.replace(path);
    };
    return (React.createElement(materialbet_common_1.ProgressButton, __assign({ submitting: isSubmitting, onClick: materialbet_common_1.hasAuthEnabled() ? newAuthSignOutHandler : onLogout(authToken), color: "primary", variant: "contained" }, buttonProps),
        React.createElement(i18n_1.Translate, { label: "actions.signOut", ns: "common" })));
};
exports.default = exports.LogoutButton;
