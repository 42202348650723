import { Divider, makeStyles, Theme } from '@material-ui/core';
import { SearchResultsProps } from 'casino/types/sharedComponents';
import * as React from 'react';
import { connectHits, connectStateResults } from 'react-instantsearch-dom';

import {
	AlgoliaHitsProvided,
	SearchResultsContent,
	SearchResultsContentProps
} from './Content';
import { SearchResultsHeader } from './Header';
import { groupByTags } from './utils';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2, 1)
	},
	divider: {
		margin: theme.spacing(1, 0, 2, 0)
	}
}));

const SearchResults: React.FC<SearchResultsProps & AlgoliaHitsProvided> = ({
	isSearchStalled,
	searching,
	filters,
	hits
}) => {
	const classes = useStyles();

	/* don't return anything if there's no data */
	if (hits.length === 0) return null;
	const groupedHits = Object.entries(groupByTags(hits)).filter(
		([category]) =>
			!filters?.length ||
			filters?.find((filter) => filter.name === category)
	);

	return (
		<>
			{groupedHits.map(([category, categoryHits]) => (
				<div key={category} className={classes.root}>
					<SearchResultsHeader category={category} />
					<Divider className={classes.divider} />
					{/* Results content are connected to algolia */}
					<SearchResultsContent
						hits={categoryHits}
						skeleton={isSearchStalled || searching}
					/>
				</div>
			))}
		</>
	);
};

/* that's required workaround because of algolia bug https://github.com/algolia/react-instantsearch/issues/137 */
const ConnectedSearchResults = connectHits(
	(connectStateResults(SearchResults) as unknown) as React.FC<
		SearchResultsContentProps & AlgoliaHitsProvided
	>
);
export { ConnectedSearchResults as SearchResults };
