import { isDesktop } from '@gaming-shell/device';
import { Translate } from '@gaming-shell/i18n';
import { Fab, Theme, Typography, Zoom, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { BetCount } from 'sports/components/displays/BetCount';

interface BetslipCountButtonProps {
	count: number;
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	float?: boolean;
	hidden?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		background: theme.palette.primary.main
	},
	float: {
		position: 'fixed',
		right: isDesktop() ? theme.spacing(2) : theme.spacing(1),
		bottom: isDesktop() ? theme.spacing(2) : theme.spacing(1),
		zIndex: 2
	},
	text: {
		marginLeft: theme.spacing(1)
	}
}));

// TODO: To be confirmed for the new design of button and text
export const BigBetslipCountButton: React.FunctionComponent<BetslipCountButtonProps> = ({
	count,
	onClick,
	float,
	hidden
}) => {
	const classes = useStyles();
	return (
		<Zoom in={!hidden}>
			<Fab
				variant={isDesktop() ? 'extended' : 'round'}
				aria-label="delete"
				className={`${classes.root} ${float && classes.float}`}
				onClick={onClick}
			>
				<BetCount count={count} />
				{isDesktop() && (
					<Typography variant="subtitle1" className={classes.text}>
						<Translate label="betting.betslip" ns="sports" />
					</Typography>
				)}
			</Fab>
		</Zoom>
	);
};
