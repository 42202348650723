import * as cookie from 'cookie';

export const currencyVariantCookieName = 'currencyVariant';

export const getPersistedCurrencyVariant = () => {
	const cookies = cookie.parse(document.cookie);
	return cookies[currencyVariantCookieName] || '';
};

export const persistCurrencyVariant = (variant: string) => {
	document.cookie = `${currencyVariantCookieName}=${variant};path=/`;
};

export const resetCurrencyVariant = () => {
	document.cookie =
		currencyVariantCookieName + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
