/** @file a file to have list of sports which are arrays */
import {
	CommonSportType,
	EsportType,
	OutrightSportType,
	SportType
} from './types';

export const supportedCommonSports = Object.keys(CommonSportType).map(
	(key) => CommonSportType[key]
);
export const supportedEsports = Object.keys(EsportType).map(
	(key) => EsportType[key]
);

/** @description sport with only outright, may be a better name like other sports */
export const supportedOutrightSports = Object.keys(OutrightSportType).map(
	(key) => OutrightSportType[key]
);

export const supportedSports = [
	...supportedCommonSports,
	...supportedEsports,
	...supportedOutrightSports
];

/** @description Hardcoded here on request, https://bitgamelabs.atlassian.net/browse/SPOFE-228 */
export const featuredSports: SportType[] = [
	SportType['soccer'],
	SportType['tennis'],
	SportType['basketball'],
	SportType['baseball'],
	SportType['mma'],
	SportType['ice-hockey'] // during NHL season
];

export const supportedVirtualSports: SportType[] = [
	SportType['soccer'],
	SportType['basketball']
];
