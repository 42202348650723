import { Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

export interface TagProps {
	color?: 'primary' | 'secondary';
}

const getColorStyle = (theme: Theme, color: Required<TagProps>['color']) => ({
	backgroundColor: theme.palette[color].main,
	color: theme.palette[color].contrastText,
	fontSize: theme.typography.caption.fontSize,
	textTransform: theme.typography.overline.textTransform,
	display: 'inline-block',
	padding: '1px 5px',
	verticalAlign: 'text-bottom'
});

const useStyles = makeStyles((theme: Theme) => ({
	primary: getColorStyle(theme, 'primary'),
	secondary: getColorStyle(theme, 'secondary')
}));

export const Tag: React.FunctionComponent<TagProps> = ({
	color = 'primary',
	children
}) => {
	const classes = useStyles();
	const className = classes[color];

	return <Typography className={className}>{children}</Typography>;
};
