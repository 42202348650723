import debounce from 'lodash/debounce';
import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { defaultIframeRatio } from '../configs/ratio';
import { getRatioFromString, parseRatio } from './ratioUtils';

export interface Dimensions {
	height: number;
	width: number;
}
export const dimension = (width: number, height: number): Dimensions => ({
	width,
	height
});

export const fillAvailableSpaceWhileKeepingRatio = ({
	height,
	width,
	ratio
}: {
	/** @description available space height */
	height: number;
	/** @description available space width */
	width: number;
	/** @description relative ratio in width/height */
	ratio: number;
}): Dimensions => {
	const availableRatio = width / height;
	if (availableRatio === ratio) {
		return dimension(width, height);
	}
	if (availableRatio < ratio) {
		return dimension(width, width / ratio);
	}
	return dimension(height * ratio, height);
};

export const getDefaultDimensions = (ratio = defaultIframeRatio) => ({
	defaultWidth: '100%',
	defaultHeight: `${100 - parseRatio(ratio)}%`
});

export interface AvailableDimensionsParams {
	windowHeight: number;
	windowWidth: number;
	parentWidth: number;
	headerHeight: number;
	actionBarHeight: number;
	positionTop: number;
	fullscreen?: boolean;
	cinema?: boolean;
	showSidemenu?: boolean;
	sidemenuWidth?: number;
	ratio?: string;
}

/** @description Given the window dimensions, parent dimensions and component inside the size */
// Calculated the spaces that the size of widget that can be, relevant to the ratio
export const getAvailableDimensions = ({
	windowHeight,
	windowWidth,
	parentWidth,
	headerHeight,
	actionBarHeight,
	positionTop,
	fullscreen,
	cinema,
	showSidemenu,
	sidemenuWidth = 0,
	ratio = defaultIframeRatio
}: AvailableDimensionsParams) => {
	const width = cinema || fullscreen ? windowWidth : parentWidth;
	// Max Height for
	const availableHeight =
		windowHeight -
		(fullscreen ? 0 : positionTop + headerHeight + actionBarHeight);
	const availableWidth = width - (showSidemenu && cinema ? sidemenuWidth : 0);
	// Ratio
	const aspectRatio = getRatioFromString(ratio);
	// Calculate Height and Width of Game
	const ratioAvailableHeight =
		fullscreen ||
		cinema ||
		!(availableWidth / availableHeight < aspectRatio)
			? availableHeight // use full height if possible
			: availableWidth / aspectRatio; // Calculated by ratio
	return { height: ratioAvailableHeight, width: availableWidth };
};

export const useWindowDimensionsResize = (callback?: () => void) => {
	const [, setWindowDimension] = React.useState(
		`${window.innerWidth}x${window.innerHeight}`
	);
	const onWindowResized = React.useCallback(
		debounce(() => {
			// Not expected the window size to be changed frequently, debounce its added here to avoid loads on render
			// Debounce will also be applied when switch to fullscreen mode
			// Handle on resize width of window
			setWindowDimension(`${window.innerWidth}x${window.innerHeight}`);
			callback && callback();
		}, 1000),
		[]
	);
	React.useEffect(() => {
		window.addEventListener('resize', onWindowResized);
		callback && callback();
		return () => {
			window.removeEventListener('resize', onWindowResized);
		};
	}, [onWindowResized, callback]);
};

/** @descripion tracks the dimensions of the given html element */
export const useElementDimensionsResize = (element?: HTMLElement | null) => {
	const width = element?.offsetWidth || 0;
	const height = element?.offsetHeight || 0;
	const [, setElementDimensions] = React.useState(
		`${width || 0}*${height || 0}`
	);
	// Listen to change of parent dimension
	const elementResizeCallback: ResizeObserverCallback = (entries) => {
		if (!entries || !entries.length) {
			return;
		}
		for (const entry of entries) {
			const width = entry.contentRect.width;
			const height = entry.contentRect.height;
			setElementDimensions(
				`${Math.round(width) || 0}*${Math.round(height) || 0}`
			);
		}
	};
	React.useEffect(() => {
		const observer = new ResizeObserver(elementResizeCallback);
		if (element) {
			observer.observe(element);
		}
		return () => {
			if (element) {
				observer.unobserve(element);
			}
		};
	}, [element]);
	return {
		width,
		height
	};
};
