// import camelize from 'sports/types/camelize';
import { warning } from '@gaming-shell/logging';
import {
	api as apiSchema,
	messageFor,
	notifications as notificationsSchema,
	pusher as pusherSchema
} from 'gaming-protobuf-schema';
import Pbf from 'pbf';
import { gunzip } from 'zlib';

export interface EncodedPusherMessage {
	metadata: EncodedPusherMessageMetaData;
	message_type: string;
	uuid: string;
	length: number;
	offset: number;
	data: string;
}
export interface EncodedPusherMessageMetaData {
	message_type: string;
	version: number;
	resource_key: number;
}
interface Frame {
	data: unknown[];
	remaining: number;
}
type Callback = (
	data: unknown,
	entityId?: number | string,
	messageType?: string
) => void;

export const reassembleFrames = (callback: Callback) => {
	const frameBuffer: { [uuid: string]: Frame } = {};
	return (message: EncodedPusherMessage) => {
		let messageType = message.message_type;
		let version = 0;
		let entitityId = 0; // event ID, outright ID
		const metadata = message.metadata;

		if (metadata) {
			messageType = metadata.message_type;
			version = metadata.version;
			entitityId = metadata.resource_key;
		}

		// if (!messageType) {
		// 	message = camelize(message);
		// 	// TODO I do not know when or why that would happen
		// 	callback(message);
		// 	return;
		// }

		if (!frameBuffer[message.uuid]) {
			frameBuffer[message.uuid] = {
				data: new Array(message.length),
				remaining: message.length
			};
		}

		let dataBuffer = frameBuffer[message.uuid].data;
		dataBuffer = dataBuffer
			.splice(message.offset, message.data.length)
			.concat(message.data.split(''));

		frameBuffer[message.uuid].remaining -= message.data.length;

		if (frameBuffer[message.uuid].remaining === 0) {
			delete frameBuffer[message.uuid];
			gunzip(
				Buffer.from(dataBuffer.join(''), 'base64'),
				(err, buffer) => {
					let MessageType;
					if (version === 3) {
						if (messageType === 'EventV6') {
							MessageType = messageFor(
								'sports_entities_api_v6_pusher.Event'
							);
						} else if (messageType === 'SubmarketV6') {
							MessageType = messageFor(
								'sports_entities_api_v6_pusher.Submarket'
							);
						} else if (messageType === 'Submarket') {
							MessageType = messageFor('pusherv2.Submarket');
						} else if (messageType === 'EventListUpdate') {
							MessageType = messageFor(
								'sports_entities_api_v6_pusher.EventListUpdate'
							);
						} else {
							MessageType =
								pusherSchema[messageType] ||
								apiSchema[messageType];
						}
					} else if (messageType === 'Notification') {
						MessageType = notificationsSchema[messageType];
					}
					if (!MessageType) {
						warning(
							`PusherParser: No message type available for message of type ${messageType} with entitityId ${entitityId} and versions ${version}`
						);
						return;
					}
					const pbf = new Pbf(buffer);
					const data = MessageType.read(pbf);

					callback(data, entitityId, messageType);
				}
			);
		}
	};
};
