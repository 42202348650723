import { api } from '@materialbet-core/api';
import fetchIntercept from 'fetch-intercept';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const requestInterceptor = (url: string, config: any) => {
	const headers: HeadersInit = config && config.headers;
	if (
		['POST', 'PUT'].includes(config?.method) &&
		!headers?.['X-Csrf-Token']
	) {
		if (config.removeCSRF) {
			delete config.removeCSRF;
			return [url, config];
		}
		return api.getCsrfToken().then((response) => {
			if (response.result === 'OK') {
				config.headers = {
					...headers,
					'X-Csrf-Token': response.player.authenticity_token
				};
			}
			return [url, config];
		});
	} else {
		return [url, config];
	}
};

export default () =>
	fetchIntercept.register({
		request: requestInterceptor
	});
