"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gtag = exports.initialize = void 0;
exports.initialize = function (gtmId) {
    var datalayerSetup = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\nnew Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','" + gtmId + "');";
    var initNode = document.createElement('script');
    initNode.textContent = datalayerSetup;
    var firstScriptNode = document.getElementsByTagName('script')[0];
    var parent = firstScriptNode.parentNode;
    if (parent) {
        parent.insertBefore(initNode, null);
    }
    var noScript = document.createElement('noscript');
    var noScriptIframe = document.createElement('iframe');
    noScriptIframe.src = "https://www.googletagmanager.com/ns.html?id=" + gtmId;
    noScriptIframe.height = '0';
    noScriptIframe.width = '0';
    noScriptIframe.style.display = 'none';
    noScriptIframe.style.visibility = 'hidden';
    noScript.insertBefore(noScriptIframe, null);
    document.body.insertBefore(noScript, document.body.firstChild);
};
exports.gtag = function () {
    var _a;
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (!window.dataLayer) {
        return;
    }
    (_a = window.dataLayer).push.apply(_a, args);
};
