import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

export const QuickInputIcon: React.FunctionComponent<SvgIconProps> = (
	props
) => {
	return (
		<SvgIcon {...props}>
			<g fill="none" fillRule="evenodd" opacity=".9">
				<rect
					width="9"
					height="8.9"
					x=".5"
					y=".5"
					fill="#6D7278"
					stroke="#6D7278"
					rx="2"
				/>
				<rect
					width="9"
					height="8.9"
					x=".5"
					y="12.6"
					fill="#6D7278"
					stroke="#6D7278"
					rx="2"
				/>
				<rect
					width="9"
					height="8.9"
					x="12.5"
					y=".5"
					fill="#6D7278"
					stroke="#6D7278"
					rx="2"
				/>
				<g transform="translate(12 12.1)">
					<rect
						width="9"
						height="8.9"
						x=".5"
						y=".5"
						fill="#6D7278"
						stroke="#6D7278"
						rx="2"
					/>
					<path
						stroke="#FFF"
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M2.125 4.95L4 6.95l3.995-4"
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
