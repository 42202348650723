"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrencyVariantConfig = exports.getCurrencyConfig = exports.config = void 0;
var types_1 = require("../types");
var getUsdEquivalent = function (label, fullName) {
    var _a;
    return ({
        label: label,
        fullName: fullName,
        type: types_1.CurrencyType.CRYPTO,
        defaultStake: 10,
        variants: (_a = {},
            _a[label] = {
                decimalPlaces: 2,
                factor: 1,
                label: label
            },
            _a),
        defaultVariant: label
    });
};
var getGoldEquivalent = function (label, fullName) {
    var _a;
    return ({
        label: label,
        fullName: fullName,
        type: types_1.CurrencyType.CRYPTO,
        defaultStake: 10,
        variants: (_a = {},
            _a[label] = {
                decimalPlaces: 4,
                factor: 1,
                label: label
            },
            _a["m" + label] = {
                decimalPlaces: 2,
                factor: 1000,
                label: "m" + label
            },
            _a),
        defaultVariant: label
    });
};
exports.config = {
    BTC: {
        label: 'BTC',
        fullName: 'Bitcoin',
        type: types_1.CurrencyType.CRYPTO,
        defaultStake: 0.001,
        variants: {
            BTC: {
                decimalPlaces: 8,
                factor: 1,
                label: 'BTC'
            },
            mBTC: {
                decimalPlaces: 5,
                factor: 1000,
                label: 'mBTC'
            },
            sat: {
                decimalPlaces: 0,
                factor: 100000000,
                label: 'sat'
            }
        },
        defaultVariant: 'BTC'
    },
    BCH: {
        label: 'BCH',
        fullName: 'Bitcoin Cash',
        type: types_1.CurrencyType.CRYPTO,
        defaultStake: 0.001,
        variants: {
            BCH: {
                decimalPlaces: 8,
                factor: 1,
                label: 'BCH'
            },
            mBCH: {
                decimalPlaces: 2,
                factor: 1000,
                label: 'mBCH'
            }
        },
        defaultVariant: 'BCH'
    },
    ETH: {
        label: 'ETH',
        fullName: 'Ethereum',
        type: types_1.CurrencyType.CRYPTO,
        defaultStake: 0.001,
        variants: {
            ETH: {
                decimalPlaces: 8,
                factor: 1,
                label: 'ETH'
            },
            mETH: {
                decimalPlaces: 5,
                factor: 1000,
                label: 'mETH'
            }
        },
        defaultVariant: 'ETH'
    },
    INR: {
        label: 'INR',
        fullName: 'Indian Rupee',
        type: types_1.CurrencyType.FIAT,
        defaultStake: 100,
        variants: {
            INR: {
                decimalPlaces: 2,
                factor: 1,
                label: 'INR'
            }
        },
        defaultVariant: 'INR'
    },
    MYR: {
        label: 'MYR',
        fullName: 'Malaysian Ringgit',
        type: types_1.CurrencyType.FIAT,
        defaultStake: 10,
        variants: {
            MYR: {
                decimalPlaces: 2,
                factor: 1,
                label: 'MYR'
            }
        },
        defaultVariant: 'MYR'
    },
    PTS: {
        label: 'PTS',
        fullName: 'PTS',
        type: types_1.CurrencyType.FIAT,
        defaultStake: 10,
        variants: {
            PTS: {
                decimalPlaces: 2,
                factor: 1,
                label: 'PTS'
            }
        },
        defaultVariant: 'PTS'
    },
    USD: {
        label: 'USD',
        fullName: 'American Dollar',
        type: types_1.CurrencyType.FIAT,
        defaultStake: 10,
        variants: {
            USD: {
                decimalPlaces: 2,
                factor: 1,
                label: 'USD'
            }
        },
        defaultVariant: 'USD'
    },
    EUR: {
        label: 'EUR',
        fullName: 'Euro',
        type: types_1.CurrencyType.FIAT,
        defaultStake: 10,
        variants: {
            EUR: {
                decimalPlaces: 2,
                factor: 1,
                label: 'EUR'
            }
        },
        defaultVariant: 'EUR'
    },
    JPY: {
        label: 'JPY',
        fullName: 'Japanese Yen',
        type: types_1.CurrencyType.FIAT,
        defaultStake: 10,
        variants: {
            JPY: {
                decimalPlaces: 2,
                factor: 1,
                label: 'JPY'
            }
        },
        defaultVariant: 'JPY'
    },
    BRL: {
        label: 'BRL',
        fullName: 'Brazilian Real',
        type: types_1.CurrencyType.FIAT,
        defaultStake: 10,
        variants: {
            BRL: {
                decimalPlaces: 2,
                factor: 1,
                label: 'BRL'
            }
        },
        defaultVariant: 'BRL'
    },
    US2: getUsdEquivalent('USDT', 'Tether'),
    US3: getUsdEquivalent('USDC', 'USD Coin'),
    US4: getUsdEquivalent('TUSD', 'TrueUSD'),
    US5: getUsdEquivalent('PAX', 'PAX'),
    US6: getUsdEquivalent('GUSD', 'Gemini Dollar'),
    AU1: getGoldEquivalent('PAXG', 'PAX Gold'),
    AU2: getGoldEquivalent('XAUT', 'Tether Gold')
};
var getDefaultCurrencyVariant = function (currency) { return ({
    decimalPlaces: 8,
    factor: 1,
    label: currency
}); };
exports.getCurrencyConfig = function (currency) { return exports.config[currency]; };
exports.getCurrencyVariantConfig = function (currency, variant) {
    var _a = exports.getCurrencyConfig(currency) || {}, defaultVariant = _a.defaultVariant, variants = _a.variants;
    var currencyVariant = (variants &&
        (variant || defaultVariant) &&
        ((variant && variants[variant]) ||
            (defaultVariant && variants[defaultVariant]))) ||
        getDefaultCurrencyVariant(currency);
    return currencyVariant;
};
