"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLanguageInPath = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var _1 = require(".");
exports.setLanguageInPath = function (language) {
    var currentPath = window.location.pathname;
    var pathWithLanguage = currentPath.replace(/\w+[-,_]?\w+/, i18n_1.toISO639(language));
    ENABLE_LOCALIZED_ROUTES &&
        _1.history.replace("" + pathWithLanguage + window.location.search);
};
