import { defaultSportInfo } from 'sports/config/sport/defaultSportInfo';

import { baseInfo } from './baseInfo';
import { featureInfo } from './featureInfo';
import { marketInfo } from './marketInfo';

export const soccer = {
	...defaultSportInfo,
	...baseInfo,
	...marketInfo,
	...featureInfo
};
