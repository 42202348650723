import { Grid, ListItemIcon, makeStyles, Theme } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { CSSProperties } from '@material-ui/styles';
import { withHook } from 'materialbet-common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { EventMetadata } from 'sports/api/sports';
import { EventName } from 'sports/components/displays/EventName';
import { EventScore } from 'sports/components/displays/EventScore';
import { EventTeams } from 'sports/components/displays/EventTeams';
import { EventTime } from 'sports/components/displays/EventTime';
import { VideoIcon } from 'sports/components/icons/common';
import { SportType } from 'sports/config/sport';
import {
	getCompetitorAwayName,
	getCompetitorHomeName,
	getEventMetadata,
	getEventName,
	getEventStartTime,
	getEventStatus,
	getPlayers,
	hasLiveStream
} from 'sports/selectors/eventSelectors/eventSelectors';
import { DeepReadonly } from 'utility-types';

import { Players } from '../eventList/Event';
import { EventListVariant } from '../eventList/types';

export interface EventListEntryInfoProps {
	startTime?: string;
	sportKey: string;
	homeTeam: string;
	awayTeam: string;
	name: string;
	status: string;
	isLiveStream: boolean;
	players?: Players;
	id: string | number;
	metadata?: DeepReadonly<EventMetadata>;
	variant?: EventListVariant;
	disableEventTime?: boolean;
}
const baseRoot: CSSProperties = {
	display: 'grid',
	width: '100%'
};
const useNarrowRootClasses = makeStyles({
	root: {
		...baseRoot,
		gridTemplateColumns: '100%',
		gridAutoFlow: 'row'
	}
});
const useWideRootClasses = makeStyles({
	root: {
		...baseRoot,
		gridTemplateColumns: 'auto 1fr',
		gridAutoFlow: 'column'
	}
});

const baseTime = {
	maxWidth: 'max-content'
};
const useNarrowTimeClasses = makeStyles({
	time: {
		...baseTime,
		gridRow: 2
	}
});
const useWideTimeClasses = makeStyles((theme: Theme) => ({
	time: {
		...baseTime,
		paddingLeft: theme.spacing(1)
	}
}));

const baseInfo = {
	display: 'grid',
	gridTemplateColumns: 'minmax(0, 1fr) auto'
};

const useNarrowInfoClasses = makeStyles((theme: Theme) => ({
	info: {
		...baseInfo,
		gridRow: 1,
		paddingRight: theme.spacing(0.5)
	}
}));
const useWideInfoClasses = makeStyles((theme: Theme) => ({
	info: {
		...baseInfo,
		paddingRight: theme.spacing(1)
	}
}));
const useListItemClass = makeStyles((theme: Theme) => ({
	livePlayButtonGrid: {
		marginLeft: 'auto',
		color: theme.palette.secondary.main,
		whiteSpace: 'nowrap',
		paddingLeft: theme.spacing(1),
		display: 'flex',
		alignItems: 'center'
	},
	livePlayButton: {
		margin: theme.spacing(0, 1),
		minWidth: 0,
		color: theme.palette.text.hint,
		whiteSpace: 'nowrap',
		paddingLeft: theme.spacing(1)
	}
}));

const MobileExtra: React.FunctionComponent<EventListEntryInfoProps> = (
	props
) => {
	const classes = useListItemClass({ variant: 'narrow' });
	return (
		<>
			<ChevronRight fontSize="small" opacity={0.6} color="action" />
			{props.status === 'TRADING_LIVE' && props.isLiveStream ? (
				<ListItemIcon className={classes.livePlayButton}>
					<VideoIcon />
				</ListItemIcon>
			) : null}
		</>
	);
};

interface DesktopExtraInfoProps {
	status: string;
	isLiveStream: boolean;
	sportKey: string;
	eventStatus?: DeepReadonly<EventMetadata['eventStatus']>;
	score?: DeepReadonly<EventMetadata['score']>;
	setScores?: DeepReadonly<EventMetadata['setScores']>;
}

const DesktopExtra: React.FunctionComponent<DesktopExtraInfoProps> = (
	props
) => {
	const classes = useListItemClass();
	return (
		<>
			{props.status === 'TRADING_LIVE' && (
				<div className={classes.livePlayButtonGrid}>
					{props.isLiveStream ? (
						<ListItemIcon className={classes.livePlayButton}>
							<VideoIcon />
						</ListItemIcon>
					) : null}
					{props.eventStatus !== 'not_started' && (
						<EventScore
							sportKey={props.sportKey}
							score={props.score}
							setScores={props.setScores}
							disableSingleTeam={true}
						/>
					)}
				</div>
			)}
		</>
	);
};

const EventListEntryInfoUi: React.FunctionComponent<EventListEntryInfoProps> = (
	props
) => {
	const { variant = 'wide', disableEventTime, name } = props;
	const narrowInfoClasses = useNarrowInfoClasses();
	const wideInfoClasses = useWideInfoClasses();
	const { info } = variant === 'narrow' ? narrowInfoClasses : wideInfoClasses;

	const narrowRootClasses = useNarrowRootClasses();
	const wideRootClasses = useWideRootClasses();
	const { root } = variant === 'narrow' ? narrowRootClasses : wideRootClasses;

	const narrowTimeClasses = useNarrowTimeClasses();
	const wideTimeClasses = useWideTimeClasses();
	const { time } = variant === 'narrow' ? narrowTimeClasses : wideTimeClasses;
	const hasTeams = props.homeTeam || props.awayTeam;
	return (
		<div className={root}>
			{!disableEventTime && (
				<Grid
					container
					item
					alignItems="center"
					wrap="nowrap"
					xs={12}
					md={1}
					className={time}
				>
					{props.startTime && (
						<EventTime
							sportKey={props.sportKey}
							status={props.status}
							startTime={props.startTime}
							eventStatus={
								props.metadata && props.metadata.eventStatus
							}
							eventTimeExtended={
								props.metadata &&
								props.metadata.eventTimeExtended
							}
						/>
					)}
					{variant === 'narrow' && <MobileExtra {...props} />}
				</Grid>
			)}

			<div className={info}>
				{hasTeams || !ENABLE_SPORT_OUTRIGHTS ? (
					<EventTeams
						eventId={+props.id}
						sportKey={props.sportKey as SportType}
						variant={variant}
					/>
				) : (
					<EventName name={name} />
				)}
				{variant !== 'narrow' && (
					<DesktopExtra
						status={props.status}
						isLiveStream={props.isLiveStream}
						sportKey={props.sportKey}
						eventStatus={
							props.metadata && props.metadata.eventStatus
						}
						score={props.metadata && props.metadata.score}
						setScores={props.metadata && props.metadata.setScores}
					/>
				)}
			</div>
		</div>
	);
};
export const EventListEntryInfo = withHook(({ id }: { id: number }) => {
	const startTime = useSelector(getEventStartTime(id));
	const homeTeam = useSelector(getCompetitorHomeName(id));
	const awayTeam = useSelector(getCompetitorAwayName(id));
	const name = useSelector(getEventName(id));
	const status = useSelector(getEventStatus(id));
	const isLiveStream = useSelector(hasLiveStream(id));
	const players = useSelector(getPlayers(id));
	const metadata = useSelector(getEventMetadata(id));
	return {
		startTime,
		homeTeam,
		awayTeam,
		name,
		status,
		players,
		metadata,
		isLiveStream
	};
})(EventListEntryInfoUi);
