import { combineEpics } from 'redux-observable';
import * as schemas from 'sports/schema';
import { createFetchEntityEpic } from 'sports/utils/epic';
import { createEntityReducer } from 'sports/utils/reducer';
import { createAction } from 'typesafe-actions';

////////////////
/// Actions ///
//////////////
export const actions = {
	fetchByEvent: createAction(
		'eventPromotions/fetchByEvent',
		(resolve) => (params: { eventId: number; promotion: string }) =>
			resolve(params)
	)
};
export const eventPromotionsReducer = createEntityReducer<
	schemas.EventPromotionEntity
>(schemas.eventPromotions.key);
export const loads = {
	fetchEvent: (eventId: number) => `positions/event_${eventId}`
};
//////////////
/// EPICS ///
////////////

const getEventPromotion = createFetchEntityEpic(
	actions.fetchByEvent,
	(params, { api }) =>
		api.sportsbetting
			.getEventPromotion(params)
			.then((result) => ({ ...result, ...params })), // we got to add the event id here for the normalizer as its not included in the response payload

	schemas.eventPromotions,
	(result) => result
);
export const eventPromotionsEpic = combineEpics(getEventPromotion);
