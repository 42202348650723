"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveHooks = exports.createRules = exports.makeDynamicStyles = exports.createDynamicStyle = exports.resolveOrReturn = void 0;
var core_1 = require("@material-ui/core");
var identityMapper = function (t) {
    return t.toString();
};
exports.resolveOrReturn = function (styles, theme) {
    if (typeof styles === 'function') {
        return styles(theme);
    }
    return styles;
};
exports.createDynamicStyle = function (base, variants, mapper) {
    if (mapper === void 0) { mapper = identityMapper; }
    return {
        _default: base,
        _propertyMapper: mapper,
        variants: variants
    };
};
exports.makeDynamicStyles = function (dynamicStyle) {
    var ruleSet = exports.createRules(dynamicStyle);
    return function (props) {
        var classes = exports.resolveHooks(ruleSet, props);
        return classes;
    };
};
exports.createRules = function (dynamicStyle) {
    var ruleSet = Object.keys(dynamicStyle).reduce(function (rules, classname) {
        var _a = dynamicStyle[classname], _default = _a._default, _propertyMapper = _a._propertyMapper, styleClass = _a.variants;
        var styleHooks = Object.keys(styleClass).reduce(function (acc, variant) {
            var _a;
            var styles = styleClass[variant];
            if (typeof styles === 'function' ||
                typeof _default === 'function') {
                acc[variant] = core_1.makeStyles(function (theme) {
                    var _a;
                    return (_a = {},
                        _a[classname] = __assign(__assign({}, exports.resolveOrReturn(_default, theme)), exports.resolveOrReturn(styles, theme)),
                        _a);
                });
            }
            else {
                acc[variant] = core_1.makeStyles((_a = {},
                    _a[classname] = __assign(__assign({}, _default), styles),
                    _a));
            }
            return acc;
        }, {});
        rules[classname] = { mapper: _propertyMapper, hooks: styleHooks };
        return rules;
    }, {});
    return ruleSet;
};
exports.resolveHooks = function (ruleSet, styleProps) {
    var classes = Object.keys(ruleSet).reduce(function (acc, classname) {
        var rule = ruleSet[classname];
        var classes = Object.keys(rule.hooks).reduce(function (dict, variant) {
            dict[variant] = rule.hooks[variant]();
            return dict;
        }, {});
        var variant = rule.mapper(styleProps);
        return __assign(__assign({}, acc), classes[variant]);
    }, {});
    return classes;
};
