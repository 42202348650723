"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainNavButton = void 0;
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
var device_1 = require("@gaming-shell/device");
var components_1 = require("./components");
var getAnimationType_1 = require("./getAnimationType");
var useStyles = core_1.makeStyles(function (theme) { return ({
    button: {
        padding: theme.spacing(1.3),
        margin: device_1.isMobile() ? 0 : theme.spacing(0, 1, 0, 0.5)
    },
    icon: {
        backgroundColor: '#FFFFFF'
    }
}); });
var MainNavButton = function (_a) {
    var _b = _a.type, type = _b === void 0 ? 'menu' : _b, _c = _a.fromType, fromType = _c === void 0 ? 'home' : _c, onClick = _a.onClick, rest = __rest(_a, ["type", "fromType", "onClick"]);
    var classes = useStyles();
    var from = getAnimationType_1.getAnimationType(fromType);
    return (React.createElement(core_1.IconButton, __assign({ onClick: onClick, className: classes.button, disableTouchRipple: true, disableFocusRipple: true, disableRipple: true, "aria-label": "Menu" }, rest),
        React.createElement(components_1.AnimatedAppBarIcon, { from: from, to: type, className: classes.icon })));
};
exports.MainNavButton = MainNavButton;
exports.MainNavButton = MainNavButton = React.memo(MainNavButton, function (prevProps, nextProps) { return prevProps.type === nextProps.type; });
