import { MarketType } from 'sports-sdk';

import { defaultMarketInfo } from '../defaultSportInfo';
import { SportMarketInfo, TabConfiguration } from '../types';

export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.politics_outright
		}
	]
};

export const marketInfo: SportMarketInfo = {
	...defaultMarketInfo,
	outrightMarketConfig
};
