import {
	ButtonBase,
	ButtonBaseProps,
	Theme,
	makeStyles
} from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import * as React from 'react';

export interface ChevronButtonProps extends ButtonBaseProps {
	direction: 'left' | 'right';
	onClick?: () => void;
	color?: SvgIconProps['color'];
	disabled?: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
	chevron: {
		verticalAlign: 'middle',
		cursor: 'pointer'
	}
}));

export const ChevronButton: React.FunctionComponent<ChevronButtonProps> = ({
	direction,
	color,
	...rest
}) => {
	const classes = useStyles();

	return (
		<ButtonBase {...rest}>
			{direction === 'left' && (
				<ChevronLeft className={classes.chevron} color={color} />
			)}
			{direction === 'right' && (
				<ChevronRight className={classes.chevron} color={color} />
			)}
		</ButtonBase>
	);
};
