/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
	Selection,
	SelectionFromJSON,
	SelectionFromJSONTyped,
	SelectionToJSON
} from './';

/**
 * Submarket is used as a part of Market
 * Used to build a map of all submarkets for a given Market
 * @export
 * @interface Submarket
 */
export interface Submarket {
	/**
	 *
	 * @type {boolean}
	 * @memberof Submarket
	 */
	blank?: boolean;
	/**
	 *
	 * @type {boolean}
	 * @memberof Submarket
	 */
	parlayRestriction?: boolean;
	/**
	 *
	 * @type {number}
	 * @memberof Submarket
	 */
	provider?: number;
	/**
	 *
	 * @type {Array<Selection>}
	 * @memberof Submarket
	 */
	selections?: Array<Selection>;
	/**
	 *
	 * @type {number}
	 * @memberof Submarket
	 */
	sequence?: number;
}

export function SubmarketFromJSON(json: any): Submarket {
	return SubmarketFromJSONTyped(json, false);
}

export function SubmarketFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): Submarket {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		blank: !exists(json, 'blank') ? undefined : json['blank'],
		parlayRestriction: !exists(json, 'parlayRestriction')
			? undefined
			: json['parlayRestriction'],
		provider: !exists(json, 'provider') ? undefined : json['provider'],
		selections: !exists(json, 'selections')
			? undefined
			: (json['selections'] as Array<any>).map(SelectionFromJSON),
		sequence: !exists(json, 'sequence') ? undefined : json['sequence']
	};
}

export function SubmarketToJSON(value?: Submarket | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		blank: value.blank,
		parlayRestriction: value.parlayRestriction,
		provider: value.provider,
		selections:
			value.selections === undefined
				? undefined
				: (value.selections as Array<any>).map(SelectionToJSON),
		sequence: value.sequence
	};
}
