"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsFetched = exports.getUndismissedMessages = exports.getAllMessages = void 0;
var reselect_1 = require("reselect");
exports.getAllMessages = function (state) {
    return state.messages;
};
exports.getUndismissedMessages = reselect_1.createSelector([exports.getAllMessages], function (messages) { return messages.filter(function (message) { return !message.dismissed; }); });
exports.getIsFetched = function (state) {
    return state.fetched;
};
