import { makeStyles } from '@material-ui/core/';

export const useStyles = makeStyles((theme) => ({
	slider: {
		margin: theme.spacing(0, -1) // to allow for spacing on each tile to grow into, while having them aligned to the left
	},
	mobileSlider: {
		overflowX: 'auto',
		flexWrap: 'nowrap',
		whiteSpace: 'nowrap',
		margin: theme.spacing(-1),
		'&::-webkit-scrollbar-track': {
			background: 'transparent'
		}
	},
	skeletonSlider: {
		margin: theme.spacing(0, -1),
		display: 'flex',
		width: '100%'
	}
}));
