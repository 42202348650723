import { isAfter } from 'date-fns';
import { filterMap } from 'materialbet-common';
import { CompetitionResponse, EventListResponse } from 'sports/api/sports';
import { BetsHistoryState } from 'sports/api/sportsbetting/api';
import {
	CompetitionEventsParams,
	EventsParams
} from 'sports/modules/eventsModule';
import {
	FinishLoadingMeta,
	LoadingMeta,
	LoadingMetaKey,
	StartLoadingMeta
} from 'sports/modules/loading';
import {
	EventTreeMeta,
	SportsMeta,
	SportsMetaKey,
	SportsMetaKeys
} from 'sports/modules/sports/sportsMeta';
import { EventEntity } from 'sports/schema';

import { competitionIsVirtual } from './competitionUtils';

export interface MetaPayload {
	[LoadingMetaKey]?: LoadingMeta;
	[SportsMetaKey]?: SportsMeta;
}
export interface SportsKeyValue<
	TKey extends SportsMetaKeys,
	TValue extends SportsMeta[TKey]
> {
	key: TKey;
	value: TValue;
}
export const startLoading = (...names: string[]) =>
	({
		[LoadingMetaKey]: names.map(
			(name) =>
				({
					name,
					initiatedAt: new Date().getTime()
				} as StartLoadingMeta)
		)
	} as MetaPayload);
export const finishLoading = (...names: string[]) =>
	({
		[LoadingMetaKey]: names.map(
			(name) =>
				({
					name,
					receivedAt: new Date().getTime()
				} as FinishLoadingMeta)
		)
	} as MetaPayload);

export const sportsMeta = (meta: SportsMeta) => ({
	[SportsMetaKey]: meta
});

// To allow autocomplete for sportsMeta keys, sadly only supports metas with same type right now (Typescript limitation)
export const sportsMetas = <
	TKey extends SportsMetaKeys,
	TValue extends SportsMeta[TKey]
>(
	...metas: Array<SportsKeyValue<TKey, TValue>>
) => ({
	[SportsMetaKey]: metas.reduce((prev, meta) => {
		prev[meta.key] = meta.value;
		return prev;
	}, {} as SportsMeta)
});

const buildEventTree = (tree: EventListResponse) => {
	const treeMeta: EventTreeMeta[] = filterMap(
		tree.sports || [],
		(sport) => !!sport.key,
		(sport) => {
			const competitions = filterMap(
				sport.competitions || [],
				(comp) => !!comp.key && comp.name !== 'Other' && !comp.blank,
				(comp) => {
					const events = filterMap(
						comp.events || [],
						(event) => !!event.id && !event.blank,
						(event) => event.id as number
					);
					return {
						id: comp.key as string,
						items: events
					};
				}
			).filter((comp) => comp.items.length);
			return { id: sport.key as string, items: competitions };
		}
	);
	return treeMeta;
};
export interface EventTreesMetaParams {
	upcoming?: boolean;
	live?: boolean;
	endpoint:
		| 'popular'
		| 'events'
		| 'topcoupon'
		| 'popularCompetitions'
		| 'outrights';
	from?: number;
	to?: number;
	sport?: string;
	sports?: string[];
	topCouponKey?: string;
	virtual?: boolean;
}
export const buildEventTreesMetaKey = ({
	upcoming,
	live,
	endpoint,
	from,
	to,
	sport,
	sports,
	topCouponKey,
	virtual
}: EventTreesMetaParams) =>
	`${endpoint}_${upcoming}_${live}_${virtual}_${topCouponKey}_${from}_${to}_${sport}_[${
		sports && sports.join(',')
	}]`;

export const createEventTreesMeta = (
	tree: EventListResponse,
	params: string
): MetaPayload => {
	if (!tree.sports) {
		return {};
	}
	const treeMeta: EventTreeMeta[] = buildEventTree(tree);
	const treesMeta = { [params]: treeMeta };
	const meta: SportsMeta = { eventTrees: treesMeta };
	return { [SportsMetaKey]: meta };
};
export const createCompetitionEventsMeta = (
	response: CompetitionResponse | undefined,
	params: string
): MetaPayload => {
	if (!response) {
		return {};
	}
	const eventIds = (response.events?.map((e) => e.id) || []).filter(
		(e) => e
	) as number[];
	const competitionEvents = { [params]: eventIds };
	const meta: SportsMeta = { competitionEvents };
	return { [SportsMetaKey]: meta };
};
export const createCompetitionOutrightsMeta = (
	response: CompetitionResponse | undefined,
	params: string
): MetaPayload => {
	if (!response) {
		return {};
	}
	const eventIds = (response.outrights?.map((e) => e.id) || []).filter(
		(e) => e
	) as number[];
	const competitionEvents = { [params]: eventIds };
	const meta: SportsMeta = { competitionEvents };
	return { [SportsMetaKey]: meta };
};
export const settlementStateToSportMeta: {
	[key in BetsHistoryState]: SportsMetaKeys;
} = {
	SETTLEMENT_PENDING: 'pendingBetsCount',
	SETTLEMENT_COMPLETED: 'completedBetsCount'
};
export const betsHistoryCountMeta = (
	state: BetsHistoryState,
	count: number
) => {
	const key = settlementStateToSportMeta[state];
	return {
		[SportsMetaKey]: {
			[key]: count
		}
	};
};
export const getLiveEventIdsForEventsParams = (
	events: EventEntity[],
	params: EventsParams
) => {
	const sports = params.sports || [params.sport] || [];
	if (!sports.length) {
		return [];
	}

	const { streaming, upcoming, virtual, from, live } = params;
	if (
		(from && isAfter(new Date(from), new Date())) ||
		live === false ||
		upcoming
	) {
		return []; // this list only is interested in events later than now
	}
	const eventIds = filterMap(
		events,
		(e) =>
			!!(
				e.id &&
				(!streaming || e.streams?.length) &&
				(!virtual || competitionIsVirtual(e.sport, e.competition)) &&
				sports.includes(e.sport)
			),
		(e) => e.id as number
	);
	return eventIds;
};
export const getLiveEventIdsForCompetitionEventsParams = (
	events: EventEntity[],
	params: CompetitionEventsParams
) => {
	const { competitionKey, from, entity } = params;
	if (from && isAfter(new Date(from), new Date())) {
		return []; // this list only is interested in events later than now
	}
	if (entity === 'outrights') {
		return []; // this list is not for events but outrights
	}
	const eventIds = filterMap(
		events,
		(e) => !!(e.id && competitionKey === e.competition),
		(e) => e.id as number
	);
	return eventIds;
};
