import { Translate } from '@gaming-shell/i18n';
import { List, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { fillArray } from 'materialbet-common';
import * as React from 'react';
import Loading from 'sports/components/displays/Loading';

import {
	DeferredEventListCompetition,
	EventListCompetitionSkeleton
} from '../EventListCompetition';
import { hasEveryGroupItems } from '../eventListUtils';
import { EventListDataProps, EventListFormatProps, Group } from '../types';

interface EventListProps extends EventListDataProps, EventListFormatProps {
	groupList: Group[];
	eventsLoaded: boolean;
	showNoEventsMessage?: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
	list: {
		marginBottom: theme.spacing(1),
		background: 'transparent'
	},
	horizontalList: {
		display: 'flex',
		overflowX: 'auto',
		marginBottom: 0
	}
}));

export const EventList: React.FunctionComponent<EventListProps> = (props) => {
	const {
		groupList,
		eventsLoaded,
		sportKey,
		marketKey,
		live,
		disableCollapsible,
		collapseFrom,
		disableSportIcon,
		showNoEventsMessage,
		disableToEventIcon,
		disableCompetitionHeader,
		disableMarketHeader,
		disableMultipleMarketHeader,
		disableMarketHeaderDate,
		variant,
		paperVariant,
		paperTitleVariant,
		competitionTitleVariant,
		disablePriceList,
		disableCompetitionIcon,
		withCompetitionDivider,
		orientation = 'vertical'
	} = props;
	const classes = useStyles();
	if (!eventsLoaded) {
		return ENABLE_SPORT_SKELETON ? (
			<>
				{fillArray(3, (i) => (
					<EventListCompetitionSkeleton
						key={i}
						disableCompetitionHeader={disableCompetitionHeader}
						disableCompetitionIcon={disableCompetitionIcon}
						paperVariant={paperVariant}
						variant={variant}
					/>
				))}
			</>
		) : (
			<Loading />
		);
	}
	if (!groupList) {
		return null;
	}
	if (!hasEveryGroupItems(groupList)) {
		return showNoEventsMessage ? (
			<Typography align="center" variant="subtitle1">
				<Translate label="event.currentlyNoEvents" ns="sports" />
			</Typography>
		) : null;
	}

	const competitions = groupList.map((group, index) => (
		<DeferredEventListCompetition
			group={group}
			sportKey={sportKey}
			marketKey={marketKey}
			key={`${sportKey}_group_${index}`}
			disableCollapsible={disableCollapsible}
			defaultCollapsed={
				collapseFrom !== undefined && index >= collapseFrom
			}
			disableSportIcon={disableSportIcon}
			variant={variant}
			withCompetitionDivider={
				withCompetitionDivider && index !== groupList.length - 1
			}
			disableToEventIcon={disableToEventIcon}
			disableCompetitionHeader={disableCompetitionHeader}
			disableMarketHeader={
				disableMarketHeader ||
				(disableMultipleMarketHeader && index !== 0)
			}
			disableMarketHeaderDate={disableMarketHeaderDate}
			disablePriceList={disablePriceList}
			disableCompetitionIcon={disableCompetitionIcon}
			paperVariant={paperVariant}
			paperTitleVariant={paperTitleVariant}
			competitionTitleVariant={competitionTitleVariant}
			live={live}
			orientation={orientation}
		/>
	));
	return (
		<List
			dense={true}
			disablePadding
			className={clsx(
				classes.list,
				orientation === 'horizontal' && classes.horizontalList
			)}
		>
			{competitions}
		</List>
	);
};
