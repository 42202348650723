import * as React from 'react';
import { AccountBalanceButtonLink } from './AccountBalanceButtonLink';
import { AccountBalanceButtonCurrencySwitch } from './AccountBalanceButtonCurrencySwitch';
import { usePlayerWallets } from '@materialbet-core/player';

export const AccountBalanceButton: React.FC = () => {
	const wallets = usePlayerWallets();

	const isLinkButton =
		!QUICK_CURRENCY_SWITCH_ENABLED || Object.values(wallets).length <= 1;

	return isLinkButton ? (
		<AccountBalanceButtonLink />
	) : (
		<AccountBalanceButtonCurrencySwitch />
	);
};
