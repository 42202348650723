"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearSessionHistory = exports.Router = exports.getPreviousRoute = exports.buildUrlFromLocation = exports.getMatchingHistoryEntry = exports.getPreviousLocation = exports.canGoBack = exports.goBack = exports.historyStack = exports.storedHistory = exports.history = exports.HistoryStorageKey = void 0;
var history_1 = require("history");
var React = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
exports.HistoryStorageKey = 'history';
exports.history = history_1.createBrowserHistory();
exports.storedHistory = sessionStorage.getItem(exports.HistoryStorageKey);
exports.historyStack = (exports.storedHistory && JSON.parse(exports.storedHistory)) || [];
exports.history.listen(function (location, action) {
    switch (action) {
        case 'POP': {
            exports.historyStack.pop();
            return;
        }
        case 'REPLACE': {
            exports.historyStack.pop();
            exports.historyStack.push(location);
            return;
        }
        case 'PUSH': {
            exports.historyStack.push(location);
            return;
        }
    }
});
var currentLocation = window.location;
var lastEntry = exports.historyStack === null || exports.historyStack === void 0 ? void 0 : exports.historyStack[exports.historyStack.length - 1];
if ((lastEntry === null || lastEntry === void 0 ? void 0 : lastEntry.pathname) !== (currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.pathname)) {
    exports.historyStack.push(__assign({}, currentLocation));
}
exports.goBack = function () {
    exports.historyStack.pop();
    var previousLocation = exports.historyStack[exports.historyStack.length - 1];
    if (!previousLocation) {
        return;
    }
    exports.history.replace(previousLocation.pathname + (previousLocation.search || ''));
};
exports.canGoBack = function () { return exports.historyStack.length >= 2; };
exports.getPreviousLocation = function (offset) {
    if (offset === void 0) { offset = 2; }
    var index = exports.historyStack.length - offset;
    return exports.historyStack[index];
};
exports.getMatchingHistoryEntry = function (routes) {
    var res = __spreadArrays(exports.historyStack).reverse()
        .find(function (_a) {
        var pathname = _a.pathname;
        return routes.find(function (route) { return pathname.includes(route); });
    });
    return res;
};
exports.buildUrlFromLocation = function (location) {
    return "" + location.pathname + location.search;
};
exports.getPreviousRoute = function () {
    var previousLocation = exports.getPreviousLocation();
    if (!previousLocation) {
        return null;
    }
    var baseRoute = previousLocation.pathname;
    if (previousLocation.search) {
        baseRoute += previousLocation.search;
    }
    return baseRoute;
};
exports.Router = function (_a) {
    var children = _a.children;
    return (React.createElement(react_router_dom_1.Router, { history: exports.history }, children));
};
exports.clearSessionHistory = function () {
    sessionStorage.removeItem(exports.HistoryStorageKey);
};
