import * as React from 'react';
import { useLocation } from 'react-router';
import {
	usePrevious,
	Row,
	ButtonLink,
	PlayerRoutes,
	IconButton
} from 'materialbet-common';
import {
	Menu,
	Typography,
	makeStyles,
	Divider,
	Tooltip
} from '@material-ui/core';
import {
	NotificationShortList,
	NotificationListItem,
	NotificationListItemTitle,
	Notification,
	NotificationListItemStatus
} from 'materialbet-inbox';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useDismissNotification } from './useDismissNotification';
import { history } from '@gaming-shell/routing';
import { useUndismissedNotificationsLimitedByLength } from './useUndismissedNotificationsLimitedByLength';

export interface NotificationsMenuProps {
	anchorEl: HTMLElement | null;
	onClose: () => void;
}

const menuOrigins = {
	vertical: 'top',
	horizontal: 'right'
} as const;

const useMenuStyles = makeStyles({
	paper: {
		width: '100%',
		maxWidth: 340
	},
	list: {
		padding: 0
	}
});

const useStyles = makeStyles((theme) => ({
	header: {
		margin: theme.spacing(1.5),
		pointerEvents: 'none'
	},
	actionButtonContainer: {
		padding: theme.spacing(1.5)
	},
	notificationListItem: {
		marginRight: theme.spacing(1),
		width: 'auto'
	},
	dismissButton: {
		marginLeft: 'auto',
		marginRight: theme.spacing(1),
		color: theme.palette.text.secondary
	},
	dismissIcon: {
		width: 12,
		height: 12
	},
	noMessagesNote: {
		padding: theme.spacing(2)
	}
}));

const getNotificationStatus = (notification: Notification) =>
	notification.unread
		? NotificationListItemStatus.Unread
		: NotificationListItemStatus.Read;

export const NotificationsMenu: React.FC<NotificationsMenuProps> = ({
	anchorEl,
	onClose
}) => {
	const menuClasses = useMenuStyles();
	const classes = useStyles();

	const { pathname } = useLocation();
	const prevPathname = usePrevious(pathname);
	const {
		undismissedNotifications,
		totalLength
	} = useUndismissedNotificationsLimitedByLength();
	const dismissNotification = useDismissNotification();

	// Should close itself on pathname change
	React.useEffect(() => {
		if (prevPathname !== pathname) {
			onClose();
		}
	}, [pathname, prevPathname, onClose]);

	const onNotificationClick = React.useCallback(
		(notificationId: string) => () => {
			history.push(
				// eslint-disable-next-line @typescript-eslint/camelcase
				PlayerRoutes.inboxMessage.create({ message_id: notificationId })
			);
			onClose();
		},
		[onClose]
	);

	return (
		<Menu
			anchorEl={anchorEl}
			open={!!anchorEl}
			onClose={onClose}
			aria-label="Notifications menu"
			anchorOrigin={menuOrigins}
			transformOrigin={menuOrigins}
			keepMounted
			classes={menuClasses}
		>
			<Typography variant="h6" className={classes.header}>
				Messages
				{/* FIXME: MTBET-1341 Replace with translation */}
			</Typography>
			<Divider />
			<NotificationShortList>
				{undismissedNotifications.map(
					(notification, notificationNo) => (
						<React.Fragment key={notification.id}>
							<Row alignItems="center" wrap="nowrap">
								<NotificationListItem
									key={notification.id}
									status={getNotificationStatus(notification)}
									className={classes.notificationListItem}
									onClick={onNotificationClick(
										notification.id
									)}
								>
									<NotificationListItemTitle>
										<span
											dangerouslySetInnerHTML={{
												__html: notification.title
											}}
										/>
									</NotificationListItemTitle>
								</NotificationListItem>
								<Tooltip
									title="Dismiss" // FIXME: MTBET-1341 Replace with translation
									placement="top"
									arrow
								>
									<IconButton
										className={classes.dismissButton}
										onClick={() =>
											dismissNotification(notification.id)
										}
										size="small"
									>
										<FiberManualRecordIcon
											className={classes.dismissIcon}
										/>
									</IconButton>
								</Tooltip>
							</Row>
							{notificationNo !==
								undismissedNotifications.length - 1 && (
								<Divider />
							)}
						</React.Fragment>
					)
				)}
				{undismissedNotifications.length === 0 && (
					<Typography className={classes.noMessagesNote}>
						No new messages
						{/* FIXME: MTBET-1341 Replace with translation */}
					</Typography>
				)}
			</NotificationShortList>
			<Divider />
			<Row justify="center" className={classes.actionButtonContainer}>
				<ButtonLink
					variant="contained"
					href={PlayerRoutes.inbox.create()}
					onClick={onClose}
					color="primary"
				>
					{totalLength > NUMBER_OF_SHOWN_PLAYER_INBOX_NOTIFICATIONS
						? 'Show more messages'
						: 'Go to messages'}
					{/* FIXME: MTBET-1341 Replace with translation */}
				</ButtonLink>
			</Row>
		</Menu>
	);
};
