import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

export const createSvgIcon = (path: string, displayName: string) => {
	const Icon: React.FunctionComponent<SvgIconProps> = (props) => (
		<SvgIcon {...props}>
			<path fill={props.fill} d={path} />
		</SvgIcon>
	);
	Icon.displayName = ''.concat(displayName, 'Icon');
	return Icon;
};
