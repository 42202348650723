import { PlaceBetV2Selection } from 'sports/api/sportsbetting/api';
import { getMarketUrl } from 'sports/utils/market';
import { parseOdds } from 'sports/utils/odds';

import { BetslipReducerSelection } from '../types';

export const createPlaceBetSelection = (
	selection: BetslipReducerSelection
): PlaceBetV2Selection => ({
	marketUrl: getMarketUrl(selection),
	price: parseOdds(selection.price).toString(),
	eventId: selection.eventId.toString()
});
