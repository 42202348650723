"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZendeskWidget = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var zendesk_1 = require("@materialbet/zendesk");
var React = __importStar(require("react"));
exports.ZendeskWidget = function (_a) {
    var apiKey = _a.apiKey, onOpen = _a.onOpen, userName = _a.userName, userEmail = _a.userEmail, reset = _a.reset, children = _a.children;
    var language = i18n_1.useLanguage();
    var _b = React.useState(false), ready = _b[0], setReady = _b[1];
    // Make sure zendesk is ready
    React.useEffect(function () {
        // check every second for 20 seconds if zendesk is available
        if (!zendesk_1.isZendeskAvailable() && !ready) {
            var checksCounter_1 = 0;
            var handler_1 = setInterval(function () {
                if (zendesk_1.isZendeskAvailable() || checksCounter_1 >= 20) {
                    setReady(true);
                    clearInterval(handler_1);
                }
                checksCounter_1++;
            }, 1000);
            return function () {
                clearInterval(handler_1);
            };
        }
        setReady(true);
        return;
    }, [setReady]);
    /* locale change handler */
    React.useEffect(function () {
        zendesk_1.zendesk('webWidget', 'setLocale', language);
    }, [ready, language]);
    /* prefill handler */
    React.useEffect(function () {
        var prefillData = {
            name: {
                value: reset ? '' : userName
            },
            email: {
                value: reset ? '' : userEmail
            }
        };
        zendesk_1.zendesk('webWidget', 'prefill', prefillData);
    }, [ready, reset, userName, userEmail]);
    // listen for widget close/new window popup action
    React.useEffect(function () {
        /* this hides zendesk button visible on the very bottom right of page */
        var hideZendesk = function () {
            zendesk_1.zendesk('webWidget', 'hide');
        };
        zendesk_1.zendesk('webWidget:on', 'chat:popout', hideZendesk);
        zendesk_1.zendesk('webWidget:on', 'close', hideZendesk);
    }, [ready]);
    var openWidget = React.useCallback(function () {
        zendesk_1.zendesk('webWidget', 'open');
        zendesk_1.zendesk('webWidget', 'show');
        onOpen && onOpen();
    }, [ready, onOpen]);
    if (!apiKey || !ready) {
        return null;
    }
    return children({ openWidget: openWidget });
};
