import { Translate } from '@gaming-shell/i18n';
import { Button, Snackbar } from '@material-ui/core';
import * as React from 'react';
import { BetShareButton } from 'sports/components/BetShareButton';

export interface BetslipBetPlacedSnackbarProps {
	open: boolean;
	onClose: () => void;
	onPlaceAgain: () => void;
	betUuid?: string;
}
export const BetslipBetPlacedSnackbar: React.FunctionComponent<BetslipBetPlacedSnackbarProps> = (
	props
) => {
	const { onClose, onPlaceAgain, open, betUuid } = props;
	return (
		<Snackbar
			open={open}
			onClose={onClose}
			message={<Translate label="betting.result.accepted" ns="sports" />}
			action={[
				<Button
					key="placeAgain"
					color="primary"
					size="small"
					onClick={onPlaceAgain}
				>
					<Translate label="betting.placeAgain" ns="sports" />
				</Button>,
				ENABLE_BET_SHARE && betUuid ? (
					<BetShareButton key={betUuid} betId={betUuid} />
				) : (
					<Button
						onClick={onClose}
						key="close"
						size="small"
						color="secondary"
					>
						<Translate label="actions.close" />
					</Button>
				)
			]}
		/>
	);
};
