import { makeStyles, Typography } from '@material-ui/core';
import clx from 'classnames';
import {
	createDynamicStyle,
	makeDynamicStyles,
	useFreeze
} from 'materialbet-common';
import * as React from 'react';
import { isPrimaryMarket, MarketType } from 'sports-sdk';
import {
	BetPrice,
	BetPriceIndicator
} from 'sports/components/displays/BetPrice';
import { OutcomeName } from 'sports/components/outcome/OutcomeName';
import {
	makeGetHideSelection,
	makeGetSelectionPrice
} from 'sports/selectors/selectionSelectors';
import { useMakeSelector } from 'sports/utils/hooks';
import { usePromotionStyles } from 'sports/utils/promotionStyles';
import { useOutcomeStyles } from 'sports/utils/styles';
import { useIsZeroMarginPromotionActive } from 'sports/utils/zeroMarginUtils/zeroMarginUtils';

import { useBetslipIsSubmitting } from '../betterBetslip/hooks/useBetslipIsSubmitting';
import { useSelectionIsInBetslip } from '../betterBetslip/hooks/useSelectionIsInBetslip';
import { EventListVariant } from '../eventList/types';

export interface OutcomeDisplayTextProps {
	eventId: number;
	params: string;
	outcome: string;
	marketType: MarketType;
	submarketKey: string;
	simplified?: boolean;
	variant?: EventListVariant;
	enableGoalsMarketView?: boolean;
	hideOutcomeName?: boolean;
	hideOutcomeNamePrefix?: boolean;
	enableVerticalAlign?: boolean;
	shorten?: boolean;
}
const usePriceStyles = makeDynamicStyles({
	root: createDynamicStyle(
		{
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center'
		},
		{
			// active_enableVerticalAlign
			// eslint-disable-next-line @typescript-eslint/camelcase
			true_true: {
				color: 'inherit',
				width: '50%'
			},
			// eslint-disable-next-line @typescript-eslint/camelcase
			true_false: {
				color: 'inherit',
				width: 'auto'
			},
			// eslint-disable-next-line @typescript-eslint/camelcase
			false_true: (theme) => ({
				color: theme.palette.primary.main,
				width: '50%'
			}),
			// eslint-disable-next-line @typescript-eslint/camelcase
			false_false: (theme) => ({
				color: theme.palette.primary.main,
				width: 'auto'
			})
		},
		({
			active,
			enableVerticalAlign
		}: {
			active: boolean;
			enableVerticalAlign?: boolean;
		}) => `${active}_${!!enableVerticalAlign}`
	)
});

const useDivStyles = makeStyles({
	outcomeContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		flex: '1'
	},
	betPriceIndicatorContainer: {
		flexGrow: 1,
		flexShrink: 0,
		flexBasis: 0,
		textAlign: 'left'
	},
	linearIndicatorSpacing: {
		flexGrow: 0,
		flexShrink: 0,
		flexBasis: 0
	},
	variantWideSpacing: {
		flexGrow: 1,
		flexShrink: 0,
		flexBasis: 0
	},
	betPriceAndIndicatorStyle: {
		flex: '1 1 100%',
		alignItems: 'center'
	}
});
const useSimplifiedRootStyles = makeDynamicStyles({
	simplifiedRoot: createDynamicStyle(
		{
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: 'flex',
			justifyContent: 'center'
		},
		{
			// eslint-disable-next-line @typescript-eslint/camelcase
			narrow_true: {
				whiteSpace: 'normal',
				width: '100%'
			},
			// eslint-disable-next-line @typescript-eslint/camelcase
			narrow_false: {
				whiteSpace: 'normal',
				width: 'auto'
			},
			// eslint-disable-next-line @typescript-eslint/camelcase
			wide_true: {
				whiteSpace: 'nowrap',
				width: '100%'
			},
			// eslint-disable-next-line @typescript-eslint/camelcase
			wide_false: {
				whiteSpace: 'nowrap',
				width: 'auto'
			}
		},
		(props: {
			variant: 'wide' | 'narrow';
			enableVerticalAlign?: boolean;
		}) => `${props.variant}_${!!props.enableVerticalAlign}`
	)
});
const useSimplifiedPriceStyles = makeDynamicStyles({
	root: createDynamicStyle(
		{
			display: 'flex'
		},
		{
			true: {
				color: 'inherit'
			},
			false: (theme) => ({
				color: theme.palette.primary.main
			})
		}
	)
});

let OutcomeDisplayText: React.FunctionComponent<OutcomeDisplayTextProps> = ({
	eventId,
	params,
	outcome,
	marketType,
	submarketKey,
	simplified,
	variant = 'wide',
	hideOutcomeName,
	hideOutcomeNamePrefix,
	enableVerticalAlign,
	shorten
}) => {
	const divClasses = useDivStyles();
	const promotionClasses = usePromotionStyles();
	const isPrimary = isPrimaryMarket(marketType);
	const isZeroMarginPromotion = useIsZeroMarginPromotionActive(eventId);
	const enablePromotionStyles = isPrimary && isZeroMarginPromotion;
	const { simplifiedRoot } = useSimplifiedRootStyles({
		variant,
		enableVerticalAlign
	});
	const isSubmitting = useBetslipIsSubmitting();
	const isInBetslip = useSelectionIsInBetslip({
		eventId,
		outcome,
		marketKey: marketType,
		params
	});
	const active = isInBetslip;
	const outcomeClasses = useOutcomeStyles();
	const simplifiedPriceClasses = {
		...useSimplifiedPriceStyles(active),
		body2:
			enablePromotionStyles && !active
				? promotionClasses.oddsColor
				: undefined
	};
	const selectionId = {
		eventId,
		params,
		outcome,
		marketType,
		submarketKey
	};
	const priceStylesClasses = {
		...usePriceStyles({ active, enableVerticalAlign }),
		body2:
			enablePromotionStyles && !active
				? promotionClasses.oddsColor
				: undefined
	};

	// TODO connect component instead of using hooks
	const price = useMakeSelector(makeGetSelectionPrice, selectionId) || 0;
	const hideSelection =
		useMakeSelector(makeGetHideSelection, selectionId) || false;

	const odds = useFreeze(price, isInBetslip && isSubmitting);
	if (variant === 'narrow' && simplified) {
		return (
			<div className={divClasses.outcomeContainer}>
				{!hideSelection && !!price && (
					<OutcomeName
						simplified={simplified}
						variant={variant}
						hideOutcomeName={hideOutcomeName}
						hideOutcomeNamePrefix={hideOutcomeNamePrefix}
						enableVerticalAlign={enableVerticalAlign}
						eventId={eventId}
						marketType={marketType}
						params={params}
						outcome={outcome}
						submarketKey={submarketKey}
						shorten={shorten}
					/>
				)}
				<div
					className={clx(
						simplifiedRoot,
						divClasses.betPriceAndIndicatorStyle
					)}
				>
					<div className={divClasses.linearIndicatorSpacing} />
					<Typography
						variant="body2"
						color="primary"
						classes={simplifiedPriceClasses}
						display="inline"
					>
						<BetPrice
							odds={odds}
							side="back"
							hideSelection={hideSelection}
						/>
					</Typography>
				</div>
				<BetPriceIndicator odds={odds} side="back" />
			</div>
		);
	}
	return (
		<>
			{simplified && <div className={divClasses.variantWideSpacing} />}
			<div className={simplified ? simplifiedRoot : outcomeClasses.root}>
				{!hideSelection && !!price && (
					<OutcomeName
						simplified={simplified}
						variant={variant}
						hideOutcomeName={hideOutcomeName}
						hideOutcomeNamePrefix={hideOutcomeNamePrefix}
						enableVerticalAlign={enableVerticalAlign}
						eventId={eventId}
						marketType={marketType}
						params={params}
						outcome={outcome}
						submarketKey={submarketKey}
						shorten={shorten}
					/>
				)}
				<Typography
					variant="body2"
					color="primary"
					classes={priceStylesClasses}
					display="inline"
				>
					<BetPrice
						odds={odds}
						side="back"
						hideSelection={hideSelection}
					/>
				</Typography>
			</div>
			<div className={divClasses.betPriceIndicatorContainer} />
			<BetPriceIndicator odds={odds} side="back" />
		</>
	);
};

OutcomeDisplayText = React.memo(OutcomeDisplayText);
export { OutcomeDisplayText };
