import merge from 'deepmerge';
import { actions as commonActions } from 'sports/modules/common';
import { getType } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';

const arrayMerge = (
	destination: Array<string | number>,
	origin: Array<string | number>
) => {
	const hash = {};
	const originHash = {}; // if a value is twice in origin we want it merged twice into the target
	const arr = [];
	if (origin[0] && typeof origin[0] === 'object') {
		return origin; // we overwrite if array consists of object
	}
	for (const e of destination) {
		if (hash[e] !== true) {
			hash[e] = true;
			arr[arr.length] = e;
		}
	}
	for (const e of origin) {
		if (hash[e] !== true || originHash[e] === true) {
			hash[e] = true;
			originHash[e] = true;
			arr[arr.length] = e;
		}
	}
	return arr;
};
const defaultMerge = <T extends {}>(destination: T, origin: T): T =>
	// circular dep between default <-> custom merge
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	merge(destination, origin, { arrayMerge, customMerge });
const overwrite = <T extends {}>(destination: T, origin: T) => origin;
const sequenceMerge = <T extends { sequence?: unknown }>(
	destination: T,
	origin: T
): T => {
	if (
		destination &&
		origin &&
		destination.sequence &&
		typeof destination.sequence === 'number' &&
		origin.sequence &&
		typeof origin.sequence === 'number' &&
		origin.sequence < destination.sequence
	) {
		return destination;
	}
	return defaultMerge(destination, origin);
};
const customMerge = (key: string) => {
	if (key === 'metadata') {
		return overwrite;
	}
	return sequenceMerge;
};

export const createEntityReducer = <TEntity>(entityKey: string) => (
	state: DeepReadonly<
		Record<string, TEntity>
	> = ({} as unknown) as DeepReadonly<Record<string, TEntity>>,
	action: { type: string; payload?: object }
) => {
	if (action.type === getType(commonActions.clear)) {
		return {};
	}

	if (
		!action.payload ||
		action.type !== getType(commonActions.updateEntities) ||
		!action.payload[entityKey]
	) {
		return state;
	}
	return defaultMerge(
		state,
		action.payload[entityKey] as DeepReadonly<{
			[key: string]: TEntity;
		}>
	);
};
