import { Theme as DefaultTheme, makeStyles } from '@material-ui/core';
import { CSSProperties } from 'react';

type StyleRules = CSSProperties | (() => CSSProperties);
type StyleRulesCallback<Theme> = (theme: Theme) => StyleRules;

type Styles<Theme> = StyleRules | StyleRulesCallback<Theme>;
type StyleProps = object | string | boolean;
// export type VariantType<T extends NotReservedKeys<U>, U extends string = T > = T
type PropertyMapper<TStyleProps extends StyleProps, TVariant extends string> = (
	props: TStyleProps
) => TVariant;

interface DynamicStyleClass<
	TStyleProps extends StyleProps,
	TVariant extends string,
	Theme extends DefaultTheme
> {
	_default: Styles<Theme>;
	_propertyMapper: PropertyMapper<TStyleProps, TVariant>;
	variants: { [variant in TVariant]: Styles<Theme> };
}

type DynamicStyle<
	TStyleProps extends StyleProps,
	TVariant extends string,
	Theme extends DefaultTheme,
	ClassKey extends string
> = {
	[variant in ClassKey]: DynamicStyleClass<TStyleProps, TVariant, Theme>;
};

type StyleHooks<TVariant extends string> = {
	[variant in TVariant]: ReturnType<typeof makeStyles>;
};
interface Rule<TStyleProps extends StyleProps, TVariant extends string> {
	mapper: PropertyMapper<TStyleProps, TVariant>;
	hooks: Record<TVariant, ReturnType<typeof makeStyles>>;
}

type RuleSet<
	TStyleProps extends StyleProps,
	ClassKeys extends string,
	TVariant extends string
> = Record<ClassKeys, Rule<TStyleProps, TVariant>>;
const identityMapper = <T extends StyleProps, TVariant extends string>(t: T) =>
	t.toString() as TVariant;
export const resolveOrReturn = <T>(styles: Styles<T>, theme: T) => {
	if (typeof styles === 'function') {
		return styles(theme);
	}
	return styles;
};

export const createDynamicStyle = <
	TStyleProps extends StyleProps,
	TVariant extends string,
	Theme extends DefaultTheme
>(
	base: Styles<Theme>,
	variants: { [variant in TVariant]: Styles<Theme> },
	mapper: PropertyMapper<TStyleProps, TVariant> = identityMapper
): DynamicStyleClass<TStyleProps, TVariant, Theme> => {
	return {
		_default: base,
		_propertyMapper: mapper,
		variants
	};
};

export const makeDynamicStyles = <
	TStyleProps extends StyleProps,
	TVariant extends string,
	Theme extends DefaultTheme,
	ClassKey extends string
>(
	dynamicStyle: DynamicStyle<TStyleProps, TVariant, Theme, ClassKey>
) => {
	const ruleSet = createRules(dynamicStyle);
	return (props: TStyleProps) => {
		const classes = resolveHooks(ruleSet, props);
		return classes;
	};
};

export const createRules = <
	TStyleProps extends StyleProps,
	TVariant extends string,
	Theme extends DefaultTheme,
	ClassKey extends string
>(
	dynamicStyle: DynamicStyle<TStyleProps, TVariant, Theme, ClassKey>
) => {
	const ruleSet = Object.keys(dynamicStyle).reduce((rules, classname) => {
		const {
			_default,
			_propertyMapper,
			variants: styleClass
		} = dynamicStyle[classname];

		const styleHooks = Object.keys(styleClass).reduce((acc, variant) => {
			const styles = styleClass[variant];
			if (
				typeof styles === 'function' ||
				typeof _default === 'function'
			) {
				acc[variant] = makeStyles((theme) => ({
					[classname]: {
						...resolveOrReturn(_default, theme),
						...resolveOrReturn(styles, theme)
					}
				}));
			} else {
				acc[variant] = makeStyles({
					[classname]: { ..._default, ...styles }
				});
			}
			return acc;
		}, {} as StyleHooks<TVariant>);
		rules[classname] = { mapper: _propertyMapper, hooks: styleHooks };
		return rules;
	}, {} as RuleSet<TStyleProps, ClassKey, TVariant>);
	return ruleSet;
};

export const resolveHooks = <
	TStyleProps extends StyleProps,
	ClassKey extends string,
	TVariant extends string
>(
	ruleSet: RuleSet<TStyleProps, ClassKey, TVariant>,
	styleProps: TStyleProps
): Record<ClassKey, string> => {
	const classes = Object.keys(ruleSet).reduce((acc, classname) => {
		const rule = ruleSet[classname];
		const classes = Object.keys(rule.hooks).reduce((dict, variant) => {
			dict[variant] = rule.hooks[variant]();
			return dict;
		}, {} as Record<TVariant, string>);
		const variant = rule.mapper(styleProps);
		return { ...acc, ...classes[variant] };
	}, {} as Record<ClassKey, string>);
	return classes;
};
