import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: [
			{
				marketType: MarketType.badminton_winner
			},
			{
				marketType: MarketType.badminton_handicap,
				view: MarketView.Handicap
			},
			{
				marketType: MarketType.badminton_totals,
				view: MarketView.Goals
			},
			{
				marketType: MarketType.badminton_correct_score,
				view: MarketView.CorrectScore
			},
			{
				marketType: MarketType.badminton_game_winner
			},
			{
				marketType: MarketType.badminton_exact_games
			},
			{
				marketType: MarketType.badminton_game_odd_even
			},
			{
				marketType: MarketType.badminton_game_point_handicap,
				view: MarketView.Handicap
			},
			{
				marketType: MarketType.badminton_game_pointnr
			},
			{
				marketType: MarketType.badminton_game_race_to_points
			},
			{
				marketType: MarketType.badminton_game_total_points,
				view: MarketView.Goals
			},
			{
				marketType: MarketType.badminton_nr_games_by_extra_points
			}
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.badminton_winner
			},
			{
				marketType: MarketType.badminton_handicap
			},
			{
				marketType: MarketType.badminton_totals
			}
		]
	}
];

export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.badminton_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
