import { route } from '@gaming-shell/routing';

export const hasAuthEnabled = () =>
	ENABLE_AUTH ||
	(ENABLE_AUTH_OVERRIDE &&
		localStorage.getItem('ENABLE_AUTH') === BRAND_NAME);

/** Creates an auth route */
export const authRoute = (path: string) => `/auth/${path}`;

export const LegacyAuthRoutes = {
	root: route(['auth']),
	check2fa: route(['auth', 'check_2fa']) /* two factor auth */,
	reset2fa: route(['auth', 'reset_2fa']),
	forgotPassword: route(['auth', 'forgot_password']),
	resetPassword: route(['auth', 'reset_password']),
	signIn: route(['auth', 'sign_in']),
	signUpConfirmation: route(['auth', 'sign_up_confirmation']),
	signUp: route(['auth', 'sign_up']),
	sso: route(['auth', 'sso']),
	locationRestricted: route(['auth', 'location_restricted']),
	logout: route(['auth', 'logout']),
	authCode: route(['auth-code'])
};

const NewAuthRoutes = {
	root: route(['auth']),
	check2fa: route(['auth', 'check_2fa']) /* two factor auth */,
	reset2fa: route(['auth', 'reset_2fa']),
	forgotPassword: route(['auth', 'forgot_password']),
	resetPassword: route(['auth', 'reset_password']),
	signIn: route(['sign-in']),
	signUpConfirmation: route(['auth', 'sign_up_confirmation']),
	signUp: route(['sign-up']),
	sso: route(['auth', 'sso']),
	locationRestricted: route(['location-restricted']),
	logout: route(['sign-out']),
	authCode: route(['auth-code'])
};

export const AuthRoutes = hasAuthEnabled() ? NewAuthRoutes : LegacyAuthRoutes;
