import { EventResponse } from 'sports/api/sports';

import { BetslipReducerSelection } from '../types';

export const getEventSelectionByBetslipSelection = (
	event: EventResponse,
	selection: BetslipReducerSelection
) => {
	const markets = event?.markets;
	if (!markets) {
		return;
	}
	const eventSelections =
		markets[selection.marketKey]?.submarkets?.[selection.submarketKey]
			?.selections;
	if (!eventSelections) {
		return;
	}
	const eventSelection = eventSelections.find(
		(es) =>
			es.outcome === selection.outcome && es.params === selection.params
	);
	return eventSelection;
};
