"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapApiResponseMessage = exports.mapApiResponseNotification = void 0;
var mapDatetimeToTimestamp = function (date) {
    return new Date(date.replace(' ', 'T') + ":00Z").getTime() / 1000;
};
exports.mapApiResponseNotification = function (responseObj) {
    var id = responseObj.id, createdAt = responseObj.created_at, expiresAt = responseObj.expires_at, content = responseObj.content, flags = responseObj.flags;
    var contentUrl = responseObj.content_url;
    var contentBody = content.body;
    var mappedNotification = {
        id: id,
        unread: !flags.message_read,
        dismissed: flags.notification_read,
        title: content.title,
        description: content.summary,
        creationTimestamp: mapDatetimeToTimestamp(createdAt),
        expirationTimestamp: expiresAt
            ? mapDatetimeToTimestamp(expiresAt)
            : null,
        contentUrl: contentUrl,
        contentBody: contentBody
    };
    return mappedNotification;
};
exports.mapApiResponseMessage = exports.mapApiResponseNotification;
