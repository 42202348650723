import * as React from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
	icon: {
		color: theme.palette.primary.main
	}
}));

export interface NoticeAlertIconProps {
	className?: string;
}

export const NoticeAlertIcon: React.FC<NoticeAlertIconProps> = ({
	className
}) => {
	const classes = useStyles();
	return <NotificationsIcon className={clsx(classes.icon, className)} />;
};
