import * as React from 'react';
import { MarketType } from 'sports-sdk';
import { useStartTimesSelector } from 'sports/selectors/sports';

import { getEventEntry } from '../getEventEntry';
import { EventEntryFormatProps, EventListVariant } from '../types';

export interface EventGroupsProps extends EventEntryFormatProps {
	eventIds: number[];
	marketKey?: MarketType;
	live?: boolean;
	timeZone: string;
	variant?: EventListVariant;
	disableFirstEventDivider?: boolean;
}

let EventGroups: React.FunctionComponent<EventGroupsProps> = (props) => {
	const {
		eventIds,
		marketKey,
		variant,
		disableToEventIcon,
		disableEventTime,
		disableFirstEventDivider,
		disableMarketHeader,
		disableMarketHeaderDate,
		disableMultipleMarketHeader,
		disablePriceList,
		timeZone,
		outrights,
		orientation
	} = props;
	const startTimes = useStartTimesSelector(eventIds);
	const events = startTimes.reduce<React.ReactNode[]>((acc, e, i) => {
		const previous = startTimes[i - 1];
		const entry = getEventEntry({
			eventId: e.id,
			startTime: e.startTime,
			disableEventTime,
			disableMarketHeaderDate,
			disableEventDivider: disableFirstEventDivider && i === 0,
			disableMarketHeader:
				disableMarketHeader || (disableMultipleMarketHeader && i !== 0),
			disableToEventIcon,
			disablePriceList,
			variant,
			marketKey,
			previousStartTime: previous && previous.startTime,
			timeZone,
			outrights,
			orientation
		});
		acc.push(...entry);
		return acc;
	}, []);
	return <>{events}</>;
};
EventGroups = React.memo(EventGroups);
export { EventGroups };
