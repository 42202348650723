const sportsEventStatus = {
	soccer: {
		awaiting: ['awaiting'],
		breakTime: ['45:00', '90:00']
	}
};

export const shouldEventTime = (
	sportsKey: string,
	eventStatus?: string,
	eventTimeExtended?: string
) => {
	if (!eventStatus || !eventTimeExtended) {
		return false;
	}

	if (!sportsEventStatus[sportsKey]) {
		return false;
	}

	const { awaiting, breakTime } = sportsEventStatus[sportsKey];

	// Fall back for other sportsKey, in case
	if (!awaiting || !breakTime) {
		return false;
	}
	const isAwaiting = awaiting.includes(eventStatus);
	const isBreakTime = breakTime.includes(eventTimeExtended);
	return isAwaiting || (!isAwaiting && isBreakTime);
};
