import { isDesktop } from '@gaming-shell/device';
import { getStaticMedia } from 'materialbet-common';
import { sportImage } from 'sports/utils/sportMedia';

const sportsBannerUrl = (sport: string) => {
	return `https://casino-assets.netlify.com/sports/${sport}`;
};

const flagIconBaseUrl = (locale: string) => {
	return `https://material-icons.netlify.com/svg/${locale}.svg`;
};

const flagIconsAvailable = [
	'abkhazia',
	'afghanistan',
	'aland-islands',
	'albania',
	'algeria',
	'american-samoa',
	'andorra',
	'angola',
	'anguilla',
	'antigua-and-barbuda',
	'argentina',
	'armenia',
	'aruba',
	'australia',
	'austria',
	'azerbaijan',
	'azores-islands',
	'bahamas',
	'bahrain',
	'balearic-islands',
	'bangladesh',
	'barbados',
	'basque-country',
	'belarus',
	'belgium',
	'belize',
	'benin',
	'bermuda',
	'bhutan-1',
	'bhutan',
	'bolivia',
	'bonaire',
	'bosnia-and-herzegovina',
	'botswana',
	'brazil',
	'british-columbia',
	'british-indian-ocean-territory',
	'british-virgin-islands',
	'brunei',
	'bulgaria',
	'burkina-faso',
	'burundi',
	'cambodia',
	'cameroon',
	'canada',
	'canary-islands',
	'cape-verde',
	'cayman-islands',
	'central-african-republic',
	'ceuta',
	'chad',
	'chile',
	'china',
	'christmas-island',
	'cocos-island',
	'colombia',
	'comoros',
	'cook-islands',
	'corsica',
	'costa-rica',
	'croatia',
	'cuba',
	'curacao',
	'cyprus',
	'czech-republic',
	'democratic-republic-of-congo',
	'denmark',
	'djibouti',
	'dominica',
	'dominican-republic',
	'east-timor',
	'ecuador',
	'egypt',
	'england',
	'equatorial-guinea',
	'eritrea',
	'estonia',
	'ethiopia',
	'european-union',
	'falkland-islands',
	'faroe-islands',
	'fiji',
	'finland',
	'france',
	'french-polynesia',
	'gabon',
	'galapagos-islands',
	'gambia',
	'georgia',
	'germany',
	'ghana',
	'gibraltar',
	'greece',
	'greenland',
	'grenada',
	'guam',
	'guatemala',
	'guernsey',
	'guinea-bissau',
	'guinea',
	'guyana',
	'haiti',
	'hawaii',
	'honduras',
	'hong-kong',
	'hungary',
	'iceland',
	'india',
	'indonesia',
	'iran',
	'iraq',
	'ireland',
	'isle-of-man',
	'israel',
	'italy',
	'ivory-coast',
	'jamaica',
	'japan',
	'jersey',
	'jordan',
	'kazakhstan',
	'kenya',
	'kiribati',
	'kosovo',
	'kuwait',
	'kyrgyzstan',
	'laos',
	'latvia',
	'lebanon',
	'lesotho',
	'liberia',
	'libya',
	'liechtenstein',
	'lithuania',
	'luxembourg',
	'macao',
	'madagascar',
	'madeira',
	'malawi',
	'malaysia',
	'maldives',
	'mali',
	'malta',
	'marshall-island',
	'martinique',
	'mauritania',
	'mauritius',
	'melilla',
	'mexico',
	'micronesia',
	'moldova',
	'monaco',
	'mongolia',
	'montenegro',
	'montserrat',
	'morocco',
	'mozambique',
	'myanmar',
	'namibia',
	'nato',
	'nauru',
	'nepal',
	'netherlands',
	'new-zealand',
	'nicaragua',
	'niger',
	'nigeria',
	'niue',
	'norfolk-island',
	'north-korea',
	'northen-cyprus',
	'northern-marianas-islands',
	'norway',
	'oman',
	'orkney-islands',
	'ossetia',
	'pakistan',
	'palau',
	'palestine',
	'panama',
	'papua-new-guinea',
	'paraguay',
	'peru',
	'philippines',
	'pitcairn-islands',
	'portugal',
	'puerto-rico',
	'qatar',
	'rapa-nui',
	'republic-of-macedonia',
	'poland',
	'republic-of-the-congo',
	'romania',
	'russia',
	'rwanda',
	'saba-island',
	'saint-kitts-and-nevis',
	'salvador',
	'samoa',
	'san-marino',
	'sao-tome-and-principe',
	'sardinia',
	'saudi-arabia',
	'scotland',
	'senegal',
	'serbia',
	'seychelles',
	'sierra-leone',
	'singapore',
	'sint-eustatius',
	'sint-maarten',
	'slovakia',
	'slovenia',
	'solomon-islands',
	'somalia',
	'somaliland',
	'south-africa',
	'south-korea',
	'south-sudan',
	'spain',
	'sri-lanka',
	'st-barts',
	'st-lucia',
	'st-vincent-and-the-grenadines',
	'sudan',
	'suriname',
	'swaziland',
	'sweden',
	'switzerland',
	'syria',
	'taiwan',
	'tajikistan',
	'tanzania',
	'thailand',
	'tibet',
	'togo',
	'tokelau',
	'tonga',
	'transnistria',
	'trinidad-and-tobago',
	'tunisia',
	'turkey',
	'turkmenistan',
	'turks-and-caicos',
	'tuvalu',
	'uganda',
	'ukraine',
	'united-arab-emirates',
	'united-kingdom',
	'united-nations',
	'united-states-of-america',
	'uruguay',
	'uzbekistn',
	'vanuatu',
	'vatican-city',
	'venezuela',
	'vietnam',
	'virgin-islands',
	'wales',
	'western-sahara',
	'yemen',
	'zambia',
	'zimbabwe',
	'usa'
];

interface BannerImageProps {
	sportsKey: string;
	image?: string;
	height?: number | string;
	width?: number;
	opacity?: number;
	background?: string;
	crop?: string;
	gravity?: string;
}
export const getBannerImage = (props: BannerImageProps) => {
	const {
		sportsKey,
		height = 190,
		width = isDesktop() ? 1280 : 470,
		opacity = 90,
		background = '#000000',
		crop = 'fill',
		gravity = 'center'
	} = props;
	let { image } = props;
	// Returns competitor image if exists

	const backgroundColorCode = background.substring(1);
	const defaultGravity = image && image.length > 0 ? 'auto:face' : gravity;
	const cloudinaryUrl = `${CLOUDINARY_URL}/image/fetch/w_${width},h_${height},o_${opacity},b_rgb:${backgroundColorCode},c_${crop},g_${defaultGravity}/`;
	if (image && image.length > 0) {
		return `${cloudinaryUrl}${CDN_URL}${image}`;
	}
	if (sportsKey) {
		if (ENABLE_NEW_SPORTS_IMAGE) {
			return `${cloudinaryUrl}${getStaticMedia(sportImage[sportsKey])}`;
		}
		image = sportsBannerUrl(sportsKey);
	}
	const url = `${image}/${width}/${height}`;
	return url;
};

export const getFlagIconUrl = (flagKey: string) => {
	const hasIconAvailable =
		flagIconsAvailable.includes(flagKey) && flagKey.length;
	if (hasIconAvailable) return flagIconBaseUrl(flagKey);
	return null;
};
