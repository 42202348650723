// https://github.com/bitgaming/gaming_engine/blob/1a8750aabe51798216590792a4567c17285bf303/app/models/casino_game.rb#L506-L510
// TODO: add more tag for the type
/** @description enum tags for categories */
export enum CategoryGameTagType {
	slots = 'slots',
	jackpotSlots = 'jackpotSlots',
	videoPoker = 'videoPoker',
	tableGames = 'tableGames',
	instantWin = 'instantWin',
	bingoAndKeno = 'bingoAndKeno',
	roulette = 'roulette',
	baccarat = 'baccaraat',
	blackJack = 'blackJack',
	virtual = 'virtuals'
}
/** @description enum tags for marketing */
export enum MarketingGameTagType {
	featured = 'featured',
	new = 'new',
	top10 = 'top10',
	branded = 'branded',
	promotions = 'promotions',
	bigWinners = 'bigWinners'
}
/** @description enum tags other than categories and marketing */
export enum OtherGameTagType {
	/** @description indicate if the game is player's favorite game */
	'favorite' = 'my-casino' // favorite is more understandable here and avoid the hyphen. could be change to favorite for type later
}
export type GameTagType =
	| CategoryGameTagType
	| MarketingGameTagType
	| OtherGameTagType;
/** @description access game tags value with the key */
export const GameTagType = {
	...CategoryGameTagType,
	...MarketingGameTagType,
	...OtherGameTagType
};
