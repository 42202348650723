import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

const Virtuals: React.FunctionComponent<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<path
				fill={props.fill}
				fillRule="nonzero"
				d="M12 2a3.22 3.22 0 0 1 1.042 6.268 5.07 5.07 0 0 1 4.028 4.962v1.85a.75.75 0 0 1-.75.75h-1.172l-.552 5.495a.75.75 0 0 1-.746.675h-3.7a.75.75 0 0 1-.746-.675l-.552-5.495H7.68a.75.75 0 0 1-.75-.75v-1.85a5.072 5.072 0 0 1 4.028-4.963A3.22 3.22 0 0 1 12 2zM5.83 4.47a2.6 2.6 0 0 1 .526 5.147.75.75 0 0 1-.526 1.283 2.33 2.33 0 0 0-2.325 2.17l-.005.16v1.1H4a.75.75 0 0 1 .725.559l.02.1.52 4.261H7.07a.75.75 0 0 1 .102 1.493l-.102.007H4.6a.75.75 0 0 1-.725-.559l-.02-.1-.52-4.261H2.75a.75.75 0 0 1-.743-.648L2 15.08v-1.85a3.832 3.832 0 0 1 2.927-3.723A2.6 2.6 0 0 1 5.83 4.47zm12.34 0a2.6 2.6 0 0 1 .903 5.039 3.828 3.828 0 0 1 2.922 3.522l.005.199v1.85a.75.75 0 0 1-.648.743l-.102.007h-.586l-.52 4.26a.75.75 0 0 1-.642.653l-.102.007h-2.47a.75.75 0 0 1-.102-1.493l.102-.007h1.806l.52-4.26a.75.75 0 0 1 .642-.653L20 14.33h.5v-1.1a2.33 2.33 0 0 0-2.33-2.33.75.75 0 0 1-.526-1.285 2.6 2.6 0 0 1 .526-5.145zM12 9.66a3.57 3.57 0 0 0-3.57 3.57v1.1h1.1a.75.75 0 0 1 .746.675l.552 5.495h2.344l.552-5.495a.75.75 0 0 1 .746-.675h1.1v-1.1A3.57 3.57 0 0 0 12 9.66zM5.83 5.97a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2zm12.34 0a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2zM12 3.5a1.72 1.72 0 1 0 0 3.44 1.72 1.72 0 0 0 0-3.44z"
			/>
		</SvgIcon>
	);
};
export default Virtuals;
