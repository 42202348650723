import {
	CasinoRoutes,
	PlayerRoutes,
	SportsRoutes,
	AuthRoutes
} from '../routes';
import { BonusEligibility } from '../types';

/* todo fix routes to use prefixed routes */
const routeMap = {
	sports: SportsRoutes,
	casino: CasinoRoutes
};
export const isBonusEligibleForPath = (
	bonusEligibility: BonusEligibility,
	pathname: string
) => {
	const eligiblePaths: ('casino' | 'sports')[] = bonusEligibility.map(
		(b: string) => b.replace('Bet', '').toLowerCase() as 'casino' | 'sports'
	);
	return (
		eligiblePaths.some((path) => routeMap[path].root.matches(pathname)) ||
		PlayerRoutes.root.matches(pathname) ||
		AuthRoutes.root.matches(pathname)
	);
};
