import { makeStyles } from '@material-ui/styles';
import { promotionColor } from 'sports/config/colors';

export const usePromotionStyles = makeStyles(() => ({
	highlight: {
		background: `linear-gradient(90deg, ${promotionColor}03 0%, ${promotionColor}1C 30%, ${promotionColor}1C 70%, ${promotionColor}03 100%)`
	},
	outcomeBorder: {
		border: `1px solid ${promotionColor}`
	},
	oddsColor: {
		/**@description Used to override odds colors in usePriceStyles(), useSimplifiedPriceStyles() for zero margin promotions  */
		color: `${promotionColor} !important`
	}
}));
