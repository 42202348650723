import { betslipUrl, sportsBettingUrl } from 'sports/config/sportsbetting';

import createSportsbetApi from './sportsbetting/api';

const configuration = {
	basePath: sportsBettingUrl,
	betslipBasePath: betslipUrl,
	baseOptions: {
		credentials: 'include',
		headers: {
			accept: 'application/json',
			'Content-Type': 'application/json',
			'Cache-Control': 'no-cache, no-store'
		}
	}
};
const api = createSportsbetApi(configuration);
export default api;
