import { usePusherProxy } from '@gaming-shell/pusher/dist/internal/hook';
import { PusherProxy } from '@gaming-shell/pusher/dist/internal/types';
import {
	usePlayerAuthToken,
	usePlayerChannel,
	usePlayerCurrency
} from '@materialbet-core/player';
import * as React from 'react';
import {
	PlaceBetStatus,
	PlaceBetV2Bet,
	PlaceBetV2RequestParams,
	PlaceBetV2Response,
	PlaceBetV2Selection
} from 'sports/api/sportsbetting/api';
import { useFunds } from 'sports/utils/hooks';
import { placeBetV2 } from 'sports/utils/placeBetV2';
import { trackOnBetslipSubmit } from 'sports/utils/trackingUtils';

import { BetslipContext } from '../BetslipContext';
import { getIsSubmitting } from '../betslipSelectors/betslipSelectors';
import { useBetslipSelector } from '../hooks/useBetslipSelector';
import {
	BetslipMode,
	BetslipReducerBetslip,
	PlaceBetCallbackArgs
} from '../types';
import { prePlaceBet } from './prePlaceBet';

export type PlaceBetCallback = (args: PlaceBetCallbackArgs) => void;
interface PlaceBetArgs {
	params: PlaceBetV2RequestParams;
	pusher: PusherProxy;
	authToken: string;
	playerChannel: string;
	betslip: BetslipReducerBetslip;
	mode: BetslipMode;
	onError: (
		status: PlaceBetStatus,
		bets: PlaceBetV2Bet[],
		selections: PlaceBetV2Selection[]
	) => void;
	callback: PlaceBetCallback;
	submit: () => void;
}

const placeBet = (args: PlaceBetArgs) => {
	const {
		params,
		pusher,
		playerChannel,
		onError,
		authToken,
		callback,
		submit,
		betslip,
		mode
	} = args;
	submit();
	trackOnBetslipSubmit();
	placeBetV2(params, authToken, playerChannel, pusher)
		.then((result) => {
			callback({ betslip, betUuid: result?.bets[0]?.uuid, mode });
		})
		.catch((error) => {
			const response = error as PlaceBetV2Response;
			if (
				response.referenceId &&
				response.referenceId === params.referenceId
			) {
				onError(response.status, response.bets, response.selections);
			} else {
				// did receive some server error, need to create custom error changes
				const errorSelections = params.selections.map((s) => {
					const errorSelection: PlaceBetV2Selection = {
						...s,
						status: 'INTERNAL_SERVER_ERROR'
					};
					return errorSelection;
				});
				onError('INTERNAL_SERVER_ERROR', params.bets, errorSelections);
			}
		});
};

export const useBetslipPlaceBet = () => {
	const pusher = usePusherProxy();
	const authToken = usePlayerAuthToken();
	const playerChannel = usePlayerChannel();
	const currency = usePlayerCurrency();
	const funds = useFunds();
	const isSubmitting = useBetslipSelector(getIsSubmitting);
	const betslipContext = React.useContext(BetslipContext);
	return React.useCallback(
		(callback: PlaceBetCallback) => {
			const result = prePlaceBet({
				isSubmitting,
				funds,
				store: betslipContext.store,
				currency
			});
			if (!result) {
				return;
			}
			const { params, submit, onError, betslip, mode } = result;
			placeBet({
				params,
				pusher,
				authToken,
				playerChannel,
				submit,
				callback,
				onError,
				betslip,
				mode
			});
		},
		[
			playerChannel,
			pusher,
			authToken,
			betslipContext.store,
			isSubmitting,
			currency,
			funds
		]
	);
};
