import { PlaceBetStatus } from '../../../api/sportsbetting/api';
import { PlaceBetErrorStatus } from '../types';

// prettier-ignore
export enum BetslipStatusFlags {
	Default						= 0,
	InternalServerError 		= 1 << 0,
	DuplicateRequest			= 1 << 1,
	MalformedRequest			= 1 << 2,
	PriceAboveMarket 			= 1 << 3,
	InsufficientFund 			= 1 << 4,
	StakeAboveMax 				= 1 << 5,
	StakeBelowMin				= 1 << 6,
	LiabilityLimitExceeded 		= 1 << 7,
	IncompleteRequest			= 1 << 8,
	MarketSuspended				= 1 << 9,
	Restricted					= 1 << 10,
	ActiveBonusMinPrice 		= 1 << 11,
	ActiveBonusRestriced		= 1 << 12,
	ActiveBonusPendingPayout 	= 1 << 13,
	ActiveBonusCorrelation 		= 1 << 14,
	ParlayRestriction			= 1 << 15,
	VerificationRequired 		= 1 << 16,
	ReofferAvailable			= 1 << 17,
	CorrelatedSelections		= 1 << 18, // remember to update maxbits if adding a another entry here

	/** Only and update with this status would cause to create changes that need to be accepted */
	CausesChange				= PriceAboveMarket | ReofferAvailable,

	/** @description A selection update can only set one these status */
	AllSelectionUpdateStatus	= PriceAboveMarket | MarketSuspended | ReofferAvailable,
	StakeValidationStatus		= StakeBelowMin | StakeAboveMax,

	RequiresAcceptance			= PriceAboveMarket | ReofferAvailable,
	RequiresStakeUpdate			= StakeAboveMax | StakeBelowMin | InsufficientFund,
	RequiresRemove				=  Restricted | ParlayRestriction | ActiveBonusPendingPayout | ActiveBonusCorrelation | ActiveBonusRestriced | CorrelatedSelections , // we dont include MarketSuspended as that condition can change so we allow to just retry again

	/** @description Any status requiring the user to take action (often just by clicking a button) should prevent showing the placebet button. We specifically exclude Insufficient Funds, as that status right might get stale, as its only set when doing full validation. */
	CannotValidate				= RequiresAcceptance | StakeAboveMax | StakeBelowMin | RequiresRemove,

	/** @description Status that can remove the selection via auto cleanup */
	CanBeRemoved 				= RequiresRemove | MarketSuspended | ActiveBonusMinPrice | ParlayRestriction | LiabilityLimitExceeded,

	/** @description Disable any bet placing UI if any of those statuses are active */
	CannotPlace 				= CannotValidate | InsufficientFund,

	/** @description These errors will highlight the selection in the UI */
	HighlightErrors 			= ParlayRestriction | MarketSuspended | LiabilityLimitExceeded | Restricted | CorrelatedSelections | VerificationRequired,

	/** @description Getting any of these status in response to placing a bet, means we need to update our max stake */
	UpdateMaxStake				= StakeAboveMax | LiabilityLimitExceeded,
	/** @description Getting this status in response means we will update the users stake directly */
	UpdateStake					= LiabilityLimitExceeded
}

export const maxBits = 18;

const placeBetErrorStatusToFlag: Record<
	PlaceBetErrorStatus | 'VALID',
	BetslipStatusFlags
> = {
	VALID: BetslipStatusFlags.Default,
	INTERNAL_SERVER_ERROR: BetslipStatusFlags.InternalServerError,
	DUPLICATE_REQUEST: BetslipStatusFlags.DuplicateRequest,
	MALFORMED_REQUEST: BetslipStatusFlags.MalformedRequest,
	PRICE_ABOVE_MARKET: BetslipStatusFlags.PriceAboveMarket,
	INSUFFICIENT_FUND: BetslipStatusFlags.InsufficientFund,
	STAKE_ABOVE_MAX: BetslipStatusFlags.StakeAboveMax,
	STAKE_BELOW_MIN: BetslipStatusFlags.StakeBelowMin,
	LIABILITY_LIMIT_EXCEEDED: BetslipStatusFlags.LiabilityLimitExceeded, // we dont show a message currently so this does not yet need to be translated
	INCOMPLETE_REQUEST: BetslipStatusFlags.IncompleteRequest,
	MARKET_SUSPENDED: BetslipStatusFlags.MarketSuspended,
	PARLAY_RESTRICTION: BetslipStatusFlags.ParlayRestriction,
	RESTRICTED: BetslipStatusFlags.Restricted,
	ACTIVE_BONUS_CORRELATION: BetslipStatusFlags.ActiveBonusCorrelation,
	ACTIVE_BONUS_MIN_PRICE: BetslipStatusFlags.ActiveBonusMinPrice,
	ACTIVE_BONUS_PENDING_PAYOUT: BetslipStatusFlags.ActiveBonusPendingPayout,
	ACTIVE_BONUS_RESTRICTED: BetslipStatusFlags.ActiveBonusRestriced,
	CORRELATED_SELECTIONS: BetslipStatusFlags.CorrelatedSelections,
	REOFFER_AVAILABLE: BetslipStatusFlags.ReofferAvailable,
	VERIFICATION_REQUIRED: BetslipStatusFlags.VerificationRequired
};

export const getBetslipStatusFlag = (status: PlaceBetStatus) =>
	status === 'VALID' ||
	status === 'ACCEPTED' ||
	status === 'PENDING_ACCEPTANCE'
		? BetslipStatusFlags.Default
		: placeBetErrorStatusToFlag[status];
