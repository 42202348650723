import { OutcomeInfo } from 'sports-sdk/sports-core/reduced-types';

export interface FormatOutcomeProps {
	defaultName: string;
	name: string | null;
	prefix: string | null;
}

export const formatOutcomeName = (
	defaultOutcomeName: string,
	outcomeDetails?: OutcomeInfo | null,
	shorten?: boolean
): FormatOutcomeProps => {
	const outcomeName: FormatOutcomeProps = {
		defaultName: defaultOutcomeName,
		name: !shorten ? defaultOutcomeName : null,
		prefix: null
	};

	if (!outcomeDetails || !outcomeDetails.variables) {
		return outcomeName;
	}

	const variables = outcomeDetails.variables;

	if (variables['handicap'] && variables['team']) {
		outcomeName.prefix = outcomeDetails.variables.team;
		outcomeName.name = outcomeDetails.variables.handicap;
	}

	// Total is over/under
	if (shorten && variables['total']) {
		const splitName = defaultOutcomeName.split(' ');
		outcomeName.prefix = splitName[0].charAt(0).toLowerCase();
		outcomeName.name = splitName[1];
	} else if (shorten) {
		outcomeName.prefix = null;
	}

	return outcomeName;
};
