import { createSelector } from 'reselect';
import { Submarket } from 'sports-sdk';
import {
	getOutcome,
	MarketType,
	selectionCountPerLine
} from 'sports-sdk/sports-core';
import { RootState } from 'sports/modules/root';
import { getSdkLocale } from 'sports/utils/locale';
import { formatOutcomeName } from 'sports/utils/outcome';

export interface OutcomeProps {
	eventId?: number | string;
	marketType?: MarketType;
	outcome?: string;
}
export interface SelectionIdentity {
	eventId?: number | string;
	outcome?: string;
	marketType?: MarketType;
	submarketKey?: string;
	params?: string;
}
const eventsSelector = (state: RootState) => state.events;
const outcomePropSelector = (_: unknown, props: SelectionIdentity) =>
	props.outcome;
const paramsPropsSelector = (_: unknown, props: SelectionIdentity) =>
	props.params;
const marketTypeSelector = (_: unknown, props: OutcomeProps) =>
	props.marketType;
const languagePropSelector = (_: unknown, props: { language: string }) =>
	props.language;
const shortenPropSelector = (_: unknown, props: { shorten?: boolean }) =>
	props.shorten;
export const getEvent = (state: RootState, { eventId }: OutcomeProps) =>
	eventsSelector(state)[eventId || ''];
export const getEventHome = (state: RootState, p: OutcomeProps) =>
	getEvent(state, p)?.home;
export const getEventAway = (state: RootState, p: OutcomeProps) =>
	getEvent(state, p)?.away;
export const getEventPlayers = (state: RootState, p: OutcomeProps) =>
	getEvent(state, p)?.players;

export const getMarket = (state: RootState, props: OutcomeProps) =>
	getEvent(state, props)?.markets?.[props.marketType || ''];

export const getSubmarket = (state: RootState, props: SelectionIdentity) =>
	getMarket(state, props)?.submarkets?.[props.submarketKey || ''];

export const makeGetSelection = () =>
	createSelector(
		[getSubmarket, outcomePropSelector, paramsPropsSelector],
		(submarket, outcome, params) =>
			submarket?.selections?.find(
				(s) => s.outcome === outcome && s.params === params
			)
	);
export const makeGetSelectionPrice = () => {
	const getSelection = makeGetSelection();
	return createSelector([getSelection], (selection) => selection?.price);
};

export const makeGetSelectionMinMaxStake = () => {
	const getSelection = makeGetSelection();
	return createSelector([getSelection], (selection) => {
		return { maxStake: selection?.maxStake, minStake: selection?.minStake };
	});
};

export const makeGetFirstLineSelections = () => {
	return createSelector(
		[getMarket, marketTypeSelector],
		(market, marketType) => {
			if (!market || !marketType) {
				return;
			}
			if (!market.submarkets) {
				return;
			}
			const firstSubmarketKey = Object.keys(market.submarkets)[0];
			const firstSubmarket = market.submarkets[
				firstSubmarketKey
			] as Submarket;
			if (!firstSubmarket) {
				return;
			}
			const selectionCount = selectionCountPerLine(
				marketType,
				firstSubmarket
			);

			const selections = firstSubmarket.selections;
			const firstLineSelections = selections.slice(
				0,
				selectionCount[0].count
			);
			return firstLineSelections;
		}
	);
};

export const makeFirstGetSelectionByOutcome = () => {
	const getSelections = makeGetFirstLineSelections();
	return createSelector(
		[getSelections, outcomePropSelector],
		(selections, outcomeType) => {
			if (!outcomeType || !selections) {
				return;
			}
			const selectionByOutcome = selections.find(
				(selection) => selection.outcome === outcomeType
			);
			return selectionByOutcome;
		}
	);
};

export const makeGetFirstOrderdSubmarketKey = () => {
	return createSelector([getMarket], (market) => {
		if (!market?.submarkets) {
			return;
		}
		const { submarkets } = market;
		const submarketKey = Object.keys(submarkets)
			.sort()
			.find((key) => {
				const submarket = submarkets[key];
				const hasSelections = submarket.selections
					? submarket.selections.length > 0
					: false;
				return !submarket.blank && hasSelections;
			});
		return submarketKey;
	});
};

export const makeGetFirstSubmarket = () => {
	const getSubmarket = makeGetFirstOrderdSubmarketKey();
	return createSelector([getMarket, getSubmarket], (market, submarketKey) => {
		return submarketKey ? market?.submarkets?.[submarketKey] : undefined;
	});
};

/**@descrioption retrieves first line with same logic as sdk*/
export const makeGetFirstLine = () => {
	return createSelector(
		[getMarket, marketTypeSelector],
		(market, marketType) => {
			if (!market || !marketType) {
				return;
			}
			if (!market.submarkets) {
				return;
			}
			const firstSubmarketKey: string = Object.keys(market.submarkets)[0];
			const firstSubmarket = market.submarkets[
				firstSubmarketKey
			] as Submarket;
			if (!firstSubmarket) {
				return;
			}
			const selectionCount = selectionCountPerLine(
				marketType,
				firstSubmarket
			);
			const selections = firstSubmarket.selections;
			const firstLine = selections.slice(0, selectionCount[0].count);
			return firstLine;
		}
	);
};

export const makeGetHideSelection = () => {
	const getSelection = makeGetSelection();
	return createSelector(
		[getSelection],
		(selection) => !(selection?.status === 'SELECTION_ENABLED')
	);
};

export const makeGetOutcome = () => {
	const getSelection = makeGetSelection();
	return createSelector(
		[
			getSelection,
			marketTypeSelector,
			getEventHome,
			getEventAway,
			getEventPlayers,
			languagePropSelector
		],
		(selection, marketType, home, away, players, language) => {
			if (!selection || !marketType || !language || !players) {
				return;
			}
			const [outcome] = getOutcome(
				getSdkLocale(language),
				{ home, away, players } as Parameters<typeof getOutcome>[1],
				marketType,
				selection as Parameters<typeof getOutcome>[3]
			);
			return outcome;
		}
	);
};
export const getMakeFormattedOutcomeName = () => {
	const outcomeSelector = makeGetOutcome();
	return createSelector(
		[outcomeSelector, shortenPropSelector],
		(outcome, shorten) => {
			if (!outcome) {
				return;
			}
			const formattedOutcomeName = formatOutcomeName(
				outcome.name || '',
				outcome,
				shorten
			);
			return formattedOutcomeName;
		}
	);
};
