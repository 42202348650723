/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
	SportWithCompetition,
	SportWithCompetitionFromJSON,
	SportWithCompetitionFromJSONTyped,
	SportWithCompetitionToJSON
} from './';

/**
 * EventListResponse presents a list of events
 * @export
 * @interface EventListResponse
 */
export interface EventListResponse {
	/**
	 *
	 * @type {Array<SportWithCompetition>}
	 * @memberof EventListResponse
	 */
	sports?: Array<SportWithCompetition>;
}

export function EventListResponseFromJSON(json: any): EventListResponse {
	return EventListResponseFromJSONTyped(json, false);
}

export function EventListResponseFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): EventListResponse {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		sports: !exists(json, 'sports')
			? undefined
			: (json['sports'] as Array<any>).map(SportWithCompetitionFromJSON)
	};
}

export function EventListResponseToJSON(value?: EventListResponse | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		sports:
			value.sports === undefined
				? undefined
				: (value.sports as Array<any>).map(SportWithCompetitionToJSON)
	};
}
