"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CornersIcon = void 0;
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
exports.CornersIcon = function (props) { return (React.createElement(core_1.SvgIcon, __assign({ viewBox: "0 0 24 24" }, props),
    React.createElement("path", { fill: props.fill, fillOpacity: ".87", d: "M4.583 4.583H2.75V2.75h1.833v1.833zm0 1.834H2.75V8.25h1.833V6.417zm14.667 3.666h-1.833v1.834h1.833v-1.834zm0-3.666h-1.833V8.25h1.833V6.417zm0 7.333h-1.833v1.833h1.833V13.75zm0 3.667h-1.833v1.833h1.833v-1.833zm-3.667 0H13.75v1.833h1.833v-1.833zM8.25 2.75H6.417v1.833H8.25V2.75zm3.667 0h-1.834v1.833h1.834V2.75zm-3.667 0H6.417v1.833H8.25V2.75zm7.333 0H13.75v1.833h1.833V2.75zm3.667 0h-1.833v1.833h1.833V2.75zM2.75 14.667c0 2.53 2.053 4.583 4.583 4.583h4.584v-1.833H7.333c-1.512 0-2.75-1.238-2.75-2.75v-4.584H2.75v4.584z", transform: "translate(1 1)" }))); };
