import * as React from 'react';
import {
	useFetchPlayerContext,
	PlayerContextProps,
	parseWallets
} from '@materialbet-core/auth';
import { useUpdatePlayer } from '@materialbet-core/player';
import { Results } from '@materialbet-core/api';
import useGeneralErrorHandler from 'core/utils/useGeneralErrorHandler';
import { warning, error } from '@gaming-shell/logging';

const playerCurrenciesContextProps: PlayerContextProps = [
	'currencies_available',
	'wallets'
];

export const useRefreshPlayerCurrencies = () => {
	const fetchPlayerCurrencies = useFetchPlayerContext(
		playerCurrenciesContextProps
	);
	const updatePlayer = useUpdatePlayer();
	const displayGenericError = useGeneralErrorHandler();

	const refreshPlayerCurrenciesCallback = React.useCallback(() => {
		return fetchPlayerCurrencies()
			.then((response) => {
				if (response.result === Results.OK) {
					const {
						wallets,
						currencies_available: currenciesAvailable
					} = response.player;
					if (!wallets || !currenciesAvailable) {
						error(
							'Missing wallets or currencies_available in /me 200 response',
							response
						);
					} else {
						updatePlayer((player) => ({
							...player,
							wallets: parseWallets(wallets),
							currenciesAvailable
						}));
					}
				}
				return response;
			})
			.catch((e) => {
				warning('Error during refreshing player currencies', e);
				displayGenericError();
			});
	}, [fetchPlayerCurrencies, updatePlayer, displayGenericError]);

	return refreshPlayerCurrenciesCallback;
};
