export const getCurrentPlayer = `
query GetPlayerMe {
    playerMe {
      id
      username
      selfExcludedTill
      dateOfBirth
      timeZone
      locale
      applyCountryRestriction
      vipLevel
      email
      regIpAddress
    }
}
`;
