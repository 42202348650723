import * as React from 'react';
import { Hit } from 'react-instantsearch-core';
import { connectHits, connectStateResults } from 'react-instantsearch-dom';
import { SportNameTranslation } from 'sports/components/displays/SportNameTranslation';

import {
	FilterItem,
	SearchFilterProps,
	SearchResultItem,
	SearchResultsProps
} from '../../types/sharedComponents';
import { AlgoliaHitsProvided } from '../SearchResults/Content';

const extractSportKey = (acc: FilterItem[], curr: Hit<SearchResultItem>) => {
	const { sportKey, sportId } = curr;
	if (sportKey && !acc.find((x) => x.name === sportKey)) {
		acc.push({ name: sportKey, value: sportId.toString() });
	}

	return acc;
};

const SearchFilters: React.FC<SearchFilterProps &
	SearchResultsProps<SearchResultItem>> = ({
	onClick,
	component,
	activeFilters,
	storeFilters,
	storedFilters,
	allSearchResults
}) => {
	const hits =
		allSearchResults?.[ALGOLIA_INDEXES.sports]
			?.hits; /* get only sports hits */
	const sports = hits
		?.reduce<FilterItem[]>(extractSportKey, [])
		?.sort((a, b) => (a.name.length < b.name.length ? -1 : 1));
	const Component = component;
	const handleClick = React.useCallback(
		(filter: FilterItem) => () => {
			onClick(filter);
			storedFilters.length === 0 &&
				sports?.length > 0 &&
				storeFilters(sports);
		},
		[storeFilters, storedFilters, onClick, sports]
	);

	return (
		<>
			{(storedFilters.length > 0 ? storedFilters : sports)?.map(
				(sport) => {
					const active = activeFilters?.find(
						(filter) => filter.name === sport.name
					);
					return (
						<Component onClick={handleClick(sport)} active={active}>
							<SportNameTranslation sportKey={sport.name} />
						</Component>
					);
				}
			)}
		</>
	);
};

/* that's required workaround because of algolia bug https://github.com/algolia/react-instantsearch/issues/137 */
const ConnectedSearchFilters = (connectHits(
	(connectStateResults(SearchFilters) as unknown) as React.FC<
		SearchFilterProps & AlgoliaHitsProvided
	>
) as unknown) as React.FC<SearchFilterProps>;
export { ConnectedSearchFilters as SearchFilters };
