import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

const allMarkets = {
	popular: [
		{
			marketType: MarketType.dota_2_winner
		},
		{
			marketType: MarketType.dota_2_total_maps,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.dota_2_match_odds
		},
		{
			marketType: MarketType.dota_2_map_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.dota_2_map_winner
		},
		{
			marketType: MarketType.dota_2_correct_score_in_maps,
			view: MarketView.CorrectScore
		}
	],
	maps: [
		{
			marketType: MarketType.dota_2_map_tower_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.dota_2_map_total_tower,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.dota_2_map_aegis_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.dota_2_map_total_aegis,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.dota_2_map_xth_aegis
		},
		{
			marketType: MarketType.dota_2_map_kill_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.dota_2_map_kill_draw_no_bet
		},
		{
			marketType: MarketType.dota_2_map_total_kills,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.dota_2_map_xth_kill
		},
		{
			marketType: MarketType.dota_2_map_race_to_xth_kills
		}
	],
	specials: [
		{
			marketType: MarketType.dota_2_map_xth_tower
		},
		{
			marketType: MarketType.dota_2_will_there_be_an_ultra_kill
		},
		{
			marketType: MarketType.dota_2_map_player_with_highest_creep_score
		},
		{
			marketType: MarketType.dota_2_map_race_to_level_xth
		},
		{
			marketType: MarketType.dota_2_map_race_to_xth_net_worth
		},
		{
			marketType: MarketType.dota_2_map_10_minutes_total_kills
		},
		{
			marketType: MarketType.dota_2_map_xth_barracks
		},
		{
			marketType: MarketType.dota_2_map_xth_shrine
		}
	]
};

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: allMarkets.popular
	},
	{
		label: 'markets.maps',
		markets: allMarkets.maps
	},
	{
		label: 'markets.specials',
		markets: allMarkets.specials
	},
	{
		label: 'descriptions.all',
		markets: [
			...allMarkets.popular,
			...allMarkets.maps,
			...allMarkets.specials
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.dota_2_winner
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.dota_2_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
