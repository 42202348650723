"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Provider = void 0;
var React = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var i18n_1 = require("./internal/i18n");
var phraseApp_1 = require("./phraseApp");
var i18n;
exports.Provider = function (props) {
    var config = {
        projectId: PHRASEAPP_ID,
        prefix: '[__',
        suffix: '__]',
        fullReparse: true,
        debugMode: true,
        autoLowercase: false
    };
    phraseApp_1.useInitPhraseAppConfig(config, !!PHRASEAPP_ID);
    if (!i18n) {
        i18n = i18n_1.createI18n(props.settings);
    }
    return React.createElement(react_i18next_1.I18nextProvider, { i18n: i18n }, props.children);
};
exports.default = exports.Provider;
