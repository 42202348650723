import { isMobile } from '@gaming-shell/device';
import { Translate } from '@gaming-shell/i18n';
import { Drawer } from '@material-ui/core';
import * as React from 'react';

import { betslipActions } from '../betslipReducer';
import { BigBetslipMultipleForm } from './BigBetslipAllStakesForm';
import { BigBetslipParlayPanelContent } from './BigBetslipParlayPanelContent';
import { BigBetslipActionPanelProps } from './types';

export const BigBetslipParlayActionPanel: React.FunctionComponent<
	BigBetslipActionPanelProps
> = (props) => {
	const {
		currentEditStakeView,
		currencyConfig,
		setCurrentEditStakeView,
		dispatch,
		onBetPlaced,
		isSubmitting
	} = props;
	const showPlaceBet = !currentEditStakeView || isMobile();
	const handleConfirmClick = (stake: number) => {
		dispatch(betslipActions.updateMultipleStake({ system: 0, stake }));
		setCurrentEditStakeView();
	};
	const form = (
		<BigBetslipMultipleForm
			currencyConfig={currencyConfig}
			onCloseEditStake={setCurrentEditStakeView}
			onConfirmClick={handleConfirmClick}
			isSubmitting={isSubmitting}
			title={<Translate label="betting.systems.parlay" ns="sports" />}
		/>
	);
	const showForm = !!(currentEditStakeView && currentEditStakeView.multiple);
	const wrappedForm = isMobile() ? (
		<Drawer open={!!showForm} anchor="bottom">
			{form}
		</Drawer>
	) : (
		showForm && form
	);
	return (
		<>
			{showPlaceBet && (
				<BigBetslipParlayPanelContent
					onBetPlaced={onBetPlaced}
					setCurrentEditStakeView={setCurrentEditStakeView}
				/>
			)}
			{wrappedForm}
		</>
	);
};
