import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: [
			{
				marketType: MarketType.bowls_winner
			},
			{
				marketType: MarketType.bowls_handicap,
				view: MarketView.Handicap
			},
			{
				marketType: MarketType.bowls_totals,
				view: MarketView.Goals
			},
			{
				marketType: MarketType.bowls_correct_score,
				view: MarketView.CorrectScore
			},
			{
				marketType: MarketType.bowls_set_1x2
			},
			{
				marketType: MarketType.bowls_set_handicap,
				view: MarketView.Handicap
			},
			{
				marketType: MarketType.bowls_set_total,
				view: MarketView.Goals
			},
			{
				marketType: MarketType.bowls_set_point
			},
			{
				marketType: MarketType.bowls_set_race_to_points
			},
			{
				marketType: MarketType.bowls_set_draw_no_bet
			},
			{
				marketType: MarketType.bowls_set_end_exact_points
			},
			{
				marketType: MarketType.bowls_set_and_end_winner
			},
			{
				marketType: MarketType.bowls_set_and_end_total,
				view: MarketView.Goals
			},
			{
				marketType: MarketType.bowls_set_end_odd_even
			}
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.bowls_winner
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.bowls_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
