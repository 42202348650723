"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SideMenuSelect = void 0;
var core_1 = require("@material-ui/core");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var useStyles = core_1.makeStyles(function (theme) { return ({
    secondaryTextColor: {
        color: theme.palette.text.secondary
    }
}); });
exports.SideMenuSelect = function (props) {
    var classes = useStyles();
    return (React.createElement(materialbet_common_1.Select, __assign({ fullWidth: true, className: classes.secondaryTextColor, classes: {
            icon: classes.secondaryTextColor
        }, variant: "outlined", color: "primary", input: React.createElement(core_1.OutlinedInput, { labelWidth: 0, notched: false }) }, props)));
};
