"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFreeze = void 0;
var React = __importStar(require("react"));
var usePrevious_1 = require("../usePrevious");
/** @descriptions Will return the given value until the freeze condition is true. Will return the given value from the moment the condition has changed from false to true. */
exports.useFreeze = function (currentValue, freezeCondition) {
    var frozenValue = React.useRef(currentValue);
    var previousCondition = usePrevious_1.usePrevious(freezeCondition);
    if (!freezeCondition) {
        return currentValue;
    }
    if (previousCondition !== freezeCondition) {
        frozenValue.current = currentValue;
    }
    return frozenValue.current;
};
