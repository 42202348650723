"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileSearch = void 0;
var core_1 = require("@material-ui/core");
var betterAppBar_1 = require("../../..");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var react_instantsearch_dom_1 = require("react-instantsearch-dom");
var shared_components_1 = require("shared-components");
var Filters_1 = require("../Filters");
var useStyles = core_1.makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        margin: theme.spacing(0, 0, 0.35, 1),
        justifyContent: 'center',
        alignItems: 'center'
    },
    filters: {
        padding: theme.spacing(1, 1, 0, 1)
    }
}); });
exports.MobileSearch = function () {
    var classes = useStyles();
    var _a = React.useState(false), showResults = _a[0], setShowResults = _a[1];
    var _b = materialbet_common_1.useToggle(), showInput = _b[0], toggleInputVisibility = _b[1];
    var _c = React.useState(), filters = _c[0], setFilters = _c[1];
    var sportsFilters = Filters_1.getActiveFilters(filters, Filters_1.AlgoliaFiltersTypes.Sports);
    var casinoFilters = Filters_1.getActiveFilters(filters, Filters_1.AlgoliaFiltersTypes.Casino);
    materialbet_common_1.useOnPathChange(function () { return setShowResults(false); });
    return (React.createElement("div", { className: classes.root },
        !showInput && React.createElement(betterAppBar_1.SearchButton, { onClick: toggleInputVisibility }),
        showInput && (React.createElement(betterAppBar_1.MobileSearchBox, { onClose: toggleInputVisibility, showResults: setShowResults })),
        React.createElement(betterAppBar_1.MobileSearchResults, { open: showResults },
            React.createElement(core_1.Divider, { variant: "fullWidth" }),
            React.createElement(react_instantsearch_dom_1.Index, { indexName: ALGOLIA_INDEXES.casino },
                React.createElement(Filters_1.Filters, { onFiltersChange: setFilters, className: classes.filters }),
                React.createElement(betterAppBar_1.SearchNoResultsMessage, { filters: filters })),
            React.createElement(react_instantsearch_dom_1.Index, { indexName: ALGOLIA_INDEXES.casino },
                React.createElement(shared_components_1.SharedComponent, { id: shared_components_1.SharedComponentIds.CasinoSearchResults, filters: filters }),
                React.createElement(shared_components_1.SharedComponent, { id: shared_components_1.SharedComponentIds.CasinoSearchConfigure, filters: casinoFilters })),
            React.createElement(react_instantsearch_dom_1.Index, { indexName: ALGOLIA_INDEXES.sports },
                React.createElement(shared_components_1.SharedComponent, { id: shared_components_1.SharedComponentIds.SportsSearchResults, filters: filters }),
                React.createElement(shared_components_1.SharedComponent, { id: shared_components_1.SharedComponentIds.SportsSearchConfigure, filters: sportsFilters })))));
};
