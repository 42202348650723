import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import {
	NotificationsBadgedIcon,
	withNotificationsBadgeButtonClick
} from 'materialbet-inbox';
import { IconButton, useOpenCloseMenu } from 'materialbet-common';
import { NotificationsMenu } from './NotificationsMenu';

const useStyles = makeStyles((theme) => ({
	iconButton: {
		margin: theme.spacing(0, 1)
	}
}));

const EnhancedIconButton = withNotificationsBadgeButtonClick(IconButton);

export const NotificationsButton: React.FC = () => {
	const classes = useStyles();
	const [handleOpenMenu, handleCloseMenu, anchorEl] = useOpenCloseMenu();

	return (
		<>
			<EnhancedIconButton
				className={classes.iconButton}
				size={0.8}
				color="inherit"
				onClick={handleOpenMenu}
				aria-label="Notifications button"
			>
				<NotificationsBadgedIcon />
			</EnhancedIconButton>
			<NotificationsMenu anchorEl={anchorEl} onClose={handleCloseMenu} />
		</>
	);
};
