import { useCallback, useState } from 'react';

export type UseToggleHook = [
	boolean,
	() => void,
	React.Dispatch<React.SetStateAction<boolean>>
];

export const useToggle = (initial: boolean = false): UseToggleHook => {
	const [value, setToggle] = useState(initial);
	const onToggle = useCallback(() => {
		setToggle((value) => !value);
	}, [setToggle]);

	return [value, onToggle, setToggle];
};
