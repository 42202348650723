import { useTranslation } from '@gaming-shell/i18n';
import { Chip, Theme, makeStyles } from '@material-ui/core';
import * as React from 'react';

export interface PromoStatusProps {
	label?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		position: 'absolute',
		left: theme.spacing(1),
		top: theme.spacing(1),
		borderRadius: theme.spacing(0.5)
	},
	label: theme.typography.body2
}));

export const PromoStatus: React.FunctionComponent<PromoStatusProps> = () => {
	const classes = useStyles();
	const trans = useTranslation();

	return (
		<Chip
			color="secondary"
			size="small"
			label={trans('common:descriptions.active')}
			clickable={false}
			classes={classes}
		/>
	);
};
