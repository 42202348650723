import { Box, Theme, Typography, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { BetCountRotatedIcon } from 'sports/components/icons/betslip/BetCountIcon';

interface BetCountProps {
	count: number;
}

const useStyles = makeStyles((theme: Theme) => ({
	icon: {
		fill: theme.palette.primary.contrastText,
		fontSize: '2rem'
	},
	count: {
		position: 'absolute',
		fontSize: '0.9rem'
	}
}));

export const BetCount: React.FunctionComponent<BetCountProps> = ({ count }) => {
	const classes = useStyles();
	return (
		<Box display="flex" justifyContent="center" alignItems="center">
			<BetCountRotatedIcon
				className={classes.icon}
				fontSize="default"
				viewBox="0 0 32 24"
			/>
			<Typography
				variant="subtitle1"
				color="primary"
				className={classes.count}
			>
				{count !== undefined && count}
			</Typography>
		</Box>
	);
};
