import { normalize, schema } from 'normalizr';
import { Action } from 'redux';
import {
	ActionsObservable,
	Epic,
	ofType,
	StateObservable
} from 'redux-observable';
import { from, of, queueScheduler, Subject } from 'rxjs';
import {
	catchError,
	groupBy,
	map,
	mergeMap,
	observeOn,
	switchAll,
	throttleTime
} from 'rxjs/operators';
import api from 'sports/api';
import config from 'sports/config/app.ts';
import { actions as commonActions } from 'sports/modules/common';
import * as schemas from 'sports/schema';
import { EpicDependencies } from 'sports/store';
import { getType } from 'typesafe-actions';

import { MetaPayload } from './meta';

interface CreateMetaParams<TArgs, TPromiseResult> {
	payload: TArgs;
	result?: TPromiseResult;
}
/**
 * Creates an epic that on dispatch of given @param actionCreator type executes the @param fetch und normalizes the result with the given @param resultSchema.
 * @returns an updateEntities action if or an raisError action if an error ocurred
 * @param actionCreator The action which should be handled.
 * @param fetch An function which returns a promise.
 * @param resultSchema The schema by which the result is to be normalized.
 * @param resultSelector Optional. Select a part of the result to be normalized.
 */

export const createFetchEntityEpic = <TArgs, TPromiseResult>(
	actionCreator: (payload: TArgs) => { type: string; payload: TArgs },
	fetch: (
		parameters: TArgs,
		dependencies: EpicDependencies
	) => Promise<TPromiseResult>,
	resultSchema: schema.Entity | schema.Entity[],
	resultSelector?: (result: TPromiseResult, parameters: TArgs) => unknown,
	createMeta?: (
		params: CreateMetaParams<TArgs, TPromiseResult>
	) => MetaPayload
) => {
	const newEpic: Epic<
		Action<string>,
		Action<string>,
		unknown,
		EpicDependencies
	> = (action$, state$, dependencies) =>
		action$.pipe(
			mergeMap((a) =>
				of(a).pipe(
					// we need to create a new observable here since catchError completes the observable if an error occurs
					ofType(getType(actionCreator)),
					groupBy(({ payload }: { type: string; payload: TArgs }) =>
						JSON.stringify(payload)
					),
					map((group) =>
						group.pipe(throttleTime(config.fetchEpicApiThrottle))
					), // prevents that the same api call gets executed multiple times within given throttle time
					switchAll(),
					mergeMap(({ payload }) =>
						from(
							fetch(payload, dependencies).catch((error) => {
								// eslint-disable-next-line no-throw-literal
								throw { response: error, payload };
							})
						).pipe(map((result) => ({ payload, result })))
					),
					map(({ result, payload }) => {
						const toNormalize = resultSelector
							? resultSelector(result, payload)
							: result;
						const entities = normalize(toNormalize, resultSchema)
							.entities as schemas.Entities;
						const meta = createMeta
							? createMeta({ payload, result })
							: undefined;
						return commonActions.updateEntities(entities, meta);
					}),
					catchError((error) => {
						const actions = [];
						if (error.response && error.payload) {
							// TODO Lets try to get additional typesafety in here as well
							const meta = createMeta
								? createMeta({ payload: error.payload })
								: undefined;
							actions.push(
								commonActions.updateEntities({}, meta)
							);
						}
						actions.push(
							commonActions.raiseError({
								error: error ? error.toString() : 'unknown',
								action: getType(actionCreator),
								message: 'Error in fetchEntity epic'
							})
						);
						return from(actions);
					})
				)
			)
		);
	return newEpic;
};

/**
 * testEpic is a simple test to compare the emited action against the expeected action for a given input action
 */
export const testEpic = (
	epic: Epic,
	expectedAction: Action,
	done: () => void,
	inputAction: Action,
	inputState?: object,
	dependencies?: object
) => {
	dependencies = dependencies || {
		api
	};
	const actionSubject$ = new Subject<Action<unknown>>().pipe(
		observeOn(queueScheduler)
	) as Subject<Action<unknown>>;
	const stateSubject$ = new Subject<{}>().pipe(
		observeOn(queueScheduler)
	) as Subject<Action<unknown>>;

	const action$ = new ActionsObservable(actionSubject$);
	const state$ = new StateObservable(stateSubject$, inputState);

	const output$ = epic(action$, state$, dependencies);

	const onError = jest.fn(() => {});
	const onNext = jest.fn();

	output$.subscribe(onNext, onError, () => {
		expect(onError).not.toBeCalled();
		if (expectedAction) {
			expect(onNext).toBeCalledWith(expectedAction);
		}
		done();
	});

	actionSubject$.next(inputAction);
	actionSubject$.complete();
};
