"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromoCollapsedContent = void 0;
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
var PromoCard_1 = require("../../PromoCard");
var useStyles = core_1.makeStyles(function (theme) { return ({
    divider: {
        marginBottom: theme.spacing(2)
    }
}); });
exports.PromoCollapsedContent = function (_a) {
    var title = _a.title, children = _a.children;
    var classes = useStyles();
    var expired = React.useContext(PromoCard_1.PromoCardContext).expired;
    return (React.createElement(core_1.Box, { paddingX: 2, marginBottom: 2, marginTop: expired && 1 },
        !expired && React.createElement(core_1.Divider, { className: classes.divider }),
        React.createElement(core_1.Typography, { variant: "subtitle1" }, title),
        React.createElement(core_1.Box, { maxHeight: 200, overflow: "auto" },
            React.createElement(core_1.Typography, { variant: "body2", color: "textSecondary" }, children))));
};
