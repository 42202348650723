"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsportsRoutes = void 0;
/** Creates a esports route */
var routing_1 = require("@gaming-shell/routing");
var sportsRouteUtils_1 = require("../utils/sportsRouteUtils");
var esportsRoute = routing_1.route(['esports']);
var competitionRoute = esportsRoute.add([
    routing_1.pathParam('sportKey'),
    routing_1.pathParam('competitionKey')
]);
var outrightRoute = esportsRoute.add([
    routing_1.pathParam('sportKey'),
    'outrights',
    routing_1.pathParam('competitionKey')
]);
var createCompetitionProxy = function (underlyingRoute) { return (__assign(__assign({}, underlyingRoute), { create: function (params) {
        return underlyingRoute.create(sportsRouteUtils_1.createCompetitionRouteParams(params));
    }, createRelative: function (params) {
        return underlyingRoute.createRelative(sportsRouteUtils_1.createCompetitionRouteParams(params));
    }, getPathParams: function (path) {
        return sportsRouteUtils_1.getCompetitionRouteParams(underlyingRoute.getPathParams(path));
    }, useParams: function () { return sportsRouteUtils_1.getCompetitionRouteParams(underlyingRoute.useParams()); } })); };
exports.EsportsRoutes = {
    root: esportsRoute,
    bets: esportsRoute.add(['bets']),
    live: esportsRoute.add(['live']),
    competition: createCompetitionProxy(competitionRoute),
    event: createCompetitionProxy(competitionRoute.add([routing_1.pathParam('eventId')])),
    eventWithKey: createCompetitionProxy(competitionRoute.add([routing_1.pathParam('eventId'), routing_1.pathParam('eventKey')])),
    outright: createCompetitionProxy(outrightRoute.add([routing_1.pathParam('eventId'), routing_1.pathParam('eventKey')])),
    sport: esportsRoute.add([routing_1.pathParam('sportKey')]),
    // Below will be use for tab selection on the single sport page
    sportCompetitions: esportsRoute.add([
        routing_1.pathParam('sportKey'),
        'competitions'
    ]),
    sportHighlights: esportsRoute.add([routing_1.pathParam('sportKey'), 'highlights']),
    sportToday: esportsRoute.add([routing_1.pathParam('sportKey'), 'today']),
    sportTomorrow: esportsRoute.add([routing_1.pathParam('sportKey'), 'tomorrow']),
    sportVirtuals: esportsRoute.add([routing_1.pathParam('sportKey'), 'virtuals']),
    sportInPlay: esportsRoute.add([routing_1.pathParam('sportKey'), 'inPlay']),
    sportOutrights: esportsRoute.add([routing_1.pathParam('sportKey'), 'outrights']),
    topCoupon: esportsRoute.add([
        routing_1.pathParam('sportKey'),
        'popular',
        routing_1.pathParam('couponKey')
    ]),
    virtualSports: esportsRoute.add(['virtuals'])
};
// For the pattern and typing
var singleEsportRoute = esportsRoute.add([routing_1.pathParam('sportKey'), '']);
