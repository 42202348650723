import { SideMenuItemList } from '@gaming-shell/layout';
import { CasinoSideMenuFooter } from 'casino/components/CasinoSideMenuFooter';
import { CasinoSideMenuMyCasino } from 'casino/components/CasinoSideMenuMyCasino';
import * as React from 'react';

import {
	LiveCasinoSideMenuSectionList,
	RngCasinoSideMenuSectionList
} from '../CasinoSideMenuSectionList';

export const CasinoSideMenu: React.FC = () => {
	return (
		<SideMenuItemList>
			<CasinoSideMenuMyCasino />
			<RngCasinoSideMenuSectionList />
			<LiveCasinoSideMenuSectionList />
			<CasinoSideMenuFooter />
		</SideMenuItemList>
	);
};
