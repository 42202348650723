"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CasinoRoutes = void 0;
var routing_1 = require("@gaming-shell/routing");
exports.CasinoRoutes = {
    root: routing_1.route(['casino']) /* also serves as rng lobby */,
    myCasino: routing_1.route(['casino', 'my']),
    recommendations: routing_1.route(['casino', 'recommendations']),
    liveCasino: routing_1.route(['casino', 'live']),
    /** @obsolete Use legacyGame and legacyFreePlayGame, this route has been deprecated in favor of using the same routing as on legacy */
    game: routing_1.route(['casino', 'game', routing_1.pathParam('gameId')]),
    legacyFreeplayGame: routing_1.route([
        'casino',
        'free',
        routing_1.pathParam('category'),
        routing_1.pathParam('gameKey'),
        routing_1.pathParam('gameId')
    ]),
    legacyGame: routing_1.route([
        'casino',
        routing_1.pathParam('category'),
        routing_1.pathParam('gameKey'),
        routing_1.pathParam('gameId')
    ]),
    rngGames: routing_1.route(['casino', routing_1.pathParam('gameType')]),
    rngStudioGames: routing_1.route([
        'casino',
        routing_1.pathParam('gameType'),
        'studio',
        routing_1.pathParam('studioKey')
    ]),
    liveGames: routing_1.route(['casino', 'live', routing_1.pathParam('gameType')]),
    liveStudioGames: routing_1.route([
        'casino',
        'live',
        routing_1.pathParam('gameType'),
        'studio',
        routing_1.pathParam('studioKey')
    ]),
    legacyLiveCasino: routing_1.route(['live_casino']),
    permanentLiveCasino: routing_1.route(['casino', 'live']),
    studioGames: routing_1.route(['casino', 'studios', routing_1.pathParam('studioKey')]),
    allProviders: routing_1.route(['casino', 'providers']),
    allThemes: routing_1.route(['casino', 'themes']),
    gamesWeLove: routing_1.route(['casino', 'games-we-love']),
    theme: routing_1.route(['casino', 'theme', routing_1.pathParam('themeKey')])
};
