import { makeStyles, Theme } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import { createDynamicStyle, makeDynamicStyles } from 'materialbet-common';
import * as React from 'react';
import { EventMetadata } from 'sports/api/sports';
import { EventScore } from 'sports/components/displays/EventScore';

import { EventListVariant } from '../eventList/types';
import { TeamName } from './TeamName';
import { DeepReadonly } from 'utility-types';

const useTeamInfoStyle = makeStyles((theme: Theme) => ({
	root: {
		justifyContent: 'space-between',
		flexWrap: 'nowrap',
		display: 'flex',
		flexShrink: 0
	},
	teamName: {
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},
	scores: {
		whiteSpace: 'nowrap',
		textAlign: 'right',
		color: theme.palette.secondary.light,
		flexShrink: 0
	}
}));

const useVariantStyles = makeDynamicStyles({
	teamInfo: createDynamicStyle(
		(theme) => ({
			flexWrap: 'nowrap',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			alignItems: 'center',
			flexGrow: 1,
			textOverflow: 'ellipsis',
			color: theme.palette.text.primary
		}),
		{
			wide: {
				display: 'flex'
			},
			narrow: {
				display: 'inline-block'
			}
		}
	)
});

// Display the team info in the event item
interface EventTeamInfoProps {
	team: string;
	playerNames?: string;
	sportKey: string;
	eventStatus?: EventMetadata['eventStatus'];
	score?: DeepReadonly<EventMetadata['score']>;
	setScores?: DeepReadonly<EventMetadata['setScores']>;
	away?: boolean;
	variant: EventListVariant;
	textVariant?: TypographyProps['variant'];
}

export const EventTeamInfo: React.FunctionComponent<EventTeamInfoProps> = ({
	team,
	playerNames,
	sportKey,
	eventStatus,
	score,
	setScores,
	away,
	variant,
	textVariant
}) => {
	const classes = useTeamInfoStyle();
	const variantClasses = useVariantStyles(variant);
	return (
		<div className={classes.root}>
			<TeamName
				textVariant={textVariant}
				team={team}
				player={playerNames}
				className={variantClasses.teamInfo}
			/>
			{variant === 'narrow' && eventStatus !== 'not_started' && (
				<div className={classes.scores}>
					<EventScore
						sportKey={sportKey}
						away={away}
						score={score}
						setScores={setScores}
						variant={variant}
						textVariant={textVariant}
					/>
				</div>
			)}
		</div>
	);
};
