"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapCategoryToTag = exports.mapTagsToCategory = exports.liveCasinoCategoriesMapper = exports.rngCasinoCategoriesMapper = exports.RngCasinoCategories = exports.LiveCasinoCategories = exports.CasinoCategories = void 0;
var tagsMap = {
    virtual: 'virtuals'
};
/** @deprecated  */
var CasinoCategories;
(function (CasinoCategories) {
    CasinoCategories["Baccarat"] = "baccarat";
    CasinoCategories["LiveBaccarat"] = "live_baccarat";
    CasinoCategories["BlackJack"] = "blackjack";
    CasinoCategories["LiveBlackJack"] = "live_blackjack";
    CasinoCategories["Featured"] = "featured";
    CasinoCategories["LiveFeatured"] = "live_featured";
    CasinoCategories["NearYou"] = "popular_near_you";
    CasinoCategories["JackpotSlots"] = "jackpot_slots";
    CasinoCategories["MyCasino"] = "my_casino";
    CasinoCategories["Other"] = "other";
    CasinoCategories["Roulette"] = "roulette";
    CasinoCategories["LiveCasinoRoulette"] = "live_roulette";
    CasinoCategories["Slots"] = "slots";
    CasinoCategories["TableGames"] = "tableGame";
    CasinoCategories["LiveCasinoTableGames"] = "live_table_games";
    CasinoCategories["Virtuals"] = "virtuals";
    CasinoCategories["Popular"] = "popular";
    CasinoCategories["VideoPoker"] = "video_poker";
    CasinoCategories["InstantWin"] = "instant_win";
    CasinoCategories["WildChristmas"] = "wild_christmas";
    CasinoCategories["Promotions"] = "promotions";
    CasinoCategories["Recommendations"] = "recommendations";
    CasinoCategories["Trending"] = "games_trending_right_now";
})(CasinoCategories = exports.CasinoCategories || (exports.CasinoCategories = {}));
/** @deprecated  */
var LiveCasinoCategories;
(function (LiveCasinoCategories) {
    LiveCasinoCategories["Featured"] = "featured";
    LiveCasinoCategories["Roulette"] = "roulette";
    LiveCasinoCategories["Baccarat"] = "baccarat";
    LiveCasinoCategories["Blackjack"] = "blackjack";
    LiveCasinoCategories["TableGames"] = "table_games";
    LiveCasinoCategories["Other"] = "other";
})(LiveCasinoCategories = exports.LiveCasinoCategories || (exports.LiveCasinoCategories = {}));
/** @deprecated  */
var RngCasinoCategories;
(function (RngCasinoCategories) {
    RngCasinoCategories["Featured"] = "featured";
    RngCasinoCategories["Slots"] = "slots";
    RngCasinoCategories["JackpotSlots"] = "jackpot_slots";
    RngCasinoCategories["Baccarat"] = "baccarat";
    RngCasinoCategories["Blackjack"] = "blackjack";
    RngCasinoCategories["Roulette"] = "roulette";
    RngCasinoCategories["TableGames"] = "table_games";
    RngCasinoCategories["Virtuals"] = "virtuals";
    RngCasinoCategories["All"] = "all";
})(RngCasinoCategories = exports.RngCasinoCategories || (exports.RngCasinoCategories = {}));
/* requires virtual not 'virtuals' */
var rngCasinoCategoriesMapping = (_a = {},
    _a[RngCasinoCategories.Virtuals] = 'virtual',
    _a[RngCasinoCategories.All] = undefined,
    _a);
/* requires virtual not 'virtuals' */
var liveCasinoCategoriesMapping = (_b = {},
    _b[LiveCasinoCategories.TableGames] = 'other_table_games',
    _b);
var casinoCategoryMapper = function (mapper) { return function (slug) {
    var mappedCategory = mapper.hasOwnProperty(slug) ? mapper[slug] : slug;
    return { label: slug, value: mappedCategory };
}; };
/** @deprecated  */
exports.rngCasinoCategoriesMapper = casinoCategoryMapper(rngCasinoCategoriesMapping);
/** @deprecated  */
exports.liveCasinoCategoriesMapper = casinoCategoryMapper(liveCasinoCategoriesMapping);
/** @deprecated  */
/* There's inconsistency between tags we receive hence the mapping */
exports.mapTagsToCategory = function (tags) { var _a; return (_a = tags === null || tags === void 0 ? void 0 : tags.map(function (tag) { return (tagsMap.hasOwnProperty(tag) ? tagsMap[tag] : tag); })) === null || _a === void 0 ? void 0 : _a.find(function (tag) { return Object.values(CasinoCategories).includes(tag); }); };
/** @deprecated  */
exports.mapCategoryToTag = function (category) {
    var _a;
    return ((_a = Object.entries(tagsMap).find(function (_a) {
        var value = _a[1];
        return value === category;
    })) === null || _a === void 0 ? void 0 : _a[0]) ||
        category;
};
