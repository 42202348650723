import camelCase from 'lodash/camelCase';
import { EventMetadata } from 'sports/api/sports';
import { SportType } from 'sports/config/sport';
import { DeepReadonly } from 'utility-types';

/** @description format set scores to single line string */
export const formatSetScores = (
	setScores: DeepReadonly<EventMetadata['setScores']>,
	away?: boolean,
	singleTeam?: boolean
) => {
	if (!setScores) {
		return '';
	}
	if (!singleTeam && setScores) {
		return setScores
			.map((scores) => scores && scores.score && scores.score.join('-'))
			.join(' ');
	}
	return setScores
		.map((scores) => scores && scores.score && scores.score[away ? 1 : 0])
		.join(' ');
};

/** @description get the access key to access sport specific data in the metadata, we can just read the V3 one, we dont use v1/v2 */
export const getMetadataSportKey = (sportkey: SportType) =>
	`${camelCase(sportkey)}V3`;
