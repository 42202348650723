import { Grid } from '@material-ui/core';
import * as React from 'react';
import { usePlayerCurrencyVariantConfig } from 'sports/utils/currency';
import { StakeTrailingInfo } from 'sports/utils/stake';

import { QuickBetslipVariant } from '../types';
import { QuickBetslipActionButtons } from './QuickBetslipActionButtons';
import { QuickBetslipStakeDialPad } from './QuickBetslipStakeDialPad';
import { StakePresetInputForm } from './StakePresetInputForm';

export interface QuickBetslipStakeControlProps {
	stake: number;
	trailing: string;
	setTrailing: (trail: StakeTrailingInfo) => void;
	disabled?: boolean;
	disableIncreaseStake?: boolean;
	minStake: number;
	maxStake: number;
	onMaxStakeClick?: () => void;
	onStakeChange: (value: number) => void;
	onStakeDelete: () => void;
	onConfirmClick: () => void;
	onSecondaryActionClick: () => void;
	showDialpad: boolean;
	variant: QuickBetslipVariant;
	isSubmitting: boolean;
	disableConfirmButton?: boolean;
	disableSecondaryButton?: boolean;
}

let QuickBetslipStakeControl: React.FunctionComponent<QuickBetslipStakeControlProps> = (
	props
) => {
	const {
		stake,
		trailing,
		setTrailing,
		disabled,
		disableIncreaseStake,
		minStake,
		maxStake,
		onMaxStakeClick,
		onStakeChange,
		onStakeDelete,
		showDialpad,
		onConfirmClick,
		onSecondaryActionClick,
		variant,
		isSubmitting,
		disableConfirmButton,
		disableSecondaryButton
	} = props;
	const currencyConfig = usePlayerCurrencyVariantConfig();
	const buttonValues = currencyConfig.increments
		.slice(0, 3)
		.map((i) => i.toString());
	buttonValues.push('max');
	const onDelete = () => {
		onStakeDelete();
	};
	const numberInputForm = showDialpad ? (
		<QuickBetslipStakeDialPad
			stake={stake}
			disabled={disableIncreaseStake || disabled}
			minStake={minStake}
			maxStake={maxStake}
			onChange={onStakeChange}
			trailing={trailing}
			setTrailing={setTrailing}
		/>
	) : (
		// ButtonValues hardcoded for now
		<StakePresetInputForm
			stake={stake}
			onChange={onStakeChange}
			buttonValues={buttonValues}
			onMaxStakeClick={onMaxStakeClick}
			maxStake={maxStake}
			disabled={disableIncreaseStake || disabled}
		/>
	);
	return (
		<Grid container>
			<Grid item xs={showDialpad ? 9 : 12}>
				{numberInputForm}
			</Grid>
			<Grid item xs={showDialpad ? 3 : 12}>
				<QuickBetslipActionButtons
					disabled={disabled}
					onDelete={onDelete}
					showDialpad={showDialpad}
					onConfirmClick={onConfirmClick}
					onSecondActionClick={onSecondaryActionClick}
					variant={variant}
					isSubmitting={isSubmitting}
					disableConfirmButton={disableConfirmButton}
					disableSecondaryButton={disableSecondaryButton}
				/>
			</Grid>
		</Grid>
	);
};
QuickBetslipStakeControl = React.memo(QuickBetslipStakeControl);

export { QuickBetslipStakeControl };
