import { Trans, useTranslation } from '@gaming-shell/i18n';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import clsx from 'clsx';
import { PlayerRoutes } from 'materialbet-common';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { BetslipStatusFlags } from 'sports/components/betterBetslip/betslipReducer/BetslipStatusFlags';
import {
	formatCurrency,
	useCurrency,
	usePlayerCurrencyVariantConfig
} from 'sports/utils/currency';
import { useFormatOdds } from 'sports/utils/odds';

import { getMinStake } from '../../../utils/currency';
import { BetslipErrorAction } from '../BetslipErrorAction';
import {
	betslipStatusTranslation,
	getSummaryTranslation
} from '../BetslipStatusTranslation';
import { BetslipReducerStatus, StakeLocation } from '../types';

interface BetslipErrorProps {
	/** @description expects a single error not a compound error  */
	error: BetslipReducerStatus;
	className?: string;
	summary?: boolean;
	stakeLocation?: StakeLocation;
	changedPrice?: number;
	minStake: number;
	maxStake: number;
}

const useBetslipErrorStyles = makeStyles((theme: Theme) => ({
	error: {
		color: theme.palette.error.main,
		padding: theme.spacing(0.5, 0)
	},
	icon: {
		marginRight: theme.spacing(1),
		fontSize: 16
	},
	text: {
		flexGrow: 1
	},
	link: {
		textDecoration: 'underline'
	}
}));

export const BetslipError: React.FunctionComponent<BetslipErrorProps> = ({
	error,
	children,
	className,
	summary,
	stakeLocation,
	changedPrice,
	minStake,
	maxStake
}) => {
	const classes = useBetslipErrorStyles();
	const t = useTranslation();
	const currency = useCurrency();
	const formatOdds = useFormatOdds();
	// TODO should be passed in
	const currencyConfig = usePlayerCurrencyVariantConfig();
	const errorMessage = summary
		? getSummaryTranslation(error)
		: betslipStatusTranslation[error] || 'betting.result.unknownError';
	const { variant, decimalPlaces } = currencyConfig;
	const maxDecimalPlaces = Math.min(decimalPlaces, BETSLIP_MAX_INPUT_DP);
	let formattedMinStake = formatCurrency(minStake, currency, {
		variant,
		maxDecimalPlaces
	});
	if (+formattedMinStake === 0) {
		formattedMinStake = formatCurrency(
			getMinStake(currency, variant, maxDecimalPlaces),
			currency,
			{ variant, maxDecimalPlaces }
		);
	}
	const formattedMaxStake = formatCurrency(maxStake, currency, {
		variant,
		maxDecimalPlaces
	});
	const formattedChangedPrice = formatOdds(changedPrice || 0);
	const label = 'sports:' + errorMessage;
	const message =
		error === BetslipStatusFlags.VerificationRequired ? (
			<Trans
				label={label}
				components={[
					<Link
						to={PlayerRoutes.sms.create()}
						key={0}
						className={clsx(classes.link, classes.error)}
					>
						text
					</Link>
				]}
			/>
		) : (
			t(label, {
				odds: formattedChangedPrice,
				minStake: formattedMinStake,
				maxStake: formattedMaxStake,
				minOddsValue: '1.90',
				hourIntervals: '72'
			})
		);
	return (
		<Box width="100%" className={className}>
			<Box display="flex" alignItems="end" className={classes.error}>
				<ReportProblemOutlinedIcon className={classes.icon} />
				<Typography variant="caption" className={classes.text}>
					{message}
				</Typography>
				<BetslipErrorAction
					error={error}
					stakeLocation={stakeLocation}
				/>
			</Box>
			{children}
		</Box>
	);
};
