import { history } from '@gaming-shell/routing';
import * as React from 'react';
import { useLocation } from 'react-router';

export interface ForcedRedirectProps {
	redirectCondition: boolean;
	allowedRoutes?: Array<string | RegExp>;
	to: string;
	state?: unknown;
	sideEffect?(): void;
}

const ForcedRedirect: React.FC<ForcedRedirectProps> = ({
	to,
	redirectCondition,
	allowedRoutes,
	state,
	sideEffect
}) => {
	const { pathname } = useLocation();
	const notAllowedRoute = allowedRoutes
		? !allowedRoutes.some((route) => new RegExp(route).test(pathname))
		: true;

	if (redirectCondition && notAllowedRoute && sideEffect) {
		sideEffect();
	}

	const shouldRedirect =
		redirectCondition && pathname !== to && notAllowedRoute;

	if (shouldRedirect) {
		history.replace(to, state);
	}

	return null;
};

export default ForcedRedirect;
