"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRefreshPlayerContext = exports.usePlayerIsLoading = exports.useFetchPlayerContext = exports.getMe = void 0;
var api_1 = require("@materialbet-core/api");
var player_1 = require("@materialbet-core/player");
var materialbet_common_1 = require("materialbet-common");
var parseWallet_1 = require("./parseWallet");
var i18n_1 = require("@gaming-shell/i18n");
var mapResponseToPlayer = function (response) {
    var authToken = response.authenticity_token, _a = response.cf_ipcountry, ipCountry = _a === void 0 ? '' : _a, channel = response.channel, currenciesAvailable = response.currencies_available, currency = response.currency, depositAddresses = response.deposit_addresses, email = response.email, forcedSmsVerification = response.forced_sms_verification, id = response.id, nickname = response.nickname, onboardingStatuses = response.onboarding_statuses, preferredOddsFormat = response.preferred_odds_format, restrictionTier = response.restriction_tier, bonusDetails = response.rollover_bonus, stakeFactor = response.stake_factor, timeZone = response.time_zone, uuid = response.uuid, locale = response.locale, wallets = response.wallets;
    return {
        authToken: authToken,
        ipCountry: ipCountry,
        locale: locale,
        channel: channel,
        currenciesAvailable: currenciesAvailable,
        currency: currency,
        depositAddresses: depositAddresses,
        email: email,
        forcedSmsVerification: forcedSmsVerification,
        id: id,
        nickname: nickname,
        onboardingStatuses: onboardingStatuses,
        preferredOddsFormat: preferredOddsFormat,
        restrictionTier: restrictionTier,
        eligibleFor: bonusDetails ? bonusDetails.models : [],
        stakeFactor: stakeFactor,
        timeZone: timeZone,
        uuid: uuid,
        wallets: parseWallet_1.parseWallets(wallets)
    };
};
exports.getMe = function (properties) {
    if (properties.length) {
        return api_1.api.getMeWithAttributesArray(properties);
    }
    else {
        return api_1.api.getMe();
    }
};
var playerContextProps = [
    'authenticity_token',
    'locale',
    'cf_ipcountry',
    'channel',
    'currencies_available',
    'currency',
    'deposit_addresses',
    'email',
    'forced_sms_verification',
    'id',
    'nickname',
    'onboarding_statuses',
    'preferred_odds_format',
    'restriction_tier',
    'rollover_bonus',
    'stake_factor',
    'time_zone',
    'uuid',
    'wallets'
];
exports.useFetchPlayerContext = function (contextProps) { return function () {
    return exports.getMe(contextProps || playerContextProps);
}; };
var forcedOnboardingingStatuses = function (onboardingStatuses) {
    if (materialbet_common_1.SPORTS_ONBOARDING_MODULE_ENABLED) {
        return {
            signUp: !(onboardingStatuses.sign_up === false),
            sports: !(onboardingStatuses.sports === false)
        };
    }
    return {};
};
var updateSignedInPlayerContext = function (response, updatePlayer) {
    var _a = mapResponseToPlayer(response.player), eligibleFor = _a.eligibleFor, forcedSmsVerification = _a.forcedSmsVerification, restrictionTier = _a.restrictionTier, onboardingStatuses = _a.onboardingStatuses, player = __rest(_a, ["eligibleFor", "forcedSmsVerification", "restrictionTier", "onboardingStatuses"]);
    updatePlayer(function (state) { return (__assign(__assign(__assign(__assign({}, state), { isLoggedIn: true }), player), { bonusDetails: __assign(__assign({}, state.bonusDetails), { eligibleFor: eligibleFor }), forcedRoutes: {
            sms: forcedSmsVerification,
            countryRestrictionTier: restrictionTier,
            onboardingStatuses: __assign(__assign({}, state.forcedRoutes.onboardingStatuses), forcedOnboardingingStatuses(onboardingStatuses))
        } })); });
};
var updateNotSignedInPlayerContext = function (response, updatePlayer) {
    var _a = response.cf_ipcountry, ipCountry = _a === void 0 ? '' : _a, restrictionTier = response.restriction_tier;
    updatePlayer(function (state) { return (__assign(__assign({}, state), { forcedRoutes: __assign(__assign({}, state.forcedRoutes), { countryRestrictionTier: restrictionTier }), ipCountry: ipCountry })); });
};
var playerContextUpdateStrategies = (_a = {},
    _a[api_1.Results.OK] = updateSignedInPlayerContext,
    _a[api_1.Results.NOT_SIGNED_IN] = updateNotSignedInPlayerContext,
    _a);
exports.usePlayerIsLoading = function () {
    var updatePlayer = player_1.useUpdatePlayer();
    return function (isLoading) {
        updatePlayer(function (player) { return (__assign(__assign({}, player), { isLoading: isLoading })); });
    };
};
exports.useRefreshPlayerContext = function () {
    var updatePlayer = player_1.useUpdatePlayer();
    var playerIsLoading = exports.usePlayerIsLoading();
    var fetchPlayerContext = exports.useFetchPlayerContext();
    var enqueueSnackbar = materialbet_common_1.useSnackbar().enqueueSnackbar;
    var trans = i18n_1.useTranslation();
    return function (response) {
        if (response) {
            updateSignedInPlayerContext(response, updatePlayer);
            return Promise.resolve(response);
        }
        else {
            playerIsLoading(true);
            return fetchPlayerContext()
                .then(function (response) {
                playerContextUpdateStrategies[api_1.Results[response.result]](response, updatePlayer);
                return response;
            })
                .catch(function () {
                enqueueSnackbar(trans('player:descriptions.failedRequest'));
            })
                .finally(function () {
                playerIsLoading(false);
            });
        }
    };
};
