interface CurrencyConfigSource extends CurrencyConfig {
	variants: Record<string, CurrencyConfigVariant>;
}
interface CurrencyConfigVariant {
	label: string;
	factor: number;
	increments: [number, number, number];
}

export interface CurrencyConfig {
	decimalPlaces: number; // for displaying and determining minimum bets,
	increments: [number, number, number]; // 6 steps
	defaultStake: number;
	label: string;
}

export interface CurrencyVariantConfig {
	decimalPlaces: number; // for displaying and determining minimum bets,
	increments: [number, number, number]; // 6 steps
	defaultStake: number;
	factor: number;
	label: string;
	variant: string;
	currency: string;
}
const usdVariant: CurrencyConfigVariant = {
	increments: [1, 2, 5],
	factor: 1,
	label: 'USD'
};
const getUsdEquivalent = (label: string): CurrencyConfigSource => ({
	label,
	decimalPlaces: 3,
	increments: [1, 2, 5],
	defaultStake: 10,
	variants: {
		USD: usdVariant
	}
});
const getGoldEquivalent = (label: string): CurrencyConfigSource => ({
	label,
	decimalPlaces: 8,
	increments: [1, 2, 5],
	defaultStake: 10,
	variants: {}
});
const config: { [currency: string]: CurrencyConfigSource | undefined } = {
	BTC: {
		label: 'BTC',
		decimalPlaces: 8,
		increments: [0.01, 0.02, 0.1],
		defaultStake: 0.001,
		variants: {
			mBTC: {
				increments: [0.1, 0.2, 1],
				factor: 1000,
				label: 'mBTC'
			},
			sat: {
				increments: [10, 20, 50],
				factor: 100000000,
				label: 'sat'
			}
		}
	},
	ETH: {
		label: 'ETH',
		decimalPlaces: 8,
		increments: [0.01, 0.02, 0.1],
		defaultStake: 0.001,
		variants: {
			mETH: {
				increments: [0.1, 0.2, 1],
				factor: 1000,
				label: 'mETH'
			}
		}
	},
	BCH: {
		label: 'BCH',
		decimalPlaces: 8,
		increments: [0.01, 0.02, 0.1],
		defaultStake: 0.001,
		variants: {
			mBCH: {
				increments: [0.1, 0.2, 1],
				factor: 1000,
				label: 'mBCH'
			}
		}
	},
	INR: {
		label: 'INR',
		decimalPlaces: 2,
		increments: [100, 200, 500],
		defaultStake: 100,
		variants: {}
	},
	MYR: {
		label: 'MYR',
		decimalPlaces: 2,
		increments: [1, 2, 5],
		defaultStake: 10,
		variants: {}
	},
	PTS: {
		label: 'PTS',
		decimalPlaces: 2,
		increments: [1, 2, 5],
		defaultStake: 10,
		variants: {}
	},
	USD: {
		label: 'USD',
		decimalPlaces: 2,
		increments: [1, 2, 5],
		defaultStake: 10,
		variants: {}
	},
	US2: getUsdEquivalent('USDT'),
	US3: getUsdEquivalent('USDC'),
	US4: getUsdEquivalent('TUSD'),
	US5: getUsdEquivalent('PAX'),
	US6: getUsdEquivalent('GUSD'),
	AU1: getGoldEquivalent('PAXG'),
	AU2: getGoldEquivalent('XAUT')
};
const defaultCurrencyConfig: CurrencyConfigSource = {
	label: '',
	decimalPlaces: 4,
	increments: [0.001, 0.005, 0.01],
	defaultStake: 0.1,
	variants: {}
}; // just in case we receive an unconfigured currency

export const getCurrencyConfig = (currency: string) => {
	return config[currency] || { ...defaultCurrencyConfig, label: currency };
};

export const getCurrencyVariantConfig = (
	currency: string,
	variant: string
): CurrencyVariantConfig => {
	const currencyConfig = getCurrencyConfig(currency);
	const { decimalPlaces, defaultStake } = currencyConfig;
	const { factor = 1, label = currencyConfig.label } =
		currencyConfig.variants[variant] || {};
	const variantExists = !!currencyConfig.variants[variant];
	const config = variantExists
		? currencyConfig.variants[variant]
		: currencyConfig;
	const increments: [number, number, number] = config.increments;
	return {
		decimalPlaces: decimalPlaces - Math.log10(factor),
		defaultStake: defaultStake * factor,
		increments,
		factor,
		label,
		currency,
		variant: variantExists ? variant : currency
	};
};
