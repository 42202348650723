export const authCode = '/auth-code';

export const authRedirectBaseParams = {
	/* eslint-disable @typescript-eslint/camelcase */
	response_type: 'code',
	/* eslint-disable @typescript-eslint/camelcase */
	client_id: 'auth-manager'
};

export const tokenCookieBaseParams = {
	/* eslint-disable @typescript-eslint/camelcase */
	grant_type: 'authorization_code',
	action: 'login'
};

//use to detect weather returning from auth action
export const signInParam = 'auth';
export const signUpParam = 'registrations';

//params added by keycloak to url
export const keyCloakParams = ['session_state', 'code'];
