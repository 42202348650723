import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from 'sports/modules/root';

export const sportsPlayerSelector = (state: RootState) => state.sportsPlayer;

export const getStakeFactor = createSelector(
	[sportsPlayerSelector],
	(player) => {
		return player.stakeFactor || 1;
	}
);

export const useStakeFactor = () => {
	const stakeFactor = useSelector((state: RootState) =>
		getStakeFactor(state)
	);
	return stakeFactor;
};
