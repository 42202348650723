/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
	Category,
	CategoryFromJSON,
	CategoryFromJSONTyped,
	CategoryToJSON
} from './';

/**
 * SportWithCategoryResponse presents all competitions grouped by categories
 * @export
 * @interface SportWithCategoryResponse
 */
export interface SportWithCategoryResponse {
	/**
	 *
	 * @type {boolean}
	 * @memberof SportWithCategoryResponse
	 */
	blank?: boolean;
	/**
	 *
	 * @type {Array<Category>}
	 * @memberof SportWithCategoryResponse
	 */
	categories?: Array<Category>;
	/**
	 *
	 * @type {number}
	 * @memberof SportWithCategoryResponse
	 */
	competitionCount?: number;
	/**
	 *
	 * @type {number}
	 * @memberof SportWithCategoryResponse
	 */
	eventCount?: number;
	/**
	 *
	 * @type {string}
	 * @memberof SportWithCategoryResponse
	 */
	key?: string;
	/**
	 *
	 * @type {string}
	 * @memberof SportWithCategoryResponse
	 */
	name?: string;
	/**
	 *
	 * @type {number}
	 * @memberof SportWithCategoryResponse
	 */
	outrightCount?: number;
}

export function SportWithCategoryResponseFromJSON(
	json: any
): SportWithCategoryResponse {
	return SportWithCategoryResponseFromJSONTyped(json, false);
}

export function SportWithCategoryResponseFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): SportWithCategoryResponse {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		blank: !exists(json, 'blank') ? undefined : json['blank'],
		categories: !exists(json, 'categories')
			? undefined
			: (json['categories'] as Array<any>).map(CategoryFromJSON),
		competitionCount: !exists(json, 'competitionCount')
			? undefined
			: json['competitionCount'],
		eventCount: !exists(json, 'eventCount')
			? undefined
			: json['eventCount'],
		key: !exists(json, 'key') ? undefined : json['key'],
		name: !exists(json, 'name') ? undefined : json['name'],
		outrightCount: !exists(json, 'outrightCount')
			? undefined
			: json['outrightCount']
	};
}

export function SportWithCategoryResponseToJSON(
	value?: SportWithCategoryResponse | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		blank: value.blank,
		categories:
			value.categories === undefined
				? undefined
				: (value.categories as Array<any>).map(CategoryToJSON),
		competitionCount: value.competitionCount,
		eventCount: value.eventCount,
		key: value.key,
		name: value.name,
		outrightCount: value.outrightCount
	};
}
