import { baseOptions } from 'auth/config/api';
import { getCookieRequestBody } from 'auth/utils/url';

interface RequestInitWithExtraConfig extends RequestInit {
	removeCSRF: boolean;
}

export const fetchTokenCookie = async (
	code: string,
	authReturnKey: string
): Promise<Response> => {
	const options: RequestInitWithExtraConfig = {
		...baseOptions,
		body: getCookieRequestBody(code, authReturnKey),
		removeCSRF: true
	};
	return await fetch(AUTH_TOKEN_URL, options);
};

export const checkSignedIn = async (): Promise<Response> => {
	const options: RequestInitWithExtraConfig = {
		...baseOptions,
		removeCSRF: true
	};
	return await fetch(SIGNED_IN_CHECK_URL, options);
};

export const performSignOut = async (): Promise<Response> => {
	const options: RequestInitWithExtraConfig = {
		...baseOptions,
		removeCSRF: true,
		body: `action=logout`
	};
	return await fetch(AUTH_TOKEN_URL, options);
};
