import * as React from 'react';
import { jumioApiV1, GetVerificationDetailsResponse200 } from 'core/api/jumio';
import { useUpdatePlayer } from '@materialbet-core/player';
import { Results } from '@materialbet-core/api';

export const useFetchKycDetails = () => {
	const [loading, setLoading] = React.useState(false);
	const updatePlayer = useUpdatePlayer();

	const fetchKycDetails = React.useCallback(() => {
		setLoading(true);
		jumioApiV1
			.getVerificationDetails()
			.then((response) => {
				const { result, ...restResponse } = response;
				if (response.result === Results.OK) {
					const {
						redirect_url: url,
						...rest
					} = restResponse as GetVerificationDetailsResponse200;
					updatePlayer((player) => ({
						...player,
						kyc: {
							details: {
								url,
								...rest
							},
							loaded: true
						}
					}));
				}
			})
			.catch((error) => {
				error.then(() => {
					updatePlayer((player) => ({
						...player,
						kyc: {
							...player.kyc,
							loaded: true
						}
					}));
				});
			})
			.finally(() => setLoading(false));
	}, [setLoading, updatePlayer]);

	return {
		fetchKycDetails,
		loading
	};
};
