import { CurrencyType } from '../types';

export interface CurrencyVariant {
	decimalPlaces: number;
	factor: number;
	label: string;
}

export interface CurrencyConfig {
	label: string;
	fullName: string;
	type: CurrencyType;
	defaultStake: number;
	variants: {
		[key: string]: CurrencyVariant;
	};
	defaultVariant: string;
}
const getUsdEquivalent = (label: string, fullName: string) => ({
	label,
	fullName,
	type: CurrencyType.CRYPTO,
	defaultStake: 10,
	variants: {
		[label]: {
			decimalPlaces: 2,
			factor: 1,
			label
		}
	},
	defaultVariant: label
});
const getGoldEquivalent = (label: string, fullName: string) => ({
	label,
	fullName,
	type: CurrencyType.CRYPTO,
	defaultStake: 10,
	variants: {
		[label]: {
			decimalPlaces: 4,
			factor: 1,
			label
		},
		[`m${label}`]: {
			decimalPlaces: 2,
			factor: 1000,
			label: `m${label}`
		}
	},
	defaultVariant: label
});
export const config: { [currency: string]: CurrencyConfig } = {
	BTC: {
		label: 'BTC',
		fullName: 'Bitcoin',
		type: CurrencyType.CRYPTO,
		defaultStake: 0.001,
		variants: {
			BTC: {
				decimalPlaces: 8,
				factor: 1,
				label: 'BTC'
			},
			mBTC: {
				decimalPlaces: 5,
				factor: 1000,
				label: 'mBTC'
			},
			sat: {
				decimalPlaces: 0,
				factor: 100000000,
				label: 'sat'
			}
		},
		defaultVariant: 'BTC'
	},
	BCH: {
		label: 'BCH',
		fullName: 'Bitcoin Cash',
		type: CurrencyType.CRYPTO,
		defaultStake: 0.001,
		variants: {
			BCH: {
				decimalPlaces: 8,
				factor: 1,
				label: 'BCH'
			},
			mBCH: {
				decimalPlaces: 2,
				factor: 1000,
				label: 'mBCH'
			}
		},
		defaultVariant: 'BCH'
	},
	ETH: {
		label: 'ETH',
		fullName: 'Ethereum',
		type: CurrencyType.CRYPTO,
		defaultStake: 0.001,
		variants: {
			ETH: {
				decimalPlaces: 8,
				factor: 1,
				label: 'ETH'
			},
			mETH: {
				decimalPlaces: 5,
				factor: 1000,
				label: 'mETH'
			}
		},
		defaultVariant: 'ETH'
	},
	INR: {
		label: 'INR',
		fullName: 'Indian Rupee',
		type: CurrencyType.FIAT,
		defaultStake: 100,
		variants: {
			INR: {
				decimalPlaces: 2,
				factor: 1,
				label: 'INR'
			}
		},
		defaultVariant: 'INR'
	},
	MYR: {
		label: 'MYR',
		fullName: 'Malaysian Ringgit',
		type: CurrencyType.FIAT,
		defaultStake: 10,
		variants: {
			MYR: {
				decimalPlaces: 2,
				factor: 1,
				label: 'MYR'
			}
		},
		defaultVariant: 'MYR'
	},
	PTS: {
		label: 'PTS',
		fullName: 'PTS',
		type: CurrencyType.FIAT,
		defaultStake: 10,
		variants: {
			PTS: {
				decimalPlaces: 2,
				factor: 1,
				label: 'PTS'
			}
		},
		defaultVariant: 'PTS'
	},
	USD: {
		label: 'USD',
		fullName: 'American Dollar',
		type: CurrencyType.FIAT,
		defaultStake: 10,
		variants: {
			USD: {
				decimalPlaces: 2,
				factor: 1,
				label: 'USD'
			}
		},
		defaultVariant: 'USD'
	},
	EUR: {
		label: 'EUR',
		fullName: 'Euro',
		type: CurrencyType.FIAT,
		defaultStake: 10,
		variants: {
			EUR: {
				decimalPlaces: 2,
				factor: 1,
				label: 'EUR'
			}
		},
		defaultVariant: 'EUR'
	},
	JPY: {
		label: 'JPY',
		fullName: 'Japanese Yen',
		type: CurrencyType.FIAT,
		defaultStake: 10,
		variants: {
			JPY: {
				decimalPlaces: 2,
				factor: 1,
				label: 'JPY'
			}
		},
		defaultVariant: 'JPY'
	},
	BRL: {
		label: 'BRL',
		fullName: 'Brazilian Real',
		type: CurrencyType.FIAT,
		defaultStake: 10,
		variants: {
			BRL: {
				decimalPlaces: 2,
				factor: 1,
				label: 'BRL'
			}
		},
		defaultVariant: 'BRL'
	},
	US2: getUsdEquivalent('USDT', 'Tether'),
	US3: getUsdEquivalent('USDC', 'USD Coin'),
	US4: getUsdEquivalent('TUSD', 'TrueUSD'),
	US5: getUsdEquivalent('PAX', 'PAX'),
	US6: getUsdEquivalent('GUSD', 'Gemini Dollar'),
	AU1: getGoldEquivalent('PAXG', 'PAX Gold'),
	AU2: getGoldEquivalent('XAUT', 'Tether Gold')
};

const getDefaultCurrencyVariant = (currency: string): CurrencyVariant => ({
	decimalPlaces: 8,
	factor: 1,
	label: currency
});

export const getCurrencyConfig = (
	currency: string
): CurrencyConfig | undefined => config[currency];

export const getCurrencyVariantConfig = (
	currency: string,
	variant?: string
): CurrencyVariant => {
	const { defaultVariant, variants } = getCurrencyConfig(currency) || {};

	const currencyVariant: CurrencyVariant =
		(variants &&
			(variant || defaultVariant) &&
			((variant && variants[variant]) ||
				(defaultVariant && variants[defaultVariant]))) ||
		getDefaultCurrencyVariant(currency);

	return currencyVariant;
};
