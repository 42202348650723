/** @file selectors for single fields in the competition entity */
import { RootState } from 'sports/modules/root';

const competitionsSelector = (state: RootState) => state.competitions;

export const getCompetition = (state: RootState, id?: string) =>
	id === undefined ? undefined : competitionsSelector(state)[id];
const forCompetition = <T>(
	selector: (event: ReturnType<typeof getCompetition> | undefined) => T
) => (id?: string) => (state: RootState) => selector(getCompetition(state, id));

export const getCompetitionBlank = forCompetition((c) => c?.blank);
export const getCompetitionName = forCompetition((c) => c?.name);
export const getCompetitionCategory = forCompetition((c) => c?.category);
export const getCompetitionSportKey = forCompetition((c) => c?.sport);
export const getCompetitionEvents = forCompetition((c) => c?.events);
export const getCompetitionOutrights = forCompetition((c) => c?.outrights);
export const getCompetitionStreamUrl = (streamIndex: number) =>
	forCompetition((c) => c?.streams?.[streamIndex]?.url);
export const getCompetitionStreamProvider = (streamIndex: number) =>
	forCompetition((c) => c?.streams?.[streamIndex]?.provider);
export const getCompetitionTags = forCompetition((c) => c?.tags);
