/** Creates a promote route */
import { pathParam, route } from '@gaming-shell/routing';

const promotionsRoute = route(['promotions']); // we cant use promotions as it is targeted to acquisition page
export const PromotionsRoutes = {
	root: promotionsRoute,
	promotion: promotionsRoute.add([
		pathParam('categoryKey'),
		pathParam('promotionKey')
	])
};
