import { Dispatch } from 'react';
import { actions as commonActions } from 'sports/modules/common';

import { Middleware } from '../sports';

const ipCountryHeader = 'x-cf-ipcountry';
export const createClearStoreOnCountryChange = (
	dispatch: Dispatch<unknown>
) => {
	let currentCountry: string | null = null;
	((window as unknown) as { setCountry: (s: string) => void }).setCountry = (
		c: string
	) => (currentCountry = c);
	const middleware: Middleware['post'] = async (context) => {
		const country = context.response.headers.get(ipCountryHeader);
		if (country && currentCountry && currentCountry !== country) {
			dispatch(commonActions.updateIpCountry(country));
		}
		currentCountry = country;
		return context.response;
	};
	return middleware;
};
