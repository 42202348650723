import { actions as commonActions } from 'sports/modules/common';
import { getType } from 'typesafe-actions';

export interface StartLoadingMeta {
	initiatedAt: number;
	name: string;
}
export interface FinishLoadingMeta {
	receivedAt: number;
	name: string;
}
export type LoadingMeta = Array<StartLoadingMeta | FinishLoadingMeta>;
export const LoadingMetaKey = 'loading';
export interface Loading {
	initiatedAt?: number;
	receivedAt?: number;
	isLoading: boolean;
}
export interface LoadingState {
	[name: string]: Loading;
}
export default (
	state: LoadingState = {},
	action: { meta?: object; type: string }
) => {
	if (
		action.meta &&
		action.meta &&
		Array.isArray(action.meta[LoadingMetaKey])
	) {
		const newLoads: LoadingMeta = action.meta[LoadingMetaKey];
		const newLoading = newLoads.reduce((loading, load) => {
			const { name, ...newLoad } = load;
			if (!name) {
				return loading;
			}
			const isLoading = !!(newLoad as StartLoadingMeta).initiatedAt;
			loading[load.name] = { ...state[name], ...newLoad, isLoading };
			return loading;
		}, {} as LoadingState);
		return { ...state, ...newLoading };
	}
	if (action.type === getType(commonActions.clear)) {
		return {};
	}
	return state;
};
