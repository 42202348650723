import { useLanguage } from '@gaming-shell/i18n';
import { zendesk, isZendeskAvailable } from '@materialbet/zendesk';
import * as React from 'react';

export interface ZendeskWidgetRenderFunction {
	openWidget: () => void;
}

export interface ZendeskWidgetProps {
	apiKey: string;
	children: (props: ZendeskWidgetRenderFunction) => JSX.Element;
	onOpen?: () => void;
	userName?: string | null;
	userEmail?: string | null;
	reset?: boolean;
}

export const ZendeskWidget: React.FC<ZendeskWidgetProps> = ({
	apiKey,
	onOpen,
	userName,
	userEmail,
	reset,
	children
}) => {
	const language = useLanguage();
	const [ready, setReady] = React.useState(false);

	// Make sure zendesk is ready
	React.useEffect(() => {
		// check every second for 20 seconds if zendesk is available
		if (!isZendeskAvailable() && !ready) {
			let checksCounter = 0;
			const handler = setInterval(() => {
				if (isZendeskAvailable() || checksCounter >= 20) {
					setReady(true);
					clearInterval(handler);
				}
				checksCounter++;
			}, 1000);

			return () => {
				clearInterval(handler);
			};
		}

		setReady(true);
		return;
	}, [setReady]);

	/* locale change handler */
	React.useEffect(() => {
		zendesk('webWidget', 'setLocale', language);
	}, [ready, language]);

	/* prefill handler */
	React.useEffect(() => {
		const prefillData = {
			name: {
				value: reset ? '' : userName
			},
			email: {
				value: reset ? '' : userEmail
			}
		};

		zendesk('webWidget', 'prefill', prefillData);
	}, [ready, reset, userName, userEmail]);

	// listen for widget close/new window popup action
	React.useEffect(() => {
		/* this hides zendesk button visible on the very bottom right of page */
		const hideZendesk = () => {
			zendesk('webWidget', 'hide');
		};
		zendesk('webWidget:on', 'chat:popout', hideZendesk);
		zendesk('webWidget:on', 'close', hideZendesk);
	}, [ready]);

	const openWidget = React.useCallback(() => {
		zendesk('webWidget', 'open');
		zendesk('webWidget', 'show');
		onOpen && onOpen();
	}, [ready, onOpen]);

	if (!apiKey || !ready) {
		return null;
	}

	return children({ openWidget });
};
