"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseRoutes = void 0;
var routing_1 = require("@gaming-shell/routing");
exports.BaseRoutes = {
    root: routing_1.route([]),
    license: routing_1.route(['about-us', 'license']),
    privacyPolicy: routing_1.route(['security', 'privacy-policy']),
    termsAndConditions: routing_1.route(['help', 'terms']),
    rules: routing_1.route(['help', 'rules']),
    providerTransactionSuccess: routing_1.route(['fiat_transaction_provider', 'success']),
    providerTransactionCanceled: routing_1.route(['fiat_transaction_provider', 'cancel'])
};
