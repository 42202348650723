"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResetMfaLayout = void 0;
var device_1 = require("@gaming-shell/device");
var styles_1 = require("@material-ui/core/styles");
var __1 = require("../..");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var styles = function (theme) {
    return styles_1.createStyles({
        input: {
            '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
                WebkitBoxShadow: "0 0 0 100px " + (device_1.isDesktop()
                    ? theme.palette.background.default
                    : theme.palette.background.paper) + " inset !important"
            }
        },
        submitButtonWrapper: {
            margin: theme.spacing(4, 0, 2)
        }
    });
};
var ResetMfaLayout = function (props) {
    var _a = React.useState(true), disabled = _a[0], setDisabled = _a[1];
    var _b = React.useState({
        login: { value: '', error: '' },
        code: { value: '', error: '' }
    }), inputValues = _b[0], setInputValues = _b[1];
    var updateValue = function (prop) { return function (value) {
        setInputValues(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[prop] = {
                error: '',
                value: value
            }, _a)));
        });
    }; };
    React.useEffect(function () {
        setDisabled(__1.isFormDisabled(inputValues));
    }, [setDisabled, inputValues]);
    var onSubmit = function (event) {
        event.preventDefault();
        var validationValues = [
            {
                key: 'login',
                value: inputValues.login.value,
                validator: 'login'
            },
            {
                key: 'code',
                value: inputValues.code.value,
                validator: 'code'
            }
        ];
        var valuesWithError = __1.validateForm(validationValues, props.validations);
        if (Object.keys(valuesWithError).length === 0) {
            props.onSubmitClick(inputValues.login.value, inputValues.code.value);
        }
        else {
            setInputValues(function (prevState) { return (__assign(__assign({}, prevState), valuesWithError)); });
        }
    };
    return (React.createElement("form", { onSubmit: onSubmit, noValidate: true },
        React.createElement(materialbet_common_1.TextField, { id: "login", label: props.loginLabel, type: "email", value: inputValues.login.value, onChange: updateValue('login'), variant: "outlined", fullWidth: true, margin: "normal", helperText: inputValues.login.error, error: !!inputValues.login.error, InputProps: {
                classes: {
                    input: props.classes.input
                }
            } }),
        React.createElement(materialbet_common_1.AuthCodeField, { id: "code", value: inputValues.code.value, onChange: updateValue('code'), label: props.codeLabel, variant: "outlined", fullWidth: true, margin: "normal", helperText: inputValues.code.error, error: !!inputValues.code.error }),
        React.createElement(materialbet_common_1.ProgressButton, { submitting: props.isSubmitting, ProgressWrapperProps: {
                className: props.classes.submitButtonWrapper
            }, variant: "contained", color: "primary", type: "submit", fullWidth: true, disabled: disabled }, props.submitButtonText)));
};
var StyledResetMfaLayout = styles_1.withStyles(styles)(ResetMfaLayout);
exports.ResetMfaLayout = StyledResetMfaLayout;
