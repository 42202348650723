import { Platform } from '@gaming-shell/device';
import { GetSectionsResponse } from 'casino/api/casino';
import { SectionEntity, sections } from 'casino/casinoSchemas';
import { createCasinoFetchEntityEpic } from 'casino/util/epicsUtil';
import { createSectionsMeta } from 'casino/util/metaUtils';
import { createEntityReducer } from 'casino/util/reducerUtils';
import { gameRowFilter } from 'casino/util/sections';
import { finishLoading } from 'materialbet-common';
import { createAction } from 'typesafe-actions';

export default createEntityReducer<SectionEntity>(sections.key);
export interface SectionsParams {
	playerUuid: string;
	signedIn: boolean;
	locale: string;
	platform: Platform;
	live: boolean;
	currency: string;
}

export const getSectionsKey = (params: SectionsParams) =>
	`${params.playerUuid}_${params.currency}_${params.platform}_${params.locale}_${params.signedIn}_${params.live}`;
export const loads = {
	sections: (params: SectionsParams) =>
		`sections/sections_${getSectionsKey(params)}`
};

export const actions = {
	fetchSections: createAction(
		'sections/fetchSections',
		(resolve) => (payload: SectionsParams) => resolve(payload)
	)
};
export const getSectionsEpic = createCasinoFetchEntityEpic<
	SectionsParams,
	GetSectionsResponse
>({
	actionCreator: actions.fetchSections,
	fetch: (payload, { api }) => {
		const promise = api.default.v1PlayersPlayerUuidContentSectionsGet(
			payload.playerUuid,
			payload.signedIn,
			payload.locale,
			payload.platform,
			payload.currency,
			undefined,
			undefined,
			undefined,
			payload.live,
			BRAND_NAME as 'materialbet'
		);
		return promise;
	},
	resultSelector: (result) => result.sections || [],
	resultSchema: [sections],
	createMeta: ({ payload, result }) => {
		const sectionsKey = getSectionsKey(payload);
		const loading = finishLoading(loads.sections(payload));
		/* filter out all not gameRows sections */
		if (result?.sections) {
			result.sections = gameRowFilter(result.sections);
		}
		const casino = createSectionsMeta(result, sectionsKey);
		return { ...loading, ...casino };
	}
});
// no need to combine here as we only have one epic at the time
export const sectionsEpic = getSectionsEpic;
