import { makeStyles } from '@material-ui/core/styles';
import { MutableRefObject } from 'react';
import * as React from 'react';

import { defaultIframeRatio } from '../../configs/ratio';
import {
	getAvailableDimensions,
	useElementDimensionsResize,
	useWindowDimensionsResize
} from '../../utils/dimensionsUtils';
import {
	getIframeDimensions,
	getIframeWidgetDimensions
} from '../../utils/iframeUtils';

// Because of classes creation order, we will need to duplicate one here
const useIframeWidgetDimensionsStyles = makeStyles({
	root: ({ height, width }: { height: string; width: string }) => ({
		width,
		height
	})
});

interface IframeWidgetParams {
	ref: MutableRefObject<HTMLDivElement | null>;
	iframeWrapperRef?: MutableRefObject<
		HTMLIFrameElement | HTMLDivElement | null
	>;
	ratio?: string;
	fullscreen?: boolean;
	cinema?: boolean;
	showSidemenu?: boolean;
}

const getIframeWidgetElements = ({ ref }: Pick<IframeWidgetParams, 'ref'>) => {
	// Define
	const widget = ref?.current;
	const parent = widget?.parentElement;
	const header = widget?.getElementsByTagName('header')?.[0];
	const actionBar = widget?.getElementsByTagName('footer')?.[0];
	return {
		parent,
		widget,
		header,
		actionBar
	};
};

export const useIframeWidgetStyles = ({
	ref, // The widget ref
	ratio = defaultIframeRatio,
	fullscreen,
	cinema,
	showSidemenu
}: IframeWidgetParams) => {
	// Get Elements
	const { parent, widget, header, actionBar } = getIframeWidgetElements({
		ref
	});
	// Window
	const {
		innerHeight: windowHeight = 0,
		innerWidth: windowWidth = 0
	} = window;
	const { width: parentWidth } = useElementDimensionsResize(parent);
	const headerHeight = header?.clientHeight || 0;
	const actionBarHeight = actionBar?.clientHeight || 0;
	const widgetTop = widget?.offsetTop || 0;
	const positionTop = cinema ? 0 : widgetTop;
	useWindowDimensionsResize();
	// Its being used to trigger the rerender and calclation when the mode its switch because of reference of components
	const [, triggerChangeMode] = React.useState('normal');
	// Show info will push the area to the left, the iframe wrapper need to be re calculated
	const [, setShowSidemnu] = React.useState(false);
	// Hooks For setting cinema
	React.useEffect(() => {
		triggerChangeMode(
			fullscreen ? 'fullscreen' : cinema ? 'cinema' : 'normal'
		);
		setShowSidemnu(!!showSidemenu);
		// Switch the Cinema Mode, the widget should cover the screen.
		// It should avoid the screen from scrolling on the main body.
		document.body.style.overflow = cinema ? 'hidden' : 'auto';
		return () => {
			document.body.style.removeProperty('overflow');
		};
	}, [cinema, showSidemenu, fullscreen]);

	const { height, width } = React.useMemo(() => {
		// Getting the available space we have for the area to place the game
		const {
			height: availableHeight,
			width: availableWidth
		} = getAvailableDimensions({
			windowHeight,
			windowWidth,
			parentWidth,
			headerHeight,
			actionBarHeight,
			positionTop,
			fullscreen,
			cinema,
			showSidemenu,
			ratio
		});
		// Getting the dimensions of game based on the spaces and ratio
		const { width: iframeWidth } = getIframeDimensions({
			availableHeight,
			availableWidth,
			ratio
		});
		// Calculated the size of widget, widget shouldnt have wider width of the given game.
		const { height, width } = getIframeWidgetDimensions({
			iframeWidth,
			headerHeight,
			availableHeight,
			actionBarHeight,
			cinema
		});
		return {
			height,
			width
		};
	}, [
		positionTop,
		windowHeight,
		windowWidth,
		headerHeight,
		actionBarHeight,
		fullscreen,
		cinema,
		showSidemenu,
		ratio,
		parentWidth
	]);
	// Create Classes
	return useIframeWidgetDimensionsStyles({
		height,
		width
	});
};
