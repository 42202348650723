import * as React from 'react';
import {
	usePlayerIpCountry,
	usePlayerCurrency
} from '@materialbet-core/player';
import { Configure } from 'react-instantsearch-dom';
import { useLanguage } from '@gaming-shell/i18n';
import { SearchConfigProps } from 'casino/types/sharedComponents';

export const SearchConfig: React.FC<SearchConfigProps> = ({ filters }) => {
	const ipCountry = usePlayerIpCountry();
	const language = useLanguage();
	const currency = usePlayerCurrency();
	const filterQuery = filters?.length
		? `AND (${filters?.map((filter) => filter.value).join(' OR ')})`
		: '';

	return (
		<Configure
			filters={`desktop_enabled AND NOT is_live AND ${language} AND ${currency} ${filterQuery}`}
			facetFilters={`country_blacklist: -${ipCountry}`}
			hitsPerPage={SEARCH_CASINO_GAMES_PER_PAGE}
		/>
	);
};
