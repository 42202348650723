// does not keep decimal precision if x > maxint
// https://stackoverflow.com/a/1685917/6593498
import BigNumber from 'bignumber.js';

export const toFixed = (x: number) => {
	let asString = x.toString();
	if (Math.abs(x) < 1.0) {
		const e = parseInt(asString.split('e-')[1], 10);
		if (e) {
			x *= Math.pow(10, e);
			asString = '0.' + new Array(e).join('0') + x.toString();
		}
	} else {
		let e = parseInt(x.toString().split('+')[1], 10);
		if (e > 20) {
			e -= 20;
			x /= Math.pow(10, e);
			asString += new Array(e + 1).join('0');
		}
	}
	return asString;
};

export const round = (number: number) =>
	number >= 0 * Math.round(Math.abs(number));

export const truncate = (
	toTrunc: number,
	decimals: number,
	keepTrailingZeros: boolean = false
) => {
	if (decimals === 0) {
		return Math.floor(toTrunc) + '';
	}
	if (toTrunc % 1 === 0 && !keepTrailingZeros) {
		return toTrunc + '';
	}
	const decimalCount = getDecimalCount(toTrunc);
	if (keepTrailingZeros && decimalCount < decimals) {
		return toTrunc.toFixed(decimals);
	}
	const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`);
	const matches = toFixed(toTrunc).match(regex);
	if (!matches) {
		return '';
	}
	if (keepTrailingZeros) {
		return matches[0];
	}
	const replaced = matches[0].replace(
		/((?:\d+))(\.)(\d*)/,
		(_, integerPart: string, separator: string, decimalPart: string) => {
			const trimmed = decimalPart.replace(/0+$/, '');
			if (!trimmed) {
				return integerPart;
			}
			return integerPart + separator + trimmed;
		}
	);
	return replaced;
};

export const parseOdds = (odds: number) => {
	const factor = odds >= 10 ? 10 : 100;
	return Math.floor(odds * factor) / factor;
};

export const parseBackersOdds = (backersOdds: number) => {
	const factor = 100;
	return Math.ceil(backersOdds * factor) / factor;
};

export const getDecimalCount = (x: number) => {
	if (x === Math.floor(x)) {
		return 0;
	}
	let asString = x.toString(10);
	if (Math.abs(x) < 1.0) {
		const value = parseInt(asString.split('e-')[0], 10);
		const e = parseInt(asString.split('e-')[1], 10);
		if (e) {
			x *= Math.pow(10, e);
			asString = '0.' + new Array(e).join('0') + value;
		}
	}
	return asString.split('.')[1].length;
};

export const timeStringToSeconds = (time: string) => {
	// it is use for example mm:ss, 80:00 = 4800
	const splitTime = time.split(':');
	if (splitTime.length) {
		let seconds = 0;
		splitTime.reverse().forEach((time, index) => {
			const timeNumber = parseInt(time, 10);
			seconds += timeNumber * Math.pow(60, index); // 60 to the power of index
		});

		return seconds;
	}

	return 0;
};

export const secondsToMinutes = (seconds: number) => {
	const minutes = Math.floor(seconds / 60);
	const sec = seconds - minutes * 60;
	let formatString = '';
	if (minutes > 0) {
		formatString += minutes < 10 ? '0' + minutes : minutes;
	} else {
		formatString += '00';
	}
	formatString += ':';
	if (sec > 0) {
		formatString += sec < 10 ? '0' + sec : sec;
	} else {
		formatString += '00';
	}

	return formatString;
};

// Unlike the number toPrecision, this function will take 0 as a sufficient figure as well
// No Trailing Zero after the decimals.
export const toPrecision = (value: number, suffFig: number) => {
	const number = new BigNumber(value);
	const exponent = Math.log10(value);

	if (exponent < 0) {
		return parseFloat(number.toFixed(suffFig, 1));
	}
	if (exponent < suffFig) {
		const decimals = Math.ceil(suffFig - exponent - 1);
		return parseFloat(number.toFixed(decimals, 1));
	}
	return parseFloat(number.toFixed(0, 1));
};

export const getTrailingZeros = (x: string) => {
	const regex = /^\d+(\.[0]*$)|\.\d*?([0]+)$/;
	const match = x.match(regex);
	if (!match) {
		return 0;
	}
	const trail = match[2] || match[1] || '';
	const hasDot = trail.startsWith('.') || trail.indexOf('.');
	const trailDecimals = (hasDot ? trail.split('.')[1] : trail) || '';
	return trailDecimals.length;
};

// FIXME doesn't handle exponential notation. returns string absolute decimal amount
export const getTrailingDecimals = (
	x: string,
	separators: string[] = ['.']
) => {
	const separator = separators.find((sep) => x.includes(sep));
	if (!separator) {
		return 0;
	}

	const trailDecimals = x.split(separator)[1];
	return trailDecimals.length;
};
