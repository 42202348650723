"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CinemaModeIcon = void 0;
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
exports.CinemaModeIcon = function (props) { return (React.createElement(core_1.SvgIcon, __assign({}, props),
    React.createElement("g", null,
        React.createElement("path", { d: "M1.75 13.498c.38 0 .694.282.744.648l.006.102v4.5c0 .39.242.69.53.742l.087.008h5.466c.415 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007H3.117c-1.134 0-2.035-.935-2.111-2.091L1 18.748v-4.5c0-.414.336-.75.75-.75zm20.5 0c.38 0 .693.282.743.648l.007.102v4.5c0 1.174-.853 2.16-1.964 2.244l-.153.006h-5.466c-.415 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007h5.466c.295 0 .563-.265.61-.636l.007-.114v-4.5c0-.414.335-.75.75-.75zM8.583 2.998c.415 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007H3.117c-.295 0-.563.265-.61.636l-.007.114v4.5c0 .414-.335.75-.75.75-.38 0-.693-.282-.743-.648L1 9.748v-4.5c0-1.174.853-2.16 1.964-2.244l.153-.006h5.466zm12.3 0c1.134 0 2.035.935 2.111 2.091l.006.159v4.5c0 .414-.336.75-.75.75-.38 0-.694-.282-.744-.648l-.006-.102v-4.5c0-.39-.242-.69-.53-.742l-.087-.008h-5.466c-.415 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007h5.466z" }),
        React.createElement("path", { d: "M12.723 7.02c1.242 0 2.25 1.008 2.25 2.25v5.46c0 1.243-1.008 2.25-2.25 2.25H2.343v-1.5h10.38c.38 0 .693-.282.743-.648l.007-.102V9.27c0-.414-.336-.75-.75-.75H2.343v-1.5z" })))); };
