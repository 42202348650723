"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetPlayerSideMenu = exports.usePlayerSideMenu = exports.PlayerSideMenuControlContext = exports.PlayerSideMenuContext = exports.useSetCasinoSideMenu = exports.useCasinoSideMenu = exports.CasinoSideMenuControlContext = exports.CasinoSideMenuContext = exports.useSetEsportsSideMenu = exports.useEsportsSideMenu = exports.EsportsSideMenuControlContext = exports.EsportsSideMenuContext = exports.useSetSportsSideMenu = exports.useSportsSideMenu = exports.SportsSideMenuControlContext = exports.SportsSideMenuContext = exports.useSetSideMenu = exports.useSideMenu = exports.SideMenuControlContext = exports.SideMenuContext = void 0;
var React = __importStar(require("react"));
exports.SideMenuContext = React.createContext(null);
exports.SideMenuControlContext = React.createContext(function () { return void 0; });
exports.useSideMenu = function () { return React.useContext(exports.SideMenuContext); };
exports.useSetSideMenu = function () { return React.useContext(exports.SideMenuControlContext); };
exports.SportsSideMenuContext = React.createContext(null);
exports.SportsSideMenuControlContext = React.createContext(function () { return void 0; });
exports.useSportsSideMenu = function () { return React.useContext(exports.SportsSideMenuContext); };
exports.useSetSportsSideMenu = function () {
    return React.useContext(exports.SportsSideMenuControlContext);
};
exports.EsportsSideMenuContext = React.createContext(null);
exports.EsportsSideMenuControlContext = React.createContext(function () { return void 0; });
exports.useEsportsSideMenu = function () {
    return React.useContext(exports.EsportsSideMenuContext);
};
exports.useSetEsportsSideMenu = function () {
    return React.useContext(exports.EsportsSideMenuControlContext);
};
exports.CasinoSideMenuContext = React.createContext(null);
exports.CasinoSideMenuControlContext = React.createContext(function () { return void 0; });
exports.useCasinoSideMenu = function () { return React.useContext(exports.CasinoSideMenuContext); };
exports.useSetCasinoSideMenu = function () {
    return React.useContext(exports.CasinoSideMenuControlContext);
};
exports.PlayerSideMenuContext = React.createContext(null);
exports.PlayerSideMenuControlContext = React.createContext(function () { return void 0; });
exports.usePlayerSideMenu = function () { return React.useContext(exports.PlayerSideMenuContext); };
exports.useSetPlayerSideMenu = function () {
    return React.useContext(exports.PlayerSideMenuControlContext);
};
