import { SlideProps, useTheme } from '@material-ui/core';
import { duration } from '@material-ui/core/styles/transitions';
import {
	getTransitionProps,
	reflow
} from '@material-ui/core/transitions/utils';
import debounce from '@material-ui/core/utils/debounce';
import useForkRef from '@material-ui/core/utils/useForkRef';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';
import {
	EndHandler,
	EnterHandler,
	ExitHandler
} from 'react-transition-group/Transition';

// Translate the node so he can't be seen on the screen.
// Later, we gonna translate back the node to his original location
// with `none`.`
type Direction = 'up' | 'right' | 'down' | 'left';
type Node = HTMLElement & { fakeTransform?: boolean };
type Callback = (node: HTMLElement | null, isAppearing?: boolean) => void;
const getTranslateValue = (direction: Direction, node: Node) => {
	const rect = node.getBoundingClientRect();
	let transform;

	if (node.fakeTransform) {
		transform = node.fakeTransform;
	} else {
		const computedStyle = window.getComputedStyle(node);
		transform =
			computedStyle.getPropertyValue('-webkit-transform') ||
			computedStyle.getPropertyValue('transform');
	}

	let offsetX = 0;
	let offsetY = 0;

	if (transform && transform !== 'none' && typeof transform === 'string') {
		const transformValues = transform
			.split('(')[1]
			.split(')')[0]
			.split(',');
		offsetX = parseInt(transformValues[4], 10);
		offsetY = parseInt(transformValues[5], 10);
	}

	if (direction === 'left') {
		return `translateX(${window.innerWidth}px) translateX(${offsetX -
			rect.left}px)`;
	}

	if (direction === 'right') {
		return `translateX(-${rect.left + rect.width - offsetX}px)`;
	}

	if (direction === 'up') {
		return `translateY(${window.innerHeight}px) translateY(${offsetY -
			rect.top}px)`;
	}

	// direction === 'down'
	return `translateY(-${rect.top + rect.height - offsetY}px)`;
};

export function setTranslateValue(
	direction: Direction,
	node: Node,
	sizeNode = node
) {
	const transform = getTranslateValue(direction, sizeNode);

	if (transform) {
		node.style.webkitTransform = transform;
		node.style.transform = transform;
	}
}

const defaultTimeout = {
	enter: duration.enteringScreen,
	exit: duration.leavingScreen
};
export interface CustomSlideProps extends SlideProps {
	children: React.ReactElement & { ref?: React.Ref<unknown> };
	ref: React.Ref<Transition>;
	sizeRef?: React.RefObject<unknown>;
}
/**
 * The Slide transition is used by the [Drawer](/components/drawers/) component.
 * It uses [react-transition-group](https://github.com/reactjs/react-transition-group) internally.
 */
export const Slide = React.forwardRef<Transition, CustomSlideProps>(
	function Slide(props, ref) {
		const {
			children,
			direction = 'down',
			in: inProp,
			onEnter,
			onEntered,
			onEntering,
			onExit,
			onExited,
			onExiting,
			style,
			sizeRef,
			timeout = defaultTimeout,
			...other
		} = props;
		const theme = useTheme();
		const childrenRef = React.useRef<HTMLElement | null>(null);
		/**
		 * used in cloneElement(children, { ref: handleRef })
		 */
		const handleOwnRef = React.useCallback((instance) => {
			// #StrictMode ready
			childrenRef.current = ReactDOM.findDOMNode(instance) as HTMLElement;
		}, []);
		const handleRefIntermediary = useForkRef(
			children.ref as React.Ref<HTMLElement>,
			handleOwnRef
		);
		const handleRef = useForkRef(handleRefIntermediary, ref);

		const normalizedTransitionCallback = (callback?: Callback) =>
			(((isAppearing: boolean) => {
				if (callback) {
					// onEnterXxx and onExitXxx callbacks have a different arguments.length value.
					if (isAppearing === undefined) {
						callback(childrenRef.current);
					} else {
						callback(childrenRef.current, isAppearing);
					}
				}
			}) as unknown) as
				| EndHandler
				| EnterHandler
				| ExitHandler
				| undefined;

		const handleEnter = normalizedTransitionCallback(
			(node: HTMLElement, isAppearing: boolean) => {
				setTranslateValue(
					direction,
					node,
					(sizeRef?.current as HTMLElement) || undefined
				);
				reflow(node);

				if (onEnter) {
					onEnter(node, isAppearing);
				}
			}
		);

		const handleEntering = normalizedTransitionCallback(
			(node: HTMLElement, isAppearing: boolean) => {
				const transitionProps = getTransitionProps(
					{ timeout, style },
					{
						mode: 'enter'
					}
				);

				node.style.webkitTransition = theme.transitions.create(
					'-webkit-transform',
					{
						...transitionProps,
						easing: theme.transitions.easing.easeOut
					}
				);

				node.style.transition = theme.transitions.create('transform', {
					...transitionProps,
					easing: theme.transitions.easing.easeOut
				});

				node.style.webkitTransform = 'none';
				node.style.transform = 'none';
				if (onEntering) {
					onEntering(node, isAppearing);
				}
			}
		);

		const handleEntered = normalizedTransitionCallback(onEntered);
		const handleExiting = normalizedTransitionCallback(onExiting);

		const handleExit = normalizedTransitionCallback((node: Node) => {
			const transitionProps = getTransitionProps(
				{ timeout, style },
				{
					mode: 'exit'
				}
			);

			node.style.webkitTransition = theme.transitions.create(
				'-webkit-transform',
				{
					...transitionProps,
					easing: theme.transitions.easing.sharp
				}
			);

			node.style.transition = theme.transitions.create('transform', {
				...transitionProps,
				easing: theme.transitions.easing.sharp
			});

			setTranslateValue(
				direction,
				node,
				(sizeRef?.current as HTMLElement) || undefined
			);

			if (onExit) {
				onExit(node);
			}
		});

		const handleExited = normalizedTransitionCallback(
			(node: HTMLElement) => {
				// No need for transitions when the component is hidden
				node.style.webkitTransition = '';
				node.style.transition = '';

				if (onExited) {
					onExited(node);
				}
			}
		);

		const updatePosition = React.useCallback(() => {
			if (childrenRef.current) {
				setTranslateValue(
					direction,
					childrenRef.current,
					(sizeRef?.current as HTMLElement) || undefined
				);
			}
		}, [direction]);

		React.useEffect(() => {
			// Skip configuration where the position is screen size invariant.
			if (inProp || direction === 'down' || direction === 'right') {
				return undefined;
			}

			const handleResize = debounce(() => {
				if (childrenRef.current) {
					setTranslateValue(
						direction,
						childrenRef.current,
						(sizeRef?.current as HTMLElement) || undefined
					);
				}
			});

			window.addEventListener('resize', handleResize);
			return () => {
				handleResize.clear();
				window.removeEventListener('resize', handleResize);
			};
		}, [direction, inProp]);

		React.useEffect(() => {
			if (!inProp) {
				// We need to update the position of the drawer when the direction change and
				// when it's hidden.
				updatePosition();
			}
		}, [inProp, updatePosition]);

		return (
			<Transition
				nodeRef={childrenRef}
				onEnter={handleEnter as EnterHandler}
				onEntered={handleEntered as EnterHandler}
				onEntering={handleEntering as EnterHandler}
				onExit={handleExit as ExitHandler}
				onExited={handleExited as ExitHandler}
				onExiting={handleExiting as ExitHandler}
				appear
				in={inProp}
				timeout={timeout}
				{...other}
			>
				{(state: string, childProps: Record<string, unknown>) => {
					return React.cloneElement(children as React.ReactElement, {
						ref: handleRef,
						style: {
							visibility:
								state === 'exited' && !inProp
									? 'hidden'
									: undefined,
							...style,
							...children.props.style
						},
						...childProps
					});
				}}
			</Transition>
		);
	}
);
export const CustomSlide = Slide;
