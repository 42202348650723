import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

const allMarkets = {
	popular: [
		{
			marketType: MarketType.darts_winner
		},
		{
			marketType: MarketType.darts_handicap_sets,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.darts_handicap_legs,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.darts_total_legs,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.darts_total_sets,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.darts_correct_score_in_sets,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.darts_set_leg_checkout_color
		}
	],
	sets: [
		{
			marketType: MarketType.darts_set_winner
		},
		{
			marketType: MarketType.darts_set_leg_winner
		},
		{
			marketType: MarketType.darts_set_leg_handicap,
			view: MarketView.Handicap
		},
		{
			marketType: MarketType.darts_set_total_legs,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.darts_set_leg_total_darts,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.darts_set_most_180s
		},
		{
			marketType: MarketType.darts_set_total_180s,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.darts_set_correct_score_in_legs,
			view: MarketView.CorrectScore
		},
		{
			marketType: MarketType.darts_set_which_player_wins_the_rest
		}
	],
	specials: [
		{
			marketType: MarketType.darts_most_180s
		},
		{
			marketType: MarketType.darts_180s_handicap,
			marketView: MarketView.Handicap
		},
		{
			marketType: MarketType.darts_team_total_180s,
			marketView: MarketView.Goals
		},
		{
			marketType: MarketType.darts_total_180s,
			marketView: MarketView.Goals
		}
	]
};

export const eventMarketConfig: TabConfiguration[] = [
	{
		key: 'popular',
		label: 'descriptions.popular',
		markets: allMarkets.popular
	},
	{
		key: 'sets',
		label: 'markets.sets',
		markets: allMarkets.sets
	},
	{
		key: 'specials',
		label: 'markets.specials',
		markets: allMarkets.specials
	},
	{
		key: 'all',
		label: 'descriptions.all',
		markets: [
			...allMarkets.popular,
			...allMarkets.sets,
			...allMarkets.specials
		]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.darts_winner
			},
			{
				marketType: MarketType.darts_handicap_sets
			},
			{
				marketType: MarketType.darts_total_sets
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.darts_outright
		}
	]
};

export const marketInfo = {
	defaultEventMarketTab: 'all',
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
