import { getPlatform } from '@gaming-shell/device';
import { useLanguage } from '@gaming-shell/i18n';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { usePlayerLoggedIn } from '@materialbet-core/player';
import { createGameLaunchUrl } from 'casino/util/createGameLaunchUrl';
import clsx from 'clsx';
import { createLink, getImageUrl, providersList } from 'materialbet-common';
import * as React from 'react';

import { SearchResultItem } from '../Content';

export interface SearchResultTileProps extends Partial<SearchResultItem> {
	skeleton?: boolean;
	imageUrl?: string;
}

const imageSize = SEARCH_TILE_IMAGE_SIZE[getPlatform()];
const tileSize = SEARCH_TILE_SIZE[getPlatform()];

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		overflow: 'hidden',
		flex: `1 1 100%`,
		flexDirection: 'column',
		flexWrap: 'nowrap',
		maxWidth: tileSize,
		minWidth: tileSize,
		paddingBottom: theme.spacing(2),
		padding: theme.spacing(0, 0.5),
		textDecoration: 'none'
	},
	skeletonImg: {
		borderRadius: theme.spacing(0.5),
		height: theme.spacing(11.625)
	},
	img: ({ imageUrl }: SearchResultTileProps) => ({
		paddingTop: theme.spacing(11.625),
		borderRadius: theme.spacing(0.5),
		backgroundImage: imageUrl ? `url(${imageUrl})` : '',
		/* in case of missing background image show background with color as placeholder */
		backgroundColor: lighten(theme.palette.background.paper, 0.1),
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat'
	}),
	title: {
		paddingTop: theme.spacing(0.5)
	},
	titleSkeleton: {
		marginTop: theme.spacing(0.5)
	},
	provider: {
		lineHeight: 1
	},
	text: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		maxWidth: tileSize
	},
	textSkeleton: {
		height: theme.spacing(1.5),
		marginTop: theme.spacing(0.5)
	},
	[theme.breakpoints.down('xs')]: {
		img: {
			'&&': {
				/* fixes mui issue which injects dynamic style after static inside media query */
				paddingTop: theme.spacing(10.25)
			}
		},
		skeletonImg: {
			'&&': {
				height: theme.spacing(10.25)
			}
		}
	}
}));

const GridItemLink = createLink(Grid);
export const SearchResultTile: React.FC<SearchResultTileProps> = ({
	id,
	gameKey,
	_tags,
	name,
	images,
	skeleton,
	providerKey
}) => {
	const imageUrl = getImageUrl(
		// if mobile isn't available fallback to desktop
		images?.[getPlatform()] || images?.desktop || '',
		imageSize
	);
	const classes = useStyles({ images, imageUrl });
	const language = useLanguage();
	const isLoggedIn = usePlayerLoggedIn();

	if (skeleton) {
		return (
			<Grid className={classes.root} item xs>
				<Skeleton variant="rect" className={classes.skeletonImg} />
				<Skeleton variant="rect" className={classes.titleSkeleton} />
				<Skeleton variant="rect" className={classes.textSkeleton} />
			</Grid>
		);
	}
	if (!id) {
		return null;
		// if we dont have the id we cannot click on the link to get to the game
		// making the result even more frustrating
	}
	return (
		<GridItemLink
			href={createGameLaunchUrl(
				// eslint-disable-next-line @typescript-eslint/camelcase
				{ id, game_key: gameKey, tags: _tags },
				isLoggedIn
			)}
			className={classes.root}
			item
			xs
		>
			<img className={classes.img} />
			<Typography
				className={clsx(classes.title, classes.text)}
				variant="body2"
				color="textPrimary"
			>
				{/* Take translated version if exist */}
				{name?.[language] || name?.[FALLBACK_LANGUAGE]}
			</Typography>
			{providerKey && providersList[providerKey] && (
				<Typography
					className={clsx(classes.provider, classes.text)}
					variant="caption"
					color="textSecondary"
				>
					{providersList[providerKey]}
				</Typography>
			)}
		</GridItemLink>
	);
};
