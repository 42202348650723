import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { createSvgIcon } from 'sports/components/util/Icon';

//TODO: move to general if not betslip specific
export const DeleteIcon = ENABLE_NEW_ICON
	? createSvgIcon(
			'M14.13 2.71c1.198 0 2.172.967 2.18 2.17v.679l2.09.001H21.25a.75.75 0 0 1 .743.648L22 6.31a.75.75 0 0 1-.648.743l-.102.007h-2.1v12.06a2.17 2.17 0 0 1-2.14 2.17H6.99a2.17 2.17 0 0 1-2.14-2.17V7.06h-2.1a.75.75 0 0 1-.743-.648L2 6.31a.75.75 0 0 1 .648-.743l.102-.007H5.6l2.089-.001.001-.684a2.18 2.18 0 0 1 2.025-2.16l.155-.005zm3.52 4.35H6.35v12.06c0 .366.294.665.65.67h9.994a.67.67 0 0 0 .656-.67V7.06zM9.87 9.83a.75.75 0 0 1 .743.648l.007.102v5.69a.75.75 0 0 1-1.493.102l-.007-.102v-5.69a.75.75 0 0 1 .75-.75zm4.26 0a.75.75 0 0 1 .743.648l.007.102v5.69a.75.75 0 0 1-1.493.102l-.007-.102v-5.69a.75.75 0 0 1 .75-.75zm0-5.62H9.87a.679.679 0 0 0-.68.67v.68h5.62v-.675a.68.68 0 0 0-.68-.675z',
			'delete',
			'0.6'
	  )
	: DeleteOutline;
