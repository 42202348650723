"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
var KabaddiIcon = function (props) {
    return (React.createElement(core_1.SvgIcon, __assign({}, props),
        React.createElement("g", { stroke: 'none', strokeWidth: "1", fill: props.fill, fillRule: "evenodd" },
            React.createElement("path", { d: "M11.39 3.2c1.619 0 2.93 1.313 2.93 2.93 0 1.619-1.311 2.93-2.93 2.93-1.617 0-2.93-1.311-2.93-2.93 0-1.617 1.313-2.93 2.93-2.93zm0 1.3c-.9 0-1.63.73-1.63 1.63 0 .9.73 1.63 1.63 1.63.9 0 1.63-.73 1.63-1.63 0-.9-.73-1.63-1.63-1.63z", id: "Path", fill: props.fill, fillRule: "nonzero" }),
            React.createElement("path", { d: "M17.714 21.594c.963.479 2.134.086 2.614-.88.48-.963.087-2.135-.877-2.615-.224-.11-.545-.292-.892-.519-.5-.327-.983-.7-1.419-1.115-1.033-.983-1.672-2.063-1.79-3.224l2.323-1.17c1.835-.912 2.835-2.943 2.436-4.957l-.898-3.553c-.18-1.016-1.196-1.72-2.256-1.53-1.06.19-1.764 1.205-1.574 2.265l.888 3.553c.053.258-.088.55-.35.681l-3.259 1.63c-2.395 1.199-5.034 1.826-7.71 1.83-1.076 0-1.95.873-1.95 1.95-.001 1.028.794 1.87 1.803 1.945l.148.006c1.978 0 3.948-.28 5.85-.827V21.1c0 .359.291.65.65.65.359 0 .65-.291.65-.65v-6.92c0-.444-.435-.758-.856-.617-1.863.623-3.804.967-5.76 1.02l-.536.007c-.359 0-.65-.291-.649-.65 0-.36.291-.65.651-.65 2.879-.005 5.716-.68 8.291-1.97l3.26-1.629c.787-.396 1.212-1.27 1.035-2.132l-.885-3.537c-.055-.31.18-.65.533-.713.322-.057.631.132.732.432l.924 3.658c.277 1.405-.436 2.856-1.75 3.509L14.39 12.27c-.22.11-.358.335-.358.58 0 1.718.83 3.241 2.213 4.557.878.834 1.87 1.483 2.63 1.857.319.159.45.55.29.872-.16.322-.55.453-.874.293-1.46-.72-2.75-1.74-3.788-2.993-.389-.469-1.151-.194-1.151.415v3.25c0 .359.291.65.65.65.359 0 .65-.291.65-.65v-1.618c.911.847 1.942 1.558 3.063 2.11z", id: "Path", fill: props.fill, fillRule: "nonzero" }))));
};
exports.default = KabaddiIcon;
