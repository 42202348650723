import { MarketType } from 'sports-sdk';
import { Event } from 'sports/api/sports/models';

import { Configuration } from './configuration';

export interface PlaceBetV2RequestParams {
	referenceId: string;
	acceptBetterOdds: boolean;
	currency: string;
	selections: PlaceBetV2Selection[];
	bets: PlaceBetV2Bet[];
}
export interface PlaceBetV2Response {
	referenceId: string;
	status: PlaceBetStatus;
	selections: PlaceBetV2Selection[];
	bets: PlaceBetV2Bet[];
}

export interface PlaceBetV2Selection {
	price: string;
	eventId: string;
	marketUrl: string;
	status?: PlaceBetStatus;
}
export interface PlaceBetV2Bet {
	stake: string;
	uuid?: string;
	straight?: PlaceBetV2Straight;
	multiple?: PlaceBetV2Multiple;
	status?: PlaceBetStatus;
	liability?: string;
}
export interface PlaceBetV2Straight {
	/** @description The array index of objects array Selections */
	selectionIndex: number;
}
export interface PlaceBetV2Multiple {
	/** @description  0 means full parlay among the Selection Array for now, cannot have other value*/
	systemValue: 0; // TODO change to number once we allow multiples
}

export type BetsHistoryState = 'SETTLEMENT_PENDING' | 'SETTLEMENT_COMPLETED';
export interface BetsHistoryRequestParams {
	from: number;
	to: number;
	state: BetsHistoryState;
	locale: string;
}
export interface BetResponseV2Params {
	referenceId: string;
}
export type PlaceBetStatus =
	| 'INTERNAL_SERVER_ERROR'
	| 'DUPLICATE_REQUEST'
	| 'MALFORMED_REQUEST'
	| 'PRICE_ABOVE_MARKET'
	| 'INSUFFICIENT_FUND'
	| 'STAKE_ABOVE_MAX'
	| 'STAKE_BELOW_MIN'
	| 'LIABILITY_LIMIT_EXCEEDED'
	| 'ACCEPTED'
	| 'PENDING_ACCEPTANCE'
	| 'INCOMPLETE_REQUEST'
	| 'MARKET_SUSPENDED'
	| 'VALID'
	| 'PARLAY_RESTRICTION'
	| 'RESTRICTED'
	| 'ACTIVE_BONUS_MIN_PRICE'
	| 'ACTIVE_BONUS_RESTRICTED'
	| 'ACTIVE_BONUS_PENDING_PAYOUT'
	| 'ACTIVE_BONUS_CORRELATION'
	| 'VERIFICATION_REQUIRED'
	| 'REOFFER_AVAILABLE'
	| 'CORRELATED_SELECTIONS';

export interface PlaceBetResponse {
	referenceId: string;
	status: PlaceBetStatus;
	stake: string;
	liabaility: string;
	price: string;
	eventId: string;
	marketUrl: string;
	side: Side;
	currency: string;
	statusDetails: string;
}
export type Side = 'LAY' | 'BACK';
export type BetsHistoryBetStatusV3 =
	| 'LOSS'
	| 'HALF_WIN'
	| 'WIN'
	| 'PENDING'
	| 'PUSH'
	| 'HALF_LOSS'
	| 'PARTIAL'
	| 'DEADHEAT';
export type BetsHistoryBetStatusV2 =
	| 'LOSS'
	| 'HALF_WIN'
	| 'WIN'
	| 'PENDING'
	| 'PUSH'
	| 'HALF_LOSS'
	| 'PARTIAL'
	| 'DEADHEAT';
export type BetsHistoryEntityType = 'EVENT' | 'OUTRIGHT';
export interface BetsHistorySelectionV3 {
	isLive: boolean;
	entityIndex: number;
	outcome: string;
	params: string;
	price: string;
	status: BetsHistoryBetStatusV3;
	marketKey: MarketType;
}
export interface BetsHistorySelectionV2 {
	name: string;
	odds: number;
	result: '';
	eventId: number;
	isLive: boolean;
	isOutright: boolean;
	startsAt: number;
	endsAt: number;
	specialBetValue: string;
	marketId: number;
	competitors: [string, string];
	betState: BetsHistoryBetStatusV2;
	sportKey: string;
	outcomeName: string;
	marketName: string;
	eventName: string;
}
export interface BetsHistoryBetV3 {
	referenceId: string;
	liability: string;
	side: Side;
	currency: string;
	status: BetsHistoryBetStatusV3;
	uuid: string;
	winLoss: string;
	selections: number[];
	systems: number[];
	createdAt: string;
	promotions: string[];
}
export interface BetsHistoryBetV2 {
	id: number;
	version: number;
	state: 'pending';
	stake: number;
	odds: number;
	createdAt: number;
	result: '';
	system: number;
	selections: BetsHistorySelectionV2[];
	winAmount: number;
	betCurrency: string;
	betState: BetsHistoryBetStatusV2;
	isLay: boolean;
	backersOdds: number;
	backersStake: number;
}
export interface BetsHistoryEntity {
	type: BetsHistoryEntityType;
	event: Event;
}
export interface BetsHistoryBetslipV3 {
	selections: BetsHistorySelectionV3[];
	bets: BetsHistoryBetV3[];
	referenceId: string;
}
export interface BetsHistoryBetslipV2 {
	bets: BetsHistoryBetV2[];
	betslipId: string;
}
export interface AcceptedBetslip {
	v2?: BetsHistoryBetslipV2;
	v3?: BetsHistoryBetslipV3;
}
export interface BetsHistoryResponse {
	acceptedBetslips: AcceptedBetslip[];
	entities: BetsHistoryEntity[];
	totalCount: number;
}
export interface EventPromotionParams {
	eventId: number | string;
	promotion: string; // this might potentially change to a string literal or enum once we have more than one promotion
}
/** @description Response structure might in the future depend on the type of promotion */
export interface EventPromotionResponse {
	customerLiability: string;
	customerLiabilityLimit: string;
	customerBets: number;
	slotsTaken: number;
	slotsAvailable: number;
	currency: string;
}
export interface SportsbettingApi {
	postPlaceBetV2(
		params: PlaceBetV2RequestParams,
		csrfToken: string
	): Promise<PlaceBetV2Response>;
	getBetsHistory(
		params: BetsHistoryRequestParams
	): Promise<BetsHistoryResponse>;
	getBetResponseV2(params: BetResponseV2Params): Promise<PlaceBetV2Response>;
	getEventPromotion(
		params: EventPromotionParams
	): Promise<EventPromotionResponse>;
}

export default (configuration: Configuration) => {
	const baseOptions = configuration.baseOptions || {};
	const basePathV1 = configuration.basePath(1);
	const basePathV2 = configuration.basePath(2);
	const betslipPath = configuration.betslipBasePath;
	const createOptions = (method: string, body?: string, headers?: object) => {
		const options = { ...baseOptions, method, body };
		options['headers'] = { ...baseOptions['headers'], ...headers };
		return options;
	};
	const api: SportsbettingApi = {
		postPlaceBetV2: (
			params: PlaceBetV2RequestParams,
			csrfToken: string
		) => {
			const options = createOptions('POST', JSON.stringify(params), {
				'X-Csrf-Token': csrfToken
			});
			return fetch(basePathV2 + 'place_bet', options).then((response) => {
				if (
					(response.status >= 200 && response.status < 300) ||
					response.status === 400 ||
					response.status === 401
				) {
					return response.json(); // TODO letting specific errors through
				} else {
					throw response;
				}
			});
		},
		getBetsHistory: (params: BetsHistoryRequestParams) => {
			let url = basePathV1 + 'bets_history';

			const queryString = Object.keys(params).reduce((qs, key) => {
				if (qs) {
					qs = qs += '&';
				}
				qs = qs + `${key}=${params[key]}`;
				return qs;
			}, '');
			if (queryString) {
				url += '?' + queryString;
			}
			const options = createOptions('GET');
			return fetch(url, options).then((response) => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				} else {
					throw response;
				}
			});
		},
		getBetResponseV2: (params: BetResponseV2Params) => {
			const url = betslipPath + 'bet_response/' + params.referenceId;
			const options = createOptions('GET');
			return fetch(url, options).then((response) => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				} else {
					if (response.json) {
						throw response.json();
					}
					throw response;
				}
			});
		},
		getEventPromotion: ({ promotion, eventId }: EventPromotionParams) => {
			const url = `${basePathV1}promotion/${promotion}/event/${eventId}`;
			const options = createOptions('GET');
			return fetch(url, options).then((response) => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				} else {
					if (response.json) {
						throw response.json();
					}
					throw response;
				}
			});
		}
	};
	return api;
};
