import { Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import * as React from 'react';
import { EventMetadata } from 'sports/api/sports';
import { sportInfo } from 'sports/config/sport';
import { formatSetScores } from 'sports/utils/metadataUtils';
import { DeepReadonly } from 'utility-types';

import { EventListVariant } from '../eventList/types';

export interface EventScoreProps {
	sportKey: string;
	score?: DeepReadonly<EventMetadata['score']>;
	setScores?: DeepReadonly<EventMetadata['setScores']>;
	away?: boolean;
	disableSingleTeam?: boolean;
	variant?: EventListVariant;
	textVariant?: TypographyProps['variant'];
}

/** @description Use to render the current score of live events*/
export const EventScore: React.FunctionComponent<EventScoreProps> = ({
	sportKey,
	score,
	setScores,
	away,
	disableSingleTeam,
	variant,
	textVariant = 'body2'
}) => {
	if (sportInfo[sportKey]?.hideScores) {
		return null;
	}
	const customVariant =
		variant === 'narrow' && !disableSingleTeam ? 'caption' : textVariant;
	if (setScores && sportInfo[sportKey]?.setScores) {
		const formattedSetScore = formatSetScores(
			setScores,
			away,
			!disableSingleTeam
		);
		return (
			<Typography variant={customVariant} color="secondary">
				{formattedSetScore}
			</Typography>
		);
	}

	if (!score) {
		return null;
	}
	const teamScore = !disableSingleTeam
		? score[away ? 1 : 0]
		: score.join('-');
	if (typeof teamScore === undefined) {
		return null;
	}
	return (
		<Typography variant={customVariant} color="secondary">
			{teamScore}
		</Typography>
	);
};
