'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
require("core-js/features/promise");
if (typeof Promise === 'undefined') {
    require('promise/lib/rejection-tracking').enable();
    window.Promise = require('promise/lib/es6-extensions.js');
}
require('whatwg-fetch');
Object.assign = require('object-assign');
if (process.env.NODE_ENV === 'test') {
    require('raf').polyfill(global);
    require('resize-observer-polyfill').polyfill(global);
}
