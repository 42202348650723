"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
var HearthStoneIcon = function (props) {
    return (React.createElement(core_1.SvgIcon, __assign({}, props),
        React.createElement("g", { stroke: "none", strokeWidth: "1", fill: props.fill, fillRule: "evenodd" },
            React.createElement("path", { d: "M18.514 7.786l1.004-3.643-3.729 1.148c-.078-.053-.163-.109-.25-.164-.108-.068-.213-.13-.316-.187-.132-.072-.257-.134-.378-.183L12.037 0 9.08 4.976l-.094.04c-.209.088-.448.186-.718.293l-3.78-1.602 1.137 4.068c-.071.117-.148.25-.224.394-.085.16-.162.318-.228.474l-.09.233c-.015.04-.028.08-.04.119L0 11.817l4.961 2.86c.084.225.175.45.274.664.065.14.13.27.197.391L4.17 19.316l3.858-.916.262.174.442.279.335.196c.104.059.201.11.292.154L11.935 24l2.875-4.794.394-.178.521-.249.275-.14c.042-.022.082-.043.12-.065l3.782 1-1.16-3.777.165-.278.248-.432.192-.355.08-.158L24 11.828 19.174 8.93c-.131-.329-.344-.72-.66-1.145zm-.782 2.245l2.994 1.797-2.642 1.586-.106.254-.093.199-.135.259-.162.29-.217.374c-.105.176-.201.333-.275.45l-.202.323.493 1.607-1.565-.414-.326.23-.116.072-.18.099-.408.204-.309.145-.604.27-.252.109-1.61 2.685-1.46-2.72-.33-.104c-.06-.018-.194-.086-.372-.187l-.391-.236c-.306-.192-.594-.386-.775-.513l-.314-.22-1.578.374.523-1.485-.274-.358c-.073-.096-.167-.264-.266-.477-.136-.297-.249-.601-.312-.794l-.1-.306-2.957-1.705 3.14-1.757.065-.398c.011-.068.034-.152.068-.248.053-.147.128-.312.22-.484.128-.241.258-.444.337-.556l.23-.324-.404-1.444 1.176.499.802-.316.607-.25.376-.162.242-.11 1.762-2.965 1.713 2.9.394.065.115.047.145.074.11.063.117.071c.202.127.382.257.487.337l.35.27 1.6-.492-.418 1.517.286.337c.444.523.656.96.715 1.186l.086.332z", id: "Path", fill: props.fill, fillRule: "nonzero" }),
            React.createElement("path", { d: "M14.59 15.066s-.961.817-2.205.896c0 0-2.167.29-2.923-.738l2.672-.412s3.51-1.125 1.328-4.81l-.103-.265-.204.054s-1.129-.396-2.104.632c0 0-.55.975.155 1.636 0 0 .384.225.359-.211l.032-.6s2.036-1.045 1.454 1.71c0 0-1.269 1.291-2.615.473 0 0-1.705-1.556-1.384-2.796L9 10.003l1.129-1.001 1.486-.897.513.106L12.59 8l.256.317 1.58.19s3.43 3.327.164 6.559", id: "Path", fill: props.fill, fillRule: "nonzero" }))));
};
exports.default = HearthStoneIcon;
