import { sportsSharingUrl } from 'sports/config/sportssharing';

import createSportsSharingApi from './sportssharing/api';

const configuration = {
	basePath: sportsSharingUrl,
	baseOptions: {
		credentials: 'include',
		headers: {
			accept: 'application/json',
			'Content-Type': 'application/json',
			'Cache-Control': 'no-cache, no-store'
		}
	}
};
const api = createSportsSharingApi(configuration);
export default api;
