/* tslint:disable */
/* eslint-disable */
/**
 * Sports API
 * Sports API. All endpoints accept `locale` query string to specify locales.
 *
 * The version of the OpenAPI document: 6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EventMedia
 */
export interface EventMedia {
	/**
	 *
	 * @type {string}
	 * @memberof EventMedia
	 */
	image?: string;
	/**
	 *
	 * @type {string}
	 * @memberof EventMedia
	 */
	videoThumbnail?: string;
}

export function EventMediaFromJSON(json: any): EventMedia {
	return EventMediaFromJSONTyped(json, false);
}

export function EventMediaFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): EventMedia {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		image: !exists(json, 'image') ? undefined : json['image'],
		videoThumbnail: !exists(json, 'videoThumbnail')
			? undefined
			: json['videoThumbnail']
	};
}

export function EventMediaToJSON(value?: EventMedia | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		image: value.image,
		videoThumbnail: value.videoThumbnail
	};
}
