"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnnouncementImportance = void 0;
var AnnouncementImportance;
(function (AnnouncementImportance) {
    AnnouncementImportance["CRITICAL"] = "CRITICAL";
    AnnouncementImportance["WARNING"] = "WARNING";
    AnnouncementImportance["NOTICE"] = "NOTICE";
    AnnouncementImportance["INFO"] = "INFO";
})(AnnouncementImportance = exports.AnnouncementImportance || (exports.AnnouncementImportance = {}));
