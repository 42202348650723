"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tag = void 0;
var core_1 = require("@material-ui/core");
var styles_1 = require("@material-ui/core/styles");
var React = __importStar(require("react"));
var getColorStyle = function (theme, color) { return ({
    backgroundColor: theme.palette[color].main,
    color: theme.palette[color].contrastText,
    fontSize: theme.typography.caption.fontSize,
    textTransform: theme.typography.overline.textTransform,
    display: 'inline-block',
    padding: '1px 5px',
    verticalAlign: 'text-bottom'
}); };
var useStyles = styles_1.makeStyles(function (theme) { return ({
    primary: getColorStyle(theme, 'primary'),
    secondary: getColorStyle(theme, 'secondary')
}); });
exports.Tag = function (_a) {
    var _b = _a.color, color = _b === void 0 ? 'primary' : _b, children = _a.children;
    var classes = useStyles();
    var className = classes[color];
    return React.createElement(core_1.Typography, { className: className }, children);
};
