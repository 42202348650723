"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
var RainbowSixIcon = function (props) {
    return (React.createElement(core_1.SvgIcon, __assign({}, props),
        React.createElement("g", { fill: props.fill, fillRule: "evenodd" },
            React.createElement("path", { d: "M12.692 15.942h-1.034v-.996c.578-.513.842-.123.84-.12l.091-.087c-.03-.151-.275-.193-.275-.193l-.006-.235h.238l.146.25v1.381zm.551-3.551s-1.402.756-1.698-.091h-1.157v.112h-.26v.24h-.125v.264h.127l.004.09v6.515H10v.352h.352V20h1.054v-.142h.141v-3.39s.382-.493 1.264-.384v-1.77s.767-.389 2.189-.389v-1.62s-.53-.74-1.757.086z" }),
            React.createElement("path", { d: "M14.15 7V5.32l-.24-.2c-.374-.35-.868-.543-1.38-.54-.464.032-.9.231-1.23.56l-.19.21v4.13h3c.55-.008 1.096.087 1.61.28.317.123.604.314.84.56.197.19.348.423.44.68v8.07c-.108.62-.413 1.188-.87 1.62-.45.408-.982.715-1.56.9-.72.252-1.477.39-2.24.41-.789.035-1.578-.07-2.33-.31-.459-.153-.876-.41-1.22-.75-.345-.357-.58-.804-.68-1.29-.061-.211-.095-.43-.1-.65V5.12c.059-.64.33-1.242.77-1.71.436-.418.958-.736 1.53-.93.729-.283 1.499-.445 2.28-.48 1.245-.156 2.5.205 3.47 1 .208.2.386.43.53.68.119.217.216.444.29.68.074.243.114.496.12.75v1.94L14.15 7zm-2.08-1.31c.148-.113.325-.182.51-.2.219.006.43.083.6.22v2.23L18 8V5.07c-.009-.339-.062-.675-.16-1-.098-.3-.222-.591-.37-.87-.201-.343-.45-.657-.74-.93-1.177-.977-2.7-1.435-4.22-1.27-.86.05-1.706.233-2.51.54-.75.23-1.435.635-2 1.18-.586.642-.939 1.463-1 2.33V19c-.003.297.034.593.11.88.124.659.433 1.27.89 1.76.459.455 1.017.797 1.63 1 .885.283 1.813.404 2.74.36.86-.014 1.713-.159 2.53-.43.714-.236 1.371-.617 1.93-1.12.631-.602 1.043-1.397 1.17-2.26v-8.35c-.12-.443-.35-.85-.67-1.18-.334-.347-.74-.616-1.19-.79-.639-.242-1.317-.36-2-.35h-2.07V5.69z", fillRule: "nonzero" }))));
};
exports.default = RainbowSixIcon;
