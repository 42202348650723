import React from 'react';
import { createClient, Provider } from 'urql';
import { urqlClientConfig } from '../config';
import { hasAuthEnabled } from 'materialbet-common';

const client = createClient(urqlClientConfig);
export const GraphqlProvider: React.FunctionComponent<unknown> = ({
	children
}) => {
	return hasAuthEnabled() ? (
		<Provider value={client}>{children}</Provider>
	) : (
		<>{children}</>
	);
};
