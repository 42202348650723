import * as React from 'react';
import { Menu } from '@material-ui/core';
import {
	usePlayerWallets,
	usePlayerCurrency,
	usePlayerAuthToken
} from '@materialbet-core/player';
import { Currency, ProgressWrapper } from 'materialbet-common';
import useSetActiveCurrency from 'core/api/hooks/useSetActiveCurrency';
import { BalanceMenuItem } from './BalanceMenuItem';

export interface BalanceMenuProps {
	anchorEl: HTMLElement | null;
	onClose: () => void;
}

const anchorOrigin = {
	vertical: 'bottom',
	horizontal: 'center'
} as const;

const transformOrigin = {
	vertical: 'top',
	horizontal: 'center'
} as const;

export const BalanceMenu: React.FC<BalanceMenuProps> = ({
	anchorEl,
	onClose
}) => {
	const wallets = usePlayerWallets();
	const activeCurrency = usePlayerCurrency();
	const {
		isSubmitting,
		apiCallback: setActiveCurrency
	} = useSetActiveCurrency();
	const authToken = usePlayerAuthToken();

	const onMenuItemClickCallback = React.useCallback(
		(currency: Currency) => () => {
			setActiveCurrency({
				currency,
				authToken,
				onSuccessSideEffect: () => {
					onClose();
				}
			});
		},
		[setActiveCurrency, authToken, onClose]
	);

	return (
		<Menu
			anchorEl={anchorEl}
			open={!!anchorEl}
			onClose={onClose}
			keepMounted
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}
			getContentAnchorEl={null}
			data-testid="change-currency-menu"
		>
			<ProgressWrapper submitting={isSubmitting}>
				{Object.entries(wallets).map(
					([key, value]) =>
						key !== activeCurrency && (
							<BalanceMenuItem
								currency={key as Currency}
								key={key}
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								wallet={value!}
								onClick={onMenuItemClickCallback(
									key as Currency
								)}
								disabled={isSubmitting}
							/>
						)
				)}
			</ProgressWrapper>
		</Menu>
	);
};
