import { getPlatform } from '@gaming-shell/device';
import sportsConfig from 'sports/config/sportsapi';

import { APIVersion60Api } from './sports/apis';
import { Configuration } from './sports/runtime';

const config = new Configuration({
	basePath: sportsConfig.url,
	credentials: 'include'
});
const api = new APIVersion60Api(config);

export const getDefaultHeaders = (timeZone: string) => {
	return {
		accept: 'application/json',
		'Content-Type': 'application/json',
		origin: sportsConfig.url,
		'x-player-timezone': timeZone,
		'x-platform-v2': getPlatform(),
		'x-channel': 'WEB',
		'x-brand': BRAND_NAME
	};
};
export * from './sports/index';
export default api;
