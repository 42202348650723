import { Grid } from '@material-ui/core';
import { fillArray } from 'materialbet-common';
import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { MarketType, possibleOutcomeCount } from 'sports-sdk';
import { OutcomeProps } from 'sports/components/outcome/types';
import { RootState } from 'sports/modules/root';
import {
	getEventStatus,
	getSubmarketKeys
} from 'sports/selectors/eventSelectors';
import { makeGetFirstLine } from 'sports/selectors/selectionSelectors';
import { showEventMarkets } from 'sports/utils/event';
import {
	useMarketLevelPusherUpdate,
	useSubmarketLevelPusherUpdate
} from 'sports/utils/pusherUtils';

import { EventListVariant } from '../eventList/types';
import {
	DeferredConnectedShortOutcome,
	ShortOutcome
} from '../outcome/ShortOutcome';

export interface PriceListProps {
	eventId: number;
	marketKey: MarketType;
	variant?: EventListVariant;
	outcomes: string[];
	params: string;
	enableForceWrap?: boolean;
}
const getSize = (outcomeCount: number, variant: EventListVariant) =>
	variant === 'wide'
		? (Math.max(3, 12 / outcomeCount) as OutcomeProps['size'])
		: 12;

let LockedPriceList: React.FC<{
	count: number;
	variant: EventListVariant;
}> = ({ count, variant }) => {
	const size = getSize(count, variant);
	const outcomes = fillArray(count, (i) => (
		<ShortOutcome
			key={i}
			params=""
			size={size}
			eventId={0}
			marketKey={'' as MarketType}
			submarketKey=""
			outcome=""
		/>
	));
	return <>{outcomes}</>;
};
LockedPriceList = React.memo(LockedPriceList);
/** @description This is a workaround to the hooks rules as the amount of submarkets varies.*/
let SubmarketSubscriber: React.FC<{
	eventId: number;
	marketType: MarketType;
	submarketKey: string;
}> = ({ eventId, marketType, submarketKey }) => {
	useSubmarketLevelPusherUpdate({
		eventId,
		submarketKey,
		marketKey: marketType
	});
	return null;
};
SubmarketSubscriber = React.memo(SubmarketSubscriber);
export const PriceList: React.FunctionComponent<PriceListProps> = (props) => {
	const {
		variant = 'wide',
		marketKey,
		eventId,
		outcomes,
		params,
		enableForceWrap
	} = props;
	useMarketLevelPusherUpdate({ eventId, marketKey });
	const submarketKeys =
		useSelector(getSubmarketKeys(marketKey)(eventId)) || [];
	const subscribers = submarketKeys.map((key) => (
		<SubmarketSubscriber
			submarketKey={key}
			marketType={marketKey}
			eventId={eventId}
			key={key}
		/>
	));
	const [outcomeCount, error] = props.marketKey
		? possibleOutcomeCount(props.marketKey)
		: [0, undefined];

	if (error || !outcomeCount || outcomeCount > 3) {
		// todo send error as warning to rollbar
		return null; // without outcomecount we dont even know how to show the locked pricelist
	}
	const showMarkets = outcomes?.length;
	const size = getSize(outcomeCount, variant);
	const outcomeElements = (outcomes || []).map((outcome, index: number) => {
		return (
			<DeferredConnectedShortOutcome
				outcome={outcome}
				params={params}
				size={size}
				eventId={props.eventId as number}
				marketKey={props.marketKey}
				key={`${props.eventId}_${props.marketKey}_${index}`}
				variant={variant}
			/>
		);
	});
	return (
		<Grid
			container
			spacing={1}
			wrap={variant === 'narrow' && !enableForceWrap ? 'nowrap' : 'wrap'}
		>
			{subscribers}
			{showMarkets ? (
				outcomeElements
			) : (
				<LockedPriceList count={outcomeCount} variant={variant} />
			)}
		</Grid>
	);
};
const mapStateToProps = () => {
	const getFirstLine = makeGetFirstLine();

	return (
		state: RootState,
		props: {
			eventId: number;
			marketKey: MarketType;
		}
	) => {
		const { eventId, marketKey } = props;
		const eventStatus = getEventStatus(eventId)(state);
		const showMarkets = eventStatus && showEventMarkets(eventStatus);
		if (!showMarkets) {
			return {
				outcomes: [],
				params: ''
			};
		}
		const firstLine = getFirstLine(state, {
			eventId,
			marketType: marketKey
		});
		return {
			outcomes: firstLine?.map((s) => s.outcome) || [],
			params: firstLine?.[0]?.params || ''
		};
	};
};
export const ConnectedPriceList = connect(mapStateToProps)(PriceList);
