import { Translate, useLanguage } from '@gaming-shell/i18n';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import clx from 'classnames';
import * as React from 'react';
import { EventListVariant } from 'sports/components/eventList/types';
import { useMakeSelector } from 'sports/utils/hooks';

import {
	getMakeFormattedOutcomeName,
	SelectionIdentity
} from '../../selectors/selectionSelectors';

export interface OutcomeNameProps {
	shorten?: boolean;
	variant?: EventListVariant;
	hideOutcomeName?: boolean;
	hideOutcomeNamePrefix?: boolean;
	enableVerticalAlign?: boolean;
	simplified?: boolean;
}

const useDivStyles = makeStyles({
	root: {
		display: 'flex',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		width: ({ enableVerticalAlign }: { enableVerticalAlign?: boolean }) =>
			enableVerticalAlign ? '50%' : 'auto',
		flexDirection: ({
			enableVerticalAlign
		}: {
			enableVerticalAlign?: boolean;
		}) => (enableVerticalAlign ? 'row-reverse' : undefined)
	}
});

export const useTextStyles = makeStyles((theme: Theme) => ({
	outcomeText: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		marginRight: theme.spacing(1)
	},
	handicap: {
		margin: theme.spacing(0, 1)
	},
	rightMargin: {
		marginRight: theme.spacing(1)
	}
}));

export const OutcomeName: React.FunctionComponent<
	OutcomeNameProps & SelectionIdentity
> = (props) => {
	const {
		simplified,
		shorten,
		variant = 'wide',
		hideOutcomeName,
		hideOutcomeNamePrefix,
		enableVerticalAlign,
		eventId,
		params,
		outcome,
		marketType,
		submarketKey
	} = props;
	const language = useLanguage();
	const divClasses = useDivStyles({ enableVerticalAlign });
	const textClasses = useTextStyles();
	const selectionId = {
		eventId,
		params,
		outcome,
		marketType,
		submarketKey
	};
	const outcomeName = useMakeSelector(getMakeFormattedOutcomeName, {
		...selectionId,
		language,
		shorten
	});

	if (!outcomeName) {
		return null;
	}
	const outcomeNameClass = clx({
		[textClasses.rightMargin]:
			simplified && variant === 'wide' && outcomeName.prefix,
		[textClasses.handicap]: !simplified && outcomeName.prefix,
		[textClasses.outcomeText]: !outcomeName.prefix
	});
	const prefixClass = outcomeName.name ? textClasses.outcomeText : undefined;
	return (
		<div className={divClasses.root}>
			{!hideOutcomeNamePrefix && outcomeName.prefix && (
				<Typography
					display="inline"
					key={0}
					className={prefixClass}
					variant="body2"
				>
					{outcomeName.prefix}
				</Typography>
			)}
			{!hideOutcomeName && outcomeName.name && (
				<Typography
					display="inline"
					key={1}
					className={outcomeNameClass}
					variant="body2"
				>
					{outcomeName.name === 'Other' ? (
						<Translate label="markets.anyOther" ns="sports" />
					) : (
						outcomeName.name
					)}
				</Typography>
			)}
		</div>
	);
};
