import { Theme } from '@gaming-shell/theming';
import { makeStyles } from '@material-ui/core';

export const useSimplePreviewCardStyles = makeStyles((theme: Theme) => ({
	simplePreviewCard: {
		background: theme.palette.background.top.main,
		display: 'flex',
		flexDirection: 'column',
		borderRadius: theme.shape.borderRadius
	},
	link: {
		padding: theme.spacing(1),
		'&:hover': {
			background: 'transparent'
		}
	},
	media: {
		height: '75%'
	},
	cardContent: {
		padding: theme.spacing(1.5, 2),
		paddingBottom: `${theme.spacing(1.5)}px !important`,
		textTransform: 'none',
		height: '25%', // Get remain space of relative to image, to center the content inside
		display: 'flex',
		alignContent: 'center'
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	title: {
		lineHeight: 1.19 //Reduce line height based on design
	},
	ellipsisTextOverflow: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap'
	}
}));
