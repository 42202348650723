import { makeStyles, Theme } from '@material-ui/core/';
import { fade } from '@material-ui/core/styles';
import { Typography } from 'materialbet-common';
import * as React from 'react';
import { Translate } from '@gaming-shell/i18n';
import { ActionBarButton, ActionBarButtonProps } from '@gaming-shell/layout';

const typographyColor = {
	dark: (theme: Theme) => theme.palette.text.secondary,
	light: (theme: Theme) => fade(theme.palette.common.white, 0.74)
};

const useStyles = makeStyles((theme: Theme) => ({
	label: {
		display: 'flex',
		alignItems: 'flex-end',
		flexDirection: 'column',
		'& > *': {
			lineHeight: 1
		}
	},
	bonus: {
		fontSize: '0.65rem',
		color: typographyColor[theme.palette.type](theme)
	}
}));

export const AccountBalanceBonusAmount: React.FC<ActionBarButtonProps> = ({
	children,
	variant = 'text',
	...rest
}) => {
	const classes = useStyles();

	return (
		<ActionBarButton
			{...rest}
			variant={variant}
			aria-label="account-balance-bonus-amount"
		>
			<div className={classes.label}>
				<Typography
					variant="caption"
					textTransform="none"
					aria-label="account-balance-bonus-amount-text"
					className={classes.bonus}
				>
					<Translate label="cashier.activeBonus" ns="player" />
				</Typography>
				<Typography variant="body2" textTransform="none">
					{children}
				</Typography>
			</div>
		</ActionBarButton>
	);
};
