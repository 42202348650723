import { SportBaseInfo } from '../types';

export const baseInfo: SportBaseInfo = {
	tabs: [
		'inPlay',
		'today',
		'tomorrow',
		'competitions',
		'highlights',
		'outrights',
		'virtuals'
	],
	defaultTab: 'competitions'
};
