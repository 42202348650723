import { SearchResultsProps } from 'casino/types/sharedComponents';
import { register, SharedComponentIds } from 'shared-components';

import { CasinoAccountPromotions } from './components/CasinoAccountPromotions';
import { SearchConfig } from './components/SearchConfig';
import { SearchFilters } from './components/SearchFilters';
import { SearchResults } from './components/SearchResults';

export const registerSharedComponents = () => {
	/* unfortunately casting is required due to algolia wrapper */
	register(
		SharedComponentIds.CasinoSearchResults,
		(SearchResults as unknown) as React.FC<SearchResultsProps>
	);
	register(SharedComponentIds.CasinoSearchConfigure, SearchConfig);
	register(SharedComponentIds.CasinoSearchFilters, SearchFilters);
	register(
		SharedComponentIds.CasinoAccountPromotions,
		CasinoAccountPromotions
	);
};
