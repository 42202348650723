import * as React from 'react';
import {
	ButtonLink,
	ButtonLinkProps,
	withHook,
	CasinoRoutes
} from 'materialbet-common';
import { useParams } from 'react-router';
import { getGame } from 'casino/selectors/gamesSelectors';
import { useSelector } from 'react-redux';

import { categoryIcons } from '../CategoryIcon/CategoryIcon';

export type PlayRoute = typeof CasinoRoutes.legacyGame;
export interface PlayButtonProps extends ButtonLinkProps {
	gameId?: string;
}

// don't let gameId to be passed down as it lands into the DOM element as attribute
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PlayButtonWrapper: React.FC<PlayButtonProps> = ({ gameId, ...props }) => (
	<ButtonLink {...props} />
);

export const createPlayButtonProps = (route: PlayRoute) => (
	props: Omit<PlayButtonProps, 'href'>
) => {
	const urlParams = useParams<{ gameId?: string }>();
	const gameId = props.gameId || urlParams.gameId;
	const game = useSelector(getGame(gameId));
	const category =
		game?.type ||
		game?.tags?.find((tag: string) => categoryIcons.hasOwnProperty(tag));
	const gameKey = game?.game_key;

	return {
		href:
			gameId &&
			category &&
			gameKey &&
			route.create({
				gameId,
				category,
				gameKey
			})
	};
};

export const PlayButton = withHook(
	createPlayButtonProps(CasinoRoutes.legacyGame)
)(PlayButtonWrapper);
export const FreePlayButton = withHook(
	createPlayButtonProps(CasinoRoutes.legacyFreeplayGame)
)(PlayButtonWrapper);
