import { Translate } from '@gaming-shell/i18n';
import { SideMenuItem } from '@gaming-shell/layout';
import { SectionIcon } from 'casino/components/SectionIcon';
import { camelize } from 'casino/util/camelcase';
import {
	getSectionRoute,
	useIsLiveRoute,
	useRouteCategorySlug
} from 'casino/util/routes';
import { withHook } from 'materialbet-common';
import * as React from 'react';

export interface CasinoSideMenuSectionItemProps {
	categorySlug: string;
	active: boolean;
	live: boolean;
}
let CasinoSideMenuSectionItemUi: React.FC<CasinoSideMenuSectionItemProps> = ({
	categorySlug,
	active,
	live
}) => {
	if (!categorySlug) {
		return null;
	}
	return (
		<SideMenuItem
			key={categorySlug}
			icon={<SectionIcon iconKey={categorySlug} />}
			label={
				<Translate
					label={`casino.games.categories.${camelize(categorySlug)}`}
				/>
			}
			href={getSectionRoute(categorySlug, live)}
			active={active}
			variant="subtitle2"
		/>
	);
};
CasinoSideMenuSectionItemUi = React.memo(CasinoSideMenuSectionItemUi);
export { CasinoSideMenuSectionItemUi };
export const useCasinoSideMenuSectionItem = ({
	live,
	categorySlug
}: Pick<CasinoSideMenuSectionItemProps, 'live' | 'categorySlug'>) => {
	const routeSlug = useRouteCategorySlug();

	const isLiveRoute = useIsLiveRoute();
	return { active: isLiveRoute === live && categorySlug === routeSlug };
};
export const CasinoSideMenuSectionItem = withHook(useCasinoSideMenuSectionItem)(
	CasinoSideMenuSectionItemUi
);
