export interface StartLoadingMeta {
	initiatedAt: number;
	name: string;
}
export interface FinishLoadingMeta {
	receivedAt: number;
	name: string;
}
export type LoadingMeta = Array<StartLoadingMeta | FinishLoadingMeta>;
export const LoadingMetaKey = 'loading';
export interface Loading {
	initiatedAt?: number;
	receivedAt?: number;
	isLoading: boolean;
}
export interface LoadingState {
	[name: string]: Loading;
}
export interface MetaPayload {
	[LoadingMetaKey]?: LoadingMeta;
}
export const loadingReducer = (
	state: LoadingState = {},
	action: { meta?: object }
) => {
	if (action.meta && Array.isArray(action.meta[LoadingMetaKey])) {
		const newLoads: LoadingMeta = action.meta[LoadingMetaKey];
		const newLoading = newLoads.reduce<LoadingState>((loading, load) => {
			const { name, ...newLoad } = load;
			if (!name) {
				return loading;
			}
			const isLoading = !!(newLoad as StartLoadingMeta).initiatedAt;
			loading[name] = { ...state[name], ...newLoad, isLoading };
			return loading;
		}, {});
		return { ...state, ...newLoading };
	}

	return state;
};

export type LoadingSelector<T> = (state: T) => LoadingState;

export const createIsLoadingSelector = <T>(
	loadingSelector: LoadingSelector<T>
) => (name: string) => (state: T) => {
	const loading = loadingSelector(state)[name];
	return loading && loading.isLoading;
};
export const createLoadedOnceSelector = <T extends string>(
	loadingSelector: LoadingSelector<T>
) => (name: string) => (state: T) => {
	const loading = loadingSelector(state)[name];
	return loading && loading.receivedAt;
};

export const startLoading = (...names: string[]) =>
	({
		[LoadingMetaKey]: names.map<StartLoadingMeta>((name) => ({
			name,
			initiatedAt: new Date().getTime()
		}))
	} as MetaPayload);
export const finishLoading = (...names: string[]) =>
	({
		[LoadingMetaKey]: names.map<FinishLoadingMeta>((name) => ({
			name,
			receivedAt: new Date().getTime()
		}))
	} as MetaPayload);
