import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import {
	eventsEpic,
	eventsReducer as events
} from 'sports/modules/eventsModule';
import { StateType } from 'typesafe-actions';

import {
	eventPromotionsEpic,
	eventPromotionsReducer
} from './eventPromotionsModule';
import loading from './loading';
import sports, { sportsEpic } from './sports';
import betsHistoryResponses, {
	betsHistoryResponsesEpic
} from './sports/betsHistoryResponses';
import categories from './sports/categories';
import competitions from './sports/competitions';
import livesport365, { livesport365Epic } from './sports/livesport365';
import sportsMeta from './sports/sportsMeta';
import topCoupons, { topCouponsEpic } from './sports/topCoupons';
import sportsPlayer, { sportsPlayerEpic } from './sportsplayer';

export const rootEpic = combineEpics(
	sportsEpic,
	eventsEpic,
	eventPromotionsEpic,
	sportsPlayerEpic,
	betsHistoryResponsesEpic,
	livesport365Epic,
	topCouponsEpic
);
export const rootReducer = combineReducers({
	events,
	eventPromotions: eventPromotionsReducer,
	categories,
	competitions,
	topCoupons,
	sportsPlayer,
	sports,
	loading,
	betsHistoryResponses,
	sportsMeta,
	livesport365
});

export type RootState = StateType<typeof rootReducer>;
export type SportsState = RootState;
