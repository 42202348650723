import { Translate } from '@gaming-shell/i18n';
import { Theme, makeStyles } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AuthRoutes } from 'materialbet-common';
import * as React from 'react';
import { RedirectLoggedOutUserButton } from 'sports/components/util/RedirectLoggedOutUserButton';

import { getIsSubmitting } from '../betslipSelectors';
import { useBetslipSelector } from '../hooks/useBetslipSelector';
import { BigBetslipActionPanelButton } from './BigBetslipActionPanelButton';

export interface BigBetslipPlaceBetButtonProps extends ButtonProps {
	onPlaceBet: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
	button: {
		marginTop: theme.spacing(2)
	}
}));
export const BigBetslipPlaceBetButton: React.FunctionComponent<BigBetslipPlaceBetButtonProps> = (
	props
) => {
	const { onPlaceBet, ...other } = props;
	const classes = useStyles();
	const isSubmitting = useBetslipSelector(getIsSubmitting);
	return (
		<RedirectLoggedOutUserButton
			ButtonComponent={BigBetslipActionPanelButton}
			to={AuthRoutes.signIn.create()}
			color="secondary"
			className={classes.button}
			onClick={onPlaceBet}
			disabled={isSubmitting}
			{...other}
		>
			{isSubmitting ? (
				<CircularProgress variant="indeterminate" size={24} />
			) : (
				<Translate label="descriptions.betPlaced" ns="sports" />
			)}
		</RedirectLoggedOutUserButton>
	);
};
