import {
	GetGamesResponse,
	GetRolloverBonusesResponse,
	GetSectionsResponse,
	GetStudiosResponse
} from 'casino/api/casino';
import {
	CasinoMeta,
	CasinoMetaKey,
	CasinoMetaKeys
} from 'casino/modules/metaModule';
import { LoadingMeta, LoadingMetaKey } from 'materialbet-common';

import { GetFreespinsResponse } from '../api/casino';

export interface CasinoMetaPayload {
	[LoadingMetaKey]?: LoadingMeta;
	[CasinoMetaKey]?: CasinoMeta;
}
export interface CasinoKeyValue<
	TKey extends CasinoMetaKeys,
	TValue extends CasinoMeta[TKey]
> {
	key: TKey;
	value: TValue;
}
export const casinoMeta = (meta: CasinoMeta) => ({
	[CasinoMetaKey]: meta
});
/** @description Alows autocomplete for casinoMeta keys, sadly only supports metas with same type right now (Typescript limitation) */
export const casinoMetas = <
	TKey extends CasinoMetaKeys,
	TValue extends CasinoMeta[TKey]
>(
	...metas: Array<CasinoKeyValue<TKey, TValue> | undefined>
) => ({
	[CasinoMetaKey]: metas.reduce((prev, meta) => {
		if (!meta) {
			return prev;
		}
		prev[meta.key] = meta.value;
		return prev;
	}, {} as CasinoMeta)
});

export const createSectionsMeta = (
	response?: GetSectionsResponse,
	key?: string
): CasinoMetaPayload => {
	if (!response?.sections || !key) {
		return {};
	}
	const ids = response.sections.map<string>((s) => s.id || '');
	const meta = casinoMetas({ key: 'sections', value: { [key]: ids } });
	return meta;
};
export const createGamesMeta = (
	response?: GetGamesResponse,
	key?: string
): CasinoMetaPayload => {
	if (!response?.list || !key) {
		return {};
	}
	const list = response.list.map<string>((g) => g.id || '');
	const gameCount = response.total;
	const meta = casinoMetas(
		{ key: 'gameLists', value: { [key]: list } },
		gameCount !== undefined
			? { key: 'gameCounts', value: { [key]: gameCount } }
			: undefined
	);
	return meta;
};

export const createStudiosMeta = (
	response?: GetStudiosResponse,
	key?: string
): CasinoMetaPayload => {
	if (!response?.list || !key) {
		return {};
	}
	const list = response.list.map((studio) => studio.key || '');
	const meta = casinoMetas({ key: 'studios', value: { [key]: list } });
	return meta;
};
export const createFreespinsMeta = (
	response?: GetFreespinsResponse,
	key?: string
): CasinoMetaPayload => {
	if (!response?.freespins_offers || !key) {
		return {};
	}
	const list = response.freespins_offers.map(
		(freespin) => freespin.uuid || ''
	);
	const meta = casinoMetas({ key: 'freespins', value: { [key]: list } });
	return meta;
};
export const createRolloverBonusesMeta = (
	response?: GetRolloverBonusesResponse,
	key?: string
): CasinoMetaPayload => {
	if (!response?.rollover_bonuses || !key) {
		return {};
	}
	const list = response.rollover_bonuses.map((bonus) => bonus.id || '');
	const meta = casinoMetas({
		key: 'rolloverBonuses',
		value: { [key]: list }
	});
	return meta;
};
