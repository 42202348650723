"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterValid = exports.filterLocation = exports.sortAnnouncements = void 0;
var materialbet_common_1 = require("materialbet-common");
var importanceToPriorityMap = (_a = {},
    _a[materialbet_common_1.AnnouncementImportance.CRITICAL] = 0,
    _a[materialbet_common_1.AnnouncementImportance.WARNING] = 1,
    _a[materialbet_common_1.AnnouncementImportance.NOTICE] = 2,
    _a[materialbet_common_1.AnnouncementImportance.INFO] = 3,
    _a);
exports.sortAnnouncements = function (a, b) {
    return importanceToPriorityMap[a.importance] -
        importanceToPriorityMap[b.importance];
};
var sitePathToRouteMap = {
    account: materialbet_common_1.PlayerRoutes,
    casino: materialbet_common_1.CasinoRoutes,
    sports: materialbet_common_1.SportsRoutes
};
exports.filterLocation = function (announcement) {
    if (!announcement.sitePath)
        return true;
    var route = sitePathToRouteMap[announcement.sitePath];
    return route.root.matches();
};
exports.filterValid = function (announcement) {
    var now = Date.now();
    return (announcement.validFrom * 1000 <= now &&
        now <= announcement.validTo * 1000);
};
