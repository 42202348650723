import { isDesktop } from '@gaming-shell/device';

export * from './gameTags';
export default {
	baseApiUrl: `${BASE_API_URL}/casino-api`,
	launchBase: BASE_API_URL,
	gamesApiDefault: {
		fetchPageSize: 20,
		// maxPage and total will not be called until api doesn't return this value
		maxPage: 1,
		total: 20
	},
	/**
	 * this number highly affect lobby page render performance ( Carousel )
	 * 24 is desktop game tile number 8*3
	 * 10 is mobile game tile number 2*5
	 * */
	gameRowApiDefaultPageSizeDesktop: 24,
	gameRowApiDefaultPageSizeMobile: 10,
	defaultGameTileImageDimension: {
		width: isDesktop() ? 600 : 520,
		height: isDesktop() ? 460 : 396
	},
	defaultGameRatio: '16:9',
	inGameSidemenuWidth: 260
};
