import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

export interface SelectionAdditionalInfoLineProps {
	info: string;
}
const useStyles = makeStyles((theme) => ({
	root: {
		fontSize: '12px', //harcode here to be very small
		lineHeight: '16px',
		color: theme.palette.text.secondary
	}
}));
export const SelectionAdditionalInfoLine: React.FC<SelectionAdditionalInfoLineProps> = ({
	info
}) => {
	const classes = useStyles();
	if (!info) {
		return null;
	}
	return (
		<Typography className={classes.root} variant="body2">
			{info}
		</Typography>
	);
};
