import { makeStyles } from '@material-ui/core/styles';
import { MutableRefObject } from 'react';
import * as React from 'react';

import { defaultIframeRatio } from '../../configs/ratio';
import { useElementDimensionsResize } from '../../utils/dimensionsUtils';
import { getIframeDimensions } from '../../utils/iframeUtils';

const useDimensionsStyles = makeStyles({
	root: ({ height, width }: { height: string; width: string }) => ({
		width,
		height
	})
});

interface IframeAreaParams {
	ref: MutableRefObject<HTMLDivElement | null>;
	ratio?: string;
	cinema?: boolean;
	fullscreen?: boolean;
}

export const useIframeStyles = ({
	ref, // The widget ref
	ratio = defaultIframeRatio,
	fullscreen,
	cinema
}: IframeAreaParams) => {
	// Get Elements
	const area = ref?.current;
	const parent = area?.parentElement;
	const {
		width: parentWidth,
		height: parentHeight
	} = useElementDimensionsResize(parent);
	const [, triggerChangeMode] = React.useState('normal');
	// Hooks For setting cinema
	React.useEffect(() => {
		triggerChangeMode(
			fullscreen ? 'fullscreen' : cinema ? 'cinema' : 'normal'
		);
	}, [cinema, fullscreen]);
	const iframeDimensions = React.useMemo(
		() =>
			getIframeDimensions({
				availableHeight: fullscreen ? window.innerHeight : parentHeight,
				availableWidth: fullscreen ? window.innerWidth : parentWidth,
				ratio
			}),
		[ratio, fullscreen, parentHeight, parentWidth]
	);
	return useDimensionsStyles(iframeDimensions);
};
