import { warning } from '@gaming-shell/logging';
import {
	PlaceBetStatus,
	PlaceBetV2Bet,
	PlaceBetV2Selection
} from 'sports/api/sportsbetting/api';
import { subtractBits } from 'sports/utils/bitmaskUtils';
import { getMarketUrl } from 'sports/utils/market';

import {
	BetslipReducerBetslip,
	BetslipReducerSelection,
	BetslipReducerStatus,
	BetslipState,
	BetslipUpdateSelectionPayload,
	SupportedSystem
} from '../types';
import { BetslipStatusFlags, getBetslipStatusFlag } from './BetslipStatusFlags';

export const statusIncludes = (status: number, otherStatus: number) =>
	(status & otherStatus) > 0;
export const isSameSelection = (
	s: BetslipUpdateSelectionPayload,
	selection: BetslipUpdateSelectionPayload
) =>
	s.eventId === selection.eventId &&
	s.marketKey === selection.marketKey &&
	s.outcome === selection.outcome &&
	s.params === selection.params;

/** @description Adds a selection to the given array, will replace selection if of same market but different outcome. Will do nothing if selection already exists */
export const addSelectionToSelections = (
	selectionsDraft: BetslipReducerSelection[],
	selection: BetslipReducerSelection
) => {
	const sameMarketSelectionIndex = selectionsDraft.findIndex(
		(s) =>
			s.eventId === selection.eventId &&
			s.marketKey === selection.marketKey &&
			s.params === selection.params
	);
	if (sameMarketSelectionIndex < 0) {
		selectionsDraft.push(selection);
	} else {
		const sameMarketSelection = selectionsDraft[sameMarketSelectionIndex];
		const isSameSelection =
			sameMarketSelection.outcome === selection.outcome;
		if (!isSameSelection) {
			selectionsDraft[sameMarketSelectionIndex] = selection;
		}
	}
};
export const addStatusInObject = (
	draft: { status: BetslipReducerStatus },
	status: BetslipReducerStatus
) => {
	draft.status = draft.status | status;
};
export const removeStatusInObject = (
	draft: { status: BetslipReducerStatus },
	status: BetslipReducerStatus
) => (draft.status = subtractBits(draft.status, status));
/** @description sets Price above market status in selection */
/** @description sets all selection and multiple status to valid, keeps change status as is */
export const clearBetslipStatus = (draft: BetslipReducerBetslip) => {
	draft.selections.forEach((s) => {
		s.status = BetslipStatusFlags.Default;
	});
	Object.values(draft.multiples).forEach((m) => {
		m.status = BetslipStatusFlags.Default;
	});
};
export const setChangeStatusInUpdate = (
	draft: BetslipState,
	update: BetslipUpdateSelectionPayload
) => {
	const selection = draft.betslip.selections.find((s) =>
		isSameSelection(s, update)
	);
	if (!selection) {
		return;
	}
	if (
		(update.price && selection.price > update.price) ||
		(!draft.acceptBetterOdds &&
			update.price &&
			selection.price < update.price)
	) {
		addStatusInObject(update, BetslipStatusFlags.PriceAboveMarket);
	}
};
export const removeSelectionFromSelections = (
	selectionsDraft: BetslipReducerSelection[],
	index: number
) => {
	const selection = selectionsDraft[index];
	selectionsDraft.splice(index, 1);
	return selection;
};
/** @returns Will return the updated selection, or undefined if selection was not found */
export const updateSelectionInSelections = (
	selectionsDraft: BetslipReducerSelection[],
	selection: BetslipUpdateSelectionPayload
) => {
	const draft = selectionsDraft.find((s) => isSameSelection(s, selection));
	if (!draft) {
		return undefined;
	}
	Object.assign(draft, selection);
	return draft;
};
type EventIdToSelectionIndex = Record<string, number>;

export const validateCorrelatedSelections = (
	selectionsDraft: BetslipReducerSelection[]
) => {
	const eventIdToSelectionIndex: EventIdToSelectionIndex = {};
	let isRestricted = false;
	selectionsDraft.forEach((s, i) => {
		const { eventId } = s;
		removeStatusInObject(s, BetslipStatusFlags.StakeAboveMax);
		removeStatusInObject(s, BetslipStatusFlags.StakeBelowMin);
		const selectionIndex = eventIdToSelectionIndex[eventId];
		if (selectionIndex !== undefined) {
			addStatusInObject(
				selectionsDraft[selectionIndex],
				BetslipStatusFlags.CorrelatedSelections
			);
			addStatusInObject(s, BetslipStatusFlags.CorrelatedSelections);
			isRestricted = true;
		} else {
			removeStatusInObject(s, BetslipStatusFlags.CorrelatedSelections);
		}
		eventIdToSelectionIndex[eventId] = i;
	});
	return isRestricted;
};
export const validateStakeAgainstSelection = (draft: {
	stake: number;
	minStake: number;
	maxStake: number;
	status: BetslipReducerStatus;
}) => {
	const { stake, maxStake, minStake } = draft;
	removeStatusInObject(draft, BetslipStatusFlags.StakeValidationStatus);
	if (stake < minStake) {
		addStatusInObject(draft, BetslipStatusFlags.StakeBelowMin);
		return;
	}
	if (stake > maxStake) {
		addStatusInObject(draft, BetslipStatusFlags.StakeAboveMax);
		return;
	}
	return;
};
export const validateStakeAgainstFunds = (
	stakeObject: { stake: number; status: BetslipReducerStatus },
	funds: number
) => {
	if (stakeObject.stake > funds) {
		addStatusInObject(stakeObject, BetslipStatusFlags.InsufficientFund);
	} else {
		removeStatusInObject(stakeObject, BetslipStatusFlags.InsufficientFund);
	}
};
/** @returns The updated selection. If stake is not update (because its the same or the selection is not found), returns `undefined` */
export const setSelectionStakeInSelections = (
	selectionsDraft: BetslipReducerSelection[],
	selectionIndex: number,
	stake: number
) => {
	const draft = selectionsDraft[selectionIndex];
	if (!draft || draft.stake === stake) {
		return undefined;
	}
	draft.stake = stake;
	return draft;
};

export const setPlaceBetStatusInObject = (
	draft: { status?: BetslipReducerStatus },
	status?: PlaceBetStatus
) => {
	if (
		status &&
		status !== 'VALID' &&
		status !== 'ACCEPTED' &&
		status !== 'PENDING_ACCEPTANCE'
	) {
		draft.status = getBetslipStatusFlag(status);
	} else {
		draft.status = BetslipStatusFlags.Default;
	}
};

export const setPlaceBetSelectionsInSelections = (
	selectionsDraft: BetslipReducerSelection[],
	placeBetSelections: PlaceBetV2Selection[]
) => {
	placeBetSelections.forEach((selection, i) => {
		const selectionDraft = selectionsDraft[i];
		if (!selectionDraft) {
			warning(
				`receive more selections from placebet response then originally in betslip. \n PlaceBetSelections:\n${JSON.stringify(
					placeBetSelections
				)}\n Betslip: \n${JSON.stringify(selectionsDraft)}`
			);
			return;
		}
		const isSameSelection =
			getMarketUrl(selectionDraft) === selection.marketUrl &&
			selectionDraft.eventId.toString() === selection.eventId;
		if (!isSameSelection) {
			warning(
				`selection received from place bet response does not match the one in the current betslip at index ${i}. \n PlaceBetSelections:\n${JSON.stringify(
					placeBetSelections
				)}\n Betslip: \n${JSON.stringify(selectionsDraft)}`
			);
			return;
		}
		selectionDraft.price = parseFloat(selection.price);

		setPlaceBetStatusInObject(selectionDraft, selection.status);
	});
};
export const createChanges = (betslip: BetslipReducerBetslip) => {
	const string = JSON.stringify(betslip);
	const copy: BetslipReducerBetslip = JSON.parse(string);
	clearBetslipStatus(copy);
	return copy;
};
export const validateStraight = (draft: BetslipState, funds: number) => {
	let totalStraightStake = 0;
	draft.betslip.selections.forEach((s) => {
		validateStakeAgainstSelection(s);
		totalStraightStake += s.stake;
		validateStakeAgainstFunds(s, funds);
	});
	if (totalStraightStake > funds) {
		addStatusInObject(draft, BetslipStatusFlags.InsufficientFund);
	} else {
		removeStatusInObject(draft, BetslipStatusFlags.InsufficientFund);
	}
};

/** @returns added status of each selection */
export const setStakeInSelections = (draft: BetslipState, stake: number) => {
	const selections = draft.betslip.selections;
	return selections.map((s, i) => {
		s.stake = stake;
		if (draft.changes) {
			draft.changes.selections[i].stake = stake;
		}
		return validateStakeAgainstSelection(s);
	});
};

export const setMaxStakeInSelections = (
	selectionDrafts: BetslipReducerSelection[]
) => {
	return selectionDrafts.map((draft) => {
		draft.stake = draft.maxStake;
		return validateStakeAgainstSelection(draft);
	});
};
export const validateMultiples = (draft: BetslipState, funds: number) => {
	validateCorrelatedSelections(draft.betslip.selections);
	let totalMultipleStake = 0;
	Object.keys(draft.betslip.multiples).map((k) => {
		const multiple = draft.betslip.multiples[k];
		const status = validateStakeAgainstFunds(multiple, funds);
		totalMultipleStake += multiple.stake;
		return status;
	});
	if (totalMultipleStake > funds) {
		addStatusInObject(draft, BetslipStatusFlags.InsufficientFund);
	}
};

export const revalidateChangesExistence = (draft: BetslipState) => {
	if (!draft.changes) {
		return;
	}
	const hasSelectionChanges = draft.changes.selections.some(
		(s) => s.status !== BetslipStatusFlags.Default
	);
	if (!hasSelectionChanges) {
		delete draft.changes;
	}
};
export const removeIvalidSelectionsFromSelections = (draft: BetslipState) => {
	const removedIndices = new Set<number>();

	draft.betslip.selections = draft.betslip.selections.filter((s, i) => {
		const remove = statusIncludes(
			BetslipStatusFlags.CanBeRemoved,
			s.status
		);
		if (remove) {
			removedIndices.add(i);
		}
		return !remove;
	});

	if (draft.changes && removedIndices.size) {
		draft.changes.selections = draft.changes.selections.filter(
			(_, i) => !removedIndices.has(i)
		);
	}
	revalidateChangesExistence(draft);
};
export const getTotalStatus = (array: { status: BetslipStatusFlags }[]) =>
	array.reduce((status, e) => status | e.status, BetslipStatusFlags.Default);

export const getBetslipIndexToPlaceBetMap = (bets: PlaceBetV2Bet[]) => {
	const mapping = bets.reduce<{
		selections: Record<number, number>;
		multiple: Partial<Record<SupportedSystem, number>>;
	}>(
		(acc, bet, i) => {
			if (bet.multiple) {
				acc.multiple[bet.multiple.systemValue] = i;
				return acc;
			}
			if (bet.straight) {
				acc.selections[bet.straight.selectionIndex] = i;
				return acc;
			}
			return acc;
		},
		{ selections: {}, multiple: {} }
	);
	return mapping;
};
