"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMemoryStatus = void 0;
exports.getMemoryStatus = function (initialMemoryStatus) {
    var memoryStatus = navigator === null || navigator === void 0 ? void 0 : navigator.deviceMemory;
    var unsupported = memoryStatus === undefined;
    if (unsupported) {
        return __assign({ unsupported: unsupported }, initialMemoryStatus);
    }
    var performanceMemory = performance.memory
        ? performance.memory
        : undefined;
    return {
        unsupported: unsupported,
        deviceMemory: navigator.deviceMemory,
        totalJSHeapSize: performanceMemory === null || performanceMemory === void 0 ? void 0 : performanceMemory.totalJSHeapSize,
        usedJSHeapSize: performanceMemory === null || performanceMemory === void 0 ? void 0 : performanceMemory.usedJSHeapSize,
        jsHeapSizeLimit: performanceMemory === null || performanceMemory === void 0 ? void 0 : performanceMemory.jsHeapSizeLimit
    };
};
