"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isZendeskAvailable = exports.initialize = exports.zendesk = void 0;
var getZeSettings = function (color) { return ({
    webWidget: {
        color: {
            theme: color,
            launcher: color,
            button: color,
            resultLists: color,
            header: color
        }
    }
}); };
var zendeskQueue = [];
exports.zendesk = function (arg1, arg2, arg3) {
    if (!window.zE) {
        zendeskQueue.push([arg1, arg2, arg3]);
        return;
    }
    if (zendeskQueue.length) {
        zendeskQueue.forEach(function (a) { var _a; return (_a = window.zE) === null || _a === void 0 ? void 0 : _a.call(window, a[0], a[1], a[2]); });
        zendeskQueue.length = 0;
    }
    window.zE(arg1, arg2, arg3);
};
exports.initialize = function (zendeskKey, color) {
    var nodeId = 'ze-snippet';
    var nodeSrc = "https://static.zdassets.com/ekr/snippet.js?key=" + zendeskKey;
    var initNode = document.createElement('script');
    initNode.onload = function () {
        exports.zendesk('webWidget', 'hide');
    };
    initNode.async = true;
    initNode.defer = true;
    initNode.src = nodeSrc;
    initNode.id = nodeId;
    var firstScriptNode = document.getElementsByTagName('script')[0];
    var parent = firstScriptNode.parentNode;
    if (parent) {
        window.zESettings = getZeSettings(color);
        parent.insertBefore(initNode, null);
    }
};
exports.isZendeskAvailable = function () { return !!window.zE; };
