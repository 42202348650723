"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePlayerIsCasinoPlayer = exports.usePlayerOnboardingStepsStatuses = exports.usePlayerKycDetails = exports.usePlayerIsOnboarded = exports.usePlayerCurrencyMinDepositAmount = exports.usePlayerMinDepositAmounts = exports.usePlayerDepositAddress = exports.useFormattedPlayerWalletAmount = exports.usePlayerLocale = exports.usePlayerDepositAddresses = exports.usePlayerTimeZone = exports.usePlayerPreferredSportsView = exports.usePlayerPreferredOddsFormat = exports.usePlayerLastBetCreatedAt = exports.usePlayerForcedRoutes = exports.usePlayerId = exports.usePlayerNickname = exports.usePlayerEmail = exports.usePlayerAuthToken = exports.usePlayerChannel = exports.usePlayerLoading = exports.usePlayerLoggedIn = exports.usePlayerUuid = exports.usePlayerWallet = exports.usePlayerBonusDetails = exports.usePlayerWallets = exports.usePlayerCurrenciesAvailable = exports.usePlayerCurrencyVariant = exports.usePlayerCurrency = exports.usePlayerIpCountry = exports.useUpdatePlayer = exports.usePlayer = void 0;
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var PlayerProviders_1 = require("./PlayerProviders");
var materialbet_player_1 = require("materialbet-player");
exports.usePlayer = materialbet_common_1.hasAuthEnabled()
    ? materialbet_player_1.usePlayer
    : function () { return React.useContext(PlayerProviders_1.PlayerContext); };
exports.useUpdatePlayer = function () { return React.useContext(PlayerProviders_1.PlayerUpdateContext); };
exports.usePlayerIpCountry = materialbet_common_1.hasAuthEnabled()
    ? materialbet_player_1.usePlayerIpCountry
    : function () { return React.useContext(PlayerProviders_1.PlayerContext).ipCountry; };
exports.usePlayerCurrency = function () { return React.useContext(PlayerProviders_1.PlayerCurrencyContext); };
exports.usePlayerCurrencyVariant = function () {
    return React.useContext(PlayerProviders_1.PlayerCurrencyVariantContext);
};
exports.usePlayerCurrenciesAvailable = function () {
    return React.useContext(PlayerProviders_1.PlayerCurrenciesAvailableContext);
};
exports.usePlayerWallets = function () { return React.useContext(PlayerProviders_1.PlayerWalletsContext); };
exports.usePlayerBonusDetails = function () {
    return React.useContext(PlayerProviders_1.PlayerBonusDetailsContext);
};
exports.usePlayerWallet = function () {
    var currency = exports.usePlayerCurrency();
    var _a = exports.usePlayerWallets(), _b = currency, currencyWallet = _a[_b];
    return currencyWallet || PlayerProviders_1.defaultWallet;
};
exports.usePlayerUuid = function () { return React.useContext(PlayerProviders_1.PlayerUuidContext); };
exports.usePlayerLoggedIn = function () { return React.useContext(PlayerProviders_1.PlayerLoggedInContext); };
exports.usePlayerLoading = function () { return React.useContext(PlayerProviders_1.PlayerLoadingContext); };
exports.usePlayerChannel = function () { return React.useContext(PlayerProviders_1.PlayerChannelContext); };
exports.usePlayerAuthToken = function () {
    return React.useContext(PlayerProviders_1.PlayerAuthTokenContext);
};
exports.usePlayerEmail = function () { return React.useContext(PlayerProviders_1.PlayerEmailContext); };
exports.usePlayerNickname = materialbet_common_1.hasAuthEnabled()
    ? materialbet_player_1.usePlayerNickname
    : function () { return React.useContext(PlayerProviders_1.PlayerNicknameContext); };
exports.usePlayerId = function () { return React.useContext(PlayerProviders_1.PlayerIdContext); };
exports.usePlayerForcedRoutes = function () {
    return React.useContext(PlayerProviders_1.PlayerForcedRoutesContext);
};
exports.usePlayerLastBetCreatedAt = function () {
    return React.useContext(PlayerProviders_1.PlayerLastBetCreatedAtContext);
};
exports.usePlayerPreferredOddsFormat = function () {
    return React.useContext(PlayerProviders_1.PlayerPreferredOddsFormatContext);
};
exports.usePlayerPreferredSportsView = function () {
    return React.useContext(PlayerProviders_1.PlayerPreferredSportsViewContext);
};
exports.usePlayerTimeZone = materialbet_common_1.hasAuthEnabled()
    ? materialbet_player_1.usePlayerTimeZone
    : function () { return React.useContext(PlayerProviders_1.PlayerTimeZoneContext); };
exports.usePlayerDepositAddresses = function () {
    return React.useContext(PlayerProviders_1.PlayerDepositAddressesContext);
};
exports.usePlayerLocale = materialbet_common_1.hasAuthEnabled()
    ? materialbet_player_1.usePlayerLocale
    : function () { return React.useContext(PlayerProviders_1.PlayerLocaleContext); };
exports.useFormattedPlayerWalletAmount = function (options) {
    var amount = exports.usePlayerWallet().amount;
    var currency = exports.usePlayerCurrency();
    var currencyAmount = materialbet_common_1.formatCurrency(amount, currency, options);
    return currencyAmount;
};
exports.usePlayerDepositAddress = function (currency) {
    var activeCurrency = exports.usePlayerCurrency();
    var depositAddresses = exports.usePlayerDepositAddresses();
    return depositAddresses[currency || activeCurrency];
};
exports.usePlayerMinDepositAmounts = function () {
    return React.useContext(PlayerProviders_1.PlayerMinDepositAmountsContext);
};
exports.usePlayerCurrencyMinDepositAmount = function (currency) {
    var minDepositAmounts = exports.usePlayerMinDepositAmounts();
    return minDepositAmounts[currency];
};
exports.usePlayerIsOnboarded = function () {
    var onboardingStatuses = React.useContext(PlayerProviders_1.PlayerForcedRoutesContext).onboardingStatuses;
    var isOnboarded = Object.values(onboardingStatuses).every(function (status) { return status; });
    return isOnboarded;
};
exports.usePlayerKycDetails = function () {
    return React.useContext(PlayerProviders_1.PlayerKycDetailsContext);
};
exports.usePlayerOnboardingStepsStatuses = function () {
    return React.useContext(PlayerProviders_1.PlayerOnboardingStepsStatusesContext);
};
exports.usePlayerIsCasinoPlayer = function () {
    var lastSportsBetCreatedAt = exports.usePlayerLastBetCreatedAt();
    return !lastSportsBetCreatedAt;
};
