"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHandleError = void 0;
var i18n_1 = require("@gaming-shell/i18n");
var routing_1 = require("@gaming-shell/routing");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
exports.useHandleError = function () {
    var enqueueSnackbar = materialbet_common_1.useSnackbar().enqueueSnackbar;
    var trans = i18n_1.useTranslation();
    var genericErrorHandling = React.useCallback(function (redirect) {
        enqueueSnackbar(trans('common:application.descriptions.generalError'));
        redirect && routing_1.history.push(materialbet_common_1.BaseRoutes.root.create());
    }, [enqueueSnackbar, trans]);
    var restrictedCountryHandling = React.useCallback(function (redirect) {
        enqueueSnackbar(trans('player:signIn.restrictedCountry'));
        redirect && routing_1.history.push(materialbet_common_1.BaseRoutes.root.create());
    }, [enqueueSnackbar, trans]);
    var emailMissingHandling = React.useCallback(function (redirect) {
        enqueueSnackbar(trans('player:signUp.SSOEmailAccessDenied'));
        redirect && routing_1.history.push(materialbet_common_1.AuthRoutes.signUp.create());
    }, [enqueueSnackbar, trans]);
    var selfExclusionHandling = React.useCallback(function (redirect) {
        enqueueSnackbar(trans('player:signIn.selfExclusion'));
        redirect && routing_1.history.push(materialbet_common_1.AuthRoutes.signIn.create());
    }, [enqueueSnackbar, trans]);
    var errorHandling = React.useMemo(function () { return ({
        GENERIC_ERROR: genericErrorHandling,
        RESTRICTED_COUNTRY: restrictedCountryHandling,
        COUNTRY_BLOCKED: restrictedCountryHandling,
        EMAIL_MISSING: emailMissingHandling,
        SELF_EXCLUSION: selfExclusionHandling
    }); }, [
        genericErrorHandling,
        restrictedCountryHandling,
        emailMissingHandling,
        selfExclusionHandling
    ]);
    var handleError = function (error, options) {
        var errorHandlingFunction = errorHandling[error];
        var redirect = options && options.redirect;
        errorHandlingFunction
            ? errorHandlingFunction(redirect)
            : redirect && routing_1.history.push(materialbet_common_1.BaseRoutes.root.create());
    };
    return handleError;
};
