/** @file A paper with a border **/
import { Theme, makeStyles } from '@material-ui/core';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import classNames from 'classnames';
import * as React from 'react';

import { PaperVariant } from './types';

const useStyles = makeStyles((theme: Theme) => ({
	card: {
		overflow: 'hidden'
	},
	outline: {
		border: `1px solid ${theme.palette.divider}`,
		overflow: 'hidden'
	},
	flat: {
		overflow: 'hidden'
	}
}));

type CustomPaperProps = Omit<PaperProps, 'classes' | 'variant'> & {
	variant?: PaperVariant;
};
export const CustomPaper: React.FunctionComponent<CustomPaperProps> = (
	props
) => {
	const classes = useStyles();
	const {
		/** @description Default paper based on outline setting*/
		variant = ENABLE_CARD_OUTLINE ? 'outline' : 'card',
		className,
		children,
		elevation,
		...newProps
	} = props;
	/** @description
	 * Only card will have the elevation by default
	 */
	const defaultElevation = variant === 'card' ? 1 : 0;
	return (
		<Paper
			elevation={elevation || defaultElevation}
			className={classNames(classes[variant], className)}
			{...newProps}
		>
			{children}
		</Paper>
	);
};
