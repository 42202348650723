import { isMobile } from '@gaming-shell/device';
import { InputAdornment, TextField, makeStyles } from '@material-ui/core';
import BigNumber from 'bignumber.js';
import * as React from 'react';
import { stakeInputMaxDecimals } from 'sports/config/app';
import {
	getVariantCurrencyValue,
	usePlayerCurrencyVariantConfig
} from 'sports/utils/currency';
import { handleStakeInputChange, useTrailing } from 'sports/utils/stake';

interface BigBetslipStakeInputProps {
	stake: number;
	disabled?: boolean;
	onStakeChange: (stake: number) => void;
	onEditStake: () => void;
}
const useStyles = makeStyles({
	root: {
		textAlign: 'right'
	}
});
export const BigBetslipParlayStakeInput: React.FunctionComponent<BigBetslipStakeInputProps> = (
	props
) => {
	const { stake, disabled, onStakeChange, onEditStake } = props;
	const classes = useStyles();
	const [trailing, setTrailing] = useTrailing(stake);
	const currencyConfig = usePlayerCurrencyVariantConfig();
	const variantStake = getVariantCurrencyValue(currencyConfig, stake);
	const stakeString = `${new BigNumber(variantStake).toFixed()}${
		trailing.trail
	}`;
	const handleChange = React.useCallback(
		handleStakeInputChange(
			currencyConfig,
			onStakeChange,
			setTrailing,
			stakeInputMaxDecimals
		),
		[onStakeChange]
	);
	const onFieldClick = React.useCallback(() => {
		if (isMobile()) {
			onEditStake();
		}
	}, [onEditStake]);
	return (
		<TextField
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						{currencyConfig.label}
					</InputAdornment>
				)
			}}
			inputProps={{ className: classes.root }}
			onChange={handleChange}
			value={stakeString}
			disabled={disabled || isMobile()}
			onClick={onFieldClick}
		/>
	);
};
