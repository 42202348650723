import { useTranslation } from '@gaming-shell/i18n';
import { PlayButton } from 'casino/components/PlayButton';
import {
	getFreespinCasinoCurrency,
	getFreespinGameId,
	getFreespinSpinsAwarded
} from 'casino/selectors/freespinsSelectors/freespinsSelectors';
import { getFreespinsImageUrl } from 'casino/util/freespinsUtils';
import format from 'date-fns/format';
import {
	PromoCard,
	PromoContent,
	PromoDetailsItem,
	PromoDetailsRow,
	PromoDetailsSection,
	PromoHeader,
	withHook
} from 'materialbet-common';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { getGameImageUrl } from 'casino/selectors/gamesSelectors';
import { getFreespinExpiresAt } from 'casino/selectors/freespinsSelectors';
import { makeStyles } from '@material-ui/core';
// TODO this should be exposed via player package
import useSetActiveCurrency from 'core/api/hooks/useSetActiveCurrency';
import {
	usePlayerAuthToken,
	usePlayerCurrency
} from '@materialbet-core/player';
import { Currency, getCurrencyConfig } from 'materialbet-common';

export interface FreespinPromotionCardUiProps {
	expiresAt: string;
	spinsAwarded: string;
	gameCurrency: string;
	playerCurrency: string;
	imageUrl: string;
	gameId: string;
}
const useStyles = makeStyles((theme) => ({
	cardMedia: {
		[theme.breakpoints.up('sm')]: {
			paddingTop: '75%'
		}
	}
}));
export const FreespinPromotionCardUi: React.FC<FreespinPromotionCardUiProps> = (
	props
) => {
	const {
		expiresAt,
		imageUrl,
		spinsAwarded,
		playerCurrency,
		gameCurrency,
		gameId
	} = props;
	const t = useTranslation();
	const isDifferentCurrency = playerCurrency !== gameCurrency;
	const expiresAtDate = new Date(expiresAt);
	const expires = t('descriptions.expiresAtDate', {
		date: format(expiresAtDate, 'dd.MM')
	}); // TODO localize dates
	const title = t('player:promotions.titles.numberOfFreeSpins', {
		amount: spinsAwarded
	});
	const awardedTitle = t('descriptions.awarded');
	const currencyTitle = t('descriptions.currency');
	const currencyDisplayName =
		getCurrencyConfig(gameCurrency)?.label || gameCurrency;
	const playTitle = isDifferentCurrency
		? t('actions.playInCurrency', { currency: currencyDisplayName })
		: t('actions.playNow');
	const classes = useStyles();

	const { isSubmitting, apiCallback } = useSetActiveCurrency();
	const isSubmittingRef = React.useRef(isSubmitting);
	isSubmittingRef.current = isSubmitting;
	const authToken = usePlayerAuthToken();
	const onClick = isDifferentCurrency
		? () =>
				apiCallback({
					currency: gameCurrency as Currency,
					authToken
				})
		: undefined;

	return (
		<PromoCard
			title={title}
			active={true}
			expires={expires}
			variant="vertical"
			imageUrl={imageUrl}
			actions={
				<PlayButton
					variant="contained"
					color="primary"
					gameId={gameId}
					onClick={onClick}
				>
					{playTitle}
				</PlayButton>
			}
		>
			<PromoHeader cardMediaClassName={classes.cardMedia}>
				<PromoDetailsSection>
					<PromoDetailsRow>
						<PromoDetailsItem
							title={awardedTitle}
							value={spinsAwarded}
						/>
						<PromoDetailsItem
							title={currencyTitle}
							value={currencyDisplayName}
						/>
					</PromoDetailsRow>
				</PromoDetailsSection>
			</PromoHeader>
			<PromoContent />
		</PromoCard>
	);
};
const useFreespinPromotionCardUiProps = ({
	id
}: {
	id: string;
}): FreespinPromotionCardUiProps => {
	const gameCurrency = useSelector(getFreespinCasinoCurrency(id)) || 'BTC';
	const playerCurrency = usePlayerCurrency();
	const gameId = useSelector(getFreespinGameId(id)) || '';
	const expiresAt = useSelector(getFreespinExpiresAt(id)) || '';
	const spinsAwarded = useSelector(getFreespinSpinsAwarded(id)) || '';
	const gameImageUrl = useSelector(getGameImageUrl(gameId)) || '';
	const imageUrl = getFreespinsImageUrl(gameImageUrl);

	return {
		gameCurrency,
		playerCurrency,
		gameId,
		expiresAt,
		spinsAwarded,
		imageUrl
	};
};
export const FreespinPromotionCard = withHook(useFreespinPromotionCardUiProps)(
	FreespinPromotionCardUi
);
