import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import clx from 'classnames';
import * as React from 'react';

const useChipStyles = makeStyles((theme) => ({
	root: {
		marginLeft: theme.spacing(1),
		maxWidth: '100px' // Fixed width incase translation string too long
	},
	wave: {
		position: 'relative',
		overflow: 'hidden',
		color: `linear-gradient(90deg, transparent, gray, transparent)`,
		'&::after': {
			animation: '$wave 1s linear 0.5s 3',
			background: `linear-gradient(90deg, transparent, rgba(255,255,255,0.5), transparent)`,
			content: '""',
			position: 'absolute',
			transform: 'translateX(-100%)',
			bottom: 0,
			left: 0,
			right: 0,
			top: 0,
			zIndex: 1
		},
		animationName: '$wave'
	},
	'@keyframes wave': {
		'0%': {
			transform: 'translateX(-100%)'
		},
		'60%': {
			// +0.5s of delay between each loop
			transform: 'translateX(100%)'
		},
		'100%': {
			transform: 'translateX(100%)'
		}
	}
}));

export interface SideMenuItemChipProps {
	label: JSX.Element;
}

export const SideMenuItemChip: React.FunctionComponent<SideMenuItemChipProps> = (
	props
) => {
	const chipClasses = useChipStyles();
	return (
		<Chip
			variant="outlined"
			size="small"
			label={props.label}
			color="secondary"
			className={clx(chipClasses.wave, chipClasses.root)}
		/>
	);
};
