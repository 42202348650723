/** Creates a esports route */
import { pathParam, route } from '@gaming-shell/routing';

import {
	createCompetitionRouteParams,
	getCompetitionRouteParams
} from '../utils/sportsRouteUtils';

const esportsRoute = route(['esports']);

const competitionRoute = esportsRoute.add([
	pathParam('sportKey'),
	pathParam('competitionKey')
]);
const outrightRoute = esportsRoute.add([
	pathParam('sportKey'),
	'outrights',
	pathParam('competitionKey')
]);
const createCompetitionProxy = <
	T extends typeof competitionRoute | typeof outrightRoute
>(
	underlyingRoute: T
): T => ({
	...underlyingRoute,
	create: (params) =>
		underlyingRoute.create(createCompetitionRouteParams(params)),
	createRelative: (params) =>
		underlyingRoute.createRelative(createCompetitionRouteParams(params)),
	getPathParams: (path) =>
		getCompetitionRouteParams(underlyingRoute.getPathParams(path)),
	useParams: () => getCompetitionRouteParams(underlyingRoute.useParams())
});

export const EsportsRoutes = {
	root: esportsRoute,
	bets: esportsRoute.add(['bets']),
	live: esportsRoute.add(['live']),
	competition: createCompetitionProxy(competitionRoute),
	event: createCompetitionProxy(competitionRoute.add([pathParam('eventId')])),
	eventWithKey: createCompetitionProxy(
		competitionRoute.add([pathParam('eventId'), pathParam('eventKey')])
	),
	outright: createCompetitionProxy(
		outrightRoute.add([pathParam('eventId'), pathParam('eventKey')])
	),
	sport: esportsRoute.add([pathParam('sportKey')]),
	// Below will be use for tab selection on the single sport page
	sportCompetitions: esportsRoute.add([
		pathParam('sportKey'),
		'competitions'
	]),
	sportHighlights: esportsRoute.add([pathParam('sportKey'), 'highlights']),
	sportToday: esportsRoute.add([pathParam('sportKey'), 'today']),
	sportTomorrow: esportsRoute.add([pathParam('sportKey'), 'tomorrow']),
	sportVirtuals: esportsRoute.add([pathParam('sportKey'), 'virtuals']),
	sportInPlay: esportsRoute.add([pathParam('sportKey'), 'inPlay']),
	sportOutrights: esportsRoute.add([pathParam('sportKey'), 'outrights']), // same as outrights now, add one to make clear that its for tab
	topCoupon: esportsRoute.add([
		pathParam('sportKey'),
		'popular',
		pathParam('couponKey')
	]),
	virtualSports: esportsRoute.add(['virtuals'])
};

// For the pattern and typing
const singleEsportRoute = esportsRoute.add([pathParam('sportKey'), '']);
export type SingleEsportTabRoute = typeof singleEsportRoute;
