import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { withHook } from 'materialbet-common';
import * as React from 'react';
import { useBetslipSelector } from 'sports/components/betterBetslip/hooks';
import { SelectionAdditionalInfoLine } from 'sports/components/SelectionAdditionalInfoLine';

import { getSelectionAdditionalInfo } from '../betterBetslip/betslipSelectors';

export interface SelectionAdditionalInfoProps {
	infos: string[];
}
const useStyles = makeStyles((theme) => ({
	divider: {
		maxWidth: 215,
		margin: theme.spacing(1, 0)
	}
}));
export const SelectionAdditionalInfoUi: React.FC<SelectionAdditionalInfoProps> = ({
	infos
}) => {
	const classes = useStyles();
	if (!infos || !infos.length) {
		return null;
	}
	return (
		<>
			<Divider className={classes.divider} />
			{infos.map((info) => (
				<SelectionAdditionalInfoLine key={info} info={info} />
			))}
		</>
	);
};
export const SelectionAdditionalInfo = withHook(
	({ selectionIndex }: { selectionIndex: number }) => {
		const infos =
			useBetslipSelector((s) =>
				getSelectionAdditionalInfo(s, { selectionIndex })
			) || [];
		return { infos };
	}
)(SelectionAdditionalInfoUi);
