import Lock from '@material-ui/icons/Lock';
import { createSvgIcon } from 'sports/components/util/Icon';

export const LockIcon = ENABLE_NEW_ICON
	? createSvgIcon(
			'M11.78 2.005L12 2a5.07 5.07 0 0 1 5.065 4.85l.005.22v2.329l.49.001a1.98 1.98 0 0 1 1.98 1.98v8.64A1.98 1.98 0 0 1 17.56 22H6.45a1.98 1.98 0 0 1-1.98-1.98v-8.64A1.98 1.98 0 0 1 6.45 9.4l.48-.001V7.07a5.07 5.07 0 0 1 4.85-5.065L12 2zm5.78 8.895H6.45a.48.48 0 0 0-.48.48v8.64c0 .265.215.48.48.48h11.11a.48.48 0 0 0 .48-.48v-8.64a.48.48 0 0 0-.48-.48zM12 13.1a2.6 2.6 0 1 1 0 5.2 2.6 2.6 0 0 1 0-5.2zm0 1.5a1.1 1.1 0 1 0 0 2.2 1.1 1.1 0 0 0 0-2.2zm.19-11.095L12 3.5a3.57 3.57 0 0 0-3.565 3.38l-.005.19v2.329h7.14V7.07a3.57 3.57 0 0 0-3.38-3.565L12 3.5z',
			'lock',
			'0 0 24 24'
	  )
	: Lock;
