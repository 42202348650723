export const createForEntitySelector = <
	TEntitySelector extends (
		id: TEntityId
	) => (state: TState) => TEntity | undefined,
	TState = Parameters<ReturnType<TEntitySelector>>[0],
	TEntityId = Parameters<TEntitySelector>[0],
	TEntity = NonNullable<ReturnType<ReturnType<TEntitySelector>>>
>(
	entitySelector: TEntitySelector
) => <T>(selector: (entity: TEntity) => T) => (id: TEntityId) => (
	state: TState
) => {
	const e = entitySelector(id)(state);
	return e ? selector(e) : undefined;
};
