"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@material-ui/core");
var React = __importStar(require("react"));
var RocketLeagueIcon = function (props) {
    return (React.createElement(core_1.SvgIcon, __assign({}, props),
        React.createElement("g", { transform: "translate(2 2)" },
            React.createElement("path", { d: "M4.297 12.212c.172-.06.9-.113 2.59.266.989.221 3.447.912 4.909 2.539.48.535.823 1.057.949 1.443-1.164.961-2.203 1.543-2.667 1.782-.703-.41-2.777-1.712-4.522-3.718-.01-.014-.022-.027-.05-.058-.842-.965-1.309-1.838-1.21-2.254m-1-5.327c1.817 0 3.296-1.544 3.296-3.442 0-.416-.07-.82-.209-1.205 1.225-.244 2.458-.367 3.67-.367 4.068 0 7.357 1.382 8.397 1.874.043 1.02-.006 4.162-1.796 7.825-.767 1.57-1.837 2.999-3.183 4.253-.196-.473-.527-1.02-.984-1.632-1.504-2.01-4.286-3.369-8.268-4.04-.892-.151-1.37-.872-1.578-1.299-.184-.692-.336-1.42-.454-2.168.355.132.733.201 1.109.201m-.018-5.317c.999 0 1.812.838 1.812 1.87 0 1.03-.813 1.87-1.812 1.87-1 0-1.812-.84-1.812-1.87 0-1.032.813-1.87 1.812-1.87m16.666 1.555l-.053-.402-.346-.19c-.167-.092-4.139-2.245-9.491-2.245-1.543 0-3.1.179-4.628.532C4.831.29 4.078 0 3.296 0 1.479 0 0 1.544 0 3.443c0 .611.156 1.21.452 1.737.153 1.824.587 4.521 1.776 7.089 2.24 4.835 7.27 7.46 7.483 7.57l.314.161.323-.138c.205-.088 5.056-2.214 7.677-7.578 2.437-4.985 1.941-8.993 1.92-9.161", id: "Path" }))));
};
exports.default = RocketLeagueIcon;
