import { SvgIconProps } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import {
	Bitcoin,
	BitcoinCash,
	Ethereum,
	GeminiDollar,
	PaxGold,
	PaxosStandard,
	Tether,
	TetherGold,
	TrueUsd,
	UsdCoin,
	USDollar,
	Euro,
	Yen,
	IndianRupee,
	BrazilianReal
} from 'materialbet-icons';
import * as React from 'react';

export interface CurrencyIconProps {
	className?: string;
	currency: string;
}

const currencyIcons: Record<string, React.ComponentType<SvgIconProps>> = {
	BTC: Bitcoin,
	BCH: BitcoinCash,
	ETH: Ethereum,
	US2: Tether,
	US3: UsdCoin,
	US4: TrueUsd,
	US5: PaxosStandard,
	US6: GeminiDollar,
	AU1: PaxGold,
	AU2: TetherGold,
	USD: USDollar,
	EUR: Euro,
	JPY: Yen,
	INR: IndianRupee,
	BRL: BrazilianReal
};

export const CurrencyIcon: React.FC<CurrencyIconProps> = ({
	currency,
	className
}) => {
	const Icon = currencyIcons[currency] || ErrorIcon;
	return <Icon className={className} role="presentation" />;
};
