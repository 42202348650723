import { CompetitionEventsParams } from 'sports/modules/eventsModule';

/** @description Presets some default values  */
export const getCompetitionEventsMetaParams = (
	props: Pick<CompetitionEventsParams, 'competitionKey' | 'locale'> &
		Partial<CompetitionEventsParams>
): CompetitionEventsParams => {
	return {
		limit: undefined,
		entity: 'events',
		...props
	};
};
/** @description Presets some default values  */
export const getCompetitionOutrightsMetaParams = (
	props: Pick<CompetitionEventsParams, 'competitionKey' | 'locale'>
): CompetitionEventsParams => {
	return {
		limit: OUTRIGHTS_LIMIT,
		entity: 'outrights',
		...props
	};
};

export const competitionIsVirtual = (sportKey = '', competitionKey = '') =>
	competitionKey.startsWith(`${sportKey}-virtual`);
