import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

const InstantWin: React.FunctionComponent<SvgIconProps> = (props) => {
	return (
		<SvgIcon {...props}>
			<g>
				<path
					fill={props.fill}
					d="M15,11.3c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8C15.8,10.9,15.4,11.3,15,11.3z"
				/>
				<path
					fill={props.fill}
					d="M10.5,11.3c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8C11.3,10.9,10.9,11.3,10.5,11.3z"
				/>
				<path
					fill={props.fill}
					d="M10.9,15.9c1.3,0.9,2.4,0.9,3.7,0c0.4-0.3,0.8-0.2,1.1,0.2c0.3,0.4,0.2,0.8-0.2,1.1c-0.9,0.6-1.8,0.9-2.7,0.9
					s-1.8-0.3-2.7-0.9c-0.4-0.3-0.5-0.7-0.2-1.1C10.1,15.8,10.6,15.7,10.9,15.9L10.9,15.9z"
				/>
				<path
					fill={props.fill}
					d="M1.5,6c0,0.8,0.7,1.5,1.5,1.5c0.3,0,0.6-0.1,0.9-0.3c0-0.9,0.3-1.7,1.1-2.5c0.3-0.3,0.7-0.3,1,0c0.3,0.3,0.3,0.7,0,1
					C5.5,6.1,5.3,6.5,5.3,7.2c0,0.9,0.7,1.7,1.5,1.8v1.6c-1.4,0-2.5,1.3-2.2,2.6c-0.9,0.5-1.5,1.5-1.5,2.6c0,1.7,1.4,3.1,3.1,3
					c0.5,1.7,2.3,2.7,4,2.1c0.5,1,1.5,1.7,2.7,1.7s2.2-0.7,2.7-1.7c1.7,0.7,3.5-0.4,4-2.1c1.7,0.1,3.1-1.3,3.1-3c0-1.1-0.6-2.1-1.5-2.6
					c0.3-1.4-0.8-2.6-2.2-2.6V9c0.9-0.2,1.5-1,1.5-1.8c0-0.9-0.7-1.7-1.6-1.9c-0.4-2.1-2.2-3.8-4.5-3.8H9.6c-2,0-3.9,0.8-5.3,2.2
					L3.4,4.6C3.3,4.5,3.1,4.5,3,4.5C2.2,4.5,1.5,5.2,1.5,6L1.5,6z M6.8,12c0.2,0.7,0.7,1.3,1.5,1.7c1.3,0.7,3.2,0.8,4.4,0.2
					c1.2,0.6,3.1,0.5,4.4-0.2c0.8-0.4,1.3-1,1.5-1.7c0.8,0,1.1,0.7,0.8,1.2c-0.2,0.2-0.2,0.5-0.1,0.7c0.1,0.2,0.3,0.4,0.5,0.5
					c0.7,0.2,1.2,0.8,1.2,1.5c0,1-1,1.8-2,1.4c-0.3-0.1-0.5-0.1-0.7,0.1c-0.2,0.2-0.4,0.4-0.3,0.8c0,1.3-1.5,2-2.5,1.1
					c-0.2-0.2-0.5-0.3-0.8-0.2c-0.3,0.1-0.5,0.4-0.5,0.7c-0.1,0.8-0.7,1.4-1.5,1.4s-1.4-0.6-1.5-1.4c0-0.3-0.2-0.6-0.5-0.7
					c-0.3-0.1-0.6-0.1-0.8,0.2C9,20,7.4,19.3,7.4,18c0.1-0.4-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.1-0.5,0.1
					c-0.8,0-1.5-0.7-1.5-1.5c0-0.7,0.5-1.3,1.2-1.5c0.3-0.1,0.5-0.2,0.5-0.5c0.1-0.3,0.1-0.5-0.1-0.7C5.8,12.7,6.1,12,6.8,12L6.8,12z
					M16.5,12.4c-1.1,0.6-2.6,0.5-3.3,0c-0.3-0.2-0.6-0.2-0.9,0c-0.7,0.5-2.3,0.6-3.3-0.1c-0.8-0.4-0.8-0.9-0.8-1.1V9h9v2.3
					C17.3,11.7,17,12.1,16.5,12.4L16.5,12.4z M7.1,6.8c4.3,0,6.9,0,11.2,0c0.5,0,0.5,0.8,0,0.8H7.1C6.6,7.5,6.6,6.8,7.1,6.8z"
				/>
			</g>
		</SvgIcon>
	);
};
export default InstantWin;
