import { MarketType } from 'sports-sdk/sports-core/sports-core-types';

import { sportInfo } from './sportInfo';
import { SportType } from './types';

/** @description compeititon markets for the sports in a dictionary object*/
export const sportDefaultMarketMapping = Object.keys(SportType).reduce(
	(acc, s) => {
		const market = sportInfo[s]?.competitionMarketConfig?.[0].markets[0];
		const marketType = market?.marketType;
		if (!marketType) {
			return acc;
		}
		acc[s] = marketType;
		return acc;
	},
	{} as Record<SportType, MarketType>
);

/** @description default compeititon markets for the sports in an array*/
export const defaultSportMarkets = Object.keys(sportDefaultMarketMapping).map(
	(s) => sportDefaultMarketMapping[s]
);
