import hoistNonReactStatic from 'hoist-non-react-statics';
import raf from 'raf';
import React, { PureComponent } from 'react';

/** @file inspired by https://github.com/hanford/defer-render-hoc/blob/master/src/index.js */
/**
 * Allows two animation frames to complete to allow other components to update
 * and re-render before mounting and rendering an expensive `WrappedComponent`.
 */

export const deferComponentRender = (placeholder: React.ReactNode = null) => {
	return function defer<P>(WrappedComponent: React.ComponentType<P>) {
		class DeferredRenderWrapper extends PureComponent<P> {
			state = { shouldRender: false };

			componentDidMount() {
				raf(() => raf(() => this.setState({ shouldRender: true })));
			}

			render() {
				return this.state.shouldRender ? (
					<WrappedComponent {...this.props} />
				) : (
					placeholder
				);
			}
		}

		return hoistNonReactStatic(DeferredRenderWrapper, WrappedComponent);
	};
};
