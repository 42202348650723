import { useLanguage, useTranslation } from '@gaming-shell/i18n';
import { LoadingOverlay } from '@gaming-shell/layout';
import { PlayerSideMenu } from '@materialbet-core/app/src/components/SideMenu';
import { License } from '@materialbet-core/app/src/pages/License/License';
import { PrivacyPolicy } from '@materialbet-core/app/src/pages/PrivacyPolicy/PrivacyPolicy';
import { Rules } from '@materialbet-core/app/src/pages/Rules/Rules';
import { Terms } from '@materialbet-core/app/src/pages/Terms/Terms';
import { TransactionResultPage } from '@materialbet-core/app/src/pages/TransactionResultPage';
import { Promotions } from '@materialbet-core/app/src/Promotions';
import { SideEffects as PlayerSideEffects } from '@materialbet-core/app/src/SideEffects';
import { Analytics } from 'Analytics';
import { SideEffects as CasinoSideEffects } from 'materialbet-casino/src';
import {
	AlternateLanguageLinks,
	AuthRoutes,
	BaseRoutes,
	CasinoRoutes,
	EsportsRoutes,
	hasAuthEnabled,
	PlayerRoutes,
	PromotionsRoutes,
	SportsRoutes,
	withHeader,
	RedirectToExternal
} from 'materialbet-common';
import { ExchangeRatesSideEffects } from 'materialbet-exchange-rates';
import {
	EsportsSideMenu,
	SportsSideEffects,
	SportsSideMenu
} from 'materialbet-sports/src';
import {
	SignOut,
	AuthSideEffectsSwitch,
	getAuthRedirectUrl,
	signInParam,
	signUpParam
} from 'materialbet-auth';
import { OnLanguageChange } from 'OnLangaugeChange';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { LoginOnlyRedirect } from './LoginOnlyRedirect';
import { NoTrailingSlashRedirect } from './NoTrailingSlashRedirect';
import { Provider } from './Providers/Provider';
import { ServiceWorkerHandler } from './registerServiceWorker';

const Player = React.lazy(() =>
	import(
		/* webpackMode: "lazy" */
		/* webpackPrefetch: true */
		'@materialbet-core/app/src/'
	)
);
const Sports = React.lazy(() =>
	import(
		/* webpackMode: "lazy" */
		/* webpackPrefetch: true */
		'materialbet-sports/src'
	)
);
const Casino = React.lazy(() =>
	import(
		/* webpackMode: "lazy" */
		/* webpackPrefetch: true */
		'materialbet-casino/src'
	)
);
const Auth = React.lazy(() =>
	import(
		/* webpackMode: "lazy" */
		/* webpackPrefetch: true */
		'@materialbet-core/auth/src'
	)
);

interface ShellProps {
	swHandler: ServiceWorkerHandler;
}

const Redirections: React.FunctionComponent<{}> = () => {
	useLanguage(); // required to rerender redirect routes when language changes
	return (
		<Switch>
			<Route path={CasinoRoutes.root.template}>
				<Casino />
			</Route>
			{!ENABLE_ESPORTS_SECTION && (
				<Redirect
					exact
					from={EsportsRoutes.root.template}
					to={SportsRoutes.root.create()}
				/>
			)}
			<Route
				path={[SportsRoutes.root.template, EsportsRoutes.root.template]}
			>
				<Sports />
			</Route>
			{!PROMOTIONS_URL && ENABLE_PROMOTION_PAGE && (
				<Route path={PromotionsRoutes.root.template}>
					<Promotions />
				</Route>
			)}
			<Route
				path={[
					BaseRoutes.providerTransactionSuccess.template,
					BaseRoutes.providerTransactionCanceled.template
				]}
			>
				<TransactionResultPage />
			</Route>
			{ENABLE_STATIC_PAGES && (
				<Route path={BaseRoutes.license.template}>
					<License />
				</Route>
			)}
			{ENABLE_STATIC_PAGES && (
				<Route path={BaseRoutes.termsAndConditions.template}>
					<Terms />
				</Route>
			)}
			{ENABLE_STATIC_PAGES && (
				<Route path={BaseRoutes.rules.template}>
					<Rules />
				</Route>
			)}
			{ENABLE_STATIC_PAGES && (
				<Route path={BaseRoutes.privacyPolicy.template}>
					<PrivacyPolicy />
				</Route>
			)}
			{hasAuthEnabled() && (
				<Route path={AuthRoutes.signIn.create()}>
					<RedirectToExternal
						path={getAuthRedirectUrl(signInParam, true)}
					/>
				</Route>
			)}
			{hasAuthEnabled() && (
				<Route path={AuthRoutes.signUp.create()}>
					<RedirectToExternal
						path={getAuthRedirectUrl(signUpParam, true)}
					/>
				</Route>
			)}
			{hasAuthEnabled() && (
				<Route path={PlayerRoutes.security.create()}>
					<RedirectToExternal path={KEYCLOAK_ACCOUNT_PAGE} />
				</Route>
			)}
			{hasAuthEnabled() && (
				<Route path={AuthRoutes.logout.create()}>
					<SignOut />
				</Route>
			)}
			<Route path={PlayerRoutes.root.template}>
				<Player />
			</Route>
			<Route path={AuthRoutes.root.template}>
				<Auth />
			</Route>
			{/* Please put all redirects below */}
			<Redirect
				exact
				from={BaseRoutes.root.create()}
				to={SportsRoutes.root.create()}
			/>
			<Redirect
				exact
				from={CasinoRoutes.root.templateWithoutLocale}
				to={CasinoRoutes.root.create()}
			/>
			<Redirect
				exact
				from={SportsRoutes.root.templateWithoutLocale}
				to={SportsRoutes.root.create()}
			/>
			<Redirect
				exact
				from={EsportsRoutes.root.templateWithoutLocale}
				to={
					ENABLE_ESPORTS_SECTION
						? EsportsRoutes.root.create()
						: SportsRoutes.root.create()
				}
			/>
			<Redirect
				exact
				from={PlayerRoutes.root.templateWithoutLocale}
				to={PlayerRoutes.root.create()}
			/>
			<Redirect
				exact
				from={AuthRoutes.root.templateWithoutLocale}
				to={AuthRoutes.root.create()}
			/>
			<Redirect
				exact
				from={AuthRoutes.signIn.templateWithoutLocale}
				to={AuthRoutes.signIn.create()}
			/>
			<Redirect
				exact
				from={AuthRoutes.signUp.templateWithoutLocale}
				to={AuthRoutes.signUp.create()}
			/>
			<Redirect
				exact
				from={CasinoRoutes.root.templateWithoutLocale}
				to={CasinoRoutes.root.create()}
			/>
			<Redirect
				exact
				from={PromotionsRoutes.root.templateWithoutLocale}
				to={PromotionsRoutes.root.create()}
			/>
			<Redirect from="/app" exact to={BaseRoutes.root.create()} />
			<Redirect
				exact
				from={BaseRoutes.root.templateWithoutLocale}
				to={SportsRoutes.root.create()}
			/>
			<Redirect
				exact
				from={CasinoRoutes.legacyLiveCasino.template}
				to={CasinoRoutes.liveCasino.create()}
			/>
			<Redirect
				exact
				from={CasinoRoutes.permanentLiveCasino.templateWithoutLocale}
				to={CasinoRoutes.liveCasino.create()}
			/>
		</Switch>
	);
};
// this header will be used for any page that does not provide their own header
const useHeaderProps = () => {
	const t = useTranslation();
	const title = t('application.sitemap.pages.common.root.title', {
		brand: BRAND_DISPLAY_NAME
	});
	const description = t('application.sitemap.pages.common.root.description', {
		brand: BRAND_DISPLAY_NAME,
		year: FOUNDING_YEAR,
		bonus: 5 // not configurable - will probably have completely different meta tags (or none at all) for different brands
	});
	return { title, description };
};
const RedirectionsWithHeader = withHeader(useHeaderProps)(Redirections);
const Shell: React.FunctionComponent<ShellProps> = (props) => {
	return (
		// TODO Create brand package anaologue to environment package
		<Provider>
			<AlternateLanguageLinks />
			<Analytics />
			<AuthSideEffectsSwitch />
			<PlayerSideEffects />
			<CasinoSideEffects />
			<OnLanguageChange />
			<SportsSideMenu />
			<EsportsSideMenu />
			<SportsSideEffects />
			<ExchangeRatesSideEffects />
			<PlayerSideMenu />
			<React.Suspense fallback={<LoadingOverlay />}>
				<LoginOnlyRedirect>
					<NoTrailingSlashRedirect />
					<RedirectionsWithHeader />
				</LoginOnlyRedirect>
			</React.Suspense>
		</Provider>
	);
};

export default React.memo(Shell);
