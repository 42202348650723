import { MarketType } from 'sports-sdk';

import { MarketView, TabConfiguration } from '../types';

const allMarkets = {
	popular: [
		{
			marketType: MarketType.cricket_winner
		},
		{
			marketType: MarketType.cricket_player_total
		}
	],
	specials: [
		{
			marketType: MarketType.cricket_away_home_supremacy
		},
		{
			marketType: MarketType.cricket_home_away_supremacy
		},
		{
			marketType: MarketType.cricket_over_team_total,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.cricket_over_total_runs_spread
		},
		{
			marketType: MarketType.cricket_team_run_range
		},
		{
			marketType: MarketType.cricket_team_total_in_highest_scoring_over,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.cricket_team_totals,
			view: MarketView.Goals
		},
		{
			marketType: MarketType.cricket_team_with_highest_score_at_dismmissal
		},
		{
			marketType:
				MarketType.cricket_test_match_inning_over_total_runs_spread
		},
		{
			marketType: MarketType.cricket_test_match_innings_over_team_total
		},
		{
			marketType: MarketType.cricket_test_match_innings_team_totals
		},
		{
			marketType: MarketType.cricket_test_match_team_run_range
		},
		{
			marketType: MarketType.cricket_test_match_innings_totals
		},
		{
			marketType:
				MarketType.cricket_test_match_team_total_in_highest_scoring_over
		},
		{
			marketType: MarketType.cricket_will_there_be_a_tie
		}
	]
};

export const eventMarketConfig: TabConfiguration[] = [
	{
		label: 'descriptions.popular',
		markets: allMarkets.popular
	},
	{
		label: 'markets.specials',
		markets: allMarkets.specials
	},
	{
		label: 'descriptions.all',
		markets: [...allMarkets.popular, ...allMarkets.specials]
	}
];
export const competitionMarketConfig: TabConfiguration[] = [
	{
		label: '',
		markets: [
			{
				marketType: MarketType.cricket_winner
			}
		]
	}
];
export const outrightMarketConfig: TabConfiguration = {
	label: '',
	markets: [
		{
			marketType: MarketType.cricket_outright
		}
	]
};

export const marketInfo = {
	eventMarketConfig,
	competitionMarketConfig,
	outrightMarketConfig
};
