import { Translate } from '@gaming-shell/i18n';
import { camelize } from 'casino/util/camelcase';
import * as React from 'react';
import { connectHits, connectStateResults } from 'react-instantsearch-dom';

import {
	SearchFilterProps,
	SearchResultsProps,
	FilterItem
} from '../../types/sharedComponents';
import {
	AlgoliaHitsProvided,
	SearchResultItem
} from '../SearchResults/Content';
import { extractCategories } from '../SearchResults/utils/extractCategories';

const SearchFilters: React.FC<SearchFilterProps &
	SearchResultsProps<SearchResultItem>> = ({
	onClick,
	component,
	activeFilters,
	storedFilters,
	storeFilters,
	allSearchResults
}) => {
	const hits =
		allSearchResults?.[ALGOLIA_INDEXES.casino]
			?.hits; /* get only casino hits */
	const categories = hits && extractCategories(hits);
	const Component = component;
	const handleClick = React.useCallback(
		(filter: FilterItem) => () => {
			onClick(filter);
			storedFilters.length === 0 &&
				categories?.length > 0 &&
				storeFilters(categories);
		},
		[onClick, storedFilters, storeFilters, categories]
	);

	return (
		<>
			{(storedFilters.length > 0 ? storedFilters : categories)?.map(
				(category) => {
					const active = activeFilters?.find(
						(filter) => filter.name === category.name
					);
					return (
						<Component
							onClick={handleClick(category)}
							active={active}
							key={category.name}
						>
							<Translate
								label={`casino.games.categories.${camelize(
									category.name
								)}`}
								ns="common"
							/>
						</Component>
					);
				}
			)}
		</>
	);
};

/* that's required workaround because of algolia bug https://github.com/algolia/react-instantsearch/issues/137 */
const ConnectedSearchFilters = (connectHits(
	(connectStateResults(SearchFilters) as unknown) as React.FC<
		SearchFilterProps & AlgoliaHitsProvided
	>
) as unknown) as React.FC<SearchFilterProps>;
export { ConnectedSearchFilters as SearchFilters };
