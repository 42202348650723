import { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import * as React from 'react';
import { Link } from 'react-router-dom';

type LinkListItemProps = ButtonBaseProps & ListItemProps & { to: string };

export const LinkListItem: React.FunctionComponent<LinkListItemProps> = (
	props
) => {
	// prettier-ignore
	// @ts-ignore
	return (<ListItem button={true as true} component={Link as React.ElementType}  {...props}>{props.children}</ListItem>
	);
};
