import * as React from 'react';

import { betslipActions } from '../betslipReducer';
import { getSelection } from '../betslipSelectors';
import {
	getChangedSelectionPrice,
	getQuickbetSlipStatus
} from '../betslipSelectors/betslipSelectors';
import { useBetslipSelector } from '../hooks';
import { useBetslipDispatch } from '../hooks/useBetslipDispatch';
import { QuickBetslip } from './QuickBetslip';
import { QuickBetslipWrapper } from './QuickBetslipWrapper';

export interface QuickBetslipContainerProps {
	anchorEl: Element | null;
	onPlaceBetClick: () => void;
	open: boolean;
	onClose: () => void;
}
export const QuickBetslipContainer: React.FunctionComponent<QuickBetslipContainerProps> = (
	props
) => {
	const { anchorEl, onPlaceBetClick, open, onClose } = props;
	const dispatch = useBetslipDispatch();
	const selection = useBetslipSelector((state) =>
		getSelection(state, { selectionIndex: 0 })
	);
	const changedPrice = useBetslipSelector((state) =>
		getChangedSelectionPrice(state, { selectionIndex: 0 })
	);
	const status = useBetslipSelector(getQuickbetSlipStatus);
	const setStake = React.useCallback(
		(stake: number) =>
			dispatch(
				betslipActions.updateSelectionStake({
					selectionIndex: 0,
					stake
				})
			),
		[dispatch]
	);
	const handleStakeDelete = React.useCallback(() => {
		dispatch(betslipActions.resetSelectionStake(0));
	}, [dispatch]);
	return (
		<QuickBetslipWrapper open={open} anchorEl={anchorEl} onClose={onClose}>
			{selection && (
				<QuickBetslip
					betslipStatus={status}
					selection={selection}
					onClose={onClose}
					setStake={setStake}
					onStakeDelete={handleStakeDelete}
					onPlaceBetClick={onPlaceBetClick}
					variant="placeBet"
					changedPrice={changedPrice}
				/>
			)}
		</QuickBetslipWrapper>
	);
};
