import { Button, Grid, Theme, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { stakeInputMaxDecimals } from 'sports/config/app';
import {
	getBaseCurrencyValue,
	getVariantCurrencyValue,
	usePlayerCurrencyVariantConfig
} from 'sports/utils/currency';
import { getDecimalCount, getTrailingDecimals } from 'sports/utils/number';
import { StakeTrailingInfo, getTrailingStakeInput } from 'sports/utils/stake';

export interface QuickBetslipStakeDialPadProps {
	stake: number;
	trailing: string;
	disabled?: boolean;
	minStake: number | string;
	maxStake: number;
	onChange: (value: number) => void;
	setTrailing: (trail: StakeTrailingInfo) => void;
}

export const useDialPadStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(1),
		height: '64px',
		fontSize: 'large',
		'&:hover': {}
	}
}));

let QuickBetslipStakeDialPad: React.FunctionComponent<QuickBetslipStakeDialPadProps> = (
	props
) => {
	const { stake, onChange, trailing, setTrailing, disabled } = props;
	const dialPadValues = [
		'7',
		'8',
		'9',
		'4',
		'5',
		'6',
		'1',
		'2',
		'3',
		'0',
		'.'
	];
	const currencyConfig = usePlayerCurrencyVariantConfig();
	const dialPadClasses = useDialPadStyles();
	const disableButton = (stake: number, buttonValue: string) => {
		if (buttonValue === '.') {
			const variantStake = getVariantCurrencyValue(currencyConfig, stake);
			return !!trailing || variantStake.toString().includes('.');
		}

		return false;
	};
	const handleChange = (value: string) => {
		const variantStake = getVariantCurrencyValue(
			currencyConfig,
			stake
		).toString();
		const newStakeInput = variantStake + trailing + value;
		const newStakeInputValue = parseFloat(newStakeInput);
		const newTrailing = getTrailingStakeInput(newStakeInput);
		const decimalCount =
			getDecimalCount(newStakeInputValue) +
			getTrailingDecimals(newStakeInput);
		if (decimalCount > stakeInputMaxDecimals) {
			return;
		}
		const newStake = getBaseCurrencyValue(
			currencyConfig,
			parseFloat(newStakeInput)
		);
		setTrailing({ trail: newTrailing, forStake: newStake });
		onChange(newStake);
	};

	const dialPadButtons = dialPadValues.map((value: string) => {
		return (
			<Grid item key={value} xs={value === '0' ? 8 : 4}>
				<Button
					disabled={disabled || disableButton(stake, value)}
					onClick={() => handleChange(value)}
					classes={dialPadClasses}
					fullWidth
					variant="contained"
				>
					{value}
				</Button>
			</Grid>
		);
	});
	return (
		<Grid container spacing={1}>
			<Grid item container xs={12} spacing={1}>
				{dialPadButtons}
			</Grid>
		</Grid>
	);
};
QuickBetslipStakeDialPad = React.memo(QuickBetslipStakeDialPad);
export { QuickBetslipStakeDialPad };
