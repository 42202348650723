import * as React from 'react';

import { getBetslipSelectionsIdentityString } from '../betslipSelectors/betslipSelectors';
import { SelectionsIdentitySeparator } from '../betslipSelectors/betslipSelectorsUtils';
import { useBetslipSelector } from '../hooks/useBetslipSelector';
import { BigBetslipSelection } from './BigBetslipSelection';
import { BigBetslipTabProps } from './types';

export const BigBetslipParlay: React.FunctionComponent<BigBetslipTabProps> = (
	props
) => {
	const {
		onSelectionUpdate,
		currencyConfig,
		setCurrentEditStakeView
	} = props;
	const selectionsIdentity = useBetslipSelector(
		getBetslipSelectionsIdentityString
	);
	const selectionList = selectionsIdentity
		.split(SelectionsIdentitySeparator)
		.map((identity, selectionIndex) => {
			return (
				<BigBetslipSelection
					key={identity}
					selectionIndex={selectionIndex}
					onSelectionUpdate={onSelectionUpdate}
					currencyConfig={currencyConfig}
					setCurrentEditStakeView={setCurrentEditStakeView}
					disableStakeInput={true}
				/>
			);
		});
	return <>{selectionList} </>;
};
