"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBackRoute = void 0;
var routing_1 = require("@gaming-shell/routing");
var materialbet_common_1 = require("materialbet-common");
/* look for first sports, casino or player entry in history stack, fallback to base route */
exports.getBackRoute = function () {
    return routing_1.getMatchingHistoryEntry([
        materialbet_common_1.SportsRoutes.root.create(),
        materialbet_common_1.EsportsRoutes.root.create(),
        materialbet_common_1.CasinoRoutes.root.create(),
        materialbet_common_1.PlayerRoutes.root.create()
    ]) || materialbet_common_1.BaseRoutes.root.create();
};
