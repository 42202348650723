import { BetsHistoryState } from 'sports/api/sportsbetting/api';

export interface BetsHistoryResponseIdentifier {
	to: number;
	from: number;
	state: BetsHistoryState;
	locale: string;
}
export const betsHistoryResponseId = ({
	state,
	from,
	to,
	locale
}: BetsHistoryResponseIdentifier) => `${state}_${from}_${to}_${locale}`;
