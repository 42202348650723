import * as React from 'react';

import { usePrevious } from '../usePrevious';

/** @descriptions Will return the given value until the freeze condition is true. Will return the given value from the moment the condition has changed from false to true. */
export const useFreeze = <T>(currentValue: T, freezeCondition: boolean) => {
	const frozenValue = React.useRef(currentValue);
	const previousCondition = usePrevious(freezeCondition);
	if (!freezeCondition) {
		return currentValue;
	}
	if (previousCondition !== freezeCondition) {
		frozenValue.current = currentValue;
	}
	return frozenValue.current;
};
