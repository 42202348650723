import { WinnerEntity } from 'casino/casinoSchemas';
import { ActionType, createAction, getType } from 'typesafe-actions';

export type WinnerType = 'recent' | 'biggest' | 'jackpot';

export interface WinnersParams {
	type: WinnerType;
	limit?: number;
}

export const getWinnersKey = (params: WinnersParams) =>
	`${params.type}_${params.limit}`;
export const loads = {
	winners: (params: WinnersParams) =>
		`winners/winners_${getWinnersKey(params)}`
};

export const actions = {
	addWinner: createAction(
		'winners/addWinner',
		(resolve) => (payload: { params: WinnersParams; data: WinnerEntity }) =>
			resolve(payload)
	)
};

export type WinnersActions = ActionType<typeof actions>;
export type Winners = { [key: string]: WinnerEntity[] };

/** @description add winner to the given winners tree */
const addWinner = (
	state: Winners = {},
	payload: { params: WinnersParams; data: WinnerEntity }
) => {
	const { params, data: winner } = payload;
	const key = getWinnersKey(params);
	let winners = state[key] || [];
	if (!winner.id) {
		return state;
	}
	const stateWinner = winners.findIndex((w) => w.id === winner.id);
	if (stateWinner >= 0) {
		return state;
	}
	winners = [winner, ...winners]; // Add winner to the front of the list
	if (params.limit) {
		winners = winners.slice(0, params.limit + 1); // Leave one more for animation
	}
	return { ...state, [key]: winners };
};

export const winnersReducer = (
	state: Winners = {},
	action: WinnersActions
): Winners => {
	if (!action.payload) {
		return state;
	}
	switch (action.type) {
		case getType(actions.addWinner):
			return addWinner(
				state,
				((action as unknown) as ActionType<typeof actions.addWinner>)
					.payload
			);
		default:
			return state;
	}
};
