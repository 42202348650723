import { combineEpics } from 'redux-observable';
import { MarketType } from 'sports-sdk/sports-core';
import { getDefaultHeaders } from 'sports/api/sports';
import { EventMetadata, Market } from 'sports/api/sports';
import * as schemas from 'sports/schema';
import { SubmarketEntity } from 'sports/schema';
import { getFormatDate, getTimeZone } from 'sports/utils/date';
import {
	setCompetitionKeyInCompetitionEvent,
	setCompetitionKeyInEvent,
	setSportKeyInCompetitions,
	setCompetitionKeyInOutright,
	setCompetitionKeyInCompetitionOutright
} from 'sports/utils/entity';
import { createFetchEntityEpic } from 'sports/utils/epic';
import { getSportsApiLocale } from 'sports/utils/locale';
import {
	buildEventTreesMetaKey,
	createEventTreesMeta,
	finishLoading
} from 'sports/utils/meta';
import { createEntityReducer } from 'sports/utils/reducer';
import { ActionType, createAction, getType } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';
import deepmerge from 'deepmerge';

import {
	createCompetitionEventsMeta,
	createCompetitionOutrightsMeta
} from 'sports/utils/meta';
import { overwriteArray } from 'sports/utils/deepmergeUtils';

const apiDefaultOptions = { headers: getDefaultHeaders(getTimeZone()) };
export interface EventsParams {
	from?: number;
	to?: number;
	sport?: string;
	sports?: string[];
	live?: boolean;
	upcoming?: boolean;
	streaming?: boolean;
	virtual?: boolean;
	limit?: number;
	locale: string;
	markets?: MarketType[];
	priority?: number;
	players?: boolean;
}
export interface CompetitionEventsParams {
	from?: number;
	to?: string;
	competitionKey: string;
	locale: string;
	entity?: 'outrights' | 'events';
	limit?: number;
}

interface OutrightsParams {
	sports?: string[];
	markets?: MarketType[];
	priority?: number;
	limit?: number;
	locale: string;
}

interface PopularParams {
	sports?: string[];
	locale: string;
	markets?: MarketType[];
	limit?: number;
	players?: boolean;
}

interface PopularCompetitionParams {
	sports?: string[];
	markets?: MarketType[];
	players?: boolean;
	numComp?: number;
	locale: string;
}

const getPopularKey = ({
	sports,
	locale,
	markets,
	players,
	limit
}: PopularParams) =>
	`[${sports && sports.join(',')}]_[${
		markets && markets.join(',')
	}_${locale}_${players}_${limit}`;

const getPopularCompetitionsKey = ({
	locale,
	markets,
	players,
	numComp
}: PopularCompetitionParams) =>
	`[${markets && markets.join(',')}]_${locale}_${players}_${numComp}`;

export const getEventsKey = (params: EventsParams) => {
	let string = '';
	if (params.from) {
		string += `_from_${params.from}`;
	}
	if (params.to) {
		string += `_to_${params.to}`;
	}
	if (params.sport) {
		string += `_sport_${params.sport}`;
	}
	if (params.sports) {
		string += `_sports_${params.sports.join(',')}`;
	}
	if (params.live) {
		string += `_live_${params.live}`;
	}
	if (params.virtual) {
		string += `_virtual_${params.virtual}`;
	}
	if (params.upcoming) {
		string += `_upcoming_${params.upcoming}`;
	}
	if (params.streaming) {
		string += `_streaming_${params.streaming}`;
	}
	if (params.players) {
		string += `_players_${params.players}`;
	}
	if (params.limit) {
		string += `_limit_${params.limit}`;
	}
	if (params.locale) {
		string += `_locale_${params.locale}`;
	}
	if (params.markets) {
		string += `_markets_${params.markets.join(',')}`;
	}
	if (params.priority) {
		string += `_priority_${params.priority}`;
	}
	return string;
};
export const getCompetitionEventsKey = (params: CompetitionEventsParams) => {
	const { entity = 'events', limit, competitionKey } = params;
	let string = `_competition_${entity}_${competitionKey}_${limit}_locale_${params.locale}`;
	if (params.to) {
		string += `_to_${params.to}`;
	}
	return string;
};
const getOutrightsKey = (params: OutrightsParams) => {
	let string = '';
	if (params.sports) {
		string += `_sports_${params.sports.join(',')}`;
	}
	if (params.markets) {
		string += `_markets_${params.markets.join(',')}`;
	}
	if (params.priority) {
		string += `_priority_${params.priority}`;
	}
	if (params.limit) {
		string += `_limit_${params.limit}`;
	}
	string += `_locale_${params.locale}`;
	return string;
};
export const loads = {
	events: (params: EventsParams) => 'events/events_' + getEventsKey(params),
	popular: (params: PopularParams) =>
		'events/popular_' + getPopularKey(params), // TODO
	competition: (params: CompetitionEventsParams) =>
		`events/competition_${getCompetitionEventsKey(params)}`,
	popularCompetitions: (params: PopularCompetitionParams) =>
		'events/popularCompetitions_' + getPopularCompetitionsKey(params),
	main: (eventId: string | number) => `events/main${eventId}`,
	mainOutright: (eventId: string | number) => `events/outright${eventId}`,
	outrights: (params: OutrightsParams) =>
		'events/ourights_' + getOutrightsKey(params)
};

////////////////
/// Actions ///
//////////////
export const actions = {
	fetchEvents: createAction(
		'events/fetchEvents',
		(resolve) => (payload: {
			timeZone: string;
			from?: number;
			to?: number;
			sport?: string;
			sports?: string[];
			live?: boolean;
			upcoming?: boolean;
			streaming?: boolean;
			virtual?: boolean;
			players?: boolean;
			limit?: number;
			locale: string;
			markets?: MarketType[];
			priority?: number;
		}) => resolve(payload)
	),
	fetchPopular: createAction(
		'events/fetchPopular',
		(resolve) => (payload: {
			sports?: string[];
			players?: boolean;
			limit?: number;
			locale: string;
			markets?: MarketType[];
			timeZone: string;
		}) => resolve(payload)
	),
	fetchMain: createAction(
		'events/fetchMain',
		(resolve) => (payload: { id: number; locale: string }) =>
			resolve(payload)
	),
	fetchByCompetition: createAction(
		'events/fetchByCompetition',
		(resolve) => (payload: {
			from?: number;
			to?: number;
			key: string;
			markets?: string[];
			players?: boolean;
			limit?: number;
			locale: string;
			timeZone: string;
		}) => resolve(payload)
	),
	fetchPopularCompetitions: createAction(
		'events/fetchPopularCompetitions',
		(resolve) => (payload: {
			sports?: string[];
			markets?: MarketType[];
			players?: boolean;
			numComp?: number;
			locale: string;
			timeZone: string;
		}) => resolve(payload)
	),
	fetchSpecials: createAction(
		'events/fetchSpecial',
		(resolve) => (payload: { id: number }) => resolve(payload)
	),
	fetchOutrights: createAction(
		'events/fetchOutrights',
		(resolve) => (payload: {
			sports?: string[];
			markets?: MarketType[];
			priority?: number;
			limit?: number;
			locale: string;
			timeZone: string;
		}) => resolve(payload)
	),
	fetchOutrightsByCompetition: createAction(
		'events/fetchOutrightsByCompetition',
		(resolve) => (payload: {
			key: string;
			markets?: string[];
			limit?: number;
			locale: string;
			timeZone: string;
		}) => resolve(payload)
	),
	fetchMainOutright: createAction(
		'events/fetchMainOutright',
		(resolve) => (payload: { id: number; locale: string }) =>
			resolve(payload)
	),
	updateSubmarket: createAction(
		'events/updateSubmarket',
		(resolve) => (payload: {
			eventId: number | string;
			marketKey: string;
			submarketKey: string;
			submarket: SubmarketEntity;
		}) => resolve(payload)
	),
	updateEvent: createAction(
		'events/updateEvent',
		(resolve) => (payload: schemas.EventEntity) => resolve(payload)
	),
	updateMarket: createAction(
		'events/updateMarket',
		(resolve) => (payload: {
			eventId: number;
			marketKey: string;
			market: Market;
		}) => resolve(payload)
	),
	updateEventMetadata: createAction(
		'events/updateEventMetadata',
		(resolve) => (payload: {
			metadata: EventMetadata & { startsAt?: number };
			eventId: number | string;
		}) => resolve(payload)
	),
	updateOdds: createAction(
		'events/updateOdds',
		(resolve) => (payload: {
			id: number | string;
			outcome: string;
			side: 'BACK' | 'LAY';
			marketKey: string;
			price: number;
			params: string;
			probability: number;
			submarketKey: string;
		}) => resolve(payload)
	),
	updateStatus: createAction(
		'events/updateStatus',
		(resolve) => (payload: { id: number; status: string }) =>
			resolve(payload)
	),
	clearMarkets: createAction(
		'events/clearMarkets',
		(resolve) => (payload: { id: number | string }) => resolve(payload)
	)
};
export const eventsActions = actions;
export type EventsActions = ActionType<typeof actions>;
const updateEntities = createEntityReducer<schemas.EventEntity>('events');

/** @description update event markets, status and promotions for now */
const updateEvent = (
	state: DeepReadonly<{ [key: string]: schemas.EventEntity }> = {},
	event: schemas.EventEntity
) => {
	if (!event || !event.id || !state[event.id]) {
		return state;
	}
	const stateEvent = state[event.id];
	if (
		stateEvent.sequence &&
		(!event.sequence || event.sequence < stateEvent.sequence)
	) {
		return state;
	}
	const markets = deepmerge(stateEvent.markets || {}, event.markets || {}, {
		arrayMerge: overwriteArray
	});
	const newEvent = {
		...state[event.id],
		markets,
		status: event.status,
		promotions: event.promotions
	};
	return { ...state, [event.id]: newEvent };
};

const updateStatus = (
	state: DeepReadonly<{ [key: string]: schemas.EventEntity }> = {},
	payload: Parameters<typeof actions.updateStatus>[0]
) => {
	if (!payload.id || !payload.status) {
		return state;
	}
	const newEvent = { ...state[payload.id], status: payload.status };
	return { ...state, [payload.id]: newEvent };
};

const updateEventMetadata = (
	state: DeepReadonly<{ [key: string]: schemas.EventEntity }> = {},
	payload: Parameters<typeof actions.updateEventMetadata>[0]
) => {
	if (!payload.eventId || !state[payload.eventId]) {
		return state;
	}
	let startTime = state[payload.eventId]?.startTime;
	if (payload.metadata.startsAt) {
		startTime = new Date(payload.metadata.startsAt * 1000).toISOString();
	}

	const newEvent = {
		...state[payload.eventId],
		metadata: payload.metadata,
		startTime
	};
	return { ...state, [payload.eventId]: newEvent };
};
const updateSubmarket = (
	state: DeepReadonly<{ [key: string]: schemas.EventEntity }> = {},
	payload: Parameters<typeof actions.updateSubmarket>[0]
) => {
	const { marketKey, submarketKey, eventId, submarket } = payload;
	const event = state[eventId];
	if (!event) {
		return state;
	}
	const market = (event.markets || {})[marketKey];
	const currentSubmarket =
		market.submarkets && market.submarkets[submarketKey];
	if (
		currentSubmarket &&
		currentSubmarket.sequence &&
		submarket.sequence &&
		currentSubmarket.sequence > submarket.sequence
	) {
		// received outdated update
		return state;
	}
	const newEvent = deepmerge(
		event,
		{
			markets: {
				[payload.marketKey]: {
					submarkets: { [payload.submarketKey]: submarket }
				}
			}
		},
		{ arrayMerge: overwriteArray }
	);
	return { ...state, [eventId]: newEvent };
};

const updateMarket = (
	state: DeepReadonly<{ [key: string]: schemas.EventEntity }> = {},
	payload: Parameters<typeof actions.updateMarket>[0]
) => {
	const { marketKey, eventId, market } = payload;
	const event = state[eventId];
	if (!event) {
		return state;
	}
	// TODO we are not comparing modified payloads right now as we do not want to query through submarkets, maybe we can add this to the marketlevel update?
	const newEvent = deepmerge(
		event,
		{
			markets: {
				[marketKey]: market
			}
		},
		{ arrayMerge: overwriteArray }
	);
	return { ...state, [eventId]: newEvent };
};
const clearMarkets = (
	state: DeepReadonly<{ [key: string]: schemas.EventEntity }> = {},
	payload: Parameters<typeof actions.clearMarkets>[0]
) => {
	if (!state[payload.id]) {
		return state;
	}
	const event = { ...state[payload.id] };
	event.markets = {};
	return { ...state, [payload.id]: event };
};

const updateOdds = (
	state: DeepReadonly<{ [key: string]: schemas.EventEntity }> = {},
	payload: Parameters<typeof actions.updateOdds>[0]
) => {
	const event = { ...state[payload.id] };

	if (!event.markets) {
		return state;
	}

	const markets = { ...event.markets };
	const marketKey = Object.keys(markets).find(
		(key) => key === payload.marketKey
	);

	if (!marketKey) {
		return state;
	}

	const market = { ...markets[marketKey] };

	const submarketKey = payload.submarketKey;
	if (!market.submarkets) {
		return state;
	}

	const submarkets = { ...market.submarkets[submarketKey] };

	if (!submarkets) {
		return state;
	}
	if (!submarkets.selections) {
		return state;
	}

	const selections = submarkets.selections.map((selection) => {
		if (
			selection.params === payload.params &&
			selection.outcome === payload.outcome
		) {
			return {
				...selection,
				probability: payload.probability,
				price: payload.price,
				side: payload.side
			};
		}
		return selection;
	});

	submarkets.selections = selections;
	market.submarkets = { ...market.submarkets, [submarketKey]: submarkets };
	markets[marketKey] = market;
	event.markets = markets;

	return { ...state, [payload.id]: event };
};

export const eventsReducer = (
	state: DeepReadonly<{ [key: string]: schemas.EventEntity }> = {},
	action: EventsActions
): DeepReadonly<{ [key: string]: schemas.EventEntity }> => {
	state = updateEntities(state, action);
	if (!action.payload) {
		return state;
	}
	switch (action.type) {
		case getType(actions.updateSubmarket):
			return updateSubmarket(
				state,
				((action as unknown) as ActionType<
					typeof actions.updateSubmarket
				>).payload
			);
		case getType(actions.updateMarket):
			return updateMarket(
				state,
				((action as unknown) as ActionType<typeof actions.updateMarket>)
					.payload
			);
		case getType(actions.clearMarkets):
			return clearMarkets(
				state,
				((action as unknown) as ActionType<typeof actions.clearMarkets>)
					.payload
			);
		case getType(actions.updateEvent):
			return updateEvent(
				state,
				((action as unknown) as ActionType<typeof actions.updateEvent>)
					.payload
			);
		case getType(actions.updateEventMetadata):
			return updateEventMetadata(
				state,
				((action as unknown) as ActionType<
					typeof actions.updateEventMetadata
				>).payload
			);
		case getType(actions.updateOdds):
			return updateOdds(
				state,
				((action as unknown) as ActionType<typeof actions.updateOdds>)
					.payload
			);
		case getType(actions.updateStatus):
			return updateStatus(
				state,
				((action as unknown) as ActionType<typeof actions.updateStatus>)
					.payload
			);
	}
	return state;
};

//////////////
/// EPICS ///
////////////

const getEventsEpic = createFetchEntityEpic(
	actions.fetchEvents,
	(
		{
			from,
			to,
			sports,
			live,
			upcoming,
			streaming,
			virtual,
			locale,
			markets,
			priority,
			players = true,
			limit,
			timeZone
		},
		{ api }
	) =>
		api.sports
			.withPreMiddleware(async (context) =>
				deepmerge(context, {
					init: { headers: getDefaultHeaders(timeZone) }
				})
			)
			.getEvents(
				from,
				to,
				sports,
				markets,
				live,
				players,
				upcoming,
				priority,
				streaming,
				virtual,
				limit,
				getSportsApiLocale(locale)
			),
	[schemas.sports],
	(result) => {
		if (!result.sports) {
			return [];
		}
		setSportKeyInCompetitions(result.sports);
		setCompetitionKeyInEvent(result);
		return result.sports;
	},
	({ payload, result }) => {
		const params = payload;
		const load = finishLoading(loads.events(params));
		if (!result) {
			return load;
		}
		const eventTreeKey = buildEventTreesMetaKey({
			...payload,
			...{ endpoint: 'events' }
		});
		const sportsMeta = createEventTreesMeta(result, eventTreeKey);
		return { ...load, ...sportsMeta };
	}
);

const getCompetitionEventsEpic = createFetchEntityEpic(
	actions.fetchByCompetition,
	(
		{ key, from, to, players = true, limit, markets, locale, timeZone },
		{ api }
	) =>
		api.sports
			.withPreMiddleware(async (context) =>
				deepmerge(context, {
					init: { headers: getDefaultHeaders(timeZone) }
				})
			)
			.getCompetitionEvents(
				key,
				from,
				to,
				markets,
				players,
				limit,
				undefined, // TODO live
				getSportsApiLocale(locale)
			),
	schemas.competitions,
	(result) => {
		setCompetitionKeyInCompetitionEvent(result);
		return result;
	},
	({ payload, result }) => {
		const formatedDate = getFormatDate(
			payload && payload.to && payload.to * 1000
		);
		const params: CompetitionEventsParams = {
			competitionKey: payload.key,
			locale: payload.locale,
			to: formatedDate
		};
		const key = getCompetitionEventsKey(params);
		const load = finishLoading(loads.competition(params));
		const meta = createCompetitionEventsMeta(result, key);
		const resultMeta = { ...load, ...meta };
		return resultMeta;
	}
);

const getMainEventEpic = createFetchEntityEpic(
	actions.fetchMain,
	({ id, locale }, { api }) =>
		api.sports
			.withPreMiddleware(async (context) =>
				deepmerge(context, {
					init: apiDefaultOptions
				})
			)
			.getMainEvent(id, undefined, getSportsApiLocale(locale)),
	schemas.events,
	(event) => event,
	({ payload }) => finishLoading(loads.main(payload.id))
);

const getPopularEventsEpic = createFetchEntityEpic(
	actions.fetchPopular,
	({ sports, players, limit, locale, markets, timeZone }, { api }) =>
		api.sports
			.withPreMiddleware(async (context) =>
				deepmerge(context, {
					init: { headers: getDefaultHeaders(timeZone) }
				})
			)
			.getPopularEvents(
				sports,
				markets,
				players,
				limit,
				getSportsApiLocale(locale)
			),
	[schemas.sports],
	(result) => {
		setCompetitionKeyInEvent(result);
		if (!result.sports) {
			return [];
		}
		setSportKeyInCompetitions(result.sports);
		return result.sports;
	},
	({ payload, result }) => {
		const load = finishLoading(loads.popular(payload));
		if (!result) {
			return load;
		}

		const eventTreeKey = buildEventTreesMetaKey({
			...payload,
			...{ endpoint: 'popular' }
		});
		const sportsMeta = createEventTreesMeta(result, eventTreeKey);
		return { ...load, ...sportsMeta };
	}
);

const getPopularCompetitionsEpic = createFetchEntityEpic(
	actions.fetchPopularCompetitions,
	({ markets, players, numComp, locale, timeZone }, { api }) =>
		api.sports
			.withPreMiddleware(async (context) =>
				deepmerge(context, {
					init: { headers: getDefaultHeaders(timeZone) }
				})
			)
			.getPopularCompetitions(
				markets,
				players,
				numComp,
				getSportsApiLocale(locale)
			),
	[schemas.sports],
	(result) => {
		setCompetitionKeyInEvent(result);
		if (!result.sports) {
			return [];
		}
		setSportKeyInCompetitions(result.sports);
		return result.sports;
	},
	({ payload, result }) => {
		const load = finishLoading(loads.popularCompetitions(payload));
		if (!result) {
			return load;
		}
		const eventTreeKey = buildEventTreesMetaKey({
			...payload,
			...{ endpoint: 'popularCompetitions' }
		});
		const sportsMeta = createEventTreesMeta(result, eventTreeKey);
		return { ...load, ...sportsMeta };
	}
);
const getOutrightsEpic = createFetchEntityEpic(
	actions.fetchOutrights,
	({ sports, markets, priority, limit, locale, timeZone }, { api }) =>
		api.sports
			.withPreMiddleware(async (context) =>
				deepmerge(context, {
					init: { headers: getDefaultHeaders(timeZone) }
				})
			)
			.getOutrights(
				sports,
				markets,
				priority,
				limit,
				getSportsApiLocale(locale)
			),
	[schemas.sports],
	(result) => {
		if (!result.sports) {
			return [];
		}
		setSportKeyInCompetitions(result.sports);
		setCompetitionKeyInOutright(result);
		return result.sports;
	},
	({ payload, result }) => {
		const params = payload;
		const load = finishLoading(loads.outrights(params));
		if (!result) {
			return load;
		}
		const eventTreeKey = buildEventTreesMetaKey({
			...payload,
			...{ endpoint: 'outrights' }
		});
		const sportsMeta = createEventTreesMeta(result, eventTreeKey);
		return { ...load, ...sportsMeta };
	}
);
const getCompetitionOutrightsEpic = createFetchEntityEpic(
	actions.fetchOutrightsByCompetition,
	({ key, limit, markets, locale, timeZone }, { api }) =>
		api.sports
			.withPreMiddleware(async (context) =>
				deepmerge(context, {
					init: { headers: getDefaultHeaders(timeZone) }
				})
			)
			.getCompetitionOutrights(
				key,
				markets,
				limit,
				getSportsApiLocale(locale)
			),
	schemas.competitions,
	(result) => {
		setCompetitionKeyInCompetitionOutright(result);
		return result;
	},
	({ payload, result }) => {
		const params: CompetitionEventsParams = {
			competitionKey: payload.key,
			locale: payload.locale,
			entity: 'outrights',
			limit: payload.limit
		};
		const key = getCompetitionEventsKey(params);
		const load = finishLoading(loads.competition(params));
		const meta = createCompetitionOutrightsMeta(result, key);
		const resultMeta = { ...load, ...meta };
		return resultMeta;
	}
);

const getMainOutrightEpic = createFetchEntityEpic(
	actions.fetchMainOutright,
	({ id, locale }, { api }) =>
		api.sports
			.withPreMiddleware(async (context) =>
				deepmerge(context, {
					init: apiDefaultOptions
				})
			)
			.getMainOutright(id, undefined, getSportsApiLocale(locale)),
	schemas.events,
	(event) => event,
	({ payload }) => finishLoading(loads.mainOutright(payload.id))
);

export const eventsEpic = combineEpics(
	getEventsEpic,
	getCompetitionEventsEpic,
	getMainEventEpic,
	getPopularEventsEpic,
	getPopularCompetitionsEpic,
	getOutrightsEpic,
	getCompetitionOutrightsEpic,
	getMainOutrightEpic
);
