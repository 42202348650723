import * as React from 'react';
import {
	formatCurrency,
	usePlayerCurrencyVariantConfig
} from 'sports/utils/currency';

import {
	getMultiplesTotalPrice,
	getMultiplesTotalReturn,
	getMultiplesTotalStake
} from '../betslipSelectors';
import {
	getCanValidate,
	getHasChanges,
	getIsSubmitting,
	getMultipleStake
} from '../betslipSelectors/betslipSelectors';
import { useBetslipSelector } from '../hooks/useBetslipSelector';
import { PlaceBetCallback } from '../placeBet/useBetslipPlaceBet';
import { BigBetSlipAcceptChangesButton } from './BigBetslipAcceptChangesButton';
import { BigBetslipPanelContent } from './BigBetslipPanelContent';
import { BigBetslipParlaySummary } from './BigBetslipParlaySummary';
import { BigBetslipPlaceMultiplesBetButton } from './BigBetslipPlaceMultiplesBetButton';
import { CurrentEditStakeView } from './types';

export interface UiBigBetslipParlayPanelContentProps {
	totalPrice: number;
	totalReturn: number;
	totalStake: number;
	hasChanges: boolean;
	stake: number;
	isSubmitting: boolean;
	onBetPlaced: PlaceBetCallback;
	setCurrentEditStakeView: (view?: CurrentEditStakeView) => void;
	canValidate: boolean;
}
export type BigBetslipParlayPanelContentProps = Omit<
	UiBigBetslipParlayPanelContentProps,
	| 'totalPrice'
	| 'totalStake'
	| 'totalReturn'
	| 'stake'
	| 'hasChanges'
	| 'isSubmitting'
	| 'canValidate'
>;
let UiBigBetslipParlayPanelContent: React.FunctionComponent<UiBigBetslipParlayPanelContentProps> = (
	props
) => {
	const {
		totalPrice,
		totalReturn,
		totalStake,
		hasChanges,
		stake,
		onBetPlaced,
		setCurrentEditStakeView,
		isSubmitting,
		canValidate
	} = props;
	const currencyConfig = usePlayerCurrencyVariantConfig();
	const formattedPotentialWin = formatCurrency(
		totalReturn,
		currencyConfig.currency,
		{ currencyPosition: 'none', variant: currencyConfig.label }
	);
	const disablePlaceBet = !canValidate || totalStake <= 0 || isSubmitting;
	const bigButton = hasChanges ? (
		<BigBetSlipAcceptChangesButton />
	) : (
		<BigBetslipPlaceMultiplesBetButton
			onBetPlaced={onBetPlaced}
			disabled={disablePlaceBet}
		/>
	);

	return (
		<>
			<BigBetslipPanelContent>
				<BigBetslipParlaySummary
					price={totalPrice}
					stake={stake}
					potentialWin={formattedPotentialWin}
					currencyLabel={currencyConfig.label}
					setCurrentEditStakeView={setCurrentEditStakeView}
				/>
				{bigButton}
			</BigBetslipPanelContent>
		</>
	);
};

UiBigBetslipParlayPanelContent = React.memo(UiBigBetslipParlayPanelContent);
export const BigBetslipParlayPanelContent: React.FunctionComponent<BigBetslipParlayPanelContentProps> = (
	props
) => {
	const totalPrice = useBetslipSelector(getMultiplesTotalPrice);
	const totalReturn = useBetslipSelector(getMultiplesTotalReturn);
	const totalStake = useBetslipSelector(getMultiplesTotalStake);
	const hasChanges = useBetslipSelector(getHasChanges);
	const isSubmitting = useBetslipSelector(getIsSubmitting);
	const canValidate = useBetslipSelector(getCanValidate);
	const stake = useBetslipSelector((state) =>
		getMultipleStake(state, { system: 0 })
	);
	return (
		<UiBigBetslipParlayPanelContent
			totalPrice={totalPrice}
			totalReturn={totalReturn}
			totalStake={totalStake}
			hasChanges={hasChanges}
			isSubmitting={isSubmitting}
			stake={stake}
			canValidate={canValidate}
			{...props}
		/>
	);
};
