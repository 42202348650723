import { Divider, makeStyles, Theme } from '@material-ui/core';
import { DividerProps } from '@material-ui/core/Divider';
import * as React from 'react';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		marginLeft: theme.spacing(1)
	},
	vertical: {
		width: 2,
		background: theme.palette.background.default
	}
}));
export const EventGroupDivider: React.FunctionComponent<DividerProps> = ({
	orientation
}) => {
	const classes = useStyles();
	return <Divider classes={classes} orientation={orientation} />;
};
