import { makeStyles, Theme } from '@material-ui/core/';
import { usePlayerCurrency } from '@materialbet-core/player';
import { PlayerRoutes } from 'materialbet-common';
import * as React from 'react';
import { AccountBalancePureButton } from './AccountBalancePureButton';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(0.5, 1)
	}
}));

export const AccountBalanceButtonLink: React.FC = () => {
	const classes = useStyles();
	const currency = usePlayerCurrency();

	return (
		<AccountBalancePureButton
			href={PlayerRoutes.cashierDeposit.create({
				currency
			})}
			className={classes.root}
		/>
	);
};
