"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRouteLanguage = void 0;
var utils_1 = require("./utils");
var routeLanguageCache = {
    path: '',
    language: DEFAULT_LANGUAGE
};
exports.getRouteLanguage = function () {
    if (routeLanguageCache.path === window.location.pathname) {
        return routeLanguageCache.language;
    }
    var routeLanguage = utils_1.toLocalLanguage(window.location.pathname.split('/')[1]);
    routeLanguageCache.language = routeLanguage;
    routeLanguageCache.path = window.location.pathname;
    return routeLanguageCache.language;
};
