import * as sportsPlayersApiConfig from 'sports/config/sportsplayers';

import { createSportsplayersApi } from './sportsplayers/api';
import { Configuration } from './sportsplayers/configuration';

const configuration: Configuration = {
	basePath: sportsPlayersApiConfig.url,
	baseOptions: {
		credentials: 'include',
		headers: {
			accept: 'application/json',
			'Content-Type': 'application/json',
			'Cache-Control': 'no-cache, no-store'
		}
	}
};
const api = createSportsplayersApi(configuration);
export default api;
