"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SideMenuFooter = void 0;
var styles_1 = require("@material-ui/core/styles");
var materialbet_common_1 = require("materialbet-common");
var React = __importStar(require("react"));
var CurrencyToggle_1 = require("../components/CurrencyToggle");
var CurrencyVariantSelect_1 = require("../components/CurrencyVariantSelect");
var LanguageSelect_1 = require("../components/LanguageSelect");
var PreferredOddsFormatSelect_1 = require("../components/PreferredOddsFormatSelect");
var SportsViewSelect_1 = require("../components/SportsViewSelect");
var useStyles = styles_1.makeStyles(function (theme) { return ({
    root: {
        padding: theme.spacing(2)
    },
    select: {
        marginBottom: theme.spacing(1)
    }
}); });
exports.SideMenuFooter = function (_a) {
    var showPlayerSettings = _a.showPlayerSettings;
    var _b = useStyles(), root = _b.root, select = _b.select;
    if (!materialbet_common_1.LEFT_NAVBAR_FOOTER_ENABLED) {
        return null;
    }
    return (React.createElement("div", { className: root },
        showPlayerSettings && materialbet_common_1.ASIAN_VIEW_ENABLED && (React.createElement("div", { className: select },
            React.createElement(SportsViewSelect_1.SportsViewSelect, null))),
        React.createElement("div", { className: select },
            React.createElement(LanguageSelect_1.LanguageSelect, null)),
        showPlayerSettings && (React.createElement("div", { className: select },
            React.createElement(PreferredOddsFormatSelect_1.PreferredOddsFormatSelect, null))),
        ENABLE_SATOSHI ? (React.createElement("div", { className: select },
            React.createElement(CurrencyVariantSelect_1.CurrencyVariantSelect, null))) : (React.createElement(CurrencyToggle_1.CurrencyToggle, null))));
};
