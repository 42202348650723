"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
var core_1 = require("@material-ui/core/");
exports.useStyles = core_1.makeStyles(function (theme) { return ({
    slider: {
        margin: theme.spacing(0, -1) // to allow for spacing on each tile to grow into, while having them aligned to the left
    },
    mobileSlider: {
        overflowX: 'auto',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        margin: theme.spacing(-1),
        '&::-webkit-scrollbar-track': {
            background: 'transparent'
        }
    },
    skeletonSlider: {
        margin: theme.spacing(0, -1),
        display: 'flex',
        width: '100%'
    }
}); });
