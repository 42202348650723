import { useUndismissedNotifications, Notification } from 'materialbet-inbox';
import take from 'lodash/take';

export interface UseUndismissedNotificationsLimitedOutput {
	undismissedNotifications: Notification[];
	totalLength: number;
}

export const useUndismissedNotificationsLimitedByLength = (): UseUndismissedNotificationsLimitedOutput => {
	const undismissedNotifications = useUndismissedNotifications();

	const undismissedNotificationsLimitedLength = take(
		undismissedNotifications,
		NUMBER_OF_SHOWN_PLAYER_INBOX_NOTIFICATIONS
	);

	return {
		undismissedNotifications: undismissedNotificationsLimitedLength,
		totalLength: undismissedNotifications.length
	};
};
