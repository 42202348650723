import { isMobile } from '@gaming-shell/device';
import { makeStyles, Theme } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';
import * as React from 'react';

import { PrimaryText, SecondaryText } from './Text';

export interface TeamNameProps {
	team: string;
	player?: string;
	className?: string;
	playerClassName?: string;
	textVariant?: TypographyProps['variant'];
	playerTextVariant?: TypographyProps['variant'];
	/** @description dont not trim the name with ellipsis if its true */
	disableEllipsis?: boolean;
}
const useTeamNameStyle = makeStyles((theme: Theme) => ({
	pitcherName: {
		margin: theme.spacing(0, 0.5)
	},
	ellipsisText: {
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	}
}));

export const TeamName: React.FunctionComponent<TeamNameProps> = (props) => {
	const {
		team,
		player,
		textVariant,
		playerTextVariant,
		className,
		playerClassName,
		disableEllipsis
	} = props;
	const classes = useTeamNameStyle(props);
	const customTextVariant = textVariant
		? textVariant
		: isMobile()
		? 'caption'
		: 'body2';
	return (
		<div className={className}>
			<PrimaryText
				variant={customTextVariant}
				className={clsx(!disableEllipsis && classes.ellipsisText)}
			>
				{team}
			</PrimaryText>
			{player && (
				<SecondaryText
					variant={playerTextVariant || customTextVariant}
					className={clsx(
						classes.pitcherName,
						!disableEllipsis && classes.ellipsisText,
						playerClassName
					)}
					noWrap={!disableEllipsis}
				>
					{player}
				</SecondaryText>
			)}
		</div>
	);
};
