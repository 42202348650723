"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.testEpic = exports.createFetchEntityEpic = void 0;
var normalizr_1 = require("normalizr");
var redux_observable_1 = require("redux-observable");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var typesafe_actions_1 = require("typesafe-actions");
/**
 * Creates an epic that on dispatch of given @param actionCreator type executes the @param fetch und normalizes the result with the given @param resultSchema.
 * @returns an updateEntities action if or an raisError action if an error ocurred
 * @param actionCreator The action which should be handled.
 * @param fetch An function which returns a promise.
 * @param resultSchema The schema by which the result is to be normalized.
 * @param resultSelector Optional. Select a part of the result to be normalized.
 */
exports.createFetchEntityEpic = function (params) {
    var newEpic = function (action$, _, dependencies) {
        return action$.pipe(operators_1.mergeMap(function (a) {
            return rxjs_1.of(a).pipe(
            // we need to create a new observable here since catchError completes the observable if an error occurs
            redux_observable_1.ofType(typesafe_actions_1.getType(params.actionCreator)), operators_1.groupBy(function (_a) {
                var payload = _a.payload;
                return JSON.stringify(payload);
            }), operators_1.map(function (group) {
                var _a;
                return group.pipe(operators_1.throttleTime(((_a = params.options) === null || _a === void 0 ? void 0 : _a.throttleTime) || 0));
            }), // prevents that the same api call gets executed multiple times within given throttle time
            operators_1.switchAll(), operators_1.mergeMap(function (_a) {
                var payload = _a.payload;
                return rxjs_1.from(params
                    .fetch(payload, dependencies)
                    .catch(function (error) {
                    // eslint-disable-next-line no-throw-literal
                    throw { response: error, payload: payload };
                })).pipe(operators_1.map(function (result) { return ({ payload: payload, result: result }); }));
            }), operators_1.map(function (_a) {
                var result = _a.result, payload = _a.payload;
                var toNormalize = params.resultSelector
                    ? params.resultSelector(result, payload)
                    : result;
                var entities = normalizr_1.normalize(toNormalize, params.resultSchema).entities;
                // this could potentially be better typed
                var meta = params.createMeta
                    ? params.createMeta({ payload: payload, result: result })
                    : undefined;
                return params.updateEntitiesAction(entities, meta);
            }), operators_1.catchError(function (error) {
                var actions = [];
                if (error.response && error.payload) {
                    // TODO Lets try to get additional typesafety in here as well
                    var meta = params.createMeta
                        ? params.createMeta({ payload: error.payload })
                        : undefined;
                    actions.push(params.updateEntitiesAction({}, meta));
                }
                return rxjs_1.from(actions);
            }));
        }));
    };
    return newEpic;
};
/**
 * testEpic is a simple test to compare the emited action against the expeected action for a given input action
 */
exports.testEpic = function (epic, expectedAction, done, inputAction, inputState, dependencies) {
    var actionSubject$ = new rxjs_1.Subject().pipe(operators_1.observeOn(rxjs_1.queueScheduler));
    var stateSubject$ = new rxjs_1.Subject().pipe(operators_1.observeOn(rxjs_1.queueScheduler));
    var action$ = new redux_observable_1.ActionsObservable(actionSubject$);
    var state$ = new redux_observable_1.StateObservable(stateSubject$, inputState);
    var output$ = epic(action$, state$, dependencies);
    var onError = jest.fn(function () { });
    var onNext = jest.fn();
    output$.subscribe(onNext, onError, function () {
        expect(onError).not.toBeCalled();
        if (expectedAction) {
            var firstCallArguments = onNext.mock.calls[0];
            expect(onNext).toBeCalled();
            expect(firstCallArguments[0]).toMatchObject(expectedAction);
        }
        done();
    });
    actionSubject$.next(inputAction);
    actionSubject$.complete();
};
