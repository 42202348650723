import { CardMedia, Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

export interface PromoHeaderImageProps {
	variant?: 'horizontal' | 'vertical';
	className?: string;
	imageUrl?: string;
	expired?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	vertical: {
		paddingTop: '60.25%',
		[theme.breakpoints.down('xs')]: {
			flex: 1,
			paddingTop: '30%',
			borderRadius: theme.spacing(0.5)
		}
	},
	horizontal: {
		paddingTop: 0,
		flex: 1,
		[theme.breakpoints.down('xs')]: {
			flex: 1.5
		}
	},
	expired: ({ variant }: PromoHeaderImageProps) => ({
		paddingTop: variant === 'horizontal' ? '17%' : '34.25%',
		filter: 'grayscale(100%)'
	})
}));

export const PromoHeaderImage: React.FunctionComponent<PromoHeaderImageProps> = ({
	variant = 'vertical',
	expired,
	imageUrl,
	className
}) => {
	const classes = useStyles({ variant });

	return (
		<CardMedia
			className={clsx(
				classes[variant],
				expired && classes.expired,
				className
			)}
			image={imageUrl}
		/>
	);
};
